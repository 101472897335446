import { useMediaQueries } from "../../../utils/mediaQueries";
import UnififiedProfile from "./desktop/UnifiedProfilePage";
import UnifiedProfilePageMobile from "./mobile/UnifiedProfilePageMobile";
import UnifiedProfilePageTablet from "./tablet/UnifiedProfilePageTablet";

const UnifiedProfileManagement = () => {
  const { isMobile, isTablet } = useMediaQueries();
  if (isMobile) return <UnifiedProfilePageMobile />;
  if (isTablet) return <UnifiedProfilePageTablet />;
  return <UnififiedProfile />;
};

export default UnifiedProfileManagement;
