import styled from "styled-components";

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.clearGray};
  background-color: ${(props) => props.theme.colors.white};
`;

const Title = styled.h1`
  margin-top: 16px;
  margin-bottom: 8px;
  ${(props) => props.theme.fonts.variant.semiBold()};
  font-size: 1.5rem;
  color: ${(props) => props.theme.colors.clearBlue};
`;

const Message = styled.p`
  ${(props) => props.theme.fonts.variant.regular()};
  font-size: 1rem;
  margin: 0px;
  padding: 0px;
`;

export {
  Title,
  Message,
  IconWrapper
};