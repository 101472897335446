import styled from 'styled-components';

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100vw;
    height: 100vh;
    background-color: ${props => props.theme.colors.greyTen};
`;

const WrapperLogoAimsByAnn = styled.div`
    position: absolute;
    top: 7.2vh;
    left: 12vw;
    z-index: 1;

    @media (max-width: 767px) {
        position: relative;
        top: auto;
        left: auto;
        display: block;
        justify-content: center;
        align-items: center;
        width: 20vh;
        padding-top: 20px;
        padding-bottom: 11vh;

        svg {
            width: clamp(16vh, 25vw, 30vh);
            height: auto;
            min-width: 120px; // Fallback for browsers that don't support clamp
        }
    }
`;

const BoxHeaderImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60vh;
    z-index: 0;

    border-radius: 0 0 20px 20px;
    overflow: hidden;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    z-index: 2;
    padding-bottom: 48px;
    
    @media (max-width: 767px) {
        display: none;
    }
`;

const DynamicTitleAnimatedWordWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 530px;
    height: 58px;
    
    div.word-container {
        width: 530px;
        height: 58px;
        position: absolute;
        opacity: 0;
        display: flex;
        justify-content: center;
    }

    div.word-container.visible {
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
    }

    div.word-container span {
        font-feature-settings: 'clig' off, 'liga' off;
        ${(props) => props.theme.fonts.variant.medium()};
        font-size: 48px;
        font-style: normal;
        line-height: normal;
        letter-spacing: -1px;
        color: ${props => props.theme.colors.white};
        opacity: 0;
    }

    div.word-container span.animate {
        animation: appear 0.5s forwards;
    }

    @keyframes appear {
        to {
            opacity: 1;
        }
    }
    
`;

const WrapperForgotPassword = styled.div`
    text-align: right;
`;

const FormWrapper = styled.div`
    display: flex;
    width: 530px;
    min-height: 460px;
    max-height: 550px;
    padding: 24px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
    z-index: 1;

    border-radius: 20px;
    background: ${props => props.theme.colors.silverGrayThree};

    @media (max-width: 767px) {
        width: 42vh;
        max-width: 100%;
        padding: 20px;
        min-height: auto;
        max-height: none;
    }
`;

const FormOrDivider = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 17px;
    align-items: center;
    justify-content: space-around;
    
    div {
        width: 100%;
        border-top: 1px solid ${props => props.theme.colors.silverGrayTwo};
        height: 2px;
    }
    
    span {
        ${(props) => props.theme.fonts.variant.regular()};
        color: ${props => props.theme.colors.greyEleven};
        font-size: 1em;
        line-height: normal;
        font-style: normal;
    }
`;

const FormSocialNetworks = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    height: 48px;
    padding: 24px 0;
`;

const SocialButton = styled.a`
    border-radius: 8px;
    border: 1px solid ${props => props.theme.colors.silverGrayTwo};
    width: 100%;
    display: flex;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    opacity: 0.7;
    
    &:hover {
        cursor: pointer;
        opacity: 1;
    }
`;

const FormTitle = styled.div`
    ${(props) => props.theme.fonts.variant.bold()};
    color: ${(props) => props.theme.colors.blueOne};
    padding: 0 0 24px 0;
    font-size: 2em;
    font-style: normal;
    line-height: normal;
    text-align: center;
`;

const FormSubtitle = styled.div`
    ${(props) => props.theme.fonts.variant.regular()};
    color: ${(props) => props.theme.colors.blueOne};
    font-feature-settings: 'clig' off, 'liga' off;
    padding: 0 0 24px 0;
    font-size: 1em;
    font-style: normal;
    line-height: normal;
    text-align: center;
`;

const FormFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 32px;
    width: 530px;
    gap: 12px;
    
    a {
        ${(props) => props.theme.fonts.variant.regular()};
        color: ${(props) => props.theme.colors.greyEleven};
        font-size: 1em;
        line-height: normal;
        font-style: normal;
        
        &:hover {
            cursor: pointer;
            color: ${(props) => props.theme.colors.blueOne};
        }
    }

    div.divider {
        width: 3px;
        height: 3px;
        background-color: ${(props) => props.theme.colors.greyEleven};
    }

    @media (max-width: 767px) {
        display: none;
    }
`;

const Footer = styled.div`
    position: absolute;
    bottom: 67px;
    display: flex;
    align-items: center;
    font-size: 1.2em;
    ${(props) => props.theme.fonts.variant.regular()};
    color: ${(props) => props.theme.colors.silverGrayTwo};
    letter-spacing: 0.15px;
    line-height: normal;

    @media (max-width: 767px) {
        display: absolute;
    }
`;

export {
    MainWrapper,
    WrapperLogoAimsByAnn,
    BoxHeaderImage,
    TitleWrapper,
    DynamicTitleAnimatedWordWrapper,
    WrapperForgotPassword,
    FormWrapper,
    FormTitle,
    FormSubtitle,
    FormOrDivider,
    FormSocialNetworks,
    SocialButton,
    FormFooter,
    Footer
}