import styled from 'styled-components';

const CategoriesList = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 60vh;
    overflow-y: auto;
    padding-right: 10px;
`;

const CategoryItem = styled.div`
    padding: 10px 20px;
    cursor: pointer;
    background-color: ${props => (props.selected ? '#f0f4ff' : 'transparent')}; 
    border-radius: 4px;
    transition: background-color 0.2s ease-in-out;
    position: relative;
    font-size:13px;

    
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: ${props => (props.selected ? '#1890ff' : 'transparent')}; 
        border-radius: 2px 0 0 2px;
    }

    &:hover {
        background-color: ${(props) => props.theme.colors.greySeven};
    }
`;

const CategoryTitle = styled.h3`
    padding: 10px;
    margin: 0;
    font-size: 16px;
`;

export { CategoriesList, CategoryItem, CategoryTitle };
