import {useCallback} from "react";
import { observer } from "mobx-react";

import { Icon } from "../../../../elements";
import {useStore} from "../../../../hooks";
import {
  StepWrapper,
  FormWrapper,
  LogoImageWrapper,
  TitleStep,
  AgentsTeamNameInput
} from "./Components.styled";

const AgentsTeamNameStep = observer(() => {
  const { agentsTeamsStore } = useStore();

  const onHandleChangeName = useCallback((e) => {
    agentsTeamsStore.onChangeName(e.target.value);
  }, [agentsTeamsStore]);

  return (
    <StepWrapper>
        <LogoImageWrapper>
            <Icon name="annIsotype" width="96px" height="75px" />
        </LogoImageWrapper>
      <FormWrapper>
        <TitleStep>Please provide a name for your team.</TitleStep>
        <AgentsTeamNameInput
          value={agentsTeamsStore.name}
          placeholder="Enter team name"
          onChange={onHandleChangeName}
        />
      </FormWrapper>
    </StepWrapper>
  );
});

export default AgentsTeamNameStep;
