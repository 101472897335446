import React, {useEffect, useState} from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks";
import InstructionsBox from "../../AgentPage/PlaygroundPage/components/InstructionsBox";
import {
    FormTitle,
    FormAgentWrapper,
    CustomDiv,
    Label,
    RadioButtonsContainer
} from "../NewAgentPage.styled";
import {CustomRadioButton} from "../../../elements";

const AgentInstructionsForm = observer(() => {
    const { newAgent } = useStore();

    const [includeConcierge, setIncludeConcierge] = useState("yes"); 
    const [usePrompter, setUsePrompter] = useState("no");

    useEffect(() => {
        newAgent.onChangeUsePrompter("no");
    }, [])
  
    const handleIncludeConciergeChange = (value) => { 
        setIncludeConcierge(value); 
        newAgent.onChangeIncludeConcierge(value);
    }; 

    const handleUsePrompterChange = (value) => { 
        setUsePrompter(value); 
        newAgent.onChangeUsePrompter(value);
    };
    
    const includeConciergeChecked = (value) => value == includeConcierge;
    const usePrompterChecked = (value) => value == usePrompter;

    const placeholderText = `What do you want your agent to do?\n\nExample: Before answering questions, always review your knowledge base`;

    const onChangeInstructions = (value) => {
        newAgent.onChangeInstructions(value);
    };

    return (
        <FormAgentWrapper>
            <FormTitle>Instructions</FormTitle>
            <InstructionsBox value={newAgent.instructions} onChange={onChangeInstructions} tooltipAvailable={false} placeholder={placeholderText} width={'40vw'}/>
            <CustomDiv>
                Remember: Users must adhere to applicable laws, avoid compromising others’privacy, 
                and not engage in or promote illegal activities. The guidelines specifically prohibit using
                ANN services to harm oneself or others, to develop or distribute harmful materials, 
                or to bypasss safety measures. ANN services also commit to reporting any detected child sexual 
                abuse material to the appropriate authorities.
            </CustomDiv>

            <Label>Use instruction helper?</Label>
            <RadioButtonsContainer mt="5px">
                <CustomRadioButton name="usePrompter" label="Yes" value="yes" onChange={handleUsePrompterChange} selectedValue={usePrompter} checked={usePrompterChecked('yes')}/>
                <CustomRadioButton name="usePrompter" label="No" value="no" onChange={handleUsePrompterChange} selectedValue={usePrompter} checked={usePrompterChecked('no')}/>
            </RadioButtonsContainer>

            <Label>Include agent in concierge?</Label>
            <RadioButtonsContainer mt="5px">
                <CustomRadioButton name="includeConcierge" label="Yes" value="yes" onChange={handleIncludeConciergeChange} selectedValue={includeConcierge} checked={includeConciergeChecked('yes')}/>
                <CustomRadioButton name="includeConcierge" label="No" value="no" onChange={handleIncludeConciergeChange} selectedValue={includeConcierge} checked={includeConciergeChecked('no')}/>
            </RadioButtonsContainer>

        </FormAgentWrapper>
    );
});

export default AgentInstructionsForm;
