import React from 'react';
import { Button, IconWrapper, StatusDot } from './CustomButton.styled';

const CustomButton = ({ icon: Icon, text, showStatusDot }) => {
  return (
    <Button>
      {showStatusDot && <StatusDot />}
      <IconWrapper>
        {Icon && <Icon />}
      </IconWrapper>
      {text}
    </Button>
  );
};

export default CustomButton;
