import React, { useState } from 'react';
import useStore from "../../../../../hooks/useStore";
import PermissionCard from "./PermissionCard/PermissionCard";
import { observer } from "mobx-react";
import CustomSelectComponent from '../../components/CustomSelectComponent/CustomSelectedGroups';
import PermissionGroup from "./PermissionGroup/PermissionGroup";
import { 
    PermissionsWrapper, 
    PermissionsCardsWrapper,
    ReviewContainer,
    ReviewItemsWrapper,
    ReviewItem,
    ReviewLabel,
    SelectWrapper,
    SelectGroups,
    CustomSelectComponentWrapper,
    AutoMarginSelectGroups
} from "./PermissionsStep/PermissionsStep.styled";

const ReviewStep = observer(() => {
  const { newUser, permissionsSetupStore } = useStore();
  const [selectedGroups, setSelectedGroups] = useState([]);

  const handleAddGroups = (groups) => {
    setSelectedGroups(groups);
  };

  const handleRemoveGroup = (group) => {
    setSelectedGroups(selectedGroups.filter(item => item !== group));
  };

  return (
    <>
      <div>
      <ReviewContainer>
          <ReviewItemsWrapper>
            <ReviewItem>
              <ReviewLabel>Name:</ReviewLabel>
              <span>{newUser.firstName}</span>
            </ReviewItem>
            <ReviewItem>
              <ReviewLabel>Email:</ReviewLabel>
                <span>{newUser.email}</span>
            </ReviewItem>
            <ReviewItem>
              <ReviewLabel>Phone:</ReviewLabel>
              <span>+1 {newUser.phone}</span>
            </ReviewItem>
          </ReviewItemsWrapper>
        </ReviewContainer>
        <SelectWrapper>
          {selectedGroups.length > 0 && (
            <SelectGroups>
              <h3>Groups</h3>
            </SelectGroups>
          )}
          <AutoMarginSelectGroups>
            <label>Include in a group</label>
            <CustomSelectComponentWrapper>
              <CustomSelectComponent onAddGroups={handleAddGroups} />
            </CustomSelectComponentWrapper>
          </AutoMarginSelectGroups>
        </SelectWrapper>
        {selectedGroups.length > 0 && (
          <PermissionGroup groups={selectedGroups} onRemoveGroup={handleRemoveGroup} />
        )}
      </div>

      <PermissionsWrapper>
        <PermissionsCardsWrapper>
          <h3>Review permissions</h3>
          <div>
            <div>
              <PermissionCard data={permissionsSetupStore.permissionsCatalog} />
            </div>
          </div>
        </PermissionsCardsWrapper>
      </PermissionsWrapper>
    </>
  );
});

export default ReviewStep;
