import { useState, useEffect } from "react";
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useStore } from "../../../hooks";
import { RoundedButton, Icon } from "../../../elements";
import { AgentsTabs, ModalFilter } from "./components";

import { dropdownConfigs } from "./consts";

import {
    SearchIcon,
    InputField,
    AgentViewContainer,
    CustomGrid,
    CustomSearchInput,
    StyledIconButton
} from "./AgentView.styled";

const AgentView = observer(() => {
    const navigate = useNavigate();
    const { agentStore, authStore } = useStore();
    const { viewAgentStore } = agentStore;

    const [openFilter, setOpenFilter] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickTuneIcon = (event) => {
        if (openFilter) {
            handleCloseModalFilter();
        } else {
            setAnchorEl(event.currentTarget);
            setOpenFilter(true);
        }
    };

    const handleCloseModalFilter = () => {
        setAnchorEl(null);
        setOpenFilter(false);
    };

    useEffect(() => {
        if (authStore.selectedAccount?.id){
            agentStore.onChangeAccount(authStore.selectedAccount.id);
        }
    }, [authStore.selectedAccount, agentStore]);

    return (
        <AgentViewContainer>
            <CustomGrid>
                <div>
                    <CustomSearchInput width='50px'>
                        <InputField type="text" value={viewAgentStore.searchValue} placeholder="Search" onChange={viewAgentStore.onHandleSearchChange}/>
                        <SearchIcon>
                            <Icon name={"magnifier"} height={'20px'} width={'20px'}></Icon>
                        </SearchIcon>
                    </CustomSearchInput>
                </div>
                {/* <div>
                    <StyledIconButton aria-controls="custom-dropdown" aria-haspopup="true" onClick={handleClickTuneIcon}>
                        <Icon name={"tuneIcon"} height={'15px'} width={'15px'} />
                    </StyledIconButton>

                    <ModalFilter
                        openFilter={openFilter}
                        anchorEl={anchorEl}
                        handleCloseModalFilter={handleCloseModalFilter}
                        dropdownConfigs={dropdownConfigs()}
                        title="Filter"
                        modalWidth="250"
                    />
                </div> */}
                <div>
                    <RoundedButton
                    width={'150px'}
                    onClick={() => {navigate('/v2/assistants/wizard/new-agent')}}>
                        New Agent
                    </RoundedButton>
                </div>
                {/* <div>
                    <RoundedButton
                    width={'150px'}
                    onClick={() => {navigate('/v2/agents-team')}}>
                        New Team
                    </RoundedButton>
                </div> */}
            </CustomGrid>

            <AgentsTabs />

        </AgentViewContainer>
    );
});

export default AgentView;