import styled, { createGlobalStyle } from 'styled-components';

const HeaderButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CustomGenericListStyle = createGlobalStyle`
  #main-content {
    overflow: hidden;
  }
`;

export const TableBodyCell = `
  font-size: 12px;
`;

export const TableHeadCell = styled.div`
  align: center;
`;

export {
  HeaderButtonWrapper,
  CustomGenericListStyle
};