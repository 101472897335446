import React, { useState, useEffect } from 'react';
import {
	SideBar2 as StyledSideBar2,
	Sidebar2HeaderContent,
	Sidebar2HeaderTextContainer,
	Sidebar2AHeaderAgentName,
	Sidebar2StatusContainer,
	Sidebar2AgentStatus,
	Sidebar2Description,
	CircleActive,
	CircleSeparator,
	Sidebar2DescriptionTitle,
	Sidebar2DescriptionText,
	Sidebar2KnowledgeBase,
	Sidebar2ActionContainer,
	Sidebar2KnowledgeBaseBody,
	Sidebar2HistoryContainer,
	Sidebar2HistoryTitle,
	Sidebar2HistoryGroup,
	Sidebar2HistoryWrapper,
	Sidebar2HistoryText,
	Sidebar2HistoryDate,
	DetailsContainer,
	Sidebar2DescriptionContainer,
	SliceButton,
} from './SidebarDetailsHistory.styled';
import CustomizedHomeChatTabs from "./CustomizedHomeChatTabs"

import { useStore } from "../../../../hooks/index";
import { Icon } from "../../../../elements";
import SidebarKnowledgeBaseCard from "./SidebarKnowledgeBaseCard";
import ActionsList from "../../../../elements/v2/ActionsCatalog/ActionsList";
import { observer } from "mobx-react";
import dayjs from 'dayjs'

const SidebarDetailsHistory = observer(React.forwardRef(({ onClick, selectedDefault, activeTab, onTabChange, isMobile, ...props }, ref) => {
	const { historyAgentStore, authStore } = useStore();
	const conversations = historyAgentStore.conversations;
	const [currentActiveTab, setCurrentActiveTab] = useState(activeTab);
	const [isExpanded, setIsExpanded] = useState(false);

	useEffect(() => {
		setCurrentActiveTab(activeTab);
	}, [activeTab]);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 1024) {
				setIsExpanded(true);
			} else {
				setIsExpanded(false);
			}
		};

		window.addEventListener('resize', handleResize);
		handleResize(); // Initial check

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const toggleSidebar = () => {
		setIsExpanded(!isExpanded);
	};

	const handleTabChange = (index) => {
		setCurrentActiveTab(index);
		onTabChange(index);
	};

	const handleConversationClick = (conversationId) => {
		historyAgentStore.fetchMessages(conversationId);
		historyAgentStore.setLastConversationID(conversationId);
	};

	const title = `${selectedDefault.name}`.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase());

	const getGroupedChatHistory = () => {
		const now = new Date();
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		const yesterday = new Date(today);
		yesterday.setDate(today.getDate() - 1);
		const lastWeek = new Date(today);
		lastWeek.setDate(today.getDate() - 7);
		const lastMonth = new Date(today);
		lastMonth.setMonth(today.getMonth() - 1);

		const filteredHistory = conversations ? conversations.filter(item => item.agent_id === selectedDefault.id) : [];

		const grouped = {
			'Today': [],
			'Yesterday': [],
			'7 days ago': [],
			'One month ago': [],
			'More': [],
		};

		filteredHistory.forEach(item => {
			const itemDate = new Date(item.last_modified);
			if (itemDate >= today) {
				grouped['Today'].push(item);
			} else if (itemDate >= yesterday) {
				grouped['Yesterday'].push(item);
			} else if (itemDate >= lastWeek) {
				grouped['7 days ago'].push(item);
			} else if (itemDate >= lastMonth) {
				grouped['One month ago'].push(item);
			} else {
				grouped['More'].push(item);
			}
		});

		// Filter out groups with no conversations
		return Object.entries(grouped).filter(([group, conversations]) => conversations.length > 0);
	};

	const groupedChatHistory = getGroupedChatHistory();

	const tabs = [
		{
			label: 'Details',
			content: (
				<DetailsContainer className={"details-container"}>
					<Sidebar2DescriptionContainer>
						<Sidebar2Description>
							<Sidebar2DescriptionTitle>Description</Sidebar2DescriptionTitle>
						</Sidebar2Description>
						<Sidebar2DescriptionText>
							{selectedDefault.job_title && (selectedDefault.job_title.charAt(0).toUpperCase() + selectedDefault.job_title.slice(1))}
						</Sidebar2DescriptionText>
					</Sidebar2DescriptionContainer>
					
					{selectedDefault.knowledge_base && selectedDefault.knowledge_base.length > 0 && (
						<Sidebar2KnowledgeBase>
							<Sidebar2DescriptionTitle>Knowledge Base</Sidebar2DescriptionTitle>
							<Sidebar2KnowledgeBaseBody>
								{selectedDefault.knowledge_base.map((item) => (
									<SidebarKnowledgeBaseCard key={item} knowledgeBase={[item]} />
								))}
							</Sidebar2KnowledgeBaseBody>
						</Sidebar2KnowledgeBase>
					)}
					
					<Sidebar2ActionContainer className="sidebar2-actions">
						{(
							<ActionsList agentActions={historyAgentStore.actions} onSave={historyAgentStore.onChangeActions} addAction={false}/>
						)}
					</Sidebar2ActionContainer>
				</DetailsContainer>
			)
		},
		{
			label: 'History',
			content: (
				<Sidebar2HistoryContainer>
					{groupedChatHistory && groupedChatHistory.map(([group, conversations]) => (
						<Sidebar2HistoryGroup key={group}>
							<Sidebar2HistoryTitle>{group}</Sidebar2HistoryTitle>
							{conversations.map((conversation, index) => (
								<Sidebar2HistoryWrapper key={index} onClick={() => handleConversationClick(conversation.id)}>
									<Sidebar2HistoryText>{conversation.title}</Sidebar2HistoryText>
									<Sidebar2HistoryDate>{dayjs(conversation.last_modified).format('MM-DD-YYYY HH:mm')}</Sidebar2HistoryDate>
								</Sidebar2HistoryWrapper>
							))}
						</Sidebar2HistoryGroup>
					))}
				</Sidebar2HistoryContainer>
			)
		}
	];

	return (
		<>
			<SliceButton onClick={toggleSidebar}>
				<Icon name={isExpanded ? "collapseSidebar" : "expandSidebar"} height={'20px'} width={'20px'} />
			</SliceButton>
			<StyledSideBar2 ref={ref} {...props} className="sidebar2-styledtabs" isMobile={isMobile} isExpanded={isExpanded}>
				<Sidebar2HeaderContent>
					<Icon name={"userAuthLogo"} height={isMobile ? '32px' : '40px'} width={isMobile ? '32px' : '40px'} />
					<Sidebar2HeaderTextContainer>
						<Sidebar2AHeaderAgentName>{title}</Sidebar2AHeaderAgentName>
						<Sidebar2StatusContainer>
							<Sidebar2AgentStatus>
								<>
									<CircleActive isActive={selectedDefault.active} />
									{"Active"}
								</>
								<>
									<CircleSeparator />
								</>
								<>
									<Icon name="smallBuilding" height={'20px'} width={'20px'} />
									{selectedDefault.scope && (selectedDefault.scope.charAt(0).toUpperCase() + selectedDefault.scope.slice(1))}
								</>
							</Sidebar2AgentStatus>
							<Sidebar2Description></Sidebar2Description>
						</Sidebar2StatusContainer>
					</Sidebar2HeaderTextContainer>
				</Sidebar2HeaderContent>
				<CustomizedHomeChatTabs tabs={tabs} activeTab={currentActiveTab} onToggleTab={handleTabChange} searchActive={false} isMobile={isMobile}/>
			</StyledSideBar2>
		</>
	);
}));

export default SidebarDetailsHistory;