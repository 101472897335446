import { useCallback } from 'react';
import { RadioChoiceWrapper } from './RuleChoice.styled';

const RuleChoice = ({ label, value, onChoice, selected, disable }) => {
  const onHandleClick = useCallback(() => {
    onChoice && onChoice(value);
  }, [onChoice, value]);

  return (
    <RadioChoiceWrapper>
      <input
        checked={selected}
        disabled={disable}
        type='radio'
        value={value}
        onClick={onHandleClick} />
      {label}
    </RadioChoiceWrapper>
  );
};

export default RuleChoice;
