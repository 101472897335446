import { RoundedButton, IconButton } from '../../../../../elements/button';
import { useCallback, useEffect } from 'react';
import { 
  ModalWrapper,
  CloseButtonWrapper,
  ModalContentWrapper
} from '../../../../../elements/v2/modals/Modals.styled';
import { InputWrapper, Subtitle, ActivationScheduleWrapper, ModalActionsWrapper, ModalBoxGray } from './NewConversationModal.styled';
import TextArea from '../../../../../elements/v2/TextArea/TextArea';
import InputText from '../../../../../elements/v2/TextInput/TextInput';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks';
import dayjs from 'dayjs';
import SimpleSelect from '../../../../../elements/v2/Selects/SimpleSelect';
import { useNavigate, createSearchParams } from 'react-router-dom';
import DateTimePicker from '../../../../../elements/v2/DateTimePicker/DateTimePicker';


const NewConversationModal = observer(({ isOpen, setIsOpen, showSourceSelect = true, buttonText = "Create" }) => {
    const { viewConversationStore, authStore } = useStore();
    const navigate = useNavigate();


    useEffect(() => {
        if (buttonText === "Create") {
            viewConversationStore.clearNewModalForm();
        }
        viewConversationStore.getEventsCatalog()
    }, [])

    const onHandleClose = useCallback(() => {
      setIsOpen(false);
    }, [setIsOpen]);

    const onHandleConfirm = useCallback(() => {
        if (buttonText === "Create") {
            const redirectToTrigger = id => navigate({
                pathname: "/v2/conversations/trigger",
                search: createSearchParams({ id }).toString(),
            })
            viewConversationStore.onCreate(authStore.selectedAccount?.id, redirectToTrigger);
        } else {
            viewConversationStore.update();
            setIsOpen(false);
        }
    }, [viewConversationStore, setIsOpen, buttonText]);

    const handleEndDateChange = (value) => {
        if (viewConversationStore.startDate && viewConversationStore.startDate.isSame(value, 'day')) {
            const minEndTime = viewConversationStore.startTime ? viewConversationStore.startTime.add(4, 'hour') : null;
            viewConversationStore.updateFormField('endDate', value);
            viewConversationStore.updateFormField('minEndTime', minEndTime);
        } else {
            viewConversationStore.updateFormField('endDate', value);
            viewConversationStore.updateFormField('minEndTime', null);
        }
    };

    const handleEndTimeChange = (value) => {
        viewConversationStore.updateFormField('endTime', value);
    };

    const minEndTime = viewConversationStore.startDate && viewConversationStore.endDate && viewConversationStore.startDate.isSame(viewConversationStore.endDate, 'day')
        ? (viewConversationStore.startTime ? viewConversationStore.startTime.add(4, 'hour') : null)
        : null;

    return (
      <ModalWrapper isOpen={isOpen} $zIndex={5}>
        <ModalBoxGray width="50%" height="auto">
          <CloseButtonWrapper>
            <IconButton icon="close" width="16px" height="16px" onClick={onHandleClose} />
          </CloseButtonWrapper>
          <ModalContentWrapper>
            {showSourceSelect && viewConversationStore.eventsCatalog.length && (
              <InputWrapper>
                  <Subtitle>Source</Subtitle>
                  <SimpleSelect 
                      placeholder="Select Source" 
                      searchBar={true}
                      hideOnBlur={false}
                      handleSelect={(_, value) => viewConversationStore.updateFormField("event_id", value)}
                      selectedDefault={viewConversationStore.event_id}
                      options={viewConversationStore.eventsCatalog.map(event => ({name: event.name, value: event.id}))}
                  />
              </InputWrapper>
            )}
            <InputWrapper>
                <Subtitle>Event Name</Subtitle>
                <InputText 
                    placeholder="Name" 
                    value={viewConversationStore.eventName} 
                    onChange={e => viewConversationStore.updateFormField("eventName", e.target.value)}
                />
            </InputWrapper>

            <InputWrapper>
                <Subtitle>Description - optional</Subtitle>
                <TextArea 
                    placeholder="Enter a description"
                    height="120px"
                    resize="none"
                    value={viewConversationStore.description} 
                    onChange={e => viewConversationStore.updateFormField("description", e.target.value)}
                />
            </InputWrapper>

           {buttonText.toLowerCase() !== 'create' && <InputWrapper>
                <Subtitle>Activation Schedule</Subtitle>

                <ActivationScheduleWrapper>

                    <DateTimePicker 
                        label="Start Date" 
                        value={viewConversationStore.startDate} 
                        onChange={value => viewConversationStore.updateFormField("startDate", value)}
                        minDate={dayjs()}
                    />
                    <DateTimePicker 
                        label="Start Time" 
                        value={viewConversationStore.startTime} 
                        type="time"
                        onChange={value => viewConversationStore.updateFormField("startTime", value)}
                    />
                    <DateTimePicker 
                        label="End Date - optional" 
                        value={viewConversationStore.endDate} 
                        onChange={handleEndDateChange}
                        minDate={viewConversationStore.startDate ? viewConversationStore.startDate.add(4, 'hour') : dayjs()}
                    />
                    <DateTimePicker 
                        label="End Time - optional" 
                        value={viewConversationStore.endTime} 
                        type="time"
                        onChange={handleEndTimeChange}
                        minTime={minEndTime}
                    />
                </ActivationScheduleWrapper>
            </InputWrapper>}
          </ModalContentWrapper>
          <ModalActionsWrapper>
            <RoundedButton onClick={onHandleConfirm} width="115px" disabled={!viewConversationStore.canCreate() || viewConversationStore.isLoading}>{buttonText}</RoundedButton>
          </ModalActionsWrapper>
        </ModalBoxGray>
      </ModalWrapper>
    );
});

export default NewConversationModal;
