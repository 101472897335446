import styled from "styled-components";

export const ContactsSegmentWrapper = styled.div`
    display: flex;
    gap: 24px;
    height: calc(100vh - 300px);
`;

export const Counter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 142px;
    height: 48px;
    text-transform: capitalize;
    border-radius: 8px;
    padding: 8px 16px;
    background-color: ${(props) => props.theme.colors.highClearGray};
    font-size: 0.8750em;
    margin: 24px 0;

    span:first-child {
        font-weight: 600;
    }
`;

export const SpreadsheetContactsMappingContainer = styled.div`
    display: flex;
    flex-direction: column;
`