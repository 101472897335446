import styled from "styled-components";

export const StyledContainer = styled.div`
  margin-top: 25px;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  height: ${(props) =>
    props.isMobile ? "90vh" : props.isTablet ? "90vh" : "88vh"};
  overflow: hidden;
`;

export const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
`;

export const headCellStyle = {
    backgroundColor: '#F2F2F2',
    fontSize: '12px',
    padding: '10px',
  };
  
  export const bodyCellStyle = {
    fontSize: '12px'
  };
  
  export const toolbarStyle = {
    backgroundColor: '#F2F2F2',
    minHeight: '45px',
  };
  
  export const tableContainerStyle = {
    border: 'none',
    height: '100%',
  };
  
  export const tablePaperStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  };