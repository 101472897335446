import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  useNodesState,
  useEdgesState,
  ConnectionLineType,
} from 'reactflow';
import dagre from 'dagre';
import 'reactflow/dist/style.css';

import AgentTypeModal from '../components/AgentTypeModal.jsx';
import ManagerModal from '../components/ManagerModal.jsx';

import { AgentSelectorContainer, AgentsTreeContainer } from './AgentsTreePage.styled.js';

import DirectorNode from './components/DirectorNode.jsx';
import ManagerNode from './components/ManagerNode.jsx';
import AgentNode from './components/AgentNode.jsx';
import AddNode from './components/AddNode.jsx';
import { observer } from 'mobx-react';
import useStore from '../../../../hooks/useStore.js';
import { toJS } from 'mobx';
import AgentTeamList from '../../AsistantManagement/components/AgentTeamSelector/AgentTeamList.jsx';
import { createSearchParams, useNavigate } from 'react-router-dom';

const nodeTypes = {
  director: DirectorNode,
  manager: ManagerNode,
  agent: AgentNode,
  add: AddNode,
};

const AgentsTreePage = observer(() => {
  const navigate = useNavigate()
  const { agentTreeStore, authStore } = useStore();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [showAgentTypeModal, setShowTypeAgentModal] = useState(false);
  const [showNewManagerModal, setShowNewManagerModal] = useState(false);
  const [showAgentSelector, setShowAgentSelector] = useState(false);

  const [modalXPos, setModalXPos] = useState(0);
  const [modalYPos, setModalYPos] = useState(0);
  const [clickedAddNode, setClickedAddNode] = useState(null);
  const [managerNode, setManagerNode] = useState(undefined);

  /**
   * * useRef to maintain a reference to the dagre graph that persists across renders.
   * * useRef does not cause re-renders when its value changes,
   * * which is more suitable for maintaining a mutable reference that is manipulated directly.
   */
  const dagreGraph = useRef(new dagre.graphlib.Graph()).current;

  useEffect(() => {
    agentTreeStore.setInitialLoad(false)
    setNodes(() => toJS(agentTreeStore.nodes))
    setEdges(() => toJS(agentTreeStore.edges))
    agentTreeStore.setInitialLoad(true)
  }, [])

  useEffect(() => {
    if(authStore.selectedAccount?.id){
      agentTreeStore.getAgents(authStore.selectedAccount.id)
    }
  }, [authStore.selectedAccount])

  const updateTreeStore = () => {
    agentTreeStore.setNodes(nodes)
    agentTreeStore.setEdges(edges)
  }

  const deleteNode = (nodeId) => {
    setNodes(nds => nds.filter(node => ![nodeId, `add${nodeId}`].includes(node.id)))
    setEdges(edgs => edgs.filter(edge => edge.target !== nodeId && edge.source !== nodeId))
  };

  const editNode = (node) => {
    if (node.type === "manager") {
      setManagerNode(node)
      setShowNewManagerModal(true)
    }
  }

  const getLayoutedElements = (direction = 'TB') => {
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ rankdir: direction });

    const nodeWidth = 293;
    const nodeHeight = 66;

    /**
     * *Clear previous nodes and edges
     */
    dagreGraph.nodes().forEach(node => dagreGraph.removeNode(node));
    dagreGraph.edges().forEach(edge => dagreGraph.removeEdge(edge.v, edge.w));

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const nodesWithChildren = edges.filter(edg => !edg.target.startsWith('add')).map(edg => edg.source)
    setNodes((nds) => nds.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).

      const xPosition = node.type === 'add' ? nodeWithPosition.x : nodeWithPosition.x - nodeWidth / 2;
      const yPosition = nodeWithPosition.y - nodeHeight / 2

      node.position = {
        x: xPosition,
        y: yPosition,
      };

      if (node.type === "agent") {
        node.data.onDelete = () => { deleteNode(node.id) }
      }

      if(node.type === 'manager'){
        node.data.onEdit = () => { editNode(node) }
        if(!nodesWithChildren.includes(node.id)){
          node.data.onDelete = () => { deleteNode(node.id) }
        }else{
          node.data.onDelete = null
        }

      }
      return node;
    }));
  };

  const onNodeClick = (event, node) => {
    setModalXPos(event.clientX/2 + 400)
    setModalYPos(event.clientY/2 + 200)
    setClickedAddNode(node)
    if (node.type === "add" && !showNewManagerModal) {
      setShowTypeAgentModal(true)
    }
  }


  const onHandleRadioChange = useCallback((value) => {
    if (value === "manager") {
      setShowTypeAgentModal(false);
      setShowNewManagerModal(true)
    }else{
      setShowTypeAgentModal(false);
      setShowAgentSelector(true);
    }
  }, []);

  const handleOnCloseManagerModal = () => {
    setShowNewManagerModal(false);
    setManagerNode(undefined);
  }

  const nodeObject = (id, type, label, title, description) => ({
    id,
    data: type === 'add' ? null : { label, title, description },
    position: { x: 0, y: 0 },
    type
  })

  const edgeObject = (source, target) => ({
    id: `${source}-${target}`,
    source,
    target,
    type: 'smoothstep'
  })

  const newNode = (type, name, title, description, source) => {
    const newNodeId = `${new Date().getTime()}`;
    const newNodes = [nodeObject(newNodeId, type, name, title, description)];

    const newEdges = [];
    if(source){
      newEdges.push(edgeObject(source, newNodeId))
    }

    if(['manager', 'director'].includes(type)){
      const newAddNodeId = `add${newNodeId}`
      newNodes.push(nodeObject(newAddNodeId, 'add'))
      newEdges.push(edgeObject(newNodeId, newAddNodeId))
    }

    setNodes(nds => [...nds, ...newNodes])
    setEdges(edgs => [...edgs, ...newEdges])
  }

  useEffect(() => {
    if(nodes.length && agentTreeStore.initialLoad){
      getLayoutedElements()
      updateTreeStore();
    }
  }, [nodes.length])

  const handleSaveManager = ({ name, description, title, id }) => {
    const addNodeId = clickedAddNode.id;

    let managerNode;
    if (id) {
      managerNode = nodes.find(node => node.id === id)
      managerNode.data = {
        ...managerNode.data,
        description,
        label: name,
        title: title,
      }
    } else {
      const clickedAddNodeEdge = edges.find((edge) => edge.target === addNodeId)
      const parentNodeId = clickedAddNodeEdge.source;
      newNode('manager', name, title, description, parentNodeId)
    }
  }

  const onChangeAgent = (agentClicked) => {
    const clickedAddNodeEdge = edges.find((edge) => edge.target === clickedAddNode.id)
    const parentNodeId = clickedAddNodeEdge.source;
    const selectedAgent = agentTreeStore.agents.find(agent => agent.id === agentClicked)
    newNode('agent', selectedAgent.name, selectedAgent.title, '', parentNodeId)
    setShowAgentSelector(false);
  };

  const createNewAgent = () => {
    navigate({
      pathname: "/v2/assistants/wizard/new-agent",
      search: createSearchParams({
        team: agentTreeStore.teamID
      }).toString(),
    });
  }

  return (
    <AgentsTreeContainer>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        connectionLineType={ConnectionLineType.SmoothStep}
        fitView
        nodeTypes={nodeTypes}
        onNodeClick={onNodeClick}
        deleteKeyCode={null}
        onClick={() => setShowAgentSelector(false)}
      >
        <Controls />
        <MiniMap />
      </ReactFlow>
      {showAgentTypeModal &&
        <AgentTypeModal
          left={modalXPos}
          top={modalYPos}
          onHandleRadioChange={onHandleRadioChange}
          close={() => setShowTypeAgentModal(false)}
        />
      }
      {showNewManagerModal &&
        <ManagerModal
          left={modalXPos}
          top={modalYPos}
          onClose={handleOnCloseManagerModal}
          onSave={handleSaveManager}
          managerNode={managerNode}
        />
      }
      {showAgentSelector &&
        <AgentSelectorContainer
          $left={modalXPos}
          $top={modalYPos}>
          <AgentTeamList
            isVisible={true}
            items={agentTreeStore.agents.map(a => ({name: a.name, value: a.id}))}
            onSelect={onChangeAgent}
            clickAddNew={createNewAgent}
          />
        </AgentSelectorContainer>
      }
    </AgentsTreeContainer>)
})

export default AgentsTreePage;