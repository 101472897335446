import React, { useState, useEffect } from "react";
import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $getSelection,
} from "lexical";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import "./styles.css";
import EmojisPlugin from "./plugins/EmojisPlugin";
import EmojiPickerPlugin from "./plugins/EmojiPickerPlugin";
import { EmojiNode } from "./node/EmojiNode";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

function Placeholder() {
  return <div className="editor-placeholder"></div>;
}

const ClearTextPlugin = ({ sent }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(() => {
      const root = $getRoot();
      if (root && sent) {
        root.clear();
      }
    });
  }, [editor, sent]);

  return null;
};

const Editor = ({
  onSelectionChange,
  initialText = "",
  onChange,
  sent,
  includeClearTextPlugin = false,
}) => {
  const editorConfig = {
    // The editor theme
    theme: ExampleTheme,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      EmojiNode,
    ],
    editorState: () => {
      const root = $getRoot();
      root.clear();
      const p = $createParagraphNode();
      p.append($createTextNode(initialText));
      root.append(p);
    },
  };

  const [content, setContent] = useState(initialText);

  const handleContentChange = (value) => {
    const editorStateJSON = value.toJSON();
    value.read(() => {
      const root = $getRoot();
      const selection = $getSelection();
    });
    setContent(editorStateJSON);
    if (onChange) {
      onChange(editorStateJSON);
    }
  };

  const handleSelectionChange = (selection) => {
    if (onSelectionChange) {
      onSelectionChange(selection);
    }
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container">
        <ToolbarPlugin />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={
              <ContentEditable className="editor-input" value={content} />
            }
            onSelectionChange={handleSelectionChange}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
            onChange={handleContentChange}
          />
          <br />
          <OnChangePlugin onChange={handleContentChange} />
          <EmojisPlugin />
          <EmojiPickerPlugin />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          {includeClearTextPlugin && <ClearTextPlugin sent={sent} />}
        </div>
      </div>
    </LexicalComposer>
  );
};

export default Editor;
