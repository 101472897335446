import React, {useState} from 'react';
import {observer} from "mobx-react";
import {useStore} from "../../../hooks";
import { RoundedButton, InputText } from '../../../elements';
import { 
    ChatQuestionsWrapper, 
    ChatQuestionsBox, 
    ChatQuestionsBoxDescription, 
    ChatQuestionsSuggestions, 
    Suggestion2, 
    ChatQuestionsBoxTitle, 
    EditAnswerInput, 
    ChatQuestionsInputContainer, 
    ChatQuestionsInputContainerLeftText 
} from "./ChatQuestionsSection.styled";
import { CustomizeQButton } from "../HomeChatPage.styled";

const ChatEditDefaultQuestions = observer(({ goBack }) => {
    const { authStore } = useStore();
    const [displayQuestionInput, setDisplayQuestionInput] = useState('first');
    const [questionInputValue, setQuestionInputValue] = useState('');
    const [displayValidationError, setDisplayValidationError] = useState(false);

    const onClickNextButton = () => {
        if(!questionInputValue){
            return setDisplayValidationError(true);
        }

        setDisplayValidationError(false);
        setQuestionInputValue('');
        setDisplayQuestionInput('second');
    }

    return (
        <ChatQuestionsWrapper>
            <h5> 
                {authStore.isSuperAdmin && 'Super Admin'}
                {authStore.isCustomerAdmin && 'Admin'}
                {!authStore.isCustomerAdmin && !authStore.isSuperAdmin && 'User'}
            </h5>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <CustomizeQButton onClick={() => goBack()}>Back</CustomizeQButton>
                
                    <ChatQuestionsBox $width='618px' $mt='40vh'>

                        {displayQuestionInput === 'first' && <ChatQuestionsInputContainer>
                            <div>
                                <ChatQuestionsBoxTitle>Which question do you want so set as default?</ChatQuestionsBoxTitle >
                                <br/>
                                <ChatQuestionsBoxDescription>Just a heads-up — you can select up to 6 questions as your defaults.</ChatQuestionsBoxDescription>
                            </div>
                            <InputText 
                                displayError={displayValidationError} 
                                validationError="Please type your first Default question to continue."
                                placeholder="Feel free to type your question right here. I'm all ears!"
                                width="420px" 
                                value={questionInputValue} 
                                onChange={(e) => {setQuestionInputValue(e.target.value); setDisplayValidationError(false)}}
                            />
                            <RoundedButton width="380px" onClick={() => onClickNextButton()}>Next</RoundedButton>
                        </ChatQuestionsInputContainer>}

                        {displayQuestionInput === 'second' && <ChatQuestionsInputContainer>
                            <ChatQuestionsInputContainerLeftText><ChatQuestionsBoxDescription>How would you like ANN agent to respond?</ChatQuestionsBoxDescription></ChatQuestionsInputContainerLeftText>
                            <EditAnswerInput placeholder="Write your answer here" rows="4" cols="65" width='420px'/>
                            <RoundedButton width="380px" onClick={() => setDisplayQuestionInput('third')}>Complete</RoundedButton>
                        </ChatQuestionsInputContainer>}

                        {displayQuestionInput === 'third' && <div>
                            <ChatQuestionsBoxDescription>
                                Please Add Your Questions
                            </ChatQuestionsBoxDescription >
                
                            <ChatQuestionsSuggestions>
                                <Suggestion2 key={5} onClick={() => setDisplayQuestionInput('first')} ><span>Add a New Default Question</span></Suggestion2>
                                {[1,2,3,4].map((_, idx) => <Suggestion2 $fontSize="24px" key={idx} onClick={() => setDisplayQuestionInput('first')} ><span>+</span></Suggestion2>)}

                            </ChatQuestionsSuggestions>
                        </div>}
                    </ChatQuestionsBox>
                
            </div>
        </ChatQuestionsWrapper>
    );
});

export default ChatEditDefaultQuestions;
