import React, { useState, useEffect } from "react";
import {
  ContactWrapper,
  CountryCodeInput,
  PhoneNumberInput,
} from "./Contact.Styled";

const ContactNumber = ({ value, onChange }) => {
  const [countryCode, setCountryCode] = useState(value?.slice(0, 2) || "");
  const [phoneNumber, setPhoneNumber] = useState(value?.slice(2) || "");

  useEffect(() => {
    if (typeof value === "string") {
      setCountryCode(value.slice(0, 2));
      setPhoneNumber(value.slice(2));
    }
  }, [value]);

  const handleCountryCodeChange = (event) => {
    const countryCodeValue = event.target.value.slice(0, 2);
    setCountryCode(countryCodeValue);
    
  };

  const handlePhoneNumberChange = (event) => {
    const phoneNumberValue = event.target.value.trim(); 
    if (phoneNumberValue.length <= 9) {
      setPhoneNumber(phoneNumberValue);
    }
  };

  return (
    <ContactWrapper>
      <CountryCodeInput
        type="text"
        value={countryCode}
        onChange={handleCountryCodeChange}
      />
      <PhoneNumberInput
        type="tel"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
      />
    </ContactWrapper>
  );
};

export default ContactNumber;
