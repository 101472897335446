import {action, flow, makeObservable, observable, toJS} from "mobx";
import {API} from "aws-amplify";
import {deleteUser, getPermissionCatalogUser, getRolePermissions, getRoles, getUserPermissions, getUsers, putUser, putUserPermissions, putUserRole} from "../../../graphql/queries";
import { PermissionsScopesIndex } from "../../../pages/v2/UsersPage/NewUserPage/consts";

class UserValidationStore {
  firstName = '';
  lastName = '';
  phone = '';
  roleGroup = '';
  isError = false;
  errorMessage = '';

  constructor() {
    makeObservable(this, {
      firstName: observable,
      lastName: observable,
      phone: observable,
      roleGroup: observable,
      isError: observable,
      errorMessage: observable,
      validateUser: action,
      clearFirstName: action,
      clearLastName: action,
      clearPhone: action,
      clearRoleGroup: action,
      clearValidation: action,
    });
  }

  clearFirstName = () => this.firstName = undefined;
  clearLastName = () => this.lastName = undefined;
  clearPhone = () => this.phone = undefined;
  clearRoleGroup = () => this.roleGroup = undefined;

  clearValidation = () => {
    this.clearFirstName();
    this.clearLastName();
    this.clearPhone();
    this.clearRoleGroup();
  }

  validateRequired = (value) => value !== undefined && !!value.length;

  validateEmail = (value) =>
    value !== undefined &&
    !!value.length &&
    value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  validatePhoneNumber = (value) => /^\+?[0-9]{1,24}$/.test(value);

  validateUser = (user, isAdmin) => {
    this.firstName = !this.validateRequired(user.first_name) ? "First Name is Required" : undefined;
    this.phone = !this.validatePhoneNumber(user.phone) ? "Incorrect Phone Format" : undefined;
    this.email = !this.validateEmail(user.email) ? "Incorrect Email Format" : undefined;
    this.roleGroup = isAdmin && !this.validateRequired(user.role_group) ? "Incorrect User Role Group" : undefined;
  }
}

class ListUserStore {
  users = [];
  status = 'idle';
  row = null;
  showDialogOpenDelete = false;
  validation = new UserValidationStore();
  userData = {};
  changeDetected = false;
  userPermissions = [];
  roles = [];
  rolePermissions = [];
  selectedRole = {};
  combinedPermissions = [];
  permissionsCatalog = [];
  permissionsLoading = false;
  originalUserPermissions = [];

  constructor() {
    makeObservable(this, {
      users: observable,
      status: observable,
      row: observable,
      showDialogOpenDelete: observable,
      validation: observable,
      userData: observable,
      changeDetected: observable,
      userPermissions: observable,
      roles: observable,
      rolePermissions: observable,
      selectedRole: observable,
      permissionsCatalog: observable,
      combinedPermissions: observable,
      permissionsLoading: observable,
      originalUserPermissions: observable,
      setRow: action,
      setError: action,
      updateUserData: action,
      updateDetectedChanges: action,
      onFetchUsers: flow,
      onDeleteUser: flow,
      onPutUser: flow,
      fetchUserData: flow,
      fetchPermissionsCatalogUser: flow,
      fetchUserPermissions: flow,
      fetchRoles: flow,
      fetchRolePermissions: flow,
      selectRole: flow,
      loadAccessTabRequests: flow,
      saveAccess: flow
    });
  }

  setRow(row) {
    this.row = row;
  }

  setShowDialogOpenDelete(value) {
    this.showDialogOpenDelete = value;
  }

  *onFetchUsers(customer) {
    try {
      this.status = "loading";
      const response = yield API.graphql({
        query: getUsers,
        variables: { input: { customer, id: "" } },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      this.users = JSON.parse(response.data?.getUsers?.body);
      this.status = "idle";
    } catch (e) {
      this.status = "error";
    }
  }

  *onDeleteUser(customer, id) {
    try {
      this.status = "loading";
      yield API.graphql({
        query: deleteUser,
        variables: { input: { customer, id } },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      this.onFetchUsers(customer);
      this.status = "idle";
    } catch (e) {
      this.status = "error";
    }finally{
      this.setRow(null);
      this.setShowDialogOpenDelete(false);
    }
  }

  *onPutUser() {
    try {
      this.status = "loading";
      this.userData.metadata = "{}";

      yield API.graphql({
        query: putUser,
        variables: { input: {
            id: this.userData.id,
            first_name: this.userData.first_name,
            last_name: this.userData.last_name,
            email: this.userData.email,
            phone: this.userData.phone,
            customer: this.userData.customer,
            role_group: this.userData.role_group,
            metadata: this.userData.metadata,
          } },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      this.changeDetected = false;

      this.status = "idle";
    } catch (error) {
      this.status = "error";
    }
  }

  *fetchUserData(userId, customerId) {
    try {
      this.status = "loading";
      const response = yield API.graphql({
        query: getUsers,
        variables: { input: { customer: customerId, id: userId } },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      if (response.data && response.data.getUsers) {
        const userData = JSON.parse(response.data.getUsers.body);
        this.userData = userData;
        if(userData.role.id){
          this.selectedRole = {name: userData.role.name, value: userData.role.id}
        }
      }
  
      this.status = "idle";
    } catch (e) {
      this.status = "error";
    }
  }

  setError(errorMessage) {
    this.isError = true;
    this.errorMessage = errorMessage;
  }

  updateUserData(key, value){
    this.changeDetected = true;
    this.userData[key] = value;
  }

  updateDetectedChanges(value){
    this.changeDetected = value;
  }

  *fetchPermissionsCatalogUser(){
    try {
      this.permissionsLoading = true;
      const response = yield API.graphql({
        query: getPermissionCatalogUser,
        variables: { input: {
          customer_id: "",
          user_id: "",
        } },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      this.permissionsCatalog = JSON.parse(response.data.getPermissionCatalogUser.body)
  
    } catch (e) {
      this.status = "error";
    } finally {
      this.permissionsLoading = false
    }
  }

  *fetchUserPermissions(customerId){
    try {
      this.permissionsLoading = true;
      const response = yield API.graphql({
        query: getUserPermissions,
        variables: { 
          input: {
            customer_id: customerId,
            user_id: this.userData.id
          } 
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      const data = JSON.parse(response.data.getUserPermissions.body);
      this.originalUserPermissions = data;
      this.userPermissions = data
      .reduce((acc, item) => {
        acc[item.code] = item;
        return acc;
      }, {});
  
    } catch (e) {
      this.status = "error";
    } finally {
      this.permissionsLoading = false
    }
  }

  *fetchRoles(customerId){
    try {
      this.permissionsLoading = true;
      const response = yield API.graphql({
        query: getRoles,
        variables: { 
          input: {
            customer_id: customerId
          } 
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      const data = JSON.parse(response.data.getRoles.body).map(role => ({name: role.name, value: role.id}));
      this.roles = 	data
      if(!this.selectedRole.value){
        this.selectedRole = this.roles[0];
      }
    } catch (e) {
      this.status = "error";
    } finally {
      this.permissionsLoading = false
    }
  }

  *fetchRolePermissions(customerId){
    try {
      this.permissionsLoading = true;
      const response = yield API.graphql({
        query: getRolePermissions,
        variables: { 
          input: {
            customer_id: customerId,
            role_id: this.selectedRole?.value
          } 
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      this.rolePermissions = JSON.parse(response.data.getRolePermissions.body).reduce((acc, item) => {
        acc[item.code] = item;
        return acc;
      }, {});
    } catch (e) {
      this.status = "error";
    } finally {
      this.permissionsLoading = false
    }
  }

  *selectRole(name, value){
    this.selectedRole = {name, value};
    yield this.fetchRolePermissions(this.userData.customer)
    this.combinedPermissions = [];
    this.loadCombinedPermissions()
  }

  loadCombinedPermissions(){
    // 1. Combine the modules that come from the user and role permissions
    const permissionsSet = Array.from(new Set([...Object.keys(this.userPermissions), ...Object.keys(this.rolePermissions)]))
    for(let module of permissionsSet){
      
      // 2. Checks if a module, e.g. AGENTS_MODULE, is present in both user and selected role permissions
      if(this.userPermissions[module] && this.rolePermissions[module]){

        // 3. Creates set to combine both user and role permissions (VIEW, CREATE, etc.) in that MODULE
        const accessSet = Array.from(new Set([...Object.keys(this.userPermissions[module].permissions), ...Object.keys(this.rolePermissions[module].permissions)]))
        const combinedAccess = accessSet.reduce((acc, item) =>{
          // 4. Gets the access level (own, rooftop, etc.) for each permission in both user and role access
          const roleAccessLevel = this.rolePermissions[module].permissions[item] || 'none';
          const userAccessLevel = this.userPermissions[module].permissions[item] || 'none';
          // 5. Picks the higher access level, doesn't matter if it comes from role or user permission
          acc[item] = PermissionsScopesIndex[roleAccessLevel] > PermissionsScopesIndex[userAccessLevel] ? roleAccessLevel : userAccessLevel
          return acc;
        }, {})

        this.combinedPermissions.push({...this.userPermissions[module], permissions: combinedAccess})
        continue
      }
      // 6. If the module is not present in both, get it either from role or user permission
      this.combinedPermissions.push(this.rolePermissions[module] ? this.rolePermissions[module] : this.userPermissions[module])

    }
  }

  *loadAccessTabRequests(customerId){
    yield this.fetchUserPermissions(customerId)
    yield this.fetchRoles(customerId)
    if(this.selectedRole?.value){
      yield this.fetchRolePermissions(customerId)
    }

    if(this.rolePermissions && this.userPermissions){
      this.loadCombinedPermissions()
    }
  }

  *saveAccess(userPermissions){
    try {
      this.permissionsLoading = true;
      yield API.graphql({
        query: putUserPermissions,
        variables: { 
          input: {
            customer_id: this.userData.customer,
            user_id: this.userData.id,
            permissions: JSON.stringify(userPermissions)
          } 
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      if(this.selectedRole?.value){
        yield API.graphql({
          query: putUserRole,
          variables: { 
            input: {
              customer_id: this.userData.customer,
              user_id: this.userData.id,
              role: JSON.stringify({id: this.selectedRole.value})
            } 
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
      }

    } catch (e) {
      this.status = "error";
    } finally {
      this.permissionsLoading = false
    }
  }

  onClear(){
    this.userData = {};
    this.changeDetected = false;
    this.userPermissions = [];
    this.roles = [];
    this.rolePermissions = [];
    this.selectedRole = {};
    this.combinedPermissions = [];
    this.permissionsCatalog = [];
    this.permissionsLoading = false;
    this.originalUserPermissions = [];
  }


}

export default ListUserStore;