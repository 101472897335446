import styled from "styled-components";

export const ContactWrapper = styled.div`
  position: relative;
  margin-bottom: 100px;
`;

export const InputWrapper = styled.input`
  width: 100%;
  height: 46px;
  margin: 10px 0;
  padding: 0 20px;
  border: 1px solid ${(props) => props.theme.colors.grey};
  font-size: ${(props) => props.theme.fonts.size.small};
  ${(props) => props.theme.fonts.variant.regular}
  color: ${(props) => props.theme.colors.primary};
  box-sizing: border-box;
`;

export const CountryCodeInput = styled(InputWrapper)`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 4rem;
  z-index: 1;
  border-radius: 7px 1px 1px 7px;
`;

export const PhoneNumberInput = styled(InputWrapper)`
  position: absolute;
  left: 60px;
  top: 0px;
  width: 92%;
  border-radius: 1px 7px 7px 1px;
`;