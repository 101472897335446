import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  BellContainer,
  ContentContainer,
  ContentBell,
  RightFunctions,
  NotificationItem,
  NotificationItemContainer,
  NotificationFooter,
  VisualSignaling,
} from "./Notifications.styled";
import Tabs2 from "../Tabs2/Tabs2";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks";
import { NameInitialsAvatar } from 'react-name-initials-avatar';

const tabs = [
  {
    label: "Unread",
    permission: null,
  },
  {
    label: "Read",
    permission: null,
  },
];

const NotificationTab = {
  UnreadTab: 0,
  ReadTab: 1,
};

const ItemStatus = {
  unread: "unread",
  read: "read",
};

const Notifications = observer(() => {
  const { notificationStore } = useStore();
  const [currentTab, setCurrentTab] = useState(NotificationTab.UnreadTab);
  const navigate = useNavigate();

  const onToggleTab = (currentTab) => {
    setCurrentTab(currentTab);
  };

  const handleViewAllNotifications = () => {
    navigate("v2/notifications/");
  };

  return (
    <ContentContainer>
      <ContentBell>
        <Tabs2 tabs={tabs} onToggleTab={onToggleTab} />
        {currentTab === NotificationTab.UnreadTab && (
          <RightFunctions>
            <span>Mark all as read</span>
            <OpenInNewIcon htmlColor="#545454" />
          </RightFunctions>
        )}
      </ContentBell>
      <div>
        <Tabs2.Outlet show={currentTab === NotificationTab.UnreadTab}>
          <NotificationItemContainer>
            {notificationStore.getItemsBellByStatus(ItemStatus.unread).map((notification) => {
              return (
                <Notifications.Item
                  isUnread={true}
                  name={notification.name}
                  description={notification.description}
                  time={notification.time}
                />                
              )
            })}

          </NotificationItemContainer>
        </Tabs2.Outlet>

        <Tabs2.Outlet show={currentTab === NotificationTab.ReadTab}>
        <NotificationItemContainer>
            {notificationStore.getItemsBellByStatus(ItemStatus.read).map((notification) => {
              return (
                <Notifications.Item
                  isUnread={false}
                  name={notification.name}
                  description={notification.description}
                  time={notification.time}
                />                
              )
            })}

          </NotificationItemContainer>
        </Tabs2.Outlet>
      </div>
      <NotificationFooter onClick={handleViewAllNotifications}>View all notifications</NotificationFooter>
    </ContentContainer>
  );
});

Notifications.Item = (props) => {
  const { isUnread, name, description, time } = props;
  return (
    <NotificationItem isUnread={isUnread}>
      <div className="photo">
        <NameInitialsAvatar
          name={name}
          size="38px"
          bgColor="#2173FF"
          textColor="white"
          borderWidth="0"
        />
      </div>
      <div className="description">
        <div className="name">{name}</div>
        <div className="eventDescription">{description}</div>
        <div className="timeAgo">{time}</div>
      </div>
    </NotificationItem>
  );
};

const Bell = (props) => {
  const [showVisualSignaling, setShowVisualSignaling] = useState(false);
  const { isActive, setIsActive } = props;

  useEffect(() => {
    setShowVisualSignaling(true);
  }, []);

  return (
    <BellContainer isActive={isActive} onClick={() => setIsActive(!isActive)}>
      {showVisualSignaling && <VisualSignaling />}
      <NotificationsIcon size="12" color="white" />
    </BellContainer>
  );
};

Notifications.Bell = Bell;

export default Notifications;
