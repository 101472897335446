import styled from 'styled-components';

const TopToolbar = styled.div`
  background: rgb(4,60,76);
  background: linear-gradient(90deg, rgba(4,60,76,1) 35%, rgba(15,23,38,1) 72%, rgba(7,34,74,1) 100%);
  color: ${props => props.theme.colors.white};
  margin-top: 16px;

  .MuiSvgIcon-root {
    color: ${props => props.theme.colors.white};
  }

  .MuiButtonBase-root {
    color: ${props => props.theme.colors.white};
  }
`;


export {
  TopToolbar
};
