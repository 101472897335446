import { InputWrapper, InputContainer } from "./TextInput.styled"

const InputText = ({ type = 'text', placeholder, value, onChange, onBlur, width, height, margin, backgroundColor, disabled, validationError, displayError=false }) => {
    return (
        <InputContainer margin={margin}>
            <InputWrapper
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                width={width}
                backgroundColor={backgroundColor}
                disabled={disabled}
                $error={displayError}
                height={height}
            />
            {displayError &&  <span>{validationError}</span>}
        </InputContainer>
    );
}

export default InputText;
