import styled from "styled-components";

const StatusWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    text-transform: capitalize;
    
    color: ${(props) => props.theme.colors.white};
    border-radius: 4px;
    
    &.active {
        background-color: ${(props) => props.theme.colors.greenPrimary};
    }

    &.finished {
        opacity: 0.5;
        background-color: ${(props) => props.theme.colors.greenPrimary};
    }

    &.draft {
        opacity: 0.5;
        background-color: ${(props) => props.theme.colors.clearGray};
    }

    &.paused {
        background-color: ${(props) => props.theme.colors.blueOne};
        opacity: 0.5;
    }

    &.standby {
        background-color: ${(props) => props.theme.colors.blueOne};
    }
`;

export {
  StatusWrapper
};
