import React from 'react';
import dayjs from 'dayjs';

const FormattedDate = ({ date, format = "YYYY-MM-DD" }) => {
  if (!date) return <span></span>;

  return (
    <span>
      {dayjs(date).format(format)}
    </span>
  );
};

export default FormattedDate;
