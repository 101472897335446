const MediaQuery = {
  flexGrow: 1,
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 35%)',
  '@media (max-width: 1024px)': {
    maxHeight: 'calc(100vh - 35%)',
  },
  '@media (max-width: 768px)': {
    maxHeight: 'calc(100vh - 35%)',
  },
  '@media (max-width: 600px)': {
    maxHeight: 'calc(100vh - 35%)',
  },
  '@media (max-width: 480px)': {
    maxHeight: 'calc(100vh - 36%)',
  },
  '@media (max-width: 360px)': {
    maxHeight: 'calc(100vh - 35%)',
  },
};

export {
  MediaQuery
};
