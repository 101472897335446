import styled from "styled-components";

export const HeaderGarageContainer = styled.div`
    border: 1.3px solid ${(props) => props.theme.colors.silverGrayTwo};
    width: 100%;
    max-width: 355px;
    height: auto;
    border-radius: 10px;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const GarageInfo = styled.div`
    color: ${(props) => props.theme.colors.greyTwo};
    font-size: 14px;
    font-weight: 500;
    margin: 0.5rem 0rem 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    height: 10rem;
`;

export const HeaderGarageInfo = styled(GarageInfo)`
    height: 2rem;
`;

export const ButtonContainer = styled.div`
    display: flex;
    margin-left: 25%;
    align-items: center;
    margin-top: 10%;
`;

export const PrevButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: #D9D9D9;
`;

export const NextButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: #D9D9D9;
`;
