import React, { useEffect, useState } from 'react';
import { CustomSelect } from '../../../elements';
import {
    FileInfo, FileName, FileSize, FileInfoContainer, StyledDiv, FormCampaignWrapper,
    FormTitle, FormSubtitle, FieldsContainer, FieldWrapper, Label, CustomSelectWrapper, ColumnName, StyledItem, XIcon,
    InnerContent, Icon, Text
} from '../ContactFlowPage.Styled';
import CheckIcon from "../../../elements/icon/assets/check.svg";
import CheckGreenIcon from "../../../elements/icon/assets/check_green.svg";
import x_icon from "../../../elements/icon/assets/x_icon.svg";
import { useStore } from "../../../hooks";
import Trash from "../../../elements/icon/assets/trash.svg"
import FileIcon from "../../../elements/icon/assets/file.svg"

const CampaignMapFiles = ({ columnNames, selectedOptions, handleFieldSelect }) => {
    const { contactStore } = useStore();
    const [forceUpdateFlag, setForceUpdateFlag] = useState(false);
    const [selectedFirstName, setSelectedFirstName] = useState("");
    const [selectedLastName, setSelectedLastName] = useState("");
    const [selectedEmail, setSelectedEmail] = useState("");
    const [selectedPhone, setSelectedPhone] = useState("");
    const [selectedZipcode, setSelectedZipcode] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isValidPhone, setIsValidPhone] = useState(false);
    const [isValidZipcode, setIsValidZipcode] = useState(false);


    const handleFileDelete = (fileName) => {
        contactStore.setSelectedFiles(contactStore.selectedFiles.filter(file => file.name !== fileName));
        contactStore.setColumnNames([]);
        contactStore.setColumnContents([]);
        contactStore.setIsUploading(false);
        contactStore.buttonUpload = false;
        contactStore.setShowCustomSelectFirstName(false);
        contactStore.setShowCustomSelectLastName(false);
        contactStore.setShowCustomSelectEmail(false);
        contactStore.setShowCustomSelectPhone(false);
        contactStore.setShowCustomSelectZipCode(false);
        contactStore.setAreAllFieldsDone(false);
        setForceUpdateFlag(prev => !prev);
    };

    const handleSelectFirstName = (option, fieldName) => {
        setSelectedFirstName(fieldName)
        handleFieldSelect(option, fieldName);
        if (option === "First Name") {
            contactStore.setShowCustomSelectFirstName(true);
            setForceUpdateFlag(prev => !prev);
        }
    };

    const handleSelectLastName = (option, fieldName) => {
        setSelectedLastName(fieldName)
        handleFieldSelect(option, fieldName);
        if (option === "Last Name") {
            contactStore.setShowCustomSelectLastName(true);
            setForceUpdateFlag(prev => !prev);
        }
    };

    const handleSelectEmail = (option, fieldName) => {
        setSelectedEmail(fieldName)
        handleFieldSelect(option, fieldName);
        // validation
        emailValidation(contactStore.columnContents[fieldName])
        if (option === "Email") {
            contactStore.setShowCustomSelectEmail(true);
            setForceUpdateFlag(prev => !prev);
        }
    };

    const handleSelectPhone = (option, fieldName) => {
        setSelectedPhone(fieldName)
        handleFieldSelect(option, fieldName);
        phoneValidation(contactStore.columnContents[fieldName])
        if (option === "Phone") { 
            contactStore.setShowCustomSelectPhone(true);
            setForceUpdateFlag(prev => !prev);
        }
    };

    const handleSelectZipCode = (option, fieldName) => {
        setSelectedZipcode(fieldName)
        handleFieldSelect(option, fieldName);
        zipcodeValidation(contactStore.columnContents[fieldName])
        if (option === "Zip Code") {
            contactStore.setShowCustomSelectZipCode(true);
            setForceUpdateFlag(prev => !prev);
        }
    };

    useEffect(() => {
        if (
            contactStore.showCustomSelectFirstName &&
            contactStore.showCustomSelectLastName &&
            isValidEmail &&
            isValidPhone &&
            isValidZipcode
        ) {
            contactStore.setAreAllFieldsDone(true);
        } else {
            contactStore.setAreAllFieldsDone(false);
        }
    }, [contactStore, forceUpdateFlag]);

    const renderItemsWithEllipsis = (items) => {
        return items.map((item, index) => {
            if (index < 4) {
                return (
                    <StyledItem key={index}>{item}</StyledItem>
                );
            } else if (index === 4) {
                return (
                    <StyledItem key={index}>{item.length > 5 ? `${item.slice(0, 5)}...` : item}</StyledItem>
                );
            }
            return null;
        });
    };

    const emailValidation = (items) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        for (let i = 0; i < items.length; i++) {
            if (emailPattern.test(items[i])){
                setIsValidEmail(true)
            }else{
                setIsValidEmail(false)
            }
        }
    };

    const phoneValidation = (items) => {
        const phonePattern = /^\+\d{11}$/;
        for (let i = 0; i < items.length; i++) {
            if (phonePattern.test(items[i])){
                setIsValidPhone(true)
            }else{
                setIsValidPhone(false)
            }
        }
    };

    const zipcodeValidation = (items) => {
        const zipPattern = /^\d{5}$/;
        for (let i = 0; i < items.length; i++) {
            if (zipPattern.test(items[i])){
                setIsValidZipcode(true)
            }else{
                setIsValidZipcode(false)
            }
        }
    };

    return (
        <FormCampaignWrapper>
            <FormTitle>Upload your files!</FormTitle>
            <FormSubtitle>
                Before importing your files, check that everything is in order to be uploaded.
            </FormSubtitle>
            {contactStore.selectedFiles.map((file, index) => (
                <div key={index}>
                    <FileInfoContainer>
                        <XIcon>
                            <img src={Trash} onClick={(e) => handleFileDelete(file.name, e)} />
                        </XIcon>
                        <FileInfo>
                            <img src={FileIcon} alt="File" />
                            <div>
                                <FileName>File uploaded, and renamed "{file.name}"</FileName>
                                <FileSize>{(file.size / 1024).toFixed(2)} KB</FileSize>
                            </div>
                        </FileInfo>
                    </FileInfoContainer>
                </div>
            ))}

            <br />

            <FieldsContainer>
            {!contactStore.showCustomSelectFirstName ? (
                    <FieldWrapper>
                        <ColumnName>
                            <Label>First Name:</Label>
                        </ColumnName>
                        <CustomSelectWrapper>
                            <CustomSelect
                                id="firstName"
                                placeholder="Select field"
                                options={columnNames.map((columnName, index) => ({ name: columnName, value: columnName }))}
                                handleSelect={(option) => handleSelectFirstName("First Name", option)}
                                width={'200px'}
                            />
                        </CustomSelectWrapper>
                    </FieldWrapper>
                ) : (
                    <FieldWrapper>
                        <ColumnName>
                            <Label>First Name</Label>
                        </ColumnName>
                        <CustomSelectWrapper>
                            <CustomSelect
                                id="firstName"
                                placeholder="Select field"
                                options={columnNames.map((columnName, index) => ({ name: columnName, value: columnName }))}
                                handleSelect={(option) => handleSelectFirstName("First Name", option)}
                                width={'200px'}
                            />
                        </CustomSelectWrapper>
                        <StyledDiv>
                            {contactStore.columnContents && contactStore.columnContents[selectedFirstName] && renderItemsWithEllipsis(contactStore.columnContents[selectedFirstName])}
                            <img src={CheckIcon} alt="Check" />
                        </StyledDiv>
                    </FieldWrapper>
                )}
                {!contactStore.showCustomSelectLastName ? (
                    <FieldWrapper>
                        <ColumnName>
                            <Label>Last Name:</Label>
                        </ColumnName>
                        <CustomSelectWrapper>
                            <CustomSelect
                                id="lastName"
                                placeholder="Select field"
                                selectedDefault={selectedOptions["lastName"]}
                                options={columnNames.map((columnName, index) => ({ name: columnName, value: columnName }))}
                                handleSelect={(option) => handleSelectLastName("Last Name", option)}
                                width={'200px'}
                            />
                        </CustomSelectWrapper>
                    </FieldWrapper>
                ) : (
                    <FieldWrapper>
                        <ColumnName>
                            <Label>Last Name:</Label>
                        </ColumnName>
                        <CustomSelectWrapper>
                            <CustomSelect
                                id="lastName"
                                placeholder="Select field"
                                selectedDefault={selectedOptions["lastName"]}
                                options={columnNames.map((columnName, index) => ({ name: columnName, value: columnName }))}
                                handleSelect={(option) => handleSelectLastName("Last Name", option)}
                                width={'200px'}
                            />
                        </CustomSelectWrapper>
                        <StyledDiv>
                            {contactStore.columnContents && contactStore.columnContents[selectedLastName] && renderItemsWithEllipsis(contactStore.columnContents[selectedLastName])}
                            <img src={CheckIcon} alt="Check" />
                        </StyledDiv>
                    </FieldWrapper>
                )}

                {!contactStore.showCustomSelectEmail ? (
                    <FieldWrapper>
                        <ColumnName>
                            <Label>Email:</Label>
                        </ColumnName>
                        <CustomSelectWrapper>
                            <CustomSelect
                                id="email"
                                placeholder="Select field"
                                selectedDefault={selectedOptions["email"]}
                                options={columnNames.map((columnName, index) => ({ name: columnName, value: columnName }))}
                                handleSelect={(option) => handleSelectEmail("Email", option)}
                                width={'200px'}
                            />
                        </CustomSelectWrapper>
                    </FieldWrapper>
                ) : (
                    <FieldWrapper>
                        <ColumnName>
                            <Label>Email:</Label>
                        </ColumnName>
                        <CustomSelectWrapper>
                            <CustomSelect
                                id="email"
                                placeholder="Select field"
                                selectedDefault={selectedOptions["email"]}
                                options={columnNames.map((columnName, index) => ({ name: columnName, value: columnName }))}
                                handleSelect={(option) => handleSelectEmail("Email", option)}
                                width={'200px'}
                            />
                        </CustomSelectWrapper>
                        <StyledDiv>
                            {
                                isValidEmail ?(
                                    <>
                                        {contactStore.columnContents && contactStore.columnContents[selectedEmail] && renderItemsWithEllipsis(contactStore.columnContents[selectedEmail])}
                                        <img src={CheckIcon} alt="Check" />
                                    </>
                                ):(
                                    <>
                                        <p style={{textOverflow: 'ellipsis', maxWidth: '350px', marginLeft: '5px', marginRight: '22px'}}>One or more emails do not match the format example@company.com</p>
                                        <img src={x_icon} alt="Xicon"/>
                                    </>
                                )
                            }
                        </StyledDiv>
                    </FieldWrapper>
                )}

                {!contactStore.showCustomSelectPhone ? (
                    <FieldWrapper>
                        <ColumnName>
                            <Label>Phone:</Label>
                        </ColumnName>
                        <CustomSelectWrapper>
                            <CustomSelect
                                id="phone"
                                placeholder="Select field"
                                selectedDefault={selectedOptions["phone"]}
                                options={columnNames.map((columnName, index) => ({ name: columnName, value: columnName }))}
                                handleSelect={(option) => handleSelectPhone("Phone", option)}
                                width={'200px'}
                            />
                        </CustomSelectWrapper>
                    </FieldWrapper>
                ) : (
                    <FieldWrapper>
                        <ColumnName>
                            <Label>Phone:</Label>
                        </ColumnName>
                        <CustomSelectWrapper>
                            <CustomSelect
                                id="phone"
                                placeholder="Select field"
                                selectedDefault={selectedOptions["phone"]}
                                options={columnNames.map((columnName, index) => ({ name: columnName, value: columnName }))}
                                handleSelect={(option) => handleSelectPhone("Phone", option)}
                                width={'200px'}
                            />
                        </CustomSelectWrapper>
                        <StyledDiv>
                            {
                                isValidPhone ?(
                                    <>
                                        {contactStore.columnContents && contactStore.columnContents[selectedPhone] && renderItemsWithEllipsis(contactStore.columnContents[selectedPhone])}
                                        <img src={CheckIcon} alt="Check" />
                                    </>
                                ):(
                                    <>
                                        <p style={{textOverflow: 'ellipsis', maxWidth: '350px', marginLeft: '5px', marginRight: '22px'}}>One or more phone numbers do not match the format +15555555555</p>
                                        <img src={x_icon} alt="Xicon"/>
                                    </>
                                )
                            }
                        </StyledDiv>
                    </FieldWrapper>
                )}


                {!contactStore.showCustomSelectZipCode ? (
                    <FieldWrapper>
                        <ColumnName>
                            <Label>Zip Code:</Label>
                        </ColumnName>
                        <CustomSelectWrapper>
                            <CustomSelect
                                id="Zip Code"
                                placeholder="Select field"
                                selectedDefault={selectedOptions["Zip Code"]}
                                options={columnNames.map((columnName, index) => {
                                    const optionWidth = columnName.length * 10 + 24;
                                    const adjustedOption = optionWidth > 200 ? `${columnName.slice(0, 15)}...` : columnName;
                                    return { name: adjustedOption, value: columnName };
                                })}
                                handleSelect={(option) => handleSelectZipCode("Zip Code", option)}
                                width={'200px'}
                            />
                        </CustomSelectWrapper>
                    </FieldWrapper>
                ) : (
                    <FieldWrapper>
                        <ColumnName>
                            <Label>Zip Code:</Label>
                        </ColumnName>
                        <CustomSelectWrapper>
                            <CustomSelect
                                id="zipCode"
                                placeholder="Select field"
                                selectedDefault={selectedOptions["zipCode"]}
                                options={columnNames.map((columnName, index) => {
                                    const optionWidth = columnName.length * 10 + 24;
                                    const adjustedOption = optionWidth > 200 ? `${columnName.slice(0, 15)}...` : columnName;
                                    return { name: adjustedOption, value: columnName };
                                })}
                                handleSelect={(option) => handleSelectZipCode("Zip Code", option)}
                                width={'200px'}
                            />
                        </CustomSelectWrapper>
                        <StyledDiv>
                            {
                                isValidZipcode ?(
                                    <>
                                        {contactStore.columnContents && contactStore.columnContents[selectedZipcode] && renderItemsWithEllipsis(contactStore.columnContents[selectedZipcode])}
                                        <img src={CheckIcon} alt="Check" />
                                    </>
                                ):(
                                    <>
                                        <p style={{textOverflow: 'ellipsis', maxWidth: '350px', marginLeft: '5px', marginRight: '22px'}}>Please select a Valid US Zip Code Field Column, not empty, that contains a 5 digit Zip Code</p>
                                        <img src={x_icon} alt="Xicon"/>
                                    </>
                                )
                            }
                        </StyledDiv>
                    </FieldWrapper>
                )}
            </FieldsContainer>
            {contactStore.showCustomSelectFirstName &&
            contactStore.showCustomSelectLastName &&
            isValidEmail &&
            isValidPhone &&
            isValidZipcode && (
                    <InnerContent>
                        <Icon src={CheckGreenIcon} alt="Check" />
                        <Text>0 errors found, of 5 rows scanned.</Text>
                    </InnerContent>
            )}
        </FormCampaignWrapper>
    );
};

export default CampaignMapFiles;
