import { useEffect, useState } from "react";
import { observer } from 'mobx-react';
import {useStore} from "../../../../hooks";
import {
    AppointmentContainer,
    AppointmentDescription,
    UserContainer,
    AppointmentTitle,
    AppointmentFooter,
    AppointmentBody,
    ExpandedAppointment,
    ButtonContainer
    
} from "./ScheduledAppointments.styled";
import { RoundedButton, GenericTextModal } from "../../../../elements";
import OpenInFull from '@mui/icons-material/OpenInFull';
import { IconButton } from "@mui/material";

const ScheduledAppointments = observer(() => {
    const { unifiedCustomerProfileStore } = useStore();
    const [displayDetailsForAppt, setDisplayDetailsForAppt] = useState();
    const [selectedAppt, setSelectedAppt] = useState();
    const [expandMoreDetails, setExpandMoreDetails] = useState(false);

    useEffect(() => {
        if(unifiedCustomerProfileStore.selectedCar){
            unifiedCustomerProfileStore.getScheduledAppointments();
        }

    }, [unifiedCustomerProfileStore.selectedCar])

    useEffect(() => {
        if(displayDetailsForAppt){
            setSelectedAppt(unifiedCustomerProfileStore.scheduledAppointments.find(appt => appt.appointment_no === displayDetailsForAppt))
        }
    }, [displayDetailsForAppt])

    return (
        <UserContainer> 
            {!displayDetailsForAppt && unifiedCustomerProfileStore.scheduledAppointments && unifiedCustomerProfileStore.scheduledAppointments.map(appt => 
                <AppointmentContainer key={appt.appointment_no}>
                    <div>
                        <AppointmentTitle>{appt.appointment_name}</AppointmentTitle>
                        <AppointmentDescription>{unifiedCustomerProfileStore.selectedCar.complete_car_name} <br/> Warranty: {appt.warranty_status ? 'Yes' : 'No'}</AppointmentDescription>
                    </div>
                    <RoundedButton width="89px" height="30px" borderRadius="4.7px" margin="12px 0 0 0" onClick={() => setDisplayDetailsForAppt(appt.appointment_no)}>Details</RoundedButton>
                </AppointmentContainer>
            )}
            {selectedAppt && displayDetailsForAppt && (
                <ExpandedAppointment>
                    <AppointmentContainer>
                            <div>
                                <AppointmentTitle>{selectedAppt.appointment_name}</AppointmentTitle>
                                <AppointmentDescription>{unifiedCustomerProfileStore.selectedCar.complete_car_name}</AppointmentDescription>
                            </div>
                            <RoundedButton width="89px" height="30px" borderRadius="4.7px" onClick={() => setDisplayDetailsForAppt()}>Back</RoundedButton>
                    </AppointmentContainer>
                    <AppointmentBody>{selectedAppt.description}</AppointmentBody>
                    {selectedAppt.description.length >= 240 && <ButtonContainer>
                        <IconButton onClick={() => setExpandMoreDetails(true)}> <OpenInFull/></IconButton>
                    </ButtonContainer>}
                    <AppointmentFooter>Warranty: {selectedAppt.warranty_status ? 'Yes' : 'No'}</AppointmentFooter>
                </ExpandedAppointment>
            )}

            {selectedAppt && displayDetailsForAppt && <GenericTextModal
              isOpen={expandMoreDetails}
              onClose={() => setExpandMoreDetails(false)}
              title={selectedAppt.appointment_name}
              body={selectedAppt.description}
              bodyMaxHeight="62vh"
              width="600px"
              height="65vh"
            />}
        </UserContainer>
    );
});

export default ScheduledAppointments;