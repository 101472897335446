import React from 'react';
import { StepWrapper, HorizontalFormWrapper, HorizontalInputsWrapper, TitleStep } from "./UserComponents.Styled";
import { CardComponent } from "../../../../../elements";
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks';

const UserCardStep = observer(() => {

    const { newUser } = useStore();

    return (
        <StepWrapper>
            {/* The first to CardComponent are intended to not have onClick or selected props */}
            <HorizontalInputsWrapper>
                <CardComponent
                    iconName="userAdmin"
                    title="Make super admin"
                    description="Super admins can manage all users, tools, and settings."
                />
                <CardComponent
                    iconName="mainTemplate"
                    title="Start with a template"
                    description="Copy another user’s permissions or use a suggested set of permissions based on common roles."
                />
                <CardComponent
                    iconName="pencilCard"
                    title="Start from scratch"
                    description="Create permissions specifically for this user."
                    onClick={() => newUser.setPermissionsOption('scratch')}
                    selected={newUser.permissionsOption === 'scratch'}
                />
            </HorizontalInputsWrapper>
        
        </StepWrapper>
    )
});

export default UserCardStep;