import styled from "styled-components";

const StepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const FormWrapper = styled.div`
    width: 462px;
    padding-top: ${(props) => props.top || "0px"};
    padding-bottom: ${(props) => props.bottom || "0px"};
`;

export { StepWrapper, FormWrapper };
