import styled from "styled-components";

export const FormContainer = styled.div`
  
  width: 600px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 14px;
  color: ${props => props.theme.colors.darkCharcoal};
`;

export const Input = styled.input`
  padding: 10px;
  border: 1px solid ${props => props.theme.colors.cerebralGray};
  border-radius: 4px;
  font-size: 14px;
  font-family: inherit;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid ${props => props.theme.colors.cerebralGray};
  border-radius: 4px;
  font-size: 14px;
  
  box-sizing: border-box;
  resize: none;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const TwoColumnRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const DateInput = styled(Input)`
  width: calc(50% - 8px);
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StatusIndicator = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
`;

export const StatusLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.theme.colors.darkCharcoal};
`;
