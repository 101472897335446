import { observer } from "mobx-react";
import { StepWrapper, FormWrapper } from "./Components.styled";
import {SubTitleStep, TitleStep} from "../CreateCustomerStepper.styled";
import {InputText} from "../../../../elements";
import {useStore} from "../../../../hooks";
import {useCallback} from "react";

const CompanyStep = observer(() => {
  const { customer } = useStore();
  const createStore = customer.currentPage;

  const onHandleChangeName = useCallback((e) => {
    createStore.onChangeName(e.target.value);
  }, [createStore]);

  return (
    <StepWrapper>
      <TitleStep>Hello! I'm your ANN agent, and I'm here to guide you through creating a new account.<br />Let's get started!</TitleStep>
      <SubTitleStep>To kick things off on the right note, let's start<br/>with your company name.</SubTitleStep>
      <FormWrapper top="90px" bottom="90px">
        <InputText value={createStore.name} placeholder="Company Name" onChange={onHandleChangeName} />
      </FormWrapper>
    </StepWrapper>
  );
});

export default CompanyStep;
