import styled from "styled-components";

export const Container = styled.div`
  top: 50px;
  position: relative;
  margin-left: 150px;
`;

export const UpdateText = styled.div`
  left: 0px;
  top: 251px;
  position: absolute;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  word-wrap: break-word;
`;

export const ImageContainer = styled.div`
  width: 226px;
  height: 226px;
  left: 16px;
  top: 0px;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: inline-flex;
`;

export const ImageStyle = styled.img`
  width: 226px;
  height: 226px;
  border-radius: 9999px;
  cursor: pointer;
`;

export const HoverImageStyle = styled.img`
  width: 226px;
  height: 226px;
  border-radius: 9999px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
`;

export const Description = styled.div`
  width: 246px;
  left: 16px;
  top: 286px;
  position: absolute;
  font-size: 15.11px;
  font-weight: 400;
  word-wrap: break-word;
`;
