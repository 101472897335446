import styled from "styled-components";
import {Alert} from "@mui/material";

export const CreateAgentsTeamStepperWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
`;

export const ErrorWrapper = styled(Alert)`
    width: 90%;
    box-sizing: border-box;
`;