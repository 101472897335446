import React, {useCallback } from "react";
import ConfirmationDialog from "../../components/common/CommonConfirmationDialog";
import {useStore} from "../../hooks";
import {observer} from "mobx-react";
import UserData from "./components/UserData";

const UserList = observer(() => {
  const { changeTitle, userStore } = useStore();

  changeTitle('Users');
  userStore.setPage('list');

  const { page } = userStore;

  const closeDeleteConfirmModal = useCallback(() => {
    page.setRow(null);
    page.setShowDialogOpenDelete(false);
  }, [page]);

  return (
    <div>
      <UserData />
      <ConfirmationDialog
        open={page.showDialogOpenDelete}
        onClose={closeDeleteConfirmModal}
        message="Are you sure you want to delete this user?"
        onConfirm={() => page.onDeleteUser()}
      />
    </div>
  );
});

export default UserList;


