import {action, makeObservable, observable} from "mobx";

const CustomerPageStrategy = {
  'list': { namePage: 'list'},
}

class AgentsTeamsStore {
  currentPage = undefined;
  name = ''
  description = ''
  isLoading = false
  isError = false
  isDone = false

  constructor() {
    makeObservable(this, {
      currentPage: observable,
      setCurrentPage: action,
      name: observable,
      onChangeName: action,
      description: observable,
      onChangeDescription: action,
      isLoading: observable,
      isDone: observable,
      isError: observable,
    });

    this.setCurrentPage('list');
  }

  onChangeName = (value) => {
    this.name = value;
  };

  onChangeDescription = (value) => {
    this.description = value;
  }

  setCurrentPage(page) {
    this.currentPage = CustomerPageStrategy[page];
  }

  onSubmitData() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
      this.isDone = true;
    }, 3000)
  }
} 

export default AgentsTeamsStore;
