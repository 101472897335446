import styled from 'styled-components';

const MainContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  flex: 1;
  background: ${props => props.theme.colors.highClearGray};
  margin: 40px 0;
  height: 100vh;
  border-radius: 10px;
  padding: 15px;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 15px;
`

const MainCard = styled.div`
  order: 0;
  align-self: stretch;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 8px;
  width: auto;
  height: auto;
  min-width: 170px;
  min-height: 200px;
  flex-direction: column;
  cursor: pointer;
  background:
    repeating-linear-gradient(
      90deg,
      transparent,
      transparent 10px,
      ${props => props.theme.colors.argent} 10px,
      ${props => props.theme.colors.argent} 15px
    ) top left / 100% 1px no-repeat,
    repeating-linear-gradient(
      0deg,
      transparent,
      transparent 10px,
      ${props => props.theme.colors.argent} 10px,
      ${props => props.theme.colors.argent} 15px
    ) top left / 1px 100% no-repeat,
    repeating-linear-gradient(
      90deg,
      transparent,
      transparent 10px,
      ${props => props.theme.colors.argent} 10px,
      ${props => props.theme.colors.argent} 15px
    ) bottom left / 100% 1px no-repeat,
    repeating-linear-gradient(
      0deg,
      transparent,
      transparent 10px,
      ${props => props.theme.colors.argent} 10px,
      ${props => props.theme.colors.argent} 15px
    ) bottom left / 1px 100% no-repeat,
    repeating-linear-gradient(
      90deg,
      transparent,
      transparent 10px,
      ${props => props.theme.colors.argent} 10px,
      ${props => props.theme.colors.argent} 15px
    ) top right / 100% 1px no-repeat,
    repeating-linear-gradient(
      0deg,
      transparent,
      transparent 10px,
      ${props => props.theme.colors.argent} 10px,
      ${props => props.theme.colors.argent} 15px
    ) top right / 1px 100% no-repeat,
    repeating-linear-gradient(
      90deg,
      transparent,
      transparent 10px,
      ${props => props.theme.colors.argent} 10px,
      ${props => props.theme.colors.argent} 15px
    ) bottom right / 100% 1px no-repeat,
    repeating-linear-gradient(
      0deg,
      transparent,
      transparent 10px,
      ${props => props.theme.colors.argent} 10px,
      ${props => props.theme.colors.argent} 15px
    ) bottom right / 1px 100% no-repeat;
`;

const MainCardTitle = styled.p`
  margin:0;
  font-weight: bold;
`

const MainIconContainer = styled.div `
  width: 100%;
  order: 1;
  flex: 0 1 auto;
  align-self: auto;
  display: flex;
  justify-content: center;
`

export {
  MainContainer,
  Content,
  MainCard,
  MainCardTitle,
  MainIconContainer
}

