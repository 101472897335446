import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks';
import { IconButton } from '../../../../../elements';
import {
  StepWrapper,
  StepTitle,
  AIButton,
  InputHeader,
  InputLabel,
  InputWrapper,
  InputArea
} from './AgentInstructionsStep.styled';
import { useCallback } from 'react';

const AgentInstructionsStep = observer(() => {
  const { agentStore } = useStore();
  const { newAgentStore } = agentStore;

  const onChangeInstructions = useCallback((e) => {
    newAgentStore.onChangeInstructions(e.target.value);
  }, [newAgentStore]);

  return (
    <StepWrapper>
      <StepTitle>What do you want this agent to do?</StepTitle>
      <InputHeader>
        <InputLabel>Instructions</InputLabel>
        {/** //Hide temporarily AIButton <AIButton>
          <IconButton icon="aiEditSpark" width="22px" height="22px" />
        </AIButton>*/ }
      </InputHeader>
      <InputWrapper>
        <InputArea value={newAgentStore.instructions} onChange={onChangeInstructions} />
      </InputWrapper>
    </StepWrapper>
  );
});

export default AgentInstructionsStep;
