import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../hooks";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Icon } from "../../../../elements";
import { DividerLine, ActionsListToggleContainer, ActionsListTitle, ActionStyledBox } from "./UserData.styled";
import ActionList from "../components/ActionList/ActionList";
import { ConfirmationModal } from "../../../../elements/v2/modals/";
import DataCards from "../components/DataCards/DataCards";
import {DefaultTheme as theme} from "../../../../theme";
import {Pending} from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';

const UserData = observer(({ searchValue, searchByRole }) => {
  const { listUserStore, authStore } = useStore();
  const { isSuperAdmin, selectedAccount } = authStore;
  const [filteredData, setFilteredData] = useState(listUserStore.users);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [clickedRow, setClickedRow] = useState(null);
  const menuRef = useRef(null);

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [modalIcon, setModalIcon] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [confirmAction, setConfirmAction] = useState(null);

  const [active, setActive] = useState(0);
  const [deactivated, setDeactivated] = useState(0);
  const [pending, setPending] = useState(0)

  const navigate = useNavigate();

  useEffect(() => {
    setActive(filteredData.filter((user) => user.status === 'ENABLED').length);
    setDeactivated(filteredData.filter((user) => user.status === 'DISABLED').length);
    setPending(filteredData.filter((user) => user.status === 'PENDING').length)
  }, [filteredData]);

  useEffect(() => {
    listUserStore.onFetchUsers(selectedAccount.id);
  }, [selectedAccount]);

  useEffect(() => {
    const filtered = listUserStore.users.filter(row =>
      Object.values(row).some(value => value.toString().toLowerCase().includes(searchValue.toLowerCase()))
    );
    setFilteredData(filtered);
  }, [listUserStore.users, searchValue, searchByRole]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "first_name",
        header: "First name",
        muiEditTextFieldProps: {
          type: "text",
          required: true,
          size: 30
        },
      },
      {
        accessorKey: "last_name",
        header: "Last name",
        muiEditTextFieldProps: {
          type: "text",
          required: false,
          size: 30
        },
      },
      {
        accessorKey: "email",
        header: "Email",
        enableEditing: false,
        grow: false,
        size: 30,
      },
      {
        accessorKey: "phone",
        header: "Phone",
        muiEditTextFieldProps: {
          type: "tel",
          required: true,
          size: 30,
        },
      },
      {
        accessorFn: (row) => new Date(row.last_modified),
        accessorKey: "last_modified",
        header: "Last activity",
        enableEditing: false,
        filterVariant: 'date',
        sortingFn: 'datetime',
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        size: 30,
      },
      {
        accessorKey: "customer",
        header: "Account",
        enableEditing: false,
        size: 30,
      },
      {
        accessorKey: "role",
        header: "Role",
        editVariant: "select",
        required: true,
        muiEditTextFieldProps: {
          select: true,
        },
        Cell: ({ cell }) => cell.getValue()?.name || '',
        size: 30,
      },

      {
        accessorFn: (row) => new Date(row.insert_date),
        accessorKey: "insert_date",
        header: "Creation Date",
        grow: false,
        size: 30,
        enableEditing: false,
        filterVariant: 'date',
        sortingFn: 'datetime',
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      }
    ],
    [filteredData]
  );

  const handleSaveUser = useCallback(({ values, table }) => {
    listUserStore.onPutUser(values);
    table.setEditingRow(null);
  }, [listUserStore]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setClickedRow(null);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEditConfirm = () => {
    setIsConfirmationOpen(false);
    if (rowToEdit) {
      table.setEditingRow(rowToEdit);
    }
  };

  const handleEditPermissionsConfirm = () => {
    setIsConfirmationOpen(false);
  };

  const handleArchiveConfirm = () => {
    setIsConfirmationOpen(false);
  };

  const handleDuplicateConfirm = () => {
    setIsConfirmationOpen(false);
  };

  const handleResendConfirm = () => {
    setIsConfirmationOpen(false);
  };

  const handleResendPasswordConfirm = () => {
    setIsConfirmationOpen(false);
  };

  const openConfirmationModal = (title, icon, message, confirmAction) => {
    setModalTitle(title);
    setModalIcon(icon);
    setModalMessage(message);
    setConfirmAction(() => confirmAction);
    setIsConfirmationOpen(true);
    handleClose();
  };

  const handleRowClick = (row) => {
    const userData = row.original;
    console.dir(row.original)
    const { customer, id } = row.original;
    navigate(`/v2/users/overview/${userData.id}`, { state: { customer, id  } });
  };

  const table = useMaterialReactTable({
    columns,
    data: filteredData,
    initialState: { isHidden: { countryCode: true } },
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    enableEditing: true,
    getRowId: (row) => row.id,
    positionActionsColumn: 'last',
    muiToolbarAlertBannerProps: listUserStore.status === "error"
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: theme.colors.highClearGray,
        color: 'black',
        paddingTop: '20px',
        paddingBottom: '20px',
      },
    },
    muiPaginationProps: {
      color: 'primary',
      shape: 'rounded',
      variant: 'outlined',
      showRowsPerPage: true,
      labelRowsPerPage: "Show",
    },
    muiTableHeadRowProps: {
      sx: {
        borderRadius: '8px',
      },
    },
    muiTableHeadCellColumnActionsButtonProps: {
      sx: {
        color: 'black',
      },
    },
    onEditingRowSave: handleSaveUser,
    enableGlobalFilter: false,
    enableColumnResizing: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableHiding: false,
    enableFilters: false,
    enableColumnFilters: false,
    paginationDisplayMode: 'pages',

    renderRowActions: ({ row, table }) => (
      <ActionStyledBox>
        {(hoveredRow === row.id || clickedRow === row.id) && (
          <ActionsListToggleContainer onClick={(event) => {
            handleClick(event);
            setClickedRow(row.id);
          }}>
            <ActionsListTitle>Action</ActionsListTitle>
            <Icon name={'expandMoreOutlinedBlack'} width={'9px'} height={'9px'} margin={'0 12px'} />
          </ActionsListToggleContainer>
        )}

        <ActionList anchorEl={anchorEl} open={open} onClose={handleClose} options={[
          {
            title: 'Edit',
            onClick: () => openConfirmationModal(
              "Edit",
              "edit",
              "Caution: You are about to edit the user. Are you sure you want to proceed?",
              handleEditConfirm
            )
          },
          {
            title: 'Edit permissions',
            onClick: () => openConfirmationModal(
              "Edit Permissions",
              "edit",
              "Caution: You are about to edit the user permissions. Are you sure you want to proceed?",
              handleEditPermissionsConfirm
            )
          },
          {
            title: 'Archive',
            onClick: () => openConfirmationModal(
              "Archive",
              "deleteFilled",
              "Caution: You are about to delete the following user. Are you sure you want to proceed?",
              handleArchiveConfirm
            )
          },
          {
            title: 'Duplicate',
            onClick: () => openConfirmationModal(
              "Duplicate",
              "duplicate",
              "Caution: You are about to duplicate the user below. Are you sure you want to proceed?",
              handleDuplicateConfirm
            )
          },
          {
            title: 'Resend',
            onClick: () => openConfirmationModal(
              "Resend",
              "deleteFilled",
              "Are you sure you want to forward the invitation?",
              handleResendConfirm
            )
          },
          {
            title: 'Reset Password',
            onClick: () => openConfirmationModal(
              "Reset Password",
              "deleteFilled",
              "Did you forget your password? Do you need to reset it?",
              handleResendPasswordConfirm
            )
          }
        ]}
        />
      </ActionStyledBox>
    ),
    state: {
      isLoading: listUserStore.status === "loading",
      isSaving: listUserStore.status === "editing",
      showAlertBanner: listUserStore.status === "error",
      showProgressBars: listUserStore.status === "loading",
    },
    muiTableBodyRowProps: ({ row }) => ({
      onMouseEnter: () => setHoveredRow(row.id),
      onMouseLeave: () => setHoveredRow(null),
      onClick: () => handleRowClick(row),
    }),
  });

  return (
    <>
      <DividerLine></DividerLine>

      <DataCards options={[
        {
          name: "Active",
          value: active,
          subtitle: "",
        },
        {
          name: "Deactivated",
          value: deactivated,
          subtitle: "",
        },
        {
          name: "Pending invite",
          value: pending,
          subtitle: "",
        }
      ]}/>

      <MaterialReactTable
        table={table}
      />

      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={confirmAction}
        title={modalTitle}
        icon={modalIcon}
        message={modalMessage}
      />
    </>
  );
});

export default UserData;
