import styled from "styled-components";

export const PageContainer = styled.div`
    padding: 0 5vw 0 5vw;
    display: grid;
    grid-template-columns: 24% 50% 24%;
    grid-column-gap: 1%;
    grid-row-gap: 0;
    margin-top: 30px;
    height: calc(100% - 50px);
`;

export const PageTile = styled.section`
    display: flex;
    flex-direction: column;
    
`;

export const ProgressBarWrapper = styled.div`
    width: 316px;
    height: 8px;
    background-color: #ddd;
    border-radius: 10px;
    margin-bottom: 10px;
`;


export const Progress = styled.div`
    height: 100%;
    width: ${props => props.progress}%;
    background-color: #717171;
    border-radius: 10px;
`;