import styled from "styled-components";

const ContainerInnerTab = styled.section`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 24px 0px;
  width: 100%;
  height: calc(85vh - 100px);
  overflow: hidden;
}
`;

const LeftColumn = styled.div`
  flex-direction: column;
  width: 50%;
  height: 100%;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.clearGray};
  padding: 24px 24px 24px 16px;
  background-color: ${props => props.theme.colors.silverGrayThree};
`;

const RightColumn = styled.div`
  flex-direction: column;
  border-radius: 8px;
  width: 50%;
  height: 100%;
  overflow-y: auto;
  padding: 10px 10px 10px 10px;
  border: 1px solid ${props => props.theme.colors.clearGray};
  background-color: ${props => props.theme.colors.silverGrayThree};
`;

const Title = styled.p`
  ${(props) => props.theme.fonts.variant.regular()};
  font-size: ${(props) => props.theme.fonts.size.b2};
  font-weight: bold;
`;

const ContentGenericList = styled.div`
  margin-top: 20px;
`;

const Divider = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 1px;
  background-color: ${props => props.theme.colors.clearGray};
  height: 100%;
  position: static;
`;


export {
  ContainerInnerTab,
  LeftColumn,
  RightColumn,
  Title,
  ContentGenericList,
  Divider
};
