import styled, { css } from "styled-components";

const DropDownContainer = styled.div`
    position: relative;
    > button {
        font-weight: 400;
    }
`;

const DropDownList = styled.ul`
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    z-index: 1001;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.colors.clearGray};
    padding: 0;
    margin-top: 5px;
    background: white;
    max-height: 200px;
    overflow-y: auto;
    width: 120%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

const ListItem = styled.li`
    background-color: ${(props) => props.theme.colors.silverGrayThree};
    z-index: 1001;
    list-style: none;
    height: 27px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid ${(props) => props.theme.colors.silverGrayTwo};
    font-size: 0.75rem;
    color: ${(props) => props.theme.colors.greyThirteen};
    padding: 3px 16px;

    &:last-child {
        border: none;
    }
`;

const ListInput = styled.input`
    position: relative;
    padding: 3px 6px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
    outline: none;
    border: 1px solid ${props => props.theme.colors.clearGray};
    color: ${(props) => props.theme.colors.greyThirteen};
    border-radius: 8px;
`;

const DropDownBody = styled.div`
    ${(props) => props.$searchBar && css`
        background-color: ${props => props.theme.colors.highClearGray};
        border-radius: 8px;
        padding: 10px;
        margin-top: 10px;
        z-index: 9998;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
    `}
`;

export {
    DropDownContainer,
    DropDownList,
    ListItem,
    ListInput,
    DropDownBody
};
