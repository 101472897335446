/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onChatAssistantResponse = /* GraphQL */ `
  subscription OnChatAssistantResponse($id: String!) {
    onChatAssistantResponse(id: $id) {
      id
      statusCode
      status
      body
      __typename
    }
  }
`;
