import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 0px 1fr;
  grid-template-rows: ${(props) =>
    props.title ? "50px 50px 1fr 50px" : "50px 0px 1fr 50px"};
  grid-template-areas: "menu top" "menu header" "menu content";
  height: 100svh;
  overflow: hidden;
  font-family: "Inter", sans-serif;
`;

const MenuWrapper = styled.div`
  grid-area: menu;
  overflow-x: hidden;
  width: ${(props) => (props.isCollapsed ? "0px" : "290px")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.1s ease-in-out;
  z-index: 99;
  background: linear-gradient(
    166deg,
    rgba(4, 79, 97, 1) 10%,
    rgba(18, 26, 41, 1) 50%,
    rgba(4, 36, 90, 1) 100%
  );
`;

const Menu = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  width: ${(props) => (props.isCollapsed ? "0px" : "290px")};
  transition: background 0.1s;
  position: relative;
  overflow-y: auto;

  color: ${(props) => props.theme.colors.white};
  gap: ${(props) => (props.isCollapsed ? "0" : "24px")};
  border: none;
  padding: 24px 0;

  ul {
    width: 100%;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    gap: 8px;
  }

  li {
    display: flex;
    box-sizing: border-box;

    a {
      width: 100%;
      display: flex;
      flex-direction: row;
      text-decoration: none;
      color: white;
      padding: 0 15px;

      & .link-menu {
        padding: 0 25px 0 15px;
        line-height: 2;
        ${(props) =>
          props.isMenuCollapsed ? "display: none" : "display: inherit"};
        transition: display 0.1s ease-in-out;
      }
    }
  }
`;

const TopBar = styled.div`
  grid-area: top;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.clearGray};
`;

const HamburguerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const DropdownList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border-radius: 8px;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
`;

const DropdownAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;

  & > ul {
    margin: 0;
  }
`;

const DropdownItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px !important;
  cursor: pointer;
  transition: background-color 0.2s ease;

  span {
    margin-left: 10px;
    margin-right: auto;
    font-size: 14px;
    color: ${(props) => props.theme.colors.white};
  }

  &:hover {
    background: ${(props) => props.theme.colors.celticBlue};
    border-radius: 10px;
  }
`;

const SubMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0px !important;
  list-style-type: none;
  padding-left: 20px;
  background-color: transparent;
`;

const SubMenuItem = styled.li`
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  justify-content: flex-start;
  padding-left: 4rem !important;
  font-size: 0.875rem;
`;

const TopMenuContentWrapper = styled.div`
  display: flex;
`;

const TopMenuContent = styled.div`
  display: flex;
`;

const AccountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const AccountInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const CustomAccountModalBodyListContainerIcon = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.darkGray};
  color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
`;

const AccountName = styled.p`
  margin: 0;
  font-size: 0.875rem;
  color: ${(props) => props.theme.colors.white};
  max-width: 9.375rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CustomAccountModalContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  min-width: 200px;
  min-height: 350px;
  border: 1px solid ${(props) => props.theme.colors.highClearGray};
`;

const CustomAccountModalWrapper = styled.div`
  &#custom-account-mobile-modal > div > div {
    min-width: 250px;
    width: 330px;
    font-size: 0.75rem;
    font-family: "Inter", sans-serif;
  }

  &#custom-account-mobile-modal > div > div > div > ul > li > div {
    padding-right: 0.5rem;
  }

  &#custom-account-mobile-modal
    > div
    > div
    > div
    > ul
    > li
    > div
    > div:nth-child(2) {
    max-width: 7.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  width: 100%;
  justify-content: space-between;
`;

const FooterWrapper = styled.div`
  font-size: 0.625rem;
  display: flex;
  padding: 0 24px 24px 24px;
`;

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px !important;
  width: 100%;
`;

export {
  Wrapper,
  MenuWrapper,
  Menu,
  TopBar,
  HamburguerWrapper,
  DropdownList,
  DropdownAccountContainer,
  DropdownContainer,
  DropdownItem,
  SubMenuList,
  SubMenuItem,
  TopMenuContentWrapper,
  TopMenuContent,
  AccountWrapper,
  CustomAccountModalBodyListContainerIcon,
  AccountName,
  AccountInfoWrapper,
  CustomAccountModalWrapper,
  MenuContainer,
  FooterWrapper,
  MenuContent,
};
