import React, { useState } from "react";
import { Icon } from "../../../../../elements";
import {
  NotificationContainer,
  CustomCheckbox,
  ContentContainer,
  FooterContainer,
  ActionContainer,
  StyledButton,
  CompletedText,
  StyledLink,
  StyledMessage,
  DateText,
  Header,
} from "./NotificationItem.styled";
import { getChannelIconAndText } from "../GetChannel/GetCahnnel";

const NotificationItem = ({
  title,
  message,
  date,
  isCompleted,
  channel,
  status,
  onMarkAsCompleted,
  selected,
  onSelect,
}) => {
  const [expanded, setExpanded] = useState(false);
  const isUnread = status === "unread";

  const handleExpandClick = () => setExpanded(!expanded);

  return (
    <NotificationContainer>
      <CustomCheckbox checked={selected} onChange={onSelect} />
      <ContentContainer>
        <Header isUnread={isUnread}>
          {title}
        </Header>
        <StyledMessage 
          isUnread={isUnread} 
          expanded={expanded}
        >
          {message}
        </StyledMessage>
        <FooterContainer>
          {isCompleted ? (
            <CompletedText>Completed</CompletedText>
          ) : (
            <StyledButton onClick={onMarkAsCompleted}>
              Mark as completed
            </StyledButton>
          )}
          <ActionContainer>
            {getChannelIconAndText(channel)}
            <DateText>{date}</DateText>
            <StyledLink href="#" onClick={handleExpandClick}>
              {expanded ? "Hide details" : "View details"}{" "}
              <Icon name={"arrowRightBlue"} width={"20px"} />
            </StyledLink>
          </ActionContainer>
        </FooterContainer>
      </ContentContainer>
    </NotificationContainer>
  );
};

export default NotificationItem;
