import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

import { AddNodeContainer, PlusIcon } from './AddNode.styled';

export default memo(({ onClick }) => {
  return (
    <AddNodeContainer>
        <PlusIcon name="plus"
          width={"8px"}
          height={"8px"}
          onClick={onClick}
        />
        <Handle
          type="target"
          position={Position.Top}
          id="add-node"
          isConnectable={false}
        />
    </AddNodeContainer>
  );
});
