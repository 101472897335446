import styled from 'styled-components';

const FilesWrapper = styled.div`
    max-height: 150px;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: scroll;
    width: 28vw;
`;

const FilesListContainer = styled.ul`
    padding: 0;
    list-style: none;
`;

const FilesListItem = styled.li`
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.colors.greySeven};
    margin-bottom: 5px;
`;

const FilesTextWrapper = styled.div`
    width: 85%;
    display: flex;
`;

const FilesTextBold = styled.span`
    font-weight: 500;
`;

const FilesName = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 75%;
    margin: 0 5px;
`;

export {
    FilesWrapper,
    FilesListContainer,
    FilesListItem,
    FilesTextWrapper,
    FilesTextBold,
    FilesName
}