import styled from "styled-components";

const IconWrapper = styled.span`
    cursor: pointer;
    border: 2px solid ${props => props.theme.colors.clearGray};
    border-radius: 8px;
    gap: 10px;
    min-height: 36px;
    min-width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export {
    IconWrapper
}