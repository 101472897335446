import React, { useState } from "react";
import {
  Container,
  UpdateText,
  ImageContainer,
  ImageStyle,
  Description
} from "./ProfilePicture.Styled";
import defaultImage from "../assets/Ellipse 6.png";
import ProfileHover from "../icon/assets/profile_hover.svg"

const ProfilePicture = ({ onChange }) => {
  const [selectedImage, setSelectedImage] = useState(defaultImage);
  const [isHovered, setIsHovered] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setSelectedImage(reader.result);
      onChange(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <Container>
      <UpdateText>Update Your Display Photo</UpdateText>
      <ImageContainer>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          id="fileInput"
        />
        <label htmlFor="fileInput">
          <ImageStyle 
            src={isHovered ? ProfileHover : selectedImage} 
            alt="Profile" 
            className="ImageStyle" 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
        </label>
      </ImageContainer>
      <Description>Your accounts will view this photo</Description>
    </Container>
  );
};

export default ProfilePicture;
