import React, { useState, useEffect, useCallback } from "react";

import { observer } from "mobx-react";
import { toJS } from "mobx";
import { useNavigate, useSearchParams } from "react-router-dom";
import { format } from "date-fns";

import {
  EditAgentWrapper,
  AgentIcon,
  CheckboxContainer,
  EditMessageInput,
  Label,
  FormButtonsContainer,
  PageWrapper,
  RadioButtonsContainer,
} from "./EditAgentPage.styled";
import EthanHumanoidImage from "../../../elements/icon/assets/ethan_humanoid.png";
import {
  InputText,
  CustomSelect,
  CustomCheckbox,
  FilesList,
  RoundedButton,
  SuccessfulModal,
  CustomRadioButton
} from "../../../elements";
import { assistantListFileRoute, extensions } from "../../NewAgentPage/components/consts";
import DragAndDropAssistant from "../../../elements/dragAndDrop/DragAndDropAssistant";
import { useStore } from "../../../hooks";
import {
  initialTones,
  initialChannels,
  agentType,
  answerType,
  initialActions,
} from "./consts";
import AdmirationSign from "../../../elements/icon/assets/admiration_sign.svg";
import ConfirmationDialog from "../../../components/common/CommonConfirmationDialog";
import AgentCompletedModal from "../../NewAgentPage/components/AgentCompletedModal";
import AgentActions from "../components/AgentActions/AgentActions"

const EditAgentPage = observer(() => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { editAgent, authStore } = useStore();

  const [ 
    includeConcierge, 
    setIncludeConcierge, 
  ] = useState(""); 

  const handleIncludeConciergeChange = (
    value
  ) => {
    setIncludeConcierge(value);
    editAgent.onChangeIncludeConcierge(value);
  }; 

  useEffect(() => {
    const concierge = editAgent.agents.find(agent => agent.domain === 'concierge');
  
    if (concierge) {
      const match = concierge.human_instructions.match(/\[.*?\]/s);
      if (match) {
        try {
          const agentsInConcierge = JSON.parse(match[0]);
          const isIncluded = agentsInConcierge.some(agent => agent.id.split("#")[1] === editAgent.id);
          handleIncludeConciergeChange(isIncluded ? "yes" : "no");
        } catch (error) {
          handleIncludeConciergeChange("no");
        }
      } else {
        handleIncludeConciergeChange("no");
      }
    }
  }, [editAgent.name])

  const includeConciergeChecked = (value) => value == includeConcierge;

  const [toneOptions, setToneOptions] = useState(initialTones);
  const [channelOptions, setChannelOptions] = useState(initialChannels);
  const [actions, setActions] = useState(initialActions)

  const [displayAgentTypeConfirmationModal, setDisplayAgentTypeConfirmationModal] = useState(false);
  const [agentTypeConfirmationMessage, setAgentTypeConfirmationMessage] = useState('');
  const [preselectedAgentType, setPreselectedAgentType] = useState('');
  const [isDuplicating, setIsDuplicating] = useState(true);

  const selectsWidth = '27.5vw';
  const inputsWidth = '17vw';

  useEffect(() => {
    editAgent.clear();

    const customer = searchParams.get('customer') || authStore.customerId;
    const agentId = searchParams.get('agent');

    if(!agentId) {
      navigate('/assistants')
    }
    
    editAgent.onChangeAccount(customer);
    editAgent.getAgents(customer, agentId);
  }, []);

  useEffect(() => {
    if(toJS(editAgent.channels)){
      const savedChannels = {...initialChannels};
      toJS(editAgent.channels).map(channel => {
          if(channel in savedChannels){
              savedChannels[channel] = true
          }
      })

      setChannelOptions({...savedChannels})
    }
  }, [editAgent.channels.length])

  useEffect(() => {
    if(toJS(editAgent.tones)){
      const savedTones = {...initialTones};
      toJS(editAgent.tones)?.map(tone => {
          if(tone in savedTones){
              savedTones[tone] = true
          }
      })
      setToneOptions({...savedTones})
    }
    
  }, [editAgent.tones.length])

  useEffect(() => {
    if(toJS(editAgent.actions)){
      const savedActions = {...initialActions};
      toJS(editAgent.actions)?.forEach(action => {
        if (action in savedActions) {
          savedActions[action] = true;
        }
      })
      setActions({...savedActions})
    }
  }, [editAgent.actions.length, editAgent.actions])

  useEffect(() => {
    const currentName = editAgent.name;
    if (isDuplicating && !currentName.startsWith('copy - ')) {
      editAgent.onChangeName(`copy - ${currentName}`);
      editAgent.isDuplicating = true;
    }
  }, [editAgent.name, isDuplicating]);

  const onSelectTone = (tone) => {
    const updatedOptions = {...toneOptions, ...tone}
    setToneOptions(updatedOptions)

    const filteredTones = Object.keys(updatedOptions).filter(tone => updatedOptions[tone]);
    if(filteredTones){
        editAgent.onChangeTones(filteredTones);
    }
  }

  const onSelectChannel = (channel) => {
    const updatedOptions = {...channelOptions, ...channel}
    setChannelOptions(updatedOptions)

    const filteredChannels = Object.keys(updatedOptions).filter(channel => updatedOptions[channel]);
    if(filteredChannels){
        editAgent.onChangeChannels(filteredChannels);
    }
  }

  const onHandleChangeName = useCallback((e) => {
      editAgent.onChangeName(e.target.value);
  }, [editAgent]);

  const onHandleChangeInstructions = useCallback((e) => {
    editAgent.onChangeInstructions(e.target.value);
  }, [editAgent]);

  const onHandleChangeAnswerExtension = useCallback((value) => {
    editAgent.onChangeAnswerExtension(value);
  }, [editAgent]);

  const onHandleChangeTitle = useCallback((e) => {
    editAgent.onChangeTitle(e.target.value);
  }, [editAgent]);

  const onChangeAgentType = (name, value) => {
    setDisplayAgentTypeConfirmationModal(true);
    setPreselectedAgentType(value);
    setAgentTypeConfirmationMessage(`Are you sure to change the user Agent type? Remember that this agent will now be ${name}, instead of ${editAgent.type}`);
  };

  const onSelectAction = (action) => {
    const newActions = { ...actions, [action]: !actions[action] }
    setActions(newActions)
    const activatedActions = Object.keys(newActions).filter(action => newActions[action]);
    editAgent.onChangeActions(activatedActions);
  }

  return (
    <PageWrapper>
      <EditAgentWrapper>
        <section>

          <div style={{ display: 'flex', gap: '1vw'}}>
            <AgentIcon src={EthanHumanoidImage}/>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <InputText placeholder="Agent name" width={inputsWidth} margin="3px 0" value={editAgent.name} onChange={onHandleChangeName}/>
              <InputText 
                placeholder="Agent title" 
                width={inputsWidth} 
                margin="3px 0 6px 0" 
                value={editAgent.title}
                onChange={onHandleChangeTitle}
              />
              <Label>Creation Date: {editAgent.createdDate ? format(new Date(editAgent.createdDate), 'MM/dd, hh:mm a') :''}</Label>
            </div>
          </div>

          <div>
            <CustomSelect 
              placeholder="Agent type" 
              width={selectsWidth} 
              margin='0 0 15px 0' 
              options={agentType} 
              handleSelect={onChangeAgentType} 
              selectedDefault={editAgent.type} 
              preventSelection={true}
              textTransform={'capitalize'}
            />

            <div>
              <Label>Agent's tone</Label>
              <CheckboxContainer>
                {toneOptions && 
                  Object.keys(toneOptions).map(option => 
                    <CustomCheckbox 
                    key={option} 
                    label={option} 
                    onSelectTone={onSelectTone} 
                    options={toneOptions} 
                    width="13vw" 
                    />
                  )
                }
              </CheckboxContainer>
            </div>

            <CustomSelect 
              placeholder="Answer's type" 
              width={selectsWidth} 
              options={answerType}
              selectedDefault={editAgent.answerExtension}
              handleSelect={onHandleChangeAnswerExtension}
              textTransform={'capitalize'}
            />
          </div>

        </section>

        <section>

          <div>
            <Label>Channel</Label>
            <CheckboxContainer>
              {channelOptions && 
              Object.keys(channelOptions).map(option => 
                <CustomCheckbox 
                  key={option} label={option} 
                  onSelectTone={onSelectChannel} 
                  options={channelOptions}
                  textTransform='uppercase'
                  width="13vw" 
                  fontSize='15px'
                  disabled={(option !== 'sms' && channelOptions['sms']) || 
                  (option === 'sms' && Object.keys(channelOptions).filter(channel => channel !== 'sms' && channelOptions[channel]).length)}
              />)}
            </CheckboxContainer>
          </div>

          <div>
              <Label margin='0'>Files</Label>
              <FilesList files={toJS(editAgent.files)} onClickRemove={row => editAgent.displayDeleteFileDialog(row)}/>   
          </div>

          <DragAndDropAssistant
            fileRoute={assistantListFileRoute}
            customer={editAgent.account}
            extensions={extensions}
            onFilesChange={editAgent.onChangeFiles}
            onError={editAgent.onSetError}
            width='28vw'
          />
                      
        </section>

        <section>
          <Label margin="0 0 5px 0">Instructions</Label>
          <EditMessageInput placeholder='Feel free to enter the correct answer right here.' rows="8" cols="51" value={editAgent.instructions} onChange={onHandleChangeInstructions}></EditMessageInput>
          <Label fontSize='9px' margin='5px 0 30px 0'>Remeber: <br/>
            Users must adhere to applicable laws, avoid compromising others'privacy, and not engage in or promote illegal activities. The guidelines specifically prohibit using ANN services to harm oneself or others, to develop or distribute harmful materials, or to bypasss safety measures. ANN services also commit to reporting any detected child sexual abuse material to the appropriate authorities.
          </Label>

          {!editAgent.isConcierge &&
            <>
              <Label margin="0 0 5px 0">Include agent in concierge?</Label>
              <RadioButtonsContainer>
                <CustomRadioButton name="includeConcierge" label="Yes" value="yes" onChange={handleIncludeConciergeChange} selectedValue={includeConcierge} checked={includeConciergeChecked('yes')}/>
                <CustomRadioButton name="includeConcierge" label="No" value="no" onChange={handleIncludeConciergeChange} selectedValue={includeConcierge} checked={includeConciergeChecked('no')}/>
              </RadioButtonsContainer>
            </>
          }
          <>
            <Label margin="10px 0">Actions:</Label>
            <AgentActions actionsValues={actions} onSelectAction={onSelectAction} />
          </>
        </section>
      </EditAgentWrapper>

      <FormButtonsContainer>
            <RoundedButton kind={'secondary'} onClick={() => {editAgent.clear(); navigate('/assistants')}}>Cancel</RoundedButton>
            <RoundedButton disabled={editAgent.isLoading} kind={'primary'} onClick={() => editAgent.updateAgent()}>
             {editAgent.isLoading ? 'Loading...' : isDuplicating ? 'Create Agent' : 'Update Agent'} 
            </RoundedButton>
        </FormButtonsContainer>

      {editAgent.isError && <SuccessfulModal
          isOpen={editAgent.isError}
          onClose={() => editAgent.onClearError()}
          imageSrc={AdmirationSign}
          title="Oops!"
          subtitle="An error occurred."
          subtitle2="Please try again later."
      />}

      <ConfirmationDialog
        open={editAgent.deleteFile}
        onClose={() => editAgent.displayDeleteFileDialog(false)}
        message="Are you sure you want to delete this file from this assistant?"
        onConfirm={() => editAgent.confirmRemoveFile()}
        maxWidth="xl"
        fullWidth={false}
      />

      <ConfirmationDialog
        open={displayAgentTypeConfirmationModal}
        onClose={() => setDisplayAgentTypeConfirmationModal(false)}
        message={agentTypeConfirmationMessage}
        onConfirm={() => {editAgent.onChangeType(preselectedAgentType); setDisplayAgentTypeConfirmationModal(false)}}
        maxWidth="sm"
        fullWidth={false}
      />

      {editAgent.updatedAgentSuccessfully && !isDuplicating && <AgentCompletedModal description="Agent has been successfully edited." redirectToAssistants={false} agentid={editAgent.id} customerid={editAgent.account} displayFooter={false}/>}

      {editAgent.updatedAgentSuccessfully && isDuplicating && <AgentCompletedModal description="Agent has been successfully duplicated." redirectToAssistants={true} displayFooter={false}/>}
      
    </PageWrapper>
  );
});

export default EditAgentPage;
