import styled from "styled-components";

const StatusContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    margin-top: 2.5rem
`

const StatusCards = styled.div`
    flex: 1;
    background: ${props => props.theme.colors.primaryWhite};
    border-radius: 5px;
    padding: 17px 10px 6px 10px;
    min-height: 120px;
`

const StatusTitleCards = styled.div`
    font-weight: bold;
    margin: 0;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
`

const StatusContentCards = styled.h2`
    font-weight: bold;
    text-align: center;
    margin: 0;
`

const StatusSubTitle = styled.div`
    margin: 0;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    color: ${props => props.theme.colors.activeBlue};
    font-size: 0.875em;
`

export {
  StatusContainer,
  StatusCards,
  StatusTitleCards,
  StatusContentCards,
  StatusSubTitle
}