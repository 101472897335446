import React, { useState } from "react";
import Tabs from "../../../elements/v2/Tabs/Tabs";
import NotificationTab from "./NotificationTab/NotificationTab";
import GenericSelect from "../../../elements/v2/Selects/MultiSelectDropdown/MultiSelectDropdown";
import { useStore } from "../../../hooks";
import { RightAlignedDiv, PaddedContainer } from "./NotificationView.styled";
import { statusOptions } from "../../../elements/v2/StatusOptions/statusOptions";
import AlertTab from "./AlertTab/AlertTab";
import { alertStatusOptions } from "../../../elements/v2/StatusOptions/alertStatusOptions/alertStatusOptions";

const NotificationPage = () => {
  const { changeTitle } = useStore();
  changeTitle("Notifications");

  const [alertFilters, setAlertFilters] = useState([]);
  const [notificationFilters, setNotificationFilters] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      label: "Alert",
      content: <AlertTab filters={alertFilters} />,
    },
    {
      label: "Notifications",
      content: <NotificationTab filters={notificationFilters} />,
    },
  ];

  const handleApplyFiltersNotifications = (selectedOptions) => {
    setNotificationFilters(selectedOptions.map((option) => option.value));
  };

  const handleApplyFiltersAlerts = (selectedOptions) => {
    setAlertFilters(selectedOptions.map((option) => option.value));
  };

  const handleToggleTab = (index) => {
    setActiveTab(index);
  };

  return (
    <PaddedContainer>
      <Tabs tabs={tabs} onToggleTab={handleToggleTab} setTab={activeTab}>
        <RightAlignedDiv>
          {activeTab === 0 && (
            <GenericSelect
              options={alertStatusOptions}
              onApply={handleApplyFiltersAlerts}
            />
          )}
          {activeTab === 1 && (
            <GenericSelect
              options={statusOptions}
              onApply={handleApplyFiltersNotifications}
            />
          )}
        </RightAlignedDiv>
      </Tabs>
    </PaddedContainer>
  );
};

export default NotificationPage;
