import { Route, Routes } from "react-router-dom";
import HomePage from "./HomePage";

const HomePageV2 = () => {
    return (
        <Routes>
            <Route index element={<HomePage />} />
        </Routes>
    );
};

export default HomePageV2;
