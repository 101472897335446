import styled from 'styled-components';
// TODO: remove material dependency and instead create our custom components
import { Avatar, Paper } from '@mui/material';


const ChatWindowAvatar = styled(Avatar)`
    margin-right: 12px;
`;

const MessageContainer = styled.div`
    margin-bottom: 12px;
    display: flex;
    width: ${props => props.width || '60%'};
`;

const MessageWrapper = styled.div`
    padding: 12px;
    border-radius: 12px;
    width: 100%;
    background-color: ${props => props.itemType == 'sent' ? props.theme.colors.greySix : 'transparent'};
    color: ${props => props.theme.colors.black };
    margin-bottom: 5px;

    > * {
        margin: 0;
    }
`;

const MessageButtonsWrapper = styled.div`
    display: flex;
    gap: 10px;
    margin-top: ${props => props.mt ||'20px'};
    align-items: center;

    a {
        cursor: pointer;
    }
`;

const EditMessageWrapper = styled.div`
    padding: 12px;
    border-radius: 12px;
    width: 100%;
    color: ${props => props.theme.colors.black};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    > * {
        margin: 0;
    }
`;

const EditMessageText = styled.div`
    ${props => props.theme.fonts.variant.light};
    font-size: ${props => props.theme.fonts.size.medium};
    color: ${props => props.theme.colors.black};
    text-align: center;
`;

const EditMessageInput = styled.textarea`
    resize: none;
    padding: 5px 10px;
    border-radius: 10px;

    &:focus {
        outline: none;
    }
`
const EditMessageInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    color: ${props => props.theme.colors.black};
`;

export {
  ChatWindowAvatar,
  MessageContainer,
  MessageWrapper,
  MessageButtonsWrapper,
  EditMessageWrapper,
  EditMessageText,
  EditMessageInput,
  EditMessageInputWrapper
}
