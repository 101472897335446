import styled from "styled-components";

const CardContainer = styled.div`
	height: 59px;
	padding: 8px 16px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	gap: 10px;	
	background: ${props => props.theme.colors.white};
	margin-bottom:	10px;
	margin-right: 8px;
	border-radius: 8px;
`

const CardContainerFileLogo = styled.div`
	order: 0;
	flex: 0 1 auto;
	align-self: center;
`

const CardContainerInfo = styled.div`
	order: 0;
	flex: 0 1 auto;
	align-self: center;
	display: flex;
	flex-direction: column;
	
`

const CardContainerInfoTitle = styled.p`
	margin:0;
	font-size: 0.875rem;
	color: ${props => props.theme.colors.black};
	width: 220px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

const CardContainerInfoDescription = styled.p`
	margin:0;
	color: ${props => props.theme.colors.darkBlueGray};
	font-size: 0.625rem;
`

export {
	CardContainer,
	CardContainerFileLogo,
	CardContainerInfo,
	CardContainerInfoTitle,
	CardContainerInfoDescription
}