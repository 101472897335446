import React from 'react';
import { Icon } from "../../../../../elements";

const CheckIcon = ({ value }) => (
  <span>
    <Icon
      name={value ? "close" : "check"}
      width="10px"
      height="10px"
    />
  </span>
);

export default CheckIcon;
