import styled from "styled-components";

const TeamTagContainer = styled.div`
    margin-top: 10px;
    display: flex;
    gap: 15px;
`;

const TeamTag = styled.div`
    display: flex;
    border-radius: 30px;
    padding: 6px;
    border: 1px solid ${props => props.theme.colors.clearGray};
    gap: 8px;
    font-size: ${props => props.theme.fonts.size.b2};
    font-weight: 500;
    width: fit-content;
    background-color: white;

    span {
        background-color: ${props => props.theme.colors.clearBlue};
        border-radius: 100px;
        padding: 4px;
        gap: 10px;
        height: 22px;
        width: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 400;
    }

    p {
        display: inline-block;
        margin: 0;
    }
`;

export {
    TeamTagContainer,
    TeamTag
}