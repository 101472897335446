import styled from "styled-components"
import { GenericListContainer } from "../GenericList/GenericList.styled";



const ListItem = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px;
    flex-grow: 2;
    height: 64px;
    border-radius: 8px;
    border: none;
    background-color: ${props => props.theme.colors.white};
    gap: 16px;
`;

const ListText = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ListTitle = styled.p`
    font-size: ${props => props.theme.fonts.size.b2};
    font-weight: 400;
    margin: 0;
    line-height: normal;
`;

const ListSubtitle = styled.p`
    font-size: ${props => props.theme.fonts.size.lt1};
    font-weight: 300;
    margin: 0;
    line-height: normal;
`;

export {
    ListItem,
    ListTitle,
    ListSubtitle,
    ListText
    
}