import styled, { css } from "styled-components";

export const Avatar = styled.img`
  width: 6.5vw;
  height: 6.5vw;
  max-width: 6.8rem;
  max-height: 6.8rem;
  border-radius: 9999px;
`;

export const UserContainer = styled.div`
    border: 1.3px solid ${(props) => props.theme.colors.silverGrayTwo};
    width: 100%;
    max-width: 355px;
    height: 359px;
    border-radius: 10px;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
`;

export const Name = styled.div`
    color: ${(props) => props.theme.colors.primary};
    font-size: min(1.1vw, 18px);
    font-weight: 700;
`

export const Telephone = styled.div``;

export const ContactInfo = styled.div`
    color: ${(props) => props.theme.colors.greyTwo};
    font-size: min(1vw, 14px);
    font-weight: 500;
    margin: 0.5rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 10rem;
`;

export const HeaderContactInfo = styled(ContactInfo)`
    margin-left: 1.5rem;
    height: 2rem;
`;

export const Email = styled.div`
    font-weight: 300;
`;

export const LastVisit = styled.div`
    font-size: min(0.9vw, 12px);
`;

export const Title = styled.div`
    color: ${(props) => props.theme.colors.greyTwo};
`;
export const UserInfoLine = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Value = styled.div`
    font-weight: 300;
`;

export const Warranty = styled.div`
    font-size: min(1.1vw, 18px);
    font-weight: 700;
`;

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 10;

    ${(props) => props.isOpen && css`
        display: flex;
    `}
`;

export const ModalWrapper = styled.div`
    z-index: 10;
    width: 600px;
    height: 85vh;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(props) => props.theme.colors.highClearGray};
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.colors.silverGrayTwo};
    width: 34em;
    height: 62vh;
    overflow: auto;
    padding-bottom: 1vh;
    background-color: ${(props) => props.theme.colors.highClearGray};
`;

export const ModalHeader = styled.div`
    margin: 20px;
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: space-between;
`;

export const ModalTitle = styled.div`
    font-weight: 450;
    font-size: 26px;
`;

export const ModalCloseButton = styled.img`
    cursor: pointer;
`;

export const Label = styled.div`
    font-size: 16px;
    color: ${props => props.theme.colors.black};
    margin: 2px 0;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 15px;
`;

export const PhoneInputContainer = styled.span`
    display: flex;
    gap: 15px;
`;

export const SegmentTag = styled.span`
    font-weight: 400;
    background-color: ${(props) => props.theme.colors.greyEight};
    border-radius: 33px;
    padding: 0 10px;
    height: 24px;
    color: ${(props) => props.theme.colors.greyTwo};
`;

export const SegmentTagInput = styled.div`
    width: 32em;
    border: 1px solid ${(props) => props.theme.colors.grey};
    height: 40px;
    border-radius: 7px;
    background-color: ${(props) => props.theme.colors.white};
    display: flex;
    align-items: center;
    padding-left: 10px;
`;