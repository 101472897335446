import styled from "styled-components";
import { InputText } from "../../../../../elements";

const StepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 791px;
`;

const LogoImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FormWrapper = styled.div`
    background-color: ${props => props.theme.colors.primaryWhite};
    border-radius: 8px;
    width: 791px;
    margin: 2rem 0;
    padding: 1rem;
`;

const TitleStep = styled.div`
    ${props => props.theme.fonts.variant.semiBold()};
    font-size: 1.5rem;
    color: ${props => props.theme.colors.black};
    text-align: center;
`;

const NameInput = styled(InputText)`
    border: 1px solid ${props => props.theme.colors.darkGray};
`;

export {
  StepWrapper,
  FormWrapper,
  LogoImageWrapper,
  TitleStep,
  NameInput
};