export const initialChannels = {
    voice: false, 
    sms: false, 
    webchat: false,
    email: false, 
    'qna-webchat': false, 
}

export const initialActions = {
    send_sms: false,
    send_email: false,
    appointments_schedule: false,
}

export const initialTones = {
    friendly: false, 
    enthusiastic: false, 
    professional: false,
    helpful: false,
    direct: false,
    cordial: false,
}

export const agentType = [
    {name: 'Private', value: 'private'},
    {name: 'Public/Consumer', value: 'public'},
    {name: 'Internal', value: 'internal'}
]

export const answerType = [
    {name: 'Summarized', value: 'summarized'},
    {name: 'Extensive', value: 'extensive'}
]

export const persona = [
    {name: 'Ethan (Sales Manager)', value: 'ethan'},
    {name: 'Isabella (Services Manager)', value: 'isabella'},
    {name: 'Benjamin (Operations Specialist)', value: 'benjamin'},
    {name: 'Ava (Operations Specialist)', value: 'ava'},
]