import React from "react";
import { Route, Routes } from "react-router-dom";
import RoleGeneral from "../RolesPage/components/RoleGeneral/RoleGeneral";
import GroupGeneral from "./components/GroupsGeneral/GroupsGeneral";



const GroupsPage = () => {
  return (
    <Routes>
      <Route path="/details/:groupId" element={<GroupGeneral />} />
    </Routes>
  );
};

export default GroupsPage;
