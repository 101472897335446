import styled from 'styled-components';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
`;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  width: 100%;
`;

export {
  PageContainer,
  StepWrapper
};
