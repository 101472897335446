import { Icon } from "../../icon"
import { TeamTag, TeamTagContainer } from "./TeamTagsViewer.styled"

const TeamTagsViewer = ({teams}) => {
    return (
        <TeamTagContainer>
            {teams.map(team => ( 
                <TeamTag>
                    <Icon name={'defaultTeam'} width='20px' height='20px'/>
                    <p>{team}</p>
                </TeamTag>   
            ))}
        </TeamTagContainer>
    )
}

export default TeamTagsViewer