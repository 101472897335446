import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../hooks';

// Higher-order component (HOC) to handle permission-based rendering
const withPermission = (WrappedComponent, permissionCode, action = 'VIEW') => {
  // Create a new component that will check for permissions
  const WithPermission = observer((props) => {
    // Get the authStore from the global store
    const { authStore } = useStore();

    console.log(`Checking permission: ${permissionCode}, ${action}`);
    const hasPermission = authStore.hasPermission(permissionCode, action);
    console.log(`Permission ${hasPermission ? 'granted' : 'denied'} for ${permissionCode}`);

    // Check if the user has the required permission
    if (hasPermission) {
      console.log(`Permission granted for ${getDisplayName(WrappedComponent)}`);
      // If the user has permission, render the wrapped component
      return <WrappedComponent {...props} />;
    }

    console.log(`Permission denied for ${getDisplayName(WrappedComponent)}`);
    // If the user doesn't have permission, render nothing
    return null;
  });

  // Set a display name for the HOC for easier debugging
  WithPermission.displayName = `WithPermission(${getDisplayName(WrappedComponent)})`;
  
  // Return the new component
  return WithPermission;
};

// Helper function to get the display name of a component
function getDisplayName(WrappedComponent) {
  // Try to get the displayName, then the name, or default to 'Component'
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withPermission;
