import styled from 'styled-components';

const MainContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${props => props.theme.colors.greyTen};
    padding: 20px 80px;
    border: 1px solid ${props => props.theme.colors.borderGray};
    overflow: hidden;
`;

const TabsContainer = styled.div`
  margin: 20px 80px;
`;

const BreadCrumbContainer = styled.div`
    flex: 1;
`;

const MenuContainer = styled.div`
    display: flex;
    gap: 10px;
`;

const Title = styled.h3`
    margin: 0;
`;

export {
  MainContainer,
  TabsContainer,
  BreadCrumbContainer,
  MenuContainer,
  Title,
};
