import React, { useEffect, useMemo, useState } from "react";
import { observer } from 'mobx-react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    TextField,
    Tooltip,
} from "@mui/material";
import { API } from "aws-amplify";
import { createRow, MaterialReactTable, MRT_EditActionButtons, useMaterialReactTable, } from "material-react-table";
import ConfirmationDialog from "../../components/common/CommonConfirmationDialog";
import CopyIcon from "../../components/common/CopyIcon";
import {DragAndDropAssistant} from "../../elements";
import PenIcon from "../../components/common/PenIcon";
import ScienceIcon from "../../components/common/ScienceIcon";
import TrashIcon from "../../components/common/TrashIcon";
import {deleteAgent, getAgent, putAgent, deleteAgentFile} from "../../graphql/queries";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {createSearchParams, useNavigate} from "react-router-dom";
import FileIcon from "./assets/file_icon.svg";
import { useStore } from "../../hooks";
import { CustomBox } from "../../theme/DefaultTheme";

const channelSelectionList = ["Webchat", "SMS", "Email", "Voice"];
const validFileExtensions = ["txt", "pdf", "json", "docx", "csv"];
const extensions = {
    "application/vnd.ms-excel": [".xls"],
    "text/csv": [".csv"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
    ],
    "application/msword": [".docx"],
    "text/plain": [".txt"],
    "application/pdf": [".pdf"],
    "application/json": [".json"],
};
const assistantListFileRoute = "knowledge_base_openai";

// const useStyles = makeStyles({
//   flexGrow: {
//     flex: '1',
//   },
//   IconButton: {
//     backgroundColor: '#3c52b2',
//     color: '#fff',
//     '&:hover': {
//       backgroundColor: '#fff',
//       color: '#3c52b2',
//   },
// }})

const AssistantList = observer(() => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [editedRow, setEditedRow] = useState(null);
    const [dialogOpenDelete, setDialogOpenDelete] = useState(false);
    const [dialogOpenDeleteFile, setDialogOpenDeleteFile] = useState(false);
    const [dialogOpenFiles, setDialogOpenFiles] = useState(false);

    const [users, setAssistants] = useState([]);
    const [isLoadingAssistantsError, setisLoadingAssistantsError] =
        useState(false);
    const [isFetchingAssistants, setisFetchingAssistants] = useState(false);
    const [isEditingAssistant, setisEditingAssistant] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [uploadedFilePaths, setUploadedFilePaths] = useState([]);
    const confirmationDialogMessageFile = "Are you sure you want to delete this file from this assistant?";
    const confirmationDialogMessage =
        "Are you sure you want to delete this assistant?";
    const navigate = useNavigate();
    const { authStore } = useStore();
    const { isLoading, isSuperAdmin, userRoleGroups, selectedAccount } = authStore;


    useEffect(() => {
        document.title = "ANN - Automotive Neural Networks";
        if (!isLoading) {
            fetchAssistants();
        }
    }, [isLoading, isEditingAssistant]);

    const fetchAssistants = async () => {
        try {
            setisFetchingAssistants(true);
            const response = await API.graphql({
                query: getAgent,
                variables: {
                    input: {
                        customer_id: selectedAccount.id
                    },
                },
            });
            const data = JSON.parse(response.data?.getAgent?.body);
            const dataFixed = data.map((row) => {
                return {
                    ...row,
                    id: `${row.id}`,
                    knowledge_base_formatted: row.knowledge_base.map((filePath) =>
                        filePath.replace("s3://", "").split("/").splice(-1)
                    ),
                    knowledge_base_formatted_count: row.knowledge_base.length,
                };
            });
            setAssistants(dataFixed);
        } catch (error) {
            console.error("Error fetching assistants:", error);
            setisLoadingAssistantsError(true);
        } finally {
            setisFetchingAssistants(false);
        }
    };

    const CustomInput = (props) => {
        const handler = (event) => {
            setValue(event.target.value);
            if (props.onChange) {
                props.onChange(event);
            }
        };

        const [value, setValue] = useState(props.value);
        return (
            <div>
                <InputLabel>{props.inputLabel}</InputLabel>
                <TextField
                    {...props}
                    onChange={handler}
                    value={value}
                    fullWidth={true}
                    type="outline"
                />
            </div>
        );
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: "customer_id",
                id: "customer",
                header: "Customer Id",
                enableEditing: false,
            },
            {
                accessorKey: "id",
                id: "id",
                header: "Id",
                enableEditing: false,
            },
            {
                accessorKey: "name",
                header: "Name",
                Edit: ({ column, row }) => {
                    const props = {
                        required: true,
                        type: "text",
                        error: !!validationErrors?.name,
                        helperText: validationErrors?.name,
                        value: row.original[column.id],
                        onChange: (event) => {
                            row.original.name = event.target.value;
                            row._valuesCache[column.id] = event.target.value;
                        },
                        inputLabel: "Name",
                    };
                    return <CustomInput {...props} />;
                },
            },
            {
                header: "Instructions",
                accessorKey: "human_instructions",
                accessorFn: (row) => row.human_instructions,
                enableEditing: false,
                Cell: ({ row, isEditing }) => {
                    const correctedJsonString = row.original.human_instructions;
                    return isEditing ? (
                        <TextField
                            multiline
                            rows={4}
                            fullWidth
                            value={correctedJsonString}
                        />
                    ) : (
                        <div
                            style={{
                                whiteSpace: "normal",
                                textOverflow: "ellipsis",
                                overflow: "scroll",
                                maxHeight: "150px",
                                maxWidth: "350px",
                            }}
                        >
                            {correctedJsonString}
                        </div>
                    );
                }
            },
            {
                accessorKey: "channels",
                header: "Channels",
                Edit: ({ column, row }) => {
                    const children = channelSelectionList.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ));
                    const props = {
                        select: true,
                        children,
                        required: true,
                        inputLabel: "Channels",
                        value: row.original[column.id] && row.original[column.id][0],
                        error: !!validationErrors?.channels,
                        helperText: validationErrors?.channels,
                        onChange: (event) => {
                            row.original[column.id] = [event.target.value];
                            row._valuesCache[column.id] = [event.target.value];
                        },
                    };
                    return <CustomInput {...props} />;
                },
            },
            {
                header: "Prompt",
                accessorKey: "prompt",
                Cell: ({ row, isEditing }) => {
                    return isEditing ? (
                        <TextField
                            multiline
                            rows={4}
                            fullWidth
                            value={row.original.prompt}
                        />
                    ) : (
                        <div
                            style={{
                                whiteSpace: "normal",
                                textOverflow: "ellipsis",
                                overflow: "scroll",
                                maxHeight: "150px",
                                maxWidth: "350px",
                            }}
                        >
                            {row.original.prompt}
                        </div>
                    );
                },
                Edit: ({ column, row }) => {
                    const props = {
                        required: true,
                        value: row.original[column.id],
                        inputLabel: "Prompt",
                        multiline: true,
                        rows: 3,
                        error: !!validationErrors?.prompt,
                        helperText: validationErrors?.prompt,
                        onChange: (event) => {
                            row.original[column.id] = event.target.value;
                            row._valuesCache[column.id] = event.target.value;
                        },
                    };
                    return <CustomInput {...props} />;
                },
            },
            {
                // Date format and filter option
                accessorFn: (row) => new Date(row.insert_date),
                filterVariant: "datetime",
                sortingFn: "datetime",
                Cell: ({ cell }) => cell.getValue()?.toUTCString() || null,
                Edit: () => null,
                accessorKey: "insert_date",
                header: "Creation Date",
            },
            {
                accessorKey: "knowledge_base_formatted_count",
                header: "Files",
                Cell: ({ row }) => (
                    <Button
                        onClick={() => {
                            setSelectedRow(row);
                            setDialogOpenFiles(true);
                        }}
                    >
                        {row.original.knowledge_base_formatted_count}
                    </Button>       
                ),
                Edit: () => null,
            },
        ],
        [validationErrors, userRoleGroups]
    );

    async function UsePutAssistant(values, customer_id, row) {
        try {
            setisEditingAssistant(true);

            if (row) {
                var id = row.original.id;
            } else {
                id = "";
            }

            const input = {
                channels: values.channels ?? [],
                customer_id: selectedAccount.id,
                human_instructions: values.prompt,
                knowledge_base: row.original.knowledge_base ?? uploadedFilePaths,
                name: values.name ?? "",
                prompt: values.prompt ?? "",
                id: id ?? "",
                job_title: '',
                persona: JSON.stringify({}),
                tone: [],
                extended_answer: true,
                scope: '',
                actions: JSON.stringify([{}])
            };

            await API.graphql({
                query: putAgent,
                variables: { input: input },
            });
        } catch (error) {
            console.error("Error:", error);
            setisLoadingAssistantsError(true);
        } finally {
            setisEditingAssistant(false);
            setUploadedFilePaths([]);
        }
    }

    async function UseDeleteAgent(value) {
        try {
            setisEditingAssistant(true);
            await API.graphql({
                query: deleteAgent,
                variables: {
                    input: {
                        customer_id: value.customer_id,
                        id: value.id
                    },
                },
            });
        } catch (error) {
            setisLoadingAssistantsError(true);
        } finally {
            setisEditingAssistant(false);
        }
    }

    const handleConfirmDelete = (row) => {
        if (row) {
            UseDeleteAgent(row.original);
        }
        setDialogOpenDelete(false);
    };

    async function UseDeleteAgentFile(value) {
        try {
            setisEditingAssistant(true);
            
            const response_update = await UsePutAssistant(editedRow, selectedAccount.id, selectedRow);
            const response = await API.graphql({
                query: deleteAgentFile,
                variables: {
                    input: {
                        customer: value.customer,
                        assistant_id: value.assistant_id,
                        s3_file_path: value.s3_file_path
                    },
                },
            });
        } catch (error) {
            setisLoadingAssistantsError(true);
        } finally {
            setisEditingAssistant(false);
        }
    }

    const handleConfirmDeleteFile = (row) => {
        if (row) {
            UseDeleteAgentFile(row);
        }
        setDialogOpenDeleteFile(false);
    };

    const validateForm = (values) => {
        const errors = {};

        if (!values.human_instructions) {
            errors.human_instructions = "Human Instructions is required";
        }

        if (!values.channels) {
            errors.channels = "Channels is required";
        }

        if (!values.prompt) {
            errors.prompt = "Prompt is required";
        }

        if (!values.name) {
            errors.name = "Name is required";
        }

        return errors;
    };

    const handleCreateAssistant = async ({ values, table, row }) => {
        const errors = validateForm({
            ...values,
            knowledge_base: row.original.knowledge_base ?? [],
        });
        setValidationErrors({...errors });
        if (Object.keys(errors).length > 0) {
            return;
        }

        await UsePutAssistant(values, selectedAccount.id, row);
        table.setCreatingRow(null);
    };

    const handleSaveAssistant = async ({ values, table, row }) => {
        const errors = validateForm({
            ...values,
            knowledge_base: row.original.knowledge_base,
        });
        setValidationErrors({ ...errors });
        if (Object.keys(errors).length > 0) {
            return;
        }

        await UsePutAssistant(values, selectedAccount.id, row);
        table.setEditingRow(null);
    };

    const openDeleteConfirmModal = (row) => {
        setSelectedRow(row);
        setDialogOpenDelete(true);
    };

    const openDeleteFileConfirmModal = (values) => {
        setSelectedFile(values);
        setDialogOpenDeleteFile(true);
    };

    const handleChatAgent = (row) => {
        navigate({
            pathname: "/playground",
            search: createSearchParams({
                agent: row.original.id,
                customer: row.original.customer_id,
            }).toString(),
        });
    };

    const handleFileUpload = (filePath, row) => {
        try {
            const url = filePath.uploadUrl;
            let newUrl = url.replace("https", "s3");
            newUrl = newUrl.replace(".s3.amazonaws.com", "");
            const indexQuestion = newUrl.indexOf("?");
            if (indexQuestion !== -1) {
                newUrl = newUrl.substring(0, indexQuestion);
            }

            const fileExtension = newUrl
                .substring(newUrl.lastIndexOf("."))
                .toLowerCase();
            if (!validFileExtensions.includes(fileExtension.substring(1))) {
                console.error("Invalid file extension");
                return;
            }

            setUploadedFilePaths((prevPaths) => [...prevPaths, newUrl]);

            row.original.knowledge_base = row.original.knowledge_base ?? [];
            row._valuesCache.knowledge_base = row._valuesCache.knowledge_base ?? [];

            row.original.knowledge_base = [...row.original.knowledge_base, newUrl];
            row._valuesCache.knowledge_base = [
                ...row._valuesCache.knowledge_base,
                newUrl,
            ];

            setValidationErrors({
                ...validationErrors,
                files: undefined,
            });
        } catch (error) {
            console.error("Error handling file upload:", error);
        }
    };

    const filesDialog = (rowFile) => (
        <List sx={{ pt: 0, padding: "20px", bgcolor: "#F0F0F0" }} dense>
            {rowFile?.original.knowledge_base_formatted.map((row, index) => (
                <ListItem key={row} disableGutters sx={{ py: 1 }}>
                    <img
                        src={FileIcon}
                        width={20}
                        height={20}
                        style={{ marginRight: 4 }}
                    />
                    <ListItemText primary={<div style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '90%'}}>{row}</div>}/>
                        <IconButton
                            onClick={() => {
                                const S3_url = rowFile?.original.knowledge_base.find(url => (new URL(url)).pathname.includes(row));
                                const edited_input = {
                                    channels: rowFile?.original.channels,
                                    customer_id: rowFile?.original.customer_id,
                                    human_instructions: rowFile?.original.prompt,
                                    id: rowFile?.original.id,
                                    knowledge_base: rowFile?.original.knowledge_base.filter(url => !(new URL(url)).pathname.includes(row)),
                                    name: rowFile?.original.name,
                                    prompt: rowFile?.original.prompt,
                                }
                                setEditedRow(edited_input)
                                setSelectedRow(rowFile)
                                const deleteFileData = {
                                    customer: rowFile?.original.customer_id,
                                    assistant_id: rowFile?.original.id,
                                    s3_file_path: S3_url
                                };
                                openDeleteFileConfirmModal(deleteFileData);
                                
                                const index_to_remove_knowledge_base_formatted = rowFile?.original.knowledge_base_formatted.indexOf(row)
                                if (index_to_remove_knowledge_base_formatted !== -1) {
                                    rowFile?.original.knowledge_base_formatted.splice(index_to_remove_knowledge_base_formatted, 1);
                                }
                                const index_to_remove = rowFile?.original.knowledge_base.indexOf(S3_url)
                                if (index_to_remove !== -1) {
                                    rowFile?.original.knowledge_base.splice(index_to_remove, 1);
                                }
                                                             
                            }}
                            >
                                <CloseRoundedIcon/>
                        </IconButton>
                </ListItem>
            ))}
        </List>
    );

    const table = useMaterialReactTable({
        columns,
        data: users,
        createDisplayMode: "modal",
        editDisplayMode: "modal",
        enableEditing: true,
        enableColumnFilters: false,
        enableHiding: false,
        muiSearchTextFieldProps: {
            size: "small",
            variant: "outlined",
        },
        initialState: {
            sorting: [
                {
                    id: "insert_date", //sort by insert date by default on page load
                    desc: true,
                },
            ],
        },

        positionActionsColumn: "last",
        getRowId: (row) => row.id,
        muiToolbarAlertBannerProps: isLoadingAssistantsError
            ? {
                color: "error",
                children: "Error loading data",
            }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: "500px",
            },
        },
        onCreatingRowCancel: () => setValidationErrors({}),
        onCreatingRowSave: handleCreateAssistant,
        onEditingRowCancel: () => setValidationErrors({}),
        onEditingRowSave: handleSaveAssistant,

        renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle
                    variant="div"
                    style={{ color: "grey", justifyContent: "center", display: "flex" }}
                >
                    New Agent
                </DialogTitle>
                <DialogContent
                    sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                    {internalEditComponents}
                    <DragAndDropAssistant
                        fileRoute={assistantListFileRoute}
                        customer={selectedAccount.id}
                        extensions={extensions}
                        onFileUpload={(filePath) => handleFileUpload(filePath, row)}
                        error={!!validationErrors?.files}
                        helperText={validationErrors?.files}
                        errorStyles={{ color: "red" }}
                    />
                </DialogContent>
                <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),

        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => {
            return (
                <>
                    <DialogTitle
                        variant="div"
                        style={{ color: "grey", justifyContent: "center", display: "flex" }}
                    >
                        Edit your agent
                    </DialogTitle>
                    <DialogContent
                        sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
                    >
                        {internalEditComponents}
                        <div>
                            <InputLabel>Files</InputLabel>
                            <p>
                                {row.original.knowledge_base.length} files currently
                                uploaded to this agent
                            </p>

                            <div
                                style={{
                                    maxHeight: "250px",
                                    whiteSpace: "normal",
                                    textOverflow: "ellipsis",
                                    overflow: "scroll",
                                }}
                            >
                                {filesDialog(row)}
                            </div>
                        </div>
                        <DragAndDropAssistant
                            fileRoute={assistantListFileRoute}
                            customer={selectedAccount.id}
                            extensions={extensions}
                            onFileUpload={(filePath) => handleFileUpload(filePath, row)}
                            error={!!validationErrors?.files}
                            helperText={validationErrors?.files}
                            errorStyles={{ color: "red" }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <MRT_EditActionButtons variant="text" table={table} row={row} />
                    </DialogActions>
                </>
            );
        },

        renderRowActions: ({ row, table }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip title="Edit">
                    <IconButton
                        disabled={row.original.name.includes('concierge')}
                        sx={{
                            color: "#919191",
                            ":hover": {
                                color: "#FFF",
                                bgcolor: "#232323",
                            },
                        }}
                    >
                        <PenIcon viewBox="9 9 35 35" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton
                        disabled={row.original.name.includes('concierge')}
                        sx={{
                            color: "#919191",
                            ":hover": {
                                color: "#FFF",
                                bgcolor: "#232323",
                            },
                        }}
                        onClick={() => openDeleteConfirmModal(row)}
                    >
                        <TrashIcon viewBox="9 9 35 35" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copy">
                    <IconButton
                        disabled={row.original.name.includes('concierge')}
                        sx={{
                            color: "#919191",
                            ":hover": {
                                color: "#FFF",
                                bgcolor: "#232323",
                            },
                        }}
                        onClick={() => {
                            const {
                                original: {
                                    name,
                                    human_instructions,
                                    channels,
                                    prompt,
                                    knowledge_base
                                }
                            } = row;

                            return table.setCreatingRow(
                                createRow(table, {
                                    customer_id: selectedAccount.id,
                                    name: name + " - copy",
                                    human_instructions: prompt,
                                    id: "",
                                    channels,
                                    prompt,
                                    knowledge_base
                                })
                            )
                        }}
                    >
                        <CopyIcon viewBox="9 9 35 35" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Test-Agent">
                    <IconButton
                        sx={{
                            color: "#919191",
                            ":hover": {
                                color: "#FFF",
                                bgcolor: "#232323",
                            },
                        }}
                        onClick={() => handleChatAgent(row)}
                    >
                        <ScienceIcon viewBox="9 9 35 35" />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <CustomBox display="flex" justifyContent="space-between">
                <div>
                    <Button
                        variant="contained"
                        onClick={() => {
                            navigate('/new-agent')
                        }}
                    >
                        + Create New Agent
                    </Button>
                </div>
            </CustomBox>
        ),
        state: {
            isLoading: isFetchingAssistants,
            isSaving: isEditingAssistant,
            showAlertBanner: isLoadingAssistantsError,
            showProgressBars: isFetchingAssistants,
            columnVisibility: { role_group: isSuperAdmin, customer: false, channels: false, human_instructions: false },
        },
    });

    return (
        <div>
            <MaterialReactTable table={table} />
            <ConfirmationDialog
                open={dialogOpenDelete}
                onClose={() => setDialogOpenDelete(false)}
                message={confirmationDialogMessage}
                onConfirm={() => handleConfirmDelete(selectedRow)}
                maxWidth="xl"
                fullWidth={false}
            />
            <ConfirmationDialog
                open={dialogOpenDeleteFile}
                onClose={() => setDialogOpenDeleteFile(false)}
                message={confirmationDialogMessageFile}
                onConfirm={() => handleConfirmDeleteFile(selectedFile)}
                maxWidth="xl"
                fullWidth={false}
            />

            {setDialogOpenFiles && (
                <>
                    <Dialog
                        open={dialogOpenFiles}
                        onClose={() => setDialogOpenFiles(false)}
                    >
                        <DialogTitle>Files</DialogTitle>
                        {filesDialog(selectedRow)}
                    </Dialog>
                </>
            )}
        </div>
    );
});

export default AssistantList;
