import { useState, useEffect } from "react";
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import { useStore } from "../../../../hooks";
import {
    ContactInfoDiv,
    Name,
    UserContainer,
    Title
} from "./ContactInfo.styled";
import { Tooltip } from "@mui/material";

const ContactInfo = observer(() => {
    const { unifiedCustomerProfileStore } = useStore();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [ucp, setUCP] = useState({});

    useEffect(() => {
        if (unifiedCustomerProfileStore.ucp) {
            setUCP(unifiedCustomerProfileStore.ucp);
        }
    }, [unifiedCustomerProfileStore.ucp]);

    return (
        <UserContainer> 
            <ContactInfoDiv>
                <Tooltip title={ucp.contactCompany}>
                    <Name>Contact-company - Primary&nbsp;</Name>
                    <Title>{ucp.industry ? ucp.contactCompany: 'No Data Available'}</Title>
                </Tooltip>
                <Tooltip title={ucp.contactCompanies}>
                    <Name>Contact-companies:&nbsp;</Name>
                    <Title>{ucp.contactCompanies ? ucp.contactCompanies: 'No Data Available'}</Title>
                </Tooltip>

                <Tooltip title={ucp.contactOwner}>
                    <Name>Contact owner&nbsp;</Name>
                    <Title>{ucp.contactOwner ? ucp.contactOwner: 'No Data Available'}</Title>
                </Tooltip>
            </ContactInfoDiv>
        </UserContainer>
    );
});

export default ContactInfo;