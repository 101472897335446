import {observer} from "mobx-react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import AssistantManagementList from "./AssistantManagement";
import {useStore} from "../../hooks";
import AgentView from "../AgentPage/AgentView/AgentView";

const AssistantManagementPage = observer(() => {
  const { changeTitle } = useStore();
  changeTitle('Agents');

  return (
    <Routes>
      <Route index element={<AgentView />} />
    </Routes>
  );
});

export default AssistantManagementPage;

