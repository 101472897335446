import React, { useEffect, useState } from "react";
import {
    GenericListContainer,
    GenericListItem,
    Header,
    Title,
    AddButton,
    StyledIconButton
} from "./GenericListComponent.styled";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CircleAddIcon from '../../../../../elements/icon/assets/circle_add_icon.svg';

const GenericListComponent = ({ 
    title, 
    listItems = [], 
    setListItems, 
    newItem, 
    placeholder, 
    ModalComponent, 
    ActionIcon = CloseRoundedIcon,
    onActionClick,
    width = "50%",
    height = "100%",
    fontSize = "14px",
    fontWeight = "600",
    textareaBorder = "1px solid #d3d3d3",
    showAddButton = true
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onClickAdd = () => {
        setIsModalOpen(true);
    }

    const handleModalClose = () => {
        setIsModalOpen(false);
    }

    const autoResizeTextarea = (event) => {
        const textarea = event.target;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleAction = (index) => {
        if (onActionClick) {
            onActionClick(index);
        }
    };

    const handleTextChange = (e, index) => {
        const updatedList = [...listItems];
        updatedList[index] = e.target.value;
        setListItems(updatedList);
    };

    useEffect(() => {
        // Resize textareas on load
        document.querySelectorAll('textarea').forEach(textarea => {
            textarea.style.height = `${textarea.scrollHeight}px`;
        });
    }, [listItems]);

    return (
        <> 
            <GenericListContainer 
                width={width} 
                height={height} 
                fontSize={fontSize} 
                fontWeight={fontWeight} 
                textareaBorder={textareaBorder}
            >
                <Header>
                    <Title>{title}</Title>
                    {showAddButton && (
                        <AddButton onClick={onClickAdd}>
                            <img src={CircleAddIcon} width={24} height={24} alt="Add Feature" />
                        </AddButton>
                    )}
                </Header>
                {listItems && listItems.map((item, idx) => (
                    <GenericListItem key={idx}>
                        <textarea 
                            value={item} 
                            placeholder={placeholder} 
                            rows="1"
                            onInput={autoResizeTextarea}
                            onChange={(e) => handleTextChange(e, idx)}
                        />  
                        <StyledIconButton onClick={() => handleAction(idx)}>
                            <ActionIcon />
                        </StyledIconButton>
                    </GenericListItem>
                ))}
            </GenericListContainer>

            {isModalOpen && ModalComponent && (
                <ModalComponent
                    onClose={handleModalClose}
                />
            )}
        </>
    );
}

export default GenericListComponent;
