import React, { useState } from 'react';
import { Icon } from "../../../../../elements";

import {
    CustomModal,
    ModalFilterHeader,
    ModalFilterTitle,
    ModalDropdownTitle,
    StyledFormControl,
    StyledSelect,
    DropdownMenu,
    StyledMenuItem,
    StyledButton,
    PlaceholderDropdown,
    DropdownIconContainer,
    IconCloseContainer
} from "./ModalFilter.styled";

const ModalFilter = ({openFilter, anchorEl, handleCloseModalFilter, onRoleChange, title, modalWidth}) => {

    const [selectedRole, setSelectedRole] = useState("");
    const [openDropdown, setOpenDropdown] = useState(false);

    const handleRoleChange = (value) => {
        setSelectedRole(value);
        toggleDropdown();
    };

    const toggleDropdown = () => {
        setOpenDropdown(!openDropdown);
    };

    const handleApply = () => {
        onRoleChange(selectedRole);
        handleCloseModalFilter();
    };

    return (
      openFilter && (
        <CustomModal
          id="custom-modal"
          top={anchorEl.getBoundingClientRect().bottom}
          left={anchorEl.getBoundingClientRect().right - modalWidth}
          width={modalWidth}
        >
            <ModalFilterHeader>
                <ModalFilterTitle>{title}</ModalFilterTitle>
                <IconCloseContainer onClick={handleCloseModalFilter}>
                    <Icon name={'close'} height={'11px'} width={'11px'} onClick={handleCloseModalFilter}/>
                </IconCloseContainer>
            </ModalFilterHeader>
            <div>
                <ModalDropdownTitle>Role</ModalDropdownTitle>
                <StyledFormControl>
                    <StyledSelect onClick={toggleDropdown}>
                        {selectedRole ? selectedRole : <PlaceholderDropdown>Search for Segment</PlaceholderDropdown>}
                        <DropdownIconContainer><Icon name={"expandMoreOutlinedBlack"} height={'8px'} width={'8px'}></Icon></DropdownIconContainer>
                    </StyledSelect>
                    <DropdownMenu open={openDropdown}>
                        <StyledMenuItem onClick={() => handleRoleChange("")}>All</StyledMenuItem>
                        <StyledMenuItem onClick={() => handleRoleChange("superadmin")}>Superadmin</StyledMenuItem>
                        <StyledMenuItem onClick={() => handleRoleChange("customeradmin")}>Customeradmin</StyledMenuItem>
                        <StyledMenuItem onClick={() => handleRoleChange("user")}>User</StyledMenuItem>
                    </DropdownMenu>
                </StyledFormControl>
            </div>
            <StyledButton onClick={handleApply}>
                Apply
            </StyledButton>
        </CustomModal>
      )
    );
};

export default ModalFilter;