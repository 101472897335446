
export const timeLineMockData2 = (customerId, contactId, lastEvaluatedKey) => {
  if (!lastEvaluatedKey) {
    return {
      lastEvaluatedKey: 1,
      messages: [
        {
          id: "msg#2024-08-30 20:29:12.233198",
          campaign: "campaign#bmwretail#iwkhezg1-general",
          sender: "381a7937-9571-4424-85aa-dd755e841288",
          receiver: "asst_zO5YYeUQOJPrlHZOiAyoEXhG",
          message: "what's the content of the pdf i'm attaching?",
          lang: "EN",
          channel: "ANNWEB",
          role: "ANNWEB_AGENT",
          code: "oj8ea4qf",
          conversation_id: "conversation#6dbb294ee35048b1afffadc0cd87ab00",
          metadata: {},
          insert_date: "2024-08-30 20:29:12.233198",
          last_modified: "2024-08-30 20:29:12.233198",
          active: true,
        },
        {
          id: "msg#2024-08-30 20:29:45.123456",
          campaign: "campaign#bmwretail#iwkhezg1-general",
          sender: "asst_zO5YYeUQOJPrlHZOiAyoEXhG",
          receiver: "381a7937-9571-4424-85aa-dd755e841288",
          message:
            "I'm sorry, but I don't have access to any attached PDF. Could you please provide more context or upload the PDF you're referring to?",
          lang: "EN",
          channel: "ANNWEB",
          role: "AI_ASSISTANT",
          code: "oj8ea4qf",
          conversation_id: "conversation#6dbb294ee35048b1afffadc0cd87ab00",
          metadata: {},
          insert_date: "2024-08-30 20:29:45.123456",
          last_modified: "2024-08-30 20:29:45.123456",
          active: true,
        },
        {
          id: "msg#2024-08-30 20:30:22.987654",
          campaign: "campaign#bmwretail#iwkhezg1-general",
          sender: "381a7937-9571-4424-85aa-dd755e841288",
          receiver: "asst_zO5YYeUQOJPrlHZOiAyoEXhG",
          message:
            "Oh, I see. I haven't attached the PDF yet. Let me do that now. It's a brochure about the new BMW 5 Series.",
          lang: "EN",
          channel: "ANNWEB",
          role: "ANNWEB_AGENT",
          code: "oj8ea4qf",
          conversation_id: "conversation#6dbb294ee35048b1afffadc0cd87ab00",
          metadata: {},
          insert_date: "2024-08-30 20:30:22.987654",
          last_modified: "2024-08-30 20:30:22.987654",
          active: true,
        },
        {
          id: "msg#2024-08-30 20:31:05.246810",
          campaign: "campaign#bmwretail#iwkhezg1-general",
          sender: "asst_zO5YYeUQOJPrlHZOiAyoEXhG",
          receiver: "381a7937-9571-4424-85aa-dd755e841288",
          message:
            "Thank you for clarifying. Once you've attached the PDF about the new BMW 5 Series, I'll be happy to analyze its content and provide you with a summary. Is there any specific information you're looking for in the brochure?",
          lang: "EN",
          channel: "ANNWEB",
          role: "AI_ASSISTANT",
          code: "oj8ea4qf",
          conversation_id: "conversation#6dbb294ee35048b1afffadc0cd87ab00",
          metadata: {},
          insert_date: "2024-08-30 20:31:05.246810",
          last_modified: "2024-08-30 20:31:05.246810",
          active: true,
        },
        {
          id: "msg#2024-08-27 15:45:30.123456",
          campaign: "campaign#bmwretail#iwkhezg1-general",
          sender: "381a7937-9571-4424-85aa-dd755e841288",
          receiver: "asst_zO5YYeUQOJPrlHZOiAyoEXhG",
          message:
            "Hello, I'm interested in the new BMW X5. Can you tell me about its features?",
          lang: "EN",
          channel: "ANNWEB",
          role: "ANNWEB_AGENT",
          code: "oj8ea4qf",
          conversation_id: "conversation#7caa385ff46159c2bfffbec1de98bc11",
          metadata: {},
          insert_date: "2024-08-27 15:45:30.123456",
          last_modified: "2024-08-27 15:45:30.123456",
          active: true,
        },
        {
          id: "msg#2024-08-27 15:46:15.789012",
          campaign: "campaign#bmwretail#iwkhezg1-general",
          sender: "asst_zO5YYeUQOJPrlHZOiAyoEXhG",
          receiver: "381a7937-9571-4424-85aa-dd755e841288",
          message:
            "Certainly! The BMW X5 is a luxury SUV known for its powerful performance, advanced technology, and premium comfort. Some key features include:\n\n1. Various engine options, including efficient hybrid powertrains\n2. xDrive all-wheel-drive system for enhanced traction\n3. Spacious interior with optional third-row seating\n4. Large infotainment display with the latest iDrive system\n5. Advanced driver assistance features\n6. Optional air suspension for a smoother ride\n\nWould you like more details on any specific aspect of the X5?",
          lang: "EN",
          channel: "ANNWEB",
          role: "AI_ASSISTANT",
          code: "oj8ea4qf",
          conversation_id: "conversation#7caa385ff46159c2bfffbec1de98bc11",
          metadata: {},
          insert_date: "2024-08-27 15:46:15.789012",
          last_modified: "2024-08-27 15:46:15.789012",
          active: true,
        },
        {
          id: "msg#2024-08-27 15:47:30.123456",
          campaign: "campaign#bmwretail#iwkhezg1-general",
          sender: "381a7937-9571-4424-85aa-dd755e841288",
          receiver: "asst_zO5YYeUQOJPrlHZOiAyoEXhG",
          message:
            "Yes, I'm particularly interested in the hybrid powertrain option. Can you provide more details about its performance and fuel efficiency?",
          lang: "EN",
          channel: "ANNWEB",
          role: "ANNWEB_AGENT",
          code: "oj8ea4qf",
          conversation_id: "conversation#7caa385ff46159c2bfffbec1de98bc11",
          metadata: {},
          insert_date: "2024-08-27 15:47:30.123456",
          last_modified: "2024-08-27 15:47:30.123456",
          active: true,
        },
        {
          id: "msg#2024-08-27 15:48:45.789012",
          campaign: "campaign#bmwretail#iwkhezg1-general",
          sender: "asst_zO5YYeUQOJPrlHZOiAyoEXhG",
          receiver: "381a7937-9571-4424-85aa-dd755e841288",
          message:
            "Certainly! The BMW X5 xDrive45e is the plug-in hybrid variant of the X5. It combines a 3.0-liter six-cylinder gasoline engine with an electric motor, delivering a total system output of 389 horsepower. This powertrain offers impressive performance, with 0-60 mph acceleration in just 5.3 seconds.\n\nRegarding fuel efficiency, the X5 xDrive45e can travel up to 30 miles on electric power alone, making it ideal for short commutes or city driving. In hybrid mode, it achieves an EPA-estimated 50 MPGe combined. The large battery pack allows for extended electric-only operation, significantly reducing fuel consumption for many drivers.\n\nWould you like to know more about the charging options or any other specific aspects of the hybrid X5?",
          lang: "EN",
          channel: "ANNWEB",
          role: "AI_ASSISTANT",
          code: "oj8ea4qf",
          conversation_id: "conversation#7caa385ff46159c2bfffbec1de98bc11",
          metadata: {},
          insert_date: "2024-08-27 15:48:45.789012",
          last_modified: "2024-08-27 15:48:45.789012",
          active: true,
        },
      ]
    };
  } else if (lastEvaluatedKey === 1) {
    return {
      lastEvaluatedKey: 2,
      messages: [
        {
          id: "msg#2024-08-26 10:15:30.123456",
          campaign: "campaign#bmwretail#iwkhezg1-general",
          sender: "381a7937-9571-4424-85aa-dd755e841288",
          receiver: "asst_zO5YYeUQOJPrlHZOiAyoEXhG",
          message: "Hi, I'm looking for information about the BMW 3 Series. Can you help?",
          lang: "EN",
          channel: "ANNWEB",
          role: "ANNWEB_AGENT",
          code: "oj8ea4qf",
          conversation_id: "conversation#8dcc496ff57260d3cfffcfd2ef99cd22",
          metadata: {},
          insert_date: "2024-08-26 10:15:30.123456",
          last_modified: "2024-08-26 10:15:30.123456",
          active: true,
        },
        {
          id: "msg#2024-08-26 10:16:15.789012",
          campaign: "campaign#bmwretail#iwkhezg1-general",
          sender: "asst_zO5YYeUQOJPrlHZOiAyoEXhG",
          receiver: "381a7937-9571-4424-85aa-dd755e841288",
          message: "Of course! I'd be happy to help you with information about the BMW 3 Series. The 3 Series is a popular compact luxury sedan known for its sporty handling and premium features. What specific aspects of the 3 Series are you interested in?",
          lang: "EN",
          channel: "ANNWEB",
          role: "AI_ASSISTANT",
          code: "oj8ea4qf",
          conversation_id: "conversation#8dcc496ff57260d3cfffcfd2ef99cd22",
          metadata: {},
          insert_date: "2024-08-26 10:16:15.789012",
          last_modified: "2024-08-26 10:16:15.789012",
          active: true,
        },
        {
          id: "msg#2024-08-26 10:17:30.246810",
          campaign: "campaign#bmwretail#iwkhezg1-general",
          sender: "381a7937-9571-4424-85aa-dd755e841288",
          receiver: "asst_zO5YYeUQOJPrlHZOiAyoEXhG",
          message: "I'm particularly interested in the engine options and fuel efficiency. Can you provide details on those?",
          lang: "EN",
          channel: "ANNWEB",
          role: "ANNWEB_AGENT",
          code: "oj8ea4qf",
          conversation_id: "conversation#8dcc496ff57260d3cfffcfd2ef99cd22",
          metadata: {},
          insert_date: "2024-08-26 10:17:30.246810",
          last_modified: "2024-08-26 10:17:30.246810",
          active: true,
        },
        {
          id: "msg#2024-08-26 10:18:45.135790",
          campaign: "campaign#bmwretail#iwkhezg1-general",
          sender: "asst_zO5YYeUQOJPrlHZOiAyoEXhG",
          receiver: "381a7937-9571-4424-85aa-dd755e841288",
          message: "Certainly! The BMW 3 Series offers several engine options:\n\n1. 330i: 2.0L turbocharged 4-cylinder (255 hp)\n2. 330e: Plug-in hybrid with 2.0L turbo 4-cylinder + electric motor (288 hp combined)\n3. M340i: 3.0L turbocharged 6-cylinder (382 hp)\n\nFuel efficiency varies by model:\n- 330i: Up to 26 city / 36 highway mpg\n- 330e: Up to 75 MPGe in hybrid mode, 22 miles of electric-only range\n- M340i: Up to 23 city / 32 highway mpg\n\nWould you like more details on any specific model?",
          lang: "EN",
          channel: "ANNWEB",
          role: "AI_ASSISTANT",
          code: "oj8ea4qf",
          conversation_id: "conversation#8dcc496ff57260d3cfffcfd2ef99cd22",
          metadata: {},
          insert_date: "2024-08-26 10:18:45.135790",
          last_modified: "2024-08-26 10:18:45.135790",
          active: true,
        },
      ]
    };
  } else if (lastEvaluatedKey === 2) {
    return {
      lastEvaluatedKey: 0,
      messages: [
        {
          id: "msg#2024-08-25 14:30:00.123456",
          campaign: "campaign#bmwretail#iwkhezg1-general",
          sender: "381a7937-9571-4424-85aa-dd755e841288",
          receiver: "asst_zO5YYeUQOJPrlHZOiAyoEXhG",
          message: "Hello, I'm interested in the BMW iX electric SUV. Can you provide some information about it?",
          lang: "EN",
          channel: "ANNWEB",
          role: "ANNWEB_AGENT",
          code: "oj8ea4qf",
          conversation_id: "conversation#9eee597ff68371e4dfffdfee3faa0d33",
          metadata: {},
          insert_date: "2024-08-25 14:30:00.123456",
          last_modified: "2024-08-25 14:30:00.123456",
          active: true,
        },
        {
          id: "msg#2024-08-25 14:31:15.789012",
          campaign: "campaign#bmwretail#iwkhezg1-general",
          sender: "asst_zO5YYeUQOJPrlHZOiAyoEXhG",
          receiver: "381a7937-9571-4424-85aa-dd755e841288",
          message: "Certainly! The BMW iX is an all-electric luxury SUV that showcases BMW's latest EV technology. Here are some key features:\n\n1. Dual electric motors providing all-wheel drive\n2. Range of up to 300 miles on a single charge (EPA estimate)\n3. Fast charging capability: up to 80% charge in 40 minutes\n4. Spacious interior with premium materials\n5. Advanced driver assistance features\n6. Large curved display with BMW's latest iDrive system\n\nWould you like more details on any specific aspect of the iX?",
          lang: "EN",
          channel: "ANNWEB",
          role: "AI_ASSISTANT",
          code: "oj8ea4qf",
          conversation_id: "conversation#9eee597ff68371e4dfffdfee3faa0d33",
          metadata: {},
          insert_date: "2024-08-25 14:31:15.789012",
          last_modified: "2024-08-25 14:31:15.789012",
          active: true,
        },
        {
          id: "msg#2024-08-25 14:32:30.246810",
          campaign: "campaign#bmwretail#iwkhezg1-general",
          sender: "381a7937-9571-4424-85aa-dd755e841288",
          receiver: "asst_zO5YYeUQOJPrlHZOiAyoEXhG",
          message: "That sounds impressive. Can you tell me more about the performance specifications? I'm particularly interested in acceleration and top speed.",
          lang: "EN",
          channel: "ANNWEB",
          role: "ANNWEB_AGENT",
          code: "oj8ea4qf",
          conversation_id: "conversation#9eee597ff68371e4dfffdfee3faa0d33",
          metadata: {},
          insert_date: "2024-08-25 14:32:30.246810",
          last_modified: "2024-08-25 14:32:30.246810",
          active: true,
        },
        {
          id: "msg#2024-08-25 14:33:45.135790",
          campaign: "campaign#bmwretail#iwkhezg1-general",
          sender: "asst_zO5YYeUQOJPrlHZOiAyoEXhG",
          receiver: "381a7937-9571-4424-85aa-dd755e841288",
          message: "Of course! The BMW iX comes in different variants, but I'll focus on the iX xDrive50, which is one of the more popular models:\n\n1. Acceleration: 0-60 mph in approximately 4.4 seconds\n2. Top speed: Electronically limited to 124 mph (200 km/h)\n3. Power output: 516 horsepower (combined from two electric motors)\n4. Torque: 564 lb-ft of instant torque\n\nThese performance figures are impressive, especially considering the iX's size and weight as a luxury SUV. The instant torque from the electric motors provides quick and smooth acceleration, making it feel very responsive in daily driving situations.\n\nIs there anything else you'd like to know about the iX's performance or other features?",
          lang: "EN",
          channel: "ANNWEB",
          role: "AI_ASSISTANT",
          code: "oj8ea4qf",
          conversation_id: "conversation#9eee597ff68371e4dfffdfee3faa0d33",
          metadata: {},
          insert_date: "2024-08-25 14:33:45.135790",
          last_modified: "2024-08-25 14:33:45.135790",
          active: true,
        },
      ]
    }
  } else {
    return {
      lastEvaluatedKey: null,
      messages: []
    };
  }
};
