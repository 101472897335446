import React from 'react';
import { IconButton } from '../../../../../../elements';
import { SelectedGroupsContainer, MarginButton } from "./PermissionGroup.styled";

const PermissionGroup = ({ groups, onRemoveGroup }) => (
  <SelectedGroupsContainer>
    {groups.map((group, index) => (
      <div key={index} className="selected-group">
        {group}
        <MarginButton>
          <IconButton icon="closeLight" height={'10px'} width={'10px'} onClick={() => onRemoveGroup(group)} />
        </MarginButton>

      </div>
    ))}
  </SelectedGroupsContainer>
);

export default PermissionGroup;
