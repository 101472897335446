// TextInputComponent.js
import React from 'react';
import { InputWrapper, TextareaField, CharacterCount } from './TextInputComponent.styled';

const TextInputComponent = ({
  value,
  maxLength = 1500,
  placeholder = 'Enter text...',
  height,
  width,
  borderColor,
  fontSize,
  fontWeight,
  onChange,
}) => {
  return (
    <InputWrapper width={width}>
      <TextareaField
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={onChange}
        height={height}
        borderColor={borderColor}
        fontSize={fontSize}
        fontWeight={fontWeight}
      />
      <CharacterCount>{`${value.length}/${maxLength}`}</CharacterCount>
    </InputWrapper>
  );
};

export default TextInputComponent;
