import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ModalContainer, ModalContent, ModalLeft, ScrollableTitles, Title, ModalRight,
	ModalTitle, ModalBody, ModalFooter, ActionCardsContainer } from './ModalLibrary.styled';
import ActionLibraryCards from "../ActionCards/ActionLibraryCards";
import Pagination from "./components/Pagination/Pagination";
import { observer } from 'mobx-react';
import { useStore } from "../../../../../../../hooks";
import Spinner from '../../../../../../../components/common/Spinner';

const ModalLibrary = observer(({ closeModalLibrary, openModalAddAction }) => {
	const { viewConversationStore } = useStore();
	const { listOfActionTitles, listOfActionLibraryCards } = viewConversationStore.conversationsActionsStore;
	const [selectedTitle, setSelectedTitle] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [cardsPerPage, setCardsPerPage] = useState(9);
	const modalRef = useRef(null);

	useEffect(() => {
		if (listOfActionTitles.length > 0) {
			setSelectedTitle(listOfActionTitles[0]);
		}
	}, [listOfActionTitles]);

	useEffect(() => {
		viewConversationStore.conversationsActionsStore.onChangeTitles();
		viewConversationStore.conversationsActionsStore.getActions();
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const { indexOfLastCard, indexOfFirstCard, currentCards } = useMemo(() => {
		const indexOfLastCard = currentPage * cardsPerPage;
		const indexOfFirstCard = indexOfLastCard - cardsPerPage;
		const currentCards = listOfActionLibraryCards ? listOfActionLibraryCards.slice(indexOfFirstCard, indexOfLastCard) : [];
		return { indexOfLastCard, indexOfFirstCard, currentCards };
	}, [currentPage, cardsPerPage, listOfActionLibraryCards]);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const handleClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			closeModalLibrary();
		}
	};

	return (
		<ModalContainer>
			<ModalContent ref={modalRef}>
				<ModalBody>
					<ModalLeft>
						<ModalTitle>Action Library</ModalTitle>
						<ScrollableTitles>
							{listOfActionTitles.map((title, index) => (
								<Title key={index} onClick={() => setSelectedTitle(title)} active={title === selectedTitle}>
									{title}
								</Title>
							))}
						</ScrollableTitles>
					</ModalLeft>
					<ModalRight>
					{!viewConversationStore.conversationsActionsStore.isLoading ?
						<ActionCardsContainer>
							{currentCards.map((listOfActionLibraryCards, index) => (
								<ActionLibraryCards key={index} options={listOfActionLibraryCards} openModalAddAction={openModalAddAction} />
							))}
						</ActionCardsContainer> :
						<Spinner/>
					}
					</ModalRight>
				</ModalBody>

				<ModalFooter>
					<Pagination
						cardsPerPage={cardsPerPage}
						totalCards={listOfActionLibraryCards ? listOfActionLibraryCards.length : 0}
						paginate={paginate}
						currentPage={currentPage}
					/>
				</ModalFooter>
			</ModalContent>
		</ModalContainer>
	);
});

export default ModalLibrary;