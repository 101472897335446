// CustomChars.js

import React from 'react';
import './styles/Metrics.css'; // Import the CSS file

const CustomChars = ({ title, subtitle, children }) => {
  return (
    <div className="custom-charts">
      <div className="chart-container">
        
        <div className="label"></div>
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
        {children}
      </div>
    </div>
  );
};

export default CustomChars;
