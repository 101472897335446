import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../../hooks";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { DefaultTheme as theme } from "../../../../../theme";
import { Container } from './GroupsTable.styled';

const GroupsTable = observer(({ searchValue, onRowClick }) => {
  const { listGroupStore } = useStore();
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    listGroupStore.onFetchGroups();
  }, [listGroupStore]);

  useEffect(() => {
    const filtered = listGroupStore.groups.filter(row => {
      const mainGroupLower = row.mainGroup ? row.mainGroup.toLowerCase() : "";
      const groupLower = row.group ? row.group.toLowerCase() : "";
      const searchValueLower = searchValue ? searchValue.toLowerCase() : "";

      return mainGroupLower.includes(searchValueLower) || groupLower.includes(searchValueLower);
    });
    setFilteredData(filtered);
  }, [listGroupStore.groups, searchValue]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "group",
        header: "Group",
        size: 400,
        minSize: 300,
        maxSize: 600,
        Cell: ({ cell }) => (
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {cell.getValue()}
          </span>
        ),
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
          sx: {
            border: 'none',
          },
        },
      },
      {
        accessorKey: "users",
        header: "Users",
        size: 150, 
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
          sx: {
            border: 'none',
          },
        },
      },
      {
        accessorKey: "createdDate",
        header: "Creation Date",
        size: 150, 
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
          sx: {
            border: 'none',
          },
        },
      },
    ],
    []
  );

  const handleRowClick = (row) => {
    if (onRowClick) {
      onRowClick(row.original);
    }
    navigate(`/v2/groups/details/${row.original.id}`);
  };

  const table = useMaterialReactTable({
    columns,
    data: filteredData,
    initialState: { isHidden: { countryCode: true } },
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    enableEditing: false,
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => handleRowClick(row),
      sx: {
        cursor: 'pointer',
        border: 'none',
      },
    }),
    muiTableHeadRowProps: {
      sx: {
        borderRadius: '8px',
        border: 'none',
        minHeight: 'auto',
        height: 'auto',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: theme.colors.highClearGray,
        color: 'black',
        paddingTop: '8px',
        paddingBottom: '8px',
        border: 'none',
      },
    },
    muiTableContainerProps: {
      sx: {
        border: 'none',
        marginTop: '0px',
        paddingTop: '0px',
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: 'none',
        marginTop: '0px',
      },
    },
    enableGlobalFilter: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    state: {
      isLoading: listGroupStore.status === "loading",
    },
  });

  return (
    <Container>
      <MaterialReactTable table={table} />
    </Container>
  );
});

export default GroupsTable;
