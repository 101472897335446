import React, { useState, useMemo, useCallback } from 'react';
import { Icon, RoundedButton } from '../../..';
import { 
  SelectContainer, 
  SelectButton, 
  IconContainer, 
  Dropdown, 
  OptionContainer, 
  Checkbox, 
  Divider, 
  ButtonContainer,
  StyledOption
} from './MultiSelectDropdown.styled';

const DropdownOption = ({ label, checked, onChange, isAllOption }) => (
  <OptionContainer>
    <StyledOption isAllOption={isAllOption}>
      {label}
    </StyledOption>
    <Checkbox
      type="checkbox"
      checked={checked}
      onChange={onChange}
      onClick={(e) => e.stopPropagation()}
    />
  </OptionContainer>
);

const MultiSelectDropdown = ({ options, onApply }) => {
  const defaultOptions = useMemo(() => [
    { label: 'All', value: 'all', checked: false },
    ...options.map((option) => ({ ...option, checked: false })),
  ], [options]);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(defaultOptions);

  const handleOptionChange = useCallback((index) => {
    setSelectedOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];

      if (index === 0) {
        const allChecked = !updatedOptions[0].checked;
        updatedOptions.forEach((opt) => (opt.checked = allChecked));
      } else {
        updatedOptions[index].checked = !updatedOptions[index].checked;
        updatedOptions[0].checked = false;
      }

      return updatedOptions;
    });
  }, []);

  const handleApply = useCallback(() => {
    setIsOpen(false);
    const selected = selectedOptions.filter((option) => option.checked);
    onApply(selected);
  }, [selectedOptions, onApply]);

  return (
    <SelectContainer>
      <SelectButton onClick={() => setIsOpen(!isOpen)}>
        Status
        <IconContainer>
          <Icon name="vectorSelect" />
        </IconContainer>
      </SelectButton>
      {isOpen && (
        <Dropdown>
          {selectedOptions.map((option, index) => (
            <DropdownOption
              key={index}
              label={option.label}
              checked={option.checked}
              onChange={() => handleOptionChange(index)}
              isAllOption={index === 0}
            />
          ))}
          <Divider />
          <ButtonContainer>
            <RoundedButton width="30%" height="50%" onClick={handleApply}>
              Apply
            </RoundedButton>
          </ButtonContainer>
        </Dropdown>
      )}
    </SelectContainer>
  );
};

export default MultiSelectDropdown;
