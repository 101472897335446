import styled, { createGlobalStyle } from 'styled-components';

const HeaderButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CustomGenericListStyle = createGlobalStyle`
  #main-content {
    overflow: hidden;
  }
`;

const MediaQuery = {
  flexGrow: 1,
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 25%)',
  '@media (max-width: 1024px)': {
    maxHeight: 'calc(100vh - 30%)',
  },
  '@media (max-width: 768px)': {
    maxHeight: 'calc(100vh - 30%)',
  },
  '@media (max-width: 600px)': {
    maxHeight: 'calc(100vh - 30%)',
  },
  '@media (max-width: 480px)': {
    maxHeight: 'calc(100vh - 31%)',
  },
  '@media (max-width: 360px)': {
    maxHeight: 'calc(100vh - 30%)',
  },
};

export const TableBodyCell = `
  font-size: 12px;
`;

export const TableHeadCell = styled.div`
  align: center;
`;

const defaultCellProps = {
  muiTableBodyCellProps: {
    align: 'center',
    sx: {
      fontSize: '12px',
      padding: '4px 8px',
    },
  },
  muiTableHeadCellProps: {
    align: 'center',
    sx: {
      padding: '4px 8px',
    },
  },
};

export {
  HeaderButtonWrapper,
  CustomGenericListStyle,
  MediaQuery,
  defaultCellProps
};
