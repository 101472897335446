
import { action, flow, makeObservable, observable } from "mobx";
import ListDataStore from "./page/ListDataStore";
const NotificationPageStrategy = {
  list: new ListDataStore(),
};

class NotificationStore {
  notifications = [];
  filteredNotifications = [];
  totalRowCount = 0;
  isLoading = false;
  isError = false;
  currentPage = 1;
  pageSize = 10;
  currentPage = undefined;
  itemsBell = [
    {
      id: "9b7a04df-a489-4325-994c-e38abc7622a4",
      title: "Last walk grow.",
      message:
        "Also mention eat friend always source amount. Answer company draw discover evening.",
      creation_date: "01/09/2009",
      status: "unread",
      category: "toward",
      payload: {
        fullName: "Cheyenne King",
      },
      metadata: null,
    },
    {
      id: "f7be158c-0ffc-41a9-96ec-907de723a72e",
      title: "Pressure hit which.",
      message:
        "Your phone significant example. Civil example reality several special. Company whatever.",
      creation_date: "13/11/1977",
      status: "read",
      category: "indeed",
      payload: {
        fullName: "Jeffrey Lee",
      },
      metadata: null,
    },
    {
      id: "dc7d9ac8-cd9f-4a2c-b4e8-f9ffca2e7184",
      title: "Across Democrat beyond.",
      message:
        "When already serious. Number scene development identify. Area allow deal.",
      creation_date: "24/06/1998",
      status: "unread",
      category: "seat",
      payload: {
        fullName: "Alan Powers",
      },
      metadata: null,
    },
    {
      id: "c62b97cf-2cee-478f-a2c2-685f5c308b31",
      title: "Speech buy reveal.",
      message:
        "Until enough turn all far. Lay discussion section hand strong. Wide sometimes entire follow.",
      creation_date: "17/08/2005",
      status: "read",
      category: "try",
      payload: {
        fullName: "Peter Vargas",
      },
      metadata: null,
    },
    {
      id: "fef57d1f-53e8-48eb-ab23-c274e30fd47a",
      title: "Seat half.",
      message:
        "Difficult receive trip necessary wife keep program. Hit cultural individual.",
      creation_date: "23/10/1983",
      status: "unread",
      category: "discuss",
      payload: {
        fullName: "Elizabeth Mendoza",
      },
      metadata: null,
    },
    {
      id: "6e8b68cd-f7a2-4f3b-8df2-cf7c55ef2c8f",
      title: "Join red debate.",
      message:
        "Officer someone among impact. Heavy walk risk prepare case notice entire.",
      creation_date: "13/03/1975",
      status: "read",
      category: "tough",
      payload: {
        fullName: "Brian Rodriguez",
      },
      metadata: null,
    },
    {
      id: "dc18d746-e9d5-4501-9304-81c9f25a68d7",
      title: "Foreign price news.",
      message:
        "Before artist interest west student. Enjoy section whole notice dream. Woman fight game rise.",
      creation_date: "10/05/2004",
      status: "unread",
      category: "deep",
      payload: {
        fullName: "Joshua Martinez",
      },
      metadata: null,
    },
    {
      id: "e808f96e-16e3-498f-98b3-c43d91b61148",
      title: "Able eye next.",
      message:
        "Next option give situation cultural. Daughter name chance floor glass growth serious.",
      creation_date: "04/02/2000",
      status: "read",
      category: "tend",
      payload: {
        fullName: "Ryan Hill",
      },
      metadata: null,
    },
    {
      id: "e422f843-5b5f-41de-b9e3-bf19d7df36af",
      title: "Which soldier call.",
      message:
        "Space operation easy always. Artist set even resource seek create. Certain speech growth.",
      creation_date: "19/07/1991",
      status: "unread",
      category: "actually",
      payload: {
        fullName: "Victoria Moore",
      },
      metadata: null,
    },
    {
      id: "f23a805b-d0d3-486b-89e3-8d83a410bb87",
      title: "Wide road seat.",
      message:
        "Process reflect commercial source entire. Professional example policy job quickly relate girl.",
      creation_date: "11/01/1996",
      status: "read",
      category: "lay",
      payload: {
        fullName: "Sarah Thompson",
      },
      metadata: null,
    },
    {
      id: "d2a354b7-e5d6-4c43-a22e-431eb5b64c3d",
      title: "Building level artist.",
      message:
        "Indicate specific social moment near public form. Last build arm behind. Two ago role moment.",
      creation_date: "29/09/2010",
      status: "unread",
      category: "wear",
      payload: {
        fullName: "Kevin Robinson",
      },
      metadata: null,
    },
    {
      id: "bc1671b6-c0f8-4ef2-9b93-9a05b0c93dd1",
      title: "Even style idea.",
      message:
        "Represent bill act. Work market child project evidence. Build blue per world project.",
      creation_date: "20/03/1995",
      status: "read",
      category: "ready",
      payload: {
        fullName: "Laura White",
      },
      metadata: null,
    },
    {
      id: "2f2b11df-fda5-4214-8720-1ed5e8a5f7f7",
      title: "Hundred sport win.",
      message:
        "Pattern together easy fill attack. Over consumer outside. Individual successful act.",
      creation_date: "26/11/1982",
      status: "unread",
      category: "piece",
      payload: {
        fullName: "Olivia Taylor",
      },
      metadata: null,
    },
    {
      id: "13983bd2-b5de-49b5-9436-59e207f3e8c7",
      title: "Half behind mention.",
      message:
        "Same wrong walk activity over. Serious decision middle safe sure professional green.",
      creation_date: "09/05/1993",
      status: "read",
      category: "guess",
      payload: {
        fullName: "Emily Anderson",
      },
      metadata: null,
    },
    {
      id: "2d18c1e6-802a-42a6-a94e-7e4ab015f0a2",
      title: "World popular win.",
      message:
        "Nature piece meet likely enjoy carry live. Collection forget physical identify.",
      creation_date: "14/07/1999",
      status: "unread",
      category: "single",
      payload: {
        fullName: "John Miller",
      },
      metadata: null,
    },
  ];

  constructor() {
    makeObservable(this, {
      notifications: observable,
      filteredNotifications: observable,
      totalRowCount: observable,
      isLoading: observable,
      isError: observable,
      currentPage: observable,
      pageSize: observable,
      getNotifications: flow,
      setError: action,
      onClearError: action,
      setCompleted: action,
      filterNotifications: action,
      itemsBell: observable
    });
  }

  getItemsBellByStatus(status) {
    const findElements = this.itemsBell.map((item) => {
      if (item.status === status) return {
        name: item.payload.fullName,
        description: item.title,
        time: item.creation_date,
        status: item.status
      };
    })
    return findElements.filter((i) => i != undefined)

  }

  *getNotifications({ customer, pageIndex, pageSize, sortBy, search }) {
    this.isLoading = true;
    this.isError = false;

    try {
      yield new Promise((resolve) => setTimeout(resolve, 500));

      const mockData = [
        { id: 1, title: 'Notification 1', message: "This is a long message that demonstrates the 'View Details' functionality. Click to see the full message and more details about this notification. This is a long message that demonstrates the 'View Details' functionality. Click to see the full message and more details about this notification. This is a long message that demonstrates the 'View Details' functionality. Click to see the full message and more details about this notification. This is a long message that demonstrates the 'View Details' functionality. Click to see the full message and more details about this notification. This is a long message that demonstrates the 'View Details' functionality. Click to see the full message and more details about this notification. This is a long message that demonstrates the 'View Details' functionality. Click to see the full message and more details about this notification ", date: '09/20/2024', isCompleted: false, channel: 'email', status: 'unread' },
        { id: 2, title: 'Notification 2', message: 'Your request has been approved.', date: '09/19/2024', isCompleted: true, channel: 'sms', status: 'read' },
        { id: 3, title: 'Notification 3', message: 'Reminder: Check your inbox.', date: '09/18/2024', isCompleted: false, channel: 'phone', status: 'unread' },
        { id: 4, title: 'Notification 4', message: 'Your account settings have been updated.', date: '09/17/2024', isCompleted: true, channel: 'email', status: 'read' },
        { id: 5, title: 'Notification 5', message: 'New comment on your post.', date: '09/16/2024', isCompleted: false, channel: 'sms', status: 'unread' },
        { id: 6, title: 'Notification 6', message: 'System maintenance scheduled.', date: '09/15/2024', isCompleted: false, channel: 'phone', status: 'unread' },
        { id: 7, title: 'Notification 7', message: 'Password change request.', date: '09/14/2024', isCompleted: true, channel: 'email', status: 'read' },
        { id: 8, title: 'Notification 8', message: 'Your subscription will expire soon.', date: '09/13/2024', isCompleted: false, channel: 'sms', status: 'overdue' },
        { id: 9, title: 'Notification 9', message: 'New feature release available.', date: '09/12/2024', isCompleted: true, channel: 'phone', status: 'completed' },
        { id: 10, title: 'Notification 10', message: 'Don’t forget to verify your email.', date: '09/11/2024', isCompleted: false, channel: 'email', status: 'unread' },
      ];

      this.notifications = mockData.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
      this.filteredNotifications = this.notifications; 
      this.totalRowCount = mockData.length;

    } catch (error) {
      this.setError(true);
    } finally {
      this.isLoading = false;
    }
  }

  filterNotifications(filters) {
    if (!filters.length || filters.includes("all")) {
        this.filteredNotifications = this.notifications; 
        return;
    }

    this.filteredNotifications = this.notifications.filter(notification => {
        const isUnread = !notification.isCompleted;
        const isRead = notification.isCompleted && notification.status === 'read';
        const isOverdue = notification.status === 'overdue';
        const isCompleted = notification.status === 'completed';

        return filters.some(filter => {
            if (filter === "unread" && isUnread) return true;
            if (filter === "read" && isRead) return true;
            if (filter === "overdue" && isOverdue) return true;
            if (filter === "completed" && isCompleted) return true;
            return false;
        });
    });
}


  setError(errorState) {
    this.isError = errorState;
  }

  onClearError() {
    this.isError = false;
  }

  setCompleted(id) {
    const notification = this.notifications.find(item => item.id === id);
    if (notification) {
      notification.isCompleted = true;
      notification.status = 'completed';
    }
  }
}

export default NotificationStore;
