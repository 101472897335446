import React, { useState } from 'react';
import {
	Overlay,
	CustomAccountModalContainer,
	CustomAccountModalHeader,
	CustomAccountModalBody,
	CustomAccountModalHeaderTitle,
	CustomAccountModalHeaderClose,
	CustomAccountModalHeaderTitleWrapper,
	CustomAccountModalBodyListContainer,
	CustomAccountModalBodyListContainerIcon,
	CustomAccountModalBodyListContainerSwitchButton,
	CustomAccountModalBodyListContainerText,
	InputSearchContainer,
	InputSearchField,
	SearchIcon,
} from './App.styled'
import { Icon } from '../../elements'
import { observer } from "mobx-react";
import { useStore } from "../../hooks";
import { useNavigate } from "react-router-dom";

const CustomAccountModal = ({ items, onClose }) => {
	const [searchTerm, setSearchTerm] = useState('');
	const { authStore } = useStore();
	const navigate = useNavigate();

	const filteredItems = items.filter(item =>
		item.text.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const handleSwitchClick = (item) => {
		item.onClick();
		onClose();
	}
	
	const handleSwitchAccount =() =>{
		onClose();
		navigate('/home');
	}

	return (
		<Overlay onClick={onClose}>
			<CustomAccountModalContainer onClick={(e) => e.stopPropagation()}>
				<CustomAccountModalHeader>
					<CustomAccountModalHeaderTitleWrapper>
						<CustomAccountModalHeaderTitle>Switch Accounts</CustomAccountModalHeaderTitle>
						<CustomAccountModalHeaderClose onClick={handleSwitchAccount}>
							<Icon name={"closeLight"} width={"14px"} height={"14px"} />
						</CustomAccountModalHeaderClose>
					</CustomAccountModalHeaderTitleWrapper>

					<InputSearchContainer>
						<InputSearchField
							type="text"
							value={searchTerm}
							onChange={e => setSearchTerm(e.target.value)}
							onClick={e => e.stopPropagation()}
							placeholder="Search accounts..."
						/>
						<SearchIcon>
							<Icon name={"magnifier"} height={'18px'} width={'18px'} />
						</SearchIcon>
					</InputSearchContainer>

				</CustomAccountModalHeader>

				<CustomAccountModalBody>
					<ul>
						{filteredItems.map((item, index) => (
							<li key={index} >
								<CustomAccountModalBodyListContainer>
									<CustomAccountModalBodyListContainerIcon>
										{item.text.split(' ').slice(0, 2).map(word => word[0]).join('')}
									</CustomAccountModalBodyListContainerIcon>
									<CustomAccountModalBodyListContainerText>{item.text}</CustomAccountModalBodyListContainerText>
									<CustomAccountModalBodyListContainerSwitchButton inUse={authStore.selectedAccount.id === item.id} onClick={() => handleSwitchClick(item)}>
										{authStore.selectedAccount.id === item.id ? 'In Use' : 'Switch to'}
									</CustomAccountModalBodyListContainerSwitchButton>
								</CustomAccountModalBodyListContainer>
							</li>
						))}
					</ul>
				</CustomAccountModalBody>
			</CustomAccountModalContainer>
		</Overlay>
	);
};

export default observer(CustomAccountModal);