import { styled } from "styled-components";

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    width: ${props => props.width || '230px'};
    height: 40px;
    background-color: ${(props) => props.checked ? "#dddddd" : "#EEEEEE"};
    border-radius: 8px;
    cursor: pointer;
`;

const CheckboxLabel = styled.span`
    font-size: ${props => props.fontSize || '18px'};
    font-weight: 500;
    color: ${(props) => props.checked ? "#4B4B4B" : "#949494"};
    text-transform: ${props => props.textTransform || 'capitalize'};
`;

const CustomCheckboxInput = styled.input.attrs({ type: 'checkbox' })`
    -webkit-appearance: none;
    appearance: none;
    min-width: 1.8em;
    min-height: 1.8em;
    border-radius: 5px;
    border: 1.45px solid #8d8d8d;
    background-color: #D9D9D9;
    outline: none;
    cursor: pointer;
    margin-right: 40px;
    margin-left: 10px;
    transition: background-color 1s;

    &:checked {
        background-color: #4B4B4B !important;
        position: relative;
    }

    &:checked:before {
        content: "\\2714";
        font-size: 1.2em;
        color: #fff;
        position: absolute;
        right: 4px;
        top: 2px;
    }
`;

export {
    CheckboxWrapper,
    CheckboxLabel,
    CustomCheckboxInput
}

