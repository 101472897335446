import { Icon } from "../icon";
import { InputWrapper, InputField, SearchIcon } from "./SearchInput.styled";
import { useState } from "react";

const SearchInput = ({ onChange }) => {
  const [value, setValue] = useState('');

  const onHandleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <InputWrapper>
      <InputField value={value} type="text" placeholder="Search" onChange={onHandleChange} />
      <SearchIcon>
        <Icon name={"magnifier"} height={'20px'} width={'20px'} />
      </SearchIcon>
    </InputWrapper>
  );
};

export default SearchInput;
