import { action, makeObservable, observable, flow } from "mobx";
import { API } from "aws-amplify";
import {getTrigger, cancelRun} from "../../graphql/queries";

class ConversationsStore {
  isLoading = false;
  isError = false;
  triggersList = [];
  groupedTriggers = [];
  account = "";
  runId="";
  threadId="";
  customerId="";

  constructor() {
    makeObservable(this, {
      triggersList: observable,
      account: observable,
      isLoading: observable,
      isError: observable,
      groupedTriggers: observable,
      runId: observable,
      threadId: observable,
      customerId: observable,
      setAccount: action,
      onClearError: action,
      getTriggers: flow,
      clear: action,
      stopResponse: flow,
      setRunId: action,
      setThreadId: action,
      setCustomerId: action,
    });
  }

  setAccount(account) {
    this.account = account;
  }

  getMarketing() {
    return this.marketing;
  }

  *getTriggers() {
    try {
      this.isLoading = true;
      this.isError = false;

      const response = yield API.graphql({
        query: getTrigger,
        variables: { input: { account: this.account } },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const triggers = JSON.parse(response.data?.getTrigger?.body);

      const groupedTriggers = triggers.reduce((acc, trigger) => {
        const lastNumber = trigger.id.split("#").pop();
        if (!acc[lastNumber]) {
          acc[lastNumber] = [];
        }

        if (trigger.id.split("#")[0] === "latest") {
          acc[lastNumber].push(trigger);
        }
        return acc;
      }, {});

      this.groupedTriggers = groupedTriggers;

      const finalTriggers = Object.values(groupedTriggers).map((group) => {
        const activeObject = group.find(
          (trigger) => trigger.status === "active",
        );
        return activeObject || group[0];
      });
      this.triggersList = finalTriggers;
    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  onClearError() {
    this.isError = false;
  }

  clear() {
    this.isLoading = false;
    this.isError = false;
    this.triggersList = [];
    this.account = "";
    this.groupedTriggers = [];
  }
  
  setRunId(runId){
    this.runId= runId;
  }
  
  setThreadId(threadId){
    this.threadId=threadId;
  }
  
  setCustomerId(customerId){
    this.customerId=customerId
  }

  *stopResponse() {
    try {
      this.isLoading = true;
      const input =  {
          run_id: this.runId,
          thread_id: this.threadId,
          customer_id: this.customerId,
      };
      
     yield API.graphql({
        query: cancelRun,
        variables: { input },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      this.isSuccessful = true;
    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }
}

export default ConversationsStore;
