import { action, flow, makeObservable, observable } from "mobx";

class ListGroupStore {
  groups = [];
  users = [];
  status = 'idle';
  row = null;
  showDialogOpenDelete = false;
  isLoading = false;
  isError = false;
  errorMessage = "";

  constructor() {
    makeObservable(this, {
      groups: observable,
      users: observable,
      status: observable,
      row: observable,
      showDialogOpenDelete: observable,
      isLoading: observable,
      isError: observable,
      errorMessage: observable,
      setRow: action,
      setShowDialogOpenDelete: action,
      setError: action,
      createGroup: action,
      onFetchGroups: flow,
      onDeleteGroup: flow,
      fetchGroupData: flow,
      onPutGroup: flow,
      onFetchUsers: flow,
    });

    this.onFetchGroups();
    this.onFetchUsers();
  }

  setRow(row) {
    this.row = row;
  }

  setShowDialogOpenDelete(value) {
    this.showDialogOpenDelete = value;
  }

  setError(errorMessage) {
    this.isError = true;
    this.errorMessage = errorMessage;
  }

  createGroup(mainGroup, group, users) {
    const newGroup = {
      id: String(this.groups.length + 1),
      mainGroup,
      group,
      users,
      createdDate: new Date().toISOString().split("T")[0],
    };
    this.groups.push(newGroup);
    this.status = "idle";
    return newGroup;
  }

  *onFetchGroups() {
    try {
      this.status = "loading";
      this.groups = [
        {
          id: "1",
          group: "Customer_success",
          description: "description group",
          users: 32,
          createdDate: "2021-11-25",
        },
        {
          id: "2",
          group: "Responsible for the dealership",
          description: "description group",
          users: 32,
          createdDate: "2021-11-25",
        },
        {
          id: "3",
          group: "managers",
          description: "description group",
          users: 32,
          createdDate: "2021-11-25",
        },
        {
          id: "4",
          group: "managers regional",
          description: "description group",
          users: 32,
          createdDate: "2021-11-25",
        },
        {
          id: "5",
          group: "managers human resources",
          description: "description group",
          users: 32,
          createdDate: "2021-11-25",
        },
        
      ];
      this.status = "idle";
    } catch (e) {
      this.status = "error";
    }
  }

  *onDeleteGroup(id) {
    try {
      this.status = "loading";
      this.groups = this.groups.filter((group) => group.id !== id);
      this.status = "idle";
    } catch (e) {
      this.status = "error";
    } finally {
      this.setRow(null);
      this.setShowDialogOpenDelete(false);
    }
  }

  *fetchGroupData(groupId) {
    try {
      this.status = "loading";
      const groupData = this.groups.find((group) => group.id === groupId);
      if (groupData) {
        this.setRow(groupData);
      }
      this.status = "idle";
    } catch (e) {
      this.status = "error";
    }
  }

  *onPutGroup(values) {
    try {
      this.status = "loading";

      if (this.validation.roleName) {
        this.status = "error";
        return;
      }

      const existingRoleIndex = this.roles.findIndex(
        (role) => role.id === values.id
      );
      if (existingRoleIndex > -1) {
        this.roles[existingRoleIndex] = {
          ...this.roles[existingRoleIndex],
          ...values,
        };
      } else {
        this.roles.push({
          ...values,
          id: String(this.roles.length + 1),
          createdDate: new Date().toISOString().split("T")[0],
        });
      }

      this.status = "idle";
    } catch (error) {
      this.status = "error";
    }
  }

  *onFetchUsers() {
    try {
      this.status = "loading";
      this.users = [
        {
          id: "1",
          firstName: "Alex",
          lastName: "Smith",
          email: "alexsmith@email.com",
          phone: "+1999930334",
          lastActivity: "03/03/2024",
          group: "Managers in...",
          role: "Superadmin",
          creationDate: "03/03/2024",
        },
        {
          id: "2",
          firstName: "John",
          lastName: "Doe",
          email: "johndoe@email.com",
          phone: "+1999930335",
          lastActivity: "03/04/2024",
          group: "Developers",
          role: "Admin",
          creationDate: "04/03/2024",
        },
        {
          id: "3",
          firstName: "Jane",
          lastName: "Doe",
          email: "janedoe@email.com",
          phone: "+1999930336",
          lastActivity: "03/05/2024",
          group: "HR",
          role: "Manager",
          creationDate: "05/03/2024",
        },
        {
          id: "4",
          firstName: "Emily",
          lastName: "Johnson",
          email: "emilyjohnson@email.com",
          phone: "+1999930337",
          lastActivity: "03/06/2024",
          group: "Marketing",
          role: "User",
          creationDate: "06/03/2024",
        },
        {
          id: "5",
          firstName: "Michael",
          lastName: "Brown",
          email: "michaelbrown@email.com",
          phone: "+1999930338",
          lastActivity: "03/07/2024",
          group: "Sales",
          role: "Salesman",
          creationDate: "07/03/2024",
        },
        {
          id: "6",
          firstName: "Chris",
          lastName: "Green",
          email: "chrisgreen@email.com",
          phone: "+1999930339",
          lastActivity: "03/08/2024",
          group: "Support",
          role: "Support",
          creationDate: "08/03/2024",
        },
        {
          id: "7",
          firstName: "Sara",
          lastName: "Blue",
          email: "sarablue@email.com",
          phone: "+1999930340",
          lastActivity: "03/09/2024",
          group: "Finance",
          role: "Accountant",
          creationDate: "09/03/2024",
        },
        {
          id: "8",
          firstName: "David",
          lastName: "White",
          email: "davidwhite@email.com",
          phone: "+1999930341",
          lastActivity: "03/10/2024",
          group: "IT",
          role: "Technician",
          creationDate: "10/03/2024",
        },
        {
          id: "9",
          firstName: "Laura",
          lastName: "Black",
          email: "laurablack@email.com",
          phone: "+1999930342",
          lastActivity: "03/11/2024",
          group: "Legal",
          role: "Lawyer",
          creationDate: "11/03/2024",
        },
        {
          id: "10",
          firstName: "Peter",
          lastName: "Gray",
          email: "petergray@email.com",
          phone: "+1999930343",
          lastActivity: "03/12/2024",
          group: "Operations",
          role: "Operator",
          creationDate: "12/03/2024",
        },
      ];
      this.status = "idle";
    } catch (e) {
      this.status = "error";
    }
  }
  
}

export default ListGroupStore;
