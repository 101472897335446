import React, {useCallback, useMemo} from "react";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {
  Box,
  IconButton,
  Tooltip
} from "@mui/material";
import TrashIcon from "../../../components/common/TrashIcon";
import {useStore} from "../../../hooks";
import {Icon, RoundedButton} from "../../../elements";
import CustomHeaderTable from "./ListData.styled";
import { format } from 'date-fns';

const ListData = () => {
  const { notificationStore } = useStore();
  const { currentPage } = notificationStore;

  const openHandleDeleteConfirmModal = useCallback((row) => {
    console.log(row);
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "icon",
        header: "",
        enableEditing: false,
        maxSize: 30,
        Cell: () => {
          return (
            <Icon name='brain' width="95px" height="95px" />
          );
        }
      },
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "message",
        header: "",
        enableEditing: false
      },
      {
        accessorFn: (row) => new Date(row.createdAt),
        accessorKey: "createdAt",
        header: "Creation Date",
        enableEditing: false,
        filterVariant: 'date',
        sortingFn: 'datetime',
        Cell: ({ cell }) => format(cell.getValue(), 'LLL d, y, H:mm a'),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
    ], []);

  const sortDataByReadAt = useMemo(() => {
    const readData = currentPage.notifications.filter((value) => value.readAt !== null && value.readAt.length > 0);
    const noReadData = currentPage.notifications.filter((value) => value.readAt === null || value.readAt === '');

    return [...noReadData, ...readData];
  }, [currentPage.notifications]);

  const table = useMaterialReactTable({
    columns,
    data: sortDataByReadAt,
    initialState: { isHidden: { countryCode: true } },
    getRowId: (row) => row.id,
    enableRowActions: true,
    enableRowSelection: true,
    positionActionsColumn: 'last',
    muiToolbarAlertBannerProps: currentPage.status === "error"
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    muiTableBodyRowProps: ({ row }) => {
      return ({
        sx: {
          backgroundColor: (row.original.readAt !== null && row.original.readAt.length > 0) ? '#D9D9D9' : '',
          opacity: (row.original.readAt !== null && row.original.readAt.length > 0) ? '0.7' : '1',
        },
      });
    },
    renderRowActions: ({ row, table }) => (
      <Box>
        <Tooltip title="Delete">
          <IconButton onClick={() => openHandleDeleteConfirmModal(row)}>
            <TrashIcon viewBox="9 9 35 35" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <CustomHeaderTable>
        <RoundedButton width="220px" margin="0px 5px">
          Mark All As Read
        </RoundedButton>
      </CustomHeaderTable>
    ),
    state: {
      isLoading: currentPage.status === "loading",
      isSaving: currentPage.status === "editing",
      showAlertBanner: currentPage.status === "error",
      showProgressBars: currentPage.status === "loading",
    },
  });

  return <MaterialReactTable table={table} />
}

export default ListData;
