import React, { useCallback, useEffect, useState } from "react";

const usePopupWindow = (currentRef, isActive) => {
  const [popupRight, setPopupRight] = useState(0);
  const [popupHeight, setPopupHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updatePosition = () => {
    if (!isActive || !currentRef) return false;
    const currentRefRect = currentRef.getBoundingClientRect();
    const right = windowWidth - currentRefRect.right;
    const height = currentRefRect.top + 45;
    setPopupRight(right);
    setPopupHeight(height);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    updatePosition();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [currentRef, isActive]);

  return {
    popupRight,
    popupHeight,
    windowWidth,
    setPopupRight,
    setPopupHeight,
    setWindowWidth,
  };
};

export default usePopupWindow;
