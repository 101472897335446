import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ContainerInnerTab, LeftColumn, RightColumn, Title, ContentGenericList, Divider } from "./TeamsTab.styled";
import { InputText } from "../../../../../../elements";
import TextArea from "../../../../../../elements/v2/TextArea/TextArea";
import GenericList from "../../../../../../elements/v2/GenericList/GenericList";
import KnowledgeBaseTable from "../../../../../../elements/v2/KnowledgeBaseTable/KnowledgeBaseTable";
import { ChatComponent } from "../../../../AsistantManagement/components";
import AgentsTeam from "../AgentsTeam/AgentsTeam";
import ActionsList from "../../../../../../elements/v2/ActionsCatalog/ActionsList";
import { useStore } from "../../../../../../hooks";
import ModalAgentsDetail from "../../../../AsistantManagement/components/ModalAgentsDetail/ModalAgentsDetail";

const TeamsTab = observer(() => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { agentTreeStore } = useStore();
  const [localActions, setLocalActions] = useState([]);

  useEffect(() => {
    agentTreeStore.loadMockData();
  }, [agentTreeStore]);

  useEffect(() => {
    setLocalActions(agentTreeStore.actions);
  }, [agentTreeStore.actions]);

  const handleAgentClick = (agent) => {
    agentTreeStore.setSelectedAgent(agent);
    setIsModalOpen(true);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    agentTreeStore.setSelectedAgent(null);
  };

  const handleSaveActions = (newActions) => {
    setLocalActions(newActions);
    try {
      agentTreeStore.onChangeActions(newActions);
    } catch (error) {
      agentTreeStore.setError("Failed to save actions.");
    }
  };

  return (
    <ContainerInnerTab>
      <LeftColumn>
        <Title>Name</Title>
        <InputText
          value={agentTreeStore.name}
          onChange={(e) => agentTreeStore.setName(e.target.value)}
          placeholder="Enter name"
        />
        <Title>Description</Title>
        <TextArea
          value={agentTreeStore.instructions}
          onChange={(e) => agentTreeStore.setInstructions(e.target.value)}
          height="120px"
          resize="none"
          placeholder="Enter description"
        />
        <ContentGenericList>
          <GenericList
            title="Chat Starters"
            listItems={Object.values(agentTreeStore.conversationStarters)}
            newItem={(value) => agentTreeStore.addConversationStarter(value)}
            removeItem={(idx) => agentTreeStore.removeConversationStarter(idx)}
            placeholder={'Type your question starter here'}
          />
        </ContentGenericList>
        <AgentsTeam onAgentClick={handleAgentClick} />
        <KnowledgeBaseTable
          fileNames={agentTreeStore.files}
          vaultName={agentTreeStore.account}
          ownerName={agentTreeStore.name}
          onFilesChange={(files) => agentTreeStore.setFiles(files)}
          onError={() => (agentTreeStore.isError = true)}
          customer={agentTreeStore.account}
          editAgentStore={agentTreeStore}
          showAddButton={false}
          showDeleteIcon={false}
        />
        <br />
        {agentTreeStore.actions ? (
          <ActionsList agentActions={localActions} onSave={handleSaveActions} />
        ) : (
          <div>Error: No actions found</div>
        )}
      </LeftColumn>
      <Divider />
      <RightColumn>
        <ChatComponent store={agentTreeStore} />
      </RightColumn>
      <ModalAgentsDetail
        open={isModalOpen}
        onClose={toggleModal}
        store={agentTreeStore}
        width="800px"
      />
    </ContainerInnerTab>
  );
});

export default TeamsTab;
