import styled from 'styled-components';

const ShineThinkingContainer = styled.div`
    min-width: 100%;
    display: flex;
    align-items: center;

    .text-container {
        font-family: 'Inter';
        font-weight: bold;
        overflow: hidden;
        font-size: 14px;
        background: linear-gradient(90deg, #fff, #000, #fff);
        background-repeat: no-repeat;
        background-size: 80%;
        animation: animate 3s linear infinite;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: rgba(196, 196, 196, 0.5);
    }

    @keyframes animate {
        0% {
            background-position: -500%;
        }
        100% {
            background-position: 500%;
        }
    }
`;

export {
    ShineThinkingContainer,
}
