import { useCallback, useState } from "react";
import InputText from "../../../../elements/v2/TextInput/TextInput.jsx";
import { IconButton } from "../../../../elements/index.js";

import {
  DescriptionTextArea,
  Header,
  InputTitle,
  ManagerModalContainer,
  ModalTitle,
  SaveButton,
  SaveButtonContainer
} from "./ManagerModal.styled.js";

const ManagerModal = ({ left, top, onClose, onSave, managerNode }) => {
  const [name, setName] = useState(managerNode?.data?.label || "");
  const [description, setDescription] = useState(managerNode?.data?.description || "");
  const [title, setTitle] = useState(managerNode?.data?.title || "");
  const [nameError, setNameError] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const onHandleChangeName = useCallback((e) => {
    setName(e.target.value);
    setNameError(e.target.value.trim() === "");
  }, []);

  const onHandleDescriptionChange = useCallback((e) => {
    setDescription(e.target.value);
  }, []);

  const onHandleTitleChange = useCallback((e) => {
    setTitle(e.target.value);
    setTitleError(e.target.value.trim() === "");
  }, []);

  const handleOnSave = () => {
    if (name.trim() === "") {
      setNameError(true);
    }
    if (title.trim() === "") {
      setTitleError(true);
    }
    if (name.trim() !== "" && title.trim() !== "") {
      onSave({ id: managerNode?.id, name, description, title });
      onClose();
    }
  };

  return (
    <ManagerModalContainer left={left} top={top}>
      <Header>
        <ModalTitle>Sales Manager</ModalTitle>
        <IconButton icon="close" width={"13px"} height={"13px"} onClick={onClose} />
      </Header>

      <span>
        <InputTitle>Name</InputTitle>
        <InputText
          placeholder="Enter name"
          value={name}
          onChange={onHandleChangeName}
          displayError={nameError}
          validationError="Your agent needs a name before saving the changes."
          disabled={false}
          backgroundColor={"#FAFAFA"}
        />
      </span>

      <span>
          <InputTitle>Title</InputTitle>
          <InputText
            placeholder="Enter title"
            value={title}
            onChange={onHandleTitleChange}
            displayError={titleError}
            validationError="Your agent needs a title before saving the changes."
            disabled={false}
            backgroundColor={"#FAFAFA"}
          />
      </span>

      <span>
          <InputTitle>Description</InputTitle>
          <DescriptionTextArea
            height="120px"
            resize="none"
            value={description}
            onChange={onHandleDescriptionChange}
            placeholder="Enter description"
          />
      </span>

      <SaveButtonContainer>
        <SaveButton onClick={handleOnSave}>Save</SaveButton>
      </SaveButtonContainer>
    </ManagerModalContainer>
  );
}

export default ManagerModal;