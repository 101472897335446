import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../../../hooks';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '../../../../../../../elements';
import {
  CardWrapper,
  CardHeader,
  CardContent,
  CardRow,
  CardLabel,
  CardValue,
  DescriptionValue,
  DateRow,
  DateColumn,
} from "./CardInformation.styled";
import NewConversationModal from '../../../../../Conversations/components/NewConversationModal/NewConversationModal';

const CardInformation = observer(() => {
  const { viewConversationStore } = useStore();
  const [openNewConversationModal, setOpenNewConversationModal] = useState(false);
  const navigate = useNavigate();

  const handleConfirm = useCallback(() => {
    viewConversationStore.onCreate(navigate);
  }, [viewConversationStore, navigate]);

  const formatDate = (date) => date ? date.format('MM/DD/YYYY - HH:mm') : '---';

  return (
    <>
      <CardWrapper>
        <CardHeader>
          <h3>Event Information</h3>
          {!viewConversationStore.isReadMode() && <IconButton icon="editBlue" width="15px" height="15px" onClick={() => setOpenNewConversationModal(true)} />}
        </CardHeader>
        <CardContent>
          <CardRow>
            <CardLabel>Name:</CardLabel>
            <CardValue>{viewConversationStore.eventNameRead}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Event:</CardLabel>
            <CardValue>{viewConversationStore.eventsCatalog.find(event => event.id === viewConversationStore.sourceRead)?.name}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Description:</CardLabel>
            <DescriptionValue>{viewConversationStore.descriptionRead}</DescriptionValue>
          </CardRow>
          <h4>Activation Schedule</h4>
          <DateRow>
            <DateColumn>
              <CardLabel>Start date:</CardLabel>
              <CardValue>{formatDate(viewConversationStore.startDateRead)}</CardValue>
            </DateColumn>
            <DateColumn>
              <CardLabel>End date:</CardLabel>
              <CardValue>{formatDate(viewConversationStore.endDateRead)}</CardValue>
            </DateColumn>
          </DateRow>
        </CardContent>
      </CardWrapper>
      {openNewConversationModal && <NewConversationModal
        isOpen={openNewConversationModal}
        setIsOpen={setOpenNewConversationModal}
        showSourceSelect={true}
        buttonText='Save'
        onConfirm={handleConfirm}
      />}
    </>
  );
});

export default CardInformation;
