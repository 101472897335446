import styled from 'styled-components';
import { Icon } from '../icon';

export const CardWrapper = styled.div`
    background-color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.$selected ? props.theme.colors.clearBlue : props.theme.colors.silverGrayTwo};
    border-radius: 8px;
    padding: 1rem;
    width: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
`;

export const CardIcon = styled(Icon)`
    margin-bottom: 0.5rem;
`;

export const CardTitle = styled.h2`
    ${props => props.theme.fonts.variant.semiBold};
    font-size: 1.0rem;
    color: black;
    margin-bottom: 0.25rem;
`;

export const CardDescription = styled.p`
    font-size: ${props => props.theme.fonts.size.small};
    color: black;
`;
