import { useCallback } from "react";
import {IconWrapper} from "./RoundedIconButton.styled";
import {Icon} from "../../../icon";

const RoundedIconButton = ({ icon, width, height, onClick }) => {
    const handleClick = useCallback(() => {
        onClick && onClick();
    }, [onClick]);

    return (
        <IconWrapper
          onClick={handleClick} $height={height} $width={width}>
            <Icon name={icon} width={width} height={height} />
        </IconWrapper>
    );
};

export default RoundedIconButton;