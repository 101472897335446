import React, { useEffect, useState } from "react";
import { Switch } from '@mui/material';
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { CustomCheckbox } from "../../../elements";
import { useStore } from "../../../hooks";
import {
    FormTitle,
    FormAgentWrapper,
    CheckboxContainer,
    FormSubtitle,
} from "../NewAgentPage.styled"
import { initialActions, initialChannels } from "./consts";
import AgentActions from "../../AgentPage/components/AgentActions/AgentActions";

const AgentChannelForm = observer(() => {
    const { newAgent } = useStore();

    const [channelOptions, setChannelOptions] = useState(initialChannels)
    const [actions, setActions] = useState(initialActions)

    const onSelectChannel = (channel) => {
        const updatedOptions = { ...channelOptions, ...channel }
        setChannelOptions(updatedOptions)
        const filteredChannels = Object.keys(updatedOptions).filter(channel => updatedOptions[channel]);
        if (filteredChannels) {
            newAgent.onChangeChannels(filteredChannels);
        }
    }

    const onSelectAction = (action) => {
        const newActions = { ...actions, [action]: !actions[action] }
        setActions(newActions)
        const activatedActions = Object.keys(newActions).filter(action => newActions[action]);
        newAgent.onChangeActions(activatedActions);
    }

    useEffect(() => {
        if (toJS(newAgent.channels)) {
            const savedChannels = { ...initialChannels };
            toJS(newAgent.channels).map(channel => {
                if (channel in savedChannels) {
                    savedChannels[channel] = true
                }
            })

            setChannelOptions({ ...savedChannels })
        }
    }, [newAgent.channels.length, newAgent.name, newAgent.channels])

    return (
        <FormAgentWrapper>
            <FormTitle>You're almost there!</FormTitle>
            <FormSubtitle>Okay, decide which channel you want your agent to go through!</FormSubtitle>
            <CheckboxContainer>
                {channelOptions && Object.keys(channelOptions).map(option =>
                    <CustomCheckbox
                        key={option}
                        label={option}
                        onSelectTone={onSelectChannel}
                        textTransform='uppercase'
                        options={channelOptions}
                        disabled={(option !== 'sms' && channelOptions['sms']) ||
                            (option === 'sms' && Object.keys(channelOptions).filter(channel => channel !== 'sms' && channelOptions[channel]).length)}
                    />
                )}
            </CheckboxContainer>
            <>
                <FormSubtitle>Which actions do you want your agent to perform?</FormSubtitle>
                <AgentActions actionsValues={actions} onSelectAction={onSelectAction} />
            </>
        </FormAgentWrapper>
    );
});

export default AgentChannelForm;
