import styled, { css } from "styled-components";

export const Avatar = styled.img`
  width: 5.5vw;
  height: 5.5vw;
  max-width: 6.8rem;
  max-height: 6.8rem;
  border-radius: 9999px;
`;

export const UserContainer = styled.div`
    border: 1.3px solid ${(props) => props.theme.colors.silverGrayTwo};
    width: 100%;
    max-width: 355px;
    border-radius: 10px;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const Header = styled.div`
    display: flex;
    
    align-items: flex-start;
    width: 100%;
`;

export const Name = styled.div`
    color: ${(props) => props.theme.colors.primary};
    font-size: min(1.1vw, 18px);
    font-weight: 700;
`

export const Telephone = styled.div``;

export const ContactInfo = styled.div`
    color: ${(props) => props.theme.colors.greyTwo};
    font-size: min(1vw, 14px);
    font-weight: 500;
    margin: 0.5rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
`;

export const HeaderContactInfo = styled(ContactInfo)`
    margin-left: 1.5rem;
    height: 2rem;
`;

export const Email = styled.div`
    font-weight: 300;
`;

export const Title = styled.div`
    color: ${(props) => props.theme.colors.greyTwo};
`;
export const UserInfoLine = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Value = styled.div`
    font-weight: 300;
`;

export const Warranty = styled.div`
    font-size: min(1.1vw, 18px);
    font-weight: 700;
`;

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 10;

    ${(props) => props.isOpen && css`
        display: flex;
    `}
`;

export const AlingIcon = styled.div`
    margin-left: 20%;
`;

