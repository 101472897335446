import React from "react";
import { Route, Routes } from "react-router-dom";
import RoleList from "./RolesList";
import RoleGeneral from "./components/RoleGeneral/RoleGeneral";


const RolesPage = () => {
  return (
    <Routes>
      <Route index element={<RoleList />} />
      <Route path="/details/:roleId" element={<RoleGeneral />} />
    </Routes>
  );
};

export default RolesPage;
