import styled from 'styled-components';

const RuleChoiceGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  justify-content: flex-start;
`;

export {
  RuleChoiceGroupWrapper
};