import styled from 'styled-components';

export const ContainerPage = styled.div`
  margin-left: 20px;
  margin-top: 20px;
  width: 100%;
`;

export const Label = styled.label`
  
  font-size: 13px;
  color: ${props => props.theme.colors.darkCharcoal};
`;