import styled from "styled-components";

const EditAgentWrapper = styled.div`
    padding: 1vw 5vw 0 5vw;
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
`;

const AgentIcon = styled.img`
    border-radius: 50%;
    width: 9.5vw;
    height: 9.5vw;
`;

const CheckboxContainer = styled.div`
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
    margin-bottom: 30px
`;

const EditMessageInput = styled.textarea`
    resize: none;
    padding: 5px 10px;
    border-radius: 10px;
    width: ${props => props.$width || '29.5vw'};
    &:focus {
        outline: none;
    }
`

const Label = styled.div`
    ${props => props.theme.fonts.variant.light};
    font-size: ${props => props.fontSize || props.theme.fonts.size.small};
    color: ${props => props.theme.colors.grey};
    margin: ${props => props.margin || '10px 0'};
    font-weight: ${props => props.bold || '600'};
    text-align: justify;
`;

const FormButtonsContainer = styled.div`
    display: flex;
    width: 460px;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;

    button:last-child {
        margin-left: 10px
    }
`;

const PageWrapper = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center
`;

const RadioButtonsContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 20px;
`;

export const Action = styled.div`
    font-size: 14px;
    display: grid;
    grid-template-columns: 200px 200px;
`


export {
    EditAgentWrapper,
    AgentIcon,
    CheckboxContainer,
    EditMessageInput,
    Label,
    FormButtonsContainer,
    PageWrapper,
    RadioButtonsContainer,
}