import styled from 'styled-components';

export const RulesRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: ${(props) => props.theme.colors.veryLightGray};
`;

export const RulesLabel = styled.div`
  font-weight: bold;
  
`;

export const RulesCount = styled.div`
  margin-left: auto;

  span {
    font-weight: bold;
  }

  span.value {
    font-weight: normal;
    margin-left: 5px;
  }
`;

export const ArrowIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
`;
