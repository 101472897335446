import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { InputText } from "../../../elements";
import { useStore } from "../../../hooks";
import { FormTitle, FormSubtitle, FormUserWrapper, FormInputContainer } from "../NewUserPage.Styled";

const UserNameForm = observer(() => {
    const { userStore } = useStore();

    const onHandleChangeFirsName = useCallback((e) => {
        userStore.page.onChangeFirstName(e.target.value);
    }, [userStore.page]);

    const onHandleChangeLastName = useCallback((e) => {
        userStore.page.onChangeLastName(e.target.value);
    }, [userStore.page]);

    const onBlurFirstName = useCallback(() => {
        userStore.page.onValidateTextField('firstName');
    }, [userStore.page]);

    const onBlurLastName = useCallback(() => {
        userStore.page.onValidateTextField('lastName');
    }, [userStore.page]);

    return (
        <FormUserWrapper>
            <FormTitle>Hello! I'm your ANN agent, and I'm here to guide you through creating a new user. <br/> Let's get started!</FormTitle>
            <FormSubtitle>Let's give your new user a first and last name. <br/> This way, you'll be able to identify them more easily.</FormSubtitle>
            <FormInputContainer>
                <InputText
                    type="text"
                    value={userStore.page.firstName}
                    placeholder="First Name"
                    onChange={onHandleChangeFirsName}
                    onBlur={onBlurFirstName}
                    width="200px"
                    margin="80px 0 40px 0"
                />
                {userStore.page.firstNameError && <span>{userStore.page.firstNameError}</span>}
                <InputText
                    type="text"
                    value={userStore.page.lastName}
                    placeholder="Last Name"
                    onChange={onHandleChangeLastName}
                    onBlur={onBlurLastName}
                    width="200px"
                    margin="80px 0 40px 0"
                />
                {userStore.page.lastNameError && <span>{userStore.page.lastNameError}</span>}
            </FormInputContainer>
        </FormUserWrapper>
    );
});

export default UserNameForm;