import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import GenericNode from './GenericNode';


export default memo(({ data }) => {
  return (
    <>
      <GenericNode data={data} />
      <Handle
        type="target"
        position={Position.Top}
        id={`agent-${data.id}-target`}
        isConnectable={false}
      />
    </>
  );
});
