import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    width: 152px;
    font-size: 12px;
`;

const IconButton = styled.img`
    cursor: pointer;
    margin-top: 70px;
`;

const Menu = styled.ul`
    position: absolute;
    top: 0;
    margin-left: 10%;
    margin-top: 20%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    list-style: none;
`;

const MenuItem = styled.li`
    display: flex;
    padding: 0px 16px;
    cursor: pointer;

    &:hover {
        background-color: #f5f5f5;
    }
`;

const OptionIcon = styled.img`
    width: 15px;
    height: 24px;
    margin-right: 8px;
    margin-top: 2px;
`;

const OptionTitle = styled.span`
    font-size: 12px;
    margin-top: 4px;
`;


export {
    Container,
    IconButton,
    Menu,
    MenuItem,
    OptionIcon,
    OptionTitle
}