import styled from "styled-components";

const FeatureLibraryContainer = styled.div`
    background-color: ${(props) => props.theme.colors.highClearGray};
    height: 45vh;
    padding: 5px;
    margin-top: 5px;
`;



export {
    FeatureLibraryContainer
}
