import { StyledLabel, Wrapper, StyledRadio } from "./IconRadioButton.styled";


const IconRadioButton = ({ title, description, img, value, name, onChange, checked }) => {
    return (
        <Wrapper checked={checked} onClick={() => onChange(value)}>
            <StyledRadio img={img} type="radio" id="radio-button" value={value} name={name} checked={checked} onChange={() => onChange(value)}/>
            <StyledLabel onClick={() => onChange(value)} checked={checked}>{title} <br/> {description || <br/>}</StyledLabel>
       </Wrapper>    
    );
}

export default IconRadioButton;
