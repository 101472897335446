import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  MainContainer, BreadCrumbContainer, MenuContainer,
  Title, UserColumnContainer, SideColumn, MainColumn, TitleName, CustomSearchInput, InputField,
  SearchIcon, CustomGrid, StyledIconButton, TabContainer, HiddenContainer
} from "./GroupsGeneral.styled";
import GroupDetails from "../GroupDetails/GroupDetails";
import { RoundedButton, Icon } from '../../../../../elements';
import Tabs from '../../../../../elements/v2/Tabs/Tabs';
import ActionSelect from "../../../UsersPage/UsersOerview/components/ActionSelect/ActionSelect";
import DeleteIcon from "../../../../../elements/icon/assets/delete_outline.svg";
import DuplicateIcon from "../../../../../elements/icon/assets/duplicate_outline.svg";

import SuccessModal from '../../../../../elements/v2/SuccessModal/SuccessModal';
import { dummyPermissions } from '../../../UsersPage/NewUserPage/consts';
import { useStore } from '../../../../../hooks';
import ConfirmationWrapper from "../../../AgentsTeam/Team/Components/ConfirmationWrapper/ConfirmationWrapper";

import { ModalFilter } from "../../../AsistantManagement/components";

import GroupUserTable from "../GroupTableUsers/GroupUserTable";
import GroupCreateModal from "../GroupCreateModal/GroupCreateModal"; 
import AddMemberModal from "../../../RolesPage/components/AddMembersModal/AddMemberModal";
import GroupPermission from "../GroupPermissions/GroupPermission";
import GroupsTable from "../GroupsTable/GroupsTable";


const GroupGeneral = () => {
  const { groupId } = useParams();
  const { listGroupStore, permissionsSetupStore } = useStore();
  const [selectedTab, setSelectedTab] = useState(0);
  const [groupDetails, setGroupDetails] = useState(null);
  const [originalGroupDetails, setOriginalGroupDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successModalContent, setSuccessModalContent] = useState({ isOpen: false, title: '', message: '' });
  const [actionConfig, setActionConfig] = useState(null);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false); 
  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  
  const discardHandlerRef = useRef(null);

  useEffect(() => {
    permissionsSetupStore.setupPermissionsCatalog(dummyPermissions);

    const fetchGroupDetails = () => {
      listGroupStore.fetchGroupData(groupId)
        .then(() => {
          setGroupDetails(listGroupStore.row);
          setOriginalGroupDetails({ ...listGroupStore.row });
        })
        .catch((error) => {
          listGroupStore.setError('Error fetching group details:', error);
        });
    };

    fetchGroupDetails();
  }, [groupId, listGroupStore, permissionsSetupStore]);

  const handleFieldChange = (field, value) => {
    setGroupDetails({
      ...groupDetails,
      [field]: value,
    });
  };

  const handleSave = () => {
    openConfirmationModal({
      name: 'Save Changes',
      title: 'Save Changes',
      message: 'Are you sure you want to save the changes?',
      icon: 'info',
      onConfirm: confirmSave,
    });
  };

  const confirmSave = () => {
    listGroupStore.onPutGroup(groupDetails)
      .then(() => {
        setSuccessModalContent({
          isOpen: true,
          title: 'Success',
          message: 'Changes have been saved successfully!',
        });
        setOriginalGroupDetails({ ...groupDetails });
      })
      .catch((error) => {
        listGroupStore.setError("Error saving group");
        setSuccessModalContent({
          isOpen: true,
          title: 'Error',
          message: 'An error occurred while saving.',
        });
      })
      .finally(() => {
        setIsModalOpen(false);
        setTimeout(() => {
          setSuccessModalContent((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }, 5000);
      });
  };

  const handleDiscard = () => {
    setGroupDetails({ ...originalGroupDetails });
    if (discardHandlerRef.current) {
      discardHandlerRef.current();
    }
  };

  const handleRemove = () => {
    openConfirmationModal({
      name: 'Remove',
      title: 'Remove',
      message: 'Caution: You are about to remove the following group. Are you sure you want to proceed?',
      icon: 'deleteOutline',
      onConfirm: confirmRemove,
    });
  };

  const confirmRemove = () => {
    listGroupStore.onDeleteGroup(groupId)
      .then(() => {
        setSuccessModalContent({
          isOpen: true,
          title: 'Great',
          message: 'The group has been deleted from the system.',
        });
      })
      .catch((error) => {
        listGroupStore.setError("Error removing group");
        setSuccessModalContent({
          isOpen: true,
          title: 'Error',
          message: 'An error occurred while removing the group.',
        });
      })
      .finally(() => {
        setIsModalOpen(false);
  
        setTimeout(() => {
          setSuccessModalContent((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }, 5000);
      });
  };

  const handleDuplicate = () => {
    openConfirmationModal({
      name: 'Duplicate',
      title: 'Duplicate',
      message: 'Caution, you are about to duplicate the next group. Are you sure about this?',
      icon: 'duplicateOutline',
      onConfirm: confirmDuplicate,
    });
  };

  const confirmDuplicate = () => {
    const newGroup = {
      ...groupDetails,
      id: null,
      groupName: `${groupDetails.groupName} (Copy)`,
    };
  
    listGroupStore.onPutGroup(newGroup)
      .then(() => {
        setSuccessModalContent({
          isOpen: true,
          title: 'Great',
          message: 'The group has been successfully duplicated.',
        });
      })
      .catch((error) => {
        listGroupStore.setError("Error duplicating group");
        setSuccessModalContent({
          isOpen: true,
          title: 'Error',
          message: 'An error occurred while duplicating the group.',
        });
      })
      .finally(() => {
        setIsModalOpen(false);
  
        setTimeout(() => {
          setSuccessModalContent((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }, 5000);
      });
  };

  const openConfirmationModal = (config) => {
    setActionConfig(config);
    setIsModalOpen(true);
  };

  const handleActionSelect = (name, value) => {
    if (value === 'reset') {
      setSelectedTab(1);
    } else if (value === 'remove') {
      handleRemove();
    } else if (value === 'duplicate') {
      handleDuplicate();
    }
  };

  const actionOptions = [
    { name: 'Remove', value: 'remove', icon: DeleteIcon },
    { name: 'Duplicate', value: 'duplicate', icon: DuplicateIcon },
  ];

  const tabs = [
    {
      label: 'General',
      content: (
        <GroupDetails groupDetails={groupDetails} onFieldChange={handleFieldChange} onDiscard={handler => discardHandlerRef.current = handler} />
      ),
    },
    {
      label: 'Access',
      content: (
      <GroupPermission />
      )
    },
    {
      label: 'Members',
      content: (
        <GroupUserTable searchValue={searchValue} />
        )
    },
    {
        label: 'Groups',
        content: (
          <GroupsTable searchValue={searchValue} />
        )
      },
  ];

  const handleAddMembers = () => {
    setIsAddMemberModalOpen(true);
  };

  const handleAddGroups = () => {
    setIsAddGroupModalOpen(true);
  };

  const handleCloseAddMemberModal = () => {
    setIsAddMemberModalOpen(false);
  };

  const handleAddGroupsToStore = (selectedGroups) => {
    setIsAddMemberModalOpen(false);
  };

  const handleCloseAddGroupModal = () => {
    setIsAddGroupModalOpen(false);
  };

  const calculateTabWidth = (tabIndex) => {
    switch(tabIndex) {
        case 0:
            return '92%';
        case 1:
            return '100%';
        case 2:
            return '90%';
        case 3:
            return '90%';
        default:
            return '100%';
        }
    };

  return (
    <>
      <MainContainer>
        <BreadCrumbContainer>
          <Title>Group &gt; </Title>
          <TitleName>{groupDetails?.groupName || 'Group'}</TitleName>
        </BreadCrumbContainer>
        <MenuContainer>
          <RoundedButton width='60px' height='30px' fontSize='12px' outline={true} onClick={handleDiscard}>
            Discard
          </RoundedButton>
          <RoundedButton width='80px' height='30px' fontSize='12px' color='#2173FF' onClick={handleSave}>
            Save
          </RoundedButton>
        </MenuContainer>
      </MainContainer>

      {selectedTab === 2 && (
        <CustomGrid>
          <div>
            <CustomSearchInput width='50px'>
              <InputField
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search"
              />
              <SearchIcon>
                <Icon name={"magnifier"} height={'20px'} width={'20px'} />
              </SearchIcon>
            </CustomSearchInput>
          </div>
          <div>
            <StyledIconButton aria-controls="custom-dropdown" aria-haspopup="true" >
              <Icon name={"tuneIcon"} height={'15px'} width={'15px'} />
            </StyledIconButton>

            <ModalFilter

              title="Filter"
              modalWidth="250"
            />
          </div>
        </CustomGrid>
      )}

    {selectedTab === 3 && (
        <CustomGrid>
          <div>
            <CustomSearchInput width='50px'>
              <InputField
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search"
              />
              <SearchIcon>
                <Icon name={"magnifier"} height={'20px'} width={'20px'} />
              </SearchIcon>
            </CustomSearchInput>
          </div>
          <div>
            <StyledIconButton aria-controls="custom-dropdown" aria-haspopup="true" >
              <Icon name={"tuneIcon"} height={'15px'} width={'15px'} />
            </StyledIconButton>

            <ModalFilter

              title="Filter"
              modalWidth="250"
            />
          </div>
        </CustomGrid>
      )}

      <UserColumnContainer>
        <MainColumn>
          
        </MainColumn>
        <SideColumn>
          {selectedTab === 2 ? (
            <RoundedButton width='120px' height='30px' fontSize='12px' onClick={handleAddMembers}>
              Add Members
            </RoundedButton>
          ) : selectedTab === 3 ? (
            <RoundedButton width='120px' height='30px' fontSize='12px' onClick={handleAddGroups}>
              Add Groups
            </RoundedButton>
          ) : selectedTab === 1 ? (
            <HiddenContainer isHidden={true}>
              <ActionSelect
                placeholder="Actions"
                options={actionOptions}
                handleAction={handleActionSelect}
              />
            </HiddenContainer>
          ) : (
            <ActionSelect
              placeholder="Actions"
              options={actionOptions}
              handleAction={handleActionSelect}
            />
          )}
        </SideColumn>
      </UserColumnContainer>

      {isAddMemberModalOpen && (
        <AddMemberModal
          onAddGroups={handleAddGroupsToStore}
          onClose={handleCloseAddMemberModal}
        />
      )}

      {isAddGroupModalOpen && (
        <GroupCreateModal open={isAddGroupModalOpen} handleClose={handleCloseAddGroupModal} />
      )}

      <ConfirmationWrapper
        isOpen={isModalOpen}
        actionConfig={actionConfig}
        onConfirm={actionConfig?.onConfirm}
        onClose={() => setIsModalOpen(false)}
      />

      <SuccessModal
        isOpen={successModalContent.isOpen}
        icon="check_circle"
        onClose={() => setSuccessModalContent({ ...successModalContent, isOpen: false })}
        title={successModalContent.title}
        message={successModalContent.message}
      />
        <TabContainer>
                <Tabs
                    tabs={tabs}
                    externalActiveTab={selectedTab}
                    onToggleTab={setSelectedTab}
                    showBorder={true}
                    width={calculateTabWidth(selectedTab)}
                />
            </TabContainer>
        
    </>
  );
};

export default GroupGeneral;
