import { styled } from "styled-components";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 120px;
    height: 180px;
    border-radius: 8px;
    cursor: pointer;
`;

const StyledLabel = styled.label`
    font-size: ${(props) => props.theme.fonts.size.small};
    margin-top: 3px;
    width: 110px;
    color: ${(props) => props.theme.colors.black};
    cursor: pointer;
`;

const StyledRadio = styled.input`
    appearance: none;
    width: 102px;
    height: 118px ;
    border: 2px solid ${(props) => props.theme.colors.greyFour};
    background-color: ${(props) => props.checked ? props.theme.colors.greyFour: props.theme.colors.white};
    border-radius: 20px;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    background-image: url(${(props) => props.img});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 79px 88px;
    opacity: ${(props) => props.checked ? 1 : 0.65};
`;

export {
    StyledLabel,
    Wrapper,
    StyledRadio
}