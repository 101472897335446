import styled from "styled-components";

export const muiTablePaperProps = {
  sx: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100%",
  },
};

export const TableGenericStyles = {
  muiTableHeadCellProps: {
    align: "center",
  },
  muiTableBodyCellProps: {
    align: "center",
    sx: {
      fontSize: "12px",
    },
  },
};

export const TableStyle = {
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  height: "100%",
  paddingRight: "24px",
};

export const TableHeaderStyle = {
  paddingY: "8px",
  justifyContent: "center",
  backgroundColor: "#F2F2F2",
  fontSize: "14px",
  fontWeight: "600",
};

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  text-transform: capitalize;

  color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.black};

  &.active {
    background-color: ${(props) => props.theme.colors.greenPrimary};
  }
`;

export const SegmentTableWrapper = styled.div`
  display: block;
  width: calc(100% - 200px);
`;
