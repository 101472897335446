import React, { useCallback, useState, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useStore } from "../../../hooks";
import { useMediaQueries } from "../../../utils/mediaQueries";
import { StyledContainer, TableWrapper, headCellStyle, bodyCellStyle, toolbarStyle, tableContainerStyle, tablePaperStyle } from "../../../elements/v2/Tables/Table.styled";
import { SuccessfulModal } from "../../../elements";
import { IconButton, Tooltip, Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

const VehiclePage = observer(() => {
    const { changeTitle, vehicleStore, authStore, annUnifiedCustomerProfileStore } = useStore();
    const navigate = useNavigate();
    changeTitle('Vehicles');
    const { isMobile, isTablet, isDesktop } = useMediaQueries();

    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([{ id: 'year', desc: true }]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [isLoading, setIsLoading] = useState(false);

    const loadData = useCallback(async () => {
        setIsLoading(true);
        try {
            await vehicleStore.fetchVehicles({
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
                sortBy: sorting,
                search: globalFilter,
            });
        } catch (error) {
            vehicleStore.setError(true);
        } finally {
            setIsLoading(false);
        }
    }, [pagination, sorting, globalFilter, vehicleStore]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const filteredVehicles = useMemo(() => vehicleStore.filteredVehicles, [vehicleStore.filteredVehicles]);

    const columns = useMemo(() => [
        { accessorKey: "make", header: "Make" },
        { accessorKey: "model", header: "Model" },
        { accessorKey: "year", header: "Year" },
        { accessorKey: "vin", header: "VIN", size: 200 },
        { accessorKey: "owner", header: "Owner" },
        { accessorKey: "color", header: "Color" },
    ], []);

    const onHandleUnifiedCustomerProfile = useCallback((vehicleId) => {
        const urlForUnified = authStore.selectedAccount.metadata.domain === 'ANN' ? "/ann-unified-profile" : "/unified-profile";
        navigate({
            pathname: urlForUnified,
            search: createSearchParams({ id: vehicleId }).toString(),
        });
    }, [authStore.selectedAccount, navigate]);

    const table = useMaterialReactTable({
        columns,
        data: filteredVehicles,
        manualSorting: true,
        manualGlobalFilter: true,
        rowCount: vehicleStore.totalRowCount || 0,
        initialState: { sorting },
        muiTableContainerProps: { sx: tableContainerStyle },
        muiTablePaperProps: { elevation: 0, sx: tablePaperStyle },
        muiTableHeadCellProps: { sx: headCellStyle },
        muiTableBodyCellProps: { sx: bodyCellStyle },

        muiTableBodyRowProps: ({ row }) => ({
            onClick: () => {
                const vehicleId = row.original.id;
                annUnifiedCustomerProfileStore.onClearUCP();
                onHandleUnifiedCustomerProfile(vehicleId);
            },
            style: { cursor: 'pointer' }
        }),

        renderRowActions: ({ row }) => (
            <Box>
                <Tooltip title="View">
                    <IconButton onClick={() => onHandleUnifiedCustomerProfile(row.original.id)}>
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),

        muiTopToolbarProps: { sx: toolbarStyle },
        muiSearchTextFieldProps: {
            variant: 'outlined',
            placeholder: 'Search',
            InputProps: { style: { fontSize: '12px' } },
        },
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableColumnResizing: false,
    });

    return (
        <StyledContainer isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}>
            <TableWrapper>
                <MaterialReactTable table={table} />
            </TableWrapper>
            {vehicleStore.isError && (
                <SuccessfulModal
                    isOpen={vehicleStore.isError}
                    onClose={vehicleStore.clearError}
                    title="Oops!"
                    subtitle="An error occurred."
                    subtitle2="Please try again later."
                />
            )}
        </StyledContainer>
    );
});

export default VehiclePage;
