import { action, flow, makeObservable, observable } from "mobx";

class VehicleStore {
  vehicles = [];
  filteredVehicles = [];
  totalRowCount = 0;
  isLoading = false;
  isError = false;
  currentPage = 1;
  pageSize = 10;

  constructor() {
    makeObservable(this, {
      vehicles: observable,
      filteredVehicles: observable,
      totalRowCount: observable,
      isLoading: observable,
      isError: observable,
      currentPage: observable,
      pageSize: observable,
      fetchVehicles: flow,
      setError: action,
      clearError: action,
      setPage: action,
      filterVehicles: action,
    });
  }

  *fetchVehicles() {
    this.isLoading = true;
    this.isError = false;

    try {
      yield new Promise((resolve) => setTimeout(resolve, 500));

      const mockData = [
        { id: "c28f5b07-4ea2-40c4-87bf-296491db89e2", make: "Ford", model: "F-150", year: 2024, vin: "2939485967", owner: "John Doe", color: "Red" },
        { id: "231cb973-161e-4a4b-b37c-7813679616e8", make: "Toyota", model: "Camry", year: 2023, vin: "5847392048", owner: "Jane Smith", color: "Blue" },
        { id: "328ed37b-bc2b-4b82-89e7-4392989a3aeb", make: "Chevrolet", model: "Silverado", year: 2022, vin: "9473028475", owner: "Alice Johnson", color: "Black" },
        { id: "a332a86c-8f9e-402d-a2b4-4f19d89ae7b2", make: "Honda", model: "Civic", year: 2021, vin: "2039485764", owner: "Robert Brown", color: "White" },
        { id: "b6b71da4-7fdd-411d-9982-0fc373c34580", make: "Tesla", model: "Model S", year: 2020, vin: "1293847562", owner: "Mary Davis", color: "Silver" },
        { id: "9a9de121-5e20-46db-89e6-f4783774b5dd", make: "Nissan", model: "Altima", year: 2019, vin: "8374920561", owner: "Patricia Wilson", color: "Gray" },
        { id: "e8fb233e-7143-4559-9458-bb47f0adcaad", make: "BMW", model: "X5", year: 2018, vin: "3847569201", owner: "Michael Martinez", color: "Blue" },
        { id: "dd61492e-76a4-4c37-9658-7e682cff173e", make: "Audi", model: "A4", year: 2017, vin: "9483726150", owner: "William Anderson", color: "Black" },
        { id: "13db2599-08b9-40d1-b043-fd92399dc2d7", make: "Mercedes-Benz", model: "C-Class", year: 2016, vin: "5738290146", owner: "Linda Taylor", color: "White" },
        { id: "9ffe322f-4141-4186-a63b-d334a75c6dd9", make: "Hyundai", model: "Elantra", year: 2015, vin: "2094857163", owner: "David Thomas", color: "Red" },
        { id: "2938475610-2938-4756-2938-847561029384", make: "Ford", model: "Mustang", year: 2014, vin: "6748392015", owner: "Barbara Jackson", color: "Yellow" },
        { id: "847561029384-8475-6102-9384-756102938475", make: "Chevrolet", model: "Malibu", year: 2013, vin: "4839205761", owner: "James Harris", color: "Green" },
        { id: "2938475610-2938-4756-2938-293847561029", make: "Toyota", model: "Corolla", year: 2012, vin: "5839201746", owner: "Elizabeth Clark", color: "Blue" },
        { id: "847561029384-8475-6102-8475-847561029384", make: "Volkswagen", model: "Passat", year: 2011, vin: "2039486751", owner: "Christopher Lewis", color: "Gray" },
        { id: "847561029384-8475-8475-8475-847561029384", make: "Mazda", model: "CX-5", year: 2010, vin: "9483726150", owner: "Karen Young", color: "Silver" },
        { id: "2039485764-8475-2938-8475-2039485764", make: "Subaru", model: "Impreza", year: 2009, vin: "5748293015", owner: "Steven Hall", color: "Red" },
        { id: "2938475610-9384-8475-8475-2938475610", make: "Jeep", model: "Wrangler", year: 2008, vin: "2938475610", owner: "Sarah Allen", color: "Black" },
        { id: "475610293847-4756-2938-9384-475610293847", make: "Dodge", model: "Ram", year: 2007, vin: "4029384756", owner: "Paul Scott", color: "White" },
        { id: "847561029384-9384-8475-6102-9384847561", make: "Kia", model: "Sorento", year: 2006, vin: "1203948576", owner: "Nancy Wright", color: "Green" },
        { id: "9483726150-9384-8475-8475-9483726150", make: "Honda", model: "Accord", year: 2005, vin: "5829301476", owner: "Mark Lopez", color: "Blue" }
      ];

      this.vehicles = mockData;
      this.filteredVehicles = mockData;
      this.totalRowCount = mockData.length;
    } catch (error) {
      this.setError(true);
    } finally {
      this.isLoading = false;
    }
  }

  setError(isError) {
    this.isError = isError;
  }

  clearError() {
    this.isError = false;
  }

  setPage(page) {
    this.currentPage = page;
  }

  filterVehicles(filters) {
    if (!filters || filters.length === 0) {
      this.filteredVehicles = this.vehicles;
    } else {
      this.filteredVehicles = this.vehicles.filter(vehicle =>
        filters.every(filter => vehicle[filter.key] === filter.value)
      );
    }
  }
}

export default VehicleStore;
