import React from 'react';
import { ConfirmationModal } from '../../../../../../elements/v2/modals';


const ConfirmationWrapper = ({ isOpen, actionConfig, onConfirm, onClose }) => {
  if (!actionConfig) return null;

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={actionConfig.title}
      message={actionConfig.message}
      icon={actionConfig.icon}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  );
};

export default ConfirmationWrapper;
