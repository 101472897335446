import React, { useState } from "react";
import { useStore } from "../../../../../hooks";
import {
  Overlay,
  ModalContainer,
  ModalHeader,
  IconCloseContainer,
  ModalBody,
  StyledTextField,
  StyledTextarea,
  DescriptionLabel,
  ModalFooter,
  StyledButton,
  CharacterCount,
} from "./GroupCreateModal.styled";

const GroupCreateModal = ({ open, handleClose }) => {
  const { listRoleStore } = useStore();
  const [roleName, setRoleName] = useState("");
  const [description, setDescription] = useState("");
  const maxDescriptionLength = 1500;

  if (!open) return null;

  const handleCreate = () => {
    listRoleStore.createRole(roleName, description);
    handleClose(); 
  };

  return (
    <Overlay>
      <ModalContainer>
        <ModalHeader>
          <DescriptionLabel>Name</DescriptionLabel>
          <IconCloseContainer onClick={handleClose}>
            &times;
          </IconCloseContainer>
        </ModalHeader>

        <ModalBody>
          <StyledTextField
            type="text"
            placeholder="Name"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
          />

          <DescriptionLabel>Description - Optional</DescriptionLabel>
          <StyledTextarea
            placeholder="Enter Description"
            rows="4"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <CharacterCount>
            {description.length}/{maxDescriptionLength}
          </CharacterCount>
        </ModalBody>

        <ModalFooter>
          <StyledButton
            onClick={handleCreate}
            disabled={roleName.trim() === "" || description.length > maxDescriptionLength}
          >
            Create
          </StyledButton>
        </ModalFooter>
      </ModalContainer>
    </Overlay>
  );
};

export default GroupCreateModal;
