import styled from 'styled-components';

export const SelectContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 150px;
`;

export const SelectButton = styled.button`
  width: 100%;
  padding: 8px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.cerebralGray};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

export const IconContainer = styled.span`
  position: relative;
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: 240px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.cerebralGray};
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin-top: 5px;
`;

export const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 6px;

  &:hover {
    background-color: ${(props) => props.theme.colors.veryLightGray};
  }
`;

export const StyledOption = styled.span`
  font-weight: ${(props) => (props.isAllOption ? 'bold' : 'normal')};
  margin-left: ${(props) => (props.isAllOption ? '5px' : '20px')};
`;

export const Checkbox = styled.input`
  transform: scale(1.2);
  cursor: pointer;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.dividerGray};
  margin: 10px 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
`;
