import React from "react";
import { Route, Routes } from "react-router-dom";

import ListKey from "./ListKey";

const KeyPage = () => {
  return (
    <Routes>
      <Route index element={<><ListKey /></>} />
    </Routes>
  );
};


export default KeyPage;
