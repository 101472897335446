import styled from 'styled-components';

export const ViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 5px;
    margin-left: 30px;
`;

export const TabsContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    height: 100%;
`;

export const Label = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
  
`;
