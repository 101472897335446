import React, {useState, useEffect} from "react";
import {observer} from "mobx-react";
import {CustomRadioButton} from "../../../elements";
import {useStore} from "../../../hooks";
import { FormTitle, FormSubtitle, FormAgentWrapper, RadioButtonsContainer } from "../NewAgentPage.styled"

const AgentScopeForm = observer(() => {
    const { newAgent } = useStore();

    const [ 
        selectedValue, 
        setSelectedValue, 
    ] = useState(""); 
  
    const handleRadioChange = ( 
        value 
    ) => { 
        setSelectedValue(value); 
        newAgent.onChangeScope(value);
    }; 

    useEffect(() => {
        setSelectedValue(newAgent.scope)
    }, [])

    const isChecked = (value) => value == selectedValue

    return (
        <FormAgentWrapper>
            <FormTitle>It's amazing!</FormTitle>
            <FormSubtitle>Decide what kind of agent you want to create.</FormSubtitle>
            <RadioButtonsContainer mb='60px' mt='80px'>
                <CustomRadioButton label="Internal" value="internal" onChange={handleRadioChange} selectedValue={selectedValue} checked={isChecked('internal')}/>
                <CustomRadioButton label="Public" value="public" onChange={handleRadioChange} selectedValue={selectedValue} checked={isChecked('public')}/>
                <CustomRadioButton width={'240px'} label="Organizational" value="organizational" onChange={handleRadioChange} selectedValue={selectedValue} checked={isChecked('organizational')} />
            </RadioButtonsContainer>
        </FormAgentWrapper>
    );
});

export default AgentScopeForm;
