export const dummyPermissions = [
	{
		"name": "Agents",
		"description": "Agents Module that includes Vaults, Actions, Agents and Teas",
		"code": "AGENTS_MODULE",
		"permission_levels": ["VIEW", "UPDATE", "CREATE", "DELETE"],
		"child_permissions": [
			{
				"name": "Playground",
				"description": "Allows to use the playground to test agents",
				"code": "PLAYGROUND_FUNCTIONALITY",
				"permission_levels": ["VIEW", "UPDATE"],
				"child_permissions": [
					{
						"name": "Basic",
						"description": "Allows to view and modify basic aspects of the agent",
						"code": "PLAYGROUND_BASIC",
						"permission_levels": ["VIEW", "UPDATE"],
						"child_permissions": [
							{
								"name": "Instructions" ,
								"description": "Allows to give instructions to an agent",
								"code": "PLAYGROUND_BASIC_INSTRUCTIONS",
								"permission_levels": ["VIEW", "UPDATE"],
								"child_permissions": []
							}
						]
					}
				]
			},
			{
				"name": "Vaults",
				"description": "Allows to use the playground to test agents",
				"code": "VAULTS_FUNCTIONALITY",
				"permission_levels": ["VIEW", "UPDATE"],
				"child_permissions": [
				]
			}
		]
	},
	{
		"name": "Contacts",
		"description": "Agents Module that includes Vaults, Actions, Agents and Teas",
		"code": "CONTACTS_MODULE",
		"permission_levels": ["VIEW", "UPDATE", "CREATE", "DELETE"],
		"child_permissions": [
			{
				"name": "Basic",
				"description": "Allows to use the playground to test agents",
				"code": "CONTACTS_FUNCTIONALITY",
				"permission_levels": ["VIEW", "UPDATE"],
				"child_permissions": [
				]
			}
		]
	}
]

export const PermissionsScopes = [
	{name: 'None', value: 'none'},
	{name: 'My own', value: 'own'},
	{name: 'My groups', value: 'groups'},
	{name: 'Rooftop', value: 'rooftop'},
	{name: 'Company', value: 'company'},
]

export const PermissionsScopesIndex = {
	'none': 0,
	'own': 1,
	'groups': 2,
	'rooftop': 3,
	'company': 4,
}