import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { 
    PermissionsWrapper, 
    PermissionNavbarWrapper,
    PermissionsCardsWrapper
} from "./RolePermissions.styled";
import PermissionCard from "../../../UsersPage/NewUserPage/components/PermissionCard/PermissionCard";
import PermissionNavbar from "../../../UsersPage/NewUserPage/components/PermissionNavbar/PermissionNavbar";
import useStore from "../../../../../hooks/useStore";
import Spinner from "../../../../../components/common/Spinner";

const RolePermission = observer(() => {
    const { permissionsSetupStore, listRoleStore } = useStore();

    useEffect(() => {
        if(listRoleStore.permissionsCatalog.length){
          permissionsSetupStore.setupPermissionsCatalog(listRoleStore.permissionsCatalog, listRoleStore.rolePermissions, null);
        }
    }, [listRoleStore.permissionsCatalog.length, listRoleStore.rolePermissions.length])

    return (
        <>{ listRoleStore.status !== 'loading' ?
        <PermissionsWrapper>
            <PermissionNavbarWrapper>
                <PermissionNavbar data={permissionsSetupStore.initialPermissionsCatalog}/>
            </PermissionNavbarWrapper>
            <PermissionsCardsWrapper>
                <div>
                    <div>
                        <PermissionCard data={permissionsSetupStore.permissionsCatalog} reviewMode={false}/>
                    </div>
                </div>
            </PermissionsCardsWrapper>
        </PermissionsWrapper>: 
            <Spinner/>
        }
        </>
    );
});

export default RolePermission;
