import React, { useState } from "react";
import { useStore } from "../../../hooks";
import { observer } from "mobx-react";
import { Icon, RoundedButton } from "../../../elements";
import {
  CustomGrid, CustomSearchInput,
  InputField, SearchIcon,
  StyledIconButton,
  CustomGenericListStyle, VerticalDividerline,
  MainContainer, MainTitleContainer,
  RolesTableContainer
} from "./RoleList.styled";
import RolesTable from "./components/RoleTable/RolesTable";
import CreateRoleModal from "./components/CreateRoleModal/CreateRoleModal";
import RoleDetails from "./components/RoleDetails/RoleDetails";

const RolesList = observer(() => {
  const { userStore } = useStore();
  const [searchValue, setSearchValue] = useState('');
  const [openModal, setOpenModal] = useState(false);

  userStore.setPage('list');

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <>
        <MainContainer>
          <MainTitleContainer><h4>Roles</h4></MainTitleContainer>
        </MainContainer>
        <CustomGrid>
          <CustomSearchInput width='50px'>
            <InputField
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search"
            />
            <SearchIcon>
              <Icon name={"magnifier"} height={'20px'} width={'20px'}></Icon>
            </SearchIcon>
          </CustomSearchInput>

          <StyledIconButton aria-controls="custom-dropdown" aria-haspopup="true">
            <Icon name={"tuneIcon"} height={'15px'} width={'15px'} />
          </StyledIconButton>

          <VerticalDividerline/>

          <RoundedButton
            width={'150px'}
            onClick={handleOpenModal}
          >
            New Role
          </RoundedButton>
          <CustomGenericListStyle />
        </CustomGrid> 
      <RolesTableContainer>
          <RolesTable searchValue={searchValue} />
      </RolesTableContainer>
        

        <CreateRoleModal open={openModal} handleClose={handleCloseModal} />
      </>
    </div>
  );
});

export default RolesList;
