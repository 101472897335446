import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 2px 10px;
  border: 1px solid ${props => props.theme.colors.cerebralGray};
  border-radius: 5px;
  background-color: ${props => props.theme.colors.thumbGray};
  color: ${props => props.theme.colors.darkCharcoal};
  font-size: 14px;
  cursor: pointer;
  
  &:hover {
    background-color: ${props => props.theme.colors.highClearGray};
  }
`;

export const IconWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
`;

export const StatusDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${(props) => props.theme.colors.greenPrimary};
  border-radius: 50%;
  
`;
