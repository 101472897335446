import { InputWrapper } from "./TextArea.styled"

const TextArea = ({ type = 'text', placeholder, value, onChange, width, height, disabled, resize, className }) => {
    return (
        <InputWrapper
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            width={width}
            disabled={disabled}
            height={height}
            $resize={resize}
            className={className}
        />
    );
}

export default TextArea;
