import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import GenericNode from './GenericNode';


export default memo(({ data }) => {
  return (
    <>
      <GenericNode data={data} />
      <Handle
        type="source"
        position={Position.Bottom}
        id={`director-${data.id}-source`}
        isConnectable={false}
      />
    </>
  );
});
