import React from 'react';
import {observer} from "mobx-react";
import {useStore} from "../../../hooks";
import { Icon } from '../../../elements';
import { ChatQuestionsWrapper, ChatQuestionsBox, ChatQuestionsBoxDescription, ChatQuestionsSuggestions, Suggestion, LogoContainer } from "./ChatQuestionsSection.styled";
import gcfLogo from '../../../images/gcfLogo.png';

const ChatQuestionsSection = observer(({ onSelectQuestion, isLoading, loadedQuestions }) => {

    const { authStore } = useStore();
    const handleShowConversations = (message) => {
        if(!isLoading){
            onSelectQuestion(message);
        }
    };

    return (
        <ChatQuestionsWrapper>
            {/* <h5> 
                {authStore.isSuperAdmin && 'Super Admin'}
                {authStore.isCustomerAdmin && 'Admin'}
                {!authStore.isCustomerAdmin && !authStore.isSuperAdmin && 'User'}
            </h5>
            <Icon name="brain" width={'200px'} height={'200px'}/> */}
            <LogoContainer>
                <img src={gcfLogo} alt="GCF Logo"/>
            </LogoContainer>
            <h2>
                Your ANN-powered Expert
            </h2>
            <div>
                <ChatQuestionsBox>
                    <ChatQuestionsBoxDescription>
                    Hello! I'm your personal ANN Agent. Feel free to type a message or select a suggestion to begin our <br/> conversation. I'll use information from within our organization to provide you with answers. Let's get started!
                    </ChatQuestionsBoxDescription   >
        
                    <ChatQuestionsSuggestions>
                        {loadedQuestions.map((question, idx) => (question ? <Suggestion key={idx} onClick={() => handleShowConversations(question)} ><span>{question}</span></Suggestion>: <></>))}

                    </ChatQuestionsSuggestions>
                </ChatQuestionsBox>
            </div>
        </ChatQuestionsWrapper>
    );
});

export default ChatQuestionsSection;
