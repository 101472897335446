import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-plugin-zoom';
import { TextField } from '@mui/material';
import data from '../Data/SimulatedData.json';

const AgentsLineChart = ({ initialData = { agentsData: [] } }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const simulatedData = {
      agentsData: data.agentsData
        .filter(({ timestamp }) => {
          // Filter by dates within the selected range
          if (startDate && endDate) {
            return timestamp >= startDate && timestamp <= endDate;
          }
          return true; // If no dates selected, do not apply filter
        })
    };

    const applyFilters = () => {
      if (!initialData || !initialData.agentsData) {
        return;
      }

      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const ctx = chartRef.current.getContext('2d');
      chartInstance.current = new Chart(ctx, {
        type: 'line',
        data: {
          datasets: [
            {
              label: 'Ilead',
              data: simulatedData.agentsData.map(({ hour, ilead }) => ({ x: hour, y: ilead })),
              borderColor: 'orange',
              backgroundColor: 'transparent',
            },
            {
              label: 'Data Collection',
              data: simulatedData.agentsData.map(({ hour, dataCollection }) => ({ x: hour, y: dataCollection })),
              borderColor: 'blue',
              backgroundColor: 'transparent',
            },
            {
              label: 'Fleet',
              data: simulatedData.agentsData.map(({ hour, fleet }) => ({ x: hour, y: fleet })),
              borderColor: 'yellow',
              backgroundColor: 'transparent',
            },
            {
              label: 'Service',
              data: simulatedData.agentsData.map(({ hour, service }) => ({ x: hour, y: service })),
              borderColor: 'green',
              backgroundColor: 'transparent',
            },
          ],
        },
        options: {
          scales: {
            x: {
              type: 'linear',
              position: 'bottom',
              ticks: {
                stepSize: 1,
                callback: (value) => `${value}:00`,
              },
            },
            y: {
              min: 0,
              max: 50,
              ticks: {
                stepSize: 10,
              },
              title: {
                display: true,
                text: 'Values',
              },
            },
          },
          plugins: {
            zoom: {
              zoom: {
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                mode: 'xy',
              },
              pan: {
                enabled: true,
                mode: 'xy',
              },
            },
          },
        },
      });
    };

    applyFilters();
  }, [initialData, startDate, endDate]);

  return (
    <div style={{ marginLeft: '80px' }}>
      <TextField
        label="Start Date"
        type="date"
        InputLabelProps={{ shrink: true }}
        onChange={(e) => setStartDate(e.target.value)}
      />
      <TextField
        label="End Date"
        type="date"
        InputLabelProps={{ shrink: true }}
        onChange={(e) => setEndDate(e.target.value)}
      />

      <canvas ref={chartRef} width="600" height="300"></canvas>
    </div>
  );
};

export default AgentsLineChart;
