import styled from 'styled-components';

const ThreeColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 0 4%;
  width: 100%;
`;

const AgentsContainer = styled.div`
  margin-bottom: 4%
`;

const InputField = styled.input`
  flex: 1;
  height: 100%;
  padding-left: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 16px;
`;

const SearchIcon = styled.div`
  width: 20px;
  height: auto;
  margin-right: 10px;
  margin-left: 10px;
`;

const CustomComponent1 = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  min-width: 400px; 
  background-color: ${props => props.theme.colors.primaryWhite};
  padding: 10px;
  cursor: pointer
`;

const InfoBoxWrapper = styled.div`
  margin-left: 10px;
  min-height: 100px;
  align-items: flex-start;
`;

const Title = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin:0;
`;

const AgentViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top : 50px;
`;

const DividerLine = styled.div`
  width: 95%;
  height: 1px;
  background-color: ${props => props.theme.colors.lighterGray};
  margin: 3%;
`;

const DividerLine1 = styled.div`
  width: 95%;
  height: 1px;
  background-color: ${props => props.theme.colors.lighterGray};
  margin: 0 0 3% 0;
`;

const TruncatedDescription = styled.p`
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    max-height: 60px;
    margin: 3px 0;
`;

const CustomImage = styled.img`
    width: 100px;
    height: auto;
`;

const LoadMoreContainer = styled.div`
  text-align: -webkit-center;
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;

const CustomGrid = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: right;
  margin-right: 80px;
`;

const CustomSearchInput = styled.div`
  display: flex;
  align-items: center;
  height: 47px;
  border-radius: 10px;
  opacity: 0.06px;
  angle: -90deg;
  background-color: ${props => props.theme.colors.primaryWhite};
  border: 1px solid ${props => props.theme.colors.borderGray};
`;

const StyledIconButton = styled.div`
  background-color: ${props => props.theme.colors.primaryWhite};
  border: 1px solid ${props => props.theme.colors.borderGray};
  color: ${props => props.theme.colors.borderGray};
  padding: 8px;
  cursor: pointer;
  border-radius: 7px;
  display: flex;
  width: 47px;
  height:47px;
  justify-content: center;
  align-items: center;
`;

const TabsContainer = styled.div`
    width: 95%;
    display: flex;
    justify-content: flex-start;
`;


export {
  ThreeColumnGrid,
  AgentsContainer,
  SearchIcon,
  InputField,
  InfoBoxWrapper,
  Title,
  AgentViewContainer,
  DividerLine,
  TruncatedDescription,
  CustomImage,
  CustomComponent1,
  LoadMoreContainer,
  CustomGrid,
  CustomSearchInput,
  StyledIconButton,
  DividerLine1,
  TabsContainer
};
