import {styled} from "styled-components";

const LinkButtonWrapper = styled.button`
    display: inline-block;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: inherit;
    font-size: ${(props) => props.theme.fonts.size.small};
    ${(props) => props.theme.fonts.variant.medium}
    color: inherit;  
  
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
    
    padding: ${(props) => props.padding ? props.padding : '0 5px'};
`;

export {
    LinkButtonWrapper
}
