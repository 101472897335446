import styled, { css } from "styled-components";

export const PermissionsWrapper = styled.section`
  display: flex;
  gap: 48px;
  height: 100vh;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    gap: 20px;
  }

  @media (min-height: 800px) {
    height: 80vh;
  }
`;

const ScrollBar = () => css`
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.primaryWhite};
  }
  
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.clearGray};
  }
`;

export const PermissionNavbarWrapper = styled.div`
  flex: 1;
  ${ScrollBar()} 
`;

export const PermissionsCardsWrapper = styled.div`
  flex: 5;

  > div {
    background-color: ${(props) => props.theme.colors.highClearGray};
    padding: 24px;
    overflow-y: auto;
    height: 100%;

    ${ScrollBar()}

    > div {
      background-color: ${(props) => props.theme.colors.primaryWhite};
    }
  }

  @media (max-width: 768px) {
    > div {
      height: auto;
    }
  }
`;

export const UserInfoTag = styled.span`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  height: 52px;
  width: fit-content;
  background-color: ${(props) => props.theme.colors.primaryWhite};
  padding: 8px 16px;
  font-weight: 400;
  font-size: 0.875em;
  margin-bottom: 24px;
  justify-content: center;

  p {
    margin: 0;
  }

  p:first-child {
    font-size: 1em;
  }
`;

export const ReviewContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 20px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.highClearGray};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  margin-bottom: 24px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    padding: 10px 15px;
  }
`;

export const ReviewItemsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ReviewItem = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child)::after {
    content: '•';
    margin: 0 10px;
    color: ${(props) => props.theme.colors.clearGray};
    font-size: 2.5em;
  }
`;

export const ReviewLabel = styled.span`
  margin-right: 5px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0;
  }
`;

export const SelectGroups = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const AutoMarginSelectGroups = styled(SelectGroups)`
  margin-left: auto;
`;

export const SelectedGroupsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  margin-bottom: 20px;
  margin-left: 0;

  .selected-group {
    padding: 5px 10px;
    border-radius: 15px;
  }
`;

export const CustomSelectComponentWrapper = styled.div`
  margin-left: 10px;
`;

export const ButtonSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;
