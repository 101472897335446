import React, { useState, Suspense } from 'react';
import { TabsContainer, TabsHeader, Tab, TabContent, TabContainer } from './Tabs.styled';
import { Assistance } from '../../../UnifiedProfile/components/Assistance';

const ConversationsHistory = React.lazy(() => import('../ConversationsHistory/ConversationsHistory'));

const ContactsTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <TabsContainer>
      <TabsHeader>
        <Tab isActive={activeTab === 0} onClick={() => handleTabClick(0)}>Chat</Tab>
        <Tab isActive={activeTab === 1} onClick={() => handleTabClick(1)}>History</Tab>
      </TabsHeader>
      <TabContent>
        {activeTab === 0 ? 
          <TabContainer><Assistance></Assistance></TabContainer> : 
          <TabContainer>
            <Suspense fallback={<div>Loading...</div>}>
              <ConversationsHistory/>
            </Suspense>
          </TabContainer>}
      </TabContent>
    </TabsContainer>
  );
};

export default ContactsTabs;
