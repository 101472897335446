import styled from "styled-components";
import { InputText } from "../../../../elements";

export const StepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 5rem 0;
`;

export const FormWrapper = styled.div`
    background-color: ${props => props.theme.colors.silverGrayThree};
    border-radius: 8px;
    width: 791px;
    margin: 2rem 0;
    padding: 1rem;
`;

export const LogoImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AgentsTeamNameInput = styled(InputText)`
    border: 1px solid ${props => props.theme.colors.silverGrayTwo};
`;

export const AgentsTeamDescriptionInput = styled.textarea`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '46px'};
    padding: 9px 16px;
    margin: 1rem 0;
    border-radius: 7px;
    ${(props) => props.theme.fonts.variant.light};
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.theme.colors.silverGrayThree};
    border: 1px solid ${props => props.theme.colors.silverGrayTwo};
    height: 120px;
    font-size: 14px;
    resize: none;
`;

export const TitleStep = styled.div`
    ${props => props.theme.fonts.variant.semiBold};
    font-size: ${props => props.theme.fonts.size.large};
    color: ${props => props.theme.colors.primary};
    text-align: center;
`;

export const LoadingCreationContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 10rem 0;
`;

export const LoadingCreationTitle = styled.div`
    ${props => props.theme.fonts.variant.semiBold};
    color: ${props => props.theme.colors.primary};
    font-size: 24px;
    margin-top: 1rem;
`;

export const LoadingCreationSubtitle = styled.div`
    ${props => props.theme.fonts.variant.regular};
    color: ${props => props.theme.colors.primary};
    font-size: 16px;
    margin-top: 0.5rem;
`