import { action, flow, makeObservable, observable } from "mobx";
import appointmentsMockData from "./data";

class AppointmentsStore {
  appointments = [];
  filteredAppointments = [];
  totalRowCount = 0;
  isError = false;

  constructor() {
    makeObservable(this, {
      appointments: observable,
      filteredAppointments: observable,
      totalRowCount: observable,
      isError: observable,
      fetchAppointments: flow,
      setError: action,
      clearError: action,
    });
  }

  *fetchAppointments() {
    this.isError = false;
    try {
      yield new Promise((resolve) => setTimeout(resolve, 500));
      this.appointments = appointmentsMockData;
      this.filteredAppointments = appointmentsMockData;
      this.totalRowCount = appointmentsMockData.length;
    } catch (error) {
      this.setError(true);
    }
  }

  setError(isError) {
    this.isError = isError;
  }

  clearError() {
    this.isError = false;
  }
}

export default AppointmentsStore;
