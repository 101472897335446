import styled from "styled-components";

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: -webkit-fill-available;
	padding: 1rem;
	gap: 7px;
`;

const TabsHeader = styled.div`
  display: flex;
  gap: 32px;
  padding: 1px 0px;
  border-bottom: ${(props) => (props.showBorder ? '1px solid' : 'none')};
  border-bottom-color: ${(props) => props.theme.colors.silverGrayTwo};
  width: ${(props) => props.width || '100%'};
		
`;

const Tab = styled.div`
  padding: 9px 0px;
  cursor: pointer;
  border-bottom: 2px solid ${(props) => (props.isActive ? props.theme.colors.activeBlue : 'transparent')};
  color: ${(props) => (props.isActive ? props.theme.colors.activeBlue : props.theme.colors.greyEleven)};
  ${(props) => props.theme.fonts.variant.regular()};
  font-size: 1em;
  line-height: normal;

  transition: color 0.3s, border-bottom 0.3s;

  &:hover {
    color: ${(props) => props.theme.colors.activeBlue};
  }
`;

const TabContent = styled.div`
  padding: 0px;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.primaryWhite};
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.primaryWhite};
  }
`;

export {
	TabsContainer,
	TabsHeader,
	Tab,
	TabContent
}