import React, { useState } from 'react';
import { Icon } from "../../../../../elements";

import {
    CustomModal,
    ModalFilterHeader,
    ModalFilterTitle,
    ModalDropdownTitle,
    StyledFormControl,
    StyledSelect,
    DropdownMenu,
    StyledMenuItem,
    StyledButton,
    PlaceholderDropdown,
    DropdownIconContainer,
    IconCloseContainer
} from "./ModalFilter.styled";

const ModalFilter = ({
                         openFilter,
                         anchorEl,
                         handleCloseModalFilter,
                         dropdownConfigs,
                         title,
                         modalWidth
                     }) => {

    const [selectedValues, setSelectedValues] = useState(
        dropdownConfigs && dropdownConfigs.map(() => "")
    );

    const [openDropdowns, setOpenDropdowns] = useState(
        dropdownConfigs && dropdownConfigs.map(() => false)
    );

    const handleChange = (index) => (value) => {
        const newSelectedValues = [...selectedValues];
        newSelectedValues[index] = value;
        setSelectedValues(newSelectedValues);
        toggleDropdown(index)();
    };

    const toggleDropdown = (index) => () => {
        const newOpenDropdowns = [...openDropdowns];
        newOpenDropdowns[index] = !newOpenDropdowns[index];
        setOpenDropdowns(newOpenDropdowns);
    };

    return (
        openFilter && (
            <CustomModal
                id="custom-modal"
                top={anchorEl.getBoundingClientRect().bottom}
                left={anchorEl.getBoundingClientRect().right - modalWidth}
                width={modalWidth}
            >
                <ModalFilterHeader>
                    <ModalFilterTitle>{title}</ModalFilterTitle>
                    <IconCloseContainer onClick={handleCloseModalFilter}>
                        <Icon name={'close'} height={'11px'} width={'11px'} onClick={handleCloseModalFilter}/>
                    </IconCloseContainer>
                </ModalFilterHeader>
                {dropdownConfigs.map((config, index) => (
                    <div key={index}>
                        <ModalDropdownTitle>{config.title}</ModalDropdownTitle>
                        <StyledFormControl>
                            <StyledSelect onClick={toggleDropdown(index)}>
                                {selectedValues[index] ? selectedValues[index] : <PlaceholderDropdown>{config.placeholder || config.title}</PlaceholderDropdown>}
                                <DropdownIconContainer><Icon name={"expandMoreOutlinedBlack"} height={'8px'} width={'8px'}></Icon></DropdownIconContainer>

                            </StyledSelect>
                            <DropdownMenu open={openDropdowns[index]}>
                                {config.items.map(item => (
                                    <StyledMenuItem key={item.value}>
                                        {item.name}
                                    </StyledMenuItem>
                                ))}
                            </DropdownMenu>
                        </StyledFormControl>
                    </div>
                ))}
                <StyledButton onClick={handleCloseModalFilter}>
                    Apply
                </StyledButton>
            </CustomModal>
        )
    );
};

export default ModalFilter;