import React from "react";
import {
  UnifiedContainer,
  Breadcrumb,
  ChatContainerMobile,
  ChatWrapper,
  WidgetChatWrapperMobile,
  CustomGenericListStyleMobile,
  WidgetContainerMobile,
} from "../BaseUnifiedProfilePage.styled";
import BaseUnifiedProfilePage from "../BaseUnifiedProfilePage";
import Assistance  from "../components/Assistance/Assistance";
import GeneralInfo from "../components/Cards/GeneralInfo";
import GarageCard from "../components/Cards/GarageCard";
import Appointments from "../components/Cards/Appointments";
import Notification from "../components/Cards/Notification";
import RepairOrders from "../components/Cards/RepairOrders";
import { observer } from "mobx-react";

const UnifiedProfilePageMobile = observer(() => {
  return (
    <BaseUnifiedProfilePage>
      <UnifiedContainer>
        <Breadcrumb>Contacts</Breadcrumb>
        <WidgetChatWrapperMobile>
          <WidgetContainerMobile>
            <GeneralInfo />
            <GarageCard />
            <Appointments />
            <RepairOrders />
            <Notification />
            <ChatContainerMobile >
              <ChatWrapper>
                <Assistance />
              </ChatWrapper>
            </ChatContainerMobile>
          </WidgetContainerMobile>
        </WidgetChatWrapperMobile>
        <CustomGenericListStyleMobile />
      </UnifiedContainer>
    </BaseUnifiedProfilePage>
  );
});

export default UnifiedProfilePageMobile;
