import React from 'react';
import { LoadingBox } from '../../../../components/loading/Loading.styled';
import {
  LoadingCreationContainer,
  LoadingCreationTitle,
  LoadingCreationSubtitle,
} from './Components.styled';

const LoadingAgentsTeamCreation = () => {
  return (
    <LoadingCreationContainer>
      <LoadingBox />
      <LoadingCreationTitle>Creating Your Team</LoadingCreationTitle>
      <LoadingCreationSubtitle>We are working on creating your new team.</LoadingCreationSubtitle>
    </LoadingCreationContainer>
  );
};

export default LoadingAgentsTeamCreation;
