import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../../hooks";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { DefaultTheme as theme } from "../../../../../theme";
import { Container } from './RoleTable.styled';

const RolesTable = observer(({ searchValue }) => {
  const { listRoleStore, authStore } = useStore();
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState(listRoleStore.roles);

  useEffect(() => {
    listRoleStore.setCustomer(authStore.selectedAccount.id)
    listRoleStore.onFetchRoles();
  }, [authStore.selectedAccount.id]);

  useEffect(() => {
    const filtered = listRoleStore.roles.filter(row =>
      row.name?.toLowerCase()?.includes(searchValue.toLowerCase()) || row.description?.toLowerCase()?.includes(searchValue.toLowerCase()) || row.access?.toLowerCase()?.includes(searchValue.toLowerCase())
    );
    setFilteredData(filtered);
  }, [listRoleStore.roles, searchValue]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Role Name",
        size: 200,
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
          sx: {
            border: 'none',
          },
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        size: 400,
        minSize: 300,
        maxSize: 600,
        Cell: ({ cell }) => (
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {cell.getValue()}
          </span>
        ),
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
          sx: {
            border: 'none',
          },
        },
      },
    ],
    []
  );

  const handleRowClick = (row) => {
    navigate(`/v2/roles/details/${row.original.id}`);
  };

  const table = useMaterialReactTable({
    columns,
    data: filteredData,
    initialState: { isHidden: { countryCode: true } },
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    enableEditing: false,
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => handleRowClick(row),
      sx: {
        cursor: 'pointer',
        border: 'none',
      },
    }),
    muiTableHeadRowProps: {
      sx: {
        borderRadius: '8px',
        border: 'none',
        minHeight: 'auto',
        height: 'auto',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: theme.colors.highClearGray,
        color: 'black',
        paddingTop: '8px',
        paddingBottom: '8px',
        border: 'none',
      },
    },
    muiTableContainerProps: {
      sx: {
        border: 'none',
        marginTop: '0px',
        paddingTop: '0px',
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: 'none',
        marginTop: '0px',
      },
    },
    enableGlobalFilter: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    state: {
      isLoading: listRoleStore.status === "loading",
    },
  });

  return (
    <Container>
      <MaterialReactTable table={table} />
    </Container>
  );
});

export default RolesTable;
