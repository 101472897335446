import styled from 'styled-components';

const RuleSelectWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    height: 40px;
    width: 100%;

    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.clearGray};
    background: ${(props) => props.theme.colors.primaryWhite};

    padding: 9px 16px;
`;

const RuleSelectedOptionWrapper = styled.div`
    ${(props) => props.theme.fonts.variant.regular()};
    font-size: 0.875rem;
    line-height: normal;
    font-style: normal;
    color: ${(props) => props.theme.colors.darkGray};
    flex: 1;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      margin-left: 5px;
      ${(props) => props.theme.fonts.variant.bold()};
    }
`;

const RuleSelectorIconWrapper = styled.div`
  display: flex;
  width: 24px;
  rotate: ${(props) => props.openDropdown ? '180deg' : '0deg'};
`;

const RuleSelectorOptionsWrapper = styled.div`
  position: absolute;
  flex-direction: column;
  padding: 16px;
  top: 42px;
  left: 0;
  right: 0;
  width: 100%;
  height: 273px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.highClearGray};
  gap: 16px;
  display: ${(props) => (props.openDropdown ? "flex" : "none")};
  z-index: 3;

  p {
    ${(props) => props.theme.fonts.variant.semiBold()};
    font-size: 0.875rem;
    text-align: center;
  }
`;

const RuleSelectorOptionsList = styled.div`
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.clearGray};
  background-color: ${(props) => props.theme.colors.primaryWhite};
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  p {
    width: 100%;
    ${(props) => props.theme.fonts.variant.extraLight()};
    font-size: 0.75rem;
    padding: 10px;
    text-align: left;
    margin: 0;
    selection: none;

    border-bottom: 1px solid ${(props) => props.theme.colors.clearGray};

    cursor: pointer;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.clearGray};
    border-radius: 8px;
    background-clip: content-box;
    pointer-events: none;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(props) => props.theme.colors.darkGray};
  }
`;

export {
  RuleSelectWrapper,
  RuleSelectedOptionWrapper,
  RuleSelectorIconWrapper,
  RuleSelectorOptionsWrapper,
  RuleSelectorOptionsList
};
