import styled, { css } from "styled-components";

export const UserContainer = styled.div`
    border: 1.3px solid ${(props) => props.theme.colors.silverGrayTwo};
    width: 100%;
    max-width: 355px;
    border-radius: 10px;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const Name = styled.div`
    color: ${(props) => props.theme.colors.primary};
    font-size: min(1.1vw, 18px);
    font-weight: 700;
`

export const ContactInfo = styled.div`
    color: ${(props) => props.theme.colors.greyTwo};
    font-size: min(1vw, 14px);
    font-weight: 500;
    margin: 0.5rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
`;

export const Title = styled.div`
    color: ${(props) => props.theme.colors.greyTwo};
`;

export const Value = styled.div`
    font-weight: 300;
`;