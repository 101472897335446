import React from 'react';
import {
    Backdrop,
    ModalWrapper,
    ModalSubtitle,
    ModalContainer,
    ModalTitle,
    ModalSubtitle2,
    ModalSubtitle3,
    ModalCloseButton,
    ModalImage
} from "./SuccessfulModal.styled";
import XIcon from '../../elements/icon/assets/x_icon.svg';
import { Icon } from '../icon';

const SuccessfulModal = ({ isOpen, onClose, imageSrc, title, subtitle, subtitle2, subtitle3, height, width, zIndex=false }) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <Backdrop isOpen={isOpen} $zIndex={zIndex}>
            <ModalWrapper $height={height} $width={width}>
                <ModalCloseButton src={XIcon} width={15} height={15} onClick={() => handleClose()} />
                <ModalContainer>
                    {imageSrc ?
                        <ModalImage src={imageSrc} width={30} height={30} onClick={() => handleClose()} />
                        :
                        <Icon name='admirationSign' width='30px' height='30px'/>
                    }
                    <ModalTitle>{title}</ModalTitle>
                    <ModalSubtitle>{subtitle}</ModalSubtitle>
                    <ModalSubtitle2>{subtitle2}</ModalSubtitle2>
                    <ModalSubtitle3>{subtitle3}</ModalSubtitle3>
                </ModalContainer>
            </ModalWrapper>
        </Backdrop>
    );
};

export default SuccessfulModal;
