import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  MainContainer, TabsContainer, BreadCrumbContainer, MenuContainer,
  Title, UserColumnContainer, SideColumn, MainColumn, TitleName, CustomSearchInput, InputField,
  SearchIcon, CustomGrid, StyledIconButton
} from "./RoleGeneral.styled";
import RoleDetails from "../RoleDetails/RoleDetails";
import { RoundedButton, Icon } from '../../../../../elements';
import Tabs from '../../../../../elements/v2/Tabs/Tabs';
import ActionSelect from "../../../UsersPage/UsersOerview/components/ActionSelect/ActionSelect";
import DeleteIcon from "../../../../../elements/icon/assets/delete_outline.svg";
import DuplicateIcon from "../../../../../elements/icon/assets/duplicate_outline.svg";
import RolePermissions from "../RolePermissions/RolePermissions";
import SuccessModal from '../../../../../elements/v2/SuccessModal/SuccessModal';
import { useStore } from '../../../../../hooks';
import ConfirmationWrapper from "../../../AgentsTeam/Team/Components/ConfirmationWrapper/ConfirmationWrapper";
import MembersTable from "../RoleMembers/MembersTable";
import AddMemberModal from "../AddMembersModal/AddMemberModal";
import { ModalFilter } from "../../../AsistantManagement/components";

const RoleGeneral = () => {
  const { roleId } = useParams();
  const { listRoleStore, authStore, permissionsSetupStore } = useStore();
  const [selectedTab, setSelectedTab] = useState(0);
  const [roleDetails, setRoleDetails] = useState(null);
  const [originalRoleDetails, setOriginalRoleDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successModalContent, setSuccessModalContent] = useState({ isOpen: false, title: '', message: '' });
  const [actionConfig, setActionConfig] = useState(null);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  
  const discardHandlerRef = useRef(null);

  useEffect(() => {
    listRoleStore.setCustomer(authStore.selectedAccount.id)
    listRoleStore.fetchRoleData(roleId)
    listRoleStore.fetchPermissionsCatalogUser()
    listRoleStore.fetchRolePermissions(roleId)
  }, [roleId]);

  const handleFieldChange = (field, value) => {
    setRoleDetails({
      ...roleDetails,
      [field]: value,
    });
  };

  const handleSave = () => {
    openConfirmationModal({
      name: 'Save Changes',
      title: 'Save Changes',
      message: 'Are you sure you want to save the changes?',
      icon: 'info',
      onConfirm: confirmSave,
    });
  };

  const confirmSave = async () => {
    try {
      if(selectedTab === 0){
        const {name, description, id} = listRoleStore.selectedRole
        await listRoleStore.createRole(name, description, id);
      } else if(selectedTab === 1){
        await listRoleStore.saveAccess(permissionsSetupStore.generateSaveStructure())
      }

      setSuccessModalContent({
        isOpen: true,
        title: 'Success',
        message: 'Changes have been saved successfully!',
      });
    } catch (error) {
      listRoleStore.setError("Error saving role");
      setSuccessModalContent({
        isOpen: true,
        title: 'Error',
        message: 'An error occurred while saving.',
      });
    } finally {
      setIsModalOpen(false);
      setTimeout(() => {
        setSuccessModalContent((prev) => ({
          ...prev,
          isOpen: false,
        }));
      }, 5000);
    }
  };

  const handleDiscard = () => {
    setRoleDetails({ ...originalRoleDetails });
    if (discardHandlerRef.current) {
      discardHandlerRef.current();
    }
  };

  const handleRemove = () => {
    openConfirmationModal({
      name: 'Remove',
      title: 'Remove',
      message: 'Caution: You are about to remove the following role. Are you sure you want to proceed?',
      icon: 'deleteOutline',
      onConfirm: confirmRemove,
    });
  };

  const confirmRemove = () => {
    listRoleStore.onDeleteRole(roleId)
      .then(() => {
        setSuccessModalContent({
          isOpen: true,
          title: 'Great',
          message: 'The role has been deleted from the system.',
        });
      })
      .catch((error) => {
        listRoleStore.setError("Error removing role");
        setSuccessModalContent({
          isOpen: true,
          title: 'Error',
          message: 'An error occurred while removing the role.',
        });
      })
      .finally(() => {
        setIsModalOpen(false);
  
        setTimeout(() => {
          setSuccessModalContent((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }, 5000);
      });
  };

  const handleDuplicate = () => {
    openConfirmationModal({
      name: 'Duplicate',
      title: 'Duplicate',
      message: 'Caution, you are about to duplicate the next role. Are you sure about this?',
      icon: 'duplicateOutline',
      onConfirm: confirmDuplicate,
    });
  };

  const confirmDuplicate = () => {
    const newRole = {
      ...roleDetails,
      id: null,
      roleName: `${roleDetails.roleName} (Copy)`,
    };
  
    listRoleStore.onPutRole(newRole)
      .then(() => {
        setSuccessModalContent({
          isOpen: true,
          title: 'Great',
          message: 'The role has been successfully duplicated.',
        });
      })
      .catch((error) => {
        listRoleStore.setError("Error duplicating role");
        setSuccessModalContent({
          isOpen: true,
          title: 'Error',
          message: 'An error occurred while duplicating the role.',
        });
      })
      .finally(() => {
        setIsModalOpen(false);
  
        setTimeout(() => {
          setSuccessModalContent((prev) => ({
            ...prev,
            isOpen: false,
          }));
        }, 5000);
      });
  };

  const openConfirmationModal = (config) => {
    setActionConfig(config);
    setIsModalOpen(true);
  };

  const handleActionSelect = (name, value) => {
    if (value === 'reset') {
      setSelectedTab(1);
    } else if (value === 'remove') {
      handleRemove();
    } else if (value === 'duplicate') {
      handleDuplicate();
    }
  };

  const actionOptions = [
    { name: 'Remove', value: 'remove', icon: DeleteIcon },
    { name: 'Duplicate', value: 'duplicate', icon: DuplicateIcon },
  ];

  const tabs = [
    {
      label: 'General',
      content: (
        <RoleDetails/>
      ),
    },
    {
      label: 'Access',
      content: (
        <RolePermissions />
      ),
    },
    // {
    //   label: 'Members',
    //   content: (
    //     <>
    //       <MembersTable searchValue={searchValue} />
    //     </>
    //   ),
    // },
  ];

  const handleAddMembers = () => {
    setIsAddMemberModalOpen(true);
  };

  const handleCloseAddMemberModal = () => {
    setIsAddMemberModalOpen(false);
  };

  return (
    <>
      <MainContainer>
        <BreadCrumbContainer>
          <Title>Role &gt; </Title>
          <TitleName>{roleDetails?.roleName || 'Role'}</TitleName>
        </BreadCrumbContainer>
        <MenuContainer>
          <RoundedButton width='60px' height='30px' fontSize='12px' outline={true} onClick={handleDiscard}>
            Discard
          </RoundedButton>
          <RoundedButton width='80px' height='30px' fontSize='12px' color='#2173FF' onClick={handleSave}>
            Save
          </RoundedButton>
        </MenuContainer>
      </MainContainer>

      {selectedTab === 2 && (
        <CustomGrid>
          <div>
            <CustomSearchInput width='50px'>
              <InputField
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search"
              />
              <SearchIcon>
                <Icon name={"magnifier"} height={'20px'} width={'20px'} />
              </SearchIcon>
            </CustomSearchInput>
          </div>
          <div>
            <StyledIconButton aria-controls="custom-dropdown" aria-haspopup="true" >
              <Icon name={"tuneIcon"} height={'15px'} width={'15px'} />
            </StyledIconButton>

            <ModalFilter

              title="Filter"
              modalWidth="250"
            />
          </div>
        </CustomGrid>
      )}

      <UserColumnContainer>
        <MainColumn>
          <TabsContainer>
            <Tabs
              tabs={tabs}
              externalActiveTab={selectedTab}
              onToggleTab={setSelectedTab}
              showBorder={true}
            />
          </TabsContainer>
        </MainColumn>
        <SideColumn>
          {/* {selectedTab === 2 ? (
            <RoundedButton width='120px' height='30px' fontSize='12px' onClick={handleAddMembers}>
              Add Members
            </RoundedButton>
          ) : (
            <ActionSelect
              placeholder="Actions"
              options={actionOptions}
              handleAction={handleActionSelect}
            />
          )} */}
        </SideColumn>
      </UserColumnContainer>

      {isAddMemberModalOpen && (
        <AddMemberModal
          onAddGroups={(selectedGroups) => {
            setIsAddMemberModalOpen(false);
          }}
          onClose={handleCloseAddMemberModal}
        />
      )}

      <ConfirmationWrapper
        isOpen={isModalOpen}
        actionConfig={actionConfig}
        onConfirm={actionConfig?.onConfirm}
        onClose={() => setIsModalOpen(false)}
      />

      <SuccessModal
        isOpen={successModalContent.isOpen}
        icon="check_circle"
        onClose={() => setSuccessModalContent({ ...successModalContent, isOpen: false })}
        title={successModalContent.title}
        message={successModalContent.message}
      />
    </>
  );
};

export default RoleGeneral;
