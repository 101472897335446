import styled, { css } from "styled-components";

export const UserContainer = styled.div`
    border: 1.3px solid ${(props) => props.theme.colors.silverGrayTwo};
    width: 100%;
    max-width: 355px;
    height: 263px;
    border-radius: 10px;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 10px;
`;

export const PartsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 4px;
`;

export const PartsTitle = styled.div`
    font-size: 14px;
    font-weight: 700;
`;

export const PartsDescription = styled.div`
    color: ${(props) => props.theme.colors.greyTwo};
    font-size: 14px;
    font-weight: 500;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;

    span {
        font-weight: 400;
    }
`;

export const ExpandedParts = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
`;

export const Title = styled.span`
    font-size: 18px;
    font-weight: 700;
`;

export const PartsHeader = styled.span`
    line-height: 16px;
`;

export const ExpandedPartsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 10px;
`;
