import { useCallback } from "react";
import { observer } from "mobx-react";
import { Icon } from '../../../../../elements';
import { useStore } from '../../../../../hooks';
import {
  StepWrapper,
  LogoImageWrapper,
  FormWrapper,
  TitleStep,
  NameInput
} from "./AgentSetNameStep.styled";

const AgentSetNameStep = observer(() => {
  const { agentStore } = useStore();
  const { newAgentStore } = agentStore;

  const onHandleChangeName = useCallback((e) => {
    newAgentStore.onChangeName(e.target.value);
  }, [newAgentStore]);

  return (
    <StepWrapper>
        <LogoImageWrapper>
          <Icon name="annIsotype" width="96px" height="80px" />
        </LogoImageWrapper>
      <FormWrapper>
        <TitleStep>Please provide a name for your agent.</TitleStep>
        <NameInput
          value={newAgentStore.name}
          placeholder="Enter agent name"
          onChange={onHandleChangeName}
        />
      </FormWrapper>
    </StepWrapper>
  );
});

export default AgentSetNameStep;
