import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function Spinner({ className }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={className}>
      <CircularProgress />
    </div>
  );
}

export default Spinner;
