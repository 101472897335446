import {useCallback} from "react";
import {IconButtonWrapper} from "./IconButton.styled";
import {Icon} from "../icon";

const IconButton = ({ icon, width, height, onClick, disabled = false, className, top, dataTestId }) => {
    const handleClick = useCallback(() => {
        onClick && onClick();
    }, [onClick]);

    return (
        <IconButtonWrapper
          width={width}
          height={height}
          disabled={disabled}
          top={top}
          type="button"
          data-testid={dataTestId}
          onClick={handleClick}>
            <Icon name={icon} width={width} height={height} className={className} />
        </IconButtonWrapper>
    );
};

export default IconButton;