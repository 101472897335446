import styled from 'styled-components';
import {Tab, Tabs} from "@mui/material";

const ModalAccessHeaderTitle = styled.h5`
  font-weight: bold;
  padding-left: 14px;
`

const ModalIconsAccess = styled.div`
  display: flex;
  justify-content: start;
  padding: 0px 20px
`

const ModalDetailsContainer = styled.div`
  text-align: right;
  padding: 0px 28px;
  color: ${props => props.theme.colors.activeBlue};
`
const GroupedIcons = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 10px;
  font-weight: bold;
  position: relative;
  z-index: ${(props) => props.index};
  margin-left: ${(props) => (props.index !== 0 ? '-8px' : '0')};
`;

const ModalAccessHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  font-size: 10px;
`;

const ModalTabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ModalAccessTabs = styled(Tabs)`
  && {
    .MuiTabs-indicator {
        display: none;
    }
  }
`;

const TabPanelBox = styled.div`
  padding: 0;
  width: 100%;
`;

const SharedMessage = styled.div`
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-content: start;
  flex-wrap: wrap;
  align-items: start;
  color: gray;
  padding: 10px 0;
`;

const TabPanelGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 95%;
`;

const TabPanelGridItemNames = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const TabPanelGridItemNamesContainer = styled.div`
 font-size: 10px;
  color: black;
  font-weight: bold;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.border};
  width: auto;
  padding: 1px 4px;
  display: inline-flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
  margin: 5px 0;
`

const TabPanelGridItemRoles = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 10px;
  color: gray;
`;

const IconsContainer = styled.div`
  display: flex;
  position: relative;
`;

const ModalAccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 16px;
`;

const ModalAccessTab = styled(Tab)`
  width: auto !important;
  max-width: none !important; 
  min-width: 0 !important; 
  max-height: none !important;
  min-height: 0 !important;
  height: auto !important;
  padding: 5px 7px !important; 
  border-radius: 5px !important;
  margin: 4px !important;
  text-transform: none !important;
  font-size: 10px !important;
  align-content: center !important;
  
  &.Mui-selected {
    color: white !important;
    background-color: ${props => props.theme.colors.brightNavyBlue};;
  }
`;

const Icons = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 10px;
  font-weight: bold;
`;

const ShowDetailsContainer = styled.div`
  cursor: pointer;
  width: auto;
`

export {
  ModalAccessHeaderTitle,
  ModalIconsAccess,
  ModalDetailsContainer,
  GroupedIcons,
  ModalAccessHeader,
  ModalTabsContainer,
  ModalAccessTabs,
  TabPanelBox,
  SharedMessage,
  TabPanelGridContainer,
  TabPanelGridItemNames,
  TabPanelGridItemNamesContainer,
  TabPanelGridItemRoles,
  IconsContainer,
  ModalAccessContainer,
  ModalAccessTab,
  Icons,
  ShowDetailsContainer
}