import {makeObservable, observable} from "mobx";

class ListDataStore {
  notifications = [
    {
      id: '56b43b87-09c3-4c08-b0f4-ac78d5430ca6',
      icon: 'mdi-account',
      message: 'Your plan that has all the benefits and Accesss to the ANN platform is about to expire, please update your contact information and check your Accesss before January 15, 2024, any questions or concerns, write us on our support line, we will provide you with the best help.',
      createdAt: '2024-01-29 20:21:13.310027',
      readAt: '2024-01-29 20:21:13.310027'
    },
    {
      id: '56b43b87-09c3-4c08-b0f4-ac78d5430ca6',
      icon: 'mdi-account',
      message: 'Your plan that has all the benefits and Accesss to the ANN platform is about to expire, please update your contact information and check your Accesss before January 15, 2024, any questions or concerns, write us on our support line, we will provide you with the best help.',
      createdAt: '2024-01-29 20:21:13.310027',
      readAt: '2024-01-29 20:21:13.310027'
    },
    {
      id: '56b43b87-09c3-4c08-b0f4-ac78d5430ca6',
      icon: 'mdi-account',
      message: 'Your plan that has all the benefits and Accesss to the ANN platform is about to expire, please update your contact information and check your Accesss before January 15, 2024, any questions or concerns, write us on our support line, we will provide you with the best help.',
      createdAt: '2024-01-29 20:21:13.310027',
      readAt: '2024-01-29 20:21:13.310027'
    },
    {
      id: '56b43b87-09c3-4c08-b0f4-ac78d5430ca6',
      icon: 'mdi-account',
      message: 'Your plan that has all the benefits and Accesss to the ANN platform is about to expire, please update your contact information and check your Accesss before January 15, 2024, any questions or concerns, write us on our support line, we will provide you with the best help.',
      createdAt: '2024-01-29 20:21:13.310027',
      readAt: '2024-01-29 20:21:13.310027'
    },
    {
      id: '56b43b87-09c3-4c08-b0f4-ac78d5430ca6',
      icon: 'mdi-account',
      message: 'Your plan that has all the benefits and Accesss to the ANN platform is about to expire, please update your contact information and check your Accesss before January 15, 2024, any questions or concerns, write us on our support line, we will provide you with the best help.',
      createdAt: '2024-01-29 20:21:13.310027',
      readAt: '2024-01-29 20:21:13.310027'
    },
    {
      id: '56b43b87-09c3-4c08-b0f4-ac78d5430ca6',
      icon: 'mdi-account',
      message: 'Your plan that has all the benefits and Accesss to the ANN platform is about to expire, please update your contact information and check your Accesss before January 15, 2024, any questions or concerns, write us on our support line, we will provide you with the best help.',
      createdAt: '2024-01-29 20:21:13.310027',
      readAt: '2024-01-29 20:21:13.310027'
    },
    {
      id: '56b43b87-09c3-4c08-b0f4-ac78d5430ca6',
      icon: 'mdi-account',
      message: 'Your plan that has all the benefits and Accesss to the ANN platform is about to expire, please update your contact information and check your Accesss before January 15, 2024, any questions or concerns, write us on our support line, we will provide you with the best help.',
      createdAt: '2024-01-29 20:21:13.310027',
      readAt: '2024-01-29 20:21:13.310027'
    },
    {
      id: '56b43b87-09c3-4c08-b0f4-ac78d5430cB8',
      icon: 'mdi-account',
      message: 'Your plan that has all the benefits and Accesss to the ANN platform is about to expire, please update your contact information and check your Accesss before January 15, 2024, any questions or concerns, write us on our support line, we will provide you with the best help.',
      createdAt: '2024-01-29 20:21:13.310027',
      readAt: ''
    },
    {
      id: '56b43b87-09c3-4c08-b0f4-ac78d5430cB8',
      icon: 'mdi-account',
      message: 'Your plan that has all the benefits and Accesss to the ANN platform is about to expire, please update your contact information and check your Accesss before January 15, 2024, any questions or concerns, write us on our support line, we will provide you with the best help.',
      createdAt: '2024-01-29 20:21:13.310027',
      readAt: ''
    }
  ];
  status = '';

  constructor() {
    makeObservable(this, {
      notifications: observable,
      status: observable
    });
  }
}

export default ListDataStore;