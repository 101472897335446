import UserList from "./UserList";
import NewUserPage from "./NewUserPage/NewUserPage";

import React from "react";
import { Route, Routes } from "react-router-dom";
import UserOverview from "./UsersOerview/UsersOverview";

const UserPage = () => {
  return (
    <Routes>
      <Route index element={<UserList />} />
      <Route path="/new" element={<NewUserPage/>} />
      <Route path="/overview/:userId" element={<UserOverview />} />
    </Routes>
  );
};

export default UserPage;
