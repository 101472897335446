import { 
    InputWrapper, 
    TabContainer,
    ButtonContiner,
    ButtonWrapper
} from './TemplateEditor.styled';
import Variable from './Variable';
import { observer } from 'mobx-react';
import useStore from '../../../hooks/useStore';
import RoundedIconButton from '../Buttons/RoundedIconButton/RoundedIconButton';
import { RoundedButton } from '../../button';

const VariablesTab = observer(({updateVariables}) => {

    const { templateEditorStore } = useStore();

    return (
        <TabContainer>
            <h1>Variable</h1>
            <ButtonContiner>
                <RoundedIconButton width='24px' height='24px' icon='circleAdd' onClick={() => templateEditorStore.newVariable()}/>
                <ButtonWrapper>
                    <RoundedButton width="79px" onClick={updateVariables} height='36px'>Save</RoundedButton>
                </ButtonWrapper>
            </ButtonContiner>

            <InputWrapper>
                {templateEditorStore.variables && templateEditorStore.variables.map((_, idx) =>
                    <Variable key={idx} id={idx}/>  
                )}
            </InputWrapper>
            
        </TabContainer>
    );
});

export default VariablesTab