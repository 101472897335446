import React, { useEffect, useState, useMemo, useCallback } from "react";
import { observer } from 'mobx-react';
import { useStore } from "../../../../../hooks";
import GenericListComponent from "../GenericListComponent/GenericListComponent";
import { ReactComponent as CircleDelIcon } from '../../../../../elements/icon/assets/zondicons_add-outline.svg';
import { FeatureLibraryContainer } from './AvailableFeature.styled';
import PaginationComponent from "../../../../../elements/PaginationComponent/PaginationComponent";

const AvailableFeature = observer(({ searchTerm }) => {
    const { featureStore } = useStore();
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);

    useEffect(() => {
        featureStore.fetchFeatures('userId', 'customerId');
    }, [featureStore]);

    const handleFeatureChange = useCallback((newList) => {
        featureStore.setFeatures(newList);
    }, [featureStore]);

    const filteredFeatures = useMemo(() => {
        return featureStore.features 
            ? featureStore.features.filter((feature) =>
                feature.toLowerCase().includes(searchTerm.toLowerCase())
              )
            : [];
    }, [featureStore.features, searchTerm]);

    const paginatedFeatures = useMemo(() => {
        const startEntry = (currentPage - 1) * entriesPerPage;
        const endEntry = startEntry + entriesPerPage;
        return filteredFeatures.slice(startEntry, endEntry);
    }, [filteredFeatures, currentPage, entriesPerPage]);

    const totalEntries = filteredFeatures.length;

    const handlePageChange = useCallback((page) => {
        setCurrentPage(page);
    }, []);

    const handleEntriesPerPageChange = useCallback((newEntriesPerPage) => {
        setEntriesPerPage(newEntriesPerPage);
        setCurrentPage(1);
    }, []);

    return (
        <FeatureLibraryContainer>
            <GenericListComponent
                listItems={paginatedFeatures}
                setListItems={handleFeatureChange}
                newItem={(value) => featureStore.addFeature(value)}
                placeholder={'Type your feature here'}
                width="100%"
                showAddButton={false}
                ActionIcon={CircleDelIcon}
                onActionClick={(newFeature) => featureStore.removeFeature(newFeature)}
                fontSize="12px"
                fontWeight="300"
                textareaBorder="none"
            />

            <PaginationComponent
                totalEntries={totalEntries}
                entriesPerPageOptions={[5, 10, 12, 20, 50]}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onEntriesPerPageChange={handleEntriesPerPageChange}
            />
        </FeatureLibraryContainer>
    );
});

export default AvailableFeature;
