import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { Icon } from "../../../../../elements";

const CHANNEL_CONFIG = {
  sms: {
    icon: "messageIcon",
    label: "SMS",
  },
  email: {
    icon: "email",
    label: "Email",
  },
  phone: {
    icon: "phone",
    label: "Phone",
  },
};

const ChannelIconAndText = React.memo(({ channel }) => {
  const channelConfig = CHANNEL_CONFIG[channel];

  if (!channelConfig) {
    return null;
  }

  return (
    <>
      <Icon name={channelConfig.icon} width="16px" height="16px" />
      <Typography variant="body2">{channelConfig.label}</Typography>
    </>
  );
});

ChannelIconAndText.propTypes = {
  channel: PropTypes.oneOf(Object.keys(CHANNEL_CONFIG)).isRequired,
};

export const getChannelIconAndText = (channel) => {
  return <ChannelIconAndText channel={channel} />;
};

export default ChannelIconAndText;
