import {action, makeObservable, observable} from "mobx";
import CreateOrEditCustomerStore from "./page/CreateOrEditCustomerStore";

const CustomerPageStrategy = {
  'list': { namePage: 'list'},
  'create': new CreateOrEditCustomerStore()
}

class CustomerStore {
  currentPage = undefined;

  constructor() {
    makeObservable(this, {
      currentPage: observable,
      setCurrentPage: action
    });

    this.setCurrentPage('list');
  }

  setCurrentPage(page) {
    this.currentPage = CustomerPageStrategy[page];
  }
}

export default CustomerStore;
