import styled from "styled-components";

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 817px;
`;

const StepTitle = styled.div`
  ${(props) => props.theme.fonts.variant.semiBold()};
  font-size: 1.5rem;
  line-height: normal;
  letter-spacing: 0.25px;
`;

const InputHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 35px;
  margin-top: 32px;
  margin-bottom: 16px;
`;

const AIButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.colors.clearGray};
  cursor: pointer;
`;

const InputLabel = styled.span`
  color: ${(props) => props.theme.colors.black};
  ${(props) => props.theme.fonts.variant.semiBold()};
  letter-spacing: 0.25px;
  font-size: 1.125rem;
`;

const InputWrapper = styled.div`
  width: 100%;
  height: 248px;
  padding: 24px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.highClearGray};
`;

const InputArea = styled.textarea`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.clearGray};
  background: ${(props) => props.theme.colors.primaryWhite};
  resize: none;
  padding: 16px;
`;

export { 
  StepWrapper,
  StepTitle,
  InputLabel,
  AIButton,
  InputHeader,
  InputWrapper,
  InputArea
};