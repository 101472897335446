import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import React from "react";
import ReactDOM from "react-dom/client";
import {RouterProvider} from 'react-router-dom';

import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./index.css";

import { Authenticator } from '@aws-amplify/ui-react';

import { Provider as ProviderStore } from "mobx-react";
import { ThemeProvider as ThemeProviderStyled } from "styled-components";
import reportWebVitals from "./reportWebVitals";
import DefaultStore from "./store/Store";
import { DefaultTheme, GlobalStyled } from "./theme";
import RoutesMenu from "./routes/Routes";
import {Signin} from "./components";

const segmentScript = document.createElement("script");
segmentScript.type = "text/javascript";
segmentScript.async = true;
segmentScript.src =
  "https://cdn.segment.com/analytics.js/v1/wQtkyaBqtMHrVz3FReAN7iK9lYHbTBk7/analytics.min.js";
document.head.appendChild(segmentScript);

const root = ReactDOM.createRoot(document.getElementById("root"));
const defaultTheme = createTheme();
root.render(
  <Authenticator.Provider>
    <ThemeProvider theme={defaultTheme}>
      <ThemeProviderStyled theme={DefaultTheme}>
        <GlobalStyled />
        <Signin>
          <ProviderStore myStore={DefaultStore}>
            <RouterProvider router={RoutesMenu} />
          </ProviderStore>
        </Signin>
      </ThemeProviderStyled>
    </ThemeProvider>
  </Authenticator.Provider>
);

reportWebVitals();


