import { API } from "aws-amplify";
import { action, makeObservable, observable, flow, toJS } from "mobx";
import { getUploadUrlFile, getUrlFile } from "../graphql/queries";
import axios from "axios";

class TemplateEditorStore {
  isError = false;
  isLoading = false;
  initialLoading = false;

  variables = [
    {
      name: "",
      variable: "",
      origin: "FIXED",
      value: ""
  }
  ];
  from = "";
  to = "";
  subject = "";
  cc = "";
  bcc = "";
  description = "";
  payload = {};
  templateJson = null;
  updatingTemplate = false;
  customer = "";
  id = "";
  template = "";
  body = ""
  sourceList = [];
  s3FileRoute = "email_templates";

  constructor() {
    makeObservable(this, {
        isLoading: observable,
        isError: observable,
        initialLoading: observable,
        variables: observable,
        from: observable,
        to: observable,
        subject: observable,
        cc: observable,
        bcc: observable,
        description: observable,
        payload: observable,
        templateJson: observable,
        updatingTemplate: observable,
        customer: observable,
        id: observable,
        template: observable,
        body: observable,
        sourceList: observable,
        s3FileRoute: observable,
        newVariable: action,
        updateVariableName: action,
        updateVariableVariable: action,
        updateVariableType: action,
        updateVariableValue: action,
        removeVariable: action,
        updateGeneralField: action,
        updateVariableValueDynamic: action,
        savePayload: action,
        saveTemplate: flow,
        uploadFileToS3: flow,
        loadTemplate: flow,
        clearTemplate: action,
        loadPayload: action,
        onClearError: action,
        setId: action
    });
  }

  setId(value){
    this.id = value;
  }

  updateGeneralField(field, value){
    this[field] = value;
  }

  newVariable(name="", variable="", origin="FIXED", value="", source=null, field=null) {
    this.variables.push(
        {
            name,
            variable,
            origin,
            value,
            _value: {source, field}
        }
    )
  }

  updateVariableName(idx, value) {
    this.variables[idx].name = value;
  }

  updateVariableVariable(idx, value) {
    this.variables[idx].variable = value;
  }

  updateVariableType(idx, value) {
    this.variables[idx].origin = value;
  }

  updateVariableValue(idx, value) {
    this.variables[idx].value = value;
  }

  updateVariableValueDynamic(idx, value, source=false) {
    if(!Object.keys(this.variables[idx]._value || []).length) this.variables[idx]._value = {};
    if(source){
      this.variables[idx]._value['source'] = value;
    }else{
      this.variables[idx]._value['field'] = value;
    }
    this.variables[idx].value = `${this.variables[idx]._value['field']}`
  }

  removeVariable(idx) {
    this.variables.splice(idx, 1)
  }

  savePayload(){
    const bindingArray = this.variables.map(variable => ({
      name: variable.name,
      variable: `{{${variable.variable}}}`,
      origin: variable.origin,
      value: variable.value,
    }))

    const payloadArray = [
      {field: "from", value: this.from},
      {field: "to", value: this.to},
      {field: "subject", value: this.subject},
      {field: "cc", value: this.cc},
      {field: "bcc", value: this.bcc},
      {field: "description", value: this.description},
      {field: "body", value: `${this.id}.html`},
      {field: "template", value: `${this.id}.json`},
    ]

    this.payload = {
      binding: bindingArray,
      payload: payloadArray
    }

  }

  loadPayload(action){
    this.id = action.id;
    const payload = action?.payload;
    if(payload?.length){
      payload.forEach(field => {
        this[field.field] = field.value
      }) 
    }

    if(action?.binding?.length){
      this.variables = []
      action.binding.forEach(binding => {
        binding.variable = binding.variable.replaceAll('{', '')
        binding.variable = binding.variable.replaceAll('}', '')

        if(binding.origin === 'EVENT'){
          binding.value = binding.value || ''
          const [source, field] = binding.value?.split('.')
          binding._value = {source, field}
        }
        this.variables.push(binding)
      }) 
    }
  }

  *loadTemplate(){
    if(!this.template) return
    try {
      this.isLoading = true;
      this.isError = false;
      const response = yield API.graphql({
        query: getUrlFile,
        variables: {
          input: {
            customer: this.customer,
            file_name: `${this.template}`,
            file_route: this.s3FileRoute,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      const uploadUrl = JSON.parse(response.data.getUrlFile?.body);
      const res = yield axios({ url: uploadUrl, method: 'GET', responseType: 'blob' })
      const blobRes = new Blob([res.data]);
      const streamer = yield blobRes.text()

      this.templateJson = JSON.parse(streamer)
    } catch (error) {
      this.templateJson = {}
    } finally{
      this.isLoading = false;
    }
  }

  *uploadFileToS3(content, type, name){
    const blobFile = new Blob([content], {type})

    const response = yield API.graphql({
      query: getUploadUrlFile,
      variables: {
        input: {
          customer: this.customer,
          file_name: name,
          file_type: type,
          file_route: this.s3FileRoute,
          overwrite: true
        },
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });

    const uploadUrl = JSON.parse(response.data.getUploadUrlFile?.body);
    const config = {
      headers: {
        "Content-Type":type,
      },
    };

    yield axios.put(uploadUrl, blobFile, config);

  }

  *saveTemplate(html, json){
    try {
      this.updatingTemplate = true;
      this.isError = false;
      yield this.uploadFileToS3(JSON.stringify(json, null, 2), 'application/json', `${this.id}.json`);
      yield this.uploadFileToS3(html, 'text/html', `${this.id}.html`);
    } catch (error) {
      this.isError = true;
    } finally{
      this.updatingTemplate = false;
    }
  }

  clearTemplate(){
    this.isError = false;
    this.isLoading = false;
    this.updatingTemplate = false;
    this.initialLoading = false;
    this.s3FileRoute = "email_templates";

    this.template = "";
    this.body = "";
    this.variables = [{
      name: "",
      variable: "",
      origin: "FIXED",
      value: ""
    }];
    this.from = "";
    this.to = "";
    this.subject = "";
    this.cc = "";
    this.bcc = "";
    this.description = "";
    this.payload = {};
    this.templateJson = null;
  }

  onClearError(){
    this.isError = false;
  }

}

export default TemplateEditorStore;