import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  IconButton,
} from "@mui/material";
import { API } from "aws-amplify";
import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import ConfirmationDialog from "../../components/common/CommonConfirmationDialog";
import React, { useEffect, useState } from "react";
import { getCustomers, getUsers, deleteCustomer, putCustomer } from "../../graphql/queries";
import CustomerCreate from "./CustomerCreate";
import {observer} from "mobx-react";
import {useStore} from "../../hooks";
import {useNavigate} from "react-router-dom";
import { CustomBox } from "../../theme/DefaultTheme";
import states from "../../components/common/USstates"


const AccountList = observer(() => {
  const { customer } = useStore();
  const navigate = useNavigate();

  customer.setCurrentPage("list");
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [dialogOpenDelete, setDialogOpenDelete] = useState(false);
  const [isEditingAssistant, setisEditingAssistant] = useState(false);

  const [validationErrors, setValidationErrors] = useState({});
  const { changeTitle } = useStore();
  changeTitle('Accounts');

  const fetchUsers = async (customerId) => {
    try {
      
      const resultUsers = await API.graphql({
        query: getUsers,
        variables: {
          input: { customer: customerId },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      const user_count = JSON.parse(resultUsers?.data?.getUsers?.body).length;
      let number_users = 0
      if (user_count == 0) {
        number_users = 0
      }else{
        number_users = user_count
      }

      return number_users;
    }catch (error) {
      console.error("Error fetching users", error);
      return 0
    }
  }

  const fetchCustomers = async () => {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    try {
      const resultCustomers = await API.graphql({
        query: getCustomers,
        variables: {
          input: { id: "" },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      const customersData = JSON.parse(resultCustomers?.data?.getCustomers?.body);
      
      customersData.forEach(item => {
        const response = fetchUsers(item.id)
        response.then(response => {
          item.user_count = response
        })
        .catch(
          error => {
            console.error('Error:', error);
          }
        );
        
      });
      await delay(400)
      setCustomers(customersData);
    } catch (error) {
      console.error("Error fetching customers", error);
    }
  };

  const openDeleteCustomerDialog = (id) => {
    setDialogOpenDelete(true)
    setSelectedCustomer(id)
  }

  const handleDeleteCustomer = async (id) => {
    try {
      await API.graphql({
        query: deleteCustomer,
        variables: {
            input: {
                id
            },
        },
      });
    } catch (error) {
      console.error("Error deleting user", error);
    }
  };

  const handleEditCustomer = async ({row, table, values}) => {
    setisEditingAssistant(true);

    const onBoardingTemplate = {
      isKeyReady: false,
      isCampaingReady: false,
      isChannelReady: false,
      isAssistantReady: false
    }

    const {name, domains, state, city, street = '', zip_code=''} = values
    const editRequestBody = {
      id: row.id,
      name,
      domains,
      state,
      city,
      street,
      site_url: domains[0] || '',
      metadata: null,
      zip_code

    }
    try {
      await API.graphql({
        query: putCustomer,
        variables: {
            input: editRequestBody,
        },
      });
    } catch (error) {
      console.error("Error deleting user", error);
    } finally {
      setisEditingAssistant(false);
      table.setEditingRow(null);
    }
  };

  
  const openCreateModal = () => {
    navigate('/customers/add');
    //setIsCreateModalOpen(true);
  };

  useEffect(() => {
    fetchCustomers();

  }, []);

  const columns = useMemo(
    () => {
      const baseColumns = [
    { 
      accessorKey: "name", 
      header: "Company Name",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    { 
      accessorKey: "user_count", 
      header: "Number of Users",
      enableEditing: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    { 
      accessorKey: "street", 
      header: "Address",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    { 
      accessorKey: "city", 
      header: "City",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    { 
      accessorKey: "state", 
      header: "State or Province" ,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      editSelectOptions: states.map(item => ({
        name: item.name,
        value: item.name,
        
      })),
      muiEditTextFieldProps: {
        select: true,
        error: !!validationErrors?.state,
        helperText: validationErrors?.state,
      },
    },
    { 
      accessorKey: "zip_code", 
      header: "Postal Code",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      }, 
      muiEditTextFieldProps: {
        error: !!validationErrors.zip_code, //highlight mui text field red error color
        helperText: validationErrors.zip_code, //show error message in helper text.
        required: true,
        type: 'text',
        onChange: (event) => {
          const value = event.target.value;
          //validation logic
          if (!value) {
            setValidationErrors((prev) => ({ ...prev, zip_code: 'Postal Code is required' }));
          } else if (!(/^\d{5}(-\d{4})?$/.test(value))) {
            setValidationErrors({
              ...validationErrors,
              zip_code: 'Invalid postal code',
            });
          } else {
            delete validationErrors.zip_code;
            setValidationErrors({ ...validationErrors });
          }
        },
      },
    },
    { 
      accessorFn: (row) => new Date(row.insert_date),
      accessorKey: "insert_date", 
      header: "Creation Date",
      enableEditing: false,
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(),
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    { 
      accessorKey: "domains", 
      header: "Domains",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      muiEditTextFieldProps: {
        error: !!validationErrors.domains, //highlight mui text field red error color
        helperText: validationErrors.domains, //show error message in helper text.
        required: true,
        type: 'text',
        onChange: (event) => {
          const value = event.target.value;
          //validation logic
          if (!value) {
            setValidationErrors((prev) => ({ ...prev, domains: 'A domain is required' }));
          } else if (!(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g.test(value))) {
            setValidationErrors({
              ...validationErrors,
              domains: 'Invalid domain',
            });
          } else {
            delete validationErrors.domains;
            setValidationErrors({ ...validationErrors });
          }
        },
      },
      
    },
  ];
    return baseColumns;
  },
    [validationErrors]
  );

  const table = useMaterialReactTable({
    columns,
    data: customers,
    enableEditing: true,
    initialState: {
      sorting: [
        {
          id: 'insert_date', //sort by age by default on page load
          desc: true,
        },
      ],
    },
    getRowId: (row) => row.id,
    onEditingRowSave: (row) => handleEditCustomer(row),
    positionActionsColumn: 'last',
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <IconButton onClick={() => table.setEditingRow(row)}>
          <EditIcon />
        </IconButton>
        {/* <IconButton onClick={() => openDeleteCustomerDialog(row.id)}>
          <DeleteIcon />
        </IconButton> */}
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <CustomBox display="flex" justifyContent="flex-end">
        <div style={{ marginLeft: 'auto' }}>
          <Button variant="contained" onClick={openCreateModal}>
            Create Account
          </Button>
        </div>
      </CustomBox>
    ),
    
    
    state: {
      isSaving: isEditingAssistant,
    }
  });

  return (
    <>
      <MaterialReactTable table={table} />
      {/* <ConfirmationDialog
          open={dialogOpenDelete}
          onClose={() => setDialogOpenDelete(false)}
          title={'Are you sure you want to delete the selected customer?'}
          onConfirm={() => handleDeleteCustomer(selectedCustomer)}
          maxWidth="sm"
          fullWidth={false}
      /> */}
    </>
  );
});

export default AccountList;
