import styled from "styled-components";

export const AgentsTreeContainer = styled.div`
    width: 100;
    height: 70vh;
`;

export const AgentSelectorContainer = styled.div`
    position: absolute;
    width: 100%;
    top: ${(props) => props.$top-100}px;
    left: ${(props) => props.$left}px;
`;