import styled from "styled-components";

const TopMenu = styled.div`
  grid-area: top;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.clearGray};
`;

const HamburguerWrapper = styled.div`
  display: flex;
  flex-direcion: row;
  gap: 0.75rem;
  align-items: center;
  justify-content: flex-start;
`;

const TopMenuContentWrapper = styled.div`
  display: flex;
`;

const HamburguerAction = styled.div`
  cursor: pointer;
  height: auto;
  width: auto;
`;

const Title = styled.p`
  margin: 0;
  font-size: 0.875rem;
  font-weight: bold;
`;

const BackAction = styled.div`
  cursor: pointer;
  height: auto;
  width: auto;
`;

export {
  TopMenu,
  HamburguerWrapper,
  TopMenuContentWrapper,
  HamburguerAction,
  Title,
  BackAction,
};
