import React from 'react';
import {
  PaginationWrapper,
  PageInfo,
  PageControls,
  Select,
  Button
} from './PaginationComponent.styled';

const PaginationComponent = ({ totalEntries, entriesPerPageOptions, currentPage, onPageChange, onEntriesPerPageChange }) => {
  const totalPages = Math.ceil(totalEntries / entriesPerPageOptions[0]);

  return (
    <PaginationWrapper>
      <PageInfo>
        Show
        <Select onChange={(e) => onEntriesPerPageChange(Number(e.target.value))}>
          {entriesPerPageOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
        Entries
      </PageInfo>
      <PageControls>
        <Button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
          &lt;
        </Button>
        {[...Array(totalPages).keys()].map((num) => (
          <Button
            key={num + 1}
            active={currentPage === num + 1}
            onClick={() => onPageChange(num + 1)}
          >
            {num + 1}
          </Button>
        ))}
        <Button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
          &gt;
        </Button>
      </PageControls>
    </PaginationWrapper>
  );
};

export default PaginationComponent;
