import styled from "styled-components"

import { DefaultTheme as theme } from "../../../theme";

const Curtain = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: ${theme.colors.blackTwo};
    opacity: 0.4;
    z-index: 1500;
    cursor: pointer;
    transition: opacity 2s ease;
`

const HeaderContainer = styled.div`
    z-index: 1600;
`

const PopupContentContainer = styled.div`
    position: absolute;
    display: flex;
    width: 511px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border: 1px solid ${theme.colors.clearGray};
    background-color: ${theme.colors.primaryWhite};
    border-radius: 8px;
    right: ${(props) => props.right}px;
    top: ${(props) => props.height}px;
    z-index: 2000;
    user-select: none;
`;

export {
    HeaderContainer,
    Curtain,
    PopupContentContainer,
}