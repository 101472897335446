import React from "react";
import { Route, Routes } from "react-router-dom";
import NotificationView from "./NotificationView";


const NotificationUserPage = () => {
  return (
    <Routes>
      <Route index element={<NotificationView />} />
      
    </Routes>
  );
};

export default NotificationUserPage;
