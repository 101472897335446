import styled, { css } from "styled-components"

const PermissionCardWrapper = styled.div`
    cursor: pointer;
    padding: 8px;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:focus {
        border-left: solid 4px ${props => props.theme.colors.clearBlue};
        background-color: ${props => props.theme.colors.clearBlue}0D;
    }

`;

const PermissionCardContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: 10px;
    justify-content: space-between;
`;

const PermissionTitle = styled.p`
    font-size: 1em;
    font-weight: 500;
    margin: 0;
    line-height: normal;
`;

const PermissionDescription = styled.div`
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    gap: 20px;
`;

const ChildPermissionWrapper = styled.div`
    margin-left: 30px;
    ${(props) => props.$display &&
    css`
        display: none;
    `}
`;

export {
    PermissionCardWrapper,
    PermissionTitle,
    PermissionCardContainer,
    PermissionDescription,
    ChildPermissionWrapper
}