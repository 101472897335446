import styled, { css } from "styled-components";

export const UserContainer = styled.div`
    border: 1.3px solid ${(props) => props.theme.colors.silverGrayTwo};
    width: 100%;
    max-width: 355px;
    border-radius: 10px;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    gap: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    max-height: 150px;
`;

export const AppointmentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 4px;
`;

export const AppointmentName = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: left;
`;

export const AppointmentTitle = styled.div`
    font-size: 18px;
    font-weight: 700;
`;

export const AppointmentDescription = styled.div`
    color: ${(props) => props.theme.colors.greyTwo};
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
`;

export const AppointmentFooter = styled(AppointmentDescription)`
    color: ${(props) => props.theme.colors.black};
`;

export const AppointmentBody = styled.span`
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: auto;
    max-height: 120px;
`;

export const ExpandedAppointment = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
`;

export const ButtonContainer = styled.div`
    position: absolute;
    bottom: -8px;
    right: -10px;
`;

export const UserInfoLine = styled.div`
    display: flex;
    flex-direction: row;
`;

export const SegmentTag = styled.span`
    font-weight: 400;
    border-radius: 33px;
    padding: 0 10px;
    height: 24px;
    margin-top: 10px;
    color: ${(props) => props.theme.colors.greyTwo};
    text-align: center;
    ${(props) => props.segmentType === "Low" &&
    css`
        background-color: #d6eeae;
    `}
    ${(props) => props.segmentType === "Medium" &&
    css`
        background-color: #9cb5f5;
    `}
    ${(props) =>  props.segmentType === "High" &&
    css`
        background-color: #e9c29e;
    `}
`;

export const SegmentTagInput = styled.div`
    width: 32em;
    border: 1px solid ${(props) => props.theme.colors.grey};
    height: 40px;
    border-radius: 7px;
    background-color: ${(props) => props.theme.colors.white};
    display: flex;
    align-items: center;
    padding-left: 10px;
    text-align: center;
`;

export const SegmentDescription = styled.span`
    font-size: 14px;
    font-weight: 400;
    text-align: left; `;

