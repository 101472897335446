import styled from "styled-components";

export const RightAlignedDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
`;

export const PaddedContainer = styled.div`
  padding: 16px;
`;
