import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { 
    PermissionsWrapper, 
    PermissionNavbarWrapper,
    PermissionsCardsWrapper,
    ButtonSelectWrapper
} from "./UserPermissions.styled";
import PermissionCard from "../../../NewUserPage/components/PermissionCard/PermissionCard";
import PermissionNavbar from "../../../NewUserPage/components/PermissionNavbar/PermissionNavbar";
import useStore from "../../../../../../hooks/useStore";
import CustomButton from "../../../../../../elements/v2/Buttons/CustomButton/CustomButton";
import SimpleSelect from "../../../../../../elements/v2/Selects/SimpleSelect";
import ActionSelect from "../ActionSelect/ActionSelect";
import Spinner from "../../../../../../components/common/Spinner";
import { UserColumn } from "../UserGeneral/UserGeneral.styled";

const UserPermission = observer(() => {
    const { listUserStore, permissionsSetupStore } = useStore();

    useEffect(() => {
        if(listUserStore.userData.id){
            listUserStore.loadAccessTabRequests(listUserStore.userData.customer)
        }
    }, [listUserStore.userData.id])

    useEffect(() => {
        if(listUserStore.permissionsCatalog.length){
          permissionsSetupStore.setupPermissionsCatalog(listUserStore.permissionsCatalog, listUserStore.combinedPermissions, listUserStore.rolePermissions);
        }
      }, [listUserStore.combinedPermissions.length, listUserStore.permissionsCatalog.length])

    return (
        <>
        {!listUserStore.permissionsLoading ? 
            <>
                <ButtonSelectWrapper>
                    <CustomButton text="Active" showStatusDot={true} />
                    <ActionSelect placeholder={listUserStore.selectedRole?.name} options={listUserStore.roles} handleAction={(name, value) => listUserStore.selectRole(name, value)}/>
                </ButtonSelectWrapper>
                <PermissionsWrapper>
                    <PermissionNavbarWrapper>
                        <PermissionNavbar data={permissionsSetupStore.initialPermissionsCatalog}/>
                    </PermissionNavbarWrapper>
                    <PermissionsCardsWrapper>
                        <div>
                            <div>
                                <PermissionCard data={permissionsSetupStore.permissionsCatalog} reviewMode={false}/>
                            </div>
                        </div>
                    </PermissionsCardsWrapper>
                </PermissionsWrapper> 
            </>
        :
            <UserColumn>
                <Spinner/>
            </UserColumn>
        }
        </>
    );
});

export default UserPermission;
