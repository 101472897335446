import styled from 'styled-components';
import {Alert} from "@mui/material";

const StepperWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.width || '100%'};
`;

const StepperError = styled(Alert)`
    width: ${(props) => props.width || '100%'};
    box-sizing: border-box;
`;

const StepperLoading = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 100px;
    width: ${(props) => props.width || '100%'};
`;

const StepperBody = styled.div`
    margin-top: 20px;
    width: ${(props) => props.width || '100%'};
`;

const StepperActions = styled.div`
    display: flex;
    width: ${(props) => props.width || '100%'};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 80px;
`;

const StepperProgressBarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 928px;
`;

const StepperProgressBarLabels = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    ${(props) => props.theme.fonts.variant.regular()};
    font-size: 0.87rem;
    line-height: normal;
    color: ${(props) => props.theme.colors.black};
`;

const StepperProgressBar = styled.div`
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background: ${(props) => props.theme.colors.greyTwelve};
`;

const StepperProgressBarIndicator = styled.div`
    width: ${(props) => props.width || '0%'};
    height: 100%;
    border-radius: 4px; 
    background: ${(props) => props.theme.colors.black};;
`;

export {
  StepperWrapper,
  StepperError,
  StepperLoading,
  StepperBody,
  StepperActions,
  StepperProgressBarWrapper,
  StepperProgressBarLabels,
  StepperProgressBar,
  StepperProgressBarIndicator
};