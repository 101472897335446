import Button from "@mui/material/Button";
import React from "react";

const CustomButton = ({ label, onClick, color, variant, type }) => {
  return (
    <Button
      variant={variant || "contained"}
      color={color || "primary"}
      onClick={onClick}
      type={type || "button"}
    >
      {label}
    </Button>
  );
};

export default CustomButton;
