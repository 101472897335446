import React, { useState, useMemo, useEffect } from "react";
import { useStore } from "../../../hooks";
import { SuccessfulModal } from "../../../elements";
import AdmirationSign from '../../../elements/icon/assets/admiration_sign.svg';
import { observer } from "mobx-react";
import { CustomGenericListStyle, defaultCellProps, MediaQuery } from './HumanInTheLoop.styled';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from "material-react-table";
import { Box } from '@mui/material';

import { getDensity } from "../../../utils/GetDensity/GetDensity";
import { useMediaQueries } from "../../../utils/mediaQueries";
import MessageCell from "../../MessageCell/MessageCell";
import StatusButtons from "../../StatusButton/StatusButton";
import { TopToolbar } from "../../sharedStyles/TopToolbar";

const HumanInTheLoop = observer(() => {
    const { authStore, humanInTheLoopStore } = useStore();
    const { isMobile, isTablet, isDesktop } = useMediaQueries();
    const { selectedAccount } = authStore;

    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([{ id: 'customer', desc: false }]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);

    const [expandedRows, setExpandedRows] = useState({});

    const toggleExpandRow = (rowId) => {
        setExpandedRows((prev) => ({
            ...prev,
            [rowId]: !prev[rowId]
        }));
    };

    useEffect(() => {
        const loadData = async () => {
            if (selectedAccount) {
                setIsLoading(true);
                try {
                    humanInTheLoopStore.getData({
                        customer: selectedAccount,
                        pageIndex: pagination.pageIndex,
                        pageSize: pagination.pageSize,
                        sortBy: sorting,
                        search: globalFilter
                    });
                } catch (error) {
                    humanInTheLoopStore.setError(true);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        loadData();
    }, [selectedAccount, pagination.pageIndex, pagination.pageSize, sorting, globalFilter, humanInTheLoopStore]);

    const columns = useMemo(
        () => [
            {
                accessorKey: "customer",
                header: "Customer",
                ...defaultCellProps,
            },
            {
                accessorKey: "message",
                header: "Message",
                maxSize: 300, 
                muiTableHeadCellProps: defaultCellProps.muiTableHeadCellProps,
                Cell: ({ cell, row }) => (
                    <MessageCell 
                        message={cell.getValue()} 
                        isExpanded={expandedRows[row.id]} 
                        onToggleExpand={() => toggleExpandRow(row.id)} 
                    />
                ),
            },
            {
                accessorKey: "date",
                header: "Date",
                ...defaultCellProps,
            },
            {
                accessorKey: "status",
                header: "Actions",
                Cell: ({ cell }) => <StatusButtons status={cell.getValue()} />,
            },
        ],
        [expandedRows]
    );

    const table = useMaterialReactTable({
        columns,
        data: humanInTheLoopStore.humanInTheLoop !== null ? humanInTheLoopStore.humanInTheLoop : [],
        manualPagination: true,
        manualSorting: true,
        manualGlobalFilter: true,
        rowCount: humanInTheLoopStore.totalRowCount || 0,
        initialState: {
            sorting,
            density: 'compact',
        },
        showGlobalFilter: true,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableRowSelection: false,
        enableGrouping: false,
        enableTopToolbar: true,

        renderTopToolbarCustomActions: () => null,

        positionToolbarAlertBanner: 'none',

        state: {
            pagination,
            globalFilter,
            sorting,
            isLoading: isLoading || isRefetching,
        },
        displayColumnDefOptions: {
            'mrt-row-actions': {
                enableColumnVisibility: false,
            },
            'mrt-row-select': {
                enableColumnVisibility: false,
            },
            'mrt-row-numbers': {
                enableColumnVisibility: false,
            },
        },

        muiTableBodyCellProps: ({ row }) => ({
            sx: {
                '& .MuiCheckbox-root': {
                    transform: 'scale(0.6)',
                },
            },
            onClick: () => {
            },
        }),

        muiTableHeadCellProps: {
            sx: {
                '& .MuiCheckbox-root': {
                    transform: 'scale(0.6)',
                },
            },
        },

        onGlobalFilterChange: (filter) => {
            setGlobalFilter(filter);
            setPagination((prev) => ({ ...prev, pageIndex: 0 }));
        },
        onPaginationChange: setPagination,
        onSortingChange: (sorting) => {
            setSorting(sorting);
            setPagination((prev) => ({ ...prev, pageIndex: 0 }));
        },
        onPageSizeChange: (newPageSize) => {
            setPagination((prev) => ({ ...prev, pageSize: newPageSize, pageIndex: 0 }));
        },
        getRowId: (row) => row.id,
        muiTablePaperProps: {
            sx: {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                height: '100%',
                paddingLeft: '20px',
                paddingRight: '20px',
            },
        },
        muiTopToolbarProps: {
            component: TopToolbar,
        },
        muiTableContainerProps: {
            sx: MediaQuery
        },
    });

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                <MaterialReactTable table={table} />
                {humanInTheLoopStore.isError && (
                    <SuccessfulModal
                        isOpen={humanInTheLoopStore.isError}
                        onClose={() => humanInTheLoopStore.onClearError()}
                        imageSrc={AdmirationSign}
                        title="Oops!"
                        subtitle="An error occurred."
                        subtitle2="Please try again later."
                    />
                )}
            </Box>
            <CustomGenericListStyle />
        </>
    );
});

export default HumanInTheLoop;
