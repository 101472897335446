import styled, { css } from "styled-components";

export const UserContainer = styled.div`
    border: 1.3px solid ${(props) => props.theme.colors.silverGrayTwo};
    width: 100%;
    max-width: 355px;
    height: 263px;
    border-radius: 10px;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 10px;
`;

export const AppointmentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 4px;
`;

export const AppointmentTitle = styled.div`
    font-size: 18px;
    font-weight: 700;
`;

export const AppointmentDescription = styled.div`
    color: ${(props) => props.theme.colors.greyTwo};
    font-size: 14px;
    font-weight: 500;
`;

export const AppointmentFooter = styled(AppointmentDescription)`
    color: ${(props) => props.theme.colors.black};
`;

export const AppointmentBody = styled.span`
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export const ExpandedAppointment = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
`;

export const ButtonContainer = styled.div`
    position: absolute;
    bottom: -8px;
    right: -10px;
`;


