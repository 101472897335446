export const hideHelpContainer = () => {
     const iframe = document.querySelector('iframe#jsd-widget');
     if (iframe) {
       const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
       const helpContainer = iframeDocument?.querySelector('#button-container');
       if (helpContainer) {
         helpContainer.style.display = 'none';
         helpContainer.style.height = '0px';
          helpContainer.style.width = '0px';
       }
     }
  };
  
  export const adjustWidgetPosition = () => {
     const iframe = document.querySelector("iframe#jsd-widget");
     if (iframe) {
       iframe.style.position = "fixed";
       iframe.style.top = "50px";
       iframe.style.right = "100px";
       iframe.style.margin = "0";
     }
  };
  
  export const showHelpButton = () => {
     const iframe = document.querySelector('iframe#jsd-widget');
     if (iframe) {
       const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
       const helpContainer = iframeDocument?.querySelector('#button-container');
       if (helpContainer) {
         const helpButton = iframeDocument?.querySelector('#help-button');
         if (helpButton) {
           helpButton.click();
         }
       }
     }
  };
  