import React, {useEffect, useMemo, useState} from "react";
import {
  StepperActions,
  StepperLoading,
  StepperBody,
  StepperWrapper,
  StepperError,
  StepperProgressBar,
  StepperProgressBarLabels,
  StepperProgressBarWrapper,
  StepperProgressBarIndicator
} from "./DefaultStepper.styled";
import {RoundedButton} from "../button";
import {Icon} from "../icon";
import { LoadingBox } from "../../components/loading/Loading.styled";

const DefaultStepper = ({
  children,
  onFinish,
  onCancel,
  onBack,
  status,
  width,
  stepperErrorWidth,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [statusValue, setStatusValue] = useState(status);
  const steps = useMemo(() => React.Children.toArray(children), [children]);
  const step = useMemo(() => steps.length > 0 ? steps[activeStep] : undefined, [activeStep, steps]);

  useEffect(() => {
    if (statusValue === 'error' || statusValue === "validation") {
      const delayDebounceFn = setTimeout(() => {
        setStatusValue('idle');
      }, 3000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [statusValue]);

  useEffect(() => {
    if (statusValue === 'error') {
      onHandleNext();
    }

    setStatusValue(status);
  }, [status]);

  if (steps.length === 0) {
    return <></>;
  }

  const { isComplete, validationErrorLabel = "Error in step!", onNext, direction = 'vertical', labelNext } = step.props;

  const onHandleNext = () => {
    const nextIndex = activeStep + 1;
    const isCompleteValidation = isComplete === undefined ? true : isComplete();

    if (isCompleteValidation && statusValue === 'idle') {
      if(onNext) {
        onNext();
        setStatusValue('loading');
      } else {
        if (nextIndex < steps.length) {
          setActiveStep(nextIndex);
          setStatusValue('idle');
        } else {
          onFinish && onFinish();
        }
      }
    } else {
      setStatusValue('validation');
    }
  };

  const onHandleBack = () => {
    if(statusValue === 'idle') {
      if(activeStep > 0) {
        setStatusValue('idle');
        setActiveStep(activeStep - 1);
        onBack && onBack();
      }
    }
  };

  const onHandleCancel = () => {
    onCancel && onCancel();
  }

  return (
      <StepperWrapper>
        <StepperActions>
          {activeStep === 0 && <RoundedButton width={'133px'} onClick={onHandleCancel}>Cancel</RoundedButton>}
          {activeStep > 0 && <RoundedButton
            width={'133px'}
            disabled={statusValue === 'loading'}
            onClick={onHandleBack}
            icon={{
              element: <Icon name="arrowBackOutlined" width="24px" height="24px" />,
              position: 'left'
            }}>
            Previous
          </RoundedButton>
          }
          <StepperProgressBarWrapper>
            <StepperProgressBarLabels>
              <div>Step {activeStep+1} of {steps.length}</div>
              <div>{activeStep+1}/{steps.length}</div>
            </StepperProgressBarLabels>
            <StepperProgressBar>
              <StepperProgressBarIndicator width={`${(activeStep+1) * 100 / steps.length}%`} />
            </StepperProgressBar>
          </StepperProgressBarWrapper>
          <RoundedButton
            width={'133px'}
            disabled={statusValue === 'loading'}
            onClick={onHandleNext}
            icon={{
              element: <Icon name="arrowForwardOutlined" width="24px" height="24px" />,
              position: 'right'
            }}>
            {labelNext || 'Next'}
          </RoundedButton>
        </StepperActions>
        { statusValue === 'loading' && <StepperLoading width={width}><LoadingBox /></StepperLoading> }
        { statusValue === 'error' && <StepperError width={stepperErrorWidth} variant="outlined" severity="error">{validationErrorLabel}</StepperError> }
        { statusValue === 'validation' && (isComplete === undefined ? false : !isComplete()) && <StepperError width={stepperErrorWidth} variant="outlined" severity="warning" >{validationErrorLabel}</StepperError> }
        { statusValue === 'idle' && <StepperBody width={width}>{step}</StepperBody> }
      </StepperWrapper>
  )
};

DefaultStepper.Step = ({ children, ...props }) => {
  return <>{children}</>;
};

export default DefaultStepper;
