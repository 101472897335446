import React from 'react';
import { MessageContainer, MessageContent, IconWrapper } from "./MessageCell.styled";
import { IconButton } from "../../elements";
import { useMediaQueries } from '../../utils/mediaQueries';

const MessageCell = ({ message, isExpanded, onToggleExpand }) => {
    const { isMobile } = useMediaQueries();
    const truncatedMessage = message.length > 100 ? `${message.substring(0, 100)}...` : message;

    return (
        <MessageContainer isMobile={isMobile}>
            <MessageContent expanded={isExpanded}>
                {isExpanded ? message : truncatedMessage}
            </MessageContent>
            <IconWrapper onClick={onToggleExpand}>
                <IconButton 
                    icon={isExpanded ? 'arrowUp' : 'arrowDown'}
                    height="14px" 
                    width="14px"
                />
            </IconWrapper>
        </MessageContainer>
    );
};

export default MessageCell;
