import styled, { css } from "styled-components";

const DropDownContainer = styled.div`
  position: relative;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.clearGray};
  text-align: left;
  background-color: ${(props) => props.theme.colors.silverGrayThree};
  font-size: 0.75rem;
  min-width: 80px;
`;

const DropDownHeader = styled.div`
  position: relative;
  cursor: pointer;
  padding: 3px 9px;
  text-transform: ${props => props.$textTransform || 'none'};

  &:after {
    content: "";
    position: absolute;
    right: 1rem;
    width: 23px;
    height: 30px;
    background-image: url('${(props) => props.arrow}');
    background-repeat: no-repeat;
    top: -3px;
    pointer-events: none;
  }
`;

const DropDownList = styled.ul`
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.clearGray};
  position: absolute;
  right: 0;
  z-index: 10;
  padding: 0;
  margin: 15px 0 0 0;
  background: white;
  max-height: 200px;
  overflow-y: auto;
  list-style: none;
  min-width: 150px;
`;

const ListItem = styled.li`
  list-style: none;
  height: 27px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 3px 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.silverGrayTwo};
  color: ${(props) => props.theme.colors.greyThirteen};
  background-color: ${(props) => props.theme.colors.silverGrayThree};

  &:hover {
    background-color: ${(props) => props.theme.colors.highClearGray};
  }

  &:last-child {
    border-bottom: none;
  }

  img {
    margin-right: 10px;
  }
`;

const ListInput = styled.input`
  padding: 3px 6px;
  height: 40px;
  width: 100%;
  margin-top: 5px;
  border: 1px solid ${props => props.theme.colors.clearGray};
  color: ${(props) => props.theme.colors.greyThirteen};
  border-radius: 8px;
  box-sizing: border-box;
`;

const DropDownBody = styled.div`
  ${props => props.$searchBar && css`
    padding: 10px;
    background-color: ${props => props.theme.colors.highClearGray};
    border-radius: 8px;
  `}
`;

export {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  ListItem,
  ListInput,
  DropDownBody
};
