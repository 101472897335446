import React, { useState, useEffect } from "react";
import Widget from "./Widget";
import {
  SubTitle,
  InfoWrapper,
  InfoList,
  DataAvaliableWrapper,
} from "./Widget.styled";
import { useStore } from "../../../../../hooks";
import { observer } from "mobx-react-lite";
import { Icon } from "../../../../../elements";
import Spinner from "../../../../../components/common/Spinner";
import CheckIcon from "./CheckIcon";

const GeneralInfo = observer(() => {
  const { unifiedCustomerProfileStore } = useStore();
  const contactInfo = unifiedCustomerProfileStore.contactInfo[0] || {};

  return (
    <Widget
      title="Contact Info"
      height={Object.keys(contactInfo).length === 0 ? "auto" : "341px"}
    >
      {unifiedCustomerProfileStore.isLoading ? (
        <Spinner />
      ) : (
        <InfoWrapper>
          {Object.keys(contactInfo).length === 0 ? (
            <DataAvaliableWrapper id="dataAvaliableWrapper">
              <p>No data available</p>
              <p>
                No data is available for this card at the moment. Please check
                again later.
              </p>
              <p>
                <Icon name={"noDataAvaliable"} width={"70px"} height={"68px"} />
              </p>
            </DataAvaliableWrapper>
          ) : (
            <InfoList>
              <li>
                <SubTitle>Name:</SubTitle>
                <span>{contactInfo.contact_full_name}</span>
              </li>
              <li>
                <SubTitle>Phone number:</SubTitle>
                <span>{contactInfo.contact_phone_number}</span>
              </li>
              <li>
                <SubTitle>Email:</SubTitle>
                <span>{contactInfo.contact_email}</span>
              </li>
              <li>
                <SubTitle>State:</SubTitle>
                <span>{contactInfo.contact_state}</span>
              </li>
              <li>
                <SubTitle>City:</SubTitle>
                <span>{contactInfo.contact_city}</span>
              </li>
              <li>
                <SubTitle>Address:</SubTitle>
                <span>{contactInfo.contact_address}</span>
              </li>
              <li>
                <SubTitle>Company name:</SubTitle>
                <span>{contactInfo.contact_company_name}</span>
              </li>
              <li>
                <SubTitle>Postal code:</SubTitle>
                <span>{contactInfo.contact_postal_code}</span>
              </li>
              <li>
                <SubTitle>Email Allow:</SubTitle>
                  <CheckIcon value={contactInfo.do_not_email} />
              </li>
              <li>
                <SubTitle>Call Allow:</SubTitle>
                  <CheckIcon value={contactInfo.do_not_call} />
              </li>
              <li>
                <SubTitle>Mail Allow:</SubTitle>
                  <CheckIcon value={contactInfo.do_not_mail} />
              </li>
            </InfoList>
          )}
        </InfoWrapper>
      )}
    </Widget>
  );
});

export default GeneralInfo;
