import { action, makeObservable, observable, flow, toJS} from "mobx";
import { getActionTitles, getDataActionTitles, getCardLists } from "../../pages/v2/Conversations/EventName/Actions/consts";
import { API } from "aws-amplify";
import { getAction } from "../../graphql/queries";
import {v4 as uuidv4} from 'uuid';
import _ from "lodash";

class ConversationsActionsStore {
	isLoading = false;
    isError = false;
	listOfActionTitles = [];
	listOfActionLibraryCards = [];
	actions = [];
	selectedAction = {};

	constructor() {
		makeObservable(this, {
			listOfActionTitles: observable,
			listOfActionLibraryCards: observable,
			actions: observable,
			selectedAction: observable,
			onChangeTitles: action,
			getActions: flow,
			setSelectedAction: action,
			clear: action,
			addNewAction: action,
			updateAction: action
		});
	}

	loadExistingActions(actions){
		this.actions = actions;
	}

	onChangeTitles() {
		this.listOfActionTitles = ['All'];
	}

	setSelectedAction(action){
		this.selectedAction = action;
	}

	saveSelectedAction() {
		if (this.selectedAction.id) return false;
		const newAction = {
			id: uuidv4(),
			action: this.selectedAction.action,
			name: this.selectedAction.name,
			description: this.selectedAction.description,
			binding: [],
			payload: [],
		}
		this.actions.push(newAction);
	}

	duplicate(action){
		this.actions.push({...action, name: `${action.name} - copy`, id: uuidv4()})
  }
  
	addNewAction(action){
		this.actions.push(action);
	}

	updateAction(updatedAction) {
		const index = this.actions.findIndex(action => action.id === updatedAction.id);
		if (index !== -1) {
			this.actions[index] = updatedAction;
		} else {
			// If the action doesn't exist, add it as a new action
			this.addNewAction(updatedAction);
		}
		// Update the selectedAction as well
		this.selectedAction = updatedAction;
	}

	removeSelectedAction(){
		this.actions = this.actions.filter(action => action.id !== this.selectedAction.id)
	}

	*getActions(){
		try {
            this.isLoading = true;
            this.isError = false;

            const response = yield API.graphql({
                query: getAction,
                variables: { input: {id: ""} },
				authMode :'AMAZON_COGNITO_USER_POOLS'
            });
            const actions = JSON.parse(response.data?.getAction?.body);
            this.listOfActionLibraryCards = actions.map(action => ({...action, action: action.id, id:null}));
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
	}

	updateBindingsAndPayload(payload=[], bindings=[]){
		this.selectedAction.payload = _.cloneDeep(payload)
		this.selectedAction.binding = _.cloneDeep(bindings)
	}

	clear(){
		this.isLoading = false;
		this.isError = false;
		this.listOfActionTitles = [];
		this.listOfActionLibraryCards = [];
		this.actions = [];
		this.selectedAction = {};
	}

}

export default ConversationsActionsStore;
