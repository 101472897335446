import React, { useState, useEffect } from "react";
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useStore } from "../../../hooks";
import { ConfirmationDialog, RoundedButton, SuccessfulModal } from "../../../elements";
import {
    ThreeColumnGrid, SearchInput, SearchIcon, InputField, CustomComponent,
    InfoBoxWrapper, Title, Date, AgentViewContainer, CenteredCustomComponent,
    DividerLine, TruncatedDescription, CustomImage, CustomComponent1, LoadMoreContainer
} from "./AgentView.styled";
import Search from "../../../elements/icon/assets/lupa.svg";
import LogoAnn from "../../../elements/icon/assets/logo_ann_customer.svg";
import VerticalButton from "../../../elements/verticalButton/VerticalButton";
import DuplicateIcon from "../../../elements/icon/assets/duplicate.svg";
import SyncIcon from "../../../elements/icon/assets/sync.svg";
import PlaygroudIcon from "../../../elements/icon/assets/playground.svg";
import DeleteIcon from "../../../elements/icon/assets/delete_vertical_button.svg";
import AdmirationSign from '../../../elements/icon/assets/admiration_sign.svg';
import { Loading } from "../../../components/loading";

const AgentView = observer(() => {
    const navigate = useNavigate();
    const { viewAgentStore } = useStore();
    const { authStore } = useStore();
    const { selectedAccount } = authStore;
    const [visibleAssistants, setVisibleAssistants] = useState(6);
    const [searchTerm, setSearchTerm] = useState("");
    const [showCenteredComponent, setShowCenteredComponent] = useState(false);
    const confirmationDialogMessage = "Are you certain you want to delete this agent?";
    const [selectedRow, setSelectedRow] = useState(null);
    const [filteredAssistants, setFilteredAssistants] = useState([]);
    const [conciergeAssistants, setConciergeAssistants] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedAssistant, setSelectedAssistant] = useState(null);

    useEffect(() => {
        setSearchTerm("");
        viewAgentStore.onChangeAccount(selectedAccount.id);
        viewAgentStore.fetchAssistants();
        setVisibleAssistants(6);
    }, [selectedAccount]);

    useEffect(() => {
        setFilteredAssistants(
            viewAgentStore.assistants.filter(assistant =>
                assistant.name.toLowerCase().includes(searchTerm.toLowerCase()) && assistant.customer_id === viewAgentStore.account && assistant.provider === 'openai'
            )
        );
        setConciergeAssistants(
            viewAgentStore.assistants.filter(assistant => assistant.domain === 'concierge' && assistant.customer_id === viewAgentStore.account && assistant.provider === 'openai')
        );
        setShowCenteredComponent(true);
    }, [viewAgentStore.assistants, searchTerm]);

    const handleCopyAssistant = (agentId, customerId) => {
        navigate({
            pathname: "/duplicate-agent",
            search: createSearchParams({
                agent: agentId,
                customer: customerId
            }).toString(),
        });
    };

    const handlePlaygroundClick = (agentId, customerId) => {
        navigate({
            pathname: "/playground",
            search: createSearchParams({
                agent: agentId,
                customer: customerId,
            }).toString(),
        });
    };

    const handleDeleteClick = (assistant) => {
        setSelectedRow(assistant);
        viewAgentStore.onDeleteConfirmation(true);
    };

    const loadMore = () => {
        setVisibleAssistants(prevVisible => prevVisible + 6);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleImageClick = (assistant) => {
        setSelectedAssistant(assistant);
    };

    const handleStartChat = (assistant) => {
        console.dir(assistant)
        navigate({
            pathname: "/home",
            search: createSearchParams({
                agent: assistant.id,
                customer: assistant.customer_id,
            }).toString(),
        });
    };

    return (
        authStore.isSuperAdmin && (
            <>
                {viewAgentStore.isLoading && <Loading />}
                {!viewAgentStore.isLoading && (
                    <AgentViewContainer>
                        <ThreeColumnGrid>
                            <div>
                                <SearchInput>
                                    <SearchIcon src={Search} alt="Search" />
                                    <InputField type="text" value={searchTerm} onChange={handleSearchChange} />
                                </SearchInput>
                            </div>
                            <div>
                                <RoundedButton 
                                    width={'200px'}
                                    onClick={() => {
                                        navigate('/new-agent')
                                    }}>
                                        + Create New Agent
                                </RoundedButton>
                            </div>
                        </ThreeColumnGrid>
                        {showCenteredComponent && conciergeAssistants.length > 0 && (
                            <CenteredCustomComponent>
                                {conciergeAssistants.map(assistant => (
                                    <CustomComponent key={assistant.id}>
                                        <img src={LogoAnn} alt="Ann Logo" />
                                        <InfoBoxWrapper>
                                            <Title>{assistant.name}</Title>
                                            <TruncatedDescription>{assistant.prompt}</TruncatedDescription>
                                            <Date>
                                                Creation Date: {assistant.insert_date ? format(assistant.insert_date, 'dd MMM, yyyy') : ''}
                                            </Date>
                                        </InfoBoxWrapper>
                                        <VerticalButton options={[
                                            {
                                                icon: DuplicateIcon,
                                                title: 'Duplicate',
                                                onClick: () => {
                                                    if (assistant.id && assistant.customer_id) {
                                                        handleCopyAssistant(assistant.id, assistant.customer_id);
                                                    }
                                                }
                                            },
                                            {
                                                icon: SyncIcon,
                                                title: 'Sync',
                                            },
                                            {
                                                icon: PlaygroudIcon,
                                                title: 'Playground',
                                                onClick: () => {
                                                    if (assistant.id && assistant.customer_id) {
                                                        handlePlaygroundClick(assistant.id, assistant.customer_id);
                                                    }
                                                }
                                            },
                                        ]} />
                                    </CustomComponent>
                                ))}
                            </CenteredCustomComponent>
                        )}
                        <DividerLine />
                        {viewAgentStore.isError && <SuccessfulModal
                            isOpen={viewAgentStore.isError}
                            onClose={() => viewAgentStore.onClearError()}
                            imageSrc={AdmirationSign}
                            title="Oops!"
                            subtitle="An error occurred."
                            subtitle2="Please try again later."
                        />}
                        {/* Render fetched assistants here */}
                        <div>
                            <ThreeColumnGrid>
                                {filteredAssistants
                                    .filter(assistant => assistant.domain !== 'concierge')
                                    .slice(0, visibleAssistants)
                                    .map((assistant, index) => (
                                        <CustomComponent1 key={index}>
                                            <CustomImage src={LogoAnn} alt="Ann Logo" onClick={() => handleImageClick(assistant)} />
                                            <InfoBoxWrapper>
                                                <Title>{assistant.name}</Title>
                                                <TruncatedDescription>{assistant.prompt}</TruncatedDescription>
                                                <Date>Creation Date: {assistant.insert_date ? format(assistant.insert_date, 'dd MMM, yyyy') : ''}</Date>
                                            </InfoBoxWrapper>
                                            <VerticalButton options={[
                                                {
                                                    icon: DuplicateIcon,
                                                    title: 'Duplicate',
                                                    onClick: () => {
                                                        if (assistant.id && assistant.customer_id) {
                                                            handleCopyAssistant(assistant.id, assistant.customer_id);
                                                        }
                                                    }
                                                },
                                                {
                                                    icon: PlaygroudIcon,
                                                    title: 'Playground',
                                                    onClick: () => {
                                                        if (assistant.id && assistant.customer_id) {
                                                            handlePlaygroundClick(assistant.id, assistant.customer_id);
                                                        }
                                                    }
                                                },
                                                {
                                                    icon: DeleteIcon,
                                                    title: 'Delete',
                                                    onClick: () => {
                                                        if (assistant.id && assistant.customer_id) {
                                                            handleDeleteClick(assistant);
                                                        }
                                                    }
                                                },
                                            ]} />
                                        </CustomComponent1>
                                    ))}
                            </ThreeColumnGrid>

                            {viewAgentStore.assistants.length > visibleAssistants && (
                                <LoadMoreContainer>
                                    <RoundedButton width={'200px'} onClick={loadMore}>
                                        Load More
                                    </RoundedButton>
                                </LoadMoreContainer>
                            )}
                        </div>
                        <ConfirmationDialog
                            isOpen={viewAgentStore.isConfirm}
                            onClose={() => viewAgentStore.onDeleteConfirmation(false)}
                            onConfirm={() => viewAgentStore.handleConfirmDelete(selectedRow)}
                            title="Confirmation"
                            subtitle={confirmationDialogMessage}
                            confirmationLabel="Yes, sure"
                        />
                    </AgentViewContainer>
                )}
            </>
        )
    );
});

export default AgentView;
