import {useState} from 'react';
import {observer} from "mobx-react";
import {createSearchParams, useNavigate} from "react-router-dom";
import { 
    ModalWrapper, 
    ModalSubtitle, 
    FormSubtitle, 
    ModalContainer, 
    ModalTitle, 
    ModalImage, 
    ModalFooterWrapper, 
    ModalCloseButton, 
    ModalTestAgentButton
} from "../NewAgentPage.styled"
import Modal from '@mui/material/Modal';
import WarningIcon from '../../../elements/icon/assets/warning.svg'
import TestAgentIcon from '../../../elements/icon/assets/test_agent_black_icon.svg'
import XIcon from '../../../elements/icon/assets/x_icon.svg';
import {useStore} from "../../../hooks";

const AgentCompletedModal = observer(({description, redirectToAssistants=true, agentid, customerid, displayFooter=true}) => {
    const { newAgent } = useStore();
    const navigate = useNavigate()
    const [open, setOpen] = useState(true);
    const handleClose = () => {
        setOpen(false); 
        if(redirectToAssistants){
            navigate('/v2/assistants')
        }
    }

    const handleChatAgent = () => {
        navigate({
            pathname: "/playground",
            search: createSearchParams({
                agent: agentid || newAgent.id,
                customer: customerid || newAgent.account,
            }).toString(),
        });
    };

    return (
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
            <ModalWrapper>
                
                <ModalContainer>
                    <ModalImage src={WarningIcon} width={40} height={40}/>
                    <ModalTitle>Great!</ModalTitle>
                    <ModalSubtitle>{description || 'A new agent has been successfully created.'}</ModalSubtitle>
                    {displayFooter && <ModalFooterWrapper>
                        <FormSubtitle>Let's see it in action!</FormSubtitle>
                        <ModalTestAgentButton src={TestAgentIcon} width={40} height={40} onClick={() => handleChatAgent()}/>
                    </ModalFooterWrapper>}
                </ModalContainer>
                <ModalCloseButton src={XIcon} width={15} height={15}  onClick={() => handleClose()}/>
            </ModalWrapper>
        </Modal>
    );
});

export default AgentCompletedModal