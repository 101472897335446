import React, { useState, useEffect } from 'react';
import {
  CustomSelectWrapper,
  CustomSelect,
  Dropdown,
  SearchInputWrapper,
  SearchInput,
  SearchIcon,
  GroupListContainer,
  GroupList,
  GroupItem,
  SelectedGroups,
  SelectedGroup,
  CloseIcon,
  LabelGroup,
  AddButton,
  ButtonContainer
} from './CustomSelectedGroups.styled';
import { IconButton } from '../../../../../elements';
import { observer } from 'mobx-react-lite';
import useStore from '../../../../../hooks/useStore';

const CustomSelectComponent = observer(({ onAddGroups }) => {
  const { newUser } = useStore();
  const { groups, fetchGroups } = newUser;
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGroups, setSelectedGroups] = useState([]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectGroup = (group) => {
    if (!selectedGroups.includes(group.name)) {
      setSelectedGroups([...selectedGroups, group.name]);
    }
  };

  const handleRemoveGroup = (group) => {
    setSelectedGroups(selectedGroups.filter(item => item !== group));
  };

  const handleAddGroups = () => {
    onAddGroups(selectedGroups);
    setSelectedGroups([]);
    setIsOpen(false);
  };

  return (
    <CustomSelectWrapper>
      <CustomSelect onClick={toggleDropdown}>
        <span>Choose a group</span>
        <span>&#x25BC;</span>
      </CustomSelect>
      {isOpen && (
        <Dropdown>
          <SearchInputWrapper>
            <SearchInput
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <SearchIcon />
          </SearchInputWrapper>
          <SelectedGroups>
            {selectedGroups.map((group, index) => (
              <SelectedGroup key={index}>
                <span>{group}</span>
                <CloseIcon>
                  <IconButton icon="closeLight" height={'10px'} width={'10px'} onClick={() => handleRemoveGroup(group)} />
                </CloseIcon>
              </SelectedGroup>
            ))}
          </SelectedGroups>
          <LabelGroup>
            Groups
          </LabelGroup>
          <GroupListContainer>
            <GroupList>
              {groups.filter(group => group.name.toLowerCase().includes(searchTerm.toLowerCase())).map((group) => (
                <GroupItem key={group.id} onClick={() => handleSelectGroup(group)}>
                  {group.name}
                </GroupItem>
              ))}
            </GroupList>
          </GroupListContainer>
          <ButtonContainer>
            <AddButton onClick={handleAddGroups}>Add</AddButton>
          </ButtonContainer>
        </Dropdown>
      )}
    </CustomSelectWrapper>
  );
});

export default CustomSelectComponent;
