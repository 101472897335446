import styled from "styled-components";

export const CustomGrid = styled.div`
    display: flex;
    gap: 10px;
    justify-content: right;
    align-items: center;
    order: 1;
    flex: 0 1 auto;
    align-self: auto;
    margin-top: 0;
`;

export const Container = styled.div`
    padding: 10px;
`;
