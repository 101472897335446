import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks";
import { FormTitle, FormSubtitle, GridContainer } from "../NewCampaignPage.Styled";
import Editor from "../../../elements/richText/RichText";

const CampaignMessageForm = observer(() => {
  const { newCampaign } = useStore();
  const [editedTemplates, setEditedTemplates] = useState([...newCampaign.templates.templates]);

  useEffect(() => {
    const emailObject = {};
    editedTemplates.forEach((template, index) => {
      const key = `t${index + 1}`;
      emailObject[key] = template;
    });
    newCampaign.onChangeMessage(emailObject);
  }, [editedTemplates, newCampaign]);

  const handleTemplateChange = (index, newTemplate) => {
  const updatedTemplates = editedTemplates;
  const newText = newTemplate.root.children[0]?.children[0]?.text;
  updatedTemplates[index] = newText ? newText : '';
  setEditedTemplates(updatedTemplates);

  const emailObject = {};
  updatedTemplates.forEach((template, i) => {
    const key = `t${i + 1}`;
    emailObject[key] = template;
  });
  newCampaign.onChangeMessage(emailObject);
};

  return (
    <>
      <FormTitle>I'm really into this name!</FormTitle>
      <FormSubtitle>
        Craft top-notch templates to make sure your conversation starters to talk of the town!
      </FormSubtitle>
      <br />
      <GridContainer>
        {editedTemplates.map((template, index) => (
          <div key={index}>
            Message {index + 1} <br />
            <Editor
              initialText={template}
              onChange={(newTemplate) => handleTemplateChange(index, newTemplate)}
            />
          </div>
        ))}
      </GridContainer>
    </>
  );
});

export default CampaignMessageForm;
