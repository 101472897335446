import { useEffect } from 'react';
import { observer } from 'mobx-react';
import {useStore} from "../../hooks";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Loading } from '../../components/loading';
import { PageContainer, PageTile } from './AnnUnifiedProfilePage.styles';
import UserInfo from './components/UserInfo/UserInfo';
import CompanyDetails from './components/CompanyDetails/CompanyDetails';
import IndustryDetails from './components/IndustryDetails/IndustryDetails';
import ContactInfo  from './components/ContactInfo/ContactInfo';
import ContactsTabs from './components/CompanyTabs/Tabs';


const AnnUnifiedProfilePage = observer(() => {

    const { changeTitle } = useStore();
    const { annUnifiedCustomerProfileStore, authStore } = useStore();
    changeTitle('Unified Customer Profile');
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { selectedAccount } = authStore;


    useEffect(() => {
        annUnifiedCustomerProfileStore.onClearUCP();
        const segmentId = searchParams.get('id');
    
        if(!segmentId) {
          navigate('/contacts')
        }
        
        annUnifiedCustomerProfileStore.getUCP(selectedAccount.id, segmentId);
    }, []);

    useEffect(() => {
        if(authStore.selectedAccount && authStore.selectedAccount.id && annUnifiedCustomerProfileStore.ucp?.email){
            annUnifiedCustomerProfileStore.getConversationsHistory(authStore.selectedAccount.id)
        } 
      }, [authStore.selectedAccount, annUnifiedCustomerProfileStore.ucp?.email])
    
    return (
        <>
            {!annUnifiedCustomerProfileStore.isLoading ? (
                <PageContainer>
                    <PageTile>
                        <h4>General Information</h4>
                        <UserInfo></UserInfo>
                        <IndustryDetails></IndustryDetails>
                        <ContactInfo></ContactInfo>
                    </PageTile>
                    <PageTile>
                        <ContactsTabs></ContactsTabs>
                    </PageTile>
                    <PageTile>
                        <h4>Company Details</h4>
                        <CompanyDetails></CompanyDetails>
                    </PageTile>
                </PageContainer>
            ) : <Loading/>}
        </>

    );
});

export default AnnUnifiedProfilePage;

