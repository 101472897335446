import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../../hooks";

import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalTitle,
  CloseButton,
  Container,
  SearchContainer,
  SearchInputWrapper,
  SearchInput,
  SearchIconWrapper,
  SearchIcon,
  ShareButton,
  SectionTitle,
  SearchResults,
  SearchResultItem,
  SelectedUserItem,
  UserInfo,
  UserName,
  UserEmail,
  PermissionsDropdown,
  DropdownButton,
  SuccessContent,
  IconWrapper,
  SuccessTitle,
  SuccessMessage,
} from "./ShareAgentModal.styled";
import { useMediaQueries } from "../../../../../utils/mediaQueries";
import PermissionModal from "./PermissionModal";
import { WarningModal } from "../../../../../elements/modals/";

const ShareAgentModal = observer(
  ({ isOpen, onClose, agentId }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);
    const { isMobile } = useMediaQueries();
    const { listUserStore, authStore, editAgent } = useStore();
    const [selectedUserForModal, setSelectedUserForModal] = useState(null);
    const [showPermissionModal, setShowPermissionModal] = useState(false);
    const [buttonPosition, setButtonPosition] = useState({
      top: 0,
      left: 0,
      width: 0,
    });
    const [userResources, setUserResources] = useState([]);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [sharedSuccessfully, setSharedSuccessfully] = useState(false);
    const [policy, setPolicy] = useState("");

    useEffect(() => {
      if (editAgent.usersResources) {
        setUserResources(editAgent.usersResources);
        setSharedSuccessfully(editAgent.sharedSuccessfully);
        setPolicy(editAgent.chosenPolicy);
      }
    }, [editAgent, editAgent.chosenPolicy]);

    const handleSearchChange = (e) => {
      const value = e.target.value;
      setSearchTerm(value);

      if (value.trim()) {
        const mockResults =
          listUserStore.users.length &&
          listUserStore.users.filter((user) =>
            `${user.first_name} ${user.last_name}`
              .toLowerCase()
              .includes(value.toLowerCase()),
          );
        const results = mockResults.filter(item => item.id !== authStore.userData.id);
        setSearchResults(results);
      } else {
        setSearchResults([]);
      }
    };

    const handleSelectUser = (user) => {
      if (!selectedUsers.find((u) => u.user === user.id)) {
        setSelectedUsers([
          ...selectedUsers,
          {
            ...user,
            user: user.id,
          },
        ]);
      }
      setSearchTerm("");
      setSearchResults([]);
    };
    const handleShare = () => {
      if (editAgent.usersResources.length) {
        editAgent.updateUserResources(
          authStore.selectedAccount.id,
          agentId,
          editAgent.usersResources,
        );
      } else {
        setShowWarningModal(true);
      }

      if(sharedSuccessfully){
        setShowSuccess(true);
      }
    };

    const handleCloseWarningModal = () => {
      setShowWarningModal(false);
    };

    const handleOpenPermissionModal = (user, e) => {
      if (e && e.currentTarget) {
        const button = e.currentTarget;
        const rect = button.getBoundingClientRect();
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const scrollLeft =
          window.scrollX || document.documentElement.scrollLeft;
        setButtonPosition({
          top: rect.bottom + scrollTop,
          left: rect.left + scrollLeft - rect.width * 2.6,
          width: rect.width,
        });
      }
      setSelectedUserForModal(user);
      setShowPermissionModal(true);
    };

    const handleClosePermissionModal = () => {
      setShowPermissionModal(false);
      setSelectedUserForModal(null);
    };

    const handleFinalClose = () => {
      setShowSuccess(false);
      setSelectedUsers([]);
      onClose();
    };

    const handleRemoveUser = (user) => {
      setSelectedUsers((prevUsers) =>
        prevUsers.filter((u) => u.user !== user.user),
      );
    };

    if (!isOpen) return null;

    return (
      <ModalOverlay
        onClick={(e) => {
          if (!showWarningModal) {
            showSuccess ? handleFinalClose() : onClose();
          }
        }}
      >
        <ModalContent isMobile={isMobile} onClick={(e) => e.stopPropagation()}>
          {!showSuccess ? (
            <>
              <ModalHeader>
                <ModalTitle>Share agent</ModalTitle>
                <CloseButton onClick={onClose}></CloseButton>
              </ModalHeader>
              <Container>
                <SearchContainer>
                  <SearchInputWrapper>
                    <SearchInput
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      autoFocus
                    />
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    {searchResults.length > 0 && (
                      <SearchResults>
                        {searchResults.map((user) => (
                          <SearchResultItem
                            key={user.user}
                            onClick={() => handleSelectUser(user)}
                          >
                            {`${user.first_name} ${user.last_name}`}
                          </SearchResultItem>
                        ))}
                      </SearchResults>
                    )}
                  </SearchInputWrapper>

                  <ShareButton
                    onClick={handleShare}
                    disabled={selectedUsers.length === 0}
                  >
                    Share
                  </ShareButton>
                </SearchContainer>

                <SectionTitle>Share with...</SectionTitle>
                {selectedUsers.map((user, index) => (
                  <SelectedUserItem key={index}>
                    <UserInfo>
                      <UserName>{`${user.first_name} ${user.last_name}`}</UserName>
                      <UserEmail>{user.email}</UserEmail>
                    </UserInfo>
                    <PermissionsDropdown>
                      <DropdownButton
                        onClick={(e) => handleOpenPermissionModal(user, e)}
                      >
                        {policy}
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M5 7.5L10 12.5L15 7.5"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </DropdownButton>
                    </PermissionsDropdown>
                  </SelectedUserItem>
                ))}
              </Container>
            </>
          ) : (
            <SuccessContent>
              <IconWrapper>
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="48" height="48" rx="24" fill="#EBF5FF" />
                  <path
                    d="M32 24C32 28.4183 28.4183 32 24 32C19.5817 32 16 28.4183 16 24C16 19.5817 19.5817 16 24 16C28.4183 16 32 19.5817 32 24Z"
                    fill="#2563EB"
                  />
                  <path
                    d="M28 22L23 27L20 24"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconWrapper>
              <SuccessTitle>Agent Shared Successfully</SuccessTitle>
              <SuccessMessage>
                Your agent is now active and ready to assist your team.
              </SuccessMessage>
            </SuccessContent>
          )}
        </ModalContent>

        {showPermissionModal && selectedUserForModal && (
          <PermissionModal
            user={selectedUserForModal}
            onClose={handleClosePermissionModal}
            position={buttonPosition}
            onRemove={handleRemoveUser}
          />
        )}

        <WarningModal
          isOpen={showWarningModal}
          onClose={handleCloseWarningModal}
          subtitle="Please assign permissions to the selected users before sharing the agent."
          height="300px"
          width="400px"
        />
      </ModalOverlay>
    );
  },
);

export default ShareAgentModal;
