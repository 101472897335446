import React from 'react';
import { DropdownContainer, DropdownSelect } from './Pagination.styled';



const Dropdown = ({ options, selectedOption, onSelectOption }) => {
	return (
		<DropdownContainer>
			<DropdownSelect value={selectedOption} onChange={(e) => onSelectOption(Number(e.target.value))}>
				{options.map((option, index) => (
					<option key={index} value={option}>
						{option}
					</option>
				))}
			</DropdownSelect>
		</DropdownContainer>
	);
};

export default Dropdown;