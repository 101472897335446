import styled from 'styled-components';

const TagInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 9px 16px;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;

  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.clearGray};
  background: ${(props) => props.theme.colors.primaryWhite};
`;

const TagInput = styled.input`
  border: none;
  outline: none;
  flex: 0.1;
  background: ${(props) => props.theme.colors.primaryWhite};
  height: 22px;
  ${(props) => props.theme.fonts.variant.regular()};
  font-style: normal;
  font-size: 0.875rem;
  line-height: normal;
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 100px;
  background: ${(props) => props.theme.colors.clearGray};
  ${(props) => props.theme.fonts.variant.regular()};
  font-style: normal;
  line-height: normal;
  font-size: 0.625rem;
  gap: 4px;
  height: 22px;
`;

export {
  TagInputWrapper,
  Tag,
  TagInput
};
