import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from "../../../../../hooks";
import GenericListComponent from "../GenericListComponent/GenericListComponent";
import { ReactComponent as CircleAddIcon } from '../../../../../elements/icon/assets/circle_add_icon.svg';
import { FeatureLibraryContainer } from './FeatureLibraryTab.styled';
import PaginationComponent from "../../../../../elements/PaginationComponent/PaginationComponent";

const FeatureLibraryTab = observer(({ searchTerm }) => {
    const { featureStore } = useStore();
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);

    useEffect(() => {
        featureStore.fetchFeatures('userId', 'customerId');
    }, [featureStore]);

    const handleFeatureChange = (newList) => {
        featureStore.setFeatures(newList);
    };

    const filteredFeatures = featureStore.features.filter((feature) =>
        feature.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalEntries = filteredFeatures.length;
    const startEntry = (currentPage - 1) * entriesPerPage;
    const endEntry = startEntry + entriesPerPage;
    const paginatedFeatures = filteredFeatures.slice(startEntry, endEntry);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleEntriesPerPageChange = (newEntriesPerPage) => {
        setEntriesPerPage(newEntriesPerPage);
        setCurrentPage(1);
    };

    return (
        <FeatureLibraryContainer>
            <GenericListComponent
                listItems={paginatedFeatures}
                setListItems={handleFeatureChange}
                newItem={(value) => featureStore.addFeature(value)}
                removeItem={(index) => featureStore.removeFeature(index)}
                placeholder={'Type your feature here'}
                width="100%"
                showAddButton={false}
                ActionIcon={CircleAddIcon}
                fontSize="12px"
                fontWeight="300"
                textareaBorder="none"
            />
            <PaginationComponent
                totalEntries={totalEntries}
                entriesPerPageOptions={[5, 10, 12, 20, 50]}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onEntriesPerPageChange={handleEntriesPerPageChange}
            />
        </FeatureLibraryContainer>
    );
});

export default FeatureLibraryTab;
