    import { useState, useEffect } from "react";
    import { observer } from "mobx-react";
    import { format } from 'date-fns';
    import { useStore } from "../../../../hooks";
    import {
        Avatar,
        ContactInfo,
        Header,
        HeaderContactInfo,
        Name,
        Title,
        UserContainer,
        AlingIcon
    } from "./UserInfo.styled";
    import defaultImage from "../../../../elements/assets/Ellipse 6.png";
    import { IconButton } from "../../../../elements";
    import { Tooltip } from "@mui/material";

    const UserInfo = observer(() => {
        const { annUnifiedCustomerProfileStore } = useStore();
        const [openEditModal, setOpenEditModal] = useState(false);
        const [ucp, setUCP] = useState({});

        useEffect(() => {
            if (annUnifiedCustomerProfileStore.ucp) {
                setUCP(annUnifiedCustomerProfileStore.ucp);
            }
        }, [annUnifiedCustomerProfileStore.ucp]);

        const formatEmail = (email) => {
            if (email && email.length > 20) {
                return `${email.substring(0, 20)}...`;
            }
            return email;
        };

        return (
            <UserContainer>
                <Header>
                    <Avatar src={defaultImage} />
                    <HeaderContactInfo>
                        <Name>Name&nbsp;</Name>
                        <Title>{ucp.name ? ucp.name : 'No Data Available'}</Title>
                    </HeaderContactInfo>
                    <AlingIcon>
                        <IconButton
                            icon={'editPencil'}
                            width={'26px'}
                            height={'26px'}
                            onClick={() => setOpenEditModal(true)}
                        />
                    </AlingIcon>
                </Header>
                <ContactInfo>
                    <Tooltip title={ucp.email}>
                        <Name>Email&nbsp;</Name>
                        <Title>{ucp.email ? formatEmail(ucp.email) : 'No Data Available'}</Title>
                    </Tooltip>
                    <Tooltip>
                        <Name>Phone Number&nbsp;</Name>
                        <Title>{ucp.phone_number ? ucp.phone_number: 'No Data Available' }</Title>
                    </Tooltip>
                    <Tooltip title={ucp.create_date}>
                        <Name>Create Date&nbsp;</Name>
                        <Title>{ucp.create_date ? format(new Date(ucp.create_date), 'MM-dd-yyyy') : 'No Data Available'}</Title>
                    </Tooltip>
                    <Tooltip title={ucp.lastActivityDate}>
                        <Name>Last Activity Date&nbsp;</Name>
                        <Title>{ucp.last_activities_date ? format(new Date(ucp.last_activities_date), 'MM-dd-yyyy') : 'No Data Available'}</Title>
                    </Tooltip>
                </ContactInfo>
            </UserContainer>
        );
    });

    export default UserInfo;
