import React from "react";
import { observer } from "mobx-react";
import { 
    PermissionsWrapper, 
    PermissionNavbarWrapper,
    PermissionsCardsWrapper,
    ButtonSelectWrapper
} from "./GroupPermission.styled";
import PermissionCard from "../../../UsersPage/NewUserPage/components/PermissionCard/PermissionCard";
import PermissionNavbar from "../../../UsersPage/NewUserPage/components/PermissionNavbar/PermissionNavbar";
import useStore from "../../../../../hooks/useStore";


import CustomButton from "../../../../../elements/v2/Buttons/CustomButton/CustomButton";
import ActionSelect from "../../../UsersPage/UsersOerview/components/ActionSelect/ActionSelect";

const GroupPermission = observer(() => {
    const { permissionsSetupStore } = useStore();

    return (
        <>
        <ButtonSelectWrapper>
            <CustomButton text="Active" showStatusDot={true} />
            <ActionSelect placeholder="Admin" />
        </ButtonSelectWrapper>
        <PermissionsWrapper>
            <PermissionNavbarWrapper>
                <PermissionNavbar data={permissionsSetupStore.initialPermissionsCatalog}/>
            </PermissionNavbarWrapper>
            <PermissionsCardsWrapper>
                <div>
                    <div>
                        <PermissionCard data={permissionsSetupStore.permissionsCatalog} reviewMode={false}/>
                    </div>
                </div>
            </PermissionsCardsWrapper>
        </PermissionsWrapper>
        </>
    );
});

export default GroupPermission;
