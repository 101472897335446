import styled from 'styled-components';
import { Menu, MenuItem } from "@mui/material";

const StyledMenu = styled(Menu)`
    & .MuiPaper-root {
        box-shadow: none;
        background-color: ${props => props.theme.colors.primaryWhite};
        border: 1px solid ${props => props.theme.colors.borderGray};
        border-radius: 8px;
    }

    & .MuiList-root {
        padding: 0;
    }

    & .MuiButtonBase-root {
        border-bottom: 1px solid ${props => props.theme.colors.borderGray};
    }

    & .MuiButtonBase-root:last-child {
        border-bottom: none;
    }
`;

const StyledMenuItem = styled(MenuItem)`
    padding: 4px !important;
`;

const StyledTitle = styled.p`
    font-size: 0.75em;
    color: ${props => props.theme.colors.digital};
    padding-left: 15px;
    padding-right: 25px;
    margin: 0;
`;

export {
  StyledMenu,
  StyledMenuItem,
  StyledTitle
};