import { DropDownContainer, DropDownHeader, DropDownList, ListItem, ListInput, DropDownBody } from "./SimpleSelect.styled";
import { useState, useEffect, useMemo } from "react"
import ArrowDownIcon from "../../icon/assets/arrow_down_icon.svg"

const SimpleSelect = ({ placeholder = 'Choose', selectedDefault = null, options=[{name: 'Option 1', value: 'option1'}], handleSelect, textTransform, hideOnBlur=true, searchBar=false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(selectedDefault);
    const [searchTerm, setSearchTerm] = useState('');
  
    const toggling = () => setIsOpen(!isOpen);
  
    const onOptionClicked = option => () => {
        setSelectedOption(option.name);
        setIsOpen(false);
        handleSelect(option.name, option.value);
    };

    useEffect(() => {
        selectedDefault && setSelectedOption(options.filter(option => option.value === selectedDefault)[0]?.name);
    }, [selectedDefault])

    const handleBlur = () => { 
        hideOnBlur && setIsOpen(false) 
    }; 

    const listSorted = useMemo(() => options.filter(option =>  (new RegExp(searchTerm, "i")).test(option.name)), [searchTerm, options.length])

  return (
      <DropDownContainer onBlur={handleBlur} tabIndex={0}>
        <DropDownHeader $textTransform={textTransform} onClick={toggling} arrow={ArrowDownIcon}>{selectedOption || placeholder}</DropDownHeader>
        {isOpen && options.length > 0 && (
            <DropDownBody $searchBar={searchBar}>
                {searchBar && <ListInput value={searchTerm} onChange={e => setSearchTerm(e.target.value)} placeholder="Search..."/>}
                <DropDownList>
                    {listSorted.map( option => 
                        <ListItem onClick={onOptionClicked(option)} key={Math.random()}><span>{option.name}</span></ListItem>
                    )}
                </DropDownList>
            </DropDownBody>
        )}
      </DropDownContainer>
  );
}

export default SimpleSelect;
