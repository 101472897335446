import styled from 'styled-components';

const ProfileContent = styled.div`
    margin-left: 50px;
    height: 100vh;
    margin-top: 8vh;
`;

const ProfileWrapper = styled.div`
    display: flex;
    margin-top: 20px
`;

const ProfileDetails = styled.div`
    flex: 1;
    margin-right: 20px;
`;

const ProfileActions = styled.div`
    flex: 1;
`;

const ProfileLabel = styled.label`
    color: #717171;
`;

export {
    ProfileContent,
    ProfileWrapper,
    ProfileDetails,
    ProfileActions,
    ProfileLabel
};
