import React, { useEffect, useMemo } from "react";
import { observer } from 'mobx-react';
import { useStore } from "../../../../../hooks";
import { RoundedButton } from "../../../../../elements/button";
import {
    ThreeColumnGrid,InfoBoxWrapper, Title,
    DividerLine, TruncatedDescription, CustomImage, CustomComponent1, LoadMoreContainer,
    AgentsContainer
} from "./AgentTabs.styled";
import LogoAnn from "../../../../../elements/icon/assets/logo_ann_customer.svg";
import { getTeams as teams, getDummyContentAgents } from "./../../consts";
import Spinner from "../../../../../components/common/Spinner";

const PublishedTab = observer(({handleOpenModal}) => {
    const { agentStore } = useStore();
    const { viewAgentStore } = agentStore;
    
    const agentsFiltered = useMemo(() => {
        let filteredAgents = agentStore.agents
            .filter(assistant => assistant.domain !== 'concierge' && assistant.provider === 'openai');

        if (viewAgentStore.searchValue) {
            filteredAgents = filteredAgents.filter(assistant => 
                assistant.name.toLowerCase().includes(viewAgentStore.searchValue.toLowerCase())
            );
        }

        return filteredAgents;
    }, [agentStore.agents, viewAgentStore.searchValue]);

    const publishedTeams = teams.filter(assistant => assistant.visibility === 'published');

    return (
        <>
            <DividerLine />
            {/* {publishedTeams && publishedTeams.length > 0 && (
                <ThreeColumnGrid>
                    {publishedTeams.map((assistant, index) => (
                        <CustomComponent1 key={index} onClick={() => handleOpenModal(assistant)}>
                            <CustomImage src={LogoAnn} alt="Ann Logo" />
                            <InfoBoxWrapper>
                                <Title>{assistant.name}</Title>
                                <TruncatedDescription>{assistant.description1}</TruncatedDescription>
                            </InfoBoxWrapper>
                        </CustomComponent1>
                    ))}
                </ThreeColumnGrid>
            )}

            <DividerLine /> */}

            { !agentStore.isDuplicating ? (<>
                <AgentsContainer>
                    <ThreeColumnGrid>
                        {agentsFiltered
                            .slice(0, viewAgentStore.currentAgentPaginator)
                            .map((assistant, index) => {
                                return (
                                    <CustomComponent1 key={index} onClick={() => handleOpenModal(assistant)}>
                                        <CustomImage src={LogoAnn} alt="Ann Logo" />
                                        <InfoBoxWrapper>
                                            <Title>{assistant.name}</Title>
                                            <TruncatedDescription>{assistant.job_title}</TruncatedDescription>
                                        </InfoBoxWrapper>
                                    </CustomComponent1>
                                )
                            })}
                    </ThreeColumnGrid>
                </AgentsContainer>

                {agentsFiltered.length > viewAgentStore.currentAgentPaginator && (
                    <LoadMoreContainer>
                        <RoundedButton width={'200px'} onClick={viewAgentStore.onHandleLoadMore}>
                            Load More
                        </RoundedButton>
                    </LoadMoreContainer>
                )}
            </>) : <Spinner/>}
        </>
    );
});

export default PublishedTab;