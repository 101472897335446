import React from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks";
import { FileInfo, FileName, FileSize, FormCampaignWrapper, FormTitle, StyledUploadFile, FileIcon } from '../NewCampaignPage.Styled';
import 'react-calendar/dist/Calendar.css';
import { IconButton } from "@mui/material";
import Trash from "../../../elements/icon/assets/trash.svg"
import FileIconImage from "../../../elements/icon/assets/file.svg"

const CampaignUploadFilesForm = observer(() => {
    const { newCampaign } = useStore();

    const handleFileDelete = (event) => {
        event.stopPropagation();
        newCampaign.setSelectedFiles([]);
        newCampaign.setColumnNames([]);
        newCampaign.setColumnContents([]);
        newCampaign.buttonUpload = false;
        newCampaign.setShowCustomSelectFirstName(false);
        newCampaign.setShowCustomSelectLastName(false);
        newCampaign.setShowCustomSelectEmail(false);
        newCampaign.setShowCustomSelectPhone(false);
        newCampaign.setShowCustomSelectZipCode(false);
        newCampaign.setAreAllFieldsDone(false);
    };

    return (
        <FormCampaignWrapper>
            <FormTitle>Sure thing! <br />We're almost there, just one more step and we're ready <br />to start this new Conversation!</FormTitle>
            <br />
            <StyledUploadFile>
                {newCampaign.selectedFiles.map((file, index) => (
                    <FileInfo key={index}>
                        <FileIcon src={FileIconImage} />
                        <FileName>File"{file.name}" uploaded</FileName><br />
                        <FileSize>{(file.size / 1024).toFixed(2)} KB</FileSize>
                        <IconButton onClick={handleFileDelete}>
                            <FileIcon src={Trash} />
                        </IconButton>
                    </FileInfo>
                ))}
            </StyledUploadFile>
        </FormCampaignWrapper>
    );
});

export default CampaignUploadFilesForm;
