import styled, { css } from 'styled-components';


const ModalWrapper = styled.div`
    position: relative;
    width: 500px;
    height: 370px;
    background-color: white;
    border-radius: 10px;
`;

const ModalTitle = styled.div`
    font-size: 20px;
    margin-bottom: 0;
    opacity: 0.6;
    font-weight: 500;
`;

const StyledModalSubtitle = styled.p`
    font-weight: bold;
    font-size: 26px;
`;

const ModalSubtitle = ({ children }) => <StyledModalSubtitle>{children}</StyledModalSubtitle>;

const ModalButtonsContainer = styled.div`
    display: flex;
    gap: 30px;
`;

export {
    ModalWrapper,
    ModalTitle,
    ModalSubtitle,
    ModalButtonsContainer
};
