import { useState, useEffect } from "react";
import {CheckboxWrapper, CheckboxLabel, CustomCheckboxInput} from "./Checkbox.styled";

const Checkbox = ({ label, onSelectValue, options, textTransform, width, disabled}) => {
    const [isChecked, setIsChecked] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const onCheck = () => {
        if(!isDisabled){
            setIsChecked((prev) => !prev)
        }
    } 

    useEffect(() => {
        onSelectValue({[label]: isChecked})

    }, [isChecked])

    useEffect(() => {
        setIsChecked(options[label])
    }, [options[label]])

    useEffect(() => {
        setIsDisabled(disabled)
    }, [disabled])

    return (
            <CheckboxWrapper onClick={onCheck} width={width}>
                <CustomCheckboxInput 
                    checked={isChecked}
                    onChange={onCheck}
                    onClick={onCheck}
                    disabled={isDisabled}
                />
                <CheckboxLabel textTransform={textTransform}>{label}</CheckboxLabel>
            </CheckboxWrapper>    
    );
}

export default Checkbox;
