import React, {useState} from "react";
import {observer} from "mobx-react";
import {useStore} from "../../../hooks";
import { FormTitle, FormSubtitle, FormAgentWrapper } from "../NewAgentPage.styled";
import {DragAndDropAssistant} from "../../../elements";
import {assistantListFileRoute, extensions} from "./consts";
import {DragAndDropAssistantWrapper} from "../NewAgentPage.styled"

const AgentFilesForm = observer(() => {
    const { newAgent } = useStore();
    return (
        <FormAgentWrapper>
            <FormTitle>You got it!</FormTitle>
            <FormSubtitle>Please upload the file with the knowledge base.</FormSubtitle>
            <DragAndDropAssistantWrapper>
              <DragAndDropAssistant
                  fileRoute={assistantListFileRoute}
                  customer={newAgent.account}
                  extensions={extensions}
                  onFilesChange={newAgent.onChangeFiles}
              />
            </DragAndDropAssistantWrapper>
        </FormAgentWrapper>
    );
});

export default AgentFilesForm;
