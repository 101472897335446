import React, { useState, useEffect } from "react";
import { useStore } from "../../hooks";
import { observer } from "mobx-react";
import { Icon, RoundedButton } from "../../elements";
import { useMediaQueries } from "../../utils/mediaQueries";
import {
  CustomGrid, CustomSearchInput,
  InputField, SearchIcon,
  StyledIconButton,
  CustomGenericListStyle, VerticalDividerline,
  MainContainer, MainTitleContainer,
  RolesTableContainer, MobileHiddenRoundedButton
} from "./ContactList.styled";
import ContactForm from "./contactInformation";

const ContactList = observer(() => {
  const { userStore, changeTitle, authStore } = useStore();
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
  const { isMobile } = useMediaQueries();

  userStore.setPage('list');

  changeTitle('Contacts');

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    if (isMobile){
      authStore.setNavbarActionElement(
        <RoundedButton
          width={'100px'}
          height={'30px'}
          fontSize={'12px'}
        >
          Add New
        </RoundedButton>
      );
    }
  });

  return (
    <div>
      {<CustomGrid>
         {/*<CustomSearchInput width='30px'>
          <InputField
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search"
          />
          <SearchIcon>
            <Icon name={"magnifier"} height={'20px'} width={'20px'}></Icon>
          </SearchIcon>
        </CustomSearchInput>

        <StyledIconButton aria-controls="custom-dropdown" aria-haspopup="true">
          <Icon name={"tuneIcon"} height={'15px'} width={'15px'} />
        </StyledIconButton>

         <VerticalDividerline />
          
        <RoundedButton
          width={'100px'}
          height={'30px'}
          fontSize={'12px'}
          onClick={() => console.log('Create new Contacts')} 
        >
          Import Contacts
        </RoundedButton>
        
        <RoundedButton
          width={'100px'}
          height={'30px'}
          fontSize={'12px'}
          onClick={() => console.log('Create new Contacts')} 
        >
          New Contact
        </RoundedButton>
        <RoundedButton
          width={'100px'}
          height={'30px'}
          fontSize={'12px'}
          onClick={() => console.log('Create new Contacts')} 
        >
          Segments
        </RoundedButton>
        */}
        <MobileHiddenRoundedButton>
        <RoundedButton
          width={'100px'}
          height={'30px'}
          fontSize={'12px'}
        >
          Add New
        </RoundedButton>
        </MobileHiddenRoundedButton>
        
        <CustomGenericListStyle /> 
        
      </CustomGrid> }
        <ContactForm searchValue={debouncedSearchValue} />
      
    </div>
  );
});

export default ContactList;
