import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import GenericNode from './GenericNode';


export default ({ data }) => {
  return (
    <>
      <GenericNode data={data} onEdit={undefined} onDelete={undefined } />
      <Handle
        type="target"
        position={Position.Top}
        id={`manager-${data.id}-target`}
        isConnectable={false}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id={`manager-${data.id}-source`}
        isConnectable={false}
      />
    </>
  );
};
