import React from 'react';

const ForbiddenPage = () => (
  <div>
    <h1>Access Denied</h1>
    <p>You do not have permission to access this page.</p>
  </div>
);

export default ForbiddenPage;
