import React, { useEffect, useCallback } from "react";
import { observer } from 'mobx-react';
import { useStore } from "../../hooks";
import { InputText, RoundedButton } from "../../elements";
import { ProfileWrapper, ProfileDetails, ProfileActions, ProfileContent, ProfileLabel } from "./ProfilePage.styled";
import ProfilePicture from "../../elements/profilePicture/ProfilePicture";
import ContactNumber from "../../elements/contactNumber/ContactNumber";
import ChangePassword from "./components/ChangePassword";
import SuccesfulIcon from '../../elements/assets/successfulIcon.svg';
import SuccessfullModal from '../../elements/modals/SuccesfulModal';

const ProfilePage = observer(() => {
    const { authStore, profileStore } = useStore();
    const { changeTitle } = useStore();
    changeTitle('Profile');

    const title = "Your profile information has been updated successfully.";
    const subtitle = "Great job!!";

    useEffect(() => {
        profileStore.fetchUserData(authStore.userId, authStore.customerId);
    }, [profileStore, authStore.userId, authStore.customerId]);

    const onHandleChangeFirstName = useCallback((e) => {
        profileStore.onChangeFirstName(e.target.value);
    }, [profileStore]);

    const onChangeLastName = useCallback((e) => {
        profileStore.onChangeLastName(e.target.value);
    }, [profileStore]);

    const onChangeDesignation = useCallback((e) => {
        profileStore.onChangeDesignation(e.target.value);
    }, [profileStore]);

    const onChangePhone = useCallback((e) => {
        profileStore.onChangePhone(e.target.value);
    }, [profileStore]);

    const handleProfilePictureChange = useCallback((e) => {

    }, []);

    const handleCloseSuccessModal = () => {
        profileStore.setIsSuccessfulModalOpen(false);
    };

    const handleUpdateProfile = async () => {
        try {
            profileStore.setIsUpdatingProfile(true);

            await profileStore.updateUser();
        } catch (error) {
            console.error("Error updating user data:", error);
        }
        finally {
            profileStore.setIsUpdatingProfile(false);
        }
    };

    return (
      <>
          <ProfileContent>
              <label>Update your personal details</label>
              <ProfileWrapper>
                  <ProfileDetails>
                      <ProfileLabel>First Name:</ProfileLabel>
                      <InputText
                        placeholder="First Name"
                        value={profileStore.first_name}
                        onChange={onHandleChangeFirstName}
                      />

                      <ProfileLabel>Last Name:</ProfileLabel>
                      <InputText
                        placeholder="Last Name"
                        value={profileStore.last_name}
                        onChange={onChangeLastName}
                      />

                      <ProfileLabel>Email Address:</ProfileLabel>
                      <InputText
                        placeholder="Email"
                        value={profileStore.email}
                        backgroundColor="#ECECEC"
                        readOnly
                      />

                      <ProfileLabel>Designation:</ProfileLabel>
                      <InputText
                        placeholder="Designation"
                        value={profileStore.designation}
                        onChange={onChangeDesignation}
                      />

                      <ProfileLabel>Contact Number (optional):</ProfileLabel>
                      <ContactNumber
                        placeholder="Phone"
                        value={profileStore.phone}
                        onChange={onChangePhone}
                      />

                      <RoundedButton
                        type={"button"}
                        kind={"primary"}
                        onClick={handleUpdateProfile}
                        width="180px"
                        disabled={profileStore.isUpdatingProfile}
                      >
                          {profileStore.isUpdatingProfile ? 'Loading...' : 'Save Changes'}
                      </RoundedButton>

                  </ProfileDetails>

                  <ProfileActions>
                      <ProfilePicture onChange={handleProfilePictureChange} />
                  </ProfileActions>

              </ProfileWrapper>
              <ChangePassword isOpen={profileStore.isChangePasswordModalOpen} onClose={() => profileStore.setIsChangePasswordModalOpen(false)} />
          </ProfileContent>
          <SuccessfullModal
            isOpen={profileStore.isSuccessfullModalOpen}
            onClose={handleCloseSuccessModal}
            imageSrc={SuccesfulIcon}
            title={title}
            subtitle={subtitle}
          />
      </>
    );
});

export default ProfilePage;
