import React from 'react';
import ReactDOM from 'react-dom';
import { ModalOverlay, ModalContent, CloseButton, Row, Label, TextField, TextArea, Header,
	Actions, Button, HeaderIcon,HeaderType } from './ModalAddAction.styled';
import Icon from "../../../../../../../elements/icon/Icon";
import { observer } from 'mobx-react';
import { useStore } from "../../../../../../../hooks";
import { IconMapping } from '../../consts';

const ModalAddAction = observer(({ isOpen, onClose}) => {
	const { viewConversationStore } = useStore();
	const { selectedAction } = viewConversationStore.conversationsActionsStore;

	const onSave = () => {
		viewConversationStore.conversationsActionsStore.saveSelectedAction()
		viewConversationStore.updateChangesDetected(true)
		onClose()
	}

	if (!isOpen) return null;

	return ReactDOM.createPortal(
		<ModalOverlay>
			<ModalContent>
				<CloseButton onClick={onClose}>
					<Icon name={"closeIconGray"} width={"15px"} height={"15px"} />
				</CloseButton>
				<Row>
					<Header>
						<HeaderIcon>
							<Icon name={IconMapping[selectedAction.action]} width={"35px"} height={"35px"} />
						</HeaderIcon>
					</Header>
				</Row>
				<Row>
					<Label>Name</Label>
					<TextField type="text" placeholder={"Enter Name"} value={selectedAction.name} onChange={e => selectedAction.name = e.target.value}/>
				</Row>
				<Row>
					<Label>Description</Label>
					<TextArea type="text" placeholder={"Enter Description"} value={selectedAction.description} onChange={e => selectedAction.description = e.target.value}/>
				</Row>
				<Row>
					<Actions>
						<Button onClick={onClose}>Cancel</Button>
						<Button onClick={onSave}>Save</Button>
					</Actions>
				</Row>
			</ModalContent>
		</ModalOverlay>,
		document.body
	);
});

export default ModalAddAction;