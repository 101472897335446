import styled from "styled-components";

export const StyledButton = styled.button`
  padding: 6px 12px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.aliceBlue};
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;
  font-size: 12px;
`;

export const CompletedText = styled.p`
  color: ${(props) => props.theme.colors.veroneseGreen};
  font-weight: bold;
  font-size: 12px;
  margin: 0;
`;

const getHeightByDevice = (isMobile, isTablet) => {
  if (isMobile) return "80vh";
  if (isTablet) return "90vh";
  return "85vh";
};

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${(props) => getHeightByDevice(props.isMobile, props.isTablet)};
`;

export const StyledText = styled.span`
  font-weight: ${(props) => (props.isCompleted ? "normal" : "bold")}};
`;

export const TableBodyRow = styled.div`
  background-color: ${(props) => (!props.isResolved ? '#F2F7FF' : 'transparent')};
`;

export const TablePaper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

export const TableContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

// Add any other styles you need to move here...
