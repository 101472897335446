import React, { useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useStore } from "../../../hooks";
import { useMediaQueries } from "../../../utils/mediaQueries";
import {
  StyledContainer,
  TableWrapper,
  headCellStyle,
  bodyCellStyle,
  toolbarStyle,
  tableContainerStyle,
  tablePaperStyle
} from "../../../elements/v2/Tables/Table.styled";
import { SuccessfulModal } from "../../../elements";
import { IconButton, Tooltip, Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

const AppointmentsPage = observer(() => {
  const { changeTitle, appointmentsStore, authStore } = useStore();
  const navigate = useNavigate();

  changeTitle('Appointments');

  const { isMobile, isTablet, isDesktop } = useMediaQueries();

  useEffect(() => {
    appointmentsStore.fetchAppointments();
  }, [appointmentsStore]);

  const columns = useMemo(() => [
    { accessorKey: "serviceType", header: "Service type" },
    { accessorKey: "appointmentDay", header: "Appointment day" },
    { accessorKey: "appointmentTime", header: "Appointment time" },
    { accessorKey: "description", header: "Description" },
    { accessorKey: "customer", header: "Customer" },
  ], []);

  const onHandleCustomerProfile = useCallback((appointmentId) => {
    const urlForUnified = authStore.selectedAccount.metadata.domain === 'ANN' ? "/ann-unified-profile" : "/unified-profile";
    navigate({
      pathname: urlForUnified,
      search: createSearchParams({ id: appointmentId }).toString(),
    });
  }, [authStore.selectedAccount, navigate]);

  const table = useMaterialReactTable({
    columns,
    data: appointmentsStore.filteredAppointments,
    manualSorting: true,
    manualGlobalFilter: true,
    rowCount: appointmentsStore.totalRowCount || 0,
    initialState: { sorting: [{ id: 'appointmentDay', desc: true }] },
    muiTableContainerProps: { sx: tableContainerStyle },
    muiTablePaperProps: { elevation: 0, sx: tablePaperStyle },
    muiTableHeadCellProps: { sx: headCellStyle },
    muiTableBodyCellProps: { sx: bodyCellStyle },

    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const appointmentId = row.original.id;
        onHandleCustomerProfile(appointmentId);
      },
      style: { cursor: 'pointer' }
    }),

    renderRowActions: ({ row }) => (
      <Box>
        <Tooltip title="View">
          <IconButton onClick={() => onHandleCustomerProfile(row.original.id)}>
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),

    muiTopToolbarProps: { sx: toolbarStyle },
    muiSearchTextFieldProps: {
      variant: 'outlined',
      placeholder: 'Search',
      InputProps: { style: { fontSize: '12px' } },
    },
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnResizing: false,
  });

  return (
    <StyledContainer isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}>
      <TableWrapper>
        <MaterialReactTable table={table} />
      </TableWrapper>
      {appointmentsStore.isError && (
        <SuccessfulModal
          isOpen={appointmentsStore.isError}
          onClose={appointmentsStore.clearError}
          title="Oops!"
          subtitle="An error occurred."
          subtitle2="Please try again later."
        />
      )}
    </StyledContainer>
  );
});

export default AppointmentsPage