import React from "react";
import {
  UnifiedContainer,
  Breadcrumb,
  ChatContainer,
  WidgetContainer,
  ChatWrapper,
  WidgetFirstColumn,
  WidgetSecondColumn,
  WidgetChatWrapper,
  CustomGenericListStyle,
} from "../BaseUnifiedProfilePage.styled";
import BaseUnifiedProfilePage from "../BaseUnifiedProfilePage";
import  Assistance  from "../components/Assistance/Assistance";
import GeneralInfo from "../components/Cards/GeneralInfo";
import GarageCard from "../components/Cards/GarageCard";
import Appointments from "../components/Cards/Appointments";
import Notification from "../components/Cards/Notification";
import RepairOrders from "../components/Cards/RepairOrders";

const UnifiedProfilePage = () => {
  return (
    <BaseUnifiedProfilePage>
      <UnifiedContainer>
        <Breadcrumb>Contacts</Breadcrumb>
        <WidgetChatWrapper>
          <WidgetContainer>
            <WidgetFirstColumn>
              <GeneralInfo />
              <Appointments />
              <Notification />
            </WidgetFirstColumn>
            <WidgetSecondColumn>
              <GarageCard />
              <RepairOrders />
            </WidgetSecondColumn>
          </WidgetContainer>
          <ChatContainer>
            <ChatWrapper>
              <Assistance />
            </ChatWrapper>
          </ChatContainer>
        </WidgetChatWrapper>
        <CustomGenericListStyle />
      </UnifiedContainer>
    </BaseUnifiedProfilePage>
  );
};

export default UnifiedProfilePage;
