import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import mockData from '../Data/mockData.json';
import { TextField } from '@mui/material';

const ConversationsChart = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [jsonData, setJsonData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    // Simulating data loading from an external JSON
    setJsonData(mockData);
  }, []);

  useEffect(() => {
    if (jsonData) {
      // Filter data based on selected dates
      const filteredData = jsonData.data.conversationData.channels.filter(entry => {
        const timestamp = new Date(entry.timestamp);
        return (!startDate || timestamp >= startDate) && (!endDate || timestamp <= endDate);
      });

      // Sum of counts per channel
      const channelTotals = filteredData.reduce((totals, entry) => {
        if (totals[entry.channel]) {
          totals[entry.channel] += entry.count;
        } else {
          totals[entry.channel] = entry.count;
        }
        return totals;
      }, {});

      // Extracting data for the chart
      const labels = Object.keys(channelTotals);
      const dataValues = Object.values(channelTotals);
      const backgroundColors = ['yellow', 'darkblue', 'lightblue', 'red'];

      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const data = {
        labels: labels,
        datasets: [{
          data: dataValues,
          backgroundColor: backgroundColors,
          borderWidth: 1,
          borderRadius: 10,
        }],
      };

      const ctx = chartRef.current.getContext('2d');

      chartInstance.current = new Chart(ctx, {
        type: 'bar',
        data: data,
        options: {
          plugins: {
            legend: false,
          },
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
            },
          },
          indexAxis: 'y',
          barThickness: 10,
        },
      });
    }
  }, [jsonData, startDate, endDate]);

  return (
    <div style={{ marginLeft: '80px' }}>
      <TextField
        label="Start Date"
        type="date"
        InputLabelProps={{ shrink: true }}
        onChange={(e) => setStartDate(new Date(e.target.value))}
      />
      <TextField
        label="End Date"
        type="date"
        InputLabelProps={{ shrink: true }}
        onChange={(e) => setEndDate(new Date(e.target.value))}
      />

      <canvas ref={chartRef} width="400" height="200"></canvas>
    </div>
  );
};

export default ConversationsChart;
