const menuItems = (navigate) => [
	{
		icon: "home",
		text: "Home",
		link: "/home/",
		customItems: [],
		superAdminOnly: false,
		permissionCode: "HOME"
	},
	{
		icon: "insights",
		text: "Insights",
		link: "/metrics/*",
		customItems: [],
		superAdminOnly: false,
		permissionCode: "METRICS_VIEW"
	},
	{
		icon: "dataIcon",
		text: "Data",
		link: "/vehicles",
		customItems: [
			{ text: "Vehicles", onClick: () => navigate("/v2/vehicles") },
			{ text: "Repair Orders", onClick: () => navigate("/v2/repair-orders") },
			{ text: "Appointments", onClick: () => navigate("/v2/appointments") },
		],
		superAdminOnly: false,
		permissionCode: "CONTACTS_VIEW"
	},
	{
		icon: "contacts",
		text: "Contacts",
		link: "/contacts",
		customItems: [
			{ text: "Import", permissionCode: "CONTACTS_UPLOAD" },
			{ text: "Contacts", onClick: () => navigate("/contacts"), permissionCode: "CONTACTS_VIEW" },
		],
		superAdminOnly: false,
		permissionCode: "CONTACTS_VIEW"
	},
	{
		icon: "agents",
		text: "Agents",
		link: "/v2/assistants/",
		customItems: [
			{ text: "Agents", onClick: () => navigate("/v2/assistants"), permissionCode: "AGENTS_VIEW" },
		],
		superAdminOnly: false,
		permissionCode: "AGENTS_VIEW"
	},
	{
		icon: "conversation",
		text: "Conversations",
		link: "/v2/conversations/",
		customItems: [
			{ text: "Conversations", onClick: () => navigate("/v2/conversations"), permissionCode: "CONVERSATIONS_VIEW" },
			{ text: "Blast Campaign", onClick: () => navigate("/blast-campaigns/configure"), permissionCode: "BLAST_CAMPAIGN" },
		],
		superAdminOnly: true,
		permissionCode: "CONVERSATIONS_VIEW"
	},
	{
		icon: "settings",
		text: "Settings",
		customItems: [
			{ text: "Users", onClick: () => navigate("/v2/users"), permissionCode: "USERS_VIEW" },
			{ text: "Roles", onClick: () => navigate("/v2/roles"), permissionCode: "ROLES_VIEW" },
		],
		superAdminOnly: true,
		permissionCode: "SETTINGS"
	}
];

const settingsItems = (navigate, handleSignOut) => [
	 {
	icon: "support",
	text: "Support"
	},
  {
    icon: "widgetChat",
    text: "Chat",
    customItems: [],
  },
  {
    icon: "avatar",
    text: "User",
    customItems: [
      { icon: "logoutAvatar", text: "Log Out", onClick: handleSignOut },
    ],
  },
  
];

const accountItems = (handleSignOut, handleCustomAccountModal, showHelpButton) => [
  {
    icon: "",
    text: "Switch Accounts",
    onClick: handleCustomAccountModal,
  },
  {
    icon: "logoutAvatar",
    text: "Log Out",
    onClick: handleSignOut,
  },
   {
     icon: "Support",
     text: "Support",
     onClick: showHelpButton,
   },
];

export { menuItems, settingsItems, accountItems };