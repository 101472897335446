import styled from 'styled-components';

import Step1 from './assets/Step-01.png';
import Step2 from './assets/Step-02.png';
import Step3 from './assets/Step-03.png';
import Step4 from './assets/Step-04.png';
import Step5 from './assets/Step-05.png';
import Step6 from './assets/Step-06.png';

const ContainerWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoadingBox = styled.div`
  @keyframes loadingAnimation {
    0%   { background-image: url(${Step1}); }
    20%  { background-image: url(${Step2}); }
    40%  { background-image: url(${Step3}); }
    60%  { background-image: url(${Step4}); }
    80%  { background-image: url(${Step5}); }
    100%  { background-image: url(${Step6}); }
  }
  
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  
  animation: loadingAnimation 5s infinite linear;
  width: 177px;
  height: 139px;
`;

export {
  ContainerWrapper,
  LoadingBox
}