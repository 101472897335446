import styled from "styled-components";
import { Checkbox } from "@mui/material";

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

export const SmallCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    transform: scale(0.8);
    margin-right: 8px;
  }
`;

export const HeaderText = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

export const TableContainer = styled.div`
  max-width: 98%;
  margin: 0 auto;
`;
