import { styled } from "styled-components";

const InputWrapper = styled.input`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '46px'};
    padding: 0 20px;
    border-radius: 7px;
    border: 1px solid ${(props) => props.$error ? props.theme.colors.red : props.theme.colors.clearGray};
    font-size: ${(props) => props.theme.fonts.size.norb2mal};
    ${(props) => props.theme.fonts.variant.light};
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.backgroundColor || 'transparent'};
    box-sizing: border-box;
    
    ::placeholder {
        font-style: italic;
        color: ${(props) => props.theme.colors.clearGray};

    }

    &:disabled{
        background-color: ${(props) => props.theme.colors.white};
        border: 1px solid ${(props) => props.theme.colors.lightGrey};
        color: ${(props) => props.theme.colors.grey};
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${(props) => props.margin || '10px 0'};

    span {
        text-align: left;
        font-size: 12px;
        margin-top: 2px;
        color: ${(props) => props.theme.colors.red};
    }
`;

export {
    InputWrapper,
    InputContainer
}