import React, { useCallback } from "react";
import {
    ButtonWrapper
} from './RoundedButton.styled';

const RoundedButton = ({
    type = 'button',
    width,
    height,
    kind = 'primary',
    outline = false,
    children,
    margin,
    icon,
    disabled = false,
    fontSize,
    onClick,
    borderRadius,
    className,
    color,
    dataTestId
}) => {
    const handleClick = useCallback((e) => {
        onClick && onClick(e);
    }, [onClick]);

    return (
        <ButtonWrapper
            type={type}
            data-testid={dataTestId}
            disabled={disabled}
            height={height}
            width={width}
            kind={kind}
            $outline={outline}
            margin={margin}
            fontSize={fontSize}
            onClick={handleClick}
            $borderRadius={borderRadius}
            className={className}
            color={color}
        >
            {icon && icon.position === 'left' && icon.element}
            {children}
            {icon && icon.position === 'right' && icon.element}
        </ButtonWrapper>
    );
};

export default RoundedButton;
