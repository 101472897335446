import React, { useEffect, useCallback, useState } from 'react';
import { useStore } from "../../../hooks";
import { FormCampaignWrapper, FormTitle, FormSubtitle } from '../NewCampaignPage.Styled';
import { CustomSelect } from "../../../elements";
import CampaignDragAndDropForm from './CampaignDragAndDop';

const CampaignFileForm = () => {

    const { newCampaign } = useStore();
    const [fileListLoaded, setFileListLoaded] = useState(false);

    const handleFileSelect = useCallback((name,url) => {
        newCampaign.setUploadFile(true);
        newCampaign.onChangeFile(name,url);
        newCampaign.hideUploadFilesStep(true);
    }, [newCampaign]);
    
    useEffect(() => {
        if(!newCampaign.fileList.length){
            newCampaign.getContactFiles();
        }
    }, []);

    return (
        <FormCampaignWrapper>
            <FormTitle>Fantastic, you're nearly there!!</FormTitle>
            <FormSubtitle>
            Which segment do you want to use? Select the one that best fits your conversation objective or upload it <br />from your pc.
            </FormSubtitle>
            
                <CustomSelect 
                    placeholder="Select Segment" 
                    margin="20px 0 90px 0" 
                    options={newCampaign.fileList}
                    handleSelect={(name,value) => handleFileSelect(name,value)}
                    selectedDefault={newCampaign.fileName}
                />
            
            <CampaignDragAndDropForm />
        </FormCampaignWrapper>
    );
};

export default CampaignFileForm;
