import styled from "styled-components";

const ChatHistoryWrapper = styled.section`
    width: 300px;
    background-color: ${props => props.theme.colors.primary};
    position: fixed;
    right: 0;
    top: 0;
    min-height: 100vh;
    height: 100vh;
`;

const ChatHistoryHeader = styled.header`
    margin: 40px 50px 20px 50px;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    color: ${props => props.theme.colors.lightGrey};
    cursor: pointer;
    transition: all 0.5s;

    span {
        font-style: italic;
        font-size: 16px;
        margin-left: 20px;
    }

    &:hover{
        background-color: ${props => props.theme.colors.white};
        color: ${props => props.theme.colors.black};
    }
`;

const ChatHistoryTabContainer = styled.div`
    margin: 80px 0 20px 0;
    display: flex;
    justify-content: space-around;
    div {
        color: ${props => props.theme.colors.lightGrey};
        font-style: italic;
        font-size: 18px;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        p {
            margin: 0;
        }

    }

    div:first-child {
        span{
            width: 70px;
        }
    }
`;

const ActiveTabIndicator = styled.span`
    width: 130px;
    height: 4px;
    border-radius: 19px;
    display: inline-block;
    background-color: ${props => props.active ? props.theme.colors.white : 'none'};
`;

const ChatHistoryListContainer = styled.div`
    color: ${props => props.theme.colors.white};
`;

const ChatHistoryList = styled.ul`
    list-style: none;
    margin: 40px 20px 0 20px;
    padding: 0;
`;

const ChatHistoryListItem = styled.li`
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 4px;
    transition: background-color 0.5s;
    cursor: pointer;
    display: flex;

    span{
        width: 90%;
        margin-right: 10px;
        overflow: hidden;
        white-space: nowrap;
    }

    div{

    }

    &: hover{
        background-color: ${props => props.theme.colors.grey};
    }
`;

const ChatHistoryDisplayButton = styled.div`
    width: 16px;
    height: 120px;
    background-color: ${props => props.theme.colors.primary};
    position: absolute;
    top: 50%;
    right: ${props => props.$display ? '298px' : '0px'};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    transform: ${props => props.$display ? 'rotate(0)' : 'rotate(180deg)'};
`;

const ChatHistoryListItemSettingsBox = styled.ul`
    width: 100px;
    height: 110px;
    background-color: ${props => props.theme.colors.primary};
    position: absolute;
    top: 25px;
    right: 10px;
    list-style: none;
    padding: 0;
    transition: all 0.5s;
    z-index: 4;

    li:first-child {
        color: red;
    }

    li {
        padding: 1px 10px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        &: hover{
            background-color: ${props => props.theme.colors.grey};   
        }

    }
`;

const ChatHistoryListItemWrapper = styled.div`
    position: relative;
`;

export {
    ChatHistoryWrapper,
    ChatHistoryHeader,
    ChatHistoryTabContainer,
    ChatHistoryListContainer,
    ChatHistoryList,
    ChatHistoryListItem,
    ActiveTabIndicator,
    ChatHistoryDisplayButton,
    ChatHistoryListItemSettingsBox,
    ChatHistoryListItemWrapper
}