import React, { useCallback, useState, useEffect } from "react";
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useStore } from "../../hooks";
import DefaultStepper from "../../elements/stepper/DefaultStepper";
import { ContactFlowWrapper, FormSubtitle } from "./ContactFlowPage.Styled";
import CampaignFileForm from "./components/ContactFlowFile";
import CampaignMapFiles from "./components/ContactFlowMapFile";
import CampaignUploadFilesForm from "./components/ContactFlowUploadFiles";
import SuccessfulModal from "../../elements/modals/SuccesfulModal";
import AdmirationSign from '../../elements/icon/assets/admiration_sign.svg';

const ContactFlowPage = observer(() => {
    const navigate = useNavigate();
    const { contactStore, authStore } = useStore();
    const [showFirstModal, setShowFirstModal] = useState(false);
    const [showSecondModal, setShowSecondModal] = useState(false);
    const [timer, setTimer] = useState(300);
    const { selectedAccount } = authStore;

    useEffect(() => {
        contactStore.onChangeAccount(selectedAccount);
    }, []);

    const onHandleCancel = useCallback(() => {
        navigate('/');
    }, [navigate]);

    const onFinishStepper = useCallback(() => {
        contactStore.onCompleteCreation(selectedAccount, () => {
            setShowFirstModal(true);
        });
    }, [contactStore]);

    useEffect(() => {
        let timerId;
        if (showFirstModal) {
            timerId = setTimeout(() => {
                handleCloseFirstModal();
            }, 5000);
        }
        return () => clearTimeout(timerId);
    }, [showFirstModal]);

    const handleCloseFirstModal = () => {
        setShowFirstModal(false);
        navigate('/contacts');
        setShowSecondModal(true);
    };

    const handleCloseSecondModal = () => {
        setShowSecondModal(false);
    };

    return (
        <ContactFlowWrapper>
            <br />
            <DefaultStepper onFinish={onFinishStepper} onCancel={onHandleCancel} status={contactStore.isLoading ? 'loading' : contactStore.isError ? 'error' : 'idle'} stepperActionsWidth='400px' stepperActionsMt='60px'>
                <DefaultStepper.Step isComplete={() => contactStore.buttonUpload } validationErrorLabel="Please upload a file.">
                    <CampaignFileForm />
                </DefaultStepper.Step>

                <DefaultStepper.Step isComplete={() => contactStore.areAllFieldsDone } validationErrorLabel="There are empty selections.">
                    <CampaignMapFiles columnNames={contactStore.columnNames} selectedOptions={contactStore.selectedOptions} handleFieldSelect={contactStore.handleFieldSelect} />
                </DefaultStepper.Step>

                <DefaultStepper.Step isComplete={() => contactStore.buttonUpload } validationErrorLabel="Please upload a file.">
                    <CampaignUploadFilesForm />
                </DefaultStepper.Step>

                
            </DefaultStepper>

            {showFirstModal && <SuccessfulModal
                isOpen={showFirstModal}
                onClose={handleCloseFirstModal}
                imageSrc={AdmirationSign}
                title="Great!"
                subtitle="The file has been"
                subtitle2="uploaded successfully."
            />}
        </ContactFlowWrapper>
    );
});

export default ContactFlowPage;
