import InputText from '../TextInput/TextInput';
import {
    VariableWrapper,
    VariableContainer,
    VariableHeader,
    VariableBody,
    VariableSelects
} from './TemplateEditor.styled';
import { CustomSelect, IconButton } from '../../'
import { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../hooks/useStore';

const Variable = observer(({id}) => {

    const [isOpened, setIsOpened] = useState(false);
    const { templateEditorStore } = useStore();
    const [origin, setOrigin] = useState()

    const filterFields = useMemo(() => { 
        const seletedtOrigin = templateEditorStore.sourceList.find((field) => field.origin === origin );
        if (seletedtOrigin) {
          return seletedtOrigin.fields?.map((field) => ({ name: field.name, value: field.field, field_type: field.field_type, operators: field.operators, default_values: field.default_values }));
        }
        return [];
      }, [origin]);

    return (
        <VariableWrapper>
            <IconButton icon={!isOpened ? 'arrowDown' : 'arrowUp'} width='12px' height='12px' onClick={() => setIsOpened(!isOpened)}/>
            <VariableContainer>
                <VariableHeader>
                    <InputText 
                        placeholder="Enter a name" 
                        width="90%" 
                        value={templateEditorStore.variables[id].name}
                        onChange={e => templateEditorStore.updateVariableName(id, e.target.value)}
                    />
                    <InputText 
                        placeholder="Variable" 
                        width="90%" 
                        value={templateEditorStore.variables[id].variable} 
                        onChange={e => templateEditorStore.updateVariableVariable(id, e.target.value)}
                    />
                    <span><IconButton icon={'coloredTrashcan'} width={'15px'} onClick={() => templateEditorStore.removeVariable(id)}/></span>
                </VariableHeader>
                {isOpened && <VariableBody>
                    <div>
                        <CustomSelect 
                            placeholder="Select type" 
                            width='90%'
                            searchBar={false}
                            options={[
                                {name: 'Fixed', value: 'FIXED'},
                                {name: 'Event', value: 'EVENT'},
                            ]}
                            handleSelect={(_, value) => templateEditorStore.updateVariableType(id, value)}
                            selectedDefault={templateEditorStore.variables[id].origin}
                        />
                    </div>
                    {templateEditorStore.variables[id].origin === 'FIXED' ?
                        <InputText 
                            placeholder="Value" 
                            width="90%" 
                            value={templateEditorStore.variables[id].value} 
                            onChange={e => templateEditorStore.updateVariableValue(id, e.target.value)}
                        />
                        :
                        (
                            <VariableSelects>
                                {/* Options in the selects below are intended to be hardcoded */}
                                <CustomSelect 
                                    width='45%'
                                    placeholder='Source'
                                    searchBar={false}
                                    options={templateEditorStore.sourceList.map((field) => ({ name: field.name, value: field.origin }))}
                                    handleSelect={(_, value) => {templateEditorStore.updateVariableValueDynamic(id, value, true);setOrigin(value)}}
                                    selectedDefault={templateEditorStore.variables[id]._value?.source}
                                />

                                <CustomSelect 
                                    width='45%'
                                    searchBar={false}
                                    placeholder='Field'
                                    options={filterFields}
                                    handleSelect={(_, value) => templateEditorStore.updateVariableValueDynamic(id, value)}
                                    selectedDefault={templateEditorStore.variables[id]._value?.field}
                                />
                            </VariableSelects>
                        )
                    }
                </VariableBody>}
            </VariableContainer>
        </VariableWrapper>
    );
});

export default Variable