import styled from "styled-components";

const WidgetContainer = styled.div`
  border: 1px solid #bababa;
  border-radius: 8px;
  padding: ${(props) => props.padding || "16px"};
  height: auto;
  min-height: ${(props) => props.height || "auto"};
  max-height: ${(props) => props.maxheight || props.height};
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  scrollbar-width: thin;
  width: 100%;
}
`;

const WidgetTitle = styled.h2`
  height: 25px;
  font-weight: bold;
  margin: 0;
  font-size: 14px;
`;

const WidgetContent = styled.div``;

const WidgetHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.brightgray};
`;

const WidgetDetails = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.activeBlue};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DataAvaliableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 5px;

  & p:first-child {
    font-weight: bold;
  }
`;

const InfoList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2.5px;

  & li {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 5px;
    align-items: center;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const SubTitle = styled.div`
  font-weight: bold;
  font-size: 0.875rem;
`;

const EditWrapper = styled.div`
  cursor: pointer;
`;

const CarouselContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
`;

const CarouselButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CarouselButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const OrdersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 320px;

  & > ul {
    list-style: none;
    padding: 12px 0;
    margin: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.clearGray};
  }

  & > ul:last-child {
    border: none;
  }

  & li {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 5px;
    align-items: center;
  }
`;

const ROStatusField = styled.span`
  height: 16px;
  font-size: 0.625rem;
  padding: 2px 8px;
  background: ${(props) =>
    props.status === "inprogress"
      ? props.theme.colors.greyEight
      : props.status === "standby"
        ? props.theme.colors.jordyBlue
        : props.status === "completed"
          ? props.theme.colors.greenTea
          : props.theme.colors.greyEight};
  border-radius: 100px;
  text-transform: capitalize !important;
`;

const AppointmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > ul {
    list-style: none;
    padding: 12px 0;
    margin: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.clearGray};
  }

  & > ul:last-child {
    border: none;
  }

  & li {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 5px;
    align-items: flex-start;
  }
`;

const ShortDescription = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NotificationWrapper = styled.div`
  padding: 16px;
  background: ${(props) =>
    props.isCompleted  ? props.theme.colors.white : props.theme.colors.aliceBlue};
  min-height: 140px;
  height: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition:
    background-color 0.3s ease,
    border 0.3s ease;
  border: ${(props) =>props.isCompleted ? `1px solid ${props.theme.colors.clearGray}` : 'none'};
`;

const NotificartionTitle = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
`;

const NotificartionDescription = styled.span`
  font-size: 0.75rem;
  font-weight: normal;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const NotificationTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.625rem;

  & > span:first-child{
    text-transform: capitalize;
  }
`;

const NotificationActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 0.625rem;
  padding-left: 32px;
`;

const NotificationsActionsViewDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;

  & > span:first-child {
    color: ${(props) => props.theme.colors.activeBlue};
  }
`;

const NotificationMarkCompleted = styled.div`
  display: flex;
  border-radius: 8px;
  border: ${(props) => (props.isCompleted ? 'none' : `1px solid ${props.theme.colors.black}`)};
  padding: 8px;
  cursor: ${(props) => (props.isCompleted ? "default" : "pointer")};
  color: ${(props) => (props.isCompleted ? props.theme.colors.veroneseGreen : props.theme.colors.black)};
  transition:
    background-color 0.3s ease,
    border 0.3s ease;
`;


const NotificationDetails = styled.span`
  cursor: pointer;
  font-size: 0.875rem;
`;

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const Loading = styled.div`
  padding: 10px;
  text-align: center;
`

export {
  WidgetContainer,
  WidgetTitle,
  WidgetContent,
  WidgetHeader,
  WidgetDetails,
  DataAvaliableWrapper,
  SubTitle,
  InfoWrapper,
  InfoList,
  EditWrapper,
  CarouselContainer,
  CarouselButtons,
  CarouselButton,
  OrdersWrapper,
  ROStatusField,
  AppointmentsWrapper,
  ShortDescription,
  NotificationWrapper,
  NotificartionTitle,
  NotificartionDescription,
  NotificationTypeWrapper,
  NotificationActions,
  NotificationsActionsViewDetails,
  NotificationMarkCompleted,
  NotificationDetails,
  NotificationContainer,
  Loading
};
