const assistantListFileRoute = "knowledge_base_openai";
const validFileExtensions = ["txt", "pdf", "json", "docx", "csv"];
const extensions = {
    "application/vnd.ms-excel": [".xls"],
    "text/csv": [".csv"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
    ],
    "application/msword": [".docx"],
    "text/plain": [".txt"],
    "application/pdf": [".pdf"],
    "application/json": [".json"],
};


const initialTones = {
    friendly: false, 
    enthusiastic: false, 
    direct: false,
    professional: false, 
    cordial: false, 
    helpful: false
}

const initialChannels = {
    voice: false, 
    sms: false, 
    webchat: false,
    email: false, 
    'qna-webchat': false, 
}

const initialActions = {
    send_sms: false,
    send_email: false,
    appointments_schedule: false,
};


const triggerRole = [
    {name:'User', value: 'user'},
    {name:'Admin',value: 'admin'},
    {name:'Customer',value: 'customer'},
    {name:'Sales - Rep',value: 'salesRep'},
    {name:'Service - Rep',value: 'serviceRep'},
    {name:'Sales Manager', value: 'salesManager'}
]

const activityOptions = [
    {name:'Request a meeting', value: 'requestMeeting'},
    {name:'Assing to a user',value: 'assingUser'},
    {name:'Request a test drive',value: 'requestTestDrive'},
    {name:'Request a  quote',value: 'requestQuote'},
    {name:'Ask to talk to a human',value: 'askTalkHuman'}
]

const thatOption = [
    {name:'Send internal Email', value: 'internEmail'},
    {name:'Send internal SMS', value: 'internalSms'},
    {name:'Call', value: 'call'},
    {name:'Reply via Email', value: 'replyEmail'},
    {name:'Reply via SMS', value: 'replySms'}
]

export {
    assistantListFileRoute,
    validFileExtensions,
    extensions,
    initialTones,
    initialChannels,
    triggerRole,
    activityOptions,
    thatOption,
    initialActions,
}