import styled from "styled-components";

import { Icon } from '../../../../../elements';
import { AgentNode } from "./GenericNode.styled";

export const AddNodeContainer = styled(AgentNode)`
    width: 2.5rem;
    height: 2rem;
`

export const PlusIcon = styled(Icon)`
    display: flex;
`