import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks';
import { useNavigate } from 'react-router-dom';

import { DefaultStepper } from "../../../elements";
import { ConfirmationModal } from "../../../elements/v2/modals";
import { AgentInstructionsStep, AgentSetNameStep } from './components';

import { PageContainer, StepWrapper } from './NewAgentWizardView.styled';

const NewAgentWizardView = observer(() => {
  const navigate = useNavigate();
  const { changeTitle, agentStore } = useStore();
  const [ showConfirmCancel, setShowConfirmCancel ] = useState(false);
  const { newAgentStore } = agentStore;

  changeTitle('Agents');

  const redirectMainPage = useCallback(() => {
    navigate('/v2/assistants');
    newAgentStore.onClearNewAgent();
  }, [navigate, newAgentStore]);

  const onHandleFinish = useCallback(() => {
    newAgentStore.onCompleteCreation(redirectMainPage);
  }, [newAgentStore, redirectMainPage]);

  const onHandleCancel = () => {
    setShowConfirmCancel(true);
  };

  const onConfirmCancel = useCallback(() => {
    setShowConfirmCancel(false);
    redirectMainPage();
  }, [redirectMainPage]);

  return (
    <PageContainer>
      <ConfirmationModal 
        isOpen={showConfirmCancel}
        title="Cancel" 
        message="Precaution You are about to exit the agent creation process. You will lose unsaved information. Are you sure about this?"
        icon="deleteFilled"
        onConfirm={onConfirmCancel}
      />
      {}
      <DefaultStepper
        stepperActionsWidth={"494px"}
        stepperErrorWidth={"494px"}
        onFinish={onHandleFinish}
        status={newAgentStore.isLoading ? 'loading' : 'idle'}
        onCancel={onHandleCancel}
      >
        <DefaultStepper.Step
          isComplete={() => newAgentStore.onValidateTextField('instructions')}
          validationErrorLabel="Agent instructions is required!">
          <StepWrapper><AgentInstructionsStep /></StepWrapper>
        </DefaultStepper.Step>
        <DefaultStepper.Step
        isComplete={() => newAgentStore.onValidateTextField('name')}
          validationErrorLabel="Agent name is required!">
          <StepWrapper><AgentSetNameStep /></StepWrapper>
        </DefaultStepper.Step>
      </DefaultStepper>
    </PageContainer>
  );
});

export default NewAgentWizardView;
