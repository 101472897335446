import {useCallback} from "react";
import { observer } from "mobx-react";

import { Icon } from "../../../../elements";
import {useStore} from "../../../../hooks";
import {
  StepWrapper,
  FormWrapper,
  LogoImageWrapper,
  TitleStep,
  AgentsTeamDescriptionInput
} from "./Components.styled";

const AgentsTeamDescriptionStep = observer(() => {
  const { agentsTeamsStore } = useStore();

  const onHandleChangeDescription= useCallback((e) => {
    agentsTeamsStore.onChangeDescription(e.target.value);
  }, [agentsTeamsStore]);

  return (
    <StepWrapper>
        <LogoImageWrapper>
            <Icon name="annIsotype" width="96px" height="75px" />
        </LogoImageWrapper>
      <FormWrapper>
        <TitleStep>What is the focus of this team's work?</TitleStep>
        <AgentsTeamDescriptionInput
          value={agentsTeamsStore.description}
          placeholder="Enter a description"
          onChange={onHandleChangeDescription}
        />
      </FormWrapper>
    </StepWrapper>
  );
});

export default AgentsTeamDescriptionStep;
