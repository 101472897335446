import styled from 'styled-components';

const Wrapper = styled.div`
    position: relative;
`;

const CustomTextArea = styled.textarea`
    resize: none;
    padding: 5px 10px;
    border-radius: 10px;
    width: ${props => props.$width || '100%'};
    border: 1px solid ${props => props.theme.colors.clearGray};
    &:focus {
        outline: none;
    }
    font-size: ${props => props.theme.fonts.size.b2};
    margin-top: 10px;
`

const IconWrapper = styled.div`
    position: absolute; 
    bottom: 5px;
    right: 5px;
    color: ${props => props.theme.colors.darkGray};
    cursor: pointer;
`;

const ExpandIcon = styled.img`
    cursor: pointer;
    width: 1.0vw;
    height: 1.0vw;
    margin-right: 12px;
`;


const Label = styled.div`
    font-size: 9px;
    color: ${props => props.theme.colors.darkGray};
    font-weight: 500;
    text-align: justify;
    background-color: ${props => props.theme.colors.greyFive};
    width: 28vw;
    padding: 8px;
    border-radius: 10px;
    position: absolute;
    top: -94px;
    max-height: 90px;
    opacity:0;
    animation: smooth-appear 1s ease forwards;

    @keyframes smooth-appear {
        to{
          opacity:1;
        }
    }

`;

const InstructionsSuggestionsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 0px;
    width: ${props => props.$width};
    height: 40px;
`;

const Suggestion = styled.div`
    height: 50px;
    background: ${props => props.theme.colors.white};
    border-radius: 10px;
    border: 1px ${props => props.theme.colors.clearGray} solid;
    margin: 5px;
    text-align: center;
    font-size: 0.7vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 16px;
    cursor: pointer;
    transition: background-color 0.5s;
    color: ${props => props.theme.colors.darkGray};

    &:hover {
        background-color: ${props => props.theme.colors.lightGrey};
    }
`;

const ExpandIconContent = styled.div`
    position: absolute;
    bottom: 5px;
    right: 5px;
`;

const CustomContent = styled.div`
    position: relative;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled.p`
    display: inline-block;
    font-size: ${props => props.theme.fonts.size.s1};
    font-weight: 600;
    margin: 0;
`;

export {
    CustomTextArea,
    Wrapper,
    Label,
    InstructionsSuggestionsContainer,
    Suggestion,
    IconWrapper,
    ExpandIcon,
    ExpandIconContent,
    CustomContent,
    Header,
    Title
}