import styled from 'styled-components';

const ThreeColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
`;

const SearchInput = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  height: 49px;
  border-radius: 10px;
  opacity: 0.06px;
  angle: -90deg;
  background-color: ${props => props.theme.colors.silverGray};
`;

const InputField = styled.input`
  flex: 1;
  height: 100%;
  padding-left: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 16px;
`;

const SearchIcon = styled.img`
  width: 20px;
  height: auto;
  margin-right: 10px;
  margin-left: 10px;
`;

const CustomComponent = styled.div`
  width: 528.92px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-left: 8%;
`;

const CustomComponent1 = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-left: 15%;
`;

const InfoBoxWrapper = styled.div`
  width: 166.45px;
  margin-left: 10px;
`;

const Title = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Description = styled.p`
  font-size: 14px;
  margin-bottom: 5px;
`;

const Date = styled.p`
  font-size: 12px;
`;

const AgentViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin-top : 50px;
  width: 100%;
`;

const CenteredCustomComponent = styled.div`
  margin-top: 20px;
`;

const DividerLine = styled.div`
  width: 80%;
  height: 1px;
  background-color: ${props => props.theme.colors.lighterGray};
  margin-top: 20px;
  margin-bottom: 20px;
`;

const TruncatedDescription = styled.p`
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
`;

const CustomImage = styled.img`
    width: 100px;
    height: auto;
`;

const LoadMoreContainer = styled.div`
  text-align: -webkit-center;
  margin-top: 20px;
`;

export { 
  ThreeColumnGrid,
  SearchInput,
  InputField,
  SearchIcon,
  CustomComponent,
  InfoBoxWrapper,
  Title,
  Description,
  Date,
  AgentViewContainer,
  CenteredCustomComponent,
  DividerLine,
  TruncatedDescription,
  CustomImage,
  CustomComponent1,
  LoadMoreContainer
};
