import React, { useState, useEffect } from "react";
import { observer } from 'mobx-react';
import {useStore} from "../../../../hooks";
import { Header, Name } from "../../components/UserInfo/UserInfo.styled";
import { HeaderGarageInfo, HeaderGarageContainer, GarageInfo } from "./GarageCard.styled";
import CardImage from "./img/card.svg";
import CardImage2 from "./img/card2.svg";
import NextImg from './img/next.svg'
import PrevImg from './img/prev.svg'

import { ButtonContainer, PrevButton, NextButton } from "./GarageCard.styled";

const formatMileage = (mileage) => {
    return mileage?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const GarageCard = observer(() => {
    const { unifiedCustomerProfileStore } = useStore();
    const [currentVehicleIndex, setCurrentVehicleIndex] = useState(0);


    useEffect(() => {
        if(unifiedCustomerProfileStore.initialLoad){
            unifiedCustomerProfileStore.getGarage();
        }

    }, [unifiedCustomerProfileStore.initialLoad])

    const handlePrevVehicle = () => {
        setCurrentVehicleIndex((prevIndex) => {
            const nextIndex = prevIndex === 0 ? unifiedCustomerProfileStore.garage.length - 1 : prevIndex - 1;
            unifiedCustomerProfileStore.setSelectedCar(nextIndex);
            return nextIndex;
        });
    };

    const handleNextVehicle = () => {
        setCurrentVehicleIndex((prevIndex) => {
            const nextIndex = prevIndex === unifiedCustomerProfileStore.garage.length - 1 ? 0 : prevIndex + 1;
            unifiedCustomerProfileStore.setSelectedCar(nextIndex);
            return nextIndex;
        });
    };

    return (
        <HeaderGarageContainer>
            <Header>
                <HeaderGarageInfo>
                    <Name>Garage:</Name>
                    <div>{`${unifiedCustomerProfileStore.selectedCar?.brand}, ${unifiedCustomerProfileStore.selectedCar?.model}, ${unifiedCustomerProfileStore.selectedCar?.year}, $${unifiedCustomerProfileStore.selectedCar?.price?.toLocaleString()}`}</div>
                </HeaderGarageInfo>
            </Header>
            <GarageInfo>
                {/* May be used in the future */}
                {/* 
                <ProgressBarWrapper>
                    <Progress progress={mileagePercentage} />
                </ProgressBarWrapper> 
                */}
                <ButtonContainer>
                    <NextButton onClick={handlePrevVehicle}><img src={PrevImg} alt="Prev" /></NextButton>
                    <img src={CardImage} alt="Vehicle" />
                    <PrevButton onClick={handleNextVehicle}><img src={NextImg} alt="Next" /></PrevButton>
                </ButtonContainer>
                <div>Mileage {formatMileage(unifiedCustomerProfileStore.selectedCar?.mileage)}</div>
            </GarageInfo>
        </HeaderGarageContainer>
    );
});

export default GarageCard;
