import styled from 'styled-components';

export const MessageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  max-width: ${(props) => (props.isMobile ? '200px' : '700px')};
  overflow: hidden;
  font-size: 12px;
  justify-content: space-between;
`;

export const MessageContent = styled.div`
  flex-grow: 1;
  white-space: ${(props) => (props.expanded ? 'normal' : 'nowrap')};
  overflow: ${(props) => (props.expanded ? 'visible' : 'hidden')};
  text-overflow: ellipsis;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
`;
