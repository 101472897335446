import styled, { css } from 'styled-components';

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 5;

    ${(props) => props.isOpen && css`
        display: flex;
    `}
`;

const ModalWrapper = styled.div`
    position: relative;
    width: 750px;
    height: fit-content;
    background-color: white;
    border-radius: 10px;
    padding: 24px;
`;

const ModalContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-radius: 10px;
    height: 590px;
    gap: 24px;
    margin-top: 34px;

    > div:first-child {
        background-color: ${props => props.theme.colors.primaryWhite};
    }

    > div:last-child {
        background-color: ${props => props.theme.colors.highClearGray};
    }

    > div:first-child, > div:last-child {
        padding: 16px;
    }
`;

const GenericListContainer = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 10px;
`;

const ModalDescription = styled.p`
    display: inline-block;
    margin: 8px 0 32px 0;
    font-weight: 300;
    font-size: ${props => props.theme.fonts.size.lt1};
`;


const ModalButtonsContainer = styled.div`
    display: flex;
    gap: 10px;
`;

const ModalHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;

`;

const Title = styled.p`
    display: inline-block;
    font-size: ${props => props.theme.fonts.size.s1};
    font-weight: 600;
    margin: 0;
`;

export {
    ModalWrapper,
    ModalButtonsContainer,
    Backdrop,
    ModalContainer,
    ModalDescription,
    Title,
    ModalHeaderContainer,
    GenericListContainer
};
