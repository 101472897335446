import { action, flow, makeObservable, observable } from "mobx";

class RepairOrderStore {
  repairOrders = [];
  filteredRepairOrders = [];
  totalRowCount = 0;
  isLoading = false;
  isError = false;
  currentPage = 1;
  pageSize = 10;

  constructor() {
    makeObservable(this, {
      repairOrders: observable,
      filteredRepairOrders: observable,
      totalRowCount: observable,
      isLoading: observable,
      isError: observable,
      currentPage: observable,
      pageSize: observable,
      fetchRepairOrders: flow,
      setError: action,
      clearError: action,
      setPage: action,
      filterRepairOrders: action,
    });
  }

  *fetchRepairOrders() {
    this.isLoading = true;
    this.isError = false;

    try {
      yield new Promise((resolve) => setTimeout(resolve, 500));
      const repairOrdersMockData = [
        { id: "c28f5b07-4ea2-40c4-87bf-296491db89e2", roNumber: "#420449", status: "Open", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "231cb973-161e-4a4b-b37c-7813679616e8", roNumber: "#420450", status: "Closed", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "328ed37b-bc2b-4b82-89e7-4392989a3aeb", roNumber: "#420451", status: "In progress", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "a332a86c-8f9e-402d-a2b4-4f19d89ae7b2", roNumber: "#420452", status: "Open", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "b6b71da4-7fdd-411d-9982-0fc373c34580", roNumber: "#420453", status: "Open", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "9a9de121-5e20-46db-89e6-f4783774b5dd", roNumber: "#420454", status: "Open", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "e8fb233e-7143-4559-9458-bb47f0adcaad", roNumber: "#420455", status: "Closed", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "dd61492e-76a4-4c37-9658-7e682cff173e", roNumber: "#420456", status: "In progress", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "13db2599-08b9-40d1-b043-fd92399dc2d7", roNumber: "#420457", status: "Open", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "9ffe322f-4141-4186-a63b-d334a75c6dd9", roNumber: "#420458", status: "Open", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "2d18c1e6-802a-42a6-a94e-7e4ab015f0a2", roNumber: "#420459", status: "Open", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "13983bd2-b5de-49b5-9436-59e207f3e8c7", roNumber: "#420460", status: "Closed", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "2f2b11df-fda5-4214-8720-1ed5e8a5f7f7", roNumber: "#420461", status: "In progress", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "bc1671b6-c0f8-4ef2-9b93-9a05b0c93dd1", roNumber: "#420462", status: "Open", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "d2a354b7-e5d6-4c43-a22e-431eb5b64c3d", roNumber: "#420463", status: "Closed", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "f23a805b-d0d3-486b-89e3-8d83a410bb87", roNumber: "#420464", status: "Open", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "e422f843-5b5f-41de-b9e3-bf19d7df36af", roNumber: "#420465", status: "Open", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "e808f96e-16e3-498f-98b3-c43d91b61148", roNumber: "#420466", status: "In progress", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "dc18d746-e9d5-4501-9304-81c9f25a68d7", roNumber: "#420467", status: "Open", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
        { id: "6e8b68cd-f7a2-4f3b-8df2-cf7c55ef2c8f", roNumber: "#420468", status: "Closed", description: "This is a description for each order.", appointmentDay: "02/13/2024", bookDay: "03/13/2024" },
      ];      

      this.repairOrders = repairOrdersMockData;
      this.filteredRepairOrders = repairOrdersMockData;
      this.totalRowCount = repairOrdersMockData.length;
    } catch (error) {
      this.setError(true);
    } finally {
      this.isLoading = false;
    }
  }

  setError(isError) {
    this.isError = isError;
  }

  clearError() {
    this.isError = false;
  }

  setPage(page) {
    this.currentPage = page;
  }

  filterRepairOrders(filters) {
    if (!filters || filters.length === 0) {
      this.filteredRepairOrders = this.repairOrders;
    } else {
      this.filteredRepairOrders = this.repairOrders.filter(order =>
        filters.every(filter => order[filter.key] === filter.value)
      );
    }
  }
}

export default RepairOrderStore;
