import { useState, useEffect } from "react";
import {CheckboxWrapper, CheckboxLabel, CustomCheckboxInput} from "./CustomCheckbox.styled";

const CustomCheckbox = ({ label, onSelectTone, options, textTransform, width, fontSize, disabled}) => {
    const [isChecked, setIsChecked] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const onCheck = () => {
        if(!isDisabled){
            setIsChecked((prev) => !prev)
        }
    } 

    useEffect(() => {
        onSelectTone({[label]: isChecked})

    }, [isChecked])

    useEffect(() => {
        setIsChecked(options[label])
    }, [options[label]])

    useEffect(() => {
        setIsDisabled(disabled)
    }, [disabled])

    return (
            <CheckboxWrapper checked={isChecked} onClick={onCheck} width={width}>
                <CustomCheckboxInput 
                    checked={isChecked}
                    onChange={onCheck}
                    onClick={onCheck}
                    disabled={isDisabled}
                />
                <CheckboxLabel checked={isChecked} textTransform={textTransform} fontSize={fontSize}>{label}</CheckboxLabel>
            </CheckboxWrapper>    
    );
}

export default CustomCheckbox;
