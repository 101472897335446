import React, { useEffect, useState } from 'react';
import { ShineThinkingContainer } from './ShineThinking.styled';

const TEXTS = ["Checking Knowledge Base", "Thinking ...", "Retrieving Answer"];
const INTERVAL = 5000;


const ShineThinking = () => {
    const texts = ["Checking Knowledge Base", "Thinking ...", "Retrieving Answer"];
    const [currentTextIndex, setCurrentTextIndex] = useState(0); 
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % TEXTS.length);
      }, INTERVAL);
  
      return () => clearInterval(interval);
    }, []); 

    return (
        <ShineThinkingContainer>
            <div className='text-container'>
                <div>{texts[currentTextIndex]}</div>
            </div>
        </ShineThinkingContainer>
    );
};

export default ShineThinking;
