import { useCallback } from "react";
import { observer } from "mobx-react";
import { Icon } from "../../../../../elements";
import { useStore } from "../../../../../hooks";
import {
  StepWrapper,
  FormWrapper,
  LogoImageWrapper,
  TitleStep,
  UserInput,
  ErrorDiv
} from "./UserComponents.Styled";

const UserEmailStep = observer(() => {
  const { newUser } = useStore();

  const onHandleChangeName = useCallback((e) => {
    newUser.onChangeEmail(e.target.value);
  }, [newUser]);

  return (
    <StepWrapper>
      <LogoImageWrapper>
        <Icon name="annIsotype" width="96px" height="75px" />
      </LogoImageWrapper>
      <FormWrapper>
        <TitleStep>Please provide an email.</TitleStep>
        <UserInput
          value={newUser.email}
          placeholder="Email Address"
          onChange={onHandleChangeName}
        />
        {newUser.formError && <ErrorDiv>{newUser.formError}</ErrorDiv>}
      </FormWrapper>
    </StepWrapper>
  );
});

export default UserEmailStep;
