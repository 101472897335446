import React from "react";
import { Route, Routes } from "react-router-dom";
import ChannelList from "./ChannelList";

const ChannelPage = () => {
  return (
    <Routes>
      <Route index element={<ChannelList />} />
    </Routes>
  );
};

export default ChannelPage;