import React, {useEffect, useRef} from 'react';
import { ChatWindowComponentPaper, MessageContainer, ChatWindowAvatar, SpinnerImage, MessageWrapper, ImageWrapper } from './ChatWindowComponent.styled';
import Message from './Message';
import AnnAvatar from '../../images/AnnAvatar.svg';
import Spinner from '../icon/assets/spinners_chat.svg';
import Markdown from 'react-markdown';
import ShineThinking from '../ShineThinking';

const ChatWindowComponent = ({ messages, bottomRef, className, displayButtons = true, width, height, agentIsTyping, boxShadow, showImage, selectedAgent, backgroundColor }) => {
  // Filter out consecutive messages of the same type, keeping only the last received message
  let filteredMessages = [];
  let lastReceivedIndex = -1;
  const chatWindowRef = useRef(null);

  useEffect(() => {
    const handleLinkClick = (event) => {
      const link = event.target.closest('a'); // Encuentra el enlace más cercano
      if (link && link.tagName === 'A' && link.target !== '_blank') {
        event.preventDefault();
        window.open(link.href, '_blank');
        event.stopPropagation();
      }
    };

    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      chatWindow.addEventListener('click', handleLinkClick);
    }

    return () => {
      if (chatWindow) {
        chatWindow.removeEventListener('click', handleLinkClick);
      }
    };
  }, []);

  messages.forEach((message, index) => {
    if (message.type !== 'received') {
      filteredMessages.push(message);
    } else {
      // Check if this received message is consecutive with the previous one
      if (index === 0 || messages[index - 1].type !== 'received') {
        lastReceivedIndex = filteredMessages.length;
        filteredMessages.push(message);
      } else {
        filteredMessages[lastReceivedIndex] = message;
      }
    }
  });

  return (
    <ChatWindowComponentPaper ref={chatWindowRef} elevation={3} className={className} $height={height} boxShadow={boxShadow} sx={{backgroundColor: backgroundColor || 'white'}}>
      {showImage && !messages.length && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <img alt="Concierge Agent" />
        </div>
      )}
      {filteredMessages.map((item, index) => (
        <Message key={index} messageItem={item} displayButtons={displayButtons} width={width} selectedAgent={selectedAgent}/>
      ))}

      {agentIsTyping && messages.type !== 'sent' && (
        <MessageContainer width={width}>
          <ChatWindowAvatar src={AnnAvatar} alt="Agent Avatar" />
          <MessageWrapper flex>
            <ShineThinking />
          </MessageWrapper>
        </MessageContainer>
      )}

      <div ref={bottomRef} />
    </ChatWindowComponentPaper>
  );
};

export default ChatWindowComponent;
