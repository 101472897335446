import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { RoundedButton, IconButton } from "../../..";
import { useStore } from "../../../../hooks";
import {
  RuleHeader,
  FooterWrapper,
  RuleSeparator,
  HeaderWrapper,
  RulesViewWrapper,
  FiltersDivision,
  FiltersGenerator,
} from "./RulesView.styled";
import RuleEngine from "../../../../pages/v2/Conversations/components/RuleEngine/RuleEngine";
import PostRulesView from "./RulesViewVisualization";
import cloneDeep from "lodash/cloneDeep";

const RulesView = observer(({ setShowContactMapping, showContactMapping }) => {
  const { contactSegmentStore } = useStore();
  const { rulesStore } = contactSegmentStore;
  const [rules, setRules] = useState(null);

  const onAddGroup = () => {
    rulesStore.addGroup("OR");
  };

  const onImportCSV = () => {
    setShowContactMapping(!showContactMapping)
  }

  const onRemoveGroup = (group) => {
    rulesStore.removeGroup(group);
    updateChanges();
  };

  const onAddCondition = (group) => {
    rulesStore.addRuleToGroup(group);
  };

  const validateRules = () => {
    return Object.values(rulesStore?.rulesGroups).some((group) => {
      return group?.conditions?.some(
        (condition) =>
          condition.source_data?.length &&
          condition.source_field?.length &&
          condition.operator?.length &&
          condition.target_value?.length
      );
    });
  };

  const onApplyFilters = () => {
    rulesStore.saveRules();
    contactSegmentStore.updateAdvancedFilter();
    updateChanges();
  };

  const updateChanges = () => {
    rulesStore.setShowRightPreview(true);
    if (validateRules()) {
      setRules(cloneDeep(rulesStore));
    } else {
      setRules(
        cloneDeep({
          fields: [],
          message: "",
          operator: null,
          rulesGroups: {},
        })
      );
    }
  };

  return (
    <RulesViewWrapper showRightPreview={rulesStore.showRightPreview}>
      <p>All filters</p>
      <FiltersDivision>
        <FiltersGenerator showRightPreview={rulesStore.showRightPreview}>
          <HeaderWrapper>
            {!showContactMapping && <RoundedButton
              width="80px"
              height="32px"
              fontSize="0.75em"
              onClick={onApplyFilters}
              kind={"blue"}
            >
              Apply
            </RoundedButton>}
          </HeaderWrapper>
          {Object.keys(rulesStore?.rulesGroups).map((group, idx) => (
            <>
              <RuleHeader>
                <p>Group {idx + 1}</p>
                <span>
                  <IconButton
                    icon="circleAdd"
                    width="16px"
                    height="16px"
                    onClick={() => onAddCondition(group)}
                  />
                  <IconButton
                    icon="deleteOutline"
                    width="20px"
                    height="20px"
                    onClick={() => onRemoveGroup(group)}
                  />
                </span>
              </RuleHeader>
              {rulesStore?.rulesGroups[group]?.conditions.length > 0 && (
                <>
                  {rulesStore?.rulesGroups[group]?.conditions.map(
                    (rule, index) => (
                      <>
                        <RuleEngine
                          deleteIcon="deleteOutline"
                          className="segment-variant"
                          rule={rule}
                          index={index}
                          key={`rule-${index}`}
                          fields={rulesStore.fields}
                          ruleOperator={rulesStore.operator}
                          onChange={(info) =>
                            rulesStore.updateRule(info.index, info.data, group)
                          }
                          onClose={() => rulesStore.removeRule(index, group)}
                        />
                      </>
                    )
                  )}
                </>
              )}
              <RuleSeparator>
                <span></span>
                <div> OR </div>
                <span></span>
              </RuleSeparator>
            </>
          ))}
          <FooterWrapper>
            {!showContactMapping && <RoundedButton width="160px" onClick={onAddGroup} kind={"blue"}>
              Add Filter Group
            </RoundedButton>}
            <RoundedButton width="160px" onClick={onImportCSV} kind={"outlined"}>
              {showContactMapping ? "< Segment Table" : "Import CSV"}
            </RoundedButton>
          </FooterWrapper>
        </FiltersGenerator>
        {rulesStore.showRightPreview && <PostRulesView rules={rules} />}
      </FiltersDivision>
    </RulesViewWrapper>
  );
});

export default RulesView;
