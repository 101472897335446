import { styled } from "styled-components";

const InputWrapper = styled.textarea`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '46px'};
    padding: 10px 20px;
    border-radius: 7px;
    border: 1px solid ${(props) => props.theme.colors.clearGray};
    font-size: ${(props) => props.theme.fonts.size.b2};
    ${(props) => props.theme.fonts.variant.light};
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.backgroundColor || 'transparent'};
    box-sizing: border-box;
    resize: ${(props) => props.$resize};
    
    ::placeholder {
        font-style: italic;
        color: ${(props) => props.theme.colors.clearGray};

    }

    &:disabled{
        background-color: ${(props) => props.theme.colors.white};
        border: 1px solid ${(props) => props.theme.colors.lightGrey};
        color: ${(props) => props.theme.colors.grey};
    }
`;


export {
    InputWrapper
}