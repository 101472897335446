import { useState, useEffect } from "react";
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import { useStore } from "../../../../hooks";
import {
    Avatar,
    ContactInfo,
    Header,
    HeaderContactInfo,
    Name,
    Title,
    UserContainer,
    Value
} from "./CompanyDetails.style";
import { Tooltip } from "@mui/material";
import defaultImage from "../../../../elements/assets/Ellipse 6.png";
import { IconButton } from "../../../../elements";

const CompanyDetails = observer(() => {
    const { annUnifiedCustomerProfileStore } = useStore();
    const [ucp, setUCP] = useState({});

    useEffect(() => {
        if (annUnifiedCustomerProfileStore.ucp) {
            setUCP(annUnifiedCustomerProfileStore.ucp);
        }
    }, [annUnifiedCustomerProfileStore.ucp]);

    return (
        <UserContainer> 
            <Name>Account Manager</Name>
            <Header>
                <Avatar src={defaultImage} />
                <HeaderContactInfo>
                        <Name>Name&nbsp;</Name>
                        <Title>{ucp.name ? ucp.name : 'Name not available'}</Title>
                </HeaderContactInfo>
            </Header>
            <ContactInfo>
                <Tooltip title={ucp.company_name}>
                    <Name>Company Name&nbsp;</Name>
                    <Title>{ucp.company_name ? ucp.company_name : 'Name not available'}</Title>
                </Tooltip>
                <Tooltip>
                    <Name>Address&nbsp;</Name>
                    <Title>{ucp.address}</Title>
                </Tooltip>
                <Tooltip title={ucp.contact}>
                    <Name>Contact Info&nbsp;</Name>
                    <Title>{ucp.phone_number ? ucp.phone_number : 'Name not available'}</Title>
                    <Title>{ucp.email ? ucp.email : 'Name not available'}</Title>
                </Tooltip>
                <Tooltip>
                    <Name>Service contract&nbsp;</Name>
                    <Title>{ucp.service_contract ? ucp.service_contract : 'Name not available'}</Title>
                </Tooltip>
            </ContactInfo>
        </UserContainer>
    );
});

export default CompanyDetails;