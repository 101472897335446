import styled from 'styled-components';

const IconButtonWrapper = styled.button`
    display: inline;
    border: 0;
    background-color: inherit;
    padding: 0;
    margin: 0;
    color: inherit;
    width: ${props => props.width || "30px"};
    height: ${props => props.height || "30px"};
    margin-top: ${props => props.top || "0px"};

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    &:disabled {
        opacity: 0.8;
    }
`;

export {
    IconButtonWrapper
}