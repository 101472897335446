import React from 'react';
import { RoundedButton } from '../button';
import { StatusButtonContainer } from './StatusButton.styled';


const StatusButtons = ({ status }) => {
    const normalizedStatus = status.toLowerCase();

    return (
        <>
            {normalizedStatus === 'approved' && (
                <RoundedButton kind={'success'} width='80px' height='30px' fontSize='12px'>
                    Approved
                </RoundedButton>
            )}
            {normalizedStatus === 'declined' && (
                <RoundedButton kind={'declined'} width='80px' height='30px' fontSize='12px'>
                    Decline
                </RoundedButton>
            )}
            {normalizedStatus === 'pending' && (
                <StatusButtonContainer>
                    <RoundedButton kind={'cancel'} width='80px' height='30px' fontSize='12px'>
                        Decline
                    </RoundedButton>
                    <RoundedButton kind={'confirm'} width='80px' height='30px' fontSize='12px'>
                        Confirm
                    </RoundedButton>
                </StatusButtonContainer>
            )}
            {normalizedStatus === 'active' && (
              <RoundedButton kind={'aquamarineGreen'} width='80px' height='30px' fontSize='12px' color='perlDarkGray'>
                  Active
              </RoundedButton>
            )}
        </>
    );
};

export default StatusButtons;
