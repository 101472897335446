const appointmentsMockData = [
    { id: "c28f5b07-4ea2-40c4-87bf-296491db89e2", serviceType: "Annual inspection", appointmentDay: "03/13/2024", appointmentTime: "07:30", description: "Annual vehicle inspection emissions test, will check all fluids", customer: "Alice Johnson" },
    { id: "231cb973-161e-4a4b-b37c-7813679616e8", serviceType: "Oil change", appointmentDay: "03/15/2024", appointmentTime: "08:30", description: "Change engine oil and filter", customer: "Bob Smith" },
    { id: "328ed37b-bc2b-4b82-89e7-4392989a3aeb", serviceType: "Tire rotation", appointmentDay: "03/17/2024", appointmentTime: "09:00", description: "Rotate all four tires", customer: "Charlie Brown" },
    { id: "a332a86c-8f9e-402d-a2b4-4f19d89ae7b2", serviceType: "Brake inspection", appointmentDay: "03/20/2024", appointmentTime: "10:30", description: "Check brake pads and rotors", customer: "Daisy Ridley" },
    { id: "b6b71da4-7fdd-411d-9982-0fc373c34580", serviceType: "Battery check", appointmentDay: "03/21/2024", appointmentTime: "11:00", description: "Test battery health and voltage", customer: "Edward Norton" },
    { id: "9a9de121-5e20-46db-89e6-f4783774b5dd", serviceType: "Transmission flush", appointmentDay: "03/23/2024", appointmentTime: "12:30", description: "Flush and replace transmission fluid", customer: "Fiona Apple" },
    { id: "e8fb233e-7143-4559-9458-bb47f0adcaad", serviceType: "Coolant replacement", appointmentDay: "03/25/2024", appointmentTime: "14:00", description: "Drain and replace coolant", customer: "George Clooney" },
    { id: "dd61492e-76a4-4c37-9658-7e682cff173e", serviceType: "Air filter replacement", appointmentDay: "03/27/2024", appointmentTime: "15:00", description: "Replace cabin and engine air filters", customer: "Hannah White" },
    { id: "13db2599-08b9-40d1-b043-fd92399dc2d7", serviceType: "Spark plug replacement", appointmentDay: "03/29/2024", appointmentTime: "16:30", description: "Replace all spark plugs", customer: "Ian McKellen" },
    { id: "9ffe322f-4141-4186-a63b-d334a75c6dd9", serviceType: "AC service", appointmentDay: "04/01/2024", appointmentTime: "09:45", description: "Recharge AC system", customer: "Julia Roberts" },
  ];
  
  export default appointmentsMockData;
  