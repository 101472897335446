import { useCallback, useEffect, useRef } from 'react';
import EmailEditor from 'react-email-editor';
import IconTabs from '../Tabs/IconTabs/IconTabs';
import { 
  TemplateEditorWrapper, 
  EmailEditorWrapper, 
  TabsWrapper, 
  Backdrop, 
  TemplateEditorContainer, 
  TemplateEditorFooter, 
  ButtonWrapper 
} from './TemplateEditor.styled';
import GeneralTab from './GeneralTab';
import { RoundedButton } from '../../button';
import VariablesTab from './VariablesTab';
import { observer } from 'mobx-react';
import useStore from '../../../hooks/useStore';
import { toJS } from 'mobx';
import Spinner from '../../../components/common/Spinner';
import { SuccessfulModal } from '../../modals';


const TemplateEditor = observer(({isOpen=true, onClose, onLoad, onExport, isPopup=true, isReadMode, exportAction}) => {
    const emailEditorRef = useRef(null);
    const { templateEditorStore } = useStore();

    useEffect(() => {
      onLoad?.();
      templateEditorStore.loadTemplate();
    }, [])
  
  
    const updateVariables = () => {
      if(templateEditorStore.variables.length && emailEditorRef.current?.editor){
        const variablesToSet = {}
        for(const variable of templateEditorStore.variables){
          variablesToSet[variable.variable] = {
            name: variable.name,
            value: `{{${variable.variable}}}`,
            sample: `{{${variable.variable}}}`
          }
        }

        emailEditorRef.current.editor.setMergeTags(variablesToSet);
      }
    };


    const exportHtml = () => {
      const unlayer = emailEditorRef.current?.editor;
  
      unlayer?.exportHtml(async (data) => {
        const { design, html } = data;
        await templateEditorStore.saveTemplate(html, design);
        templateEditorStore.savePayload();
        onExport?.()
      });
    };

    const closeEditor = () => {
      templateEditorStore.clearTemplate();
      onClose && onClose();
    }

    const onReady = () => {
      updateVariables()
      templateEditorStore.updateGeneralField('initialLoading', true)
      if(templateEditorStore.templateJson && templateEditorStore.initialLoading){
        emailEditorRef.current.editor.loadDesign(toJS(templateEditorStore.templateJson))
      }
      exportAction?.(() => () => exportHtml())
    }

    const tabs = [
        {
          label: 'General',
          icon: 'gear',
          content: (<GeneralTab/>),
        },
        {
          label: 'Variables',
          icon: 'brackets',
          content: (<VariablesTab updateVariables={updateVariables}/>),
        },
      ];
    
    
    const CoreEditor = useCallback(() => (
      <TemplateEditorContainer>
        <TabsWrapper>
            <IconTabs tabs={tabs} />
        </TabsWrapper>
        <EmailEditorWrapper>
            {templateEditorStore.isLoading ? <Spinner/> : <EmailEditor onReady={onReady} ref={emailEditorRef} options={{
                version: "latest",
                appearance: {
                  theme: "modern_light"
                },
                id: 'editor',
                projectId: 250235,
            }} />}
        </EmailEditorWrapper>
      </TemplateEditorContainer>
    ), [templateEditorStore.initialLoading])
  
    return (
      <>
        {isPopup ?
          <Backdrop isOpen={isOpen}>
            <TemplateEditorWrapper>
              <CoreEditor/>
              <TemplateEditorFooter>
                <RoundedButton width="79px" kind='secondary' onClick={closeEditor}>Cancel</RoundedButton>
                {!isReadMode && <ButtonWrapper>
                  <RoundedButton width="89px" onClick={exportHtml} disabled={templateEditorStore.updatingTemplate}>{templateEditorStore.updatingTemplate ? 'Updating...' : 'Update'}</RoundedButton>
                </ButtonWrapper>}

              </TemplateEditorFooter>
            </TemplateEditorWrapper>
            {templateEditorStore.isError && <SuccessfulModal
              isOpen={templateEditorStore.isError}
              onClose={() => templateEditorStore.onClearError()}
              title="Oops!"
              subtitle="An error occurred."
              subtitle2="Please try again later."
              zIndex={true}
            />}
          </Backdrop> :
          <CoreEditor/>
        }
      </>
    );
});

export default TemplateEditor