import styled from "styled-components";

export const TabsContainer = styled.div`
  margin: 20px 80px;
`

export const MainContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;
    background: ${(props) => props.theme.colors.primaryWhite};
    padding: 8px 24px;
    border: 1px solid ${props => props.theme.colors.borderGray};
    overflow: hidden;
    max-height: 1.75rem;
    font-size: 0.625em;
`

export const BreadCrumbContainer = styled.div `
    order: 1;
    flex: 0 1 auto;
    align-self: auto;
`

export const ButtonsContainer = styled.div `
  display: flex;
  gap: 10px;

  button:nth-child(2){
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.clearBlue};
    font-weight: 400;
  }
`;

export const MenuContainer = styled.div`
    order: 1;
    flex: 0 1 auto;
    align-self: auto;
`

export const Title = styled.h3`
    margin:0;
`

export const EmailTemplateWrapper = styled.div`
  height: 80vh;
`;