import styled, {css} from "styled-components";

const FilesListContainer = styled.ul`
    padding: 0;
    list-style: none;
`;

const FilesListItem = styled.li`
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.colors.greySeven};
    margin-bottom: 5px;
`;

const FilesTextWrapper = styled.div`
    width: 85%;
    display: flex;
`;

const FilesName = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 75%;
    margin: 0 5px;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center; 
`;

const DragAndDropWrapper = styled.div`
    ${(props) => props.$width && css`
        width: ${props.$width};
    `}
`;

export {
    FilesListContainer,
    FilesListItem,
    FilesTextWrapper,
    FilesName,
    ButtonContainer,
    DragAndDropWrapper
}