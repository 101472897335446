import {Icon} from "../../../../../elements";
import {InputWrapper, InputField, SearchIcon} from "./SearchInput.styled";
import {useEffect, useState} from "react";

const SearchInput = ({ defaultValue = '', onChange }) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onHandleChange = (e) => {
    setValue(e.target.value);
    onChange && onChange(e.target.value);
  }

  return (
    <InputWrapper>
      <InputField value={value} type="text" placeholder="Search" onChange={onHandleChange} />
      <SearchIcon>
        <Icon name={"magnifier"} height={'20px'} width={'20px'}></Icon>
      </SearchIcon>
    </InputWrapper>
  );
}

export default SearchInput;