import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { InputText } from "../../../elements";
import { useStore } from "../../../hooks";
import {FormTitle, FormSubtitle, FormUserWrapper, FormInputContainer} from "../NewUserPage.Styled";

const UserEmailForm = observer(() => {
    const { userStore } = useStore();

    const onHandleChangePhone = useCallback((e) => {
      userStore.page.onChangePhone(e.target.value);
    }, [userStore]);

    const onHandleChangeEmail = useCallback((e) => {
      userStore.page.onChangeEmail(e.target.value);
    }, [userStore]);

    const onBlurPhone = useCallback(() => {
      userStore.page.onValidateTextField('phone');
    }, [userStore]);

    const onBlurEmail = useCallback(() => {
      userStore.page.onValidateTextField('email');
    }, [userStore]);

    return (
        <FormUserWrapper>
            <FormTitle>What a great name!</FormTitle>
            <FormSubtitle>You will need an email and <br/> a phone number</FormSubtitle>
            <FormInputContainer>
                <InputText
                    type="text"
                    value="+1"
                    placeholder="Phone"
                    width="60px"
                    margin="60px 0px 20px 0"
                    disabled
                />
                <InputText
                    type="text"
                    value={userStore.page.phone}
                    placeholder="Phone"
                    onChange={onHandleChangePhone}
                    onBlur={onBlurPhone}
                    width="300px"
                    margin="60px 0 20px 0px"
                    displayError={userStore.page.phoneError}
                    validationError={userStore.page.phoneError}
                />
            </FormInputContainer>
            <FormInputContainer>
              <InputText
                  type="text"
                  value={userStore.page.email}
                  placeholder="Email"
                  onChange={onHandleChangeEmail}
                  onBlur={onBlurEmail}
                  width="380px"
                  margin="20px 0 20px 0"
                  displayError={userStore.page.emailError}
                  validationError={userStore.page.emailError}
              />
            </FormInputContainer>
            
        </FormUserWrapper>
    );
});

export default UserEmailForm;
