import { styled } from "styled-components";

const StyledLabel = styled.label`
  font-weight: 400;
  font-size: ${props => props.theme.fonts.size.b1};
  margin-top: 3px;
  color: ${(props) => props.theme.colors.darkGray };
  cursor: pointer;
  line-height: 19px;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 36px;
    background-color: ${(props) => props.theme.colors.highClearGray };
    border-radius: 8px;
    cursor: pointer;
    gap: 10px;
`;

const StyledRadio = styled.input`
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid ${(props) => props.theme.colors.darkGray };
    border-radius: 50%;
    transition: all 0.1s ease-in-out;
    cursor: pointer;

    &:checked {
        border: 2px solid ${(props) => props.theme.colors.clearBlue };
    }

    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        margin: 2px;
    }

    &:checked::after {
        background-color: ${(props) => props.theme.colors.clearBlue };
    }
`;

export {
    StyledLabel,
    Wrapper,
    StyledRadio
}