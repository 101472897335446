import React, { useEffect, useState } from "react";
import Widget from "./Widget";
import { useStore } from "../../../../../hooks";
import { observer } from "mobx-react-lite";
import {
  InfoList,
  SubTitle,
  CarouselContainer,
  CarouselButton,
  DataAvaliableWrapper,
} from "./Widget.styled";
import { Icon } from "../../../../../elements";
import dayjs from "dayjs";
import Spinner from "../../../../../components/common/Spinner";
import CheckIcon from "./CheckIcon";
import FormattedDate from "./FormattedDate";

const formatMileage = (mileage) => {
  return mileage?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const GarageCard = observer(() => {
  const { unifiedCustomerProfileStore } = useStore();
  const [currentVehicleIndex, setCurrentVehicleIndex] = useState(0);

  const garage = unifiedCustomerProfileStore.garageInfo || [];

  const handlePrevVehicle = () => {
    setCurrentVehicleIndex((prevIndex) => {
      const nextIndex = prevIndex === 0 ? garage.length - 1 : prevIndex - 1;
      unifiedCustomerProfileStore.setSelectedCar(nextIndex, true);
      return nextIndex;
    });
  };

  const handleNextVehicle = () => {
    setCurrentVehicleIndex((prevIndex) => {
      const nextIndex = prevIndex === garage.length - 1 ? 0 : prevIndex + 1;
      unifiedCustomerProfileStore.setSelectedCar(nextIndex, true);
      return nextIndex;
    });
  };

  const currentVehicle = garage[currentVehicleIndex] || {};

  return (
    <Widget title="Garage" height={garage.length === 0 ? "auto" : "291px"}>
      {unifiedCustomerProfileStore.isLoading ? (
        <Spinner />
      ) : (
        <>
          {garage.length === 0 ? (
            <DataAvaliableWrapper id="dataAvaliableWrapper">
              <p>No data available</p>
              <p>
                No data is available for this card at the moment. Please check
                again later.
              </p>
              <p>
                <Icon name={"noDataAvaliable"} width={"70px"} height={"68px"} />
              </p>
            </DataAvaliableWrapper>
          ) : (
            <CarouselContainer>
              <CarouselButton onClick={handlePrevVehicle}>
                <Icon name="backWardArrow" width="10px" height="16px" />
              </CarouselButton>
              <InfoList>
                <li>
                  <SubTitle>Vehicle/Model: </SubTitle>
                  <span>
                    {currentVehicle.vehicle_make
                      ? `${currentVehicle.vehicle_make}/${currentVehicle.vehicle_model}`
                      : currentVehicle.vehicle_model}
                  </span>
                </li>
                <li>
                  <SubTitle>Year:</SubTitle>
                  <span>{currentVehicle.vehicle_model_year}</span>
                </li>
                <li>
                  <SubTitle>Model Description:</SubTitle>
                  <span>{currentVehicle.model_description}</span>
                </li>
                <li>
                  <SubTitle>Estimated mileage:</SubTitle>
                  <span>{formatMileage(currentVehicle.estimated_milage)}</span>
                </li>
                <li>
                  <SubTitle>Last record mileage:</SubTitle>
                  <span>
                    {formatMileage(currentVehicle.last_recorded_mileage)}
                  </span>
                </li>
                <li>
                  <SubTitle>Last service:</SubTitle>
                  <FormattedDate date={currentVehicle.last_service} />
                </li>
                <li>
                  <SubTitle>Purchase date:</SubTitle>
                    <FormattedDate date={currentVehicle.purchase_date} />
                </li>
                <li>
                  <SubTitle>Purchase used:</SubTitle>
                    <CheckIcon value={currentVehicle.purchase_used} />
                </li>
                <li>
                  <SubTitle>VIN:</SubTitle>
                  <span>{currentVehicle.vin_no}</span>
                </li>
              </InfoList>
              <CarouselButton onClick={handleNextVehicle}>
                <Icon name="forWardArrow" width="10px" height="16px" />
              </CarouselButton>
            </CarouselContainer>
          )}
        </>
      )}
    </Widget>
  );
});

export default GarageCard;
