import { action, makeObservable, observable, flow, toJS } from "mobx";
import { API } from "aws-amplify";
import { 
  getAgent, putAgent
} from "../../graphql/queries";

import ViewAgentStore from "./pages/ViewAgentStore";
import NewAgentStore from "./pages/NewAgentStore";

class AgentStore {
  isError = false;
  isLoading = false;
  isDuplicating = false;

  agents = [];
  selectedAgent = undefined;
  account = '';

  viewAgentStore = new ViewAgentStore();
  newAgentStore = new NewAgentStore();

  constructor() {
    makeObservable(this, {
      isError: observable,
      isLoading: observable,
      isDuplicating: observable,
      agents: observable,
      selectedAgent: observable,
      account: observable,
      viewAgentStore: observable,
      newAgentStore: observable,
      onChangeAccount: action,
      onSelectedAgentById: action,
      onSelectedAgent: action,
      fetchGetAgent: flow,
      duplicateAgent: flow
    });
  }

  onChangeAccount = (value) => {
    this.account = value;
    this.newAgentStore.onChangeAccount(value);
    this.fetchGetAgent();
  }

  onSelectedAgentById = (value) => {
    const agent = this.agents.find(agent => agent.id === value);
    this.selectedAgent = agent;
  }

  onSelectedAgent = (value) => {
    this.selectedAgent = value;
  }

  *fetchGetAgent() {
    try {
        this.isLoading = true;
        const response = yield API.graphql({
            query: getAgent,
            variables: {
                input: {
                    customer_id: this.account
                },
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        const data = JSON.parse(response.data?.getAgent?.body);
        this.agents = data;
    } catch (error) {
        this.isError = true;
    } finally {
        this.isLoading = false;
    }
  }

  *duplicateAgent() {
    this.isDuplicating = true;
    const { original } = this.selectedAgent
    try {
      const input = {
        channels: original.channels,
        customer_id: original.customer_id,
        human_instructions: original.human_instructions,
        knowledge_base: JSON.parse(JSON.stringify(original.knowledge_base)),
        name: `${original.name} - copy`,
        prompt: original.prompt,
        id: "",
        job_title: original.job_title,
        persona: JSON.stringify(original.persona),
        tone: toJS(original.tone),
        extended_answer: original.extended_answer,
        scope: original.scope,
        actions: original.actions,
        add_to_concierge: false,
        use_prompter: false,
        conversation_starters: original.conversation_starters,
        domain: ""
      };

      yield API.graphql({
        query: putAgent,
        variables: { input: input },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      yield this.fetchGetAgent()

    } catch (err) {
      this.isError = true;
    } finally {
      this.isDuplicating = false;
    }
  }
}

export default AgentStore;