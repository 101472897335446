import styled from 'styled-components';

const SearchWrapper = styled.div`
  width: 450px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export {
  SearchWrapper,
  HeaderButtonWrapper
}