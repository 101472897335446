import styled from 'styled-components';

const ViewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-inline: 15px;
    padding-block: 8px;
    height: calc(100vh - 100px);
    gap: 24px;
`;

const HeaderViewWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 60px;
`;

const ContentViewWrapper = styled.div`
    flex: 1;
`;

export {
  ViewWrapper,
  HeaderViewWrapper,
  ContentViewWrapper
};
