import styled from "styled-components";
import { DefaultTheme as theme } from "../../../../theme";

export const RulesGroup = styled.div`
  border-radius: 8px;
  border: 1px solid ${theme.colors.silverGrayTwo};
  width: 100%;
  padding: 12px;
  margin-block-end: 5px;
  overflow-wrap: break-word;
  word-wrap: break-word; 
  word-break: break-word;
  white-space: normal;
`;

export const LogicalOp = styled.div`
  display: block;
  width: 45px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  margin-block: 5px;
  text-align: center;
  padding: 10px;
  background-color: ${theme.colors.silverGrayTwo};
`;
