import { styled } from "styled-components";

const StyledLabel = styled.label`
  font-weight: 500;
  font-size: ${props => props.$fontSize || '26px'};
  margin-right: 8px;
  margin-top: 3px;
  color: ${(props) => props.checked ? '#4B4B4B' : '#949494'};
  cursor: pointer;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    width: ${props => props.$width || '220px'};
    height: 50px;
    background-color: ${(props) => props.checked ? '#D9D9D9' : '#EEEEEE'};
    border-radius: 8px;
    cursor: pointer;
`;

const StyledRadio = styled.input`
    appearance: none;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border: 9px solid ${(props) => props.checked ? '#4B4B4B' : '#D9D9D9'};
    background-color: ${(props) => props.checked ? '#4B4B4B' : '#D9D9D9'};
    border-radius: 50%;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
`;

export {
    StyledLabel,
    Wrapper,
    StyledRadio
}