import styled from 'styled-components';

const GenericListContainer = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    width: ${props => props.width || '50%'};
    overflow-y: auto;
    height: ${props => props.height || '100%'};
    border-radius: 8px;
    box-sizing: border-box;

    textarea {
        flex-grow: 2;
        height: 30px;
        border-radius: 8px;
        font-size: ${props => props.fontSize || '14px'};
        font-weight: ${props => props.fontWeight || '600'};
        padding: 12px;
        resize: none;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow: hidden;
        box-sizing: border-box;
        line-height: 1.4;
        min-height: 50px;
        height: auto;
        border: ${props => props.textareaBorder || '1px solid #d3d3d3'};
    }

    textarea:focus {
        outline: none;
        border: 1px solid #a0a0a0;
    }
`;

const GenericListItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
`;

const Header = styled(GenericListItem)`
    align-items: center;
`;

const Title = styled.p`
    display: inline-block;
    font-size: ${props => props.theme.fonts.size.s1};
    font-weight: 600;
    margin: 0;
`;

const AddButton = styled.span`
    cursor: pointer;
    border: 2px solid ${props => props.theme.colors.clearGray};
    border-radius: 8px;
    gap: 10px;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledIconButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 5px;

    &:hover {
        background-color: transparent;
    }
`;

export {
    GenericListContainer,
    GenericListItem,
    Header,
    Title,
    AddButton,
    StyledIconButton
};
