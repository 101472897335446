import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../../hooks';
import CategorySection from '../../../../../elements/CategorySectionComponent/CategorySectionComponent';
import Tabs from '../../../../../elements/v2/Tabs/Tabs';
import FeatureLibraryTab from '../FeatureLibraryTab/FeatureLibraryTab';
import SearchInput from '../../../../../elements/SearchInput/SearchInput';
import { RoundedButton, IconButton } from "../../../../../elements";
import {
    ModalBackground,
    ModalContainer,
    CloseButton,
    ModalTitle,
    HeaderContent,
    SearchBarContainer,
    ContentContainer,
    FeaturesContainer
} from './FeatureSelectionModal.styled';
import AvailableFeature from '../AvailableFeature/AvailableFeature';

const FeatureSelectionModal = observer(({ onClose }) => {
    const { featureStore } = useStore();
    const [selectedCategory, setSelectedCategory] = useState(featureStore.categories[0] || '');
    const [searchTerm, setSearchTerm] = useState('');

    
    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        featureStore.filterFeatures(value);
    };

    const tabs = [
        {
            label: 'Feature Library',
            content: (
                <FeatureLibraryTab searchTerm={searchTerm} />
            ),
        },
        {
            label: 'Available features',
            content: (
                <AvailableFeature searchTerm={searchTerm} />
            ),
        },
    ];

    return (
        <ModalBackground>
            <ModalContainer>
                <CloseButton onClick={onClose}>
                    <IconButton icon="close" width="10px" height="10px" onClick={onClose} />
                </CloseButton>
                <br />
                <HeaderContent>
                    <ModalTitle>Features</ModalTitle>
                    <SearchBarContainer>
                        <SearchInput
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <RoundedButton margin="5px" width='60px' height="40px" fontSize="12px"> Save </RoundedButton>
                    </SearchBarContainer>
                </HeaderContent>

                <ContentContainer>
                    <CategorySection
                        categories={featureStore.categories}
                        selectedCategory={selectedCategory}
                        onSelectCategory={setSelectedCategory}
                    />
                    <FeaturesContainer>
                        <Tabs tabs={tabs} showBorder={true} height="80vh" color="highClearGray" />
                    </FeaturesContainer>
                </ContentContainer>
            </ModalContainer>
        </ModalBackground>
    );
});

export default FeatureSelectionModal;
