import React, {useState} from 'react';
import {
	DropdownSection,
	DropdownTitle,
	DropdownDescription,
	RemoveButton,
	PermissionModalCloseButton,
	PermissionModalContainer
} from './ShareAgentModal.styled';
import { observer } from 'mobx-react';
import { useStore } from "../../../../../hooks";

const PermissionModal = ({ user, onClose, onRemove, position }) => {
	const { authStore, editAgent } = useStore();

	const handlePermissionChange = (user) => {

		const authorizer = authStore.userData.id;
		const newUser = {
			id: user.user,
			authorizer: authorizer,
			policy: ["get", "chat"]
		};

		editAgent.setUsersResources(newUser);
	  editAgent.setChosenPolicy("Chat");
		onClose();
	};

 const removeUserResource = (user) => {
    editAgent.removeUserResource(user.user);
    onRemove(user);
    onClose();
  }

	return (
		<div onClick={onClose}>
			<PermissionModalContainer
				onClick={e => e.stopPropagation()}
				position={position}
			>
				<PermissionModalCloseButton onClick={onClose}>
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M15 5L5 15M5 5L15 15"
							stroke="currentColor"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</PermissionModalCloseButton>
				<DropdownSection onClick={() => handlePermissionChange(user)}>
					<DropdownTitle>Chat</DropdownTitle>
					<DropdownDescription>
						Users can only chat with this agent
					</DropdownDescription>
				</DropdownSection>
				<RemoveButton onClick={() => removeUserResource(user)}>
					Remove
				</RemoveButton>
			</PermissionModalContainer>
		</div>
	);
};

export default observer(PermissionModal);
