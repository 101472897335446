import { IconButton } from "../../../../elements";
import {
    AgentTypeRadioButton,
    AgentTypeModalContainer,
    AgentTypeModalTitle,
    RadioButtonContainer,
    AgentModalHeader
} from "./AgentTypeModal.styled";

const AgentTypeModal = ({ left, top, onHandleRadioChange, close }) => {

    return (
        <AgentTypeModalContainer left={left} top={top} >
            <AgentModalHeader>
                <AgentTypeModalTitle>
                    What type of agent do you want to create?
                </AgentTypeModalTitle>
                <IconButton icon="close" width="14px" height="14px"  onClick={close}/>
            </AgentModalHeader>
            <RadioButtonContainer>
                <AgentTypeRadioButton
                    label="Manager" value="manager" onChange={(value) => onHandleRadioChange(value)} checked={false}
                />
                <AgentTypeRadioButton
                    label="Agent" value="agent" onChange={onHandleRadioChange} checked={false}
                />
            </RadioButtonContainer>
        </AgentTypeModalContainer>
    )
};

export default AgentTypeModal;