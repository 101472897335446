import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-top: 10px;
  overflow: hidden;
`;

export const PageInfo = styled.div`
  font-size: 14px;
  color: #666;
`;

export const PageControls = styled.div`
  display: flex;
  align-items: center;
`;

export const Select = styled.select`
  margin: 0 10px;
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
`;

export const Button = styled.button`
  border: 1px solid #ccc;
  padding: 5px 10px;
  margin: 0 2px;
  background-color: ${(props) => (props.active ? props.theme.colors.greySeven : props.theme.colors.white)};
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
