import React from "react";
import { observer } from 'mobx-react';
import {
    ThreeColumnGrid,InfoBoxWrapper, Title,
    DividerLine, TruncatedDescription, CustomImage, CustomComponent1,
} from "./AgentTabs.styled";
import LogoAnn from "../../../../../elements/icon/assets/logo_ann_customer.svg";
import { getTeams as teams } from "./../../consts";

const DraftTab = observer(({handleOpenModal}) => {
    const draftTeams = teams.filter(assistant => assistant.visibility === 'draft');

    return (
        <>
            <DividerLine />
            {draftTeams && draftTeams.length > 0 && (
                <ThreeColumnGrid>
                    {draftTeams.map((assistant, index) => (
                        <CustomComponent1 key={index} onClick={() => handleOpenModal(assistant)}>
                            <CustomImage src={LogoAnn} alt="Ann Logo" />
                            <InfoBoxWrapper>
                                <Title>{assistant.name}</Title>
                                <TruncatedDescription>{assistant.description1}</TruncatedDescription>
                            </InfoBoxWrapper>
                        </CustomComponent1>
                    ))}
                </ThreeColumnGrid>
            )}

        </>
    );
});

export default DraftTab;