import { useState, useEffect } from "react";
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import { useStore } from "../../../../hooks";
import {
    Avatar,
    ContactInfo,
    Email,
    Header,
    HeaderContactInfo,
    LastVisit,
    Name,
    Telephone,
    Title,
    UserContainer,
    UserInfoLine,
    Warranty,
    Value,
    SegmentTag
} from "./UserInfo.styled";
import defaultImage from "../../../../elements/assets/Ellipse 6.png";
import { IconButton } from "../../../../elements";
import EditUserInfoModal from "./EditUserInfoModal";
import { Tooltip } from "@mui/material";

const UserInfo = observer(() => {
    const { unifiedCustomerProfileStore } = useStore();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [ucp, setUCP] = useState({});

    useEffect(() => {
        if (unifiedCustomerProfileStore.ucp) {
            setUCP(unifiedCustomerProfileStore.ucp);
        }
    }, [unifiedCustomerProfileStore.ucp]);

    const formatEmail = (email) => {
        if (email && email.length > 20) {
            return `${email.substring(0, 20)}...`;
        }
        return email;
    };

    return (
        <UserContainer> 
            <Header>
                <Avatar src={defaultImage} />
                <HeaderContactInfo>
                    <div style={{display: 'flex'}}>
                        <Name>{ucp.name}</Name>
                        <IconButton
                            icon={'editPencil'}
                            width={'26px'}
                            height={'26px'}
                            onClick={() => setOpenEditModal(true)} />
                    </div>
                    <Telephone>{ucp.phone_number}</Telephone>
                    <Tooltip title={ucp.email}>
                        <Email>{ucp.email ? formatEmail(ucp.email) : 'No email available'}</Email>
                    </Tooltip>
                    <LastVisit>Last visit: {ucp.last_activities_date ? format(new Date(ucp.last_activities_date), 'MM-dd-yyyy') : ''}</LastVisit>
                </HeaderContactInfo>
            </Header>
            <ContactInfo>
                <UserInfoLine>
                    <Title>Date of Birth:&nbsp;</Title>
                    <Value>{ucp.birth_date ? format(new Date(ucp.birth_date), 'MM-dd-yyyy') :''}</Value>
                </UserInfoLine> 
                <UserInfoLine>
                    <Title>Last Engagement:&nbsp;</Title>
                    <Value>{ucp.last_engagement_date ? format(new Date(ucp.last_engagement_date), 'MM-dd-yyyy HH:mm') :''}</Value>
                </UserInfoLine>
                <UserInfoLine>
                    <Title>Next Best Action (NBA):&nbsp;</Title>
                    <Value>{ucp.next_bestaction_date ? format(new Date(ucp.next_bestaction_date), 'MM-dd-yyyy HH:mm') :''}</Value>
                </UserInfoLine>
                <UserInfoLine>
                    <Title>Score:&nbsp;</Title>
                    <Value>{ucp.score}</Value>
                </UserInfoLine>
                <UserInfoLine>
                    <Title>Segment of Clients:&nbsp;</Title>
                    <SegmentTag>{ucp.client_segment}</SegmentTag>
                </UserInfoLine>
            </ContactInfo>
            <EditUserInfoModal
                isOpen={openEditModal}
                onClose={() => setOpenEditModal(false)}
            />
        </UserContainer>
    );
});

export default UserInfo;