import React, { useState, useMemo, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { SuccessfulModal } from "../../../../elements";
import { useStore } from "../../../../hooks";
import { TopToolbar } from "../../../../elements/sharedStyles/TopToolbar";
import { useMediaQueries } from "../../../../utils/mediaQueries";
import NotificationItem from "../components/NotificationItem/NotificationItem";
import { HeaderContainer, SmallCheckbox, HeaderText, TableContainer } from "./NotificationTab.styled";

const NotificationTab = observer(({ filters }) => {
    const { authStore, notificationStore } = useStore();
    const { selectedAccount } = authStore;
    const { isMobile, isTablet, isDesktop } = useMediaQueries();

    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([{ id: 'date', desc: true }]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [isLoading, setIsLoading] = useState(false);
    const [selectedNotifications, setSelectedNotifications] = useState([]);

    const markAsCompleted = useCallback((id) => {
        notificationStore.setCompleted(id);
    }, [notificationStore]);

    const loadData = useCallback(async () => {
        if (selectedAccount) {
            setIsLoading(true);
            try {
                await notificationStore.getNotifications({
                    customer: selectedAccount,
                    pageIndex: pagination.pageIndex,
                    pageSize: pagination.pageSize,
                    sortBy: sorting,
                    search: globalFilter,
                });
            } catch (error) {
                notificationStore.setError(true);
            } finally {
                setIsLoading(false);
            }
        }
    }, [selectedAccount, pagination, sorting, globalFilter, notificationStore]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        notificationStore.filterNotifications(filters);
    }, [filters, notificationStore]);

    const filteredNotifications = useMemo(() => notificationStore.filteredNotifications, [notificationStore.filteredNotifications]);

    const handleSelectAll = useCallback((event) => {
        setSelectedNotifications(event.target.checked ? filteredNotifications.map(notification => notification.id) : []);
    }, [filteredNotifications]);

    const handleSelectNotification = useCallback((id) => {
        setSelectedNotifications((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter(selectedId => selectedId !== id)
                : [...prevSelected, id]
        );
    }, []);

    const columns = useMemo(() => [
        {
            accessorKey: "message",
            header: "Notification",
            Header: () => (
                <HeaderContainer>
                    <SmallCheckbox
                        checked={selectedNotifications.length === filteredNotifications.length}
                        indeterminate={
                            selectedNotifications.length > 0 && selectedNotifications.length < filteredNotifications.length
                        }
                        onChange={handleSelectAll}
                    />
                    <HeaderText>Notifications</HeaderText>
                </HeaderContainer>
            ),

            Cell: ({ row }) => {
                const { id, title, message, date, isCompleted, channel, status } = row.original;

                return (
                    <NotificationItem
                        title={title}
                        message={message}
                        date={date}
                        isCompleted={isCompleted}
                        channel={channel}
                        status={status}
                        selected={selectedNotifications.includes(id)}
                        onSelect={() => handleSelectNotification(id)}
                        onMarkAsCompleted={() => markAsCompleted(id)}
                    />
                );
            },
            minSize: 200,
            maxSize: 400,
        },
    ], [selectedNotifications, filteredNotifications, handleSelectAll, markAsCompleted, handleSelectNotification]);

    const table = useMaterialReactTable({
        columns,
        data: filteredNotifications,
        manualSorting: true,
        manualGlobalFilter: true,
        rowCount: notificationStore.totalRowCount || 0,
        initialState: { sorting, density: 'compact' },
        enableStickyHeader: true,
        enableStickyFooter: false,
        enableTopToolbar: true,
        enableColumnHeaders: true,
        muiTopToolbarProps: { component: TopToolbar },
        muiSearchTextFieldProps: {
            variant: 'outlined',
            placeholder: 'Search',
            InputProps: { style: { color: 'white' } },
        },
    });

    return (
        <TableContainer>
            <MaterialReactTable table={table} />
            {notificationStore.isError && (
                <SuccessfulModal
                    isOpen={notificationStore.isError}
                    onClose={notificationStore.onClearError}
                    title="Oops!"
                    subtitle="An error occurred."
                    subtitle2="Please try again later."
                />
            )}
        </TableContainer>
    );
});

export default NotificationTab;
