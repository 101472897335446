import { useEffect, useState } from "react";
import {
    GenericListContainer,
    GenericListItem,
    Header,
    Title,
    AddButton
} from "./GenericList.styled";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IconButton } from "@mui/material";
import CircleAddIcon from '../../icon/assets/circle_add_icon.svg'

const GenericList = ({title, listItems=['', '', ''], setListItems, newItem, removeItem, placeholder}) => {
    const [displayAddButton, setDisplayAddButton] = useState(true);

    const onClickRemove = (idx) => {
        removeItem(idx);
    }

    const onClickAdd = () => {
        newItem('');
    }

    useEffect(() => {
        if(listItems.length < 4){
            setDisplayAddButton(true);
        } else if(listItems.length >= 4){
            setDisplayAddButton(false);
        }

    }, [listItems])

    return (
        <> 
            <Header>
                <Title>{title}</Title>
                {displayAddButton && <AddButton onClick={onClickAdd}>
                    <img src={CircleAddIcon} width={24} height={24}/>
                </AddButton>}
            </Header>
            <GenericListContainer>
                {listItems && listItems.map((item, idx) => 
                    <GenericListItem key={idx}>
                        <input value={item} placeholder={placeholder} onChange={e => {
                            const currentList = [...listItems];
                            currentList[idx] = e.target.value;
                            setListItems(idx, e.target.value)
                        }}/>
                        <IconButton onClick={() => onClickRemove(idx)}> <CloseRoundedIcon/></IconButton>
                    </GenericListItem>  
                )}
            </GenericListContainer>
        </>
    )
}

export default GenericList