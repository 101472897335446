import React, { useState } from "react";
import { observer } from 'mobx-react';
import { useStore } from "../../../../../hooks";
import { AgentViewContainer } from "./AgentTabs.styled";
import { Loading } from "../../../../../components/loading";
import ModalAgentsDetail from "../../components/ModalAgentsDetail/ModalAgentsDetail";
import Tabs from "../../../../../elements/v2/Tabs/Tabs"
import PublishedTab from "./PublishedTab";
import DraftTab from "./DrafTab";
import LogoAnn from "../../../../../elements/icon/assets/logo_ann_customer.svg";

const AgentsTabs = observer(() => {
    const { agentStore } = useStore();
    const { viewAgentStore } = agentStore;

    const [isModalOpen, setModalOpen] = useState(false);

    const handleOpenModal = (assistant) => {
        const starters = assistant.conversation_starters.filter((starter)=>starter);
        const content = {
            id: assistant.id,
            image: LogoAnn,
            name: assistant.name,
            type: "Agent",
            description1: assistant.job_title,
            description2: assistant.prompt,
            conversations: 4,
            members: 1,
            starters: [],
            knowledgeBase: assistant.knowledge_base.map((filePath) =>
                filePath.replace("s3://", "").split("/").splice(-1).pop()),
            conversation_starters: starters || [],
            visibility: "draft",
            actions: assistant.action || [],
            original: assistant
        }
        agentStore.onSelectedAgent(content);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const tabs = [
        {
          label: 'Published',
          content: (
            <PublishedTab handleOpenModal={handleOpenModal} />
          ),
        },
        // {
        //   label: 'Draft',
        //   content: (
        //     <DraftTab handleOpenModal={handleOpenModal}/>
        //   ),
        // },
    ];

    return (
        <>
            {viewAgentStore.isLoading && <Loading />}
            {!viewAgentStore.isLoading && (  
                <AgentViewContainer>
                    <Tabs tabs={tabs} />
                </AgentViewContainer>
            )}
            {agentStore.selectedAgent && (
                <ModalAgentsDetail open={isModalOpen} onClose={handleCloseModal} />
            )}
        </>
    );
});

export default AgentsTabs;