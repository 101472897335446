import React from "react";
import { Route, Routes } from "react-router-dom";
import AccountList from "./CustomersList";
import CustomerCreate from "./CustomerCreate";
import CreateCustomerStepper from "./CreateCustomer/CreateCustomerStepper";

const CustomerPage = () => {
  return (
    <Routes>
      <Route index element={<AccountList />} />
      <Route path="add" element={<CreateCustomerStepper />} />
    </Routes>
  );
};

export default CustomerPage;

