import useStore from "../../../../../../hooks/useStore";
import PermissionCard from "../PermissionCard/PermissionCard";
import PermissionNavbar from "../PermissionNavbar/PermissionNavbar";
import { observer } from "mobx-react";
import { 
    PermissionsWrapper, 
    PermissionNavbarWrapper,
    PermissionsCardsWrapper,
    UserInfoTag
} from "./PermissionsStep.styled";


const PermissionsStep = observer(() => {

    const { newUser, permissionsSetupStore } = useStore();

    return (
        <PermissionsWrapper>
            <PermissionNavbarWrapper>
                <h1>Permissions</h1>
                <PermissionNavbar data={permissionsSetupStore.initialPermissionsCatalog}/>
            </PermissionNavbarWrapper>
            <PermissionsCardsWrapper>
                <UserInfoTag>
                    <p>{newUser.firstName}</p>
                    <p>{newUser.email}</p>
                </UserInfoTag>
                <div>
                    <div>
                        <PermissionCard data={permissionsSetupStore.permissionsCatalog} reviewMode={false}/>
                    </div>
                </div>
            </PermissionsCardsWrapper>
        </PermissionsWrapper>
    );
})

export default PermissionsStep