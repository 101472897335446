import React, { createContext, useState } from "react";

// Create a context for the sidebar state
export const SidebarContext = createContext();

// SidebarProvider component to manage and provide sidebar state
export const SidebarProvider = ({ children }) => {
  // State to track whether the sidebar is open or closed
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Function to toggle the sidebar state
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Provide the sidebar state and toggle function to child components
  return (
    <SidebarContext.Provider value={{ isSidebarOpen, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};
