import { useNavigate } from 'react-router-dom';
import {useCallback, useEffect, useState} from "react";
import { observer } from 'mobx-react';
import {useStore} from "../../hooks";
import AgentNameForm from "./components/AgentNameForm"
import AgentJobTitleForm from "./components/AgentJobTitleForm"
import AgentToneForm from "./components/AgentToneForm"
import AgentAnswerExtensionForm from "./components/AgentAnswerExtensionForm"
import AgentInstructionsForm from "./components/AgentInstructionsForm"
import AgentFilesForm from "./components/AgentFilesForm"
import AgentCompletedModal from "./components/AgentCompletedModal"
import { NewAgentWrapper } from "./NewAgentPage.styled"
import DefaultStepper from "../../elements/stepper/DefaultStepper"
import BrainImage from '../../elements/icon/assets/brain.png';
import AgentScopeForm from './components/AgentScopeForm';
import AgentChannelForm from './components/AgentChannelForm';

const NewAgentPage = observer(() => {
    const navigate = useNavigate();

    const { newAgent, authStore } = useStore();
    const { selectedAccount, isCustomerAdmin, isSuperAdmin } = authStore;

    const [viewCompleteModal, setViewCompleteModal] = useState(false);

    useEffect(() => {
        if(!isCustomerAdmin && !isSuperAdmin){
            navigate('/');
        }
        newAgent.onClearNewAgent();
        newAgent.onChangeAccount(selectedAccount.id);
    }, [selectedAccount]);

    const onHandleCancel = useCallback(() => {
        navigate('/');
    }, []);

    const onFinishStepper = useCallback(() => {
        newAgent.onCompleteCreation(() => setViewCompleteModal(true));
    }, [newAgent]);

    return (
        <NewAgentWrapper>
            <img src={BrainImage} width={185} height={135}/>
            <DefaultStepper onFinish={onFinishStepper} onCancel={onHandleCancel} status={newAgent.isLoading ? 'loading' : newAgent.isError ? 'error' : 'idle'} stepperActionsWidth='400px' stepperActionsMt='60px'>
                <DefaultStepper.Step isComplete={() => newAgent.onValidateTextField('name')} isBack={false} validationErrorLabel="Please enter a name">
                    <AgentNameForm/>
                </DefaultStepper.Step>

                <DefaultStepper.Step isComplete={() => newAgent.onValidateTextField('jobTitle')} validationErrorLabel="Please enter a job title.">
                    <AgentJobTitleForm/>
                </DefaultStepper.Step>

                <DefaultStepper.Step isComplete={() => newAgent.onValidateTextField('scope')} validationErrorLabel="Please choose a scope.">
                    <AgentScopeForm/>
                </DefaultStepper.Step>

                <DefaultStepper.Step isComplete={() => newAgent.onValidateObjectField('tones')} validationErrorLabel="Please choose at least one tone.">
                    <AgentToneForm/>
                </DefaultStepper.Step>

                <DefaultStepper.Step isComplete={() => newAgent.onValidateObjectField('answerExtension')} validationErrorLabel="Please choose an answer extension.">
                    <AgentAnswerExtensionForm/>
                </DefaultStepper.Step>

                <DefaultStepper.Step isComplete={() => newAgent.onValidateObjectField('channels')} validationErrorLabel="Please select a channel">
                    <AgentChannelForm />
                </DefaultStepper.Step>

                <DefaultStepper.Step isComplete={() => newAgent.onValidateObjectField('instructions')} validationErrorLabel="Please enter the instructions">
                    <AgentInstructionsForm/>
                </DefaultStepper.Step>

                <DefaultStepper.Step labelNext="Complete" validationErrorLabel = "An error occurred while creating the agent! Please try again later.">
                    <AgentFilesForm/>
                </DefaultStepper.Step>

            </DefaultStepper>
            {viewCompleteModal && <AgentCompletedModal displayFooter={false}/>}
        </NewAgentWrapper>
    );
});

export default NewAgentPage;
