import { SelectorContentName } from './AgentTeamSelector.styled';

const AgentTeamName = ({ teamName="", agentName }) => {
  return (
    <>
      {(!!agentName) && <SelectorContentName>{agentName} / {teamName}</SelectorContentName>}
      {(!agentName) && <SelectorContentName>New Agent</SelectorContentName>}
    </>
  );
}

export default AgentTeamName;