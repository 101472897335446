import React, { useState, useEffect } from 'react';
import {
  ModalOverlay,
  ModalContent,
  CustomSelectWrapper,
  Dropdown,
  SearchInputWrapper,
  SearchInput,
  SearchIcon,
  GroupListContainer,
  GroupList,
  GroupItem,
  SelectedGroups,
  SelectedGroup,
  CloseIcon,
  LabelGroup,
  AddButton,
  ButtonContainer,
  CloseButton
} from './AddMemberModal.styled';
import { IconButton, Icon } from '../../../../../elements';
import { observer } from 'mobx-react-lite';
import useStore from '../../../../../hooks/useStore';

const AddMemberModal = observer(({ onAddGroups, onClose }) => {
  const { listRoleStore } = useStore();
  const { users, fetchUsers } = listRoleStore;
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGroups, setSelectedGroups] = useState([]);

  useEffect(() => {
    fetchUsers(searchTerm);
  }, [searchTerm, fetchUsers]);

  const handleSelectGroup = (group) => {
    if (!selectedGroups.includes(group.name)) {
      setSelectedGroups([...selectedGroups, group.name]);
    }
  };

  const handleRemoveGroup = (group) => {
    setSelectedGroups(selectedGroups.filter(item => item !== group));
  };

  const handleAddGroups = () => {
    onAddGroups(selectedGroups);
    setSelectedGroups([]);
  };

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={handleCloseModal}>×</CloseButton>
        <CustomSelectWrapper>
          <Dropdown>
            <SearchInputWrapper>
              <SearchInput
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <SearchIcon />
            </SearchInputWrapper>
            <SelectedGroups>
              {selectedGroups.map((group, index) => (
                <SelectedGroup key={index}>
                  <span>{group}</span>
                  <CloseIcon>
                    <IconButton icon="closeLight" height={'10px'} width={'10px'} onClick={() => handleRemoveGroup(group)} />
                  </CloseIcon>
                </SelectedGroup>
              ))}
            </SelectedGroups>
            <LabelGroup>
              Users
            </LabelGroup>
            <GroupListContainer>
              <GroupList>
                {users.map((user) => (
                  <GroupItem key={user.id} onClick={() => handleSelectGroup(user)}>
                    <Icon name={"user"} height={'20px'} width={'20px'} />
                    <span>{user.name}</span> - <span>{user.email}</span>
                  </GroupItem>
                ))}
              </GroupList>
            </GroupListContainer>
            <ButtonContainer>
              <AddButton onClick={handleAddGroups}>Add</AddButton>
            </ButtonContainer>
          </Dropdown>
        </CustomSelectWrapper>
      </ModalContent>
    </ModalOverlay>
  );
});

export default AddMemberModal;
