import React, { useEffect, useCallback } from 'react';
import { useStore } from "../../../hooks";
import { FormCampaignWrapper, FormTitle, FormSubtitle } from '../ContactFlowPage.Styled';
import CampaignDragAndDropForm from './ContactFlowDragAndDop';
import FilesImage from '../../../elements/icon/assets/Group 292.svg';


const CampaignFileForm = ({ onNextStep }) => {  

    const { contactStore } = useStore();

    const filePaths = [
        "/src/store/campaigns/files/contacts-one.xlsx",
        "/src/store/campaigns/files/contacts-two.xlsx"
    ];

    const handleFileSelect = useCallback((e) => {
        
    }, [contactStore, onNextStep]);
    
    useEffect(() => {
        contactStore.getFileNamesFromPaths(filePaths);
    }, [contactStore]);

    return (
        <FormCampaignWrapper>
            <img src={FilesImage} width={185} height={135} />            
            <br></br>
            <FormSubtitle>
                Upload your contact list <br/> All <b>.csv, xlsx and .xls</b> files types are supported
            </FormSubtitle>
            <CampaignDragAndDropForm />
        </FormCampaignWrapper>
    );
};

export default CampaignFileForm;
