import React from 'react';
import { CardWrapper, CardIcon, CardTitle, CardDescription } from './CardComponent.styled';

const CardComponent = ({ iconName, title, description, onClick, selected }) => (
    <CardWrapper onClick={onClick} $selected={selected}>
        <CardIcon name={iconName} width="48px" height="48px" />
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
    </CardWrapper>
);

export default CardComponent;
