import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  PopupContentContainer,
  Curtain,
  HeaderContainer,
} from "./Popup.styled";
import usePopupWindow from "../../../utils/usePopupWindow";

const Popup = ({ header, content }) => {
  const headerRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const { popupRight, popupHeight } = usePopupWindow(
    headerRef.current,
    isActive
  );

  const handleEsc = (event) => {
    if (event.key === "Escape") setIsActive(false);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  const defaultProps = useMemo(() => ({
    isActive,
    setIsActive,
  }));

  const HeaderComponent = useMemo(
    () => React.cloneElement(header, defaultProps),
    [header, defaultProps]
  );

  const ContentComponent = useMemo(
    () => React.cloneElement(content, defaultProps),
    [content, defaultProps]
  );

  return (
    <>
      <HeaderContainer ref={headerRef}>{HeaderComponent}</HeaderContainer>
      {isActive && (
        <>
          <Curtain onClick={() => setIsActive(false)} />
          <PopupContentContainer right={popupRight} height={popupHeight}>
            {ContentComponent}
          </PopupContentContainer>
        </>
      )}
    </>
  );
};

export default Popup;
