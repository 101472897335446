import React, { useState, useEffect, useRef, useCallback } from "react";
import Widget from "./Widget";
import {
  NotificationWrapper,
  NotificartionTitle,
  NotificartionDescription,
  NotificationTypeWrapper,
  NotificationActions,
  NotificationMarkCompleted,
  NotificationContainer,
  DataAvaliableWrapper,
  Loading
} from "./Widget.styled";
import { Icon } from "../../../../../elements";
import { useStore } from "../../../../../hooks";
import { observer } from "mobx-react";
import dayjs from "dayjs";
import Spinner from "../../../../../components/common/Spinner";
import {WarningModal} from "../../../../../elements/modals";

const Notification = observer(() => {
  const { alertStore, authStore } = useStore();
  const [alerts, setAlerts] = useState(alertStore.alertNotification);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [nextPage, setNextPage] = useState(
    alertStore.lastEvaluatedKey,
  );
  const observer = useRef();
  const lastNotificationRef = useRef();
  const notificationContainerRef = useRef();
  const NotificationStatus = {
    Resolved: "resolved",
    Unresolved: "unresolved",
  }
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const updateAlertStatus = useCallback((id, status) => {
    setAlerts((prevAlerts) => ({
      ...prevAlerts,
      items: prevAlerts.items.map((alert) =>
        alert.id === id ? { ...alert, status } : alert
      ),
    }));
  }, []);

  const handleIsCompleted = useCallback(
    (id, status) => {
      if(status === NotificationStatus.Unresolved) {
        return async () => {
          try {
            // Temporarily update the local state to reflect the new status
            updateAlertStatus(id, NotificationStatus.Resolved);
            
            // Update the alert in the backend
            await alertStore.updateAlert(id, authStore.selectedAccount.id, NotificationStatus.Resolved);
            
            // If the backend update is successful, no further action is needed
            // as the local state is already updated.
          } catch (error) {
            // If the backend update fails, revert the local state
            updateAlertStatus(id, NotificationStatus.Unresolved);
            setErrorMessage("Error updating alert");
            setShowErrorModal(true);
          }
        };
      }
    },
    [alertStore, authStore.selectedAccount.id, updateAlertStatus]
  );

  useEffect(() => {
    setAlerts(alertStore.alertNotification);
    setNextPage(alertStore.lastEvaluatedKey);
  }, [alertStore.alertNotification]);

  useEffect(() => {
    if (!nextPage) {
      if (observer.current && lastNotificationRef.current) {
        observer.current.unobserve(lastNotificationRef.current);
      }
      return;
    }

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    /**
     * The callback for the IntersectionObserver.
     * @param {IntersectionObserverEntry[]} entries
     * @description
     * The callback is called when the last element of the notification list
     * is intersecting with the viewport. It then fetches the next page of
     * notifications and appends them to the list.
     * It also updates the scroll position of the notification container
     * so that the user is still at the same position after new notifications
     * are appended.
     */
    const observerCallback = async(entries) => {
      if (entries[0].isIntersecting && nextPage) {
        const currentScrollTop = notificationContainerRef.current.scrollTop;
        const currentScrollHeight =
          notificationContainerRef.current.scrollHeight;
        setIsLoadingMore(true);
        await alertStore
          .fetchNotificationsUCP(
            alertStore.accountId,
            alertStore.userId,
            nextPage,
          )
          .then(() => {
            setIsLoadingMore(false);

            if (notificationContainerRef.current) {
              const newScrollHeight =
                notificationContainerRef.current.scrollHeight;
              const scrollDiff = newScrollHeight - currentScrollHeight;
              notificationContainerRef.current.scrollTop =
                currentScrollTop + scrollDiff;
            }
          });
      }
    };

    // Create an IntersectionObserver to detect when the last notification
    // is intersecting with the viewport. When it is, it fetches the next
    // page of notifications and appends them to the list.
    observer.current = new IntersectionObserver(observerCallback, options);
    if (lastNotificationRef.current) {
      observer.current.observe(lastNotificationRef.current);
    }

    // This return statement is a cleanup function that is called when the component
    // is unmounted. It unobserves the last notification element so that the
    // IntersectionObserver doesn't keep observing it after the component is gone.
    return () => {
      if (lastNotificationRef.current) {
        observer.current.unobserve(lastNotificationRef.current);
      }
    };
  }, [nextPage, alertStore]);

  return (
    <>
      <Widget title="Notification" height="220px" maxheight={"250px"} padding={"12px"}>
        {alertStore.isLoading && !isLoadingMore ? (
          <Spinner />
        ) : alerts.items && alerts.items.length > 0 ? (
          <NotificationContainer ref={notificationContainerRef}>
            {alerts.items.map((notification, index) => (
              <NotificationWrapper
                key={notification.id}
                isCompleted={notification.status === NotificationStatus.Resolved}
                // This ref is used to observe when the last notification is visible
                // in the viewport, so that we can fetch the next page of
                // notifications.
                ref={
                  index === alerts.items.length - 1 ? lastNotificationRef : null
                }
              >
                <NotificartionTitle>{notification.title}</NotificartionTitle>
                <NotificartionDescription title={notification.message}>
                  {notification.message}
                </NotificartionDescription>
                <NotificationTypeWrapper>
                  <span>{notification.payload.channel}</span>
                  <span>
                    {dayjs(notification.created_date).format("MM/DD/YYYY")}
                  </span>
                </NotificationTypeWrapper>
                <NotificationActions>
                  <NotificationMarkCompleted
                    onClick={handleIsCompleted(notification.id, notification.status)}
                    isCompleted={notification.status === NotificationStatus.Resolved}
                  >
                    {notification.status === NotificationStatus.Resolved
                      ? "Completed"
                      : "Mark as completed"}
                  </NotificationMarkCompleted>
                </NotificationActions>
              </NotificationWrapper>
            ))}
            {isLoadingMore && (
              <Loading>
                <Spinner />
              </Loading>
            )}
          </NotificationContainer>
        ) : (
          <DataAvaliableWrapper id="dataAvaliableWrapper">
            <p>No data available</p>
            <p>
              No data is available for this card at the moment. Please check again
              later.
            </p>
            <p>
              <Icon name={"noDataAvaliable"} width={"70px"} height={"68px"} />
            </p>
          </DataAvaliableWrapper>
        )}
      </Widget>
      <WarningModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        title="Error"
        subtitle={errorMessage}
        height="300px"
        width="400px"
      />
    </>
  );
});

export default Notification;
