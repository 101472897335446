import { Route, Routes } from "react-router-dom";
import AgentView from "./AgentView";
import PlaygroundView from "./PlaygroundView";
import NewAgentWizardView from "./NewAgentWizardView";

const AgentPage = () => {
  return (
    <Routes>
      <Route index element={<AgentView />} />
      <Route path="/playground" element={<PlaygroundView />} />
      <Route path="/wizard/new-agent" element={<NewAgentWizardView />} />
    </Routes>
  );
};

export default AgentPage;
