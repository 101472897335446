import React, { useCallback, useState, useEffect } from "react";
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useStore } from "../../hooks";
import DefaultStepper from "../../elements/stepper/DefaultStepper";
import BrainImage from '../../elements/icon/assets/brain.png';
import { NewCampaignWrapper } from "./NewCampaignPage.Styled";
import CampaignModuleForm from "./components/CampaingModule";
import CampaignNameForm from "./components/CampaignName";
import CampaignMessageForm from "./components/CampaignMessage";
import CampaignFileForm from "./components/CampaignFile";
import CampaignUploadFilesForm from "./components/CampaignUploadFiles";
import CampaignCalendarForm from "./components/CampaingCalendar";
import SuccessfulModal from "../../elements/modals/SuccesfulModal";
import AdmirationSign from '../../elements/icon/assets/admiration_sign.svg';

const NewCampaignPage = observer(() => {
    const navigate = useNavigate();
    const { newCampaign, authStore } = useStore();
    const [showSecondModal, setShowSecondModal] = useState(false);
    const [timer, setTimer] = useState(300);

    useEffect(() => {
        newCampaign.onClearNewCampaign()
        newCampaign.onChangeAccount(authStore.selectedAccount.id);
        newCampaign.getModules();
    }, [authStore.selectedAccount]);

    const onHandleCancel = useCallback(() => {
        navigate('/conversations');
    }, [navigate]);

    const onFinishStepper = useCallback(() => {
        newCampaign.onCompleteCreation();
    }, [newCampaign]);

    useEffect(() => {
        let timerId;
        if (newCampaign.showFirstModal) {
            timerId = setTimeout(() => {
                handleCloseFirstModal();
            }, 5000);
        }
        return () => clearTimeout(timerId);
    }, [newCampaign.showFirstModal]);

    useEffect(() => {
        let timerId;
        if (showSecondModal) {
            timerId = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer === 0) {
                        setShowSecondModal(false);
                        navigate('/conversations');
                        return prevTimer;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timerId);
    }, [showSecondModal]);

    const handleCloseFirstModal = () => {
        newCampaign.onChangeFirstModal(false);
        setShowSecondModal(true);
    };

    const handleCloseSecondModal = () => {
        setShowSecondModal(false);
        navigate('/conversations');
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    };

    return (
        <NewCampaignWrapper>
            <br />
            <img src={BrainImage} width={185} height={135} />            
            <DefaultStepper onFinish={onFinishStepper} onCancel={onHandleCancel} status={newCampaign.isLoading ? 'loading' : 'idle'} stepperActionsWidth='400px' stepperActionsMt='60px'>
                <DefaultStepper.Step isComplete={() => newCampaign.onValidateTextField('moduleSelect')} validationErrorLabel="Please choose a module.">
                    <CampaignModuleForm />
                </DefaultStepper.Step>

                <DefaultStepper.Step
                    isComplete={() => {
                        const isNameValid = newCampaign.onValidateObjectField('campaignName');
                        const isTypeValid = newCampaign.onValidateObjectField('campaignType');
                        return isNameValid && isTypeValid;
                    }}
                    nameError={newCampaign.nameError}
                    typeError={newCampaign.typeError}
                    validationErrorLabel="Please enter a name and choose the campaign type."
                >
                    <CampaignNameForm />
                </DefaultStepper.Step>

                <DefaultStepper.Step>
                    <CampaignMessageForm />
                </DefaultStepper.Step>

                <DefaultStepper.Step isComplete={() => newCampaign.buttonUpload } validationErrorLabel="Please upload a file.">
                    <CampaignFileForm />
                </DefaultStepper.Step>

                {/* <DefaultStepper.Step isComplete={() => newCampaign.areAllFieldsDone } validationErrorLabel="Please choose al select.">
                    <CampaignMapFiles columnNames={newCampaign.columnNames} selectedOptions={newCampaign.selectedOptions} handleFieldSelect={newCampaign.handleFieldSelect} />
                </DefaultStepper.Step>  */}

                {!newCampaign.hideUploadFiles && (
                    <DefaultStepper.Step>
                        <CampaignUploadFilesForm />
                    </DefaultStepper.Step>
                )}

                <DefaultStepper.Step
                    labelNext="Complete"
                    isComplete={() => newCampaign.startDate && newCampaign.endDate}
                    validationErrorLabel="Please enter start date and end date."
                >
                    <CampaignCalendarForm />
                </DefaultStepper.Step>
            </DefaultStepper>

            {newCampaign.showFirstModal && <SuccessfulModal
                isOpen={newCampaign.showFirstModal}
                onClose={() => newCampaign.setShowFirstModal(false)}
                imageSrc={AdmirationSign}
                title="Great!"
                subtitle="Your conversation"
                subtitle2="has been successfully created!"
            />}

            {showSecondModal && <SuccessfulModal
                isOpen={showSecondModal}
                onClose={handleCloseSecondModal}
                imageSrc={AdmirationSign}
                title="Great!"
                subtitle="Your Conversation is ready"
                subtitle2="and starts in."
                subtitle3={formatTime(timer)}
            />}

            {newCampaign.isError && <SuccessfulModal
                isOpen={newCampaign.isError}
                onClose={() => newCampaign.onClearError()}
                imageSrc={AdmirationSign}
                title="Oops!"
                subtitle="An error occurred."
                subtitle2="Please try again later."
            />}

        </NewCampaignWrapper>
    );
});

export default NewCampaignPage;
