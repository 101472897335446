import React, { useEffect, useState } from "react";
import { RoundedButton, SuccessfulModal } from "../../../../elements";
import Actions from "./Actions/Actions";
import { useStore } from "../../../../hooks";
import {
  MainContainer,
  TabsContainer,
  BreadCrumbContainer,
  MenuContainer,
  Title,
  ButtonsContainer,
} from "./EventName.styled";
import General from "../../CampaignPage/EventName/General/General";
import { useSearchParams, useBlocker, useNavigate } from "react-router-dom";
import { ConfirmationModal } from "../../../../elements/v2/modals";
import { observer } from "mobx-react";
import Spinner from "../../../../components/common/Spinner";
import RulesView from "../RulesView";
import Tabs2 from "../../../../elements/v2/Tabs2/Tabs2";

export const ConversationTab = {
  General: 0,
  Rules: 1,
  Actions: 2,
};

const tabs = [
  {
    label: "General",
  },
  {
    label: "Rules",
  },
  {
    label: "Actions",
  },
];

const EventName = observer(() => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(ConversationTab.General);

  const [searchParams, setSearchParams] = useSearchParams();
  const { changeTitle, viewConversationStore, authStore } = useStore();
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname &&
      viewConversationStore.changesDetected
  );
  changeTitle(null);

  useEffect(() => {
    viewConversationStore.clear();

    const triggerId = searchParams.get("id");
    const tabId = parseInt(searchParams.get("tabId"));

    if (tabId) {
      setCurrentTab(tabId);
    }

    if (!triggerId) {
      navigate("/v2/conversations");
    }

    viewConversationStore.setId(triggerId, authStore.selectedAccount?.id);
    viewConversationStore.getEventsCatalog();
  }, []);


  return (
    <>
      <MainContainer>
        <BreadCrumbContainer>
          <Title>Conversations Starters</Title>
        </BreadCrumbContainer>
        <MenuContainer>
          {!viewConversationStore.isReadMode() && (
            <ButtonsContainer>
              <RoundedButton
                height="20px"
                width="79px"
                kind="secondary"
                onClick={() => viewConversationStore.draft()}
                disabled={viewConversationStore.isUpdating}
              >
                Draft
              </RoundedButton>
              <RoundedButton
                height="20px"
                width="89px"
                onClick={() => viewConversationStore.publish()}
                disabled={viewConversationStore.isUpdating}
              >
                Publish
              </RoundedButton>
            </ButtonsContainer>
          )}
        </MenuContainer>
      </MainContainer>

      {viewConversationStore.finishedInitialLoad &&
      !viewConversationStore.isUpdating ? (
        <TabsContainer>
          <Tabs2 defaultTab={currentTab} tabs={tabs} onToggleTab={(tabId) => setCurrentTab(tabId)} />

          <Tabs2.Outlet show={currentTab === ConversationTab.General}>
            <General />
          </Tabs2.Outlet>

          <Tabs2.Outlet show={currentTab === ConversationTab.Rules}>
            <RulesView />
          </Tabs2.Outlet>

          <Tabs2.Outlet show={currentTab === ConversationTab.Actions}>
            <Actions />
          </Tabs2.Outlet>
        </TabsContainer>
      ) : (
        <Spinner />
      )}

      <ConfirmationModal
        isOpen={blocker.state === "blocked"}
        title="Warning"
        message="Are you sure you want to discard the changes in this trigger? Any changes made to your agent will be lost. Are you sure you want to proceed?"
        icon="deleteFilled"
        onClose={() => {
          blocker.reset();
        }}
        onConfirm={() => blocker.proceed()}
      />
      {viewConversationStore.isError && (
        <SuccessfulModal
          isOpen={viewConversationStore.isError}
          onClose={() => viewConversationStore.onClearError()}
          title="Oops!"
          subtitle="An error occurred."
          subtitle2="Please try again later."
          zIndex={true}
        />
      )}

      <ConfirmationModal
        isOpen={viewConversationStore.warningMessage}
        title="Warning"
        message={viewConversationStore.warningMessage}
        icon="edit"
        onConfirm={() => viewConversationStore.clearWarning()}
        onClose={() => viewConversationStore.clearWarning()}
        onConfirmLabel="Understood"
        displayCancel={false}
      />
    </>
  );
});

export default EventName;
