import { action, makeObservable, observable } from "mobx";

class RoleValidationStore {
  roleName = '';
  isError = false;
  errorMessage = '';
  users = [];

  constructor() {
    makeObservable(this, {
      roleName: observable,
      isError: observable,
      errorMessage: observable,
      validateRole: action,
      clearRoleName: action,
      clearValidation: action,
      
    });
  }

  clearRoleName = () => this.roleName = undefined;

  clearValidation = () => {
    this.clearRoleName();
  }

  validateRequired = (value) => value !== undefined && !!value.length;

  validateRole = (role) => {
    this.roleName = !this.validateRequired(role.roleName) ? "Role Name is Required" : undefined;
  }

  
}

export default RoleValidationStore;
