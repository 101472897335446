import styled from 'styled-components';
import Calendar from 'react-calendar';

const NewCampaignWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const FormCampaignWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const FormTitle = styled.p`
    font-weight: 400;
    font-size: ${(props) => props.fontSize || '28px'};
    font-style: italic;
    ${(props) => props.theme.fonts.variant.regular}
    margin-bottom: ${(props) => props.marginBottom || '0'};
`;

const FormSubtitle = styled.p`
    ${(props) => props.theme.fonts.variant.regular}
    color: ${(props) => props.theme.colors.grey};
    margin-bottom: ${(props) => props.marginBottom || '0'};
`;

const RadioButtonsContainer = styled.div`
    display: flex;
    gap: 50px;
    margin-top: ${(props) => props.mt || '50px'};
    margin-bottom: ${(props) => props.mb || '0'};
`;

const DropzoneContainer = styled.div`
  display: ${props => props.uploading ? 'none' : 'block'};
`;

const FileInfo = styled.div`
  display: flex;
  align-items: center;
`;

const FileName = styled.p`
  margin-right: 10px;
`;

const FileSize = styled.p`
  margin-left: auto;
`;

const FileInfoContainer = styled.div`
    background-color: #f0f0f0;
    padding: 20px;
`;

const FieldContainer = styled.div`
    margin-bottom: 10px;
    display: flex;
`;

const ModalSubtitle = ({ children }) => <FormTitle fontSize="20px">{children}</FormTitle>;

const FormInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 768px) {
        flex-direction: row;
    }
`;

const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const FieldWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

const Label = styled.label`
    margin-right: 20px;
    font-weight: bold;
    min-width: 120px;
`;

const CustomSelectWrapper = styled.div`
    flex: 1;
    max-width: 50%;
`;

const StyledDiv = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 525px%;
    height: auto;
    border-radius: 5px;
    background-color: #F0F0F0;
    padding: 5px;
`;

const StyledItem = styled.div`
    border: 1px solid #F0F0F0;
    border-radius: 3px;
    padding: 3px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    width: 70px;
    background-color: #F0F0F0;
    overflow-x: auto;
`;

const ColumnName = styled.div`
    width: 129px;
    height: 45px;
    top: 560px;
    left: 627px;
    border-radius: 5px;
    background-color: #F0F0F0;
    display: flex;
    align-items: center;
    margin-right: 10px;
`;

const FileContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
`;

const FileIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 10px;
`;


const Trash = styled.div`
    margin-left: 90%;
    cursor: pointer;
`;

const XIcon = styled.div`
    margin-left: 95%;
`;

const CalendarContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 100px;
  margin-top: 50px;

  .react-calendar__tile--active {
    background-color: black;
    color: white;
  }
  
`;

const StyledCalendar = styled(Calendar)`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.5rem;
  background-color: #EFEFEF;
`;

const StyledUploadFile = styled.div`
  width: 786.12px;
  height: 60.99px;
  top: 540px;
  left: 566.8px;
  border-radius: 7.57px;
  background-color: #EDEDED;
`;

const InnerContent = styled.div`
  display: flex;
  align-items: center;
  width: 337px;
  height: 30px;
  position: relative;
  left: 36px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const Text = styled.p`
  margin: 0;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-left: 40px;
`;


export {
    NewCampaignWrapper,
    FormCampaignWrapper,
    FormTitle,
    FormSubtitle,
    FormInputContainer,
    RadioButtonsContainer,
    DropzoneContainer,
    FileInfo,
    FileName,
    FileSize,
    FileInfoContainer,
    FieldContainer,
    FieldsContainer,
    FieldWrapper,
    Label,
    CustomSelectWrapper,
    StyledDiv,
    ColumnName,
    StyledItem,
    FileContainer,
    FileIcon,
    Trash,
    XIcon,
    CalendarContainer,
    StyledCalendar,
    StyledUploadFile,
    InnerContent,
    Icon,
    Text,
    GridContainer
};
