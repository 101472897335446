import React from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../../../../hooks";
import {
  RulesRowWrapper,
  RulesLabel,
  RulesCount,
  ArrowIcon,
} from "./GeneralInformation.styled";

const GeneralInformation = observer(() => {
  const { viewConversationStore } = useStore();

  return (
    <>
      <RulesRowWrapper>
        <RulesLabel>Rules</RulesLabel>
        <RulesCount>
          <span>Number of rules:</span>
          <span className="value">
            {
              Object.values(viewConversationStore.rulesPage?.rulesGroups)[0]
                ?.conditions.length
            }
          </span>
        </RulesCount>
        <ArrowIcon></ArrowIcon>
      </RulesRowWrapper>

      {/* <RulesRowWrapper>
        <RulesLabel>Segments</RulesLabel>
        <RulesCount><span>Contacts of rules:</span><span className="value"> {viewConversationStore.segments}</span></RulesCount>
        <ArrowIcon></ArrowIcon>
      </RulesRowWrapper> */}

      <RulesRowWrapper>
        <RulesLabel>Actions</RulesLabel>
        <RulesCount>
          <span>Number of actions:</span>
          <span className="value">
            {viewConversationStore.conversationsActionsStore.actions.length}{" "}
          </span>
        </RulesCount>
        <ArrowIcon></ArrowIcon>
      </RulesRowWrapper>

      {/* <RulesRowWrapper>
        <RulesLabel>Agent</RulesLabel>
        <RulesCount>
          <UserProfileCard
            avatarUrl={viewConversationStore.avatarUrl}
            userName="Leonard"
            userRole="Finance Assistant"
            isActive={true}
            isPublic={true}
          />
        </RulesCount>
        <ArrowIcon></ArrowIcon>
      </RulesRowWrapper> */}
    </>
  );
});

export default GeneralInformation;
