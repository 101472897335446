import { action, makeObservable, observable, flow, runInAction } from "mobx";
import { API } from "aws-amplify";
import { getContactsAnn, getConversations } from "../../graphql/queries";
import dayjs from "dayjs";

class AnnUnifiedCustomerProfileStore {
    ucp = {};
    initialLoad = false;
    isLoading = false;
    isError = false;
    conversationHistory = [{"message": "What can I assist you with today?", "type": "received", "time": dayjs().format("HH:mm")}];
    isLoadingHistory = false;
    
    constructor() {
        makeObservable(this, {
            ucp: observable,
            initialLoad: observable,
            isLoading: observable,
            isError: observable,
            conversationHistory: observable,
            isLoadingHistory: observable,
            getUCP: flow,
            getConversationsHistory: flow,
            onClearUCP: action,
            addMessage: action,
            setConversationHistory: action,
        });
    }
    
    onClearUCP() {
        this.ucp = {};
        this.initialLoad = false;
        this.isError = false;
        this.conversationHistory = [{"message": "Currently this contact does not have history.", "type": "received"}];
        this.isLoadingHistory = false;
    }
    
    *getUCP(customer, segment_id) {
        try {
            this.isLoading = true;
            const response = yield API.graphql({
                query: getContactsAnn,
                variables: {
                    input: { customer: customer, segment_id },
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            
            const data = JSON.parse(response.data?.getContactsAnn?.body);
            
            if(!data.hasOwnProperty(segment_id)) throw new Error();
            
            this.ucp = data[segment_id];
            
            this.initialLoad = true;
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
    }
    
    *getConversationsHistory(customerId) {
        try {
            this.isLoadingHistory = true;
            const response = yield API.graphql({
                query: getConversations,
                variables: { input: { "campaign": `campaign#${customerId}-general`} },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            
            const rawConversations = JSON.parse(response.data?.getConversations?.body);
            const email = this.ucp.email
            const formattedConversations = rawConversations.data.messages.filter(conv => conv.sender == email || conv.receiver == email).map(conv => ({message: conv.message, type: conv.sender == email ? 'sent' : 'received'}))
            if(formattedConversations.length){
                this.conversationHistory = formattedConversations;
            }
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoadingHistory = false;
        }
    }
    
    addMessage(message) {
        this.conversationHistory.push(message);
    }
    
    setConversationHistory(conversations) {
        this.conversationHistory = conversations;
    }
}

export default AnnUnifiedCustomerProfileStore;