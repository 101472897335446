import styled from 'styled-components';

const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContainer = styled.div`
    background-color: ${props => props.theme.colors.white};
    width: 70%;
    max-width: 1200px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    min-height: 85vh;
    overflow-y: auto;
    position: relative;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
`;

const ModalTitle = styled.h3`
    margin: 0;
    flex: 1;
    text-align: left;
`;

const HeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
`;

const SearchBarContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
`;

const FeaturesContainer = styled.div`
    width: 80%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
`;

const FeaturesList = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 10px;
`;

export {
    ModalBackground,
    ModalContainer,
    CloseButton,
    ModalTitle,
    HeaderContent,
    SearchBarContainer,
    ContentContainer,
    FeaturesContainer,
    FeaturesList,
};