import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react";
import {
  FieldMapperContainer,
  FileSelectContainer,
  SelectedFileContainer,
  FieldMapperGroup,
  Titles,
  CSVTableContainer,
} from "./ContactsCSVReader.styled";
import {  Icon, IconButton, SuccessfulModal } from "../..";
import { useStore } from "../../../hooks";
import CSVTable from "../CSVTable/CSVTable";
import SimpleSelect from "../Selects/SimpleSelect";

const MAPPINGS = [
  { name: "First Name", mapping: "first_name" },
  { name: "Last Name", mapping: "last_name" },
  { name: "Email", mapping: "email" },
  { name: "Company Name", mapping: "company_name" },
  { name: "Address", mapping: "address" },
  { name: "City", mapping: "city" },
  { name: "State", mapping: "state" },
  { name: "Postal Code", mapping: "postal_code" },
  { name: "Phone", mapping: "phone" },
];

const FieldMapperElement = observer(({ titles, mapping, index }) => {
  const { contactSegmentStore } = useStore();

  if (!contactSegmentStore?.dynamicColumns?.length) {
    contactSegmentStore.setDynamicColumns(MAPPINGS);
  }

  const onChangeSelect = (value) => {
    contactSegmentStore.setHeaderMapping(mapping, value);
  };

  return (
    <FieldMapperContainer>
      <p>{titles[index].name}</p>
      <Icon name="blueArrow" />
      <SimpleSelect
        options={contactSegmentStore.fileUploadedHeaders}
        handleSelect={onChangeSelect}
      />
    </FieldMapperContainer>
  );
});

const ContactsCSVReader = observer(({ isSaved, setIsSaved }) => {
  const { contactSegmentStore } = useStore();

  const [errorPopup, setErrorPopup] = useState("");
  const fileInputRef = useRef();

  const handleFile = (files) => {
    if (files.length) {
      const fileToUpload = files[0];

      if (!["text/csv"].includes(fileToUpload.type)) {
        return setErrorPopup("Please upload a valid format file: *.csv.");
      }

      if (fileToUpload.size / 1e6 > 100) {
        return setErrorPopup(`File exceeds the 100 mb file size limit.`);
      }

      contactSegmentStore.setFileUploaded(fileToUpload);
    }
  };

  const removeFile = () => {
    contactSegmentStore.setFileUploaded(null);
    if (fileInputRef.current) fileInputRef.current.value = "";
    setIsSaved(false);
    contactSegmentStore.clear();
    contactSegmentStore.setDynamicParsedContactList([])
  };

  return (
    <>
      {!contactSegmentStore.fileUploaded && (
        <FileSelectContainer
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDragLeave={(e) => {
            e.preventDefault();
          }}
          onDrop={(e) => {
            e.preventDefault();
            handleFile(e.dataTransfer.files);
          }}
        >
          <input
            id="contacts-file-input"
            ref={fileInputRef}
            type="file"
            style={{ display: "none" }}
            onChange={(e) => handleFile(e.target.files)}
          />
          <label htmlFor="contacts-file-input">
            <Icon name="paperUpload" width="20px" height="20px" />
            Drag and drop or choose a file to upload
          </label>
        </FileSelectContainer>
      )}

      {contactSegmentStore.fileUploaded && (
        <SelectedFileContainer>
          <div>
            <span>
              <Icon name="excelLogo" width="20px" height="20px" />
            </span>
            <div>
              <p>{contactSegmentStore.fileUploaded?.name}</p>
              <span>
               {contactSegmentStore.fileUploaded?.size && <p>{contactSegmentStore.fileUploaded?.size / 1000} kb</p>}
              </span>
            </div>
          </div>
          <IconButton
            icon="close"
            width="10px"
            height="10px"
            onClick={removeFile}
          />
        </SelectedFileContainer>
      )}

      {isSaved && contactSegmentStore?.fileUploaded ? (
        <CSVTableContainer>
          <CSVTable />
        </CSVTableContainer>
      ) : (
        <>
          {contactSegmentStore.fileUploaded?.size && (
            <>
              <Titles>
                <b>File fields</b>
                <b>System fields</b>
              </Titles>
              <FieldMapperGroup>
                {MAPPINGS.map(({ mapping }, key) => (
                  <FieldMapperElement
                    titles={MAPPINGS}
                    mapping={mapping}
                    index={key}
                  />
                ))}
              </FieldMapperGroup>
            </>
          )}
        </>
      )}

      <SuccessfulModal
        isOpen={!!errorPopup || contactSegmentStore.error}
        onClose={() => {
          contactSegmentStore.onClearError();
          setErrorPopup("");
        }}
        title="Oops!"
        subtitle="An error occurred."
        subtitle2={errorPopup || contactSegmentStore.error}
        height="300px"
        width="500px"
        zIndex={true}
      />
    </>
  );
});

export default ContactsCSVReader;
