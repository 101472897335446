import React from "react";
import { InputText } from "../../../../../elements";
import {
  FormContainer,
} from "./RoleDetails.styled";
import TextArea from "../../../../../elements/v2/TextArea/TextArea";
import { observer } from "mobx-react";
import { useStore } from "../../../../../hooks";
import Spinner from "../../../../../components/common/Spinner";

const RoleDetails = observer(({}) => {

  const { listRoleStore } = useStore()

  return (
    <FormContainer>
      {listRoleStore.status !== 'loading' ? <><div>
        <label>Name</label>
        <InputText
          placeholder="Role Name"
          value={listRoleStore.selectedRole.name || ''}
          onChange={(e) => listRoleStore.onChangeRoleDetails("name", e.target.value)}
          width="800px"
        />
      </div>

      <div>
        <label>Description</label>
        <TextArea
          value={listRoleStore.selectedRole.description || ''}
          height="120px"
          resize="none"
          placeholder="Enter description"
          width="800px"
          onChange={(e) => listRoleStore.onChangeRoleDetails("description", e.target.value)}
        />
      </div></> :
        <Spinner/>
      }
    </FormContainer>
  );
});

export default RoleDetails;
