import {observer} from "mobx-react";
import { StepWrapper, FormWrapper } from "./Components.styled";
import {SubTitleStep, TitleStep} from "../CreateCustomerStepper.styled";
import {InputText, CustomSelect} from "../../../../elements";
import {useStore} from "../../../../hooks";
import {useCallback} from "react";
import states from "../../../../components/common/USstates";

const LocationStep = observer(() => {
  const { customer } = useStore();
  const createStore = customer.currentPage;

  const onHandleChangeAddress = useCallback((e) => {
    createStore.onChangeAddress(e.target.value);
  }, [createStore]);

  const onHandleChangeCity = useCallback((e) => {
    createStore.onChangeCity(e.target.value);
  }, [createStore]);

  const onHandleChangeState = useCallback((e) => {
    createStore.onChangeState(e);
  }, [createStore]);

  const onHandleChangePostalCode = useCallback((e) => {
    PostalCodeValidation(e.target.value)
    
    createStore.onChangePostalCode(e.target.value);
    
      
  }, [createStore]);

  const PostalCodeValidation = (item) => {
    const postalCodePattern = /^\d{5}(-\d{4})?$/;
      if (postalCodePattern.test(item)){
          createStore.setPostalCodeValid(true)
      }else{
          createStore.setPostalCodeValid(false)
      }

  };


  return (
    <StepWrapper>
      <TitleStep>I like that name!</TitleStep>
      <SubTitleStep>Now just a few details so that nothing is missed.</SubTitleStep>
      <FormWrapper top="40px" bottom="40px">
        <InputText placeholder="Address" value={createStore.address} onChange={onHandleChangeAddress} />
        <InputText placeholder="City" value={createStore.city} onChange={onHandleChangeCity} />
        <CustomSelect placeholder="State" value={createStore.state} handleSelect={onHandleChangeState} options={states}/>

        {!createStore.postalCodeValid ? (
          
          <InputText placeholder="Postal Code" value={createStore.postalCode} onChange={onHandleChangePostalCode} displayError={true} validationError={'Invalid postal code'}/>
        ) : (
          <InputText placeholder="Postal Code" value={createStore.postalCode} onChange={onHandleChangePostalCode}/>
        )}
      </FormWrapper>
    </StepWrapper>
  );
});

export default LocationStep;
