import React, { useState, useEffect, useRef } from 'react';
import VerticalPoints from '../../elements/icon/assets/vertical_points.svg';
import { Container, IconButton, Menu, MenuItem, OptionIcon, OptionTitle } from './VerticalButton.styled';

const VerticalButton = ({ options }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleMenu = () => {
        setMenuOpen(prevState => !prevState);
    };

    return (
        <Container ref={menuRef}>
            <IconButton
                src={VerticalPoints}
                alt="Custom Icon"
                onClick={toggleMenu}
            />
            {menuOpen && options && options.length > 0 && (
                <Menu>
                    {options.map((option, index) => (
                        <MenuItem key={index} onClick={() => { option.onClick && option.onClick(); setMenuOpen(false); }}>
                            <OptionIcon src={option.icon} alt="Option Icon" />
                            <OptionTitle>{option.title}</OptionTitle>
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </Container>
    );
};

export default VerticalButton;
