import React, { useState } from "react";
import { observer } from 'mobx-react';
import {useStore} from "../../../../hooks";
import { AppointmentContainer, AppointmentDescription, UserContainer, AppointmentTitle, AppointmentBody, ExpandedAppointment, 
    SegmentTag, UserInfoLine } from "./UserOrder.styled";
import { RoundedButton, GenericTextModal } from "../../../../elements";
import { useEffect } from "react";

const UserOrder = observer(() => {
    const { unifiedCustomerProfileStore } = useStore();
    const [displayDetailsForAppt, setDisplayDetailsForAppt] = useState();
    const [selectedAppt, setSelectedAppt] = useState();
    const [expandMoreDetails, setExpandMoreDetails] = useState(false);
    const [isOrderVisible, setIsOrderVisible] = useState(true);

    useEffect(() => {
        if(unifiedCustomerProfileStore.selectedCar){
            unifiedCustomerProfileStore.getOrders();
        }

    }, [unifiedCustomerProfileStore.selectedCar])

    useEffect(() => {
        if(displayDetailsForAppt){
            setSelectedAppt(unifiedCustomerProfileStore.orders.find(appt => appt.order_no === displayDetailsForAppt))
            setIsOrderVisible(false);
        }
    }, [displayDetailsForAppt])

    return (
        <UserContainer> 
            {isOrderVisible && (
                <AppointmentContainer>
                    <div>
                        <AppointmentTitle>Orders</AppointmentTitle>
                    </div>
                </AppointmentContainer>
            )}
            
            {!displayDetailsForAppt && unifiedCustomerProfileStore.orders && unifiedCustomerProfileStore.orders.map(appt => 
                <AppointmentContainer key={appt.order_no}>
                    <div>
                        <AppointmentTitle>{appt.name}</AppointmentTitle>
                        <AppointmentDescription>Status: 
                        <UserInfoLine>
                            <SegmentTag segmentType={appt.status}>{appt.status}</SegmentTag>
                        </UserInfoLine>
                            </AppointmentDescription>
                    </div>
                    <RoundedButton width="89px" height="30px" borderRadius="4.7px" margin="20px 0 0 0" onClick={() => setDisplayDetailsForAppt(appt.order_no)}>Details</RoundedButton>
                </AppointmentContainer>
            )}
            {selectedAppt && displayDetailsForAppt && (
                <ExpandedAppointment>
                    <AppointmentContainer>
                            <div>
                                <AppointmentTitle>{selectedAppt.name}</AppointmentTitle>
                            </div>
                            <RoundedButton width="89px" height="30px" borderRadius="4.7px" onClick={() => {setDisplayDetailsForAppt(); setIsOrderVisible(true);}}>Back</RoundedButton>
                    </AppointmentContainer>
                    <AppointmentBody>
                        <AppointmentDescription>Name: {selectedAppt.name} </AppointmentDescription>
                        <AppointmentDescription>Date: {selectedAppt.order_date}</AppointmentDescription>
                        <AppointmentDescription>Type: {selectedAppt.type}</AppointmentDescription>
                        <AppointmentDescription>Accepted: {selectedAppt.accepted ? 'Yes' : 'No'}</AppointmentDescription>
                        <AppointmentDescription>Warranty: {selectedAppt.warranty ? 'Yes' : 'No'}</AppointmentDescription>                        
                    </AppointmentBody>
                        <SegmentTag segmentType={selectedAppt.status}>{selectedAppt.status}</SegmentTag>
                </ExpandedAppointment>
            )}

            {selectedAppt && displayDetailsForAppt && <GenericTextModal
              isOpen={expandMoreDetails}
              onClose={() => setExpandMoreDetails(false)}
              title={selectedAppt.name}
              
              bodyMaxHeight="62vh"
              width="600px"
              height="65vh"
            />}
        </UserContainer>
    );
});

export default UserOrder;
