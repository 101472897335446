import { observer } from "mobx-react";
import { ChatConversationWrapper } from "./Assistance.styled";
import {
  MessageWrapper,
  MessageContainer,
  ChatWindowAvatar,
  SpinnerImage,
} from "../../../../Chatbox/styles/ChatWindowComponent.styled";
import React, { useEffect, useRef, useState } from "react";
import Message from "./Message";
import Markdown from "react-markdown";
import Spinner from "../../../../../elements/icon/assets/spinners_chat.svg";
import AnnAvatar from "../../../../../images/AnnAvatar.svg";
import ShineThinking from "../../../../../elements/ShineThinking";

const ChatBox = observer(
  ({
    messages,
    displayButtons,
    agentIsTyping,
    cleanMessages = true,
    maxHeight,
  }) => {
    const bottomRef = useRef(null);

    useEffect(() => {
      scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
      bottomRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    };

    // Filter out consecutive messages of the same type, keeping only the last received message
    let filteredMessages = [];
    let lastReceivedIndex = -1;

    messages.forEach((message, index) => {
      if (cleanMessages) {
        if (message.type !== "received") {
          filteredMessages.push(message);
        } else {
          // Check if this received message is consecutive with the previous one
          if (index === 0 || messages[index - 1].type !== "received") {
            lastReceivedIndex = filteredMessages.length;
            filteredMessages.push(message);
          } else {
            filteredMessages[lastReceivedIndex] = message;
          }
        }
      } else {
        filteredMessages.push(message);
      }
    });

    return (
      <ChatConversationWrapper $maxHeight={maxHeight}>
        {filteredMessages.map((item, index) => (
          <Message
            messageItem={item}
            key={index}
            displayButtons={displayButtons}
            width="100%"
          />
        ))}

        {agentIsTyping && messages.type !== "sent" && (
          <MessageContainer>
            <ChatWindowAvatar src={AnnAvatar} alt="Agent Avatar" />
            <MessageWrapper flex>
            <ShineThinking />
          </MessageWrapper>
          </MessageContainer>
        )}
        <div ref={bottomRef} />
      </ChatConversationWrapper>
    );
  },
);

export default ChatBox;
