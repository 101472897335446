import { action, flow, makeObservable, observable } from "mobx";
import { API } from "aws-amplify";
import ava from '../../images/Ava.png';
import EmailIcon from '../../elements/icon/assets/icon_mail.svg';
import { getAgent } from "../../graphql/queries";

class AgentTreeStore {
    isLoading = false;
    isError = false;
    errorMessage = '';
    nodes = [
        {
            id: 'd1',
            data: { label: 'Director one', title: 'Sales director' },
            position: { x: 0, y: 0 },
            type: 'director'
        },
        {
            id: 'add1',
            position: { x: 0, y: 0 },
            type: 'add',
        },
    ];
    edges = [
        { id: 'd1-add1', source: 'd1', target: 'add1', type: 'smoothstep' },
    ];
    initialLoad = false;
    agents = [];
    agentsMocks = [];
    teamID = 1234;

    selectedAgent = null;

    name = "";
    title = "";
    persona = "";
    tones = [];
    answerExtension = "";
    instructions = "";
    account = "";
    files = [];
    channels = [];
    conversationStarters = {};
    actions = [];
    isConcierge = false;
    agentPrompter = '';
    agentConcierge = '';
    uploadProgress = 0;
    filesWithStatus = [];
    id = "";
    createdDate = "";
    updatedDate = "";
    domain = "";

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            isError: observable,
            errorMessage: observable,
            nodes: observable,
            edges: observable,
            initialLoad: observable,
            agents: observable,
            agentsMocks: observable,
            teamID: observable,
            selectedAgent: observable,
            name: observable,
            title: observable,
            persona: observable,
            tones: observable,
            answerExtension: observable,
            instructions: observable,
            account: observable,
            files: observable,
            channels: observable,
            conversationStarters: observable,
            actions: observable,
            isConcierge: observable,
            agentPrompter: observable,
            agentConcierge: observable,
            uploadProgress: observable,
            filesWithStatus: observable,
            id: observable,
            createdDate: observable,
            updatedDate: observable,
            domain: observable,
            setNodes: action,
            setEdges: action,
            clear: action,
            setInitialLoad: action,
            addConversationStarter: action,
            removeConversationStarter: action,
            setFiles: action,
            setInstructions: action,
            setAccount: action,
            setName: action,
            setTitle: action,
            setPersona: action,
            setTones: action,
            setAnswerExtension: action,
            setChannels: action,
            setActions: action,
            updateConversationStarter: action,
            loadMockData: action,
            getAgents: flow,
            onChangeActions: flow,
            setSelectedAgent: action,
            setError: action,
            clearError: action,
            onChangeAccount: action
        });

        this.loadMockData();
        
    }

    setInitialLoad(value) {
        this.initialLoad = value;
    }

    setNodes(nodes) {
        this.nodes = [...nodes];
    }

    setEdges(edges) {
        this.edges = [...edges];
    }

    clear() {
        this.nodes = [];
        this.edges = [];
        this.isLoading = false;
        this.isError = false;
        this.errorMessage = '';
        this.agents = [];
        this.agentsMock = [];
        this.name = "";
        this.title = "";
        this.persona = "";
        this.tones = [];
        this.answerExtension = "";
        this.instructions = "";
        this.account = "";
        this.files = [];
        this.channels = [];
        this.conversationStarters = {};
        this.actions = [];
        this.isConcierge = false;
        this.agentPrompter = '';
        this.agentConcierge = '';
        this.uploadProgress = 0;
        this.filesWithStatus = [];
        this.id = "";
        this.createdDate = "";
        this.updatedDate = "";
        this.domain = "";
        this.selectedAgent = null;
    }

    addConversationStarter(starter) {
        const newKey = `cs${Object.keys(this.conversationStarters).length + 1}`;
        this.conversationStarters[newKey] = starter;
    }

    removeConversationStarter(index) {
        const key = Object.keys(this.conversationStarters)[index];
        if (key) {
            delete this.conversationStarters[key];
        }
    }

    setFiles(files) {
        this.files = files;
    }

    setInstructions(instructions) {
        this.instructions = instructions;
    }

    setAccount(account) {
        this.account = account;
    }

    setName(name) {
        this.name = name;
    }

    setTitle(title) {
        this.title = title;
    }

    setPersona(persona) {
        this.persona = persona;
    }

    setTones(tones) {
        this.tones = tones;
    }

    setAnswerExtension(answerExtension) {
        this.answerExtension = answerExtension;
    }

    setChannels(channels) {
        this.channels = channels;
    }

    setActions(actions) {
        this.actions = actions;
    }

    updateConversationStarter(id, value) {
        this.conversationStarters[id] = value;
    }

    setSelectedAgent(agent) {
        this.selectedAgent = agent;
    }

    setError(errorMessage) {
        this.isError = true;
        this.errorMessage = errorMessage;
    }

    clearError() {
        this.isError = false;
        this.errorMessage = '';
    }

    onChangeAccount = (accountId) => {
        this.setAccount(accountId);
    }

    loadMockData() {
        this.name = "Sales Team";
        this.title = "Sales Director";
        this.persona = "Friendly";
        this.tones = ["Friendly", "Professional"];
        this.answerExtension = "short";
        this.instructions = "Provide clear and concise responses.";
        this.account = "1234-5678";
        this.files = ["file1.pdf", "file2.docx"];
        this.channels = ["email", "chat"];
        this.conversationStarters = {
            "cs1": "How can I help you today?",
            "cs2": "Do you have any questions about our product?",
            "cs3": "Would you like to schedule a demo?",
            "cs4": "Is there anything specific you're looking for?",
        };
        this.isConcierge = false;
        this.id = "agent-123";
        this.createdDate = "2023-01-01";
        this.updatedDate = "2023-07-01";
        this.domain = "sales";
    }

    

    *getAgents(customer_id) {
        try {
            this.isLoading = true;
            this.isError = false;

            const response = yield API.graphql({
                query: getAgent,
                variables: { input: { customer_id } },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            const agents = JSON.parse(response.data?.getAgent?.body);
            this.agents = agents
        } catch (error) {
            this.setError("Failed to fetch agents.");
        } finally {
            this.isLoading = false;
        }
    }

    *onChangeActions(newActions) {
        try {
            this.setActions(newActions);
        } catch (error) {
            this.setError("Failed to save actions.");
        }
    }
}

export default AgentTreeStore;
