import { action, makeObservable, observable } from "mobx";
import { API } from "aws-amplify";
import { getUsers } from "../../../graphql/queries";

class SelectedUserStore {
  userData = {};
  isLoading = false;
  isError = false;

  constructor() {
    makeObservable(this, {
      userData: observable,
      isLoading: observable,
      isError: observable,
      fetchUserData: action,
      setUserData: action,
      clearUserData: action,
    });
  }

  
  fetchUserData = async (userId, customerId) => {
    this.isLoading = true;
    this.isError = false;
    try {
      const response = await API.graphql({
        query: getUsers,
        variables: { input: { customer: customerId, id: userId } },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      const userData = JSON.parse(response.data?.getUsers?.body);
      this.userData = userData || {};
      this.isLoading = false;
    } catch (error) {
      this.isError = true;
      this.isLoading = false;
    }
  }

  
  setUserData = (data) => {
    this.userData = data;
  }

  
  clearUserData = () => {
    this.userData = {};
  }
}

export default SelectedUserStore;
