import styled, { css } from 'styled-components';

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 4;

    ${(props) => props.isOpen && css`
        display: flex;
    `}
`;

const ModalWrapper = styled.div`
    position: relative;
    width: ${props => props.$width || '1000px'};
    height: ${props => props.$height || '500px'};
    background-color: ${(props) => props.theme.colors.highClearGray};
    border-radius: 10px;
`;

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 30px;
    width: ${props => props.$width || '940px'};
    height: ${props => props.$height || '400px'};
    background-color: ${(props) => props.theme.colors.white};
    margin-top: 35px;
    margin-left: 31px;
    border: 1px solid;
    border-color: ${(props) => props.theme.colors.silverGrayTwo};
    overflow: scroll;
`;

const ModalTitle = styled.div`
    font-size: 18px;
    color: ${(props) => props.theme.colors.black};
`;

const StyledModalSubtitle = styled.p`
    font-size: 20px;
    margin-bottom: 0;
`;

const ModalSubtitle = ({ children }) => <StyledModalSubtitle>{children}</StyledModalSubtitle>;

const ModalContainerCloseButton = styled.div`
    display: flex;
`;

const ModalCloseButton = styled.button`
    cursor: pointer;
    width: ${props => props.$width || '150px'};
    height: ${props => props.$height || '40px'};
    border-radius: 8px;
    border: 1px solid black;
`;

const ModalAddButton = styled.button`
    cursor: pointer;
    width: ${props => props.$width || '150px'};
    height: ${props => props.$height || '40px'};
    border-radius: 8px;
    border: 1px solid black;
    background-color: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.white};
    margin-right: 10px;
`;

const ModalChatContainer = styled.div`
    margin-top: 5px;
    width: 100%;
    margin-left: 3%;
`;

const ModalButtonContainer = styled.div`
    margin-top: 10px;
    display: flex;
    margin-right: 3%;
`;

const SpinnerImage = styled.img`
    height: 30px;
    margin-left: 5px;
    opacity: 0.5;
    align-self: center;
`;

export {
    Backdrop,
    ModalWrapper,
    ModalContainer,
    ModalTitle,
    ModalSubtitle,
    ModalCloseButton,
    ModalAddButton,
    ModalContainerCloseButton,
    ModalChatContainer,
    ModalButtonContainer,
    SpinnerImage
};
