import styled from "styled-components"

const GenericListContainer = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 10px;

    input {
        flex-grow: 2;
        height: 40px;
        border-radius: 8px;
        border: none;
    }
`;

const GenericListItem = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Header = styled(GenericListItem)`
    align-items: center;
`;

const Title = styled.p`
    display: inline-block;
    font-size: ${props => props.theme.fonts.size.s1};
    font-weight: 600;
    margin: 0;
`;

const AddButton = styled.span`
    cursor: pointer;
    border: 2px solid ${props => props.theme.colors.clearGray};
    border-radius: 8px;
    gap: 10px;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export {
    GenericListContainer,
    GenericListItem,
    Header,
    Title,
    AddButton
}