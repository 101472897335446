import React from "react";
import { Route, Routes } from "react-router-dom";
import CampaignJourneyList from "./CampaignJourneyList";
import CampaignList from "./CampaignsList";

const CampaignPage = () => {
  return (
    <Routes>
      <Route index element={<CampaignList />} />
      <Route path="journey-details" element={<CampaignJourneyList />} />
    </Routes>
  );
};

export default CampaignPage;
