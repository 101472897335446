import styled from "styled-components";

const AssistantWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const AssistantBox = styled.div`
    width: 100%;
    border: 2px solid ${(props) => props.theme.colors.silverGrayTwo};
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.highClearGray};
    box-sizing: border-box;
`;

const ChatConversationWrapper = styled(AssistantBox)`
    background-color: ${(props) => props.theme.colors.white};
    padding: 16px 40px;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: ${props => props.$maxHeight || '600px'};
`;

const RichTextWrapper = styled(AssistantBox)`
    position: relative;
    height: 204px;
    padding: 16px;
`;

const RichTextWrapperTopActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
`;

const RichTextWrapperTopActionsLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;

const RichTextWrapperSendButton = styled.div`
    position: absolute;
    bottom: 35px;
    right: 23px;
    width: 90px;
    z-index: 3;
`;

const AttachmentButtonWrapper = styled.div`
    position: absolute;
    top: 20px;
    right: 90px;
    z-index: 4;
    display: flex;
    align-items: flex-end;
    gap: 5px;
`;

const AttachmentButton = styled.label`
  cursor: pointer;
  background-color: black;
  padding: 6px;
  display: flex;
  border-radius: 10px;
`;

const FileAttachmentWrapper = styled.div`
  padding: 5px 15px;
  max-width: 100%;
  border-radius: 10px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: white;

  span {
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  button {
    margin-top: 2px;
  }
`;

export {
  AssistantWrapper,
  ChatConversationWrapper,
  RichTextWrapper,
  RichTextWrapperTopActions,
  RichTextWrapperTopActionsLeft,
  RichTextWrapperSendButton,
  AttachmentButtonWrapper,
  AttachmentButton,
  FileAttachmentWrapper
};
