import React from 'react';
import { StatusCards, StatusContainer, StatusContentCards, StatusSubTitle, StatusTitleCards } from "./DataCards.styled";
import { Icon } from "../../../../../elements";

const DataCards = ({ options }) => {
  return (
    <StatusContainer>
      {options && options.map((state) => {
        return (
          <StatusCards key={state.name}>
            <StatusTitleCards>
              {state.name}
            </StatusTitleCards>
            <StatusContentCards> {state.value} </StatusContentCards>
            {/* <StatusSubTitle onClick={() => { state.onClick && state.onClick()}}>
              {state.subtitle}
            </StatusSubTitle> */}
          </StatusCards>
        );
      })}
    </StatusContainer>
  );
}

export default DataCards;