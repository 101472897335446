import React from 'react';
import {observer} from "mobx-react";
import { ChatQuestionsWrapper, ChatQuestionsBox, ChatQuestionsSuggestions, Suggestion } from "./ChatQuestionsSection.styled";

const ChatQuestionsSection = observer(({ onSelectQuestion, isLoading, loadedQuestions }) => {
    const handleShowConversations = (message) => {
        if(!isLoading){
            onSelectQuestion(message);
        }
    };

    return (
        <>
            <ChatQuestionsWrapper>
                <ChatQuestionsBox>
                    <ChatQuestionsSuggestions>
                        {loadedQuestions.map((question, idx) => (question ? <Suggestion key={idx} onClick={() => handleShowConversations(question)} ><span>{question}</span></Suggestion>: <></>))}
                    </ChatQuestionsSuggestions>
                </ChatQuestionsBox>
            </ChatQuestionsWrapper>
        </>
    );
});

export default ChatQuestionsSection;
