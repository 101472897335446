import React from 'react';
import { observer } from "mobx-react-lite";
import ChatBox from "../../../../../elements/ChatBox/ChatBox";

const ChatComponent = observer(({ store }) => {
    return (
        <ChatBox
            customerSelected={store.account}
            agentId={store.id}
            agentDomain={store.isConcierge}
            inputWidth="80vh"
            agentData={store.agentSelected}
        />
    );
});

export default ChatComponent;
