import React, { useState } from 'react';
import ModalChangePassword from './ModalChangePassword';
import { Container, TitleContainer, TitleText, DescriptionText } from '../Styles/ChangePassword.Styled';

const ChangePassword = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Container onClick={toggleModal}>
        <TitleContainer>
          <TitleText>Change Password</TitleText><br />
          <DescriptionText>Update your login Password</DescriptionText>
        </TitleContainer>
      </Container>
      
      <ModalChangePassword isOpen={isModalOpen} onClose={toggleModal} />
    </>
  );
}

export default ChangePassword;
