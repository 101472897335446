import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  border-radius: 8px;
  width: 100%;
  max-width: 613px;
  position: relative;
  background: ${(props) => props.theme.colors.primaryWhite};
  margin: ${(props) => props.isMobile ? '0 10px' : 'auto'}
`;

export const ModalHeader = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.brightGray};
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 51px;
`;

export const ModalTitle = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #${(props) => props.theme.colors.greyEleven};
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.greyEleven};
  padding: 4px;
  
  &:hover {
    color: ${(props) => props.theme.colors.charcoal};
  }
`;

export const Container = styled.div`
  padding: 24px 24px 16px 24px;
`;

export const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  flex: 1;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 8px 36px 8px 12px;
  border: 1px solid ${(props) => props.theme.colors.philippineSilver};
  border-radius: 8px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.greyEleven};
  background: ${(props) => props.theme.colors.primaryWhite};
  font-weight: 400;
  
  &::placeholder {
    color: #9CA3AF;
  }
  
  &:focus {
    outline: none;
    border-color: #2563EB;
  }
`;

export const SearchIconWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #9CA3AF;
  display: flex;
  align-items: center;
  pointer-events: none;
`;

export const SearchIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z" 
      stroke="#9CA3AF" 
      strokeWidth="1.66667" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </svg>
);

export const ShareButton = styled.button`
  background: ${(props) => props.theme.colors.activeBlue};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
  width: 71px;
  height: 40px;
  
  &:hover {
    background: #1D4ED8;
  }
  
  &:disabled {
    background: #9CA3AF;
    cursor: not-allowed;
  }
`;

export const SectionTitle = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.greyEleven};
  font-weight: 600;
`;

export const SearchResults = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.brightGray};
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

export const SearchResultItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.charcoal};
  font-size: 14px;
  
  &:hover {
    background: ${(props) => props.theme.colors.ghostWhite};
  }
  
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.brightGray};
  }
`;

export const SelectedUserItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.div`
  font-size: 14px;
  color: #${(props) => props.theme.colors.greyEleven};
  font-weight: 500;
`;

export const UserEmail = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.greyEleven};
`;

export const UserRole = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.colors.greyEleven};
  margin-left: 8px;
`;

export const PermissionsDropdown = styled.div`
  position: relative;
`;

export const DropdownButton = styled.button`
  padding: 8px 12px;
  background: ${(props) => props.theme.colors.primaryWhite};
  border: 1px solid ${(props) => props.theme.colors.brightGray};
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.greyEleven};
  font-size: 14px;
  font-weight: 600;
  
  &:hover {
    background: ${(props) => props.theme.colors.ghostWhite};
  }
`;

export const DropdownContent = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 4px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.brightGray};
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  min-width: 288px;
  z-index: 1000;
  padding: 10px;
`;

export const PermissionModalCloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  color: ${(props) => props.theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: ${(props) => props.theme.colors.charcoal};
  }
  
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const DropdownSection = styled.div`
  padding: 10px;
  cursor: pointer;
  
  &:hover {
    background: ${(props) => props.theme.colors.ghostWhite};
  }
  
  &:nth-child(2) {
    border-bottom: 1px solid ${(props) => props.theme.colors.clearGray};
  }
`;

export const DropdownTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.greyEleven};
  margin-bottom: 4px;
`;

export const DropdownDescription = styled.div`
  font-size: 10px;
  color: ${(props) => props.theme.colors.greyEleven};
`;

export const RemoveButton = styled.button`
  color: ${(props) => props.theme.colors.red};
  font-size: 14px;
  padding: 12px;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
  
  &:hover {
    background: ${(props) => props.theme.colors.redLavender};
  }
`;

export const SuccessContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  text-align: center;
`;

export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
`;

export const SuccessTitle = styled.h3`
  font-size: 18px;
  font-weight: 500;
  color: #${(props) => props.theme.colors.greyEleven};
  margin: 0 0 8px 0;
`;

export const SuccessMessage = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.colors.greyEleven};
  margin: 0;
`;


export const PermissionModalContainer = styled.div`
  position: fixed;
  top: ${props => `${props.position?.top || 0}px`};
  left: ${props => `${props.position?.left || 0}px`};
  transform: translateY(8px); // Pequeño espacio entre el botón y el modal
  background: ${(props) => props.theme.colors.primaryWhite};
  border-radius: 8px;
  min-width: 288px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  padding: 24px;
  z-index: 1100;
`;