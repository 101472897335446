import React, {useCallback} from "react";
import {observer} from "mobx-react";
import {InputText} from "../../../elements";
import {useStore} from "../../../hooks";
import { FormTitle, FormSubtitle, FormAgentWrapper } from "../NewAgentPage.styled"

const AgentNameForm = observer(() => {
    const { newAgent } = useStore();

    const onHandleChangeName = useCallback((e) => {
        newAgent.onChangeName(e.target.value);
    }, [newAgent]);

    return (
        <FormAgentWrapper>
            <FormTitle>Hello! I'm your ANN agent, excited to guide you through creating a new agent <br/> Let's get started!</FormTitle>
            <FormSubtitle>What name do you have in mind for your <br/> agent?</FormSubtitle>
            <InputText
                type="text"
                value={newAgent.name}
                placeholder="Agent name"
                onChange={onHandleChangeName}
                width="380px"
                margin="80px 0 40px 0"
            />
        </FormAgentWrapper>
    );
});

export default AgentNameForm;
