import {observer} from "mobx-react";
import ListData from "./components/ListData";

const NotificationList = observer(() => {
  return (
    <>
      <ListData />
    </>
  );
});

export default NotificationList;
