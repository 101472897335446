import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import '../styles/ChatQuestions.css';
import brain_image from '../assets/brain_chat.png';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress

const ChatQuestions = ({ onSelectQuestion, isLoading }) => { // Pass isLoading prop
    const [newMessage, setNewMessage] = useState('');
    const [isChatQuestionsVisible, setIsChatQuestionsVisible] = useState(true);

    const suggestionMessages = {
        modules: 'What is ANN and how ANN can help my business?',
        appointments: 'Explain more about ANN products.',
        activities: 'Give me an example of how ANN could help my dealership increase sales.',
        channelConversations: 'How can ANN help me with lead generation?',
        responseRate: 'Who is Ben?',
    };

    const handleShowConversations = async (messageKey) => {
        try {
            if (suggestionMessages.hasOwnProperty(messageKey)) {
                const message = suggestionMessages[messageKey];
                setNewMessage(message);
                setIsChatQuestionsVisible(false);
                if (typeof onSelectQuestion === 'function') {
                    await onSelectQuestion(message);
                }
            } else {
                console.error('Invalid messageKey:', messageKey);
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    return (
        <div className="chat-questions-container">
            <div className="chat-questions-title">
                ANN AI Software Admin
            </div>
            <img src={brain_image} className="chat-questions-image" alt="Brain" />
            <Typography variant="h4" className="chat-powered-expert">
                Your ANN-powered Expert
            </Typography>
            <div>
                <div className="my-component-container">
                    <div className="header">
                        <div className="header-text">
                            I’m Your Tasca AI Agent. Enter a prompt or choose a suggestion to start a conversation. I’ll respond using data from within the organization.
                        </div>
                    </div>
                    <div className="suggestions">
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <button className="suggestion" onClick={() => handleShowConversations('modules')}>
                                    {suggestionMessages.modules}
                                </button>
                                <button className="suggestion" onClick={() => handleShowConversations('appointments')}>
                                    {suggestionMessages.appointments}
                                </button>
                            </Grid>
                            <Grid item xs={6}>
                                <button className="suggestion" onClick={() => handleShowConversations('activities')}>
                                    {suggestionMessages.activities}
                                </button>
                                <button className="suggestion" onClick={() => handleShowConversations('channelConversations')}>
                                    {suggestionMessages.channelConversations}
                                </button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="single-column">
                            <Grid item xs={12}>
                                <div className="suggestion" style={{ width: '50%', marginLeft: '25%' }} onClick={() => handleShowConversations('responseRate')}>
                                    {suggestionMessages.responseRate}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            {isLoading && isChatQuestionsVisible && <CircularProgress className="spinner" />} {/* Show spinner if isLoading prop is true and questions are visible */}
        </div>
    );
};

export default ChatQuestions;
