import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 3% auto;
  height: -webkit-fill-available;
`;

export const TabsHeader = styled.div`
  display: flex;
`;

export const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? '#ddd' : '#f1f1f1')};
  border-bottom: ${(props) => (props.isActive ? '2px solid #000' : 'none')};

  &:hover {
    background-color: #ddd;
  }
`;

export const TabContent = styled.div`
  padding: 20px;
  border: 1px solid #ddd;
  border-top: none;
  height: 100%;
`;

export const TabContainer = styled.div`
  height: 100%;
`;

