import { useEffect, useState, useMemo, useCallback } from "react";
import {
  InputWrapper,
  InputField
} from "./RuleInput.styled";

const RuleInput = ({ defaultValue = '', onChange, type }) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const isValidDateTime = (value) => {
    const datetimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
    return datetimeRegex.test(value);
  };

  const isValidTime = (value) => {
    const datetimeRegex = /^\d{2}:\d{2}$/;
    return datetimeRegex.test(value);
  };

  const onHandleChange = (e) => {
    let isValid = true;
    if (type === "DATE") {
      isValid = isValidDateTime(e.target.value);
    }

    if (type === "TIME") {
      isValid = isValidTime(e.target.value);
    }

    setValue(e.target.value);

    if (isValid) {
      onChange && onChange(e.target.value);
    }
  }

  const typeInput = useMemo(() => { 
    return type === 'NUMBER' ? 'number' : 'text' 
  }, [type]);

  const placeholder = useMemo(() => {
    return type === 'DATE' ? 'yyyy-mm-dd hh:mm' : type === 'TIME' ? 'hh:mm' : 'Enter value';
  }, [type]);

  const onHandleBlur = useCallback(() => {
    if (type === "DATE") {
      if (!isValidDateTime(value)) {
        alert('Please enter a valid datetime in the format yyyy-mm-dd hh:mm');
      }
    }

    if (type === "TIME") {
      if (!isValidDateTime(value)) {
        alert('Please enter a valid time in the format hh:mm');
      }
    }
  }, [value, type]);

  return (
    <InputWrapper>
      <InputField
        value={value}
        type={typeInput}
        placeholder={placeholder}
        onChange={onHandleChange} />
    </InputWrapper>
  );
}

export default RuleInput;