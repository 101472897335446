import React from 'react';
import {
    Backdrop,
    ModalWrapper,
    ModalSubtitle,
    ModalContainer,
    ModalTitle,
    ModalCloseButton,
} from "./WarningModal.styled";
import { Icon } from '../../elements';
import XIcon from '../../elements/icon/assets/x_icon.svg';

const WarningModal = ({ isOpen, onClose, title, subtitle, height, width }) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <Backdrop isOpen={isOpen}>
            <ModalWrapper $height={height} $width={width}>
                <ModalCloseButton src={XIcon} width={15} height={15} onClick={() => handleClose()} />
                <ModalContainer>
                    <Icon name={'warning'} width="100px" height="100px" />
                    <ModalTitle>{title}</ModalTitle>
                    <ModalSubtitle>{subtitle}</ModalSubtitle>
                </ModalContainer>
            </ModalWrapper>
        </Backdrop>
    );
};

export default WarningModal;
