import styled from 'styled-components';

const ViewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 80px;
    height: 76vh;
    gap: 24px;
`;

const RulesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--Clear-gray, #BABABA);
  background: var(--White, #FFF);
`;

const RulesHeaderWrapper = styled.div`
  padding: 16px;
`;

const RulesMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  color: ${(props) => props.theme.colors.dark};
  font-size: 0.875rem;
  background: ${(props) => props.theme.colors.lightRed};
  border-top: 1px solid var(--Clear-gray, #BABABA);
  ${(props) => props.theme.fonts.variant.semiBold()};
  gap: 8px;
`;

const RulesFooterWrapper = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  border-top: 1px solid var(--Clear-gray, #BABABA);
`;

const RulesContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  background: var(--White-gray, #FAFAFA);
`;

export {
  ViewWrapper,
  RulesWrapper,
  RulesHeaderWrapper,
  RulesMessageWrapper,
  RulesContentWrapper,
  RulesFooterWrapper
};
