import { useState, useEffect } from "react";
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import { useStore } from "../../../../hooks";
import {
    ContactInfo,
    Name,
    UserContainer,
    Value,
    Title
} from "./IndustryDetails.styled";
import { Tooltip } from "@mui/material";

const IndustryDetails = observer(() => {
    const { annUnifiedCustomerProfileStore } = useStore();
        const [openEditModal, setOpenEditModal] = useState(false);
        const [ucp, setUCP] = useState({});

        useEffect(() => {
            if (annUnifiedCustomerProfileStore.ucp) {
                setUCP(annUnifiedCustomerProfileStore.ucp);
            }
        }, [annUnifiedCustomerProfileStore.ucp]);

    return (
        <UserContainer> 
            <ContactInfo>
                <Tooltip title={ucp.industry}>
                    <Name>Industry&nbsp;</Name>
                    <Title>{ucp.industry ? ucp.industry: 'No Data Available'}</Title>
                </Tooltip>
                <Tooltip title={ucp.state_region}>
                    <Name>State / Region&nbsp;</Name>
                    <Title>{ucp.state_region ? ucp.state_region: 'No Data Available'}</Title>
                </Tooltip>
                <Tooltip title={ucp.city}>
                    <Name>City&nbsp;</Name>
                    <Title>{ucp.city ? ucp.city: 'No Data Available'}</Title>
                </Tooltip>
            </ContactInfo>
        </UserContainer>
    );
});

export default IndustryDetails;