import React, { useContext, useState, useMemo, useCallback, useEffect } from "react";
import {
  MenuWrapper,
  Menu,
  AccountWrapper,
  AccountInfoWrapper,
  CustomAccountModalBodyListContainerIcon,
  AccountName,
  CustomAccountModalWrapper,
  MenuContainer,
  FooterWrapper,
  MenuContent,
  DropdownAccountContainer,
  DropdownItem,
  SubMenuList,
  SubMenuItem,
} from "./App.styled";

import {
  CollapsedLogoWrapper,
  CollapsedCloseWrapper,
  ToogleIconWrapper,
  OpenCloseSidebarWrapper,
} from "../App.styled";

import CustomAccountModal from "../CustomerAccountModal";
import { Icon } from "../../../elements";
import CustomizedDropdownList from "./CustomizedDropdownList";
import { SidebarContext } from "./SidebarContext";
import { useStore } from "../../../hooks";
import { menuItems, accountItems } from "../consts";
import { Auth } from "aws-amplify";
import { hideHelpContainer, showHelpButton } from '../../../elements/SupporButton/supportUtils';

const Sidebar = ({ handleMenuItemClick, navigate }) => {
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);
  const { authStore } = useStore();
  const [isCustomAccountModalOpen, setIsCustomAccountModalOpen] = useState(false);
  const [customAccountModalItems, setCustomAccountModalItems] = useState([]);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [observerHelpButton, setObserver] = useState(null);

	useEffect(() => {
		const mutationObserver = new MutationObserver(() => {
		  hideHelpContainer();
		});
	
		setObserver(mutationObserver);
	
		return () => mutationObserver.disconnect();
	  }, []);

	  const observeIframe = useCallback(() => {
		const iframe = document.querySelector('iframe#jsd-widget');
		if (iframe) {
		  const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
		  if (observerHelpButton) {
        observerHelpButton.observe(iframeDocument, { childList: true, subtree: true });
		  }
		}
	  }, [observerHelpButton]);

	  useEffect(() => {
		const iframe = document.querySelector('iframe#jsd-widget');
		if (iframe) {
		  hideHelpContainer();
		  observeIframe();
		}
	  }, [observeIframe]);

  const getAccountSelector = useCallback(() => {
    return authStore.sortedAccountListByFavs.map((item) => ({
      text: item.name,
      id: item.id,
      onClick: () => handleAccountSelector(item.id),
    }));
  }, [authStore.sortedAccountListByFavs]);

  
  const handleAccountSelector = useCallback(
    (id) => {
      authStore.pickAccount(id);
      setIsCustomAccountModalOpen(false);
    },
    [authStore],
  );

  const handleCustomAccountModal = useCallback(() => {
    if (authStore.isGroupSuperAdmin) {
      setCustomAccountModalItems(getAccountSelector());
      setIsCustomAccountModalOpen(true);
    }
  }, [authStore.isGroupSuperAdmin, getAccountSelector]);

  const closeAccountModal = () => {
    setIsCustomAccountModalOpen(false);
  };

  const handleSignOut = useCallback(() => {
    Auth.signOut()
      .then((data) => authStore.clear())
      .catch((error) => console.log("error signing out: ", error));
  }, [authStore]);

  const toggleAccountMenu = () => {
    setIsAccountMenuOpen(!isAccountMenuOpen);
  };

  const { memoizedMenuItems, memoizedAccountItems } = useMemo(() => {
    return {
      memoizedMenuItems: menuItems(navigate),
      memoizedAccountItems: accountItems(
        handleSignOut,
        handleCustomAccountModal,
        showHelpButton
      ),
    };
  }, [navigate, handleSignOut, handleCustomAccountModal]);

  return (
    <MenuWrapper isCollapsed={!isSidebarOpen}>
      <Menu isCollapsed={!isSidebarOpen}>
        <MenuContainer>
          <MenuContent id="menuContent">
            <OpenCloseSidebarWrapper isCollapsed={!isSidebarOpen}>
              {!isSidebarOpen ? (
                <ToogleIconWrapper onClick={toggleSidebar}>
                  <Icon
                    name={"arrowWhiteRight"}
                    width={"16px"}
                    height={"16px"}
                  />
                </ToogleIconWrapper>
              ) : (
                <>
                  <CollapsedLogoWrapper onClick={() => navigate("/home")}>
                    <Icon
                      name={"newLogoWhite"}
                      width={"34px"}
                      height={"25px"}
                    />
                  </CollapsedLogoWrapper>

                  <CollapsedCloseWrapper onClick={toggleSidebar}>
                    <Icon name={"closeWhite"} width={"16px"} height={"16px"} />
                  </CollapsedCloseWrapper>
                </>
              )}
            </OpenCloseSidebarWrapper>

            <DropdownAccountContainer>
              <DropdownItem onClick={toggleAccountMenu}>
                <AccountWrapper>
                  <AccountInfoWrapper>
                    <CustomAccountModalBodyListContainerIcon>
                      {`${authStore.selectedAccount.name
                        .split(" ")
                        .slice(0, 2)
                        .map((word) => word[0])
                        .join("")}`}
                    </CustomAccountModalBodyListContainerIcon>
                    <AccountName>
                      {authStore.selectedAccount.name
                        .toLowerCase()
                        .replace(/\w\S*/g, (w) =>
                          w.replace(/^\w/, (c) => c.toUpperCase()),
                        )}
                    </AccountName>
                  </AccountInfoWrapper>
                  <Icon
                    name={isAccountMenuOpen ? "arrowWhiteUp" : "arrowWhiteDown"}
                    width="12px"
                    height="12px"
                  />
                </AccountWrapper>
              </DropdownItem>
              {isAccountMenuOpen && (
                <SubMenuList>
                  {memoizedAccountItems.map((item, index) => (
                    <SubMenuItem key={index} onClick={item.onClick}>
                      {item.text}
                    </SubMenuItem>
                  ))}
                </SubMenuList>
              )}
            </DropdownAccountContainer>

            <CustomizedDropdownList
              items={memoizedMenuItems.filter(
                (item) =>
                  (item.superAdminOnly && authStore.isGroupSuperAdmin) ||
                  !item.superAdminOnly,
              )}
              onItemClick={handleMenuItemClick}
            />
          </MenuContent>

          <FooterWrapper>
            Powered by
            <br />
            Automotive Neural Networks
          </FooterWrapper>
        </MenuContainer>
      </Menu>

      {isCustomAccountModalOpen && (
        <CustomAccountModalWrapper id="custom-account-mobile-modal">
          <CustomAccountModal
            items={customAccountModalItems}
            onClose={closeAccountModal}
          />
        </CustomAccountModalWrapper>
      )}
    </MenuWrapper>
  );
};

export default Sidebar;
