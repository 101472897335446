import { styled } from "styled-components";

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 56px;
    background-color: ${(props) => props.theme.colors.highClearGray };
    border-radius: 8px;
    cursor: pointer;
    align-self: stretch;
    gap: 24px;
    padding: 8px 16px;
`;

const CheckboxLabel = styled.span`
    font-weight: 400;
    font-size: ${props => props.theme.fonts.size.b1};
    margin-top: 3px;
    color: ${(props) => props.theme.colors.darkGray };
    cursor: pointer;
    line-height: 19px;
    text-transform: ${props => props.textTransform || 'capitalize'};
`;

const CustomCheckboxInput = styled.input.attrs({ type: 'checkbox' })`
    -webkit-appearance: none;
    appearance: none;
    min-width: 24px;
    min-height: 24px;
    border-radius: 3px;
    border: 2px solid ${(props) => props.theme.colors.clearGray };
    background-color: ${(props) => props.theme.colors.highClearGray };
    outline: none;
    cursor: pointer;

    &:checked {
        background-color: ${(props) => props.theme.colors.clearBlue };
        border: 2px solid ${(props) => props.theme.colors.clearBlue };
        position: relative;
    }

    &:checked:before {
        content: "\\2714";
        font-size: 1.2em;
        color: #fff;
        position: absolute;
        right: 2px;
        top: -3px;
    }
`;

export {
    CheckboxWrapper,
    CheckboxLabel,
    CustomCheckboxInput
}

