import styled, { css } from 'styled-components';

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 5;

    ${(props) => props.isOpen && css`
        display: flex;
    `}
`;

const ModalWrapper = styled.div`
    position: relative;
    width: 750px;
    height: fit-content;
    background-color: ${props => props.theme.colors.white};
    border-radius: 10px;
`;

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    padding: 56px;
`;

const ModalTitle = styled.div`
    margin-top: 32px;
    font-size: ${props => props.theme.fonts.size.s1};
    margin-bottom: 0;
    font-weight: 500;
    color: ${props => props.theme.colors.darkGray};
`;

const ModalDescription = styled.p`
    display: inline-block;
    margin: 8px 0 32px 0;
    font-weight: 300;
    font-size: ${props => props.theme.fonts.size.lt1};
`;

const ModalSubtitle = styled(ModalTitle)`
    font-size: ${props => props.theme.fonts.size.b2};
    margin: 0;
`;

const ModalButtonsContainer = styled.div`
    display: flex;
    gap: 30px;
`;

const DetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`;

const Divider = styled.div`
    height: 73px;
    width: 1px;
    background-color: ${props => props.theme.colors.clearGray};
`;

const DetailCell = styled.span`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

const NameContainer = styled.div`
    display: flex;
    border-radius: 30px;
    padding: 5px;
    border: 1px solid ${props => props.theme.colors.clearGray};
    gap: 5px;
    font-size: ${props => props.theme.fonts.size.b2};
    font-weight: 500;

    span {
        background-color: ${props => props.theme.colors.clearBlue};
        border-radius: 100px;
        padding: 4px;
        gap: 10px;
        height: 22px;
        width: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 400;
    }

    p {
        display: inline-block;
        margin: 0;
    }
`;

const DateContainer = styled.div`
    display: flex;
    gap: 10px;
    font-size: ${props => props.theme.fonts.size.b2};
    font-weight: 300;
    justify-content: center;

    p {
        display: inline-block;
        margin: 0;
    }
`;

const ModalCloseButton = styled.img`
    position: absolute;
    top: 18px;
    right: 19px;
    cursor: pointer;
    font-weight: 400;
`;

export {
    ModalWrapper,
    ModalTitle,
    ModalSubtitle,
    ModalButtonsContainer,
    Backdrop,
    ModalContainer,
    DetailsContainer,
    Divider,
    ModalDescription,
    DetailCell,
    NameContainer,
    DateContainer,
    ModalCloseButton
};
