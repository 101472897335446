import {observer} from "mobx-react";
import { StepWrapper, FormWrapper } from "./Components.styled";
import {SubTitleStep, TitleStep} from "../CreateCustomerStepper.styled";
import {InputText} from "../../../../elements";
import {useStore} from "../../../../hooks";
import {useCallback} from "react";

const DomainStep = observer(() => {
  const { customer } = useStore();
  const createStore = customer.currentPage;

  const onHandleChangeDomain = useCallback((e) => {
    DomainValidation(e.target.value)

    createStore.onChangeDomain(e.target.value);
  }, [createStore]);

  const DomainValidation = (item) => {
    const domainPattern = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
      if (domainPattern.test(item)){
          createStore.setDomainValid(true)
      }else{
          createStore.setDomainValid(false)
      }

  };

  return (
    <StepWrapper>
      <TitleStep>That's great to hear!<br />Understanding your needs and preferences helps us serve you better.</TitleStep>
      <SubTitleStep>As we wrap up, could you specify which domain you'll need access to?</SubTitleStep>
      <FormWrapper top="90px" bottom="90px">
        <InputText placeholder="Domain" value={createStore.domain} onChange={onHandleChangeDomain} />
      </FormWrapper>
    </StepWrapper>
  );
});

export default DomainStep;
