import { API } from "aws-amplify";
import { action, flow, makeObservable, observable, toJS } from "mobx";
import { getAgent, getMessages, getConversationsHistory } from "../../../graphql/queries";
import dayjs from 'dayjs'

class HistoryAgentStore {
    agents = [];
    name = "";
    id = "";
    isLoading = false;
    isError = false;
    actions = [];
    conciergeAgent = null;
    isChatQuestionsVisible = true;
    bottomRef = null;
    lastConversationID = "";
    conversationStarters = [
        "What is your main purpose?",
        "How can you assist me?",
        "Tell me about your capabilities.",
        "What specific knowledge do you have available?",
    ]
    agentQuestions = [];
    conversations = [];
    messagesData = [];
    user_id = "";

    constructor() {
        makeObservable(this, {
            agents: observable,
            name: observable,
            id: observable,
            isLoading: observable,
            isError: observable,
            actions: observable,
            conciergeAgent: observable,
            isChatQuestionsVisible: observable,
            bottomRef: observable,
            agentQuestions: observable,
            conversations: observable,
            messagesData: observable,
            lastConversationID: observable,
            user_id: observable,
            getAgents: flow,
            fetchConciergeAgent: flow,
            fetchConversations: flow,
            fetchMessages: flow,
            setIsChatQuestionsVisible: action,
            setBottomRef: action,
            setConversationsStarters: action,
            setLastConversationID: action,
            addConversation: action,
            clearMessagesData: action,
        });
    }

    *getAgents(customer_id, agent_id) {
        try {
            this.isLoading = true;
            this.isError = false;

            // Check if agents are already fetched
            if (!this.agents.length) {
                const response = yield API.graphql({
                    query: getAgent,
                    variables: { input: { customer_id } },
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                const agents = JSON.parse(response.data?.getAgent?.body);
                this.agents = agents.filter((agent) => agent.provider === "openai");
            }

            const selectedAgent = this.agents.find(agent => agent.id === agent_id);

            if (selectedAgent) {
                this.name = selectedAgent.name;
                this.id = selectedAgent.id;
                this.actions = selectedAgent.actions;
            }
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
    }

    *fetchConciergeAgent(customer_id) {
        try {
            this.isLoading = true;
            this.isError = false;

            // Check if conciergeAgent is already fetched
            if (!this.conciergeAgent) {
                const response = yield API.graphql({
                    query: getAgent,
                    variables: { input: { customer_id, domain: "concierge" } },
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                const agents = JSON.parse(response.data.getAgent.body).filter(
                    (agent) => agent.provider === "openai"
                );
                this.conciergeAgent = agents[0];
            }
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
    }

    setIsChatQuestionsVisible(visible) {
        this.isChatQuestionsVisible = visible;
    }

    setBottomRef(ref) {
        this.bottomRef = ref;
    }

    setConversationsStarters = (selectedAgent) => {
        if (
            selectedAgent?.conversation_starters.length &&
            !selectedAgent?.conversation_starters.every((el) => !el)
        ) {
            this.agentQuestions = selectedAgent.conversation_starters;
        } else {
            this.agentQuestions = this.conversationStarters;
        }
    };

    *fetchConversations(agentId, userId) {
        try {
            this.isLoading = true;
            this.clearMessagesData();
            const response = yield API.graphql({
                query: getConversationsHistory,
                variables: { input: { user_id: userId, agent_id: agentId } },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            this.user_id = userId;
            const conversations = JSON.parse(response.data?.getConversationsHistory?.body)?.items.sort((a, b) => {
                return new Date(b.last_modified) - new Date(a.last_modified);
            });

            // Ensure no duplicates
            this.conversations = conversations.filter((conv, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === conv.id
                    ))
            );
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
    }

    *fetchMessages(conversationID) {
        try {
            this.isLoading = true;
            const response = yield API.graphql({
                query: getMessages,
                variables: { input: { conversationId: conversationID, lastEvaluatedKey: '' } },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });

            this.messagesData = JSON.parse(response.data?.getMessages?.body).map(message => ({
                time:  dayjs(message.insert_date).format('HH:mm'),
                message: message.message,
                type: message.sender === this.user_id ? 'sent' : 'received',
            }));
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
    }

    setLastConversationID(conversationId) {
        this.lastConversationID = conversationId
    }

    addConversation = (conversation) => {
        const existingConversationIndex = this.conversations.findIndex(
            (conv) => conv.id === conversation.id
        );

        if (existingConversationIndex !== -1) {
            // Update the existing conversation
            this.conversations[existingConversationIndex] = {
                ...this.conversations[existingConversationIndex],
                ...conversation,
            };
        } else {
            // Add a new conversation
            this.conversations.unshift(conversation);
        }
    };

    clearMessagesData = (() => {
        this.messagesData = [];
    });
}

export default HistoryAgentStore;