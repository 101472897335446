import styled from "styled-components";
import { MediaQuery } from "../../sharedStyles/MediaQuery";

export const muiTablePaperProps = {
  sx: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100%",
    border: "none",
    boxShadow: "none",
  },
};

export const TableHeaderStyle = {
  paddingY: "8px",
  justifyContent: "center",
  backgroundColor: "#F2F2F2",
  fontSize: "14px",
  fontWeight: "600",
};

export const muiTableContainerProps = {
  sx: {
    height: "700px",
    overflowY: "auto",
    ...MediaQuery,
  },
};

export const muiPaginationProps = {
  rowsPerPageOptions: [10, 13, 15, 17, 19, 21, 30, 40],
  showFirstButton: false,
  showLastButton: false,
  labelRowsPerPage: "Show",
  showRowsPerPage: true,
  variant: "outlined",
  shape: "rounded",
};

export const muiTableBodyRowProps = {
  sx: {
    height: "42px",
  },
};

export const displayColumnDefOptions = {
  "mrt-row-actions": {
    enableColumnVisibility: false,
  },
  "mrt-row-select": {
    enableColumnVisibility: false,
  },
  "mrt-row-numbers": {
    enableColumnVisibility: false,
  },
};

export const muiTableHeadCellProps = {
  sx: {
    textAlign: "left",
    height: "42px",
    borderBottom: "none",
    boxShadow: "none",
    ...TableHeaderStyle,
  },
};
