import styled from 'styled-components';


const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	background: ${props => props.theme.colors.white};
	border: 1px solid ${props => props.theme.colors.borderGray};
	width: auto;
	height: auto;
	padding: 15px;
`

const CardHeader = styled.div`
	order: 1;
	flex: 0 1 auto;
	align-self: auto;
	display: flex;
	justify-content: space-between;
	align-content: flex-start;
	margin-bottom: 4px;
	height: auto;
`

const CardTitle = styled.div`
	order: 1;
	flex: 0 1 auto;
	align-self: auto;
		
`

const Title = styled.h4`
	margin:5px 0;
	text-align: left;
`

const CardDescription = styled.div`
	order: 1;
	flex: 0 1 auto;
	align-self: auto;
	height: 80px;
  min-height: 70px;
	margin-bottom: 10px;
`

const Description = styled.p`
	margin:10px 0;
	text-align: left;
	font-size: 0.8125em;
  color: ${props => props.theme.colors.taupeGray};
`

const CardFooter = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 5px;
  align-self: auto;
  flex: 0 1 auto;
	order: 2
`

const Action = styled.div`
	cursor: pointer;
	width: auto;
  height: auto;
`

const SettingsContainer = styled.div`
	width: auto;
	height: auto;
	cursor: pointer;
`

export {
	CardContainer,
	CardHeader,
	CardTitle,
	CardDescription,
	Title,
	Description,
	CardFooter,
	Action,
	SettingsContainer
}