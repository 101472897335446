import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import dayjs from "dayjs";
import StatusBadge from "../../../../../elements/StatusBadge";
import { observer } from "mobx-react";
import { useStore } from "../../../../../hooks";
import { SuccessfulModal } from "../../../../../elements";
import { muiTableBodyCellProps, displayColumnDefOptions, muiTableHeadCellProps, muiTablePaperProps } from "./MarketingTab.styled";
import { TopToolbar } from "../../../../../elements/sharedStyles/TopToolbar";
import { MediaQuery } from "../../../../../elements/sharedStyles/MediaQuery";
import { useMediaQueries } from "../../../../../utils/mediaQueries";
import { getDensity } from "../../../../../utils/GetDensity/GetDensity";
import { createSearchParams, useNavigate } from "react-router-dom";

const MarketingTab = observer(() => {
  const navigate = useNavigate()
  const { authStore, marketingStore } = useStore();
  const { isMobile, isTablet, isDesktop } = useMediaQueries();
  const density = useMemo(
    () => getDensity(isMobile, isTablet, isDesktop),
    [isMobile, isTablet, isDesktop]
  );
  const [sorting] = useState([{ id: "contact_name", desc: false }]);


  useEffect(() => {
    if (authStore.selectedAccount) {
      marketingStore.getMarketingConversations(authStore.selectedAccount.id)
    }
  }, [authStore.selectedAccount]);

  const navigateToMarketingView = (id) => {
    navigate({
      pathname: "/v2/conversations/marketing",
      search: createSearchParams({
          id: id,
      }).toString(),
    })
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ renderedCellValue }) => {
          return (
            <StatusBadge status={renderedCellValue}>
              {renderedCellValue}
            </StatusBadge>
          );
        },
      },
      {
        accessorKey: "name",
        header: "Marketing Name",
      },
      {
        accessorKey: "metadata",
        header: "Contacts",
        Cell: ({ cell }) => cell.getValue()?.segment_count || 0
      },
      {
        accessorKey: "sent",
        header: "Sent",
      },
      {
        accessorKey: "reply",
        header: "Reply",
      },
      {
        accessorKey: "start_datetime",
        header: "Start date",
        filterVariant: "date",
        sortingFn: "datetime",
        Cell: ({ cell }) =>
          cell.getValue()
            ? dayjs(cell.getValue()).format("MM/DD/YYYY - HH:mm")
            : "---",
      },
      {
        accessorKey: "end_datetime",
        header: "End date",
        filterVariant: "date",
        sortingFn: "datetime",
        Cell: ({ cell }) =>
          cell.getValue()
            ? dayjs(cell.getValue()).format("MM/DD/YYYY - HH:mm")
            : "---",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    muiTopToolbarProps: {
      component: TopToolbar,
    },
    muiTableContainerProps: {
      sx: MediaQuery,
    },
    data: marketingStore.marketingConversations,
    enableRowSelection: true,
    enableSorting: false,
    enableColumnFilters: false,
    showGlobalFilter: false,
    enableHiding: true,
    enableColumnActions: false,
    enableColumnResizing: false,
    enableFullScreenToggle: true,
    enableDensityToggle: true,
    enableTopToolbar: true,
    positionToolbarAlertBanner: "none",
    initialState: {
      sorting,
      density: density,
    },
    renderTopToolbarCustomActions: () => null,
    muiTablePaperProps,
    displayColumnDefOptions,
    muiTableHeadCellProps,
    muiTableBodyCellProps,
    positionActionsColumn: "last",
    paginationDisplayMode: "pages",
    state: {
      isLoading: marketingStore.isLoading,
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 13, 15, 17, 19, 21, 30, 40],
      showFirstButton: false,
      showLastButton: false,
      labelRowsPerPage: "Show",
      showRowsPerPage: true,
      variant: "outlined",
      shape: "rounded",
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => navigateToMarketingView(row.original.id),
      sx: {
        cursor: 'pointer',
      },
    }),
  });

  return (
    <>
      <MaterialReactTable table={table} />
      {marketingStore.isError && (
        <SuccessfulModal
          isOpen={marketingStore.isError}
          onClose={() => marketingStore.onClearError()}
          title="Oops!"
          subtitle="An error occurred."
          subtitle2="Please try again later."
          zIndex={true}
        />
      )}
    </>
  );
});

export default MarketingTab;
