import { action, makeObservable, observable, flow } from "mobx";

class HumanInTheLoopStore {
  humanInTheLoop = [];
  totalRowCount = 0;
  isLoading = false;
  isError = false;
  currentPage = 1;
  pageSize = 10;

  constructor() {
    makeObservable(this, {
      humanInTheLoop: observable,
      totalRowCount: observable,
      isLoading: observable,
      isError: observable,
      currentPage: observable,
      pageSize: observable,
      getData: flow,
      setError: action,
      onClearError: action,
    });
  }


  *getData({ customer, pageIndex, pageSize, sortBy, search }) {
    this.isLoading = true;
    this.isError = false;

    try {
      
      yield new Promise((resolve) => setTimeout(resolve, 500));

      const mockData = [
        { id: 1, customer: 'johndoe@example.com', 
          message: 'This is an extremely long message that is being used to test the expand functionality of the table. The message contains a lot of information, possibly spanning multiple lines and paragraphs. It serves no actual purpose other than to ensure that when messages are too long to be displayed in a single line, they will be truncated with an ellipsis and allow the user to expand the message to read the full content. Testing long messages is crucial for ensuring good user experience in tables like this, where space is limited, and users need to quickly scan through many entries. The message could also contain different formatting and additional details that should not be cut off inappropriately when displayed in the table. This is why we test!', 
          date: '09/20/2024', status: 'Pending' },
        { id: 2, customer: 'janedoe@example.com', message: 'Declined. Please check the details.', date: '09/20/2024', status: 'Declined' },
        { id: 3, customer: 'jackdoe@example.com', message: 'Confirmation required for approval.', date: '09/19/2024', status: 'Approved' },
        { id: 4, customer: 'alice@example.com', message: 'Approved. Let us know if you need anything else.', date: '09/18/2024', status: 'Approved' },
        { id: 5, customer: 'bob@example.com', message: 'Declined due to missing information.', date: '09/17/2024', status: 'Declined' },
        { id: 6, customer: 'chris@example.com', message: 'Confirmation needed for further processing.', date: '09/16/2024', status: 'Approved' },
        { id: 7, customer: 'diana@example.com', message: 'Approved. Your request has been processed.', date: '09/15/2024', status: 'Approved' },
        { id: 8, customer: 'eva@example.com', message: 'Declined due to policy violation.', date: '09/14/2024', status: 'Declined' },
        { id: 9, customer: 'frank@example.com', message: 'Confirmation pending approval.', date: '09/13/2024', status: 'Approved' },
        { id: 10, customer: 'grace@example.com', message: 'Approved. All requirements met.', date: '09/12/2024', status: 'Approved' },
        { id: 11, customer: 'harry@example.com', message: 'Declined due to incomplete information.', date: '09/11/2024', status: 'Declined' },
        { 
          id: 12, 
          customer: 'isabella@example.com', 
          message: 'This is an extremely long message that is being used to test the expand functionality of the table. The message contains a lot of information, possibly spanning multiple lines and paragraphs. It serves no actual purpose other than to ensure that when messages are too long to be displayed in a single line, they will be truncated with an ellipsis and allow the user to expand the message to read the full content. Testing long messages is crucial for ensuring good user experience in tables like this, where space is limited, and users need to quickly scan through many entries. The message could also contain different formatting and additional details that should not be cut off inappropriately when displayed in the table. This is why we test!', 
          date: '09/10/2024', 
          status: 'Declined' 
        },
        { id: 13, customer: 'james@example.com', message: 'Approved. Let us know if you need more information.', date: '09/09/2024', status: 'Approved' },
        { id: 14, customer: 'karen@example.com', message: 'Declined. Please contact support.', date: '09/08/2024', status: 'Declined' },
        { id: 15, customer: 'leo@example.com', message: 'Approved. Everything is good to go.', date: '09/07/2024', status: 'Approved' },
        { id: 16, customer: 'mia@example.com', message: 'Declined due to incorrect data.', date: '09/06/2024', status: 'Declined' },
        { id: 17, customer: 'nick@example.com', message: 'Confirmation pending for review.', date: '09/05/2024', status: 'Pending' },
        { id: 18, customer: 'olivia@example.com', message: 'Approved. Let us know if anything else is needed.', date: '09/04/2024', status: 'Approved' },
        { id: 19, customer: 'paul@example.com', message: 'Declined. Please review the submission.', date: '09/03/2024', status: 'Declined' },
        { id: 20, customer: 'quincy@example.com', message: 'Confirmation required before approval.', date: '09/02/2024', status: 'Pending' },
      ];
      

      this.humanInTheLoop = mockData.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize); // Cambiado de 'contacts' a 'humanInTheLoop'
      this.totalRowCount = mockData.length;

    } catch (error) {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  setError(errorState) {
    this.isError = errorState;
  }

  onClearError() {
    this.isError = false;
  }
}

export default HumanInTheLoopStore;
