import styled, { css } from "styled-components";

export const PermissionsWrapper = styled.section`
    display: flex;
    gap: 48px;
    height: calc(100vh - 280px);
    padding: 24px 48px 0 48px;
`;

const ScrollBar = () => css`
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.primaryWhite};
  }
  
  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.clearGray};
  }
`;  

export const PermissionNavbarWrapper = styled.div`
    flex: 1;
    ${ScrollBar()} 
`;

export const PermissionsCardsWrapper = styled.div`
    flex: 5;
    > div{
        background-color: ${props => props.theme.colors.highClearGray};
        padding: 24px;
        overflow-y: auto;
        height: calc(100% - 60px);
        ${ScrollBar()}

        > div{
          background-color: ${props => props.theme.colors.primaryWhite};
        }
    }
`;

export const UserInfoTag = styled.span`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    height: 52px;
    width: fit-content;
    background-color: ${props => props.theme.colors.primaryWhite};
    padding: 8px 16px;
    font-weight: 400;
    font-size: 0.875em;
    margin-bottom: 24px;
    justify-content: center;

    p {
        margin: 0;
    }

    p:first-child{
        font-size: 1em;
    }
`;

export const ReviewContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 20px;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.highClearGray};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  font-size: 14px;
  margin-bottom: 24px;
  width: calc(100% - 90px);
  margin-left: 45px;
  margin-right: 24px;
`;

export const ReviewItemsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ReviewItem = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child)::after {
    content: '•';
    margin: 0 10px;
    color: ${props => props.theme.colors.clearGray};
    font-size: 2.5em;
  }
`;

export const ReviewLabel = styled.span`
  margin-right: 5px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 45px;
`;

export const SelectGroups = styled.div`
  display: flex;
  align-items: center;
  margin-left: 60px;
`;

export const AutoMarginSelectGroups = styled(SelectGroups)`
  margin-left: auto;
`;

export const SelectedGroupsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  margin-bottom: 20px;
  margin-left: 45px;

  .selected-group {
    padding: 5px 10px;
    border-radius: 15px;
  }
`;

export const CustomSelectComponentWrapper = styled.div`
  margin-left: 10px;
`;
