import { useEffect, useState } from "react";
import { observer } from 'mobx-react';
import {useStore} from "../../../../hooks";
import {
    PartsContainer,
    PartsDescription,
    UserContainer,
    PartsTitle,
    ExpandedParts,
    Title,
    PartsHeader,
    ExpandedPartsContainer
} from "./DetailedPartsInformation.styled";
import { RoundedButton, GenericTextModal } from "../../../../elements";

const DetailedPartsInformation = observer(() => {
    const { unifiedCustomerProfileStore } = useStore();
    const [displayDetailsForAppt, setDisplayDetailsForAppt] = useState();
    const [selectedAppt, setSelectedAppt] = useState();
    const [expandMoreDetails, setExpandMoreDetails] = useState(false);

    useEffect(() => {
        if(displayDetailsForAppt){
            setSelectedAppt(unifiedCustomerProfileStore.parts.find(appt => appt.id === displayDetailsForAppt))
        }
    }, [displayDetailsForAppt]);

    useEffect(() => {
        if(unifiedCustomerProfileStore.selectedCar){
            unifiedCustomerProfileStore.getParts();
        }

    }, [unifiedCustomerProfileStore.selectedCar])

    return (
        <UserContainer> 
            {!displayDetailsForAppt && 
                <>
                    <Title>Parts:</Title>
                   {unifiedCustomerProfileStore.parts && unifiedCustomerProfileStore.parts.map(appt => 
                        <PartsContainer key={appt.id}>
                            <PartsHeader>
                                <PartsTitle>Product: {appt.product_name}</PartsTitle>
                                <PartsDescription> Order: {appt.order_no}</PartsDescription>
                                <PartsDescription> Date: {appt.date}</PartsDescription>
                            </PartsHeader>
                            <RoundedButton width="89px" height="30px" borderRadius="4.7px" margin="12px 0 0 0" onClick={() => setDisplayDetailsForAppt(appt.id)}>Details</RoundedButton>
                        </PartsContainer>
                    )}
                </>
            }   
            {selectedAppt && displayDetailsForAppt && (
                <ExpandedParts>
                    <PartsContainer>
                            <Title>Parts:</Title>
                            <RoundedButton width="89px" height="30px" borderRadius="4.7px" onClick={() => setDisplayDetailsForAppt()}>Back</RoundedButton>
                    </PartsContainer>
                    <ExpandedPartsContainer>
                        <Title>Product: {selectedAppt.product_name}</Title>
                        <PartsDescription> Order: <span>{selectedAppt.order_no}</span></PartsDescription>
                        <PartsDescription> Quantity: <span>{selectedAppt.quantity}</span></PartsDescription>
                        <PartsDescription> Description: <span>{selectedAppt.description}</span></PartsDescription>
                        <PartsDescription> Unit Price: <span>${selectedAppt.unit_price}</span></PartsDescription>
                        <PartsDescription> Date: <span>{selectedAppt.date}</span></PartsDescription>
                    </ExpandedPartsContainer>
                </ExpandedParts>
            )}

            {selectedAppt && displayDetailsForAppt && <GenericTextModal
              isOpen={expandMoreDetails}
              onClose={() => setExpandMoreDetails(false)}
              title={selectedAppt.name}
              body={selectedAppt.description}
              bodyMaxHeight="62vh"
              width="600px"
              height="65vh"
            />}
        </UserContainer>
    );
});

export default DetailedPartsInformation;