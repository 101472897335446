import React, { useState, useEffect } from "react";
import Widget from "./Widget";
import {
  SubTitle,
  OrdersWrapper,
  ROStatusField,
  DataAvaliableWrapper,
} from "./Widget.styled";
import { useStore } from "../../../../../hooks";
import { observer } from "mobx-react-lite";
import { Icon } from "../../../../../elements";
import Spinner from "../../../../../components/common/Spinner";
import FormattedDate from "./FormattedDate";


const RepairOrders = observer(() => {
  const { unifiedCustomerProfileStore } = useStore();
  const orders = unifiedCustomerProfileStore.repairOrdersInfo || [];

  return (
    <Widget title="Repair orders" height={orders.length === 0 ? "auto" : orders.length === 1 ? "180px" : "315px"}>
      {unifiedCustomerProfileStore.isLoading ? (
        <Spinner />
      ) : (
        <>
          {orders.length === 0 ? (
            <DataAvaliableWrapper id="dataAvaliableWrapper">
              <p>No data available</p>
              <p>
                No data is available for this card at the moment. Please check
                again later.
              </p>
              <p>
                <Icon name={"noDataAvaliable"} width={"70px"} height={"68px"} />
              </p>
            </DataAvaliableWrapper>
          ) : (
            <OrdersWrapper>
              {orders &&
                orders.map((order, index) => (
                  <ul key={index}>
                    <li>
                      <SubTitle>RO number:</SubTitle>
                      <span>{order.ro_number}</span>
                    </li>
                    <li>
                      <SubTitle>Status:</SubTitle>
                      <ROStatusField status={order.ro_status.toLowerCase()}>
                        {order.ro_status}
                      </ROStatusField>
                    </li>
                    <li>
                      <SubTitle>Description:</SubTitle>
                      <span>{order.ro_description}</span>
                    </li>
                    <li>
                      <SubTitle>RO date:</SubTitle>
                       <FormattedDate date={order.ro_date} />
                    </li>
                  </ul>
                ))}
            </OrdersWrapper>
          )}
        </>
      )}
    </Widget>
  );
});

export default RepairOrders;
