import React from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../../../hooks";
import { InputText } from "../../../../../../elements";
import { UserWrapper, UserDetails, UserContent, UserLabel, UserColumnContainer, UserPhone, UserColumn } from "./UserGeneral.styled";
import CustomButton from "../../../../../../elements/v2/Buttons/CustomButton/CustomButton";
import { ReactComponent as PendingIcon } from "../../../../../../elements/icon/assets/pending.svg";
import { ReactComponent as ConfirmedIcon } from "../../../../../../elements/icon/assets/gg_check-o.svg";
import dayjs from 'dayjs';
import Spinner from "../../../../../../components/common/Spinner";

const UserGeneral = observer(({}) => {
  const { listUserStore } = useStore();

  return (
    <UserContent>
      {listUserStore.status !== 'loading' && Object.keys(listUserStore.userData).length ? 
      <UserWrapper>
        <UserDetails>
          <UserLabel>Name</UserLabel>
          <InputText
            placeholder="First Name"
            value={listUserStore.userData.first_name || ''}
            onChange={(e) => listUserStore.updateUserData("first_name", e.target.value)}
            maxWidth="800px"
            displayError={!listUserStore.userData.first_name}
            validationError="First name is required."
          />
          <UserLabel>Last Name</UserLabel>
          <InputText
            placeholder="Last Name"
            value={listUserStore.userData.last_name || ''}
            onChange={(e) => listUserStore.updateUserData("last_name", e.target.value)}
            maxWidth="800px"
            displayError={!listUserStore.userData.last_name}
            validationError="Last name is required."
          />
          <UserLabel>Email</UserLabel>
          <InputText
            placeholder="Email"
            value={listUserStore.userData.email || ''}
            maxWidth="800px"
            backgroundColor="#ECECEC"
          />
          <UserLabel>Phone Number</UserLabel>
          <UserColumnContainer>
            <InputText
              placeholder="+1"
              value="+1"
              readOnly
              width='60px'
            />
            <UserPhone>
              <InputText
                placeholder="Phone number"
                value={listUserStore.userData.phone || ''}
                onChange={(e) => listUserStore.updateUserData("phone", e.target.value)}
                maxWidth="730px"
              />
            </UserPhone>
          </UserColumnContainer>
          <UserColumnContainer>
            <span>
              <UserLabel>Date Created</UserLabel>
              <InputText
                value={dayjs(listUserStore.userData.insert_date || '').format('MM/DD/YYYY')}
                backgroundColor="#ECECEC"
              />
            </span>
            <span>
              <UserLabel>Last Modified</UserLabel>
              <InputText
                value={dayjs(listUserStore.userData.last_modified || '').format('MM/DD/YYYY')}
                backgroundColor="#ECECEC"
              />
            </span>
          </UserColumnContainer>
          <UserLabel>Status</UserLabel>
          {listUserStore.userData.status === 'ENABLED' && (
            <CustomButton icon={ConfirmedIcon} text="Accepted" />
          )} 
          {listUserStore.userData.status === 'PENDING' &&
            <CustomButton icon={PendingIcon} text="Pending confirmation" />
          }
          {listUserStore.userData.status === 'DISABLED' &&
            <CustomButton icon={PendingIcon} text="Disabled" />
          }
        </UserDetails>
      </UserWrapper>
      :
      <UserColumn>
        <Spinner/>
      </UserColumn>
      }
    </UserContent>
  );
});

export default UserGeneral;
