import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {toJS} from "mobx";
import {CustomCheckbox} from "../../../elements";
import {useStore} from "../../../hooks";
import { FormTitle, FormAgentWrapper, CheckboxContainer } from "../NewAgentPage.styled"
import {initialTones} from "./consts";

const AgentToneForm = observer(() => {
    const { newAgent } = useStore();

    const [toneOptions, setToneOptions] = useState(initialTones)

    const onSelectTone = (tone) => {
        const updatedOptions = {...toneOptions, ...tone}
        setToneOptions(updatedOptions)
        const filteredTones = Object.keys(updatedOptions).filter(tone => updatedOptions[tone]);
        if(filteredTones){
            newAgent.onChangeTones(filteredTones);
        }
    }

    useEffect(() => {
        const savedTones = {...initialTones};
        toJS(newAgent.tones).map(tone => {
            if(tone in savedTones){
                savedTones[tone] = true
            }
        })
        setToneOptions({...savedTones})
        
    }, [])

    return (
        <FormAgentWrapper>
            <FormTitle>Fantastic!<br/> How would you like your agent's tone to be?</FormTitle>
            <CheckboxContainer>
                {toneOptions && Object.keys(toneOptions).map(option => <CustomCheckbox key={option} label={option} onSelectTone={onSelectTone} options={toneOptions}/>)}
            </CheckboxContainer>
        </FormAgentWrapper>
    );
});

export default AgentToneForm;
