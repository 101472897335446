import styled from "styled-components";

const AgentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const AgentsTitle = styled.p`
  font-size: 18px;
  font-weight: 700;
`;

const AgentsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const AgentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

const AgentImage = styled.img`
  width: 60px;
  height: 60px;
`;

export {
  AgentsContainer,
  AgentsTitle,
  AgentsList,
  AgentItem,
  AgentImage,
};
