import styled, { createGlobalStyle } from "styled-components";

const TabContainer = styled.div`
  display: grid;
  grid-template-columns: 500px auto;
`;

const TabButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;

const BreadCrumb = styled.div`
  padding: 0.5rem 1rem;
  font-size: 0.625rem;
  font-weight: bold;

  & > span {
    color: ${(props) => props.theme.colors.activeBlue};
  }
`;

const MarketingContainer = styled.div`
  padding: 1rem;
  & > div:nth-child(2) > div:nth-child(1) > div {
    font-size: 0.875rem;
  }

  & > div:nth-child(2) > div:nth-child(1) {
    padding: ${(props) => (props.isMobile ? "0 0" : "0 1rem")};
  }

  & > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) {
    padding-top: 1rem;
  }

  & > div:nth-child(2) > div:nth-child(2) > div {
    padding: 1rem;
    font-size: 0.875rem;
  }
`;

const MarketingWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  padding: 0.5rem 0;
  padding-right: ${(props) => props.paddingRight};

  & > div:nth-child(2) {
    background: white;
    height: calc(100vh - 127px);
  }
`;

const MarketingWrapperHeader = styled.div`
  display: grid;
`;

const MarketingWrapperContent = styled.div`
  display: grid;
`;

const TemplateContainer = styled.div`
  padding-top: 1rem;
`;

const MessageActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const UploadFilesActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const SegmentsActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-inline-end: 270px;
`;


//Segments CSS

const SegmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: calc(100vh - 165px);
  padding-top: 1rem;
  overflow-y: auto;

  & > div:nth-child(2) > div > div {
    padding-left: 0;
  }
`;

const ContactsCard = styled.div`
  width: 142px;
  height: 50px;
  padding: 0 1rem;
  background: ${(props) => props.theme.colors.highClearGray};
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.2;
`;

const CloseCard = styled.div`
  width: 154px;
  height: 50px;
  padding: 0 1rem;
  background: ${(props) => props.theme.colors.highClearGray};
  border-radius: 0.625rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ContactsSegmentTableWrapper = styled.div``;

const ContactStatus = styled.div`
  width: 69px;
  height: 21px;
  font-size: 12px;
  background: ${(props) => props.theme.colors.aquamarineGreen};
  color: ${(props) => props.theme.colors.perlDarkGray};
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const defaultCellProps = {
  size: 165,
  muiTableBodyCellProps: {
    align: "left",
    sx: {
      fontSize: "12px",
      padding: "4px 8px",
    },
  },
  muiTableHeadCellProps: {
    backgroundColor: "#f2f2f2",
    align: "left",
    sx: {
      padding: "8px",
      paddingTop: "0.4rem",
      "& .MuiCheckbox-root": {
        transform: "scale(0.6)",
      },
      backgroundColor: "#f2f2f2",
      height: "42px",
    },
  },
};

const SegmentsWrapper = styled.div`
  display: block;
  width: ${(props) => (props.isTablet ? "84vw" : "100%")};

  & > div:nth-child(2) > div:first-child {
    width: calc(100% - 230px);
  }
`;

//Overview

const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 16px 0;
  height: ${(props) => (props.isMobile ? "73vh" : "83vh")};
  overflow-y: ${(props) => (props.isMobile ? "scroll" : "none")};
  width: ${(props) => (props.isMobile ? "100%" : "100%")};
`;

const OverviewCardInfo = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: ${(props) => props.theme.colors.primaryWhite};

  > div {
    display: flex;
    justify-content: space-between;
  }
`;

const Label = styled.span`
  font-weight: 600;
  font-size: 0.875rem;
`;

const OverviewSegmentInfo = styled.div`
  width: 100%;
  padding: 0 1rem;
  min-height: 49px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.colors.primaryWhite};
`;
const ViewDetails = styled.span`
  color: ${(props) => props.theme.colors.activeBlue};
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
`;

const AgentTeamSelectorWrapper = styled.div`
  display: flex;

  & > div:nth-child(1) > div:nth-child(4) {
    width: ${(props) => (props.isMobile ? "290px" : "534px")};
  }

  &
    > div:first-child
    > div:nth-child(4)
    > div:nth-child(2)
    > div
    > div:nth-child(1) {
    display: none !important;
  }
`;

const DescriptionKnowledgeBaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const KnowledgeBaseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const OverviewKnowledgeBaseContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.isTablet ? "1fr 1fr" : props.isMobile ? "1fr" : "1fr 1fr 1fr"};
`;

const OverviewKnowledgeBaseCard = styled.div`
  background: ${(props) => props.theme.colors.white};
`;

const CustomGenericListStyle = createGlobalStyle`
  #main-content {
    overflow: auto;
  }
`;

const CustomGenericListStyleDesktop = createGlobalStyle`
  #main-content {
    overflow: hidden;
  }
`;

const CustomGenericListStyleSegments = createGlobalStyle`
  #main-content {
    overflow-x: hidden;
  }
`;

const UploadContactContainer = styled.div`
  padding-inline: 15px;
`;


export {
  UploadContactContainer,
  UploadFilesActionsWrapper,
  TabContainer,
  TabButtonContainer,
  BreadCrumb,
  MarketingContainer,
  MarketingWrapper,
  MarketingWrapperHeader,
  MarketingWrapperContent,
  TemplateContainer,

  //Segments
  SegmentsContainer,
  SegmentsActionsWrapper,
  ContactsCard,
  CloseCard,
  ContactsSegmentTableWrapper,
  ContactStatus,
  defaultCellProps,
  SegmentsWrapper,

  //Overview
  OverviewContainer,
  OverviewCardInfo,
  Label,
  OverviewSegmentInfo,
  ViewDetails,
  AgentTeamSelectorWrapper,
  DescriptionKnowledgeBaseContainer,
  DescriptionWrapper,
  KnowledgeBaseWrapper,
  OverviewKnowledgeBaseContainer,
  OverviewKnowledgeBaseCard,
  CustomGenericListStyle,
  CustomGenericListStyleDesktop,
  CustomGenericListStyleSegments,
  MessageActionsWrapper,
};
