import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {IconRadioButton} from "../../../elements";
import {useStore} from "../../../hooks";
import { FormTitle, FormSubtitle, FormUserWrapper, RadioButtonsContainer } from "../NewUserPage.Styled"
import SuperAdmin from '../../../elements/icon/assets/superadmin.svg'
import CustomerAdmin from '../../../elements/icon/assets/customeradmin.svg'
import user from '../../../elements/icon/assets/user_role.svg'

const UserRoleForm = observer(() => {
    const { userStore } = useStore();

    const [ 
        selectedValue, 
        setSelectedValue, 
    ] = useState(""); 
  
    const handleRadioChange = (value) => { 
        setSelectedValue(value);
        userStore.page.onChangeRole(value);
    }; 

    const isChecked = (value) => value == selectedValue;

    useEffect(() => {
        setSelectedValue(userStore.page.role)
    }, [])


    return (
        <FormUserWrapper>
            <FormTitle>Just one more step!</FormTitle>
            <FormSubtitle>What role will your new user have?</FormSubtitle>
            <RadioButtonsContainer>
                <IconRadioButton name="profile" title="Super Admin" img={SuperAdmin} value="superadmin" onChange={handleRadioChange} selectedValue={selectedValue} checked={isChecked('superadmin')}/>
                <IconRadioButton name="profile" title="Admin" img={CustomerAdmin} value="customeradmin" onChange={handleRadioChange} selectedValue={selectedValue} checked={isChecked('customeradmin')}/>
                <IconRadioButton name="profile" title="User" img={user} value="user" onChange={handleRadioChange} selectedValue={selectedValue} checked={isChecked('user')}/>
            </RadioButtonsContainer>
        </FormUserWrapper>
    );
});

export default UserRoleForm;
