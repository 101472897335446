import styled from "styled-components";
import searchIcon from "../../../../../elements/icon/assets/search_icon.svg";

export const CustomSelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 150px;
  letter-spacing: 0;
`;

export const CustomSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid ${props => props.theme.colors.clearGray};
  border-radius: 5px;
  background-color: ${props => props.theme.colors.primaryWhite};
  color: ${props => props.theme.colors.clearGray};
  cursor: pointer;
  max-height: 30px;
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: -350px;
  width: 500px;
  border: 1px solid ${props => props.theme.colors.clearGray};
  border-radius: 5px;
  background-color: ${props => props.theme.colors.primaryWhite};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-top: 20px;
  overflow: hidden;
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  margin: 10px;
  font-size: 15px;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  border: 1px solid ${props => props.theme.colors.clearGray};
  border-radius: 5px;
`;

export const SearchIcon = styled.span`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url(${searchIcon}) no-repeat center;
  pointer-events: none;
`;

export const GroupListContainer = styled.div`
  padding: 10px;
  font-size: 12px;
`;

export const GroupList = styled.div`
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid ${props => props.theme.colors.clearGray};
  border-radius: 5px;
`;

export const GroupItem = styled.div`
  padding: 15px 10px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.highClearGray};
  }
`;

export const SelectedGroups = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  max-height: 150px;
  overflow-y: auto;
`;

export const SelectedGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.clearGray};
  border-radius: 15px;
  font-size: 12px;

  & > span {
    margin-right: 5px;
  }

  & > button {
    border: none;
    background: none;
    cursor: pointer;
  }
`;

export const CloseIcon = styled.div`
  margin-left: 10px;
`;

export const LabelGroup = styled.div`
  margin: 15px 20px 10px;
  font-weight: bold;
  color: ${props => props.theme.colors.primaryGray};
  font-size: 14px;
`;

export const AddButton = styled.button`
  margin: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.primaryWhite};
  cursor: pointer;
  float: right;

  &:hover {
    background-color: ${props => props.theme.colors.primaryDarkBlue};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;
