import { StyledLabel, Wrapper, StyledRadio } from "./RadioButton.styled";

const RadioButton = ({ label, value, name, onChange, checked, className }) => {
    return (
        <Wrapper onClick={() => onChange(value)} className={className}>
            <StyledRadio type="radio" id={value} value={value} name={name} onChange={() => onChange(value)} checked={checked}/>
            <StyledLabel htmlFor={value}>{label}</StyledLabel>
       </Wrapper>    
    );
}

export default RadioButton;
