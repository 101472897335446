import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: ${(props) => props.width || '100%'};
`;

export const TextareaField = styled.textarea`
  width: 100%;
  padding: 10px 14px;
  border: 1px solid ${(props) => props.borderColor || props.theme.colors.cerebralGray}; 
  border-radius: 8px;
  font-size: ${(props) => props.fontSize || '14px'};
  font-weight: ${(props) => props.fontWeight || '400'};
  box-sizing: border-box;
  line-height: 1.4;
  min-height: ${(props) => props.height || '80px'};
  resize: none;

  &:focus {
    outline: none;
  }
`;

export const CharacterCount = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.grey};
  margin-top: 4px;
  text-align: left;
`;
