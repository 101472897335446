// FeatureStore.js
import { makeObservable, observable, action, flow } from "mobx";
import { API } from "aws-amplify";
import { getUsers } from "../../graphql/queries";

class FeatureStore {
  categories = [];
  features = [];
  status = 'idle';
  errorMessage = '';

  constructor() {
    makeObservable(this, {
      categories: observable,
      features: observable,
      status: observable,
      errorMessage: observable,
      fetchCategories: flow,
      fetchFeatures: flow,
      setFeatures: action,
      filterFeatures: action,
      addCategory: action,
      addFeature: action,
      removeFeature: action,
      updateFeature: action,
      setError: action,
    });

    // Fetch initial data
    this.fetchCategories();
  }

  *fetchCategories() {
    try {
      this.status = 'loading';
      this.categories = [
        'Category One', 'Category Two', 'Category Three', 'Category Four',
        'Category Five', 'Category Six', 'Category Seven', 'Category Eight',
      ];
      this.status = 'idle';
    } catch (error) {
      this.status = 'error';
      this.setError('Failed to load categories');
    }
  }

  *fetchFeatures(userId, customerId) {
    try {
      this.status = 'loading';
      const response = yield API.graphql({
        query: getUsers,
        variables: { input: { customer: customerId, id: userId } },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      const userData = JSON.parse(response.data?.getUsers?.body);
      this.setFeatures(userData.features || [
        "Improve user experience",
        "Optimize workflows",
        "Enhance security",
        "Increase performance"
      ]);

      this.status = 'idle';
    } catch (error) {
      this.status = 'error';
      this.setError('Failed to load features');
    }
  }

  setFeatures(features) {
    this.features = features;
  }

  filterFeatures(searchTerm) {
    if (!searchTerm) {
      this.filteredFeatures = this.features;
    } else {
      this.filteredFeatures = this.features.filter((feature) =>
        feature.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }

  addCategory(category) {
    if (category) {
      this.categories.push(category);
    }
  }

  addFeature(feature) {
    if (feature) {
      this.features.push(feature);
    }
  }

  removeFeature(index) {
    if (index >= 0 && index < this.features.length) {
      this.features.splice(index, 1);
      this.features = [...this.features];
    }
  }

  updateFeature(index, value) {
    if (index >= 0 && index < this.features.length) {
      this.features[index] = value;
    }
  }

  setError(message) {
    this.errorMessage = message;
    this.status = 'error';
  }
}

export default FeatureStore;
