import styled from "styled-components";

const RuleEngineWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  gap: 16px;

  border-top: 1px solid ${(props) => props.theme.colors.clearGray};
  padding: 48px 16px;

  &.segment-variant { 
    border: none;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.clearGray};
    background-color: ${(props) => props.theme.colors.whiteGray};
    margin-bottom: 16px;
  }
`;

const RuleEngineRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
  padding-right: 40px;

  &.segment-variant {
    flex-direction: column;
    padding-right: 0;
    gap: 16px;
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 48px;
  right: 16px;
  width: 24px;
  height: 24px;
  color: ${(props) => props.theme.colors.clearGray};

  img {
    opacity: 0.5;
  }

  &.segment-variant {
    position: initial;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid ${(props) => props.theme.colors.clearGray};

    img{
      opacity: 1;
    }

    > button > div {
      width: 20px;
      height: 20px;
    }
  }
`;

const ConditionWrapper = styled.div`
  position: absolute;
  top: -20px;
  padding: 0 16px;
  right: calc(50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.black};
  ${(props) => props.theme.fonts.variant.regular()};
  line-height: normal;
  font-style: normal;
  color: ${(props) => props.theme.colors.white};

  &.segment-variant {
    top: 10px;
    width: 49px;
    height: 24px;
    left: 16px;
    background-color: ${(props) => props.theme.colors.clearGray};
    color: ${(props) => props.theme.colors.black};
    font-size: 0.75em;
  }
`;

export {
  RuleEngineWrapper,
  RuleEngineRow,
  CloseButtonWrapper,
  ConditionWrapper
};
