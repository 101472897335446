import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px - 80px);
  margin: 40px 4%;
  gap: 16px;
`;

export const LoadngContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px - 80px);
  margin: 40px 4%;
  align-items: center;
  justify-content: center;
`;

export const ContainerColumns = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  gap: 23px;
`;

export const Column = styled.div`
  flex: 1;
  padding: 8px 16px 24px 16px;
  border: 1px solid ${(props) => props.theme.colors.silverGrayTwo};
  background-color: ${props => props.theme.colors.primaryWhite};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: auto;
`;

export const VerticalLine = styled.div`
  width: 1px;
  background-color: ${(props) => props.theme.colors.silverGrayTwo};
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  padding-bottom: 24px;

  @media (min-width: 792px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ActionsButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media (min-width: 792px) {
    width: auto;
    justify-content: flex-end;
  }
`;

export const Title = styled.p`
    ${(props) => props.theme.fonts.variant.regular()};
    font-size: ${props => props.theme.fonts.size.s1};
    font-weight: 600;
`;

export const ContainerInnerTab = styled.section`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 0px;
`;

export const CheckboxContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    margin-top: 10px
`;

export const RadioButtonContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 10px
`;

export const ChatToggleButton = styled.button`
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.darkCharcoal};
  }

  &:active {
    background-color: ${(props) => props.theme.colors.grey};
  }
`;

export const MobileChatWrapper = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 400px;
  background-color: white;
  z-index: 999;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isVisible }) => isVisible ? 'translateX(0)' : 'translateX(100%)'};
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

export const MobileChatHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.dividerGray};
`;

export const MobileChatCloseButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.darkCharcoal};
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) => props.theme.colors.greySeven};
  }

  &::before {
    content: '×';
  }
`;

export const MobileChatContent = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const TabsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 16px;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 109%;
  left: 13px;
  background: ${(props) => props.theme.colors.primaryWhite};
  border: 1px solid ${(props) => props.theme.colors.clearGray};
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 123px;
  width: 123px;
  
 & > button:nth-child(1){
    border-bottom: 1px solid ${(props) => props.theme.colors.clearGray};
 }

 & > button:nth-child(2){
    border-bottom: 1px solid ${(props) => props.theme.colors.clearGray};
 }
`;

export const DropdownItem = styled.button`
  width: 100%;
  padding: 8px 16px;
  background: none;
  border: none;
  text-align: left;
  color: ${(props) => props.theme.colors.greyThirteen};
  font-size: 10px;
  cursor: pointer;
  
  &:hover {
    background: ${(props) => props.theme.colors.ghostWhite};
  }
`;

export const MenuButtonWrapper = styled.div`
  position: relative;
`;

export const MenuButton = styled.button`
  margin-right: 8px;
  min-width: 32px;
  width: 40px;
  height: 40px;
  padding: 0;
  background: ${(props) => props.theme.colors.primaryWhite};
  border: 1px solid ${(props) => props.theme.colors.clearGray};
  border-radius: 8px;
  color: ${(props) => props.theme.colors.clearGray};
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
