import styled from "styled-components";
import { Avatar } from '@mui/material';
import { IconButton } from "../../../../../elements";

export const AgentNode = styled.div`
    background: ${(props) => props.theme.colors.silverGrayThree};
    border: 1px solid ${(props) => props.theme.colors.silverGrayTwo};
    width: 293px;
    height: 66px;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
`

export const NodeName = styled.div`
    ${(props) => props.theme.fonts.variant.regular};
    color: ${(props) => props.theme.colors.blackTwo};
    font-size: 1em;
    height: 18px;
`

export const NodeTitle = styled.div`
    color: ${(props) => props.theme.colors.golden};
    ${(props) => props.theme.fonts.variant.light};
    font-size: 0.875em;
    height: 14px;
    margin-top: 0.3rem;
`

export const NodeAvatar = styled(Avatar)``;

export const AgentHeader = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
    padding: 1rem 0;
`;

export const NodeIcons = styled.div`
    position: absolute;
    right: 10px;
    display: flex;
    gap: 5px;
`

export const NodeIcon = styled(IconButton)`
    img {
        object-fit: none;
    }
`