import React, {useEffect, useState} from "react";
import { I18n } from 'aws-amplify';
import {Authenticator, useAuthenticator, translations, Button} from '@aws-amplify/ui-react';
import {
  // Import styled components
  MainWrapper,
  TitleWrapper,
  FormWrapper,
  DynamicTitleAnimatedWordWrapper,
  WrapperLogoAimsByAnn,
  BoxHeaderImage,
  WrapperForgotPassword,
  FormTitle,
  FormSubtitle,
  FormOrDivider,
  FormSocialNetworks,
  SocialButton,
  FormFooter,
  Footer
} from "./Signin.styled";
import { Icon } from "../../elements";
import { Loading } from "../loading";
import BoxHeaderImageSrc from "./assets/box-header-bg.png";
import { useMediaQuery } from 'react-responsive';

// Import styles
import '@aws-amplify/ui-react/styles.css';
import './Signin.css';

// Set up internationalization
I18n.putVocabularies(translations);
I18n.setLanguage('en');

// Custom translations for the authentication UI
I18n.putVocabulariesForLanguage('en', {
    'Sign in': 'Log in',
    'Username': 'Email',
    'Password': 'Password',
    'Forgot your password?': 'Forgot Password?',
    'Create a new account': 'Sign up',
    'Reset Password': 'Forgot password',
    'Enter your Email': 'Email',
    'Send code': 'Enter your email',
    'Change Password': 'Log In',
})

const Signin = ({ children }) => {
    // Get authentication status
    const { authStatus  } = useAuthenticator(context => [context.user]);
    // State for the current word in the title animation
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    // Check if the screen is desktop size
    const isDesktop = useMediaQuery({ minWidth: 768 });

    // Custom components for the Authenticator
    const components = {
        SignIn: {
            Header() {
                return (
                  <FormTitle>Log in</FormTitle>
                );
            },
            Footer() {
                const { toResetPassword } = useAuthenticator();

                return (
                  <>
                      <WrapperForgotPassword><Button onClick={toResetPassword} variation="link">Forgot password?</Button></WrapperForgotPassword>
                      {/* Commented out social login options */}
                      {/* <FormOrDivider>
                          <div></div>
                          <span>Or</span>
                          <div></div>
                      </FormOrDivider>
                      <FormSocialNetworks>
                        <SocialButton><Icon name="apple" width="23px" height="24px" /></SocialButton>
                        <SocialButton><Icon name="microsoft" width="23px" height="24px" /></SocialButton>
                        <SocialButton><Icon name="google" width="23px" height="24px" /></SocialButton>
                      </FormSocialNetworks> */}
                  </>
                );
            }
        },
        // Custom components for other authentication steps
        ResetPassword: {
            Header() {
                return (
                  <>
                      <FormTitle>Forgot password</FormTitle>
                      <FormSubtitle>Don't worry, it happens sometimes. Please enter the email associated with your account.</FormSubtitle>
                  </>
                );
            }
        },
        ForceNewPassword: {
            Header() {
                return (
                  <>
                      <FormTitle>Create password</FormTitle>
                      <FormSubtitle>Create your new password and jump right back into the joyful ANN experience!</FormSubtitle>
                  </>
                );
            }
        },
        ConfirmResetPassword: {
            Header() {
                return (
                  <>
                      <FormTitle>Verification code</FormTitle>
                      <FormSubtitle>We sent a code to your email, please enter the code to verify your account and password, finally jump right back into the joyful ANN experience!</FormSubtitle>
                  </>
                );
            }
        }
    };

    // Configuration for form fields
    const formFields = {
        signIn: {
            username: {
                labelHidden: true,
            },
            password: {
                labelHidden: true,
            },
        },
        forceNewPassword: {
            password: {
                labelHidden: true,
            },
            confirm_password: {
                labelHidden: true,
            }
        },
        resetPassword: {
            username: {
              labelHidden: true,
          }
        },
        confirmResetPassword: {
            confirmation_code: {
                labelHidden: true,
            },
            password: {
                labelHidden: true,
            },
            confirm_password: {
                labelHidden: true,
            }
        }
    };

    // Words for the animated title
    const titleWords = [
      'Ecosystem',
      'Market of ONE',
      'Real time Service Updates',
      'Data Centralization',
      'Next Best Actions',
      'Tracking customers',
      'Personalize your Conversations',
      'Dynamic Segmentations'
    ];

    // Check if the screen is mobile size
    const isMobile = useMediaQuery({ maxWidth: 767 });

    // Effect for animating the title words on desktop
    useEffect(() => {
        if (isDesktop) {
            const wordInterval = setInterval(() => {
                setCurrentWordIndex(prevIndex => (prevIndex + 1) % titleWords.length);
            }, titleWords[currentWordIndex].length * 200 + 1000);

            return () => clearInterval(wordInterval);
        }
    }, [currentWordIndex, titleWords, isDesktop]);

    // Effect to toggle JSD widget based on screen size and route
    useEffect(() => {
        if (window.toggleJSDWidget) {
            window.toggleJSDWidget();
        }
    }, []);

    return (
      <>
          {/* Show loading screen while configuring */}
          {authStatus === 'configuring' && <Loading />}
          {/* Show children components if authenticated, otherwise show sign-in form */}
          {authStatus === 'authenticated' ? [children] : (
            <MainWrapper>
                {/* Logo */}
                <WrapperLogoAimsByAnn>
                    <Icon name="aimsByAnn" />
                </WrapperLogoAimsByAnn>
                {/* Header image */}
                <BoxHeaderImage>
                    <img src={BoxHeaderImageSrc}  alt="" />
                </BoxHeaderImage>
                {/* Animated title (desktop only) */}
                <TitleWrapper>
                    <DynamicTitleAnimatedWordWrapper>
                      {isDesktop && (
                        titleWords.map((word, wordIndex) => (
                          <div
                            key={wordIndex}
                            className={`word-container ${wordIndex === currentWordIndex ? 'visible' : ''}`}
                          >
                              {word.split('').map((letter, letterIndex) => (
                                <span
                                  key={`${wordIndex}-${letterIndex}`}
                                  className={wordIndex === currentWordIndex ? 'animate' : ''}
                                  style={{animationDelay: `${letterIndex * 0.2}s`}}
                                >
                                  {letter === ' ' ? '\u00A0' : letter}
                                </span>
                              ))}
                          </div>
                        ))
                      )}
                    </DynamicTitleAnimatedWordWrapper>
                    {isDesktop && <div className="with-aims">with AIMS</div>}
                </TitleWrapper>
                {/* Authentication form */}
                <FormWrapper>
                    <Authenticator
                      hideSignUp={true}
                      components={components}
                      formFields={formFields}
                      signUpAttributes={[ 'email', 'name', 'phone_number' ]} />
                </FormWrapper>
                {/* Footer links */}
                <FormFooter>
                    <a>Privacy policy</a>
                    <div className="divider"></div>
                    <a>Terms of use</a>
                </FormFooter>
                {/* Powered by footer */}
                <Footer>Powered by Automotive Neural Networks</Footer>
            </MainWrapper>
          )}
      </>
    );
};

export default Signin;
