import { observer } from "mobx-react";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useStore} from "../../../hooks";
import { MaterialReactTable, MRT_EditActionButtons, useMaterialReactTable} from "material-react-table";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip
} from "@mui/material";
import PenIcon from "../../../components/common/PenIcon";
import TrashIcon from "../../../components/common/TrashIcon";
import {CustomBox} from "../../../theme/DefaultTheme";
import {useNavigate} from "react-router-dom";
import {RoundedButton} from "../../../elements";
import {HeaderButtonWrapper, SearchWrapper} from "./UserData.styled";

const UserData = observer(() => {
  const navigate = useNavigate();
  const { userStore, authStore } = useStore();
  const { page } = userStore;
  const { isSuperAdmin, userRoleGroups, selectedAccount } = authStore;
  const [selectedValue, setSelectedValue] = useState('+1');

  useEffect(() => {
    page.onFetchUsers(selectedAccount.id);
  }, [selectedAccount]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "first_name",
        header: "First Name",
        muiEditTextFieldProps: {
          type: "text",
          required: true,
          error: !!page.validation.firstName,
          helperText: page.validation.firstName,
          onFocus: () => page.validation.clearFirstName(),
        },
      },
      {
        accessorKey: "last_name",
        header: "Last Name",
        muiEditTextFieldProps: {
          type: "text",
          required: false,
          error: !!page.validation.lastName,
          helperText: page.validation.lastName,
          onFocus: () => page.validation.clearLastName(),
        },
      },
      {
        accessorKey: "email",
        header: "Email",
        enableEditing: false,
      },
      {
        accessorKey: "phone",
        header: "Phone",
        muiEditTextFieldProps: {
          type: "tel",
          required: true,
          error: !!page.validation.phone,
          helperText: page.validation.phone,
          onFocus: () => page.validation.clearPhone(),
        },
      },
      {
        accessorKey: "customer",
        header: "Account",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "role_group",
        header: "Role",
        editVariant: "select",
        required: true,
        editSelectOptions: userRoleGroups,
        muiEditTextFieldProps: {
          select: true,
          error: !!page.validation.roleGroup,
          helperText: page.validation.roleGroup,
        },
      },
      {
        accessorFn: (row) => new Date(row.insert_date),
        accessorKey: "insert_date",
        header: "Creation Date",
        enableEditing: false,
        filterVariant: 'date',
        sortingFn: 'datetime',
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(),
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
    ],
    [userRoleGroups]
  );

  const handleSaveUser = useCallback(({ values, table }) => {
    page.validation.validateUser(values, isSuperAdmin);
    page.onPutUser(values);
    table.setEditingRow(null);
  }, [page]);

  const openHandleDeleteConfirmModal = useCallback((row) => {
    page.setRow(row);
    page.setShowDialogOpenDelete(true);
  }, []);

  const onHandleNewUser = useCallback(() => {
    navigate('/new-user');
  }, []);

  const table = useMaterialReactTable({
    columns,
    data: page.users,
    initialState: { isHidden: { countryCode: true } },
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    enableEditing: true,
    getRowId: (row) => row.id,
    positionActionsColumn: 'last',
    muiToolbarAlertBannerProps: page.status === "error"
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel: () => page.validation.clearValidation(),
    onEditingRowCancel: () => page.validation.clearValidation(),
    onEditingRowSave: handleSaveUser,

    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Edit User</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {internalEditComponents.slice(0, 4).map((component, index) => (
            <div key={index}>{component}</div>
          ))}
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <div style={{ marginTop: '13px'}}>
                <Select
                  labelId="select-label"
                  id="select"
                  value={selectedValue}
                  label="Code"
                  onChange={handleChange}
                  variant="standard"
                >
                  <MenuItem value="+1">+1</MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item xs={10}>
              <div>{internalEditComponents[4]}</div>
            </Grid>
          </Grid>
          {internalEditComponents.slice(5).map((component, index) => (
            <div key={index}>{component}</div>
          ))}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "0.5rem" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <PenIcon viewBox="9 9 35 35" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={() => openHandleDeleteConfirmModal(row)}>
            <TrashIcon viewBox="9 9 35 35" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <CustomBox width="100%" display="flex" justifyContent="center">
        <SearchWrapper>
        </SearchWrapper>
        <HeaderButtonWrapper>
          <RoundedButton width="197px" onClick={onHandleNewUser} margin="0px 5px">
            Create New User
          </RoundedButton>
          <RoundedButton width="197px" margin="0px 5px">
            Upload User List
          </RoundedButton>
        </HeaderButtonWrapper>
      </CustomBox>
    ),
    state: {
      isLoading: page.status === "loading",
      isSaving: page.status === "editing",
      showAlertBanner: page.status === "error",
      showProgressBars: page.status === "loading",
      columnVisibility: { role_group: isSuperAdmin },
    },
  });

  return <MaterialReactTable table={table} />
});

export default UserData;
