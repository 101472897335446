import React, { useState } from 'react';
import { CategoriesList, CategoryItem } from './CategorySectionComponent.styled';

const CategorySection = ({ categories, selectedCategory, onSelectCategory }) => {
    return (
        <CategoriesList>
            {categories.map((category, index) => (
                <CategoryItem
                    key={index}
                    selected={selectedCategory === category}
                    onClick={() => onSelectCategory(category)}
                >
                    {category}
                </CategoryItem>
            ))}
        </CategoriesList>
    );
};

export default CategorySection;
