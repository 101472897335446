import React, { useCallback, useState, useEffect } from "react";
import { API } from "aws-amplify";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { getUploadUrlFile } from "../../graphql/queries";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton, Button } from '@mui/material';
import AdmirationSign from '../../elements/icon/assets/admiration_sign.svg';
import { RoundedButton, SuccessfulModal, Icon } from "..";
import {
  FilesListContainer,
  FilesListItem,
  FilesTextWrapper,
  FilesName,
  ButtonContainer,
  DragAndDropWrapper
} from "./DragAndDropAssistant.styled";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const DragAndDropAssistant = ({currentUploadedFilesLength, customer, fileRoute, onFinishUpload, extensions, width, rejectMessage, onFilesChange, onError}) => {
  const totalFilesThreshold = 20;
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const [errorPopup, setErrorPopup] = useState('');

  const removeSpecialCharacters = (string) => {
    const withoutSpaces = string.replace(/\s/g, "_");
    const withoutSpecialChars = withoutSpaces.replace(/[^.\w\s]/gi, "");
    return withoutSpecialChars;
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    setFilesToUpload((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const onFileUpload = (filePath) => {
    try {
      const url = filePath.uploadUrl;
      let newUrl = url.replace("https", "s3").replace(".s3.amazonaws.com", "");
      const indexQuestion = newUrl.indexOf("?");
      if (indexQuestion !== -1) {
        newUrl = newUrl.substring(0, indexQuestion);
      }

      if(onFilesChange){
        onFilesChange(newUrl);
      }

    } catch (error) {
        if(onError){
          onError();
        }
    }
  };

  const handleUpload = async () => {
    const filesDifference = (filesToUpload.length + currentUploadedFilesLength) - totalFilesThreshold
    if(filesToUpload.length && currentUploadedFilesLength && 
      filesDifference > 0){
        return setErrorPopup(`You will need to delete ${filesDifference} of your current files to upload this one. The limit of files by each of our agents is 20.`);
    }

    for(const file of filesToUpload){
      //Check the 512mb limit
      if(file?.size/1e+6 > 512){
        return setErrorPopup(`File ${file.name} exceeds the 512 mb file size limit per file, you might need to split it into smaller parts and then upload it.`);
      }
    }

    try {
      setProgress(0);
      setUploading(true);

      for (const file of filesToUpload) {
        const fileName = removeSpecialCharacters(file.name);

        const response = await API.graphql({
          query: getUploadUrlFile,
          variables: {
            input: {
              customer: customer,
              file_name: fileName,
              file_type: file.type,
              file_route: fileRoute,
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });

        const uploadUrl = JSON.parse(
          response.data.getUploadUrlFile?.body
        );
        const formData = new FormData();
        formData.append("file", file);

        const config = {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
          headers: {
            "Content-Type": file.type,
          },
        };

        await axios.put(uploadUrl, file, config);

        if (onFileUpload) {
          onFileUpload({
            uploadUrl,
            fileName: fileName,
          });
        }
      }
      if (onFinishUpload) {
        setFilesToUpload([])
        onFinishUpload();
      }
    } catch (error) {
      return setErrorPopup(`Error uploading file, please try again later.`);
    } finally {
      setUploading(false);
    }
  };

  const handleFileDelete = (fileName) => {
    setFilesToUpload((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName)
    );
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: extensions,
    onDrop,
  });

  return (
    <DragAndDropWrapper $width={width}>
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? "active" : ""}`}
      >
        <input {...getInputProps()} />
        {isDragAccept && <p>Drop the files here ...</p>}
        {isDragReject && <p>{rejectMessage || 'Unsupported file type'}</p>}
        {!uploading && (
          <p>Drag and drop a file here, or click to select a file.</p>
        )}
      </div>
      {progress > 0 && (
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${progress}%` }}
          >
            {progress}%
          </div>
        </div>
      )}
      {filesToUpload.length > 0 &&  (
        <>
          <ButtonContainer>
            <RoundedButton onClick={handleUpload} width='40%'> Upload </RoundedButton>
          </ButtonContainer>
          <FilesListContainer>
            {filesToUpload.map((file, index) => (
                <FilesListItem key={index}>
                    <Icon name={"file"} height={'20px'} width={'20px'} margin='0 2px'/>
                    <FilesTextWrapper>
                        <FilesName>{file.name}</FilesName>
                    </FilesTextWrapper>
                    <IconButton onClick={() => handleFileDelete(file.name)}> <CloseRoundedIcon/></IconButton>
                </FilesListItem>
            ))}
          </FilesListContainer>

        </>
      )}

      {uploading && <CircularProgress className="spinner" />}
      <SuccessfulModal
          isOpen={errorPopup}
          onClose={() => setErrorPopup('')}
          imageSrc={AdmirationSign}
          title="Oops!"
          subtitle="An error occurred."
          subtitle2={errorPopup}
          height='380px'
          width='520px'
          zIndex={true}
      />
    </DragAndDropWrapper>
  );
};

export default DragAndDropAssistant;
