import { Icon } from '../../../../../elements';

import {
  SelectorAvatarWrapper,
} from "./AgentTeamSelector.styled";

const AgentTeamAvatar = ({ url }) => {
  return (
    <SelectorAvatarWrapper>
      { url === undefined && <Icon name="carbonUserAvatarFilled" width="44px" height="44px" />}
      { url && <img src={url} alt="" />}
    </SelectorAvatarWrapper>
  );
};

export default AgentTeamAvatar;