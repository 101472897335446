import styled from 'styled-components';

const CustomModal = styled.div`
    position: absolute;
    top: ${(props) => props.top}px;
    left: ${(props) => props.left}px;
    border: 1px solid ${props => props.theme.colors.cerebralGray};
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 16px;
    z-index: 1000;
    background: ${props => props.theme.colors.primaryWhite};
    width: 250px;
    margin-top: 10px;
`;

const ModalFilterHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    margin-bottom: 16px;
`;

const ModalFilterTitle = styled.h4`
    margin: 0;
    font-weight: bold;
`;

const ModalDropdownTitle = styled.p`
    margin: 0;
    font-size: 0.6875em;
    color: black;
    margin-bottom: 2px;
`;

const StyledFormControl = styled.div`
    position: relative;
    margin-bottom: 16px;
`;

const StyledSelect = styled.div`
    border: 1px solid ${props => props.theme.colors.cerebralGray};
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    position: relative;
`;

const DropdownIconContainer = styled.div`
    position: absolute;
    right: 10px;
    top: 35%;
`;

const DropdownMenu = styled.ul`
    display: ${(props) => (props.open ? 'block' : 'none')};
    position: absolute;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid ${props => props.theme.colors.cerebralGray};
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    z-index:1;
`;

const StyledMenuItem = styled.li`
    padding: 8px;
    cursor: pointer;
    &:hover {
        background-color: ${props => props.theme.colors.greySeven}; 
    }
`;

const StyledButton = styled.button`
    width: 100%;
    margin-top: 16px;
    padding: 10px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: ${props => props.theme.colors.darkCharcoal};
    }
`;

const PlaceholderDropdown = styled.p`
  margin: 0;
  font-size: 0.625em;
  color: ${props => props.theme.colors.dhusarGray};
  font-style: italic;
`;

const IconCloseContainer = styled.div`
    cursor: pointer;
    position: relative;
    max-height: 15px;
    max-width: 15px;
    padding-right: 5px;
`

export {
    CustomModal,
    ModalFilterHeader,
    ModalFilterTitle,
    ModalDropdownTitle,
    StyledFormControl,
    StyledSelect,
    DropdownMenu,
    StyledMenuItem,
    StyledButton,
    PlaceholderDropdown,
    DropdownIconContainer,
    IconCloseContainer
};