import styled from "styled-components";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
  opacity: 1;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalTitle = styled.h4`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.colors.darkCharcoal};
`;

export const IconCloseContainer = styled.div`
  cursor: pointer;
  background: none;
  border: none;
  font-size: 30px;
  color: ${props => props.theme.colors.clearGray};
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledTextField = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid ${props => props.theme.colors.cerebralGray};
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid ${props => props.theme.colors.cerebralGray};
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  resize: none;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const DescriptionLabel = styled.label`
  font-weight: 600;
  font-size: 14px;
  color: ${props => props.theme.colors.darkCharcoal};
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export const StyledButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;

  &:disabled {
    background-color: ${props => props.theme.colors.darkCharcoal};
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #0056b3;
  }
`;

export const CharacterCount = styled.div`
  font-size: 12px;
  text-align: right;
  color: #666;
  margin-top: -10px;
`;
