import { StyledLabel, Wrapper, StyledRadio } from "./CustomRadioButton.styled";

const CustomRadioButton = ({ label, value, name, onChange, checked, width, fontSize }) => {
    return (
        <Wrapper checked={checked} onClick={() => onChange(value)} $width={width}>
            <StyledRadio type="radio" id="radio-button" value={value} name={name} checked={checked} onChange={() => onChange(value)}/>
            <StyledLabel $fontSize={fontSize} onClick={() => onChange(value)} checked={checked}>{label}</StyledLabel>
       </Wrapper>    
    );
}

export default CustomRadioButton;
