import styled from 'styled-components';

const PaginationContainer = styled.ul`
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding-right: 86px;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 82%;
`;


const PageItem = styled.li`
    margin: 0 5px;
    background-color: ${props => props.active ? props => props.theme.colors.white : props.disabled ? props => props.theme.colors.culturedGray : props => props.theme.colors.culturedGray };
    border: 1px solid ${props => props.active ? props => props.theme.colors.black : props => props.disabled ? 'none' : props => props.theme.colors.silverChalice};
    border-radius: 5px;
    color: ${props => props.active ? props => props.theme.colors.black : props.disabled ? props => props.theme.colors.silverChalice : props => props.theme.colors.silverChalice};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    padding: 5px 12px;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    justify-content: center;
		
    &:hover {
        border: 1px solid ${props => props.theme.colors.black};
				color: ${props => props.theme.colors.black};
    }
`;


const PageLink = styled.a`
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
`;


const PageEntries = styled.div`
	order: 0;
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
  align-items: center;
`

const PageItems = styled.div`
	order: 1;
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-end;
`

const EntriesInfo = styled.div`
	font-size: 0.8em; 
	padding-left: 15px;
`

const DropdownContainer = styled.div`
  margin: 0 15px;
`;

const  DropdownSelect = styled.select`
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: white;
  color: black;
`;

const ShowEntries = styled.p`
	margin: 0;
	color: 	${props => props.theme.colors.silverFoil};
`

export {
	PaginationContainer,
	PageItem,
	PageLink,
	PageEntries,
	PageItems,
	EntriesInfo,
	ShowEntries,
	DropdownContainer,
	DropdownSelect,
}