import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { CalendarContainer, StyledCalendar, FormCampaignWrapper, FormTitle, Label } from '../NewCampaignPage.Styled';
import { useStore } from "../../../hooks";

const CampaignCalendarForm = observer(() => {
    const { newCampaign } = useStore();
    const [endDateDisabled, setEndDateDisabled] = useState(!newCampaign.startDate);

    useEffect(() => {
        if (newCampaign.endDate && newCampaign.endDate < newCampaign.startDate) {
            newCampaign.setEndDate(null);
        }
    }, [newCampaign.startDate]);

    const handleStartDateChange = (date) => {
        newCampaign.setStartDate(date);
        if (newCampaign.endDate && newCampaign.endDate <= date) {
            newCampaign.setEndDate(null);
        }
        setEndDateDisabled(!date);
    };

    const handleEndDateChange = (date) => {
        newCampaign.setEndDate(date);
    };

    return (
        <FormCampaignWrapper>
            <FormTitle>Decide when your Conversation kicks off and when it wraps up.</FormTitle>
            <br />
            <CalendarContainer>
                <div>
                    <Label>Start Date</Label>
                    <StyledCalendar
                        value={newCampaign.startDate}
                        onChange={handleStartDateChange}
                        minDate={new Date()}
                    />
                </div>
                <div>
                    <Label>End Date</Label>
                    <StyledCalendar
                        value={newCampaign.endDate}
                        onChange={handleEndDateChange}
                        minDate={newCampaign.startDate ? new Date(newCampaign.startDate.getTime() + 86400000) : new Date()}
                        disabled={endDateDisabled}
                    />
                </div>
            </CalendarContainer>
        </FormCampaignWrapper>
    );
});

export default CampaignCalendarForm;
