import styled from "styled-components";

const RadioChoiceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;

  ${(props) => props.theme.fonts.variant.regular()};
  font-size: 0.875rem;
  font-style: normal;
  line-height: normal;
  color: ${(props) => props.theme.colors.darkGray};
`;

export {
  RadioChoiceWrapper
};
