import React, { useCallback, useState, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useStore } from "../../../hooks";
import { useMediaQueries } from "../../../utils/mediaQueries";
import {
  StyledContainer,
  TableWrapper,
  headCellStyle,
  bodyCellStyle,
  toolbarStyle,
  tableContainerStyle,
  tablePaperStyle
} from "../../../elements/v2/Tables/Table.styled";
import { SuccessfulModal } from "../../../elements";
import { IconButton, Tooltip, Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DefaultTheme } from "../../../theme";
import StatusColumn from "../../../elements/v2/Tables/StatusColumn";

const RepairOrdersPage = observer(() => {
    const { changeTitle, repairOrderStore, authStore, annUnifiedCustomerProfileStore } = useStore();
    const navigate = useNavigate();
    
    changeTitle('Repair Orders');
    const { isMobile, isTablet, isDesktop } = useMediaQueries();

    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([{ id: 'appointmentDay', desc: true }]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

    useEffect(() => {
        repairOrderStore.fetchRepairOrders();
    }, [repairOrderStore]);

    const filteredRepairOrders = useMemo(() => repairOrderStore.filteredRepairOrders, [repairOrderStore.filteredRepairOrders]);

    const columns = useMemo(() => [
        { accessorKey: "roNumber", header: "RO number" },
        { 
          accessorKey: "status", 
          header: "Status", 
          Cell: ({ cell }) => <StatusColumn status={cell.getValue()} colors={DefaultTheme.colors} /> 
        },
        { accessorKey: "description", header: "Description" },
        { accessorKey: "appointmentDay", header: "Appointment day" },
        { accessorKey: "bookDay", header: "Book day" },
    ], []);

    const onHandleUnifiedCustomerProfile = useCallback((repairOrderId) => {
        const urlForUnified = authStore.selectedAccount.metadata.domain === 'ANN' ? "/ann-unified-profile" : "/unified-profile";
        navigate({
            pathname: urlForUnified,
            search: createSearchParams({ id: repairOrderId }).toString(),
        });
    }, [authStore.selectedAccount, navigate]);

    const table = useMaterialReactTable({
        columns,
        data: filteredRepairOrders,
        manualSorting: true,
        manualGlobalFilter: true,
        rowCount: repairOrderStore.totalRowCount || 0,
        initialState: { sorting },
        muiTableContainerProps: { sx: tableContainerStyle },
        muiTablePaperProps: { elevation: 0, sx: tablePaperStyle },
        muiTableHeadCellProps: { sx: headCellStyle },
        muiTableBodyCellProps: { sx: bodyCellStyle },

        muiTableBodyRowProps: ({ row }) => ({
            onClick: () => {
                const repairOrderId = row.original.id;
                annUnifiedCustomerProfileStore.onClearUCP();
                onHandleUnifiedCustomerProfile(repairOrderId);
            },
            style: { cursor: 'pointer' }
        }),
        muiTopToolbarProps: { sx: toolbarStyle },
        muiSearchTextFieldProps: {
            variant: 'outlined',
            placeholder: 'Search',
            InputProps: { style: { fontSize: '12px' } },
        },
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableColumnResizing: false,
    });

    return (
        <StyledContainer isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}>
            <TableWrapper>
                <MaterialReactTable table={table} />
            </TableWrapper>
            {repairOrderStore.isError && (
                <SuccessfulModal
                    isOpen={repairOrderStore.isError}
                    onClose={repairOrderStore.clearError}
                    title="Oops!"
                    subtitle="An error occurred."
                    subtitle2="Please try again later."
                />
            )}
        </StyledContainer>
    );
});

export default RepairOrdersPage;
