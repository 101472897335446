import InputText from '../TextInput/TextInput';
import TextArea from '../TextArea/TextArea';
import { Subtitle, InputWrapper, TabContainer } from './TemplateEditor.styled';
import { observer } from 'mobx-react';
import useStore from '../../../hooks/useStore';

const GeneralTab = observer(() => {
    const { templateEditorStore } = useStore();

    const updateField = field => e => templateEditorStore.updateGeneralField(field, e.target.value)

    return (
        <TabContainer>
            <h1>General</h1>

            <InputWrapper>
                <Subtitle>From</Subtitle>
                <InputText 
                    placeholder="From" 
                    value={templateEditorStore.from} 
                    onChange={updateField('from')}
                />
            </InputWrapper>

            <InputWrapper>
                <Subtitle>To</Subtitle>
                <InputText 
                    placeholder="To" 
                    value={templateEditorStore.to} 
                    onChange={updateField('to')}
                />
            </InputWrapper>
            
            <InputWrapper>
                <Subtitle>Subject*</Subtitle>
                <InputText 
                    placeholder="Subject" 
                    value={templateEditorStore.subject} 
                    onChange={updateField('subject')}
                />
            </InputWrapper>

            <InputWrapper>
                <Subtitle>CC</Subtitle>
                <InputText 
                    placeholder="CC" 
                    value={templateEditorStore.cc} 
                    onChange={updateField('cc')}
                />
            </InputWrapper>

            <InputWrapper>
                <Subtitle>BCC</Subtitle>
                <InputText 
                    placeholder="BCC" 
                    value={templateEditorStore.bcc} 
                    onChange={updateField('bcc')}
                />
            </InputWrapper>

            <InputWrapper>
                <Subtitle>Description (optional)</Subtitle>
                <TextArea 
                    placeholder="Enter a description"
                    height="120px"
                    resize="none"
                    value={templateEditorStore.description} 
                    onChange={updateField('description')}
                />
            </InputWrapper>
            
        </TabContainer>
    );
});

export default GeneralTab