import styled from 'styled-components';

export const Container = styled.div`
border-top: 1px solid ${(props) => props.theme.colors.grey};
border-bottom: 1px solid ${(props) => props.theme.colors.grey};
position: relative;
top: 30px;
cursor: pointer;
width: 70rem;
height: 5rem;
`;

export const TitleContainer = styled.div`
  height: 80px;
  left: 0px;
  top: 10px;
  position: absolute;
  background: rgba(235.88, 235.88, 235.88, 0);
`;

export const TitleText = styled.span`
  font-size: 20px;
  font-weight: 500;
  word-wrap: break-word;
`;

export const DescriptionText = styled.span`
  font-size: 20px;
  font-weight: 400;
  word-wrap: break-word;
  color: #717171;
`;
