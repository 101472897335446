import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { Label } from "./DateTimePicker.styled";
import { TextField } from '@mui/material';

const DateTimePicker = ({ label, type='date', ...props }) => {
    const slots = { textField: TextField };
    const slotProps = { inputAdornment: { position: 'start' } };
    return (
        <div>
            <Label>{label}</Label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                { type === 'date' ? 
                    (<DatePicker slots={slots} slotProps={slotProps} {...props} />)
                    :
                    (<TimePicker slots={slots} slotProps={slotProps} {...props} />)
                }
            </LocalizationProvider>
        </div>
    );
};

export default DateTimePicker