import React, {useState, useEffect} from "react";
import {observer} from "mobx-react";
import {CustomRadioButton} from "../../../elements";
import {useStore} from "../../../hooks";
import { FormTitle, FormSubtitle, FormAgentWrapper, RadioButtonsContainer } from "../NewAgentPage.styled"

const AgentAnswerExtensionForm = observer(() => {
    const { newAgent } = useStore();

    const [ 
        selectedValue, 
        setSelectedValue, 
    ] = useState(""); 
  
    const handleRadioChange = ( 
        value 
    ) => { 
        setSelectedValue(value); 
        newAgent.onChangeAnswerExtension(value);
    }; 

    useEffect(() => {
        setSelectedValue(newAgent.answerExtension)
    }, [])

    const isChecked = (value) => value == selectedValue

    return (
        <FormAgentWrapper>
            <FormTitle>You're doing great</FormTitle>
            <FormSubtitle>Do you prefer detailed explanations or concise summaries <br/> for your answers?</FormSubtitle>
            <RadioButtonsContainer mb='60px' mt='80px'>
                <CustomRadioButton name="agentTone" label="Summarized" value="summarized" onChange={handleRadioChange} selectedValue={selectedValue} checked={isChecked('summarized')}/>
                <CustomRadioButton name="agentTone" label="Extensive" value="extensive" onChange={handleRadioChange} selectedValue={selectedValue} checked={isChecked('extensive')}/>
            </RadioButtonsContainer>
        </FormAgentWrapper>
    );
});

export default AgentAnswerExtensionForm;
