import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: ${props => props.theme.colors.highClearGray};
  padding: 20px;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 550px;
  height: 400px;
		
`;

const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: normal;
`;

const TextField = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    min-height: 130px;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    resize: vertical;

    &::placeholder {
        vertical-align: top;
        line-height: normal;
    }
`;

const Header = styled.div`
	display: flex;
	gap:10px;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: flex-end;
`

const Actions = styled.div`
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	align-items: center;
	justify-content: end;
	gap:15px;	
`


const Button = styled.button`
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 8px;
    background-color: transparent;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
        background-color: black;
        color: white;
    }
`;

const HeaderIcon = styled.div`
	display: flex;
	order: 0;
`

const HeaderType = styled.div`
    display: flex;
    order: 1;
`

export {
	ModalOverlay,
	ModalContent,
	CloseButton,
	Row,
	Label,
	TextField,
	TextArea,
	Header,
	Actions,
	Button,
	HeaderIcon,
	HeaderType
}