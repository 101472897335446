import styled, { css } from "styled-components"

export const SelectedGroupsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  margin-left: 50px;
  margin-right: 50px;
  background-color: ${props => props.theme.colors.highClearGray};
  font-size: 12px;

  .selected-group {
    padding: 5px 10px;
    background-color: ${props => props.theme.colors.white};
    border-radius: 15px;
    display: flex;
    border: 1px solid ${props => props.theme.colors.clearGray};
    
  }
`;

export const MarginButton = styled.div`
    margin-left: 10px;
`; 

