import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { InputText, RoundedButton } from '../../../elements';

const ModalChangePassword = ({ isOpen, onClose }) => {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [retypeNewPassword, setRetypeNewPassword] = useState('');

    const handleConfirm = () => {
        
        const formData = {
            password: password,
            newPassword: newPassword,
            retypeNewPassword: retypeNewPassword
        };
        
        onClose(formData);
    };

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
                <div>
                    <label>Enter Password:</label>
                    <InputText
                        placeholder="Enter Password"
                        type="password"
                    />
                </div>
                <div>
                <label>New Password:</label>
                    <InputText
                        placeholder="New Password"
                        type="password"
                    />
                    <label>
                        Your password must be at least 8 characters long, contain at least one number, one uppercase letter(A-Z) and one lowercase letter(a-z)
                    </label>
                </div>
                <div>
                <label>Re-type New Password:</label>
                    <InputText
                        placeholder="Retype New Password"
                        type="password"
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <RoundedButton type={"submit"} kind={"primary"} onClick={onClose}>
                    {'Cancel'}
                </RoundedButton>

                <RoundedButton type={"submit"} kind={"primary"} onClick={handleConfirm}>
                    {'Confirm'}
                </RoundedButton>

            </DialogActions>
        </Dialog>
    );
}

export default ModalChangePassword;
