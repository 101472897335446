import React, { useRef, useEffect } from 'react';
import { ModalSubMenuContainer, ModalSubMenuHeader, ModalSubMenuBody, ModalSubMenuItems } from './App.styled';
import { Icon } from "../../elements";

const ModalSubMenu = ({ items, position, title, onClose, avatar=false }) => {
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <ModalSubMenuContainer ref={menuRef} position={position} avatar={avatar}>
            <ModalSubMenuHeader>
                {title}
            </ModalSubMenuHeader>

            <ModalSubMenuBody>
                {items.map((item, idx) => (
                    <ModalSubMenuItems key={idx} onClick={item.onClick}>
                        {item.icon && (<Icon name={item.icon} height={"16px"} width={"16px"}/>)}
                        {item.text}
                    </ModalSubMenuItems>
                ))}
            </ModalSubMenuBody>
        </ModalSubMenuContainer>
    );
};

export default ModalSubMenu;