import {observer} from "mobx-react";
import {useStore} from "../../../hooks";

import {
  ViewWrapper,
  HeaderViewWrapper,
  ContentViewWrapper
} from "./ConversationView.styled";
import Tabs2 from "../../../elements/v2/Tabs2/Tabs2";
import HeaderView from "./components/HeaderView/HeaderView";
import TriggerTab from "./components/TriggersTab/TriggerTab";
import { useTabs } from "./hook/useTabs";
import { useState } from "react";
import MarketingTab from "./components/MarketingTab/MarketingTab";

const ConversationView = observer(() => {

  const { changeTitle } = useStore();
  const tabsConversation = useTabs();
  const [index, setIndex] = useState(0);

  changeTitle('Conversations Starters');

  const onToggleTab = (index) => {
    setIndex(index);
  }

  return (
    <ViewWrapper>
      <HeaderViewWrapper>
        <Tabs2 tabs={tabsConversation} onToggleTab={onToggleTab} />
        <HeaderView index={index}/>
      </HeaderViewWrapper>
      <ContentViewWrapper>

        <Tabs2.Outlet show={index === 0}>
          <TriggerTab />
        </Tabs2.Outlet>
        
        <Tabs2.Outlet show={index === 1}>
          <MarketingTab />
        </Tabs2.Outlet>

      </ContentViewWrapper>
    </ViewWrapper>
  );
});

export default ConversationView;