import { DropDownContainer, DropDownHeader, DropDownList, ListItem, ListInput } from "./CustomSelect.styled";
import ArrowDownIcon from "../icon/assets/arrow_down_icon.svg"
import { useState, useEffect, useMemo } from "react"

const CustomSelect = ({ placeholder = 'Choose an option', selectedDefault = null, options=[{name: 'Option 1', value: 'option1'}], margin, handleSelect, width, preventSelection=false, textTransform, searchBar=true }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(selectedDefault);
    const [searchTerm, setSearchTerm] = useState('');
  
    const toggling = () => setIsOpen(!isOpen);
  
    const onOptionClicked = option => () => {
        if(!preventSelection) setSelectedOption(option.name);
        setIsOpen(false);
        handleSelect(option.name, option.value);
    };

    useEffect(() => {
        setSelectedOption(selectedDefault);
    }, [selectedDefault])

    const listSorted = useMemo(() => 
        options.sort((a, b) => { 
            const nameA = a.name.toUpperCase(); 
            const nameB = b.name.toUpperCase();  
            if (nameA < nameB) { return -1; } 
            if (nameA > nameB) { return 1; } 
            return 0; 
        }).filter(option =>  (new RegExp(searchTerm, "i")).test(option.name)), [searchTerm, options.length])

  return (
      <DropDownContainer margin={margin} width={width}>
        <DropDownHeader $textTransform={textTransform} onClick={toggling} arrow={ArrowDownIcon}>{selectedOption || placeholder}</DropDownHeader>
        {isOpen && options.length > 0 && (
            <>
                {searchBar && <ListInput value={searchTerm} onChange={e => setSearchTerm(e.target.value)} placeholder="Find in list..."/>}
                <DropDownList>
                    {listSorted.map( option => 
                        <ListItem onClick={onOptionClicked(option)} key={Math.random()}><span>{option.name}</span></ListItem>
                    )}
                </DropDownList>
            </>
        )}
      </DropDownContainer>
  );
}

export default CustomSelect;
