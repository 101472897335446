import React from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks";
import { FileInfo, FileName, FileSize, FormCampaignWrapper, FormTitle, StyledUploadFile, FileIcon } from '../ContactFlowPage.Styled';
import 'react-calendar/dist/Calendar.css';
import { IconButton } from "@mui/material";
import Trash from "../../../elements/icon/assets/trash.svg"
import FileIconImage from "../../../elements/icon/assets/file.svg"

const CampaignUploadFilesForm = observer(() => {
    const { contactStore } = useStore();

    const handleFileDelete = (event) => {
        event.stopPropagation();
        contactStore.setSelectedFiles([]);
        contactStore.setColumnNames([]);
        contactStore.setColumnContents([]);
        contactStore.buttonUpload = false;
        contactStore.setShowCustomSelectFirstName(false);
        contactStore.setShowCustomSelectLastName(false);
        contactStore.setShowCustomSelectEmail(false);
        contactStore.setShowCustomSelectPhone(false);
        contactStore.setShowCustomSelectZipCode(false);
        contactStore.setAreAllFieldsDone(false);
    };

    return (
        <FormCampaignWrapper>
            <StyledUploadFile>
                {contactStore.selectedFiles.map((file, index) => (
                    <FileInfo key={index}>
                        <FileIcon src={FileIconImage} />
                        <FileName>File"{file.name}" uploaded</FileName><br />
                        <FileSize>{(file.size / 1024).toFixed(2)} KB</FileSize>
                        <IconButton onClick={handleFileDelete}>
                            <FileIcon src={Trash} />
                        </IconButton>
                    </FileInfo>
                ))}
            </StyledUploadFile>
        </FormCampaignWrapper>
    );
});

export default CampaignUploadFilesForm;
