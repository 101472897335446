import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import React, {useEffect, useMemo, useState} from "react";
import {StatusWrapper} from "./TriggerTab.styled";
import {DefaultTheme as theme} from "../../../../../theme";
import { observer } from "mobx-react";
import { useStore } from "../../../../../hooks";
import dayjs from 'dayjs'
import { createSearchParams, useNavigate } from "react-router-dom";
import { SuccessfulModal } from '../../../../../elements';
import { ConfirmationModal } from "../../../../../elements/v2/modals";

const TriggerTab = observer(() => {
  const navigate = useNavigate()
  const {authStore, conversationsStore, viewConversationStore} = useStore();
  const [openTriggerModal, setOpenTriggerModal] = useState(false);

  useEffect(() => {
    conversationsStore.clear()
  }, [])

  useEffect(() => {
    if(authStore.selectedAccount){
      conversationsStore.setAccount(authStore.selectedAccount.id)
    }
  }, [authStore.selectedAccount])

  useEffect(() => {
    if(conversationsStore.account){
      conversationsStore.getTriggers()
      viewConversationStore.getEventsCatalog();
    }
  }, [conversationsStore.account])

  const navigateToTriggerView = (id, type='draft') => {
    const triggerId = id.split('#').pop();
    const finalTriggerId = conversationsStore.groupedTriggers[triggerId].find(trigger => trigger.status === type)

    navigate({
      pathname: "/v2/conversations/trigger",
      search: createSearchParams({
          id: finalTriggerId.id,
      }).toString(),
    })
  }

  const openTrigger = (rowId) =>{
    const triggerId = rowId.split('#')[3];
    if(conversationsStore.groupedTriggers[triggerId].length === 1){
      navigateToTriggerView(rowId)
    }else{
      setOpenTriggerModal(rowId)
    }
  }

  const columns = useMemo(
  () => [
    {
      accessorKey: "status",
      header: "Status",
      Cell: ({ renderedCellValue }) => {
        return (<StatusWrapper className={renderedCellValue}>{renderedCellValue}</StatusWrapper>);
      }
    },
    {
      accessorKey: "name",
      header: "Trigger Name"
    },
    {
      accessorKey: "event_id",
      header: "Trigger Event",
      Cell: ({ cell }) => viewConversationStore.eventsCatalog.find(event => event.id === cell.getValue())?.name || '---'
    },
    {
      accessorKey: "rules",
      header: "Rules",
      Cell: ({ cell, row }) => conversationsStore.triggersList.find(event => event.id === row.original.id)?.rules[0]?.conditions?.length
    },
    {
      accessorKey: "actions",
      header: "Actions",
      Cell: ({ cell }) => cell.getValue()?.length
    },
    {
      accessorKey: "start_at",
      header: "Start date",
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => dayjs(cell.getValue()).format('MM/DD/YYYY - HH:mm'),
    },
    {
      accessorKey: "end_at",
      header: "End date",
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => cell.getValue() ? dayjs(cell.getValue()).format('MM/DD/YYYY - HH:mm') : '---',
    },
    {
      accessorKey: "last_modified",
      header: "Last Updated",
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => dayjs(cell.getValue()).format('MM/DD/YYYY - HH:mm'),
    },
  ], []);

  const table = useMaterialReactTable({
    columns,
    data: conversationsStore.triggersList,
    enableColumnFilters: false,
    enableHiding: false,
    enableColumnActions: false,
    enableColumnResizing: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: theme.colors.highClearGray,
        color: 'black',
        paddingTop: '16px',
        paddingBottom: '16px',
      },
    },
    positionActionsColumn: 'last',
    paginationDisplayMode: 'pages',
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => openTrigger(row.original.id),
      sx: {
        cursor: 'pointer',
      },
    }),
    state: {
      isLoading: viewConversationStore.isLoading,
    }
  });

  return (
    <>
      <MaterialReactTable table={table} />
      {conversationsStore.isError && <SuccessfulModal
        isOpen={conversationsStore.isError}
        onClose={() => conversationsStore.onClearError()}
        title="Oops!"
        subtitle="An error occurred."
        subtitle2="Please try again later."
        zIndex={true}
      />}
      {openTriggerModal && 
        <ConfirmationModal
          isOpen={openTriggerModal}
          onClose={() => navigateToTriggerView(openTriggerModal, 'active')}
          onConfirm={() => navigateToTriggerView(openTriggerModal)}
          onCancel={() => setOpenTriggerModal(false)}
          title={'Edit Trigger'}
          message={'Do you want to edit or view trigger?'}
          icon="edit"
          onConfirmLabel="Edit"
          cancelLabel="View"
        />
      }
    </>

  );
});

export default TriggerTab;
