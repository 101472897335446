import React, { useState, useEffect } from 'react';
import { TabsContainer, TabsHeader, Tab, TabContent, TabElement, TabsWrapper, ActionsContainer } from './PlaygroundTabs.styled';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks';
import { useMediaQueries } from "../../../utils/mediaQueries";

const Tabs = observer(({ tabs, onToggleTab, showBorder, width, blockToggle = false, onToggleBlocked, height = "100%", children, setTab }) => {
    const { authStore } = useStore();
    const [activeTab, setActiveTab] = useState(0);
    const { isMobile } = useMediaQueries();

    useEffect(() => {
        if (setTab !== undefined) {
            setActiveTab(setTab);
        }
    }, [setTab]);

    const handleTabClick = (index) => {
        if (!blockToggle) {
            setActiveTab(index);
            onToggleTab && onToggleTab(index);
        } else {
            onToggleBlocked?.();
        }
    };

    return (
        <TabsContainer>
            <TabsHeader showBorder={showBorder} width={width} isMobile={isMobile}>
                <TabsWrapper isMobile={isMobile}>
                    {tabs.map((tab, index) => (
                        (!tab.permission || (tab.permission && authStore.hasPermission(tab.permission.code, tab.permission.level))) && (
                            <Tab key={index} isActive={activeTab === index} onClick={() => handleTabClick(index)} isMobile={isMobile}>
                                {tab.label}
                            </Tab>
                        )
                    ))}
                </TabsWrapper>
                <ActionsContainer>{children && children}</ActionsContainer>
            </TabsHeader>
            <TabContent>
                {tabs.map((tab, index) => (
                    (!tab.permission || (tab.permission && authStore.hasPermission(tab.permission.code, tab.permission.level))) && (
                        <TabElement key={index} $isActive={activeTab === index}>
                            {tab.content}
                        </TabElement>
                    )
                ))}
            </TabContent>
        </TabsContainer>
    );
});

export default Tabs;
