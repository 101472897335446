import React, { useMemo } from 'react';
import { PaginationContainer, PageItem, PageLink, PageEntries, PageItems, EntriesInfo, ShowEntries } from './Pagination.styled';
import Icon from '../../../../../../../../../elements/icon/Icon';
import Dropdown from './Dropdown';

const Pagination = ({ cardsPerPage, totalCards, paginate, currentPage, setCardsPerPage }) => {
	const { pageNumbers, canGoBack, canGoForward, indexOfLastCard, indexOfFirstCard, showingRange } = useMemo(() => {
		const pageNumbers = [];
		for (let i = 1; i <= Math.ceil(totalCards / cardsPerPage); i++) {
			pageNumbers.push(i);
		}

		const canGoBack = currentPage > 1;
		const canGoForward = currentPage < pageNumbers.length;
		const indexOfLastCard = currentPage * cardsPerPage;
		const indexOfFirstCard = indexOfLastCard - cardsPerPage + 1;
		const showingRange = `${indexOfFirstCard} to ${Math.min(indexOfLastCard, totalCards)} of ${totalCards}`;

		return { pageNumbers, canGoBack, canGoForward, indexOfLastCard, indexOfFirstCard, showingRange };
	}, [cardsPerPage, totalCards, currentPage]);

	const dropdownOptions = pageNumbers;

	return (
		<PaginationContainer>
			<PageEntries>
				Show <Dropdown options={dropdownOptions} selectedOption={currentPage} onSelectOption={paginate} /> Entries
				<EntriesInfo>Showing {showingRange}</EntriesInfo>
			</PageEntries>

			<PageItems>
				<PageItem disabled={!canGoBack}>
					<PageLink onClick={() => canGoBack && paginate(currentPage - 1)}>
						<Icon name={"arrowLeftOutlined"} width={"10px"} height={"10px"} />
					</PageLink>
				</PageItem>
				{pageNumbers.map(number => (
					<PageItem key={number} active={number === currentPage} onClick={() => paginate(number)}>
						<PageLink>
							{number}
						</PageLink>
					</PageItem>
				))}
				<PageItem disabled={!canGoForward}>
					<PageLink onClick={() => canGoForward && paginate(currentPage + 1)}>
						<Icon name={"arrowRightOutlined"} width={"10px"} height={"10px"} />
					</PageLink>
				</PageItem>
			</PageItems>
		</PaginationContainer>
	);
};

export default Pagination;