import React, { useContext } from "react";
import {
  TopMenu,
  HamburguerWrapper,
  TopMenuContentWrapper,
  HamburguerAction,
  Title,
  BackAction,
} from "./TopBar.styled";
import { Icon } from "../../../elements";
import { SidebarContext } from "./SidebarContext";
import { useStore } from "../../../hooks";
import { useLocation } from "react-router-dom";

const TopBar = () => {
  const { toggleSidebar } = useContext(SidebarContext);
  const { authStore } = useStore();
  const location = useLocation();

  const shouldShowBackButton = () => {
    return location.pathname !== "/" && location.pathname !== "/home";
  };

  return (
    <TopMenu>
      <HamburguerWrapper>
        <HamburguerAction onClick={toggleSidebar}>
          <Icon name={"mobileMenu"} width={"16px"} height={"10px"} />
        </HamburguerAction>
        {shouldShowBackButton() && (
          <BackAction onClick={() => window.history.back()}>
            <Icon
              name={"arrowBackOutlinedBlack"}
              width={"12.5px"}
              height={"10.5px"}
            />
          </BackAction>
        )}
        <Title>{authStore.title}</Title>
      </HamburguerWrapper>
      
      <TopMenuContentWrapper>
        {authStore.navbarActionElement}
      </TopMenuContentWrapper>
    </TopMenu>
  );
};

export default TopBar;
