import React, { useEffect, useState } from "react";
import { InputText } from "../../../../../elements";
import {
  FormContainer,
  TwoColumnRow,
} from "./GroupDetails.styled";
import TextArea from "../../../../../elements/v2/TextArea/TextArea";

const GroupDetails = ({ groupDetails, onFieldChange, onDiscard }) => {
  const [localGroupDetails, setLocalGroupDetails] = useState(groupDetails);

  useEffect(() => {
    setLocalGroupDetails(groupDetails);
  }, [groupDetails]);

  useEffect(() => {
    if (onDiscard) {
      onDiscard(() => setLocalGroupDetails(groupDetails));
    }
  }, [onDiscard, groupDetails]);

  const handleChange = (field, value) => {
    const updatedDetails = { ...localGroupDetails, [field]: value };
    setLocalGroupDetails(updatedDetails);
    onFieldChange(field, value);
  };

  return (
    <FormContainer>
      <div>
        <label>Name</label>
        <InputText
          placeholder="Name"
          value={localGroupDetails?.group || ''}
          onChange={(e) => handleChange("group", e.target.value)}
          width="800px"
        />
      </div>

      <div>
        <label>Description</label>
        <TextArea
          
          height="120px"
          resize="none"
          placeholder="Enter description"
          width="800px"
          value={localGroupDetails?.group || ''}
          onChange={(e) => handleChange("description", e.target.value)}
        />
      </div>

      <TwoColumnRow>
        <div>
          <label>Date Created</label>
          <InputText
            placeholder="Date Created"
            value={localGroupDetails?.createdDate || ''}
            width="390px"
            readOnly
          />
        </div>

        <div>
          <label>Last Modification</label>
          <InputText
            placeholder="Last Modification"
            value={localGroupDetails?.lastModification || localGroupDetails?.createdDate || ''}
            width="390px"
            readOnly
          />
        </div>
      </TwoColumnRow>
    </FormContainer>
  );
};

export default GroupDetails;
