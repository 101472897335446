import React from "react";
import { observer } from 'mobx-react';
import Tabs from "../../../elements/v2/Tabs/Tabs";
import { useStore } from "../../../hooks";
import { ViewContainer } from "./PersonaPage.styled";
import PersonalizationPage from "./PersonalizationPage/PersonalizationPage";


const PersonaPage = observer(() => {

  const { changeTitle } = useStore();
  changeTitle('Settings');

    const tabs = [
        {
          label: 'Profile'
        },
        {
            label: 'Persona',
            content: (
              <PersonalizationPage />
            ),
          },
    ];

    return (
        <ViewContainer>
            <Tabs tabs={tabs} />
        </ViewContainer>
    );
});

export default PersonaPage;
