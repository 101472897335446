import styled from 'styled-components';

const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.clearGray};
    background: ${(props) => props.theme.colors.primaryWhite};
    height: 48px;
`;

const InputField = styled.input`
    width: 100%;
    height: 40px;
    border: none;
    outline: none;

    background: ${(props) => props.theme.colors.primaryWhite};
    ${(props) => props.theme.fonts.variant.regular()};
    
    ::placeholder {
        color: ${(props) => props.theme.colors.clearGray};
    }
`;

const SearchIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
`;

export {
  InputWrapper,
  InputField,
  SearchIcon
};
