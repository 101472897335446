import React from 'react';
import {
    Backdrop,
    ModalContainer,
    ModalImage
} from "./SuccessfulModal.styled";
import {
    ModalWrapper,
    ModalSubtitle,
    ModalTitle,
    ModalButtonsContainer,
} from "./ConfirmationDialog.styled";
import AdmirationSign from '../../elements/icon/assets/admiration_sign.svg';
import { RoundedButton } from '../button';

const ConfirmationDialog = ({ isOpen, onClose, onConfirm, title, subtitle, confirmationLabel, cancelLabel}) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <Backdrop isOpen={isOpen}>
            <ModalWrapper>
                <ModalContainer>
                    <ModalImage src={AdmirationSign} width={40} height={40} onClick={() => handleClose()} />
                    <ModalTitle>{title}</ModalTitle>
                    <ModalSubtitle>{subtitle}</ModalSubtitle>
                    <ModalButtonsContainer>
                        <RoundedButton width={'180px'}  kind={'secondary'} onClick={onClose}>{cancelLabel || 'Cancel'}</RoundedButton>
                        <RoundedButton width={'180px'}  kind={'primary'} onClick={onConfirm}>{confirmationLabel || 'Ok'}</RoundedButton>
                    </ModalButtonsContainer>
                </ModalContainer>
            </ModalWrapper>
        </Backdrop>
    );
};

export default ConfirmationDialog;
