import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks";
import { FormTitle, FormSubtitle, FormCampaignWrapper } from "../NewCampaignPage.Styled";
import { CustomSelect } from "../../../elements";

const CampaignModuleForm = observer(() => {
    const { newCampaign } = useStore();
    const [module, setModule] = useState(null);

    const handleModuleSelect = (name, value) => {
        setModule(name);
        const moduleData = newCampaign.moduleList.find(module => module.value === value);
        if (moduleData) {
            const type = moduleData.type;
            newCampaign.onChangeModule(name, value, type);
        }
    };

    return (
        <FormCampaignWrapper>
            <FormTitle>Fantastic!</FormTitle>
            <FormSubtitle>Now, select your conversation starter.</FormSubtitle>
            <CustomSelect 
                placeholder="Select Type" 
                selectedDefault={newCampaign.moduleName} 
                options={newCampaign.moduleList} 
                margin="80px 0 90px 0" 
                handleSelect={(name, value) => handleModuleSelect(name, value)}
            />
        </FormCampaignWrapper>
    );
});

export default CampaignModuleForm;
