import { FilesWrapper, FilesListItem, FilesListContainer, FilesTextWrapper, FilesTextBold, FilesName} from "./FilesList.styled";
import { Icon } from "../icon";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IconButton } from "@mui/material";

const FilesList = ({ files, onClickRemove }) => {

  return (
    <FilesWrapper>
    
        <FilesListContainer>
            {files.map((row, index) => (
                <FilesListItem key={index}>
                    <Icon name={"file"} height={'20px'} width={'20px'} margin='0 2px'/>
                    <FilesTextWrapper>
                        <FilesTextBold>File</FilesTextBold>
                        <FilesName>{row}</FilesName>
                        <FilesTextBold>uploaded</FilesTextBold>
                    </FilesTextWrapper>
                    <IconButton onClick={() => onClickRemove(row)}> <CloseRoundedIcon/></IconButton>
                </FilesListItem>
            ))}
    </FilesListContainer>

    </FilesWrapper>
  );
}

export default FilesList;
