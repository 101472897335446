import React, { useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { observer } from "mobx-react-lite";
import * as xlsx from 'xlsx';
import Papa from 'papaparse';
import { Button } from "@mui/material";
import { useStore } from "../../../hooks";
import FileIcon from "../../../elements/icon/assets/file.svg"
import Trash from "../../../elements/icon/assets/trash.svg"
import {
    FormCampaignWrapper,
    FileInfo,
    FileName,
    FileSize, 
    FileInfoContainer, 
    XIcon
} from "../ContactFlowPage.Styled";

const CampaignDragAndDropForm = observer(() => {
    const { contactStore } = useStore();
    const inputRef = useRef(null);

    const onDrop = useCallback((acceptedFiles) => {
        contactStore.setSelectedFiles(acceptedFiles);

        for (const file of acceptedFiles) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const binaryStr = event.target.result;
                if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
                    const workbook = xlsx.read(binaryStr, { type: "binary" });

                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];

                    const data = xlsx.utils.sheet_to_json(worksheet, { header: 1 });

                    contactStore.setColumnContents(data);
                } else if (file.name.endsWith('.csv')) {
                    Papa.parse(binaryStr, {
                        header: true,
                        complete: (result) => {
                            contactStore.setColumnContents(result.data);
                        }
                    });
                }
            };

            reader.readAsBinaryString(file);
        }
        handleUpload();
    }, [contactStore]);

    const handleUpload = async () => {
        try {
            contactStore.setIsUploading(true);

            for (const file of contactStore.selectedFiles) {
                if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        const binaryStr = event.target.result;
                        const workbook = xlsx.read(binaryStr, { type: "binary" });

                        const sheetName = workbook.SheetNames[0];
                        const worksheet = workbook.Sheets[sheetName];

                        const data = xlsx.utils.sheet_to_json(worksheet, { header: 1 });

                        if (data.length > 0) {
                            const xlsColumns = data[0].map(String);
                            contactStore.setColumnNames(xlsColumns);
                            const columnContents = {};

                            xlsColumns.forEach(column => {
                                const columnIndex = data[0].indexOf(column);
                                if (columnIndex !== -1) {
                                    const content = data.slice(1).map(row => row[columnIndex]);
                                    columnContents[column] = content;
                                }
                            });
                            contactStore.setColumnContents(columnContents);
                            contactStore.setUploadFile(true);
                        }
                    };

                    reader.readAsBinaryString(file);
                } else if (file.name.endsWith('.csv')) {
                    Papa.parse(file, {
                        header: true,
                        complete: (result) => {
                            if (result.data.length > 0) {
                                const csvColumns = Object.keys(result.data[0]);
                                contactStore.setColumnNames(csvColumns);
                                const columnContents = {};

                                csvColumns.forEach(column => {
                                    const content = result.data.map(row => row[column]);
                                    columnContents[column] = content;
                                });
                                contactStore.setColumnContents(columnContents);
                                contactStore.setUploadFile(true);
                            }
                        }
                    });
                } else {
                    contactStore.setError('Unsupported file type');
                }
            }
        } catch (error) {
            contactStore.setError('Error uploading files:', error);
        } finally {
            setTimeout(() => {
                contactStore.setIsUploading(false);
            }, 3000);
        }
    };

    const handleFileDelete = (fileName, event) => {
        event.stopPropagation();
        contactStore.setSelectedFiles([]);
        contactStore.setColumnNames([]);
        contactStore.setColumnContents([]);
        contactStore.buttonUpload = false;
        contactStore.setShowCustomSelectFirstName(false);
        contactStore.setShowCustomSelectLastName(false);
        contactStore.setShowCustomSelectEmail(false);
        contactStore.setShowCustomSelectPhone(false);
        contactStore.setShowCustomSelectZipCode(false);
        contactStore.setAreAllFieldsDone(false);
    };

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop,
    });

    const handleUploadButtonClick = () => {
        inputRef.current.click();
    };

    return (
        <div>
            <FormCampaignWrapper>
                <div className={`dropzone ${isDragActive ? "active" : ""}`}>
                    <div
                        {...getRootProps()}
                        uploading={contactStore.isUploading}
                    >
                        <input {...getInputProps({ref: inputRef})} />
                        {isDragAccept && <p>Drop the files here ...</p>}
                        {isDragReject && <p>Unsupported file type</p>}
                        {!contactStore.isUploading && contactStore.selectedFiles.length === 0 && (
                            <p>Drag and drop file, or click to select a file.</p>
                        )}
                        {contactStore.selectedFiles.map((file, index) => (
                            <div key={index}>
                                <FileInfoContainer>
                                    <XIcon>
                                        <img src={Trash} onClick={(e) => handleFileDelete(file.name, e)} />
                                    </XIcon>
                                    <FileInfo>
                                        <img src={FileIcon} alt="File" />
                                        <div>
                                            <FileName>File uploaded, and renamed "{file.name}"</FileName>
                                            <FileSize>{(file.size / 1024).toFixed(2)} KB</FileSize>
                                        </div>
                                    </FileInfo>
                                </FileInfoContainer>
                            </div>
                        ))}
                    </div>
                </div>
                <br />
                <Button
                    variant="contained"
                    onClick={handleUploadButtonClick}
                >
                    + Upload Your File
                </Button>
            </FormCampaignWrapper>
        </div>
    );
});

export default CampaignDragAndDropForm;
