import { action, flow, makeObservable, observable } from "mobx";
import RoleValidationStore from "./RoleValidationStore";
import { API } from "aws-amplify";
import { getPermissionCatalogUser, getRolePermissions, getRoles, putPermissionsRole, putRole } from "../../../graphql/queries";

class ListRoleStore {
  roles = [];
  members = [];
  status = 'idle';
  customer = '';
  row = null;
  originalRow = null;
  showDialogOpenDelete = false;
  validation = new RoleValidationStore();
  users = [];
  isLoading = false;
  isError = false;
  errorMessage = "";
  selectedRole = {};
  permissionsCatalog = [];
  rolePermissions = [];

  constructor() {
    makeObservable(this, {
      customer: observable,
      roles: observable,
      members: observable,
      status: observable,
      row: observable,
      originalRow: observable,
      showDialogOpenDelete: observable,
      validation: observable,
      users: observable,
      isLoading: observable,
      isError: observable,
      errorMessage: observable,
      selectedRole: observable,
      permissionsCatalog: observable,
      rolePermissions: observable,
      setRow: action,
      setOriginalRow: action,
      updateRoleDetails: action,
      restoreOriginalRole: action,
      setShowDialogOpenDelete: action,
      onChangeRoleDetails: action,
      setCustomer: action,
      setError: action,
      createRole: flow,
      onFetchRoles: flow,
      onFetchMembers: flow,
      onDeleteRole: flow,
      onPutRole: flow,
      fetchRoleData: flow,
      fetchUsers: action,
      fetchPermissionsCatalogUser: flow,
      saveAccess: flow,
      fetchRolePermissions: flow
    });

  }

  onChangeRoleDetails(key, value){
    this.selectedRole[key] = value;
  }

  setCustomer(customer){
    this.customer = customer;
  }

  setRow(row) {
    this.row = row;
    this.originalRow = { ...row };
  }

  setOriginalRow(row) {
    this.originalRow = { ...row };
  }

  updateRoleDetails(field, value) {
    if (this.row) {
      this.row[field] = value;
    }
  }

  restoreOriginalRole() {
    if (this.originalRow) {
      this.row = { ...this.originalRow };
    }
  }

  setShowDialogOpenDelete(value) {
    this.showDialogOpenDelete = value;
  }

  setError(errorMessage) {
    this.isError = true;
    this.errorMessage = errorMessage;
  }

  *fetchPermissionsCatalogUser(){
    try {
      this.status = "loading";
      const response = yield API.graphql({
        query: getPermissionCatalogUser,
        variables: { input: {
          customer_id: "",
          user_id: "",
        } },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      this.permissionsCatalog = JSON.parse(response.data.getPermissionCatalogUser.body)
      this.status = "idle";
    } catch (e) {
      this.status = "error";
    }
  }

  *createRole(name, description, id='') {
    try {
      this.status = "loading";
      yield API.graphql({
        query: putRole,
        variables: { 
          input: {
            customer: this.customer,
            name,
            description,
            id,
            metadata: '{}',
          } 
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });

      if(!id){
        this.onFetchRoles();
      }
  
      this.status = "idle";
    } catch (e) {
      this.status = "error";
    }
  }

  *onFetchRoles() {
    try {
      this.status = "loading";
      const response = yield API.graphql({
        query: getRoles,
        variables: { 
          input: {
            customer_id: this.customer
          } 
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      const data = JSON.parse(response.data.getRoles.body)
      this.roles = 	data
      this.status = "idle";
    } catch (e) {
      this.status = "error";
    }
  }

  *onFetchMembers() {
    try {
      this.status = "loading";
      this.members = [
        {
          id: "1",
          firstName: "Alex",
          lastName: "Smith",
          email: "alexsmith@email.com",
          memberSince: "01/30/2024",
        },
        {
          id: "2",
          firstName: "Jane",
          lastName: "Doe",
          email: "janedoe@email.com",
          memberSince: "02/15/2024",
        },
      ];
      this.status = "idle";
    } catch (e) {
      this.status = "error";
    }
  }

  *onDeleteRole(id) {
    try {
      this.status = "loading";
      this.roles = this.roles.filter((role) => role.id !== id);
      this.status = "idle";
    } catch (e) {
      this.status = "error";
    } finally {
      this.setRow(null);
      this.setShowDialogOpenDelete(false);
    }
  }

  *onPutRole(values) {
    try {
      this.status = "loading";

      if (this.validation.roleName) {
        this.status = "error";
        return;
      }

      const existingRoleIndex = this.roles.findIndex(
        (role) => role.id === values.id
      );
      if (existingRoleIndex > -1) {
        this.roles[existingRoleIndex] = {
          ...this.roles[existingRoleIndex],
          ...values,
        };
      } else {
        this.roles.push({
          ...values,
          id: String(this.roles.length + 1),
          createdDate: new Date().toISOString().split("T")[0],
        });
      }

      this.status = "idle";
    } catch (error) {
      this.status = "error";
    }
  }

  *fetchRoleData(roleId) {
    try {
      this.status = "loading";
      const response = yield API.graphql({
        query: getRoles,
        variables: { 
          input: {
            customer_id: this.customer,
            role_id: roleId
          } 
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      const data = JSON.parse(response.data.getRoles.body)
      const roleData = data[0];
      if (roleData) {
        this.selectedRole = roleData;
      }
      this.status = "idle";
    } catch (e) {
      this.status = "error";
    }
  }

  fetchUsers = (searchTerm = "") => {
    this.isLoading = true;
    try {
      const usersData = [
        { id: 1, name: "Cesar", email: "Cesar@dealerlakes.com" },
        { id: 2, name: "Alex", email: "Alex@dealerlakes.com" },
        { id: 3, name: "Sebastian", email: "Sebastian@dealerlakes.com" }
      ];

      this.users = usersData.filter(
        (user) =>
          user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase())
      );

      this.isLoading = false;
    } catch (error) {
      this.isError = true;
      this.isLoading = false;
    }
  };

  *saveAccess(rolePermissions){
    try {
      this.status = "loading";
      yield API.graphql({
        query: putPermissionsRole,
        variables: { 
          input: {
            customer_id: this.customer,
            role_id: this.selectedRole.id,
            permissions: JSON.stringify(rolePermissions)
          } 
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      this.status = "idle";
    } catch (e) {
      this.status = "error";
    }
  }

  *fetchRolePermissions(roleId){
    try {
      this.status = "loading";
      const response = yield API.graphql({
        query: getRolePermissions,
        variables: { 
          input: {
            customer_id: this.customer,
            role_id: roleId
          } 
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
  
      this.rolePermissions = JSON.parse(response.data.getRolePermissions.body)
      this.status = "idle";
    } catch (e) {
      this.status = "error";
    }
  }
}

export default ListRoleStore;
