import RoundedIconButton from "../Buttons/RoundedIconButton/RoundedIconButton";
import { ListItem, ListTitle, ListSubtitle, ListText } from "./ActionsList.styled"

const ActionItem = ({icon, title, subtitle, onClick}) => {
    return (
        <ListItem onClick={onClick}>
            <RoundedIconButton icon={icon} width='24px' height='24px'/>
            <ListText>
                <ListTitle>{title}</ListTitle>
                <ListSubtitle>{subtitle}</ListSubtitle>
            </ListText>
        </ListItem>  
    )
}

export default ActionItem