import {Button, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {API, graphqlOperation, Hub} from "aws-amplify";
import {CONNECTION_STATE_CHANGE} from '@aws-amplify/pubsub';
import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Loading from "../../components/common/Loading";
import ChatWindowComponent from './components/ChatWindowComponent';
import ChatQuestions from './components/ChatQuestions';
import {ChatInput} from '../../elements';
import Spinner from "../../components/common/Spinner";
import {getAgent, getConversationChatAssistant} from "../../graphql/queries";
import {onChatAssistantResponse} from "../../graphql/subscriptions";
import AddIcon from '@mui/icons-material/Add';
import {useStore} from "../../hooks";
import {observer} from "mobx-react";
import _ from "lodash"

Hub.listen('api', (data) => {
    const {payload} = data;
    if (payload.event === CONNECTION_STATE_CHANGE) {
        const connectionState = payload.data.connectionState;
    }
});


const ChatWindow = observer(() => {
    const {changeTitle, authStore} = useStore();
    changeTitle('Test Chats');
    const [agentIsTyping, setAgentIsTyping] = useState(false);
    const navigate = useNavigate();
    const [isChatQuestionsVisible, setIsChatQuestionsVisible] = useState(false);
    const {isLoading, isSuperAdmin, selectedAccount } = authStore;
    const location = useLocation();

    const {agent, message, params} = location.state || {};

    const [showSpinner, setShowSpinner] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");

    const [agents, setAgents] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState("");
    const [agent_params, setAgent_params] = useState({
        message: "",
        campaign_id: "",
        campaign_version: "",
        agent: "",
    });

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const maxRetries = 3; // Maximum number of retry attempts
    const [isSendMessageClicked, setIsSendMessageClicked] = useState(false); // State to track send message button click

    const handleSelectQuestion = (selectedQuestion) => {
        setNewMessage(selectedQuestion);
    };

    const bottomRef = useRef(null);

    useEffect(() => {
        fetchAgents(selectedAccount.id);
    }, [selectedAccount]);

    useEffect(() => {
        fetchAgents(selectedAccount.id);
        setSelectedAgent(agent);
        setNewMessage(message || "");
    }, [selectedAccount, agent, message, params]);

    const handleAgentChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedAgent(selectedValue);
        setAgent_params((prevParams) => ({
            ...prevParams,
            agent: selectedValue.id,
            is_concierge: selectedValue.domain === 'concierge'
        }));
    };

    const fetchAgents = async (customer) => {
        try {
            const response = await API.graphql({
                query: getAgent,
                variables: {input: {customer_id: customer}},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            setAgents(JSON.parse(response.data.getAgent.body));
        } catch (error) {
            console.error("Error getting Agents", error);
            showFeedback("Error fetching agents");
        }
    };

    const handleSendMessage = async () => {
        if (newMessage.trim() === "") return;

        setIsSendMessageClicked(true);

        setNewMessage("");
        const params = {...agent_params, message: newMessage, customer_id: selectedAccount.id};

        try {

            let openAiAnswer = null;

            // Try sending the request a maximum of maxRetries times
            let local_msgs = []
            local_msgs = _.union(messages, [])


            let resultConversationOpenAi = null
            try {
                resultConversationOpenAi = await API.graphql({
                    query: getConversationChatAssistant,
                    variables: {input: params},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                if (!resultConversationOpenAi.errors?.length) {
                    setAgentIsTyping(true);

                    const apiRespnse = resultConversationOpenAi.data.getConversationChatAssistant;

                    const parseResponseBody = (responseBody) => JSON.parse(responseBody);

                    local_msgs = _.union(local_msgs, [{message: newMessage, type: "sent"}])
                    setMessages(local_msgs);

                    // Subscribe to chat assistant response
                    let assistantResponseSub = API.graphql({
                        ...graphqlOperation(onChatAssistantResponse, {id: apiRespnse.id}),
                        authMode: 'AMAZON_COGNITO_USER_POOLS'
                    }).subscribe({
                        next: ({provider, value}) => {

                            let subApiRespnse = value.data.onChatAssistantResponse;                        
                            let body = parseResponseBody(
                                subApiRespnse.body
                            )
                            openAiAnswer = body.answer;

                            if (subApiRespnse.status == 'done') {
                                local_msgs = _.union(local_msgs, [{message: openAiAnswer, type: "received"}])
                            } else if (subApiRespnse.status == "error") {
                                const { error } = body;
                                local_msgs = [{message: error, type: "received"}]
                            }
                            assistantResponseSub.unsubscribe();
                            setShowSpinner(false);
                            setAgentIsTyping(false);
                            setMessages(local_msgs);
                        },
                        error: (error) => {
                            console.warn(error)
                            setShowSpinner(false);
                            local_msgs = _.union(local_msgs, [{
                                    message:
                                        "Oops! Something went wrong. Please try again.",
                                    type: "received",
                                }])
                            setMessages(local_msgs);
                            // scrollToBottom();
                        }
                    });

                }
            } catch (err) {
                console.log(err)
                setMessages([
                    ...messages,
                    {
                        message:
                            "Oops! Something went wrong. Please try again.",
                        type: "received",
                    },
                ]);
                // scrollToBottom();
            }
            setIsChatQuestionsVisible(false);
        } catch (error) {
            console.error(error);

            if (
                error.errors &&
                error.errors[0].errorType === "Lambda:ExecutionTimeoutException"
            ) {
                setMessages([
                    ...messages,
                    {
                        message:
                            "Sorry, I'm taking longer than expected to get your answer. I'll notify my team to teach me more about this topic. main while, lets continue with other questions you have?",
                        type: "received",
                    },
                ]);
                // scrollToBottom();
            }
        }
    };

    // Use useEffect to scroll to the bottom whenever new messages arrive
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
    };

    const showFeedback = (message) => {
        setSnackbarMessage(message);
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    if (isLoading) {
        return <Loading/>;
    }

    return (
        <div
            className="container"
            style={{
                width: "100%",
                marginLeft: "10px",
                marginRight: "10px",
            }}
        >
            <div style={{display: 'flex'}}>
                <FormControl variant="standard" sx={{m: 1, minWidth: 200}}>
                    <InputLabel id="select-label">Agent Selection</InputLabel>
                    <Select
                        name="agentSelection"
                        labelId="select-label"
                        id="select"
                        onChange={handleAgentChange}
                        value={selectedAgent}
                        variant="standard"
                        sx={{m: 1, minWidth: 200}}
                    >
                        {agents.map((agent) => (
                            <MenuItem key={agent.id} value={agent}>
                                {agent.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            {isChatQuestionsVisible && (
                <ChatQuestions onSelectQuestion={handleSelectQuestion}/>
            )}
            <div className="chat-window">
                <div style={{
                    padding: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingTop: 0
                }}>
                    <Typography variant="h6" style={{marginBottom: 0}}>Chat
                        with {selectedAgent ? selectedAgent.name || "Agent" : "Agent"}</Typography>
                    <div style={{flexGrow: 1}}/>
                    <Button
                        variant="contained"
                        onClick={() => navigate('/assistants')}
                        startIcon={<AddIcon/>}
                        style={{textTransform: 'none', backgroundColor: 'black'}}
                    >
                        Create New Agent
                    </Button>
                </div>
                <div
                    className="chat-messages"
                    style={{maxHeight: "600px", overflowY: "auto"}}
                >
                    {!isChatQuestionsVisible && (
                        <ChatWindowComponent
                            selectedAgent={selectedAgent}
                            showSpinner={showSpinner}
                            messages={messages}
                            bottomRef={bottomRef}
                            agentIsTyping={agentIsTyping}
                        />
                    )}
                    {/*<div ref={bottomRef}/>*/}
                </div>
            </div>
            <div style={{padding: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{width: '60px', height: '60px'}}>
                    {showSpinner && <Spinner className="spinner"/>} {/* Show spinner if showSpinner is true */}
                </div>
                <ChatInput onChange={(e) => setNewMessage(e.target.value)} onKeyPress={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey && !showSpinner) {
                        e.preventDefault();
                        handleSendMessage();
                    }
                }} showSpinner={showSpinner} newMessage={newMessage} handleSendMessage={handleSendMessage}/>
            </div>
        </div>
    );
});

export default ChatWindow;
