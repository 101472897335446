import { Route, Routes } from "react-router-dom";
import App from "./App";
import MobileApp from "./mobile/App";

import { useMediaQueries } from "../../utils/mediaQueries";

const AppPage = () => {
  const { isMobile } = useMediaQueries();
  if (isMobile) return <MobileApp />;
  return <App />;
};

export default AppPage;