import React from 'react'
import CardInformation from './Components/CardInformation/CardInformation';
import GeneralInformation from './Components/GeneralInformation/GeneralInformation';

const General = () => {
  return (
    <>
    <CardInformation></CardInformation>
    <GeneralInformation></GeneralInformation>
    </>
  )
}

export default General;
