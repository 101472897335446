import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import InputText from "../../../../../elements/v2/TextInput/TextInput.jsx";
import { initialTones } from "../../../../NewAgentPage/components/consts.js";
import CheckBox from "../../../../../elements/v2/Checkbox/Checkbox.jsx"
import RadioButton from "../../../../../elements/v2/RadioButton/RadioButton.jsx";
import { useStore } from "../../../../../hooks/index.js";
import TeamTagsViewer from "../../../../../elements/v2/TeamTagsViewer/TeamTagsViewer.jsx"
import {
    Title,
    ContainerInnerTab,
    CheckboxContainer,
    RadioButtonContainer
} from "../../PlaygroundView.styled.js";

const AdvancedConfigurationTab = observer(() => {

    const { editAgent } = useStore();

    const [toneOptions, setToneOptions] = useState(initialTones);
    
    useEffect(() => {
        if(toJS(editAgent.tones)){
          const savedTones = {...initialTones};
          toJS(editAgent.tones)?.map(tone => {
              if(tone in savedTones){
                  savedTones[tone] = true
              }
          })
          setToneOptions({...savedTones})
        }
        
    }, [editAgent.tones.length, editAgent.name])
    
    const onSelectTone = (tone) => {
        const updatedOptions = {...toneOptions, ...tone}
        setToneOptions(updatedOptions)
    
        const filteredTones = Object.keys(updatedOptions).filter(tone => updatedOptions[tone]);
        if(filteredTones){
            editAgent.onChangeTones(filteredTones);
        }
    }

    const handleRadioChange = (value) => { 
        editAgent.onChangeAnswerExtension(value);
    }; 

    const radioButtonChecked = value => editAgent.answerExtension === value;

    return (
        <ContainerInnerTab>

            <span>
                <Title>Team member</Title>
                
                <TeamTagsViewer teams={['HR Team', 'Sales Team']}/>
                
            </span>

            <span>
                <Title>Title</Title>
                <InputText 
                    placeholder="Enter title"
                />
            </span>

            <span>
                <Title>Agent's tone</Title>
                <CheckboxContainer>
                    {toneOptions && 
                        Object.keys(toneOptions).map(option => 
                            <CheckBox 
                                key={option} 
                                label={option} 
                                onSelectValue={onSelectTone} 
                                options={toneOptions} 
                                width="13vw" 
                            />
                        )
                    }
                </CheckboxContainer>
            </span>

            <span>
                <Title>Reply style</Title>
                <RadioButtonContainer>
                    <RadioButton label="Extensive" value="extensive" name="replyStyle" onChange={handleRadioChange} checked={radioButtonChecked('extensive')}/>
                    <RadioButton label="Summarized" value="summarized" name="replyStyle" onChange={handleRadioChange} checked={radioButtonChecked('summarized')}/>
                </RadioButtonContainer>
            </span>

        </ContainerInnerTab>
    );
});

export default AdvancedConfigurationTab;
