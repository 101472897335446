import React from 'react';
import { StyledMenu, StyledMenuItem, StyledTitle } from './ActionList.styled';

const ActionList = ({ anchorEl, open, onClose, options }) => {
  return (
    <>
      {options && options.length > 0 && (
        <StyledMenu
          anchorEl={anchorEl}
          open={open}
          onClose={onClose}
        >
          {options.map((option, index) => (
            <StyledMenuItem key={index} onClick={() => { option.onClick && option.onClick()}}>
              <StyledTitle>{option.title}</StyledTitle>
            </StyledMenuItem>
          ))}
        </StyledMenu>
      )}
    </>
  );
};

export default ActionList;