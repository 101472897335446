import styled from "styled-components";

const CustomizeQButton = styled.div`
    height: 50px;
    width: 140px;
    background: ${props => props.theme.colors.white};
    border-radius: 12px;
    border: 1px ${props => props.theme.colors.grey} solid;
    color: ${props => props.theme.colors.grey};
    margin: 5px;
    text-align: center;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.5s;

    &:hover {
        background-color: ${props => props.theme.colors.black};
        color: ${props => props.theme.colors.white};
    }
`;

const HomeChatWrapper = styled.div`
    margin: 0 10px;
`;

const ChatWindowWrapper = styled.div`
    
    overflow-y: auto;
`;

const ChatInputWrapper = styled.div`
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Separator = styled.div`
    width: 60px;
    height: 60px;
`;

export {
    CustomizeQButton,
    HomeChatWrapper,
    ChatWindowWrapper,
    ChatInputWrapper,
    Separator
}