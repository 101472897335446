import styled, {css} from 'styled-components';

const ImageSelectorWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ImageSelectorSettings = styled.div`
    width: 120px;
    height: 83px;
    background-color: ${props => props.theme.colors.primaryWhite};
    position: absolute;
    top: 80px;
    list-style: none;
    padding: 0;
    transition: all 0.5s;
    z-index: 4;
    font-size: ${props => props.theme.fonts.size.b2};   

    li {
        padding: 10px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid  ${props => props.theme.colors.clearGray};
    }
`;

const ImageSelectorButton = styled.div`
    cursor: pointer;
    width: 80px;
    height: 80px;
    border: 2px dashed;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.highClearGray};
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        border-radius: 50%;
        width: ${(props) => props.$imageSelected};
        height: ${(props) => props.$imageSelected};
    }
`;

export {
    ImageSelectorButton,
    ImageSelectorWrapper,
    ImageSelectorSettings
}