import React, {
  useEffect,
  useMemo,
  useState,
} from "react";
import { useStore } from "../../../../hooks";
import { observer } from "mobx-react";
import {
  BreadCrumb,
  MarketingContainer,
  MarketingWrapper,
  MarketingWrapperHeader,
  CustomGenericListStyleDesktop,
  MessageActionsWrapper,
  TabButtonContainer,
  TabContainer,
  UploadFilesActionsWrapper,
  SegmentsActionsWrapper,
} from "./Marketing.styled";
import { RoundedButton } from "../../../../elements/button";
import Segments from "./Segments";
import Overview from "./Overview";
import Message from "./Message";
import { Icon, SuccessfulModal } from "../../../../elements";
import { useMediaQueries } from "../../../../utils/mediaQueries";
import { useSearchParams, useNavigate } from "react-router-dom";
import Spinner from "../../../../components/common/Spinner";
import Tabs2 from "../../../../elements/v2/Tabs2/Tabs2";
import IconSelect from "../../../../elements/v2/Selects/IconSelect/IconSelect";
import { ConfirmationModal } from "../../../../elements/v2/modals";

const tabs = [
  {
    label: "Message",
  },
  {
    label: "Segments",
  },
  {
    label: "Overview",
  },
];

const MarketingTab = {
  Message: 0,
  Segments: 1,
  Overview: 2,
};

const Marketing = observer(() => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { changeTitle, authStore, marketingStore, templateEditorStore, contactSegmentStore } = useStore();
  const [currentTab, setCurrentTab] = useState(MarketingTab.Message);
  const [clickedOption, setClickedOption] = useState(null);
  const { rulesStore } = contactSegmentStore;
  const { isMobile } = useMediaQueries();

  const actionsOptions = useMemo(() => [
    {
      name: "Cancel", 
      icon: "cancelSquare", 
      onClick: () => {
        marketingStore.setMarketingAttribute("confirmationDialogMessage", "You're about to end the upcoming conversation. Do you want to continue?")
      },
      onConfirm: () => {
        marketingStore.clearConfirmationDialog();
        marketingStore.cancel(authStore.selectedAccount.id);
      }
    }
  ], [marketingStore])

  changeTitle(null);

  useEffect(() => {
    authStore.fetchAgents();
  }, [authStore]);

  useEffect(() => {
    marketingStore.clear();
    templateEditorStore.clearTemplate();
    contactSegmentStore.clear();

    const marketingId = searchParams.get("id");

    if (!marketingId) {
      navigate("/v2/conversations");
    }

    const id = `${marketingId}/${marketingId}`.replaceAll('#', "");
    templateEditorStore.setId(id);
    contactSegmentStore.setFileId(`${id}_contacts`);
    contactSegmentStore.init(authStore.selectedAccount.id)
    templateEditorStore.updateGeneralField("customer", authStore.selectedAccount.id, );
    templateEditorStore.updateGeneralField("s3FileRoute", "marketing_conversations");
    contactSegmentStore.updateGeneralField("s3FileRoute", "marketing_conversations");
    marketingStore.getMarketingConversations(authStore.selectedAccount.id, marketingId)
  }, []);

  useEffect(() => {
		templateEditorStore.updateGeneralField('sourceList', contactSegmentStore.emailTemplateEventSourceList)
	}, [contactSegmentStore.emailTemplateEventSourceList[0].fields.length])

  useEffect(() => {
    const actions = marketingStore.marketingConversation?.metadata?.actions
    const rules = marketingStore.marketingConversation?.metadata?.filters
    if(actions?.length){
      templateEditorStore.updateGeneralField(
        "customer",
        authStore.selectedAccount.id
      );
      templateEditorStore.loadPayload(actions[0])
      templateEditorStore.setId(`${searchParams.get("id")}/${searchParams.get("id")}`.replaceAll("#", ""));
      templateEditorStore.loadTemplate();
    }

    if(rules?.length){
      contactSegmentStore.rulesStore.loadExistingRules(rules)
      contactSegmentStore.updateAdvancedFilter()
    }
  }, [marketingStore.marketingConversation])

  const onTabChange = (selectedTab) => {
    setCurrentTab(selectedTab);
  };

  const actionsButtons = (
    <>
      {!['active', 'cancelled'].includes(marketingStore.status?.toLowerCase()) && <><RoundedButton
        width="65px"
        height="40px"
        fontSize="0.875rem"
        kind={"blue"}
        onClick={() => {
          marketingStore.setMarketingAttribute("status", "ACTIVE")
          marketingStore.emailTemplateExportAction()
      }}
      >
        Publish
      </RoundedButton>
      <RoundedButton
        width="80px"
        height="40px"
        fontSize="0.875rem"
        kind={"publish"}
        onClick={() => {
          marketingStore.setMarketingAttribute("status", "DRAFT")
          marketingStore.emailTemplateExportAction()
        }}
      >
        Draft
      </RoundedButton></>}
    </>
  );

  const getCorrectPaddingRight = () => {
    const isSegmentTab = currentTab === MarketingTab.Segments;
    if (isSegmentTab) {
      return rulesStore?.showRightPreview ? "470px" : "120px";
    }
    return "5px";
  };

  return (
    <MarketingContainer isMobile={isMobile}>
      <BreadCrumb>
        Conversations Starters &gt; <span>Marketing</span>
      </BreadCrumb>

      {!marketingStore.isLoading && !templateEditorStore.updatingTemplate  ? <MarketingWrapper activeTab={marketingStore.activeTab} paddingRight={getCorrectPaddingRight()}>
        <MarketingWrapperHeader>
          <TabContainer>
            <Tabs2
              tabs={tabs}
              onToggleTab={onTabChange}
              defaultTab={currentTab}
            ></Tabs2>
            <TabButtonContainer>
              {currentTab === MarketingTab.Message && (
                <MessageActionsWrapper>{actionsButtons}</MessageActionsWrapper>
              )}
              {currentTab === MarketingTab.Segments && (
                <SegmentsActionsWrapper>
                  {actionsButtons}
                </SegmentsActionsWrapper>
              )}
              {currentTab === MarketingTab.Overview && (
                <UploadFilesActionsWrapper>
                  {marketingStore.activeCampaign && <IconSelect 
                    placeholder="Actions" 
                    options={actionsOptions}
                    setClickedOption={setClickedOption}
                  />}
                  {actionsButtons}
                </UploadFilesActionsWrapper>
              )}
            </TabButtonContainer>
          </TabContainer>

          <Tabs2.Outlet show={currentTab === MarketingTab.Message}>
            <Message />
          </Tabs2.Outlet>

          <Tabs2.Outlet show={currentTab === MarketingTab.Segments}>
            <Segments />
          </Tabs2.Outlet>

          <Tabs2.Outlet show={currentTab === MarketingTab.Overview}>
            <Overview />
          </Tabs2.Outlet>

        </MarketingWrapperHeader>
      </MarketingWrapper>  : <Spinner/>}
      <CustomGenericListStyleDesktop />

      <SuccessfulModal
        isOpen={marketingStore.isError}
        onClose={() => marketingStore.onClearError()}
        title="Oops!"
        subtitle="An error occurred."
        subtitle2="Please try again later."
        height='300px'
        zIndex={true}
      />

      <SuccessfulModal
        isOpen={marketingStore.isSuccessful}
        onClose={() => marketingStore.setMarketingAttribute('isSuccessful', false)}
        title="Success!"
        subtitle="Campaign has been saved successfully."
        height='200px'
        width='500px'
        zIndex={true}
      />

      <ConfirmationModal
        isOpen={marketingStore.confirmationDialogMessage}
        title="Cancel"
        message={marketingStore.confirmationDialogMessage}
        icon="edit"
        onConfirm={() => clickedOption?.onConfirm?.()}
        onClose={() => marketingStore.clearConfirmationDialog()}
        onConfirmLabel="Continue"
      />
    </MarketingContainer>
  );
});

export default Marketing;
