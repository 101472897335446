import React, { useEffect, useState, useCallback, useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Amplify } from "aws-amplify";
import awsconfig from "../../../aws-exports";
import { ConciergeChat } from "../../../pages/Chatbox/components/ConciergeChat";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks";
import { Outlet, useNavigate } from "react-router-dom";
import { Loading } from "../../loading";
import { Icon } from "../../../elements";
import {
  Content,
  FloatingChatWrapper,
  CloseConciergeButton,
} from "../App.styled";
import { Wrapper } from "./App.styled";
import TopBar from "./TopBar";
import Sidebar from "./Sidebar";
import { SidebarProvider } from "./SidebarContext";

Amplify.configure(awsconfig);

const theme = createTheme({
  palette: {
    primary: { main: "#3D3D3D" },
    secondary: { main: "#FF5722" },
  },
});

const MobileApp = observer(() => {
  const [isConciergeCollapsed, setIsConciergeCollapsed] = useState(true);
  const { authStore } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (authStore.isLoading) {
      authStore.getGlobalInformation();
    }
  }, [authStore]);

  const handleMenuItemClick = (item) => {
    if (item.link) {
      navigate(item.link);
    }
  };

  return authStore.isLoading ? (
    <Loading />
  ) : (
    <SidebarProvider>
      <Wrapper>
        <TopBar />
        <Sidebar
          handleMenuItemClick={handleMenuItemClick}
          navigate={navigate}
        />

        <Content id="main-content">
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Outlet />
          </ThemeProvider>
        </Content>

        <FloatingChatWrapper isCollapsed={isConciergeCollapsed}>
          {!isConciergeCollapsed && (
            <CloseConciergeButton
              onClick={() => setIsConciergeCollapsed(!isConciergeCollapsed)}
            >
              <Icon name={"widgetChat"} width={"inherit"} height={"inherit"} />
            </CloseConciergeButton>
          )}
          <ConciergeChat
            isCollapsed={isConciergeCollapsed}
            onClose={() => setIsConciergeCollapsed(true)}
          />
        </FloatingChatWrapper>
      </Wrapper>
    </SidebarProvider>
  );
});

export default MobileApp;
