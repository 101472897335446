import React, { useCallback, useEffect, useState } from "react";
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useStore } from "../../hooks";
import DefaultStepper from "../../elements/stepper/DefaultStepper";
import BrainImage from '../../elements/icon/assets/brain.png';
import AdmirationSign from '../../elements/icon/assets/admiration_sign.svg';
import { NewUserWrapper } from "./NewUserPage.Styled";
import UserNameForm from './components/UserNameForm';
import UserEmailForm from './components/UserEmailForm';
import UserRoleForm from './components/UserRoleForm';
import SuccessfulModal from "../../elements/modals/SuccesfulModal";

const NewUserPage = observer(() => {
    const navigate = useNavigate();
    const { userStore, authStore } = useStore();
    const { selectedAccount } = authStore;

    userStore.setPage('new');

    useEffect(() => {
        userStore.page.onChangeAccount(selectedAccount.id);
    }, [selectedAccount]);

    const onHandleCancel = useCallback(() => {
        navigate('/');
    }, [navigate]);

    const onFinishStepper = useCallback(() => {
        userStore.page.onCompleteCreation();
    }, [userStore.page]);

    const handleCloseSuccessModal = () => {
        userStore.page.setIsSuccessfulModalOpen(false);
        navigate('/users');
    };

    return (
        <NewUserWrapper>
            <img src={BrainImage} width={185} height={135} />
            <DefaultStepper onFinish={onFinishStepper} onCancel={onHandleCancel} status={userStore.page.isLoading ? 'loading' : userStore.page.isError ? 'error' : 'idle'} stepperActionsWidth='400px' stepperActionsMt='60px'>
                <DefaultStepper.Step isComplete={() => {
                    const isNameValid = userStore.page.onValidateTextField('firstName');
                    const isLastNameValid = userStore.page.onValidateTextField('lastName');
                    return isNameValid && isLastNameValid;
                }} isBack={false} validationErrorLabel="Please enter a name and a last name">
                    <UserNameForm />
                </DefaultStepper.Step>
                <DefaultStepper.Step
                    isComplete={() => {
                        const isPhoneValid = userStore.page.onValidateTextField('phone');
                        const isEmailValid = userStore.page.onValidateTextField('email');
                        return isPhoneValid && isEmailValid;
                    }}
                    phoneError={userStore.page.phoneError}
                    emailError={userStore.page.emailError}
                    validationErrorLabel="Please enter a phone number and an email."
                >
                    <UserEmailForm />
                </DefaultStepper.Step>
                <DefaultStepper.Step isComplete={() => userStore.page.onValidateTextField('role')} labelNext="Complete" validationErrorLabel = "An error occurred while creating the user! Please try again later.">
                    <UserRoleForm />
                </DefaultStepper.Step>
            </DefaultStepper>
            {userStore.page.isSuccessfullModalOpen && <SuccessfulModal
                isOpen={userStore.page.isSuccessfullModalOpen}
                onClose={handleCloseSuccessModal}
                imageSrc={AdmirationSign}
                title="Great!"
                subtitle="User has been"
                subtitle2="successfully created"
            />}
        </NewUserWrapper>
    );
});

export default NewUserPage;
