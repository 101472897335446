import React, { useState } from "react";
import { Icon } from "../../../elements";
import {
  DropdownList,
  DropdownContainer,
  DropdownItem,
  SubMenuList,
  SubMenuItem,
} from "./App.styled";

const CustomizedDropdownList = ({ items, onItemClick }) => {
  const [openMenus, setOpenMenus] = useState({});

  const toggleSubMenu = (index) => {
    setOpenMenus((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <DropdownList>
      {items.map((item, index) => (
        <DropdownContainer key={index}>
          <DropdownItem
            onClick={() =>
              item.customItems && item.customItems.length > 0
                ? toggleSubMenu(index)
                : onItemClick(item)
            }
          >
            <Icon name={item.icon} height="20px" width="20px" />
            <span>{item.text}</span>
            {item.customItems && item.customItems.length > 0 && (
              <Icon
                name={openMenus[index] ? "arrowWhiteUp" : "arrowWhiteDown"}
                height="12px"
                width="12px"
              />
            )}
          </DropdownItem>
          {item.customItems &&
            item.customItems.length > 0 &&
            openMenus[index] && (
              <SubMenuList>
                {item.customItems.map((subItem, subIndex) => (
                  <SubMenuItem key={subIndex} onClick={subItem.onClick}>
                    {subItem.text}
                  </SubMenuItem>
                ))}
              </SubMenuList>
            )}
        </DropdownContainer>
      ))}
    </DropdownList>
  );
};

export default CustomizedDropdownList;
