import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {useStore} from "../../../../hooks";
import {
    Backdrop,
    ModalWrapper,
    ModalContainer,
    ModalTitle,
    ModalCloseButton,
    ModalHeader,
    Label,
    InputContainer,
    PhoneInputContainer,
    SegmentTag,
    SegmentTagInput
} from "./UserInfo.styled";
import { InputText, RoundedButton, WarningModal } from '../../../../elements';
import XIcon from '../../../../elements/icon/assets/x_icon.svg';
import { format } from 'date-fns';

const EditUserInfoModal = observer(({ isOpen, onClose }) => {

    const { unifiedCustomerProfileStore } = useStore();

    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [dob, setDob] = useState();
    const [openSuccessModal, setOpenSuccessModal] = useState(false);

    useEffect(() => {
        if(unifiedCustomerProfileStore.initialLoad){
            setName(unifiedCustomerProfileStore.ucp.name)
            setPhone(unifiedCustomerProfileStore.ucp.phone_number)
            setDob(format(unifiedCustomerProfileStore.ucp.birth_date, 'yyyy-MM-dd'))
        }
    }, [unifiedCustomerProfileStore.initialLoad])


    const inputsWidth = '32em';
    const inputsHeight = '40px';

    return (
        <>
            <Backdrop isOpen={isOpen}>
                <ModalWrapper>
                    <ModalHeader>
                        <ModalTitle>Edit</ModalTitle>
                        <ModalCloseButton src={XIcon} width={15} height={15} onClick={onClose} />
                    </ModalHeader>
                    <ModalContainer>
                    
                    <InputContainer>
                        <Label>Name</Label>
                        <InputText 
                            width={inputsWidth} 
                            height={inputsHeight}
                            margin="0"
                            value={name} 
                            onChange={(e) => setName(e.target.value)}
                            displayError={!name} 
                            validationError="Your need a name before saving the changes."
                            backgroundColor="white"
                        />
                    </InputContainer>

                    <InputContainer>
                        <Label>Phone Number</Label>
                        <PhoneInputContainer>
                            <InputText
                                type="text"
                                value="+1"
                                placeholder="Phone"
                                width="4em"
                                height={inputsHeight}
                                margin="0"
                                disabled
                            />
                            <InputText 
                                width="27em" 
                                height={inputsHeight}
                                margin="0"
                                value={phone} 
                                onChange={(e) => setPhone(e.target.value)}
                                displayError={!phone} 
                                validationError="Your need a phone before saving the changes."
                                backgroundColor="white"
                            />
                        </PhoneInputContainer>
                    </InputContainer>

                    <InputContainer>
                        <Label>Email</Label>
                        <InputText 
                            width={inputsWidth} 
                            height={inputsHeight}
                            margin="0"
                            value={unifiedCustomerProfileStore.ucp.email} 
                            disabled
                        />
                    </InputContainer>

                    <InputContainer>
                        <Label>Date of Birth</Label>
                        <InputText 
                            width={inputsWidth} 
                            height={inputsHeight}
                            margin="0"
                            value={dob} 
                            onChange={(e) => setDob(e.target.value)}
                            displayError={!dob}
                            type="date"
                            backgroundColor="white"
                        />
                    </InputContainer>

                    <InputContainer>
                        <Label>Score</Label>
                        <InputText 
                            width={inputsWidth}
                            height={inputsHeight} 
                            margin="0"
                            value={unifiedCustomerProfileStore.ucp.score} 
                            disabled
                        />
                    </InputContainer>

                    <InputContainer>
                        <Label>Segment of Clients</Label>
                        <SegmentTagInput>
                            <SegmentTag>{unifiedCustomerProfileStore.ucp.client_segment}</SegmentTag>
                        </SegmentTagInput>
                    </InputContainer>

                    <InputContainer>
                        <Label>Warranty</Label>
                        <InputText 
                            width={inputsWidth} 
                            height={inputsHeight}
                            margin="0"
                            value="Warranty Expires" 
                            disabled
                        />
                    </InputContainer>

                    </ModalContainer>

                    <RoundedButton width="16em" margin="35px" onClick={() => {onClose();setOpenSuccessModal(true)}}>Save</RoundedButton>
                </ModalWrapper>
            </Backdrop>
            <WarningModal
              isOpen={openSuccessModal}
              onClose={() => setOpenSuccessModal(false)}
              title="Great!"
              subtitle="The customer has been successfully edited."
            />
        </>

    );
});

export default EditUserInfoModal;
