import React from "react";
import { Route, Routes } from "react-router-dom";
import ConversationView from "./ConversationView";
import EventName from "./EventName/EventName";
import Marketing from "./Marketing/Marketing";

const ConversationPage = () => {
  return (
    <Routes>
      <Route index element={<ConversationView />} />
      <Route path="/trigger" element={<EventName />} />
      <Route path="/marketing" element={<Marketing />} />
    </Routes>
  );
};

export default ConversationPage;
