import { observer } from "mobx-react";
import {
  ContactsSegmentWrapper,
  Counter,
  SpreadsheetContactsMappingContainer,
} from "./ContactsSegment.styled";
import RulesView from "./components/RulesView";
import SegmentTable from "./components/SegmentTable";
import { useStore } from "../../../hooks";
import SpreadsheetContactsMapping from "../../../pages/v2/BlastCampaigns/Tabs/SpreadsheetContactsMapping/SpreadsheetContactsMapping";
import { useState, useEffect } from "react";

const ContactsSegment = observer(({onFileUpload, showFile=false}) => {
  const { contactSegmentStore } = useStore();
  const [showContactMapping, setShowContactMapping] = useState(showFile);

  useEffect(() => {
      contactSegmentStore.getEventsCatalog();
  }, [])
  return (
    <>
      <Counter>
        <span>Contacts</span>
        {!showContactMapping && <span>{contactSegmentStore.totalRowCount}</span>}
      </Counter>
      <ContactsSegmentWrapper>
        {showContactMapping ? (
          <SpreadsheetContactsMappingContainer>
            <SpreadsheetContactsMapping showSendButton={true} onFileUpload={onFileUpload}/>
          </SpreadsheetContactsMappingContainer>
        ) : (
          <SegmentTable />
        )}
        <RulesView showContactMapping={showContactMapping} setShowContactMapping={setShowContactMapping} />
      </ContactsSegmentWrapper>
    </>
  );
});

export default ContactsSegment;
