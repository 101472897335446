import React, { memo } from 'react';

import UserAvatar from '../../../../../images/TreeAvatar.png'
import {
  NodeName,
  AgentNode,
  NodeTitle,
  NodeAvatar,
  AgentHeader,
  NodeIcon,
  NodeIcons
} from './GenericNode.styled'

export default ({ data }) => {
  return (
    <AgentNode>
      <NodeAvatar
        src={UserAvatar}
        sx={{ width: 34, height: 34 }}
      />
      <AgentHeader>
        <NodeName>{data.label}</NodeName>
        {data.title && <NodeTitle>{data.title}</NodeTitle>}
      </AgentHeader>
      <NodeIcons>
        { data.onEdit &&
          <NodeIcon
            icon="editPencil"
            width={"24px"}
            height={"24px"}
            onClick={data.onEdit}
          />
        }
        { data.onDelete && 
          <NodeIcon
            icon="deleteGray"
            width={"24px"}
            height={"24px"}
            onClick={data.onDelete}
          />
        }
      </NodeIcons>
    </AgentNode>
  );
};
