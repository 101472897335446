import styled, { createGlobalStyle } from "styled-components";

const RootHome = styled.div`
  display: flex;
  width: 100%;
`;

const HomeContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  height: calc(100svh - 48px);
  overflow-x: hidden;
  font-size: 0.75em;
  width: 100%;
  font-family: 'Inter', sans-serif;
`;

const MainArea = styled.div`
  display: inherit;
  padding: 2em;
  flex-direction: row;
  grid-column: 2 / 3;
  transition: grid-column 0.3s ease;
  max-height: calc(100svh - 76px);
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    padding: 1em;
    max-height: fit-content;
  }
`;

const BodyChatContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 30px 20px 20px 20px;
  flex: 1;

  @media (max-width: 768px) {
    padding: 10px;
    padding-bottom: 60px; // Add space for fixed footer
  }
`;

const ChatContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.clearGray};
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.tabs > div > div > div > div {
    /*color: ${(props) => props.theme.colors.clearGray};*/
    font-weight: bold;
  }
`;

const SearchContainer = styled.div`
  position: relative;
  width: 400px;
  display: flex;
  justify-content: center;
  z-index: 4;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.clearGray};
  background: ${(props) => props.theme.colors.white};
  height: 46px;
  padding-right: 5px;
`;

const InputField = styled.input`
  width: 100%;
  height: 35px;
  border: none;
  outline: none;
  padding: 0 10px;
  background: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.fonts.variant.regular()};

  ::placeholder {
    color: ${(props) => props.theme.colors.clearGray};
  }
`;

const SearchIcon = styled.div`
  display: block;
  margin: auto;
`;

const FooterChatContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  position: relative;
  background-color: ${(props) => props.theme.colors.white};

  &.footer-chat-container > div > div:first-child {
    display: none;
  }

  &.footer-chat-container > div > div:nth-child(2) {
    width: 100%;
  }

  &.footer-chat-container > div > div:nth-child(2) > div {
    background: ${(props) => props.theme.colors.primaryWhite};
    border-color: ${(props) => props.theme.colors.clearGray};
  }

  @media (max-width: 768px) {
    padding: 8px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 480px) {
    padding: 5px;
  }
`;

const MessageInputField = styled.input`
  width: 100%;
  height: 30px;
  outline: none;
  padding: 0 10px;
  border: none;
  background: ${(props) => props.theme.colors.silverGrayThree};
  ${(props) => props.theme.fonts.variant.regular()};

  ::placeholder {
    color: ${(props) => props.theme.colors.clearGray};
  }
`;

const ChatInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }

  @media (max-width: 480px) {
    padding: 3px;
    gap: 5px;
  }

  & > * {
    width: 100%;
  }

  & > div:first-child {
    flex: 1;
  }

  & > div:last-child {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    & > div:last-child {
      justify-content: center;
    }
  }


`;

const ChatConversationContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  height: auto;

  &.chat-conversation-container > div > div > div {
    background: none;
    font-weight: normal;
  }
`;

const ChatWindowWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100%;
`;

const Separator = styled.div`
  width: 60px;
  height: 60px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  margin-left: 8px;
  height: 45px;

  & > div:first-child {
    cursor: pointer;
  }

  & > div:last-child {
    cursor: pointer;
  }
`;

const InfoContainer = styled.div`
  width: auto;
  height: auto;
`;

const TimeContainer = styled.div`
  width: auto;
  height: auto;
`;

const WelcomeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: black;
  font-weight: normal;
`;

const ImageContainer = styled.div`
  width: 70px;
  height: auto;
  margin: 20px auto;
  display: flex;
  justify-content: center;
`;

const AgentWelcomeText = styled.div`
  margin-bottom: 10px;
`;

const AgentNameText = styled.div`
  margin-bottom: 10px;
  font-weight: bold !important;
`;

const ChatWindowComponentWrapper = styled.div`
  & #chat-window-component-wrapper > div > div {
    width: 100% !important;
  }
`;

const CenteredSpinner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const CustomGenericListStyle = createGlobalStyle`
  #main-content {
    overflow: hidden;
  }
`;

const ResponsiveMainArea = styled(MainArea)`
  @media (max-width: 1024px) {
    height: calc(100vh - 60px);
    overflow: hidden;
  }
`;

const ResponsiveChatContainer = styled(ChatContainer)`
  @media (max-width: 768px) {
    height: calc(100vh - 110px);
  }
`;

const ResponsiveBodyChatContainer = styled(BodyChatContainer)`
  @media (max-width: 768px) {
    padding: 0.5rem;
    height: calc(100% - 60px); // Adjust for footer height
  }
`;

const ResponsiveFooterChatContainer = styled(FooterChatContainer)`
  @media (max-width: 768px) {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0.5rem;
    background-color: ${(props) => props.theme.colors.white};
  }
`;

export {
  HomeContainer,
  MainArea,
  ChatContainer,
  InputWrapper,
  InputField,
  SearchIcon,
  SearchContainer,
  BodyChatContainer,
  FooterChatContainer,
  MessageInputField,
  ChatInputWrapper,
  ChatConversationContainer,
  ChatWindowWrapper,
  Separator,
  ButtonsContainer,
  InfoContainer,
  TimeContainer,
  WelcomeContainer,
  ImageContainer,
  AgentWelcomeText,
  AgentNameText,
  ChatWindowComponentWrapper,
  CenteredSpinner,
  CustomGenericListStyle,
  RootHome,
  ResponsiveMainArea,
  ResponsiveChatContainer,
  ResponsiveBodyChatContainer,
  ResponsiveFooterChatContainer,
};