import styled from "styled-components";
import searchIcon from "../../../../../elements/icon/assets/search_icon.svg";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: ${props => props.theme.colors.primaryWhite};
  border-radius: 12px;
  padding: 20px;
  width: 700px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
`;

export const CustomSelectWrapper = styled.div`
  position: relative;
  width: 100%;
  letter-spacing: 0;
`;

export const Dropdown = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.primaryWhite};
  margin-top: 10px;
  overflow: hidden;
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  margin: 10px;
  font-size: 16px;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 12px;
  padding-right: 40px;
  border: 1px solid ${props => props.theme.colors.clearGray};
  border-radius: 8px;
  font-size: 14px;
`;

export const SearchIcon = styled.span`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: url(${searchIcon}) no-repeat center;
  pointer-events: none;
`;

export const GroupListContainer = styled.div`
  padding: 10px;
  font-size: 14px;
`;

export const GroupList = styled.div`
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid ${props => props.theme.colors.clearGray};
  border-radius: 8px;
  padding: 5px 10px;
`;

export const GroupItem = styled.div`
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  
  &:hover {
    background-color: ${props => props.theme.colors.highClearGray};
  }

  & > span {
    margin-left: 8px;
  }
`;

export const SelectedGroups = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  max-height: 100px;
  overflow-y: auto;
`;

export const SelectedGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: ${props => props.theme.colors.primaryWhite};
  border: 1px solid ${props => props.theme.colors.clearGray};
  border-radius: 15px;
  font-size: 12px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & > span {
    margin-right: 5px;
  }

  & > button {
    border: none;
    background: none;
    cursor: pointer;
  }
`;

export const CloseIcon = styled.div`
  margin-left: 10px;
`;

export const LabelGroup = styled.div`
  margin: 15px 20px 10px;
  color: ${props => props.theme.colors.primaryGray};
  font-size: 14px;
`;

export const AddButton = styled.button`
  margin: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.primaryWhite};
  cursor: pointer;
  float: right;

  &:hover {
    background-color: ${props => props.theme.colors.primaryDarkBlue};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 25px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: ${props => props.theme.colors.clearGray};
`;