import React, { useMemo, useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../../hooks";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { DefaultTheme as theme } from "../../../../../theme";

const MembersTable = observer(({ searchValue }) => {
  const { listRoleStore } = useStore();
  const [filteredData, setFilteredData] = useState(listRoleStore.members);

  useEffect(() => {
    const filtered = listRoleStore.members.filter(member =>
      member.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
      member.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
      member.email.toLowerCase().includes(searchValue.toLowerCase()) ||
      member.memberSince.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchValue, listRoleStore.members]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "firstName",
        header: "First Name",
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
          sx: {
            border: 'none',
          },
        },
      },
      {
        accessorKey: "lastName",
        header: "Last Name",
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
          sx: {
            border: 'none',
          },
        },
      },
      {
        accessorKey: "email",
        header: "Email",
        Cell: ({ cell }) => (
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {cell.getValue()}
          </span>
        ),
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
          sx: {
            border: 'none',
          },
        },
      },
      {
        accessorKey: "memberSince",
        header: "Member Since",
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
          sx: {
            border: 'none',
          },
        },
        Cell: ({ cell }) => (
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {cell.getValue()}
          </span>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: filteredData,
    initialState: { isHidden: { countryCode: true } },
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    enableEditing: false,
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer',
        border: 'none',
      },
    }),
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: theme.colors.highClearGray,
        color: 'black',
        paddingTop: '8px',
        paddingBottom: '8px',
        border: 'none',
      },
    },
    muiTableHeadRowProps: {
      sx: {
        borderRadius: '8px',
        border: 'none',
      },
    },
    muiTableContainerProps: {
      sx: {
        border: 'none',
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none', 
        border: 'none',    
      },
    },
    enableGlobalFilter: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    state: {
      isLoading: listRoleStore.status === "loading",
    },
  });

  return (
      <MaterialReactTable table={table} />
  );
});

export default MembersTable;
