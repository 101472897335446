import StatusBadgeContainer from "./StatusBadge.styled";

const StatusBadge = ({ status }) => {
  return (
    <>
      <StatusBadgeContainer className={status.toLowerCase()}>{status}</StatusBadgeContainer>
    </>
  );
};

export default StatusBadge;
