import styled from "styled-components";
import RadioButton from '../../../../elements/v2/RadioButton/RadioButton'

export const AgentTypeModalContainer = styled.div`
    position: absolute;
    width: 279px;
    height: 172px;
    background: ${(props) => props.theme.colors.highClearGray};
    padding: 16px;
    border-radius: 8px;
    top: ${(props) => props.top}px;
    left: ${(props) => props.left}px;
`;

export const AgentTypeModalTitle = styled.div`
    ${(props) => props.theme.fonts.variant.semiBold};
    color: ${(props) => props.theme.colors.blackTwo};
    font-size: 18px;
`

export const AgentTypeRadioButton = styled(RadioButton)`
    background: ${(props) => props.theme.colors.silverGrayThree};
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 10px;
`

export const RadioButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
`

export const AgentModalHeader = styled.div`
    display: flex;
`;