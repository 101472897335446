import styled from "styled-components";
import { RoundedButton } from "../../../../elements/index.js";
import TextArea from "../../../../elements/v2/TextArea/TextArea.jsx";

export const ManagerModalContainer = styled.div`
    position: absolute;
    width: 576px;
    height: 505px;
    background: ${(props) => props.theme.colors.highClearGray};
    padding: 16px;
    border-radius: 8px;
    top: ${(props) => props.top-110}px;
    left: ${(props) => props.left}px;
    gap: 24px;
`;

export const InputTitle = styled.label`
    ${(props) => props.theme.fonts.variant.regular};
    font-size: ${props => props.theme.fonts.size.b2};
    color: ${(props) => props.theme.colors.black};
`;

export const ModalTitle = styled.div`
    ${(props) => props.theme.fonts.variant.semiBold};
    font-size: 24px;
    color: ${(props) => props.theme.colors.greyEleven};
`;

export const SaveButton = styled(RoundedButton)`
    width: 69px;
    height: 48px;
    ${(props) => props.theme.fonts.variant.regular};
`

export const SaveButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    position: absolute;
    bottom: 15px;
    right: 20px;
`;

export const DescriptionTextArea = styled(TextArea)`
    background: ${(props) => props.theme.colors.silverGrayThree};
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
    
`
