import { action, makeObservable, observable, flow } from "mobx";
import { API } from "aws-amplify";
import { putEmailBlast } from "../../graphql/queries";
import _ from "lodash";

class BlastCampaignStore {

    isLoading = false;
    isError = false;
    isSuccessful = false;
    emailTemplatePayload = [];
    emailTemplateBinding = [];
    id = "";
    customer = "";
    error = "";

	constructor() {
		makeObservable(this, {
            isLoading: observable,
            isError: observable,
            emailTemplatePayload: observable,
            emailTemplateBinding: observable,
            id: observable,
            customer: observable,
            error: observable,
            isSuccessful: observable,
            clear: action,
            setBlastCampaignAttribute: action,
            blast: flow,
		});
	}

    setBlastCampaignAttribute(attribute, value){
        this[attribute] = value;
    }

    *blast(){
        try {
            this.isLoading = true;

            yield API.graphql({
                query: putEmailBlast,
                variables: {
                  input: {
                    customer_id: this.customer,
                    email_setup: JSON.stringify({
                        binding: this.emailTemplateBinding,
                        payload: this.emailTemplatePayload,
                    }),
                    contacts: `${this.id}.json`
                  },
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            this.isSuccessful = true;
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
    }

    updateBindingsAndPayload(payload=[], bindings=[]){
		this.emailTemplatePayload = _.cloneDeep(payload)
		this.emailTemplateBinding = _.cloneDeep(bindings)
	}

    clear() {
        this.isLoading = false;
        this.isError = false;
        this.isSuccessful = false;
        this.emailTemplatePayload = [];
        this.emailTemplateBinding = [];
        this.error = "";
        this.id = "";
    }

}

export default BlastCampaignStore;