import styled, { css } from 'styled-components';

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1;

    ${(props) => props.isOpen && css`
        display: flex;
    `}
`;

const ModalWrapper = styled.div`
    position: relative;
    width: ${props => props.$width|| '440px'};
    height: ${props => props.$height || '300px'};
    background-color: white;
    border-radius: 10px;
`;

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    padding: 30px;
`;

const ModalTitle = styled.div`
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 20px;
`;

const ModalBody = styled.span`
    text-align: justify;
    font-size: 16px;
    margin-bottom: 0;
    overflow-y: scroll;
    max-height: ${props => props.$bodyMaxHeight || 'inherit'}
`;

const ModalFooterWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
`;

const ModalCloseButton = styled.img`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`;

const ModalTestAgentButton = styled.img`
    cursor: pointer;
`;

export {
    Backdrop,
    ModalWrapper,
    ModalContainer,
    ModalTitle,
    ModalBody,
    ModalFooterWrapper,
    ModalCloseButton,
    ModalTestAgentButton
};
