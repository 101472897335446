import LogoAnn from "../../../elements/icon/assets/logo_ann_customer.svg";

const getTeams = [
    {
        id: 1,
        image: LogoAnn,
        name: "HR Team",
        type: "Concierge Agent",
        createdby: "Jhon Williams",
        description1: "A chatbot can be integrated with existing time and project management tools in the company, allowing for greater efficiency in planning and executing daily tasks...",
        description2: "An HR team manages employees and ensures smooth operations. They handle recruitment, onboarding, employee relations, performance management, training, compensation, compliance, and employee engagement.",
        conversations: 4,
        members: 3,
        access: [
            {
                image: "",
                name: 'John Smith',
                role: 'User'
            },
            {
                image: "",
                name: 'Emily Johnson',
                role: 'User'
            },
            {
                image: "",
                name: 'David Williams',
                role: 'Admin'
            },
            {
                image: "",
                name: 'Michael Jones',
                role: 'Super Admin'
            }
        ],
        starters: [],
        knowledgeBase: [
            "Document1.pdf",
            "Document2.pdf",
            "Document3.pdf"
        ],
        visibility: "published",
        actions:  ['appointments_schedule',  'send_sms'],
        conversation_starters: [
            "How many conversations are there per Modules?",
            "Give me a proposal to organize the inventory.",
            "Create a report considering the following parameters.",
            "What strategies do you use to build customer loyalty and ensure repeat sales?",
            "Develop a strategy to achieve the following objectives. "
        ]
    },
    {
        id: 2,
        image: LogoAnn,
        name: "Sales Team",
        type: "",
        createdby: "Emily Brown",
        description1: "A chatbot can be integrated with existing time and project management tools in the company",
        description2: "A sales team sells products or services to customers, identifies leads, nurtures relationships, closes deals, and meets sales targets for the company.",
        conversations: 4,
        members: 3,
        access: [
            {
                image: "",
                name: 'John Smith',
                role: 'User'
            },
            {
                image: "",
                name: 'Emily Johnson',
                role: 'User'
            },
            {
                image: "",
                name: 'David Williams',
                role: 'Admin'
            },
            {
                image: "",
                name: 'Michael Jones',
                role: 'Super admin'
            }
        ],
        starters: [],
        knowledgeBase: [
            "Document1.pdf",
            "Document2.pdf",
            "Document3.pdf"
        ],
        visibility: "published",
        actions:  ['appointments_schedule',  'send_sms'],
        conversation_starters: [
            "How many conversations are there per Modules?",
            "Give me a proposal to organize the inventory.",
            "Create a report considering the following parameters.",
            "What strategies do you use to build customer loyalty and ensure repeat sales?",
            "Develop a strategy to achieve the following objectives. "
        ]
    },
    {
        id: 3,
        image: LogoAnn,
        name: "Services Team",
        type: "",
        createdby: "David Johnson",
        description1: "A chatbot can be integrated with existing time and project management tools in the company",
        description2: "A services team provides support and solutions to customers, addressing inquiries, resolving issues, and ensuring customer satisfaction for your organization.",
        conversations: 4,
        members: 3,
        access: [
            {
                image: "",
                name: 'John Smith',
                role: 'User'
            },
            {
                image: "",
                name: 'Emily Johnson',
                role: 'User'
            },
            {
                image: "",
                name: 'David Williams',
                role: 'Admin'
            },
            {
                image: "",
                name: 'Michael Jones',
                role: 'Super admin'
            }
        ],
        starters: [],
        knowledgeBase: [
            "Document1.pdf",
            "Document2.pdf",
            "Document3.pdf"
        ],
        visibility: "published",
        actions:  ['appointments_schedule',  'send_sms'],
        conversation_starters: [
            "How many conversations are there per Modules?",
            "Give me a proposal to organize the inventory.",
            "Create a report considering the following parameters.",
            "What strategies do you use to build customer loyalty and ensure repeat sales?",
            "Develop a strategy to achieve the following objectives. "
        ]
    },
    {
        id: 4,
        image: LogoAnn,
        name: "Draft HR Team",
        type: "Concierge Agent",
        createdby: "Jane Doe",
        description1: "This is a draft team example...",
        description2: "Draft team description goes here.",
        conversations: 4,
        members: 1,
        access: [
            {
                image: "",
                name: 'John Smith',
                role: 'User'
            },
            {
                image: "",
                name: 'Emily Johnson',
                role: 'User'
            },
            {
                image: "",
                name: 'David Williams',
                role: 'Admin'
            },
            {
                image: "",
                name: 'Michael Jones',
                role: 'Super admin'
            }
        ],
        starters: [],
        knowledgeBase: [
            "Document1.pdf",
            "Document2.pdf",
            "Document3.pdf"
        ],
        visibility: "draft",
        actions: ['appointments_schedule',  'send_sms'],
        conversation_starters: [
            "How many conversations are there per Modules?",
            "Give me a proposal to organize the inventory.",
            "Create a report considering the following parameters.",
            "What strategies do you use to build customer loyalty and ensure repeat sales?",
            "Develop a strategy to achieve the following objectives. "
        ]
    }
];

const getDummyDropdownItems = () => [
    {
        name: "Dummy 1",
        value: 1
    },
    {
        name: "Dummy 2",
        value: 2
    },
    {
        name: "Dummy 3",
        value: 3
    },
];

const dropdownConfigs = (items) => [
    {
        title: "By Agent type",
        items: items && items.length > 0 ? items : getDummyDropdownItems()
    },
    {
        title: "By Role",
        items: items && items.length > 0 ? items : getDummyDropdownItems()
    },
    {
        title: "By Actions",
        items: items && items.length > 0 ? items :  getDummyDropdownItems()
    }
];

const getDummyContentTabs = (name) => ({
    title: name || "Who has access?",
    names: ["User", "Admin", "Super Admin"]
})

export {
    getTeams,
    getDummyDropdownItems,
    dropdownConfigs,
    getDummyContentTabs
}