import styled from 'styled-components';

const NewUserWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 12vw 0 12vw;
`;

const FormUserWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const FormTitle = styled.p`
    font-weight: 400;
    font-size: ${(props) => props.fontSize || '28px'};
    font-style: italic;
    ${(props) => props.theme.fonts.variant.regular}
    margin-bottom: ${(props) => props.marginBottom || '0'};
`;

const FormSubtitle = styled.p`
    ${(props) => props.theme.fonts.variant.regular}
    color: ${(props) => props.theme.colors.grey};
    margin-bottom: ${(props) => props.marginBottom || '0'};
`;

const RadioButtonsContainer = styled.div`
    display: flex;
    gap: 50px;
    margin-top: ${(props) => props.mt || '50px'};
    margin-bottom: ${(props) => props.mb || '0'};
`;

const ModalSubtitle = ({children}) => <FormTitle fontSize="20px">{children}</FormTitle>;

const FormInputContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espacio entre los inputs */

    @media (min-width: 768px) {
        flex-direction: row;
    }
`;

export {
    NewUserWrapper,
    FormUserWrapper,
    FormTitle,
    FormSubtitle,
    FormInputContainer,
    RadioButtonsContainer
}