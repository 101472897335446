import React, { useState } from "react";
import { observer } from "mobx-react";
import { AgentsContainer, AgentsTitle, AgentsList, AgentItem, AgentImage } from "./AgentsTeam.styled";
import ModalAgentsDetail from "../../../../AsistantManagement/components/ModalAgentsDetail/ModalAgentsDetail";
import { useStore } from "../../../../../../hooks";
import ava from '../../../../../../images/Ava.png';


const AgentsComponent = observer(() => {
  const { agentTreeStore } = useStore();

  if (!agentTreeStore) {
    return <div>Error: agentTreeStore is undefined</div>;
  }

  const { agents } = agentTreeStore;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (agent) => {
    agentTreeStore.setSelectedAgent(agent);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <AgentsContainer>
      <AgentsTitle>Agents</AgentsTitle>
      <AgentsList>
        {agents.map((agent) => (
          <AgentItem key={agent.id} onClick={() => handleOpenModal(agent)}>
            <AgentImage src={agent.image || ava} alt={agent.name} />
          </AgentItem>
        ))}
      </AgentsList>
      {isModalOpen && (
        <ModalAgentsDetail open={isModalOpen} onClose={handleCloseModal} store={agentTreeStore} width="600px" showGearIcon={true}/>
      )}
    </AgentsContainer>
  );
});

export default AgentsComponent;
