import React, { useEffect, useRef } from 'react';
import { observer } from "mobx-react";
import dayjs from "dayjs";
import Markdown from "react-markdown";
import {
  MessageWrapper,
  MessageContainer,
  ChatWindowAvatar,
  SpinnerImage,
} from "../../../../Chatbox/styles/ChatWindowComponent.styled";
import {
  TimeLineMessagesWrapper,
} from "./Assistance.styled";
import Message from "../Assistance/Message";
import Spinner from "../../../../../elements/icon/assets/spinners_chat.svg";
import AnnAvatar from "../../../../../images/AnnAvatar.svg";
import { useStore } from "../../../../../hooks";

const groupMessagesByDate = (messages) => {
  const groupedMessages = {};
  messages.forEach(message => {
    const date = dayjs(message.insert_date).format("YYYY-MM-DD");
    if (!groupedMessages[date]) {
      groupedMessages[date] = [];
    }
    groupedMessages[date].push(message);
  });
  return groupedMessages;
};

const TimelineBox = observer(() => {
  const { assistanceUCPStore } = useStore();
  const {
    timelineMessages,
    loading,
    lastEvaluatedKey,
    loadMoreData,
    setScrollPosition,
    setContainerDimensions,
    updateScroll
  } = assistanceUCPStore;

  const loader = useRef(null);
  const iobserver = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    loadMoreData();
  }, []);
  
  // Set up the Intersection Observer to detect when the user reaches the top of the list
  useEffect(() => {
    // Define the options for the Intersection Observer
    const options = {
      root: null, //  This is the default value. It means that the viewport is used as the root.
      rootMargin: '20px', // This creates a virtual margin around the viewport area. In this case, it extends the detection area 20px beyond the viewport, which may cause the load to trigger a little before the element reaches the edge of the viewport.
      threshold: 1.0, // This means that the callback is triggered when 100% of the observed element is visible.
    };
    
    // Creates a new instance of Intersection Observer
    iobserver.current = new IntersectionObserver(handleObserver, options);
  
    // If the reference element 'loader' exists, it starts observing it.
    if (loader.current) {
      iobserver.current.observe(loader.current);
    }
    
    // Cleaning function: disconnects the observer when the component is disassembled
    return () => iobserver.current.disconnect();
  }, [lastEvaluatedKey]);// Runs when lastEvaluatedKey changes

  useEffect(() => {
    // If the containerRef has a current value (i.e., the DOM element exists)
    if (containerRef.current) {
      // Calculate the client height and scroll height of the container element
      // and set them in the store using the setContainerDimensions function
      setContainerDimensions(
        containerRef.current.clientHeight,
        containerRef.current.scrollHeight
      );

      // Call the updateScroll function in the store, passing the container element
      // This will update the scroll position in the store
      updateScroll(containerRef.current);
    }
  }, [timelineMessages]);

  /**
   * This function is the callback for the IntersectionObserver. It is triggered
   * when the observed element (loader) intersects with the root element (null,
   * meaning the viewport). It checks if the element is fully visible and if the
   * loading flag is false, and if the last evaluated key is not zero. If all
   * conditions are met, it calls the loadMoreData function to load more messages.
   * If the last evaluated key is zero, it disconnects the observer because there
   * are no more messages available.
   * @param {IntersectionObserverEntry[]} entities - An array of IntersectionObserverEntry objects
   */
  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && !loading && lastEvaluatedKey !== 0) {
      loadMoreData();
    } else if (lastEvaluatedKey === 0) {
      iobserver.current.disconnect();
    }
  };

  /**
   * This function is the callback for the 'onScroll' event of the container element.
   * It updates the scroll position in the store by calculating the difference between
   * the scroll height and the current scroll top of the container element. This value
   * is used to determine when to load more messages.
   */
  const handleScroll = () => {
    
    if (containerRef.current) {
      setScrollPosition(
        containerRef.current.scrollHeight - containerRef.current.scrollTop
      );
    }
  };

  const groupedMessages = groupMessagesByDate(timelineMessages);

  return (
    <div
      ref={containerRef}
      onScroll={handleScroll}
      style={{ overflowY: 'scroll', height: '100vh' }}
    >
      <div ref={loader}>
        {loading && (
          <MessageContainer>
            <ChatWindowAvatar src={AnnAvatar} alt="Agent Avatar" />
            <MessageWrapper flex>
              <Markdown>Loading messages...</Markdown>
              <SpinnerImage src={Spinner} alt="Spinner" />
            </MessageWrapper>
          </MessageContainer>
        )}
      </div>
      {Object.keys(groupedMessages).reverse().map((date, index) => (
        <div key={index}>
          <TimeLineMessagesWrapper>
            <span>{dayjs(date).format("DD/MM/YYYY")}</span>
          </TimeLineMessagesWrapper>
          {groupedMessages[date].map((item) => (
            <Message
              messageItem={{
                ...item,
                type: item.sender === "asst_zO5YYeUQOJPrlHZOiAyoEXhG" ? "received" : "sent",
                time: dayjs(item.insert_date).format("HH:mm"),
              }}
              key={item.id}
              id={item.id}
              displayButtons={false}
              width="100%"
              className="message-item"
            />
          ))}
        </div>
      ))}
    </div>
  );
});

export default TimelineBox;
