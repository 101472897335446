import React, {useCallback} from "react";
import {observer} from "mobx-react";
import {InputText} from "../../../elements";
import {useStore} from "../../../hooks";
import { FormTitle, FormSubtitle, FormAgentWrapper } from "../NewAgentPage.styled"

const AgentJobTitleForm = observer(() => {
   const { newAgent } = useStore();

    const onHandleChangeJobTitle = useCallback((e) => {
        newAgent.onChangeJobTitle(e.target.value);
    }, [newAgent]);
    return (
        <FormAgentWrapper>
            <FormTitle>Well done!<br/> That sounds like a fantastic choice.</FormTitle>
            <FormSubtitle>What title would you like to assign to your Agent? <br/> This will help define its role or the specific function it serves</FormSubtitle>
            <InputText
                type="text"
                value={newAgent.jobTitle}
                placeholder="Sales, Services, Marketing..."
                onChange={onHandleChangeJobTitle}
                width="380px"
                margin="80px 0 40px 0"
            />
        </FormAgentWrapper>
    );
});

export default AgentJobTitleForm;
