import styled from 'styled-components';

export const CardWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  padding: 16px;
  margin-bottom: 16px;
  margin-top: 10px;
  border-left: none;
  border-right: none;
}
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

export const DateRow = styled(CardRow)`
  justify-content: space-between;
`;

export const DateColumn = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
`;

export const CardLabel = styled.div`
  font-weight: bold;
  margin-right: 5px;
  white-space: nowrap;
`;

export const CardValue = styled.div`
  flex: 1;
  margin-left: 5px;
  word-wrap: break-word;
  max-width: calc(100% - 60px);
`;

export const DescriptionValue = styled(CardValue)`
  text-align: justify;
`;
