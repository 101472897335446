import styled from 'styled-components';

const MainContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${props => props.theme.colors.greyTen};
    padding: 0px 80px;
    border: 1px solid ${props => props.theme.colors.borderGray};
    overflow: hidden;
`;

const ThreeColumnGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding: 0 4%;
    width: 100%;
`;

const UserColumnContainer = styled.div`
    display: flex;
    gap: 20px;
`;

const UserColumn = styled.div`
    flex: 1;
`;

const MainColumn = styled.div`
    flex: 13;
`;

const SideColumn = styled.div`
    flex: 1;
    margin-top: 30px;
    margin-right: 50px;
`;

const TabsContainer = styled.div`
    max-width: 95%;
    display: flex;
    justify-content: flex-start;
    margin: 20px 10px 0px 80px;
`;

// Header/Breadcrumb
const BreadCrumbContainer = styled.div`
    display: flex;
    flex: 1;
`;

const MenuContainer = styled.div`
    display: flex;
    gap: 10px;
`;

const Title = styled.h3`
    margin: 0;
`;

const TitleName = styled.h3`
    margin: 0;
    color: #2173FF;
    margin-left: 5px;
`;

// Input and Search Bar
const CustomSearchInput = styled.div`
    display: flex;
    align-items: center;
    height: 47px;
    border-radius: 10px;
    opacity: 0.06px;
    angle: -90deg;
    background-color: ${props => props.theme.colors.primaryWhite};
    border: 1px solid ${props => props.theme.colors.borderGray};
`;

const InputField = styled.input`
    flex: 1;
    height: 100%;
    padding-left: 10px;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 16px;
`;

const SearchIcon = styled.div`
    width: 20px;
    height: auto;
    margin-right: 10px;
    margin-left: 10px;
`;

const StyledIconButton = styled.div`
    background-color: ${props => props.theme.colors.primaryWhite};
    border: 1px solid ${props => props.theme.colors.borderGray};
    color: ${props => props.theme.colors.borderGray};
    padding: 8px;
    cursor: pointer;
    border-radius: 7px;
    display: flex;
    width: 47px;
    height: 47px;
    justify-content: center;
    align-items: center;
`;

const CustomGrid = styled.div`
    display: flex;
    gap: 10px;
    width: 96%;
    justify-content: right;
    margin-top: 10px;
`;

const DividerLine = styled.div`
    width: 95%;
    height: 1px;
    background-color: ${props => props.theme.colors.lighterGray};
    margin: 3%;
`;

const DividerLine1 = styled.div`
    width: 95%;
    height: 1px;
    background-color: ${props => props.theme.colors.lighterGray};
    margin: 0 0 3% 0;
`;

const VerticalDividerline = styled.div`
    width: 1px;
    height: 40px;
    background-color:  ${props => props.theme.colors.silverGrayTwo};
    margin: 0 10px;
`;

const CustomComponent1 = styled.div`
    position: relative;
    display: flex;
    justify-content: start;
    min-width: 400px; 
    background-color: ${props => props.theme.colors.primaryWhite};
    padding: 10px;
    cursor: pointer;
`;

const InfoBoxWrapper = styled.div`
    margin-left: 10px;
    min-height: 100px;
    align-items: flex-start;
`;

const TruncatedDescription = styled.p`
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    max-height: 60px;
    margin: 3px 0;
`;

const CustomImage = styled.img`
    width: 100px;
    height: auto;
`;

const LoadMoreContainer = styled.div`
    text-align: -webkit-center;
    margin: 20px 0;
    display: flex;
    justify-content: center;
`;

const AgentsContainer = styled.div`
    margin-bottom: 4%
`;

const AgentViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top : 50px;
`;

const TabContainer = styled.div`
    margin-top: -50px; 
    padding: 0px 5%;
`;

const HiddenContainer = styled.div`
  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
`;

// Exports
export {
    MainContainer,
    ThreeColumnGrid,
    UserColumnContainer,
    UserColumn,
    MainColumn,
    SideColumn,
    TabsContainer,
    BreadCrumbContainer,
    MenuContainer,
    Title,
    TitleName,
    CustomSearchInput,
    InputField,
    SearchIcon,
    StyledIconButton,
    CustomGrid,
    DividerLine,
    DividerLine1,
    VerticalDividerline,
    CustomComponent1,
    InfoBoxWrapper,
    TruncatedDescription,
    CustomImage,
    LoadMoreContainer,
    AgentsContainer,
    AgentViewContainer,
    TabContainer,
    HiddenContainer
};
