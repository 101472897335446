import { IconButton, RoundedButton } from '../../button';
import { useCallback, useEffect, useState } from 'react';
import { 
  ModalWrapper,
  ModalBox,
  CloseButtonWrapper,
  ModalActionsWrapper,
  ModalContentWrapper
} from './Modals.styled';
import {
  IconWrapper,
  Title,
  Message
} from './ConfirmationModal.styled';
import { Icon } from '../../icon';

const ConfirmationModal = ({ isOpen, icon, onClose, onConfirm, title, message, displayCancel=true, onConfirmLabel='Continue', cancelLabel='Cancel', onCancel }) => {
  
  const [ open, setOpen ] = useState(isOpen);

  const onHandleClose = useCallback(() => {
    setOpen(false);
    onClose && onClose();
  },[onClose]);

  const onHandleCancel = useCallback(() => {
    setOpen(false);
    onCancel && onCancel();
  }, [onCancel]);

  const onHandleConfirm = useCallback(() => {
    setOpen(false);
    onConfirm && onConfirm();
  }, [onConfirm]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <ModalWrapper isOpen={open}>
      <ModalBox width="355px" height="305px">
        <CloseButtonWrapper>
          <IconButton icon="close" width="16px" height="16px" onClick={onHandleCancel} />
        </CloseButtonWrapper>
        <ModalContentWrapper>
          <IconWrapper><Icon name={icon} width="24px" height="24px" /></IconWrapper>
          <Title>{title}</Title>
          <Message>{message}</Message>
        </ModalContentWrapper>
        <ModalActionsWrapper>
          {displayCancel && <RoundedButton outline={true} onClick={onHandleClose}>{cancelLabel}</RoundedButton>}
          <RoundedButton onClick={onHandleConfirm}>{onConfirmLabel}</RoundedButton>
        </ModalActionsWrapper>
      </ModalBox>
    </ModalWrapper>
  );
};

export default ConfirmationModal;
