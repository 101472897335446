import React from 'react';
import {
    Backdrop,
    ModalWrapper,
    ModalBody,
    ModalContainer,
    ModalTitle,
    ModalCloseButton,
} from "./GenericTextModal.styled";
import XIcon from '../../elements/icon/assets/x_icon.svg';

const GenericTextModal = ({ isOpen, onClose, title, body, height, width, bodyMaxHeight }) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <Backdrop isOpen={isOpen}>
            <ModalWrapper $height={height} $width={width}>
                <ModalCloseButton src={XIcon} width={15} height={15} onClick={() => handleClose()} />
                <ModalContainer>
                    <ModalTitle>{title}</ModalTitle>
                    <ModalBody $bodyMaxHeight={bodyMaxHeight}>{body}</ModalBody>
                </ModalContainer>
            </ModalWrapper>
        </Backdrop>
    );
};

export default GenericTextModal;
