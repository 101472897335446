import styled from 'styled-components';

const Wrapper = styled.div`
    position: relative;
`;

const CustomTextArea = styled.textarea`
    resize: none;
    padding: 5px 10px;
    border-radius: 10px;
    width: ${props => props.$width};
    &:focus {
        outline: none;
    }
`

const IconWrapper = styled.div`
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: ${props => props.theme.colors.grey};
    cursor: pointer;
`;

const ExpandIcon = styled.img`
    cursor: pointer;
    width: 1.0vw;
    height: 1.0vw;
    margin-right: 12px;
`;


const Label = styled.div`
    font-size: 9px;
    color: ${props => props.theme.colors.grey};
    font-weight: 500;
    text-align: justify;
    background-color: ${props => props.theme.colors.greyFive};
    width: 28vw;
    padding: 8px;
    border-radius: 10px;
    position: absolute;
    top: -94px;
    max-height: 90px;
    opacity:0;
    animation: smooth-appear 1s ease forwards;

    @keyframes smooth-appear {
        to{
          opacity:1;
        }
    }

`;

const InstructionsSuggestionsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 60px;
    grid-row-gap: 0px;
    width: ${props => props.$width};
`;

const Suggestion = styled.div`
    height: 50px;
    background: ${props => props.theme.colors.white};
    border-radius: 10px;
    border: 1px ${props => props.theme.colors.black} solid;
    margin: 5px;
    text-align: center;
    font-size: 0.7vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 16px;
    cursor: pointer;
    transition: background-color 0.5s;
    color: ${props => props.theme.colors.greyTwo};

    &:hover {
        background-color: ${props => props.theme.colors.lightGrey};
    }
`;

    const ExpandIconContent = styled.div`
        position: absolute;
        bottom: 5px;
        right: 5px;
    `;

    const CustomContent = styled.div`
        position: relative; 
        width: 28vw;
    `;

    const StyledButton = styled.button`
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        text-align: center;
        color: #2173FF;
        background: ${props => props.theme.colors.white};
        border: none;
        cursor: pointer;
        display: inline-block;
        border-radius: 4px;
        text-decoration: none;
    `;


export {
    CustomTextArea,
    Wrapper,
    Label,
    InstructionsSuggestionsContainer,
    Suggestion,
    IconWrapper,
    ExpandIcon,
    ExpandIconContent,
    CustomContent,
    StyledButton
}