import React, {useEffect, useState} from 'react'
import Tabs from "../../../elements/v2/Tabs/Tabs";
import { RoundedButton, SuccessfulModal } from '../../../elements';
import {useStore} from "../../../hooks";
import {MainContainer, TabsContainer, BreadCrumbContainer, MenuContainer, Title, ButtonsContainer, EmailTemplateWrapper} from "./BlastCampaignConfiguration.styled"
import { observer } from 'mobx-react';
import Spinner from '../../../components/common/Spinner';
import SpreadsheetContactsMapping from './Tabs/SpreadsheetContactsMapping/SpreadsheetContactsMapping';
import TemplateEditor from '../../../elements/v2/TemplateEditor/TemplateEditor';
import {v4 as uuidv4} from 'uuid';

const BlastCampaignConfiguration = observer(() => {
    const { changeTitle, authStore, blastCampaignStore, templateEditorStore, contactSegmentStore } = useStore();
    changeTitle(null);
    const [exportTemplate, setExportTemplate] = useState();

    const reloadBlastCampaign = () => {
        templateEditorStore.clearTemplate();
        blastCampaignStore.clear();
        contactSegmentStore.clear();

        //Setting the same uuid for the files that eventually will be uploaded to S3 so they have the same name.
        const id = uuidv4();
        templateEditorStore.setId(id);
        contactSegmentStore.setFileId(id);
        blastCampaignStore.setBlastCampaignAttribute('id', id);

        templateEditorStore.updateGeneralField('customer', authStore.selectedAccount.id);
        blastCampaignStore.setBlastCampaignAttribute('customer', authStore.selectedAccount.id);
        contactSegmentStore.init( authStore.selectedAccount.id);
        
        templateEditorStore.newVariable("Email", "Email", "FIXED", "hello@example.com")
        templateEditorStore.newVariable("To", "To", "EVENT", "email", "contacts", "email")
        templateEditorStore.updateGeneralField("from", "{{Email}}")
        templateEditorStore.updateGeneralField("to", "{{To}}")
    }

    useEffect(() => {
        reloadBlastCampaign();
    }, [])

    useEffect(() => {
        if(blastCampaignStore.isSuccessful){
            reloadBlastCampaign();
            blastCampaignStore.setBlastCampaignAttribute('isSuccessful', true)
        }
    }, [blastCampaignStore.isSuccessful])

    useEffect(() => {
		templateEditorStore.updateGeneralField('sourceList', contactSegmentStore.emailTemplateEventSourceList)
	}, [contactSegmentStore.emailTemplateEventSourceList[0].fields.length])

    const onExportEditor = async () => {
		blastCampaignStore.updateBindingsAndPayload(templateEditorStore.payload.payload, templateEditorStore.payload.binding)
        await contactSegmentStore.uploadFileToS3()
        if(!contactSegmentStore.error) blastCampaignStore.blast()
	}

    const tabs = [
        {
            label: 'Contacts',
            content: (
                <SpreadsheetContactsMapping/>
            ),
        },
        {
            label: 'Email Template',
            content: (
                <EmailTemplateWrapper>
                    <TemplateEditor isPopup={false} onExport={onExportEditor} exportAction={setExportTemplate}/>
                </EmailTemplateWrapper>
            )
        }
    ];

    const clearError = () => {
        blastCampaignStore.setBlastCampaignAttribute('error', '');
        blastCampaignStore.setBlastCampaignAttribute('isError', false);
        templateEditorStore.onClearError()
    }

    return (
        <>
            <MainContainer>
                <BreadCrumbContainer>
                    <Title>Blast Campaign</Title>
                </BreadCrumbContainer>
                <MenuContainer>
                    <ButtonsContainer>
                    <RoundedButton height="20px" width="79px" onClick={() => exportTemplate()} disabled={blastCampaignStore.isLoading || templateEditorStore.isLoading || contactSegmentStore.isLoading}>Blast</RoundedButton>
                    </ButtonsContainer>
                </MenuContainer>
            </MainContainer>
            <TabsContainer>
                {!blastCampaignStore.isLoading && !templateEditorStore.updatingTemplate ? <Tabs
                    tabs={tabs}
                /> : <Spinner/>}
            </TabsContainer>
            <SuccessfulModal
                isOpen={!!blastCampaignStore.error || (blastCampaignStore.isError || templateEditorStore.isError)}
                onClose={() => clearError()}
                title="Oops!"
                subtitle="An error occurred."
                subtitle2={blastCampaignStore.error || "Campaign couldn't be set up, please try again."}
                height='300px'
                width='500px'
                zIndex={true}
            />
            <SuccessfulModal
                isOpen={blastCampaignStore.isSuccessful}
                onClose={() => blastCampaignStore.setBlastCampaignAttribute('isSuccessful', false)}
                title="Success!"
                subtitle="Campaign has been sent successfully."
                height='200px'
                width='500px'
                zIndex={true}
            />
        </>
    )
})

export default BlastCampaignConfiguration
