import styled, { css } from "styled-components"
import { DefaultTheme as theme } from "../../../theme";

const BellContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${(props) => props.isActive ? theme.colors.activeBlue : "transparent"};
    border-radius: 50%;
    height: 32px;
    width: 32px;
`;

const VisualSignaling = styled.div`
    position: absolute;
    right: 3px;
    height: 9px;
    width: 9px;
    background-color: red;
    border-radius: 50%;
`

const ContentContainer = styled.div`
    position: relative;
    width: 100%;
`;

const ContentBell = styled.div`
    display: grid;
    grid-template-columns: 40% auto;
    padding-inline: 10px;
    padding-block: 10px;
`;

const RightFunctions = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    gap: 5px;
    & span {
        color: ${theme.colors.activeBlue}
    }
`;

const NotificationItemContainer = styled.div`
    max-height: 760px;
    overflow: scroll;
`

const NotificationItem = styled.div`
    font-family: 'Inter';
    opacity: 0.7;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
    display: grid;
    grid-template-columns: 10% auto;
    padding-inline: 5px;
    padding-block: 7px;
    background-color: ${(props) => props.isUnread ? "#F2F7FF" : "transparent"};
    ${(props) => !props.isUnread && css`border-bottom: 1px solid gray`};
    width: 98%;
    margin-inline: 5px;
    margin-block-end: 5px;
    ${(props) => props.isUnread && css`border-radius: 8px`};
    min-height: 60px;
    gap: 8px;
    & .photo {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    & .description {
        display: flex;
        flex-direction: column;
        color: ${theme.colors.darkGray};
        & .name {
            text-align: left;
            font-weight: bold;
        }
        & .eventDescription {
            font-weight: bold;
            font-size: 12px;
        }
    }
    & .timeAgo {
        font-size: 10px;
    }
`
const NotificationFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 31px;
    font-family: 'Inter';
    color: ${theme.colors.clearBlue};
    cursor: pointer;
    margin-block-end: 5px;
    font-size: 12px;
`

export {
    NotificationItemContainer,
    NotificationItem,
    RightFunctions,
    ContentContainer,
    BellContainer,
    ContentBell,
    NotificationFooter,
    VisualSignaling,
}