import styled from 'styled-components';

const UserContent = styled.div`
    height: 100vh;
`;

const UserWrapper = styled.div`
    display: flex;
    margin-top: 20px
`;

const UserDetails = styled.div`
    flex: 1;
    margin-right: 20px;
`;

const UserActions = styled.div`
    flex: 1;
`;

const UserLabel = styled.label`
    color: #717171;
`;

const UserColumnContainer = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  span {
    flex: 1;
  }

`;

const UserColumn = styled.div`
  margin-top: 20px;
`;

const UserPhone = styled.div`
    flex: 1;
`;

export {
    UserContent,
    UserWrapper,
    UserDetails,
    UserActions,
    UserLabel,
    UserColumnContainer,
    UserColumn,
    UserPhone
};
