import styled from "styled-components";

const StatusBadgeContainer = styled.div`
    display: flex;
    user-select: none;
    justify-content: center;
    align-items: center;
    width: 100px;
    min-height: 21px;
    text-transform: capitalize;
    line-height: 14.52px;
    font-size: 12px;
    font-family: 'Inter';
    
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.blueOne};
    border-radius: 4px;
    
    &.active {
        background-color: ${(props) => props.theme.colors.greenPrimary};
    }

    &.finished {
        opacity: 0.5;
        background-color: ${(props) => props.theme.colors.greenPrimary};
    }

    &.draft {
        opacity: 0.5;
        background-color: ${(props) => props.theme.colors.clearGray};
        color: ${(props) => props.theme.colors.darkGray}
    }

    &.paused {
        background-color: ${(props) => props.theme.colors.blueOne};
        opacity: 0.5;
    }

    &.standby {
        background-color: ${(props) => props.theme.colors.blueOne};
    }

    &.cancelled {
        background-color: ${(props) => props.theme.colors.red};
    }
`;

export default StatusBadgeContainer;
