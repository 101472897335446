export const OperatorsEnum = {
    EQ: 'Equal',
    NEQ: 'Not Equal',
    CONTAINS: 'Contains',
    STARTS_WITH: 'Starts With',
    ENDS_WITH: 'Ends With',
    IN: 'In',
    NOT_IN: 'Not In',
    BETWEEN: 'Between',
    LT: 'Less Than',
    GT: 'Greater Than',
    LTE: 'Less Than or Equal',
    GTE: 'Greater Than or Equal',
}