import styled from 'styled-components';

const TitleView = styled.div`
    flex: 1;
`;

const ActionsView = styled.div`
    display: flex;
    align-items: center;
    gap: 16px
`;

const Separator = styled.div`
    width: 2px;
    height: 48px;
    background-color: ${(props) => props.theme.colors.clearGray};
`;

export {
  TitleView,
  ActionsView,
  Separator
};
