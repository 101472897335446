import React, {useEffect, useState} from 'react';
import CustomizedHomeChatTabs from "../components/CustomizedHomeChatTabs";
import ChatWindowComponent from "../../../Chatbox/components/ChatWindowComponent";
import ChatQuestionsSection from "../components/ChatQuestionsSection";
import { Icon } from "../../../../elements";
import { observer } from "mobx-react";
import { useStore } from "../../../../hooks";
import {
    ChatConversationContainer,
    ChatWindowWrapper,
    WelcomeContainer,
    ImageContainer,
    AgentWelcomeText,
    AgentNameText,
    CenteredSpinner
} from '../HomePage.styled';
import Spinner from '../../../../components/common/Spinner';

const ChatTabs = observer(({selectedAgent, messages, agentIsTyping, handleSelectQuestion,
                               activeTab, setActiveTab, handleInfoTimeButtonClick, isSidebarDetailsHistoryExpanded,
                               SidebarDetailsHistoryActiveTab}) => {
    const {historyAgentStore, authStore} = useStore();
    const isChatQuestionsVisible = historyAgentStore.isChatQuestionsVisible;
    const bottomRef = historyAgentStore.bottomRef;
    const conciergeAgent = historyAgentStore.conciergeAgent;
    const agentQuestions = historyAgentStore.agentQuestions;
    const selectedAgentName = selectedAgent.name;


    useEffect(() => {
        if(historyAgentStore.conversations.length){
            const initialConversationID = historyAgentStore.conversations?.[0].id;
            historyAgentStore.fetchMessages(initialConversationID)
            historyAgentStore.setLastConversationID(initialConversationID)
            historyAgentStore.setIsChatQuestionsVisible(false)
        } else {
            historyAgentStore.setLastConversationID('')
            historyAgentStore.setIsChatQuestionsVisible(true)
        }
    }, [historyAgentStore.conversations])

    const tabs = [
        {
            label: "Chat",
            content: (
                <>
                    <ChatConversationContainer className="chat-conversation-container">
                        <ChatWindowWrapper>
                            {selectedAgentName && isChatQuestionsVisible && (
                                <WelcomeContainer>
                                    <ImageContainer>
                                        <Icon name="userAuthLogo" height="50px" width="50px" />
                                    </ImageContainer>
                                    <AgentNameText>{selectedAgentName}</AgentNameText>
                                    <AgentWelcomeText>
                                        {`Hello! I'm ${selectedAgentName}, feel free to type a message or select a suggestion to begin our chat. 
                            I'll use information from our organization to provide you with responses.`}
                                    </AgentWelcomeText>
                                </WelcomeContainer>
                            )}

                            {!isChatQuestionsVisible && (
                                <ChatWindowComponent
                                    selectedAgent={selectedAgent}
                                    messages={messages}
                                    bottomRef={bottomRef}
                                    height="60vh"
                                    width="100%"
                                    agentIsTyping={agentIsTyping}
                                />
                            )}
                        </ChatWindowWrapper>

                        {isChatQuestionsVisible && (
                            <ChatQuestionsSection
                                onSelectQuestion={handleSelectQuestion}
                                isLoading={!conciergeAgent}
                                loadedQuestions={agentQuestions}
                                selectedAgentName={selectedAgentName}
                            />
                        )}
                    </ChatConversationContainer>
                </>
            ),
        },
    ];

    return (
        <>{!historyAgentStore.isLoading ? (<CustomizedHomeChatTabs
            tabs={tabs}
            onToggleTab={setActiveTab}
            activeTab={activeTab}
            handleInfoTimeButtonClick={handleInfoTimeButtonClick}
            isSidebarDetailsHistoryExpanded={isSidebarDetailsHistoryExpanded}
            sidebarDetailsHistoryActiveTab={SidebarDetailsHistoryActiveTab}
            searchActive={true}
        />) : <CenteredSpinner><Spinner className="spinner" /></CenteredSpinner>}</>
    );
});

export default ChatTabs;