import React from 'react';
import {
	CardContainer,
	CardContainerFileLogo,
	CardContainerInfo,
	CardContainerInfoTitle,
	CardContainerInfoDescription
} from "./SidebarKnowledgeBaseCard.styled";
import { Icon } from "../../../../elements";


const extractFileNameAndExtension = (path) => {
	const parts = path.split('/').pop().split('.');
	const extension = parts.pop();
	const fileName = parts.join('.');
	return { fileName, extension };
};

const logo = {
	docx: <Icon name="wordLogo" />,
	xlsx: <Icon name="excelLogo" />
}

const SidebarKnowledgeBaseCard = ({ knowledgeBase }) => {
	return (
		<>
			{knowledgeBase && knowledgeBase.map((item, index) => {
				const { fileName, extension } = extractFileNameAndExtension(item);
				const fileLogo = logo[extension] || null;

				return (
					<CardContainer key={item}>
						<CardContainerFileLogo>{fileLogo}</CardContainerFileLogo>
						<CardContainerInfo>
							<CardContainerInfoTitle>{fileName}</CardContainerInfoTitle>
							<CardContainerInfoDescription>
								{/*This file is important; it contains a list of<br/>important information*/}
							</CardContainerInfoDescription>
						</CardContainerInfo>
					</CardContainer>
				);
			})}
		</>
	);
}

export default SidebarKnowledgeBaseCard;