import React, { useState, useEffect, useMemo } from "react";
import { DropDownContainer, DropDownHeader, DropDownList, ListItem, ListInput, DropDownBody } from "./ActionSelect.styled";
import ArrowDownIcon from "../../../../../../elements/icon/assets/arrow_down_icon.svg";

const ActionSelect = ({ placeholder = 'Choose Action', options = [], handleAction, textTransform, hideOnBlur = true, searchBar = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = option => () => {
    setIsOpen(false);
    handleAction(option.name, option.value);
  };

  const handleBlur = () => { 
    hideOnBlur && setIsOpen(false);
  };

  const filteredOptions = useMemo(() => 
    options.filter(option => new RegExp(searchTerm, "i").test(option.name)), 
    [searchTerm, options]
  );

  return (
    <DropDownContainer onBlur={handleBlur} tabIndex={0}>
      <DropDownHeader $textTransform={textTransform} onClick={toggling} arrow={ArrowDownIcon}>
        {placeholder}
      </DropDownHeader>
      {isOpen && options.length > 0 && (
        <DropDownBody $searchBar={searchBar}>
          {searchBar && (
            <ListInput
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              placeholder="Search..."
            />
          )}
          <DropDownList>
            {filteredOptions.map(option => (
              <ListItem onClick={onOptionClicked(option)} key={option.value}>
                {option.icon && <img src={option.icon} alt="" style={{ marginRight: '5px' }} />}
                <span>{option.name}</span>
              </ListItem>
            ))}
          </DropDownList>
        </DropDownBody>
      )}
    </DropDownContainer>
  );
};

export default ActionSelect;
