
import styled from "styled-components";
import InputAdornments from '../../../../elements/input/ChatInput'
import ChatWindowComponent from '../ChatWindowComponent';
import BaseSpinner from "../../../../components/common/Spinner";
import { Fab } from "@mui/material";

export const ChatInput = styled(InputAdornments)`
 && {
   background-color: ${(props) => props.theme.colors.greyFive};
   width: 90%;
   color:  ${(props) => props.theme.colors.primary};
   border-style: none;
   margin: 0 29px;
   border-radius: 10%;
   position: absolute;
   bottom: 3rem;
 }
`;

export const ChatContent = styled(ChatWindowComponent)`
  && {
    height: 100%;
    width: 100%;
    max-height: 85%;
    border-style: none;
    box-shadow: none;
    overflow-y: auto;
    overflow-x: hidden;
    align-items: initial;
  }
`;

export const ConciergeContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CenteredText = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 2rem;
`;

export const Spinner = styled(BaseSpinner)``
