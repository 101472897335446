import { TitleView, ActionsView } from "./HeaderView.styled";
import { RoundedButton } from "../../../../../elements";
import { useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../../hooks";
import { DefaultTheme as theme } from "../../../../../theme";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ConversationTab } from "../../EventName/EventName";

const HeaderView = observer(({index}) => {
  const { viewConversationStore, marketingStore, authStore } = useStore();
  const { isLoading } = viewConversationStore;
  const navigate = useNavigate();

  const onNewTrigger = () => {
    viewConversationStore.clearNewModalForm();
    const eventId = viewConversationStore.event_id;
    viewConversationStore.updateFormField("event_id", "coxleads");
    viewConversationStore.updateFormField("eventName", "New Event");
    viewConversationStore.updateFormField("description", "Description");
    const redirectToTrigger = (id) => {
      navigate({
        pathname: "/v2/conversations/trigger",
        search: createSearchParams({ id, tabId: ConversationTab.Rules  }).toString(),
      });
    }
    viewConversationStore.onCreate(
      authStore.selectedAccount?.id,
      redirectToTrigger
    );
  };

  const onNewMarketing = async () => {
    const id = await marketingStore.newMarketingConversation(authStore.selectedAccount.id);
    if(id)
      navigate({
        pathname: "/v2/conversations/marketing",
        search: createSearchParams({ id }).toString(),
      });
  }

  const onNewConversation = () => {
    if(index === 0) return onNewTrigger
    if(index === 1) return onNewMarketing
  }

  return (
    <>
      <TitleView></TitleView>
      <ActionsView>
        {authStore.hasPermission("CONVERSATIONS_TRIGGERS", "c") && (
          <RoundedButton
            width="63px"
            height="40px"
            color={theme.colors.activeBlue}
            onClick={onNewConversation()}
            disabled={isLoading}
          >
            New
          </RoundedButton>
        )}
      </ActionsView>
    </>
  );
});

export default HeaderView;
