import styled from "styled-components";

const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: ${props => props.$zIndex || 1000};
    display: ${(props) => props.isOpen ? 'flex' : 'none'}
`;

const ModalBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid ${(props) => props.theme.colors.clearGray};
  background-color: ${(props) => props.theme.colors.highClearGray};
  width: ${(props) => props.width || '200x'};
  padding: 32px;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;
  width: 16px;
  height: 16px;
  color: ${(props) => props.theme.colors.clearGray};
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ModalActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
  margin-top: 24px;
`;

export {
  ModalWrapper,
  ModalBox,
  CloseButtonWrapper,
  ModalActionsWrapper,
  ModalContentWrapper
}