import {observer} from "mobx-react";
import React, { useCallback, useEffect } from "react";
import {
  CreateAgentsTeamStepperWrapper,
  ErrorWrapper,
} from "./CreateAgentsTeamStepper.styled";
import { DefaultStepper } from "../../../elements";

import AgentsTeamNameStep from "./components/AgentsTeamNameStep";
import AgentsTeamDescriptionStep from "./components/AgentsTeamDescriptionStep";
import LoadingAgentsTeamCreation from "./components/LoadingAgentsTeamCreation";
import { useStore } from "../../../hooks";
import { useNavigate } from "react-router-dom";

const CreateAgentsTeamStepper = observer(() => {
  const { agentsTeamsStore } = useStore();
  const navigate = useNavigate();

  const onHandleFinish = useCallback(() => {
    agentsTeamsStore.onSubmitData()
  }, [agentsTeamsStore.onSubmitData]);

  useEffect(() => {
    if (agentsTeamsStore.isDone) {
      navigate('/v2/agents-team/tree')
    }
  }, [agentsTeamsStore.isDone]);

  return (
    <CreateAgentsTeamStepperWrapper>
      {agentsTeamsStore.isLoading && <LoadingAgentsTeamCreation/> }
      {!agentsTeamsStore.isLoading &&
        <>
          {agentsTeamsStore.isError && <ErrorWrapper variant="outlined" severity="error">Error submitting customer form!</ErrorWrapper>}
          <DefaultStepper
            stepperActionsWidth={"494px"}
            stepperErrorWidth={"494px"}
            onFinish={onHandleFinish}
            status={agentsTeamsStore.isError ? 'error' : 'idle'}
            >
            <DefaultStepper.Step
              isBack={false}
              isComplete={agentsTeamsStore.currentPage.isNameComplete}
              validationErrorLabel="Team name is required!">
              <AgentsTeamNameStep />
            </DefaultStepper.Step>
            <DefaultStepper.Step
              isBack={false}
              isComplete={agentsTeamsStore.currentPage.isDescriptionComplete}
              validationErrorLabel="Team description is required!">
              <AgentsTeamDescriptionStep />
            </DefaultStepper.Step>
          </DefaultStepper>
        </>
      }
    </CreateAgentsTeamStepperWrapper>
  
  );
});

export default CreateAgentsTeamStepper;
