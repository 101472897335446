import { styled } from "styled-components";

const DropDownContainer = styled("div")`
    width: ${(props) => props.width || '462px'};
    height: 46px;
    border-radius: 10px;
    border: 1px solid #666;
    background: #FCFCFC;

    color: rgba(0, 0, 0, 0.50);
    text-align: left;
    font-size: 18px;
    font-style: italic;
    font-weight: 300;

    margin: ${(props) => props.margin || '0'}
`;

const DropDownHeader = styled("div")`
    position: relative;
    cursor: pointer;
    padding: 0.4em 2em 0.4em 1em;
    text-transform: ${props => props.$textTransform || 'none'};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: ${props => props.theme.colors.black};
    &:after {
        --size: 0.4rem;
        content: "";
        position: absolute;
        right: 1rem;
        pointer-events: none;
    }
    
    &:after {
        width: 30px;
        height: 30px;
        background-image: url('${(props) => props.arrow}');
        background-repeat: no-repeat;
        top: 20%;
        right: 6px;
    }
`;

const DropDownList = styled("ul")`
    position: relative;
    z-index: 10;
    padding: 0;
    margin: 0;
    background: white;
    max-height: 200px;
    overflow-y: scroll;
    &:first-child {
        padding-top: 0.2em;
    }
`;

const ListItem = styled("li")`
    padding-left: 0.6em;
    list-style: none;
    height: 47px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover{
        color:  ${props => props.theme.colors.black};
        background-color: rgba(0, 0, 0, 0.2);
    }
    color:  ${props => props.theme.colors.black};
    transition: background-color 0.5s;
`;

const ListInput = styled("input")`
    position: relative;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
    outline: none;
    border: none;
    border-bottom: 2px solid ${props => props.theme.colors.lightGrey};
    z-index: 10;
`;

export {
    DropDownContainer,
    DropDownHeader,
    DropDownList,
    ListItem,
    ListInput
}