import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import useStore from "../../../../../hooks/useStore";
import ChatBox from "../../../../../elements/ChatBox/ChatBox";
import BasicConfigurationTab from './BasicConfigurationTab';
import AdvancedConfigurationTab from './AdvancedConfigurationTab';
import Spinner from '../../../../../components/common/Spinner';
import {
  Tab,
  TabsContainer,
  TabsHeader,
  TabContent,
  TabElement,
  TabsWrapper
} from '../../../../../elements/v2/Tabs/PlaygroundTabs.styled';
import { useMediaQuery } from 'react-responsive';

const PlaygroundTabs = observer(({ toggleChat, isChatVisible }) => {
  const { authStore, editAgent } = useStore();
  const [activeTab, setActiveTab] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 791 });

  const handleTabClick = (index) => {
      setActiveTab(index);
  };

  const handleMetadata = (object) => {
    const k = Object.keys(object).pop()
    const v = Object.values(object).pop()
    editAgent.updateFromConversation(k, v)
    editAgent.setAgentConfigureMetadata()
  }

  const tabs = [
    {
      label: 'Create',
      content: (
        <ChatBox
          customerSelected={authStore.selectedAccount?.id}
          agentId={editAgent.agentCreator?.id}
          agentDomain={'creator'}
          inputWidth="80vh"
          metadata={editAgent.agentConfigureMetadata}
          initialMessage={'Hello'}
          handleMetadata={handleMetadata}
          agentData={editAgent.agentCreator}
        /> 
      ),
    },
    {
      label: 'Basic',
      content: (
          <BasicConfigurationTab/>
      ),
    },
    // {
    //   label: 'Advanced',
    //   content: (
    //       <AdvancedConfigurationTab/>
    //   ),
    // },
  ];

  return (editAgent.finishedInitialLoad ? 
    <>
      <TabsContainer>
          <TabsHeader>
            <TabsWrapper>
              <Tab isActive={activeTab === 0} onClick={() => handleTabClick(0)}>Create</Tab>
              <Tab isActive={activeTab === 1} onClick={() => handleTabClick(1)}>Basic</Tab>
              {/* <Tab isActive={activeTab === 2} onClick={() => handleTabClick(2)}>Advanced</Tab> */}
              {isMobile && (
                <Tab onClick={toggleChat}>
                  {isChatVisible ? 'Hide Chat' : 'Preview'}
                </Tab>
              )}
            </TabsWrapper>
          </TabsHeader>
          <TabContent>
            <TabElement $isActive={activeTab === 0}>{tabs[0].content}</TabElement>
            <TabElement $isActive={activeTab === 1}>{tabs[1].content}</TabElement>
            {/* <TabElement $isActive={activeTab === 2}>{tabs[2].content}</TabElement> */}
          </TabContent>
      </TabsContainer>
    </>
  : <Spinner/>);
});

export default PlaygroundTabs;
