import ContactForm from "./contactInformation";

import React from "react";
import { Route, Routes } from "react-router-dom";
import ContactList from "./ContactList";

const ContactPage = () => {
  return (
    <Routes>
      <Route index element={<ContactList />} />
    </Routes>
  );
};

export default ContactPage;
