import styled from 'styled-components';

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  border: 1px solid ${(props) => props.theme.colors.dividerGray};
  border-radius: 8px;
  margin-bottom: 16px;
  align-items: flex-start;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

export const CustomCheckbox = styled.input.attrs({ type: 'checkbox' })`
  margin-top: 4px;
`;

export const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

export const Header = styled.div`
  font-weight: ${(props) => (props.isUnread ? 'bold' : 'normal')};
  margin-bottom: 4px;
`;

export const StyledMessage = styled.p`
  margin-top: 0;
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: ${(props) => (props.isUnread && !props.expanded ? 'bold' : 'normal')};
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.expanded ? 'none' : 2)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 1.5;
  white-space: ${(props) => (props.expanded ? 'pre-wrap' : 'normal')};
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledButton = styled.button`
  padding: 6px 12px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;
  font-size: 12px;
`;

export const StyledLink = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.theme.colors.activeBlue};
  text-decoration: none;
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
`;

export const Icon = styled.span`
  font-size: 16px;
`;

export const CompletedText = styled.p`
  color: ${(props) => props.theme.colors.greenPrimary};
  font-weight: bold;
  font-size: 12px;
  margin: 0;
`;

export const DateText = styled.p`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
  align-self: center; 
`;
