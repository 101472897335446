import React, { useMemo, useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../../hooks";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { DefaultTheme as theme } from "../../../../../theme";
import { Container } from '../GroupsTable/GroupsTable.styled';

const GroupUserTable = observer(({ searchValue }) => {
  const { listGroupStore } = useStore();
  const [filteredData, setFilteredData] = useState(listGroupStore.users);

  useEffect(() => {
    const filtered = listGroupStore.users.filter(user =>
      user.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchValue.toLowerCase()) ||
      user.email.toLowerCase().includes(searchValue.toLowerCase()) ||
      user.phone.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchValue, listGroupStore.users]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "firstName",
        header: "First Name",
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
          sx: {
            border: 'none',
          },
        },
      },
      {
        accessorKey: "lastName",
        header: "Last Name",
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
          sx: {
            border: 'none',
          },
        },
      },
      {
        accessorKey: "email",
        header: "Email",
        Cell: ({ cell }) => (
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {cell.getValue()}
          </span>
        ),
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
          sx: {
            border: 'none',
          },
        },
      },
      {
        accessorKey: "phone",
        header: "Phone",
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
          sx: {
            border: 'none',
          },
        },
      },
      {
        accessorKey: "lastActivity",
        header: "Last Activity",
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
          sx: {
            border: 'none',
          },
        },
        Cell: ({ cell }) => (
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {cell.getValue()}
          </span>
        ),
      },
      {
        accessorKey: "group",
        header: "Group",
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
          sx: {
            border: 'none',
          },
        },
      },
      {
        accessorKey: "role",
        header: "Role",
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
          sx: {
            border: 'none',
          },
        },
      },
      {
        accessorKey: "creationDate",
        header: "Creation Date",
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
          sx: {
            border: 'none',
          },
        },
        Cell: ({ cell }) => (
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {cell.getValue()}
          </span>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: filteredData,
    initialState: { isHidden: { countryCode: true } },
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    enableEditing: false,
    enableRowVirtualization: true,
    enablePagination: false,
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer',
        border: 'none',
      },
    }),
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: theme.colors.highClearGray,
        color: 'black',
        paddingTop: '8px',
        paddingBottom: '8px',
        border: 'none',
      },
    },
    muiTableHeadRowProps: {
      sx: {
        borderRadius: '8px',
        border: 'none',
      },
    },
    muiTableContainerProps: {
      sx: {
        border: 'none',
        
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none', 
        border: 'none',    
        
      },
    },
    enableGlobalFilter: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    state: {
      isLoading: listGroupStore.status === "loading",
    },
  });

  return (
    <Container>
    <MaterialReactTable table={table} />
  </Container>
  );
});

export default GroupUserTable;
