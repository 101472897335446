import styled from 'styled-components';

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
  display: flex;
  width: 72%;
  height: 90%;
  background-color: ${props => props.theme.colors.silverGrayThree};
  border-radius: 10px;
  overflow: hidden;
	padding: 50px 40px 0 40px;
	flex-direction: column;
		
`;

const ModalRight = styled.div`
    width: 80%;
		padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    flex-wrap: wrap;
		margin: 0 20px;
    background: ${props => props.theme.colors.highClearGray};
    max-height: 93%; 
    overflow-y: auto; 
`;

const ModalLeft = styled.div`
  width: 20%;
`;


const ModalBody = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 90%;
`;

const ModalFooter = styled.div`
		display: flex;
		flex: 1;
    min-height: 10%;
    background-color: ${props => props.theme.colors.silverGrayThree};
		position: sticky;
		bottom: 0;
		flex-direction: row;
		justify-content: flex-end;
		padding: 5px 0;
`;

const ActionCardsContainer = styled.div`
		padding: 20px;
    gap: 15px;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`;



const ScrollableTitles = styled.div`
  height: 90%;
  overflow-y: auto;
`;

const Title = styled.div`
    padding: 10px;
    cursor: pointer;
    font-weight: 500;
    background-color: ${props => props.active ? props.theme.colors.antiFlashWhite : 'transparent'};
    border-left: 3px solid ${props => props.active ? props.theme.colors.blueCrayola : 'transparent'};

    &:hover {
        background-color: ${props => props.theme.colors.antiFlashWhite};
        border-left: 3px solid ${props => props.theme.colors.blueCrayola};
    }
`;


const Message = styled.div`
  font-size: 16px;
`;

const ModalTitle = styled.h2`
	margin: 0 0 20px 0;
`



export {
	ModalContainer,
	ModalContent,
	ModalLeft,
	ScrollableTitles,
	Title,
	ModalRight,
	Message,
	ModalTitle,
	ModalBody,
	ModalFooter,
	ActionCardsContainer,
}