import styled from "styled-components";
import {Alert} from "@mui/material";

const CreateCustomerStepperWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ModalWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: ${(props) => (props.$isOpen ? "flex" : "none")};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.45);
`;

const ModalFrame = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
    text-align: center;
    background-color: ${props => props.theme.colors.white};
    padding: 20px;
    border-radius: 10px;

    h2 {
        ${props => props.theme.fonts.variant.bold};
        font-size: ${props => props.theme.fonts.size.large};
        color: ${props => props.theme.colors.black};
    }

    p {
        ${props => props.theme.fonts.variant.regular};
        font-size: ${props => props.theme.fonts.size.medium};
        color: ${props => props.theme.colors.black};
        font-style: italic;
    }
`;

const ButtonCloseModal = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.primary};

    &:hover {
        opacity: 0.6;
    }
`;

const ErrorWrapper = styled(Alert)`
    width: 90%;
    box-sizing: border-box;
`;

const LogoImageWrapper = styled.div`
    width: 194px;
    height: 194px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TitleStep = styled.div`
    ${props => props.theme.fonts.variant.regular};
    font-size: ${props => props.theme.fonts.size.large};
    font-style: italic;
    color: ${props => props.theme.colors.primary};
    width: 90%;
    text-align: center;
`;

const SubTitleStep = styled.div`
    ${props => props.theme.fonts.variant.light};
    font-size: ${props => props.theme.fonts.size.normal};
    color: ${props => props.theme.colors.primary};
    width: 90%;
    opacity: 60%;
    text-align: center;
    padding-top: 50px;
`;

export {
  CreateCustomerStepperWrapper,
  ModalWrapper,
  ModalFrame,
  ErrorWrapper,
  ButtonCloseModal,
  LogoImageWrapper,
  TitleStep,
  SubTitleStep
}
