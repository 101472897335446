import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { InputText } from "../../../elements";
import { useStore } from "../../../hooks";
import { FormTitle, FormSubtitle, FormCampaignWrapper } from "../NewCampaignPage.Styled";
import { CustomSelect } from "../../../elements";

const CampaignNameForm = observer(() => {
    const { newCampaign } = useStore();
    const [capaignType, setCampaignType] = useState(null);

    const onHandleChangeName = useCallback((e) => {
        newCampaign.onChangeName(e.target.value);
    }, [newCampaign]);

    const onHandleChangeType = (name, value) => {
        const templates = newCampaign.typeList.find(type => type.value === value);
        newCampaign.onChangeType(name,  value, templates);
    };

    const onBlurName = useCallback(() => {
        newCampaign.onValidateTextField('campaignName');
    }, [newCampaign]);

    useEffect(() => {
        newCampaign.getTypes();
        setCampaignType(newCampaign.campaignTypeName)
      }, []);


    return (
        <FormCampaignWrapper>
            <FormTitle>You can´t miss it!</FormTitle>
            <FormSubtitle>Pick a name that really makes your conversation pop, and figure out what <br /> 
            type would be the best fit.</FormSubtitle>
            <br />
            <InputText
                type="text"
                value={newCampaign.campaignName}
                placeholder="Conversation Name"
                onChange={onHandleChangeName}
                onBlur={onBlurName}
                width="460px"
            />
            <CustomSelect placeholder="Conversation Type" 
                selectedDefault={newCampaign.campaignTypeName} 
                options={newCampaign.typeList} 
                margin="20px 0 90px 0" 
                handleSelect={onHandleChangeType}/>
        </FormCampaignWrapper>
    );
});

export default CampaignNameForm;
