import React, { useCallback } from 'react';
import { observer } from "mobx-react";
import { StepWrapper, HorizontalFormWrapper, HorizontalInputsWrapper, LogoImageWrapper, UserInput, TitleStep, ErrorDiv } from "./UserComponents.Styled";
import { Icon } from "../../../../../elements";
import { useStore } from "../../../../../hooks";

const UserInfoStep = observer(() => {
    const { newUser } = useStore();

    const onHandleChangeFirstName = useCallback((e) => {
        newUser.onChangeFirstName(e.target.value);
    }, [newUser]);

    const onHandleChangeLastName = useCallback((e) => {
        newUser.onChangeLastName(e.target.value);
    }, [newUser]);

    const onHandleChangePhone = useCallback((e) => {
        newUser.onChangePhone(e.target.value);
    }, [newUser]);

    return (
        <StepWrapper>
            <LogoImageWrapper>
                <Icon name="annIsotype" width="96px" height="75px" />
            </LogoImageWrapper>
            <HorizontalFormWrapper>
                <TitleStep>To proceed, we require the following information</TitleStep>
                <HorizontalInputsWrapper>
                    <UserInput
                        type="text"
                        value={newUser.firstName}
                        width="155.5px"
                        placeholder="First Name"
                        onChange={onHandleChangeFirstName}
                    />
                    <UserInput
                        type="text"
                        value={newUser.lastName}
                        width="155.5px"
                        placeholder="Last Name"
                        onChange={onHandleChangeLastName}
                    />
                    <UserInput
                        type="text"
                        value="+1"
                        width="50px"
                        placeholder="Country Code"
                        readOnly
                    />
                    <UserInput
                        type="text"
                        value={newUser.phone}
                        width="270px"
                        placeholder="Phone Number"
                        onChange={onHandleChangePhone}
                    />
                </HorizontalInputsWrapper>
                {newUser.formError && <ErrorDiv>{newUser.formError}</ErrorDiv>}
            </HorizontalFormWrapper>
        </StepWrapper>
    );
});

export default UserInfoStep;
