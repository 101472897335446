import React, { useEffect, useState } from 'react';
import GenericActionCard from './GenericActionCard';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../../../hooks';

const ActionCards = observer(({ options, openModalAddAction, openConfirmationModal, displaySettingsButton=false, setIsTemplateEditorOpen }) => {

	const { viewConversationStore, templateEditorStore } = useStore();

	const openTemplateEditor = () => {
		viewConversationStore.conversationsActionsStore.setSelectedAction(options)
		setIsTemplateEditorOpen(true);
	};

	useEffect(() => {
		templateEditorStore.updateGeneralField('sourceList', viewConversationStore.rulesPage.fields)
	}, [viewConversationStore.rulesPage.fields.length])

	const duplicateAction = () => {
		viewConversationStore.conversationsActionsStore.duplicate(options)
		viewConversationStore.updateChangesDetected(true)
	}

	const customActions = [
		{ iconName: "duplicateOutline", onClick: duplicateAction},
		{ iconName: "editOutline", onClick: () => openModalAddAction(options) },
		{ iconName: "deleteOutline", onClick: () => openConfirmationModal("Delete Confirmation", "Are you sure you want to delete this item?", options) }
	];

	return (
		<>
			<GenericActionCard
				options={options}
				customActions={customActions}
				onSettingsClick={openTemplateEditor}
				displaySettingsButton={displaySettingsButton}
			/>
		</>
	);
});

export default ActionCards;