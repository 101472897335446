import { action, makeObservable, observable, toJS } from "mobx";

export const PermissionsScopes = [
	{name: 'None', value: 'none'},
	{name: 'My own', value: 'own'},
	{name: 'My groups', value: 'groups'},
	{name: 'Rooftop', value: 'rooftop'},
	{name: 'Company', value: 'company'},
]

export const PermissionsScopesIndex = {
	'none': 0,
	'own': 1,
	'groups': 2,
	'rooftop': 3,
	'company': 4,
}

class PermissionsSetupStore {

    initialPermissionsCatalog = [];
    permissionsCatalog = [];
    intermediatePermissionsStructure = {}
    permissionsLoaded = false;
    saveStructure = {};
    loadedPermissions = null;

    constructor() {
        makeObservable(this, {
            permissionsCatalog: observable,
            intermediatePermissionsStructure: observable,
            permissionsLoaded: observable,
            saveStructure: observable,
            setupPermissionsCatalog: action,
            loadPermissions: action,
            loadParentPermissions: action,
            generateSaveStructure: action
        });
    }

    setupPermissionsCatalog(value, loadedPermissions=null, parentPermissions=null){
        this.saveStructure = {};
        this.permissionsLoaded = false;
        this.permissionsCatalog = value;
        this.initialPermissionsCatalog = [{ name: "All", code: "ALL", children: []}, ...value];
        this.setPermissionStructure(value);
        if(loadedPermissions){
            this.loadPermissions(loadedPermissions)
        }
        if(parentPermissions){
            this.loadParentPermissions(parentPermissions)
        }
        this.permissionsLoaded = true;
    }

    loadPermissions(permissions){
        /*
        *************
        *************
            In order to load user's permissions into the component, they're expected to have the following structure

            [
                {
                    "id": "",
                    "name": "Agent",
                    "code": "AGENTS_MODULE",
                    "description": "",
                    "permissions":{
                        "VIEW": "company"
                    }
                }
            ]
        *************
        *************
        */
        this.loadedPermissions = permissions;
        for(let permission of permissions){
            if(this.intermediatePermissionsStructure[permission.code])
                this.intermediatePermissionsStructure[permission.code].set_permissions = permission.permissions
        }

        for(let permission of permissions){
            if(this.intermediatePermissionsStructure[permission.code]){
                const parentCode = this.intermediatePermissionsStructure[permission.code].parent_code
                if(parentCode){
                    for(let level of Object.keys(this.intermediatePermissionsStructure[parentCode].set_permissions)){
                        const levelScope = this.intermediatePermissionsStructure[parentCode].set_permissions[level]
                        this.intermediatePermissionsStructure[permission.code].available_scopes[level] = PermissionsScopes.slice(0, PermissionsScopesIndex[levelScope]+1);
                    }
                }
            }
        }

    }

    loadParentPermissions(permissions){
        for(let permission of Object.keys(permissions)){
            if(!this.intermediatePermissionsStructure[permission]) continue
            for(let level of Object.keys(permissions[permission].permissions)){
                const levelScope = permissions[permission].permissions[level]
                this.intermediatePermissionsStructure[permission].available_scopes[level] = PermissionsScopes.slice(PermissionsScopesIndex[levelScope]);
            }
        }
    }

    setPermissionStructure(data, initial=true, parentCode=''){
        for(const parent of data){
            this.intermediatePermissionsStructure[parent.code] = {
                "parent_code": parentCode,
                "name": parent.name,
                "id": parent.id,
                "set_permissions": parent.permission_levels.reduce((a, val) => ({ ...a, [val]: "none"}), {}),
                "available_scopes": parent.permission_levels.reduce((a, val) => (
                    { ...a, [val]: initial ? PermissionsScopes : [{name: 'None', value: 'none'}]}), {}
                ),
            }
            
            if(parent.children.length){
                this.setPermissionStructure(parent.children, false, parent.code)
            }
        }
    }

    setPermissionLevels(parent, level, scope, save=false){
        if(parent.permission_levels.includes(level)){
            this.intermediatePermissionsStructure[parent.code]["set_permissions"][level] = scope;

            if(save){
                if(this.saveStructure[parent.code])
                    this.saveStructure[parent.code][level] = scope;
                else
                    this.saveStructure[parent.code] = {...this.intermediatePermissionsStructure[parent.code]["set_permissions"], [level]: scope}
            }
        }
        
        if(parent.children.length){
            for(const child of parent.children){
                // Line below will make sure to set a maximum level of scope that can be set on children, setting the top as the one
                // selected by the parent. For example, if parent set 'My groups', that will be the maximum level of permissions
                // that its children will be able to choose
                this.intermediatePermissionsStructure[child.code]["available_scopes"][level] = PermissionsScopes.slice(0, PermissionsScopesIndex[scope]+1);
                this.setPermissionLevels(child, level, scope, save);
            }
        }
    }

    filterPermissionDisplayed(value){
        if(value.code === 'ALL'){
            this.permissionsCatalog = this.initialPermissionsCatalog.filter(perm => perm.code !== 'ALL');
            return;
        }
        this.permissionsCatalog = [value];
        
    }

    generateSaveStructure(initialStructure=null){
        let structureToCombine = initialStructure || this.loadedPermissions || []
        const finalStructure = [];

        for(let permission of structureToCombine){
            if(!this.saveStructure[permission.code]){
                finalStructure.push({
                    id: permission.id,
                    name: permission.name,
                    code: permission.code,
                    permission: permission.permissions
                })
            }
        }

        for(let code of Object.keys(this.saveStructure)){
            if(!Object.values(this.saveStructure[code]).every(permission => permission === 'none'))
                finalStructure.push({
                    id: this.intermediatePermissionsStructure[code].id,
                    name: this.intermediatePermissionsStructure[code].name,
                    code,
                    permission: this.saveStructure[code]
                });
        }

        return finalStructure;
    }

}

export default PermissionsSetupStore;