import { action, makeObservable, observable } from "mobx";
import AuthStore from "./auth/AuthStore";
import NewAgentStore from "./agents/pages/NewAgentStore";
import EditAgentStore from "./agents/pages/EditAgentStore";
import CustomerStore from "./customer/CustomerStore";
import NewCampaignStore from "./campaigns/NewCampaignStore";
import ContactStore from "./campaigns/ContactStore";
import UserStore from "./users/UserStore";
import NotificationStore from "./notifications/NotificationStore";
import ProfileStore from "./profile/ProfileStore";
import ViewAgentStore from "./agents/pages/ViewAgentStore";
import NewUserStore from "./users/pages/NewUserStore";
import UnifiedCustomerProfileStore from "./unifiedCustomerProfile/UnifiedCustomerProfile";
import AnnUnifiedCustomerProfileStore from "./annUnifiedCustomerProfile/AnnUnifiedCustomerProfile";
import AgentsTeamsStore from "./agentsTeams/AgentsTeamsStore";
import AgentStore from "./agents/AgentStore";
import TemplateEditorStore from "./TemplateEditorStore";
import AgentTreeStore from "./agentsTeams/AgentTreeStore";
import ConversationsStore from "./conversations/ConversationsStore";
import ViewConversationStore from "./conversations/ViewConversationStore";
import ConversationsActionsStore  from "./conversations/ConversationsActionsStore";
import SelectedUserStore from "./users/pages/SelectedUserStore";
import ListUserStore from "./users/pages/ListUserStore";
import ListRoleStore from "./roles/pages/ListRoleStore";
import HistoryAgentStore from "./agents/pages/HistoryAgentStore";
import ListGroupStore from "./groups/ListGroupStore"
import FeatureStore from "./feature/FeatureStore";
import PermissionsSetupStore from "./users/PermissionsSetupStore";
import BlastCampaignStore from "./conversations/BlastCampaignStore";
import ContactSegmentStore from "./conversations/ContactSegmentStore";
import HumanInTheLoopStore from "./humanInTheLoop/HumanInTheLoopStore";
import AssistanceUCPStore from "./unifiedCustomerProfile/AssistanceUCPStore";
import MarketingStore from "./conversations/MarketingStore";
import AlertStore from "./alerts/AlertsStore";
import VehicleStore from "./vehicles/VehicleStore";
import RepairOrderStore from "./repairsOrderStore/ReapairOrderStore";
import AppointmentsStore from "./appointments/AppointmentsStore";

class Store {
  title = '';

  authStore = new AuthStore();
  customer = new CustomerStore();
  newAgent = new NewAgentStore();
  newUser = new NewUserStore();
  newCampaign = new NewCampaignStore();
  userStore = new UserStore();
  editAgent = new EditAgentStore();
  notificationStore = new NotificationStore();
  profileStore = new ProfileStore();
  contactStore = new ContactStore();
  viewAgentStore = new ViewAgentStore();
  unifiedCustomerProfileStore = new UnifiedCustomerProfileStore();
  annUnifiedCustomerProfileStore = new AnnUnifiedCustomerProfileStore();
  agentsTeamsStore = new AgentsTeamsStore();
  agentStore = new AgentStore();
  templateEditorStore = new TemplateEditorStore();
  agentTreeStore = new AgentTreeStore();
  conversationsStore = new ConversationsStore();
  viewConversationStore = new ViewConversationStore()
  conversationsActionsStore = new ConversationsActionsStore();
  selectedUserStore = new SelectedUserStore();
  listUserStore = new ListUserStore();
  listRoleStore = new ListRoleStore();
  historyAgentStore = new HistoryAgentStore();
  listGroupStore = new ListGroupStore();
  featureStore = new FeatureStore();
  permissionsSetupStore = new PermissionsSetupStore();
  blastCampaignStore = new BlastCampaignStore();
  contactSegmentStore = new ContactSegmentStore();
  humanInTheLoopStore = new HumanInTheLoopStore();
  assistanceUCPStore = new AssistanceUCPStore();
  marketingStore =  new MarketingStore();
  alertStore = new AlertStore();
  vehicleStore = new VehicleStore();
  repairOrderStore = new RepairOrderStore();
  appointmentsStore = new AppointmentsStore();

  constructor() {
    makeObservable(this, {
      title: observable,
      authStore: observable,
      newAgent: observable,
      editAgent: observable,
      customer: observable,
      userStore: observable,
      notificationStore: observable,
      profileStore: observable,
      contactStore: observable,
      viewAgentStore: observable,
      unifiedCustomerProfileStore: observable,
      changeTitle: action,
      agentsTeamsStore: observable,
      agentStore: observable,
      templateEditorStore: observable,
      agentTreeStore: observable,
      viewConversationStore: observable,
      conversationsActionsStore: observable,
      conversationsStore: observable,
      selectedUserStore: observable,
      listUserStore: observable,
      listRoleStore: observable,
      historyAgentStore: observable,
      listGroupStore: observable,
      featureStore: observable,
      permissionsSetupStore: observable,
      contactSegmentStore: observable,
      assistanceUCPStore: observable,
      marketingStore: observable,
      alertStore: observable,
      vehicleStore: observable,
      repairOrderStore: observable,
      appointmentsStore: observable,
    });
  }

  changeTitle = (title) => {
    this.title = title;
  }

  globalConstants = () => {
    return {
      userRoleGroups: ["superadmin", "customeradmin", "user"],
      onboardingTemplate: {
        isKeyReady: false,
        isCampaingReady: false,
        isChannelReady: false,
        isAssistantReady: false,
      },
    };
  }
}

const DefaultStore = new Store();
export default DefaultStore;
