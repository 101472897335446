import React, { useState } from "react";
import { observer } from 'mobx-react';
import {useStore} from "../../../../hooks";
import { AppointmentContainer, AppointmentDescription, UserContainer, AppointmentTitle, AppointmentName, AppointmentBody, ExpandedAppointment, 
    SegmentTag, UserInfoLine, SegmentDescription } from "./UserNotifications.styled";
import { RoundedButton, GenericTextModal } from "../../../../elements";
import { useEffect } from "react";

const UserNotifications = observer(() => {
    const { unifiedCustomerProfileStore } = useStore();
    const [displayDetailsForAppt, setDisplayDetailsForAppt] = useState();
    const [selectedAppt, setSelectedAppt] = useState();
    const [expandMoreDetails, setExpandMoreDetails] = useState(false);
    const [isOrderVisible, setIsOrderVisible] = useState(true);

    useEffect(() => {
        if(displayDetailsForAppt){
            setSelectedAppt(unifiedCustomerProfileStore.notifications.find(appt => appt.id === displayDetailsForAppt))
            setIsOrderVisible(false);
        }
    }, [displayDetailsForAppt])

    useEffect(() => {
        if(unifiedCustomerProfileStore.initialLoad){
            unifiedCustomerProfileStore.getNotifications();
        }

    }, [unifiedCustomerProfileStore.initialLoad])

    return (
        <UserContainer> 
                <>
                    <AppointmentContainer>
                    <AppointmentTitle>Notifications</AppointmentTitle>
                    {displayDetailsForAppt && 
                    <RoundedButton width="89px" height="30px" borderRadius="4.7px" onClick={() => {setDisplayDetailsForAppt(); setIsOrderVisible(true);}}>Back</RoundedButton>
                    }          
                    </AppointmentContainer>
                </>
            {!displayDetailsForAppt && unifiedCustomerProfileStore.notifications.map(appt => 
                <AppointmentContainer key={appt.id}>
                    <div>
                        <AppointmentName>{appt.notification_title}</AppointmentName>
                        <AppointmentDescription>Vehicle: {unifiedCustomerProfileStore.selectedCar.complete_car_name} </AppointmentDescription>
                        <br/>
                        <AppointmentDescription>
                            Priority: 
                            <SegmentTag segmentType={appt.priority}>{appt.priority}</SegmentTag>
                        </AppointmentDescription>
                    </div>
                    <RoundedButton width="89px" height="30px" borderRadius="4.7px" margin="20px 0 0 0" onClick={() => setDisplayDetailsForAppt(appt.id)}>Details</RoundedButton>
                </AppointmentContainer>
            )}
            {selectedAppt && displayDetailsForAppt && (
                <ExpandedAppointment>
                    <AppointmentBody>
                        <AppointmentDescription>Vehicle: <SegmentDescription>{unifiedCustomerProfileStore.selectedCar.complete_car_name}</SegmentDescription></AppointmentDescription>
                        <AppointmentDescription>Issue: <SegmentDescription>{selectedAppt.issue}</SegmentDescription></AppointmentDescription>
                        <AppointmentDescription>Reason for Handoff: <SegmentDescription>{selectedAppt.reason_hand_off}</SegmentDescription></AppointmentDescription>
                        <AppointmentDescription>Action Needed: <SegmentDescription>{selectedAppt.action_needed}</SegmentDescription></AppointmentDescription>
                        
                        {/* TODO UCP */}
                        {/* <RoundedButton width="100%" height="30px" borderRadius="4.7px" margin="20px 0 0 0" kind="tertiary">Review</RoundedButton>
                        <RoundedButton width="100%" height="30px" borderRadius="4.7px" margin="5px 0 10px 0" kind="secondary">Contact John</RoundedButton> */}
                        
                    </AppointmentBody>
                        <SegmentTag segmentType={selectedAppt.priority}>{selectedAppt.priority} Priority</SegmentTag>
                </ExpandedAppointment>
            )}

            {selectedAppt && displayDetailsForAppt && <GenericTextModal
              isOpen={expandMoreDetails}
              onClose={() => setExpandMoreDetails(false)}
              title={selectedAppt.name}
              
              bodyMaxHeight="62vh"
              width="600px"
              height="65vh"
            />}
        </UserContainer>
    );
});

export default UserNotifications;
