// PersonalizationPage.js
import React, { useEffect } from "react";
import { observer } from 'mobx-react';
import { useStore } from "../../../../hooks";
import GenericListComponent from "../components/GenericListComponent/GenericListComponent";
import { ContainerPage, Label } from "./PersonalizationPage.styled";
import FeatureSelectionModal from "../components/FeatureSeleccionModal/FeatureSeleccionModal";
import TextInputComponent from "../components/TextInputComponent/TextInputComponent";
import { ReactComponent as xIcon } from '../../../../elements/icon/assets/x_icon_gray.svg';

const PersonalizationPage = observer(() => {
  const { profileStore, featureStore, authStore } = useStore();

  useEffect(() => {
    profileStore.fetchUserData(authStore.userId, authStore.selectedAccount.id);
    featureStore.fetchFeatures(authStore.userId, authStore.selectedAccount.id);
  }, [profileStore, featureStore]);

  const handleFeatureChange = (newList) => {
    featureStore.setFeatures(newList);
  };

  return (
    <ContainerPage>
      <Label>What do you want the agents to know about you to provide better answers?</Label>
      <TextInputComponent
        value={profileStore.customPersonaKnowledgeDescription}
        maxLength={1500}
        height="80px"
        width="50%"
        fontSize="12px"
        onChange={(e) => profileStore.onChangePersonaKnowledgeDescription(e.target.value)}
      />
      <Label>How do you want your agents to respond?</Label>
      <TextInputComponent
        value={profileStore.customPersonaResponseDescription}
        maxLength={1500}
        height="80px"
        width="50%"
        fontSize="12px"
        onChange={(e) => profileStore.onChangePersonaResponseDescription(e.target.value)}
      />
      <GenericListComponent
        title="Features"
        listItems={featureStore.features}
        setListItems={handleFeatureChange}
        newItem={(value) => featureStore.addFeature(value)}
        placeholder={'Type your feature here'}
        ModalComponent={FeatureSelectionModal}
        height="35vh"
        fontSize="12px"
        ActionIcon={xIcon}
        onActionClick={(index) => featureStore.removeFeature(index)}
      />
    </ContainerPage>
  );
});

export default PersonalizationPage;
