
import styled from 'styled-components';

export const BadgeContainer = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 8px;
  background-color: ${({ color }) => color};
  white-space: nowrap;
  width: auto;
`;

