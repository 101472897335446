import { Icon } from "../../../../../elements";
import {
  SelectorContentIndicatorItem,
  SelectorContentIndicatorItemText
} from "./AgentTeamSelector.styled";

const AgentTeamIndicatorItem = ({icon, value}) => {
  const { name, width, height } = icon;
  return (
    <SelectorContentIndicatorItem>
      <Icon name={name} width={width} height={height} />
      <SelectorContentIndicatorItemText>{value || "-------"}</SelectorContentIndicatorItemText>
    </SelectorContentIndicatorItem>
  );
};

export default AgentTeamIndicatorItem;