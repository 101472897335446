import styled from "styled-components";

const EditAgentWrapper = styled.div`
    padding: 0 5vw 0 5vw;
    display: grid;
    grid-template-columns: ${props => props.$gridColumnsWidth};
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
`;

const AgentProfileWrapper = styled.div`
    height: 80vh;
    max-height: 80vh;
    overflow-y: auto;
`;

const AgentImgContainer = styled.div`
    display: flex;
    gap: 1vw;
`;

const AgentDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const AgentIcon = styled.img`
    border-radius: 50%;
    width: 9.5vw;
    height: 9.5vw;
`;

const CheckboxContainer = styled.div`
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
    margin-bottom: 30px
`;

const EditMessageInput = styled.textarea`
    resize: none;
    padding: 5px 10px;
    border-radius: 10px;
    width: ${props => props.$width || '28vw'};
    &:focus {
        outline: none;
    }
`

const Label = styled.div`
    ${props => props.theme.fonts.variant.light};
    font-size: ${props => props.$fontSize || '18px'};
    color: ${props => props.theme.colors.grey};
    margin: ${props => props.$margin || '10px 0'};
    font-weight: ${props => props.$bold || '500'};
    text-align: ${props => props.$align || 'justify'};
`;

const FormButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 6vw;
    gap: 10px;
`;

const PageWrapper = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center
    height: 100vh;
    max-height: 100vh;
`;

const RadioButtonsContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 20px;
`;

const Separator = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        height: 90%;
        width: 2px;
        background-color: ${props => props.theme.colors.lightGrey};
    }
`;

const ConversationStartersContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    width: 28vw;
`;

export {
    EditAgentWrapper,
    AgentIcon,
    CheckboxContainer,
    EditMessageInput,
    Label,
    FormButtonsContainer,
    PageWrapper,
    RadioButtonsContainer,
    Separator,
    AgentProfileWrapper,
    AgentImgContainer,
    AgentDescriptionContainer,
    ConversationStartersContainer
}