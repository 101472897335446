import { action, makeObservable, observable, flow } from "mobx";
import { API } from "aws-amplify";
import { deleteAgent } from "../../../graphql/queries";

class ViewAgentStore {
  searchValue = '';
  maxAgentPaginator = 6;
  currentAgentPaginator = this.maxAgentPaginator;
  isLoading = false;
  isError = false;
  isConfirm = false;

  constructor() {
    makeObservable(this, {
      searchValue: observable,
      currentAgentPaginator: observable,
      isLoading: observable,
      isError: observable,
      isConfirm: observable,
      onHandleSearchChange: action,
      onHandleLoadMore: action,
      onClearError: action,
      handleConfirmDelete: flow
    });
  }

  onHandleSearchChange = (e) => {
    this.searchValue = e.target.value;
  }

  onHandleLoadMore = () => {
    this.currentAgentPaginator = this.currentAgentPaginator + this.maxAgentPaginator;
  }

  onClearNewAgent() {
    this.isLoading = false;
    this.isError = false;
  }

  *handleConfirmDelete(assistant) {
    this.isLoading = true;
    this.isConfirm = false;
    if (assistant) {
      try {
        yield API.graphql({
          query: deleteAgent,
          variables: {
            input: {
              customer_id: assistant.customer_id,
              id: assistant.id
            },
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        });
        yield this.fetchAssistants();
      } catch (error) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    }
  }

  onDeleteConfirmation = (value) => {
    this.isConfirm = value;
  }

  
  onClearError = () => {
    this.isError = false;
  }
}

export default ViewAgentStore;
