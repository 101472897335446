import React from 'react';

import {
    Backdrop,
    ModalWrapper,
    ModalContainer,
    ModalTitle,
    ModalContainerCloseButton,
    ModalCloseButton
} from "./ExpandableComponent.styled";
import Markdown from 'react-markdown';

const ExpandableComponent = ({ isOpen, onClose, title, height, width, zIndex=false }) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <Backdrop isOpen={isOpen} $zIndex={zIndex}>
            <ModalWrapper $height={height} $width={width}>
                <ModalContainer>
                    {title && <Markdown>{title}</Markdown>}
                </ModalContainer>
                <ModalContainerCloseButton>
                    <ModalCloseButton onClick={handleClose}>Close</ModalCloseButton>
                </ModalContainerCloseButton>
            </ModalWrapper>
        </Backdrop>
    );
};

export default ExpandableComponent;
